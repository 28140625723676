import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PriceCal, MainCon } from './PriceCalculator.style';
import { H4, H5 } from 'components/reusable-css';
import { useSelector } from 'react-redux';
import { UpperCaseWord } from 'assets/css/common-styles';

const PriceCalculator = ({ totalPrice }) => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  return (
    <MainCon>
      <PriceCal>
        <H5> {t('string11')}</H5>
        <H4>
          <Trans
            i18nKey={'string10'}
            values={{ price: totalPrice || '$0', currency: userAccount?.account_currency }}
            components={{ uppercase: <UpperCaseWord /> }}
          >
            <span />
          </Trans>
        </H4>
      </PriceCal>
    </MainCon>
  );
};

export default PriceCalculator;
