import * as Yup from 'yup';
import { EMAIL_YUP } from './yup';
// import { LENGTH_ERROR } from 'utils';
// import { passwordRegex } from 'constants/regex';

export const languageArray = [
  { text: 'English', value: 'en' },
  { text: 'Español', value: 'es' },
];

export const signUpInitial = {
  email: '',
  password: '',
  confirm_password: '',
};
export const signUpValidationSchema = Yup.object({
  email: EMAIL_YUP.required('Email is required'),
  password: Yup.string().required('Please enter password'),
  confirm_password: Yup.string()
    .required('Both passwords must match')
    .oneOf([Yup.ref('password'), null], 'Both passwords must match'),
});

export const initialValues = {
  new_password: '',
  confirm_password: '',
  user_show_password: false,
  user_password_show_again: false,
};
export const passResetValidation = Yup.object({
  new_password: Yup.string().required('Please enter new password').min(7, 'too short').max(64, 'too long'),
  confirm_password: Yup.string()
    .required('Both passwords must match')
    .min(6, 'too short')
    .max(64, 'too long')
    .oneOf([Yup.ref('new_password'), null], 'Both passwords must match'),
});

export const passRecoveryInitial = {
  password_recovery_email: '',
};

export const validationSchema = Yup.object({
  password_recovery_email: Yup.string().required('Please enter email or username'),
});

export const useFeatures = [
  {
    id: 1,
    lanRef: 'string11',
  },
  {
    id: 2,
    lanRef: 'string12',
  },
  {
    id: 3,
    lanRef: 'string13',
  },
];

export const doFeatures = [
  {
    id: 1,
    lanRef: 'string15',
  },
  {
    id: 2,
    lanRef: 'string16',
  },
  {
    id: 3,
    lanRef: 'string17',
  },
  {
    id: 4,
    lanRef: 'string18',
  },
];
