import React from 'react';
import * as Styles from 'pages/member/new-bm/new-bm-last-step/NewBMLastStep.style';
import CustomButton from 'components/common/custom-button';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useMediaQuery } from '@material-ui/core';
import { upgradeServerNewPrice, isSliderBaseUpgradeDefault } from 'components/manage-vm/utils';
import { Message } from 'components/alert/Message';
import { scrollToSection } from 'utils';
import { cardError } from 'validations';
import hooks from 'hooks';
import { AmountContent } from './AmountContent';
import { useTranslation } from 'react-i18next';

const UpgradeLastStep = ({ data, formikProp, loading, features, currentPrice }) => {
  const { t } = useTranslation();
  const { validateForm, handleSubmit, isSubmitting, values } = formikProp;
  const { isSliderInterface } = hooks();
  const isSliderUpgraded = isSliderBaseUpgradeDefault(data, formikProp.values);
  const match = useMediaQuery('(min-width:1317px)');
  const match1 = useMediaQuery('(max-width:1316px)');

  let buttonStyle = match ? { padding: '0.8rem 1rem' } : match1 ? { padding: '0.8rem 5rem' } : null;
  let newPrice = {};
  if (formikProp.values.size && !isSliderInterface) {
    newPrice = upgradeServerNewPrice(formikProp.values, features);
  }
  if (isSliderInterface && !isSliderUpgraded) {
    newPrice = upgradeServerNewPrice(formikProp.values, features);
  }
  return (
    <Styles.NewBMLastStep>
      <Styles.Flex>
        <AmountContent
          monthlyPrice={currentPrice.mo}
          hourlyPrice={currentPrice.hourly}
          noDiscount={data.price_nodiscount_monthly_usd}
        />
        <AmountContent
          monthlyPrice={get(newPrice, 'mo', 0) || currentPrice.mo}
          hourlyPrice={get(newPrice, 'hourly', 0) || currentPrice.hourly}
          title="vs/instance.string91"
          noDiscount={isSliderUpgraded ? data.price_nodiscount_monthly_usd : false}
          values={formikProp.values.prices}
        />
      </Styles.Flex>
      <Styles.FlexUpgrade>
        <Styles.Upgrade>
          <Styles.SubmitServer>
            <CustomButton
              title={'vs/instance.string56'}
              type="submit"
              buttonStyles={buttonStyle}
              // disabled={isSliderInterface ? isSliderUpgraded : !formikProp.values.size}
              onClick={async () => {
                const errors = await validateForm();
                handleSubmit();
                if (errors.size) {
                  Message.error(t('string14'), t('string13'));
                  return scrollToSection('ST1');
                }

                if (cardError(errors, values)) {
                  Message.error(t('string14'), t('string13'));
                  return scrollToSection('PAYMENT-CARD');
                }
              }}
              isLoading={loading || isSubmitting}
            />
          </Styles.SubmitServer>
        </Styles.Upgrade>
      </Styles.FlexUpgrade>
    </Styles.NewBMLastStep>
  );
};

UpgradeLastStep.propTypes = {
  data: PropTypes.object,
  formikProp: PropTypes.object,
  loading: PropTypes.bool,
};

export { UpgradeLastStep };
