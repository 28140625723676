import styled from 'styled-components';

export const Width1 = styled.div`
  width: 40%;
  @media (max-width: 1130px) {
    width: 50%;
  }
`;

export const Width2 = styled.div`
  width: 60%;
  padding-left: 10px;
  @media (max-width: 1130px) {
    width: 50%;
    padding-left: 0px;
  }
`;
export const Box = styled.div`
  position: relative;
  width: 280px;
  height: 290px;
  @media (max-width: 991.5px) {
    margin: 0 auto;
    width: 250px;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  .border {
    border-top: 1px solid gray;
  }
`;

export const NotAvailable = styled.h5`
  position: absolute;
  top: 40%;
  left: 42%;
  @media (max-width: 1340px) {
    top: 33%;
    left: 40%;
  }
  @media (max-width: 425px) {
    top: 30%;
  }
`;

export const ChartImage = styled.img`
  padding: 1rem;
  width: 100%;
  min-height: 22rem;

  @media (max-width: 1279.9px) {
    padding: 1rem 1rem 1rem 0.5rem;
    width: ${(props) => (props.fullWidth ? ' 95%' : '100%')};
  }

  @media (max-width: 650px) {
    min-height: 18rem;
  }

  @media (max-width: 450px) {
    min-height: 15rem !important;
  }
  @media (max-width: 365px) {
    min-height: 13rem !important;
  }
`;
export const ChartArea = styled.div`
  @media (max-width: 1279.9px) {
    display: ${(props) => (props.mobileleftLines ? 'flex' : 'block')};
  }
`;
export const EmptyAreaText = styled.h3`
  color: gray;
  font-weight: bolder;
  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
`;

export const EmptyArea = styled.div`
  display: flex;
  height: 15rem;
  justify-content: center;
  align-items: center;
  flexd-irection: column;
  background-color: #ededed;
  width: 96%;
  margin-top: 2.5rem;
  margin-left: 1.2rem;
  @media (max-width: 1279.9px) {
    padding-left: 1rem;
  }
  @media (max-width: 700px) {
    margin-left: 0.7rem;
  }
  @media (max-width: 436px) {
    margin-left: 0.5rem;
  }
`;
export const MobileleftBorder = styled.span`
  display: none;
  @media (max-width: 1279.9px) {
    display: inline;
    border-left: 2px solid #ff7300;
    border-top: ${(props) => (props.index == 0 ? '2px solid #ff7300' : '0px')};
    border-bottom: ${(props) => (props.index == 1 ? '2px solid #ff7300' : '0px')};
    margin: ${(props) => (props.index == 0 ? '20% 0rem 0rem 1rem' : '-1rem 0rem 25% 1rem')};
    padding-right: 1rem;
  }
`;

export const TopArrow = styled.div`
  width: 0;
  border-bottom: 12px solid #ff7300;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  margin: 0 auto;
`;
export const TopArrowText = styled.h5`
  text-align: center;
  font-weight: 600;
  @media (max-width: 800px) {
    font-size: 14px;
  }
  @media (max-width: 1000px) {
    font-size: 15px;
  }
`;

export const ArrowBorders = styled.div`
  margin: 0 auto;
  width: 150px;
  height: 20px;
  border: 2px solid #ff7300;
  border-bottom: none;
  @media (max-width: 1279.9px) {
    display: ${(props) => (props.lines ? 'block' : 'none')};
  }
`;

export const StatisticsContainer = styled.div`
  background-color: #f5f5f5;
  width: 100% !important;
  height: 100% !important;
`;

export const BMBanwidth = styled.div`
  background-color: #f5f5f5;
  padding-top: 1.5rem !important;
`;

export const VSBanwidth = styled.div`
  width: 100% !important;
  padding-left: 1rem !important;
  padding-right: 0.5rem !important;
`;

export const StatisticsChild = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  background-color: #f5f5f5;
`;
