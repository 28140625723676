import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import * as Styles from './CreditCard.style';
import classes from 'assets/css/fading-animation.module.css';
import { useTranslation, Trans } from 'react-i18next';
import { Highlight } from 'components/vm-deployment/components';
import PaymentMethod from 'components/common/payment-method';
import { Formik } from 'formik';
import { initialValues, NewCreditCardError } from '../constants';
import { validationObject } from 'validations';
import PaymentForm from 'components/Payment-form';
import StoredCard from '../stored-cards';
import { Notification } from 'components/vm-deployment/components';
import { useMutation } from 'react-query';
import { DELETE_CARD, DEFAULT_CARD } from 'pages/member/add-fund/queries';
import { useSelector } from 'react-redux';
import creditCardClasses from './CreditCard.module.css';
import { Message } from 'components/alert/Message';
import { SAVE_CHARGE_CARD } from 'components/vm-deployment/queries/billing';
import { scrollToSection, vm_year } from 'utils';
import CustomButton from 'components/common/custom-button';
import { CustomHook } from 'utils/custom-hook';
import ConditionalFragment from 'components/conditional-fragment';
import { Heading } from 'components/user-management/Heading';

const CreditCard = ({ rights, storedCreditCards, refetchStoredCreditCards }) => {
  const { t } = useTranslation();
  const { mutationProps } = CustomHook();
  const state = useSelector((state) => state.user);
  const credit_card_info_currency = useSelector((state) => state?.user?.account);
  const { mutate: updateDefaultCard, isLoading: updateCreditCardLoading } = useMutation(DEFAULT_CARD);
  const { mutate: createCreditCard, isLoading: createCreditCardLoading } = useMutation(SAVE_CHARGE_CARD);
  const { mutate: deleteCard, isLoading: deleteCreditCardLoading } = useMutation(DELETE_CARD);
  const [clickedItem, setClickedItem] = useState({});
  const [itemsDeleted, setItemsDeleted] = useState([]);

  const handleDefaultCard = async (mutateFn, body, refetch) => {
    await mutateFn(
      body,
      mutationProps('', {
        onSuccess: async () => {
          state.userAccountRefetch();
          state.userBillingAccountRefetch();
          refetch();
        },
      }),
    );
  };
  const defaultCardAvailaible = (storedCreditCards?.billing_stored_credit_cards || []).some(
    (item) => item?.default === '1',
  );

  const handleCreateCreditCard = async (values, resetForm) => {
    try {
      await createCreditCard(
        {
          number: values.card_number,
          expmonth: ('0' + values.card_month).slice(-2),
          expyear: vm_year(values.card_year),
          cvv: values.cvv,
          name: values.card_name,
          phone: values.phone.replace(/\D/g, ''),
          organization: values.company,
          address1: values.billing_address,
          address2: values.address_continue,
          city: values.city,
          state: values.state,
          zipcode: values.zip,
          country: values.country,
          bank: values.bank_name,
          bankphone: values.bank_phone_number.replace(/\D/g, ''),
          currency: credit_card_info_currency?.account_currency,
          amount_to_charge: '0',
          make_default: '1',
        },
        mutationProps(resetForm, {
          onSuccess: async () => {
            refetchStoredCreditCards();
            state.userAccountRefetch();
            state.userBillingAccountRefetch();
            return scrollToSection('credit_cards');
          },
        }),
      );
    } catch (err) {
      Message.error(t(`${err.response.data?.error}`));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationObject}
      onSubmit={(values, { resetForm }) => handleCreateCreditCard(values, resetForm)}
    >
      {(formikProp) => (
        <Styles.CreditCardSC>
          <Styles.CreditCards id="credit_cards">
            <Heading>
              <Trans i18nKey="account/billing_info.string30" components={{ highlight: <Highlight /> }} />
            </Heading>
            <Styles.Paragraph>
              <p>{t('account/billing_info.string31')}</p>
            </Styles.Paragraph>
            {(storedCreditCards?.billing_stored_credit_cards || []).map((item) => (
              <StoredCard
                key={item?.id}
                data={item}
                rights={rights}
                itemsDeleted={itemsDeleted}
                clickedItem={clickedItem || {}}
                updateCreditCardLoading={updateCreditCardLoading}
                deleteCreditCardLoading={deleteCreditCardLoading}
                onDoNotDefaultClick={() => {
                  handleDefaultCard(updateDefaultCard, { id: item.id, defaultcard: '0' }, refetchStoredCreditCards);
                  setClickedItem({ id: item.id, do_not_default: true });
                }}
                onDefaultClick={() => {
                  handleDefaultCard(updateDefaultCard, { id: item.id, defaultcard: '1' }, refetchStoredCreditCards);
                  setClickedItem({ id: item.id, default: true });
                }}
                onDeleteClick={() => {
                  handleDefaultCard(deleteCard, { id: item.id }, () => {
                    setItemsDeleted((prevItemsDeleted) => [...prevItemsDeleted, item.id]);
                  });
                  setClickedItem({ id: item.id, delete: true });
                  setTimeout(() => {
                    refetchStoredCreditCards();
                  }, 5000);
                }}
              />
            ))}
            <ConditionalFragment
              condition={storedCreditCards?.billing_stored_credit_cards.length && !defaultCardAvailaible}
            >
              <div className={!defaultCardAvailaible ? classes.price_box_show : classes.price_box_hide}>
                <Styles.NotificationContainer>
                  <Notification bg={'true'}>
                    <Icon name="info circle" />
                    {t('alert10')}
                  </Notification>
                </Styles.NotificationContainer>
              </div>
            </ConditionalFragment>
            <ConditionalFragment condition={rights.billingaddcc !== 0}>
              <Styles.NewCard
                storedCards={storedCreditCards?.billing_stored_credit_cards?.length}
                id="new_card"
                className={defaultCardAvailaible && creditCardClasses.newCardClose}
              >
                <PaymentMethod
                  heading={t('account/billing_info.string32')}
                  data={{ id: 'new_card' }}
                  setCheckedValue={(e) =>
                    formikProp.setFieldValue('new_card', formikProp.values?.new_card == 'new_card' ? '' : e)
                  }
                  checkboxValue={formikProp.values?.new_card || ''}
                  onClick={() => {
                    formikProp.setFieldValue('new_card', formikProp.values?.new_card == 'new_card' ? '' : 'new_card');
                  }}
                  style={
                    formikProp.errors['new_card'] && formikProp.touched['new_card']
                      ? {
                          color: 'white',
                          background: '#d80000',
                        }
                      : {}
                  }
                />
              </Styles.NewCard>
              <Styles.NewCardForm>
                {formikProp.values?.new_card && (
                  <>
                    <PaymentForm addFunds={'true'} formikProp={formikProp} />
                    <CustomButton
                      isLoading={createCreditCardLoading}
                      onClick={async () => {
                        const errors = await formikProp?.validateForm();
                        formikProp?.handleSubmit();
                        if (NewCreditCardError(errors)) {
                          Message.error(t('string14'), t('string13'));
                          return scrollToSection('new_card');
                        }
                      }}
                      title={t('account/billing_info.string32')}
                      buttonStyles={{
                        marginTop: '2rem',
                        opacity: !formikProp.isValid && 0.3,
                        backgroundColor: !formikProp.isValid && 'gray',
                      }}
                    />
                  </>
                )}
              </Styles.NewCardForm>
            </ConditionalFragment>
          </Styles.CreditCards>
        </Styles.CreditCardSC>
      )}
    </Formik>
  );
};
export default CreditCard;
