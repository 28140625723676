import React from 'react';
import * as Styles from 'pages/member/dns/DNS.style';
import DNSImage from 'assets/svg/img_DNS-Hosting.svg';
import { H4, P } from 'components/reusable-css';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const hostingArr = ['string5', 'string6', 'string7', 'string8'];
const nsDomain = ['string10', 'string11', 'string12'];

const DNSNotFound = () => {
  const { t } = useTranslation();
  return (
    <Styles.NotFound>
      <Styles.NotFoundFlex>
        <Styles.NotFoundContent>
          <H4>{t('dns/instance.string4')}</H4>
          {hostingArr.map((val, i) => (
            <div className="flexCol" key={i}>
              <Icon name="circle" />
              <P>{t(`dns/instance.${val}`)}</P>
            </div>
          ))}
          <H4>{t('dns/instance.string9')}</H4>
          <div className="flexRow">
            {nsDomain.map((val, i) => (
              <div className="flexCol" key={i}>
                <Icon name="circle" />
                <P>{t(`dns/instance.${val}`)}</P>
              </div>
            ))}
          </div>
          <P className="last">{t('dns/instance.string13')}</P>
        </Styles.NotFoundContent>
        <Styles.Image>
          <Styles.ImageContainer src={DNSImage} />
        </Styles.Image>
      </Styles.NotFoundFlex>
    </Styles.NotFound>
  );
};
export default DNSNotFound;
