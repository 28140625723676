import React from 'react';
import { RadioCheck, Input } from './RadioButton.style';
import PropTypes from 'prop-types';
import { isChecked } from './util.js';
const Checkbox = (props) => {
  const { checkbox, data, setCheckedValue, onChange, id, type, name, checked, className, onClick, field } = props;
  return (
    <RadioCheck
      onClick={() => {
        setCheckedValue(data?.id);
        onClick && onClick();
      }}
    >
      <Input
        type={type || 'checkbox'}
        name={name || 'check'}
        id={id}
        checked={isChecked(checkbox, data, checked, field)}
        onChange={onChange}
        onClick={(e) => {
          e.stopPropagation();
          setCheckedValue(data?.id);
          onClick && onClick();
        }}
        className={className}
      />
    </RadioCheck>
  );
};

Checkbox.defaultProps = {
  setCheckedValue: () => {},
  onChange: () => {},
};

Checkbox.propTypes = {
  checkbox: PropTypes.string,
  data: PropTypes.object,
  setCheckedValue: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Checkbox;
