import React from 'react';
import * as Styles from './DomainStep2.style';
import { useTranslation } from 'react-i18next';
import Input from 'components/input';
import Heading from 'components/Heading/Heading';

const DomainStep2 = () => {
  const { t } = useTranslation();

  return (
    <Styles.NewDomainStep2SC>
      <Heading name={`new_domain.step2.heading`}>
        <Styles.StepDescription>{t('new_domain.step2.description')}</Styles.StepDescription>
        <div className="d-flex flex-column">
          <Input
            isFormIk={false}
            placeholderClass
            styling={{
              width: '100%',
              maxWidth: '36.5em',
            }}
          />
        </div>
      </Heading>
    </Styles.NewDomainStep2SC>
  );
};

export default DomainStep2;
