import React from 'react';
import { TabsHeader } from '../components';
import ConditionalFragment from 'components/conditional-fragment';
import { LineUsage, Transfer, BMCharts } from './components';
import { statisticPanelTabs } from 'constants/index';
import { GET_STATS } from '../queries';
import { useQuery } from 'react-query';
import { get } from 'lodash';
import {
  CpuUsageEveryFiveMinutes,
  UnixLoadAverage,
  RAMUsage,
  UnixLoadYMax,
  Bandwidth,
  BandwithYMax,
  CPUYMax,
  RamYMax,
  bmGraphImages,
} from './util';
import Loading from 'components/common/loading';
import { HeadingAndChart } from './components/index';
import { BMBanwidth, StatisticsChild, StatisticsContainer, VSBanwidth } from './Statistics.style';

const DesktopStatisticPanel = ({
  setOpenStatisticPanel,
  data,
  menuOptions,
  setActivePanelValue,
  activePanelValue,
  type,
}) => {
  const { data: statsData, isLoading } = useQuery(['GET_STATS', { id: data?.id, type: type }], GET_STATS, {
    enabled: data?.id ? true : false,
  });

  if (isLoading) {
    return <Loading gap={50} />;
  }
  return (
    <React.Fragment>
      <TabsHeader
        data={data}
        menuOptions={menuOptions}
        isClose={setOpenStatisticPanel}
        setActivePanelValue={setActivePanelValue}
        activePanelValue={activePanelValue}
      />
      <StatisticsContainer>
        <ConditionalFragment condition={activePanelValue === statisticPanelTabs.statistics}>
          <ConditionalFragment condition={type === 'bm'}>
            <BMBanwidth>
              <HeadingAndChart heading="bm/instance.string16">
                <BMCharts graphImages={bmGraphImages} data={data} />
              </HeadingAndChart>
            </BMBanwidth>
          </ConditionalFragment>
          <StatisticsChild>
            <ConditionalFragment condition={type === 'vs'}>
              <VSBanwidth>
                <Transfer
                  data={statsData}
                  statsData={get(statsData, 'vs_resource_statistics[0].data_transfer_current[0]', [])}
                />
              </VSBanwidth>
            </ConditionalFragment>
            <LineUsage
              maxValues={RamYMax(statsData, type)}
              customStepSize={25}
              statsData={RAMUsage(statsData, type)}
              title="vs/instance.string71"
              containerClass={type == 'vs' ? 'col-sm-12 col-md-12 col-lg-6 col-xl-6' : 'col-12'}
            />
            <LineUsage
              count={data?.vcpus}
              maxValues={CPUYMax(statsData, type)}
              ystepSize={2}
              statsData={CpuUsageEveryFiveMinutes(statsData, type)}
              title={`vs/instance.string72`}
              pointRadius={'0'}
              containerClass={type == 'vs' ? 'col-sm-12 col-md-12 col-lg-6 col-xl-6' : 'col-12'}
            />
            {data?.os_class != 'windows' && (
              <LineUsage
                yLabel=" "
                ystepSize={4}
                maxValues={UnixLoadYMax(statsData, type)}
                statsData={UnixLoadAverage(statsData, type)}
                title="vs/instance.string73"
                containerClass={'col-12'}
              />
            )}
            <ConditionalFragment condition={type === 'vs'}>
              <LineUsage
                ystepSize={4}
                maxValues={BandwithYMax(statsData)}
                yLabel=" mbps"
                statsData={Bandwidth(statsData)}
                pointRadius={'0'}
                title="vs/instance.string74"
                containerClass={'col-12'}
              />
            </ConditionalFragment>
          </StatisticsChild>
        </ConditionalFragment>
      </StatisticsContainer>
    </React.Fragment>
  );
};

export default DesktopStatisticPanel;
