import { H5 } from 'components/reusable-css';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Heading = ({ children, heading }) => {
  const { t } = useTranslation();
  return (
    <div className="mr-4 mb-2">
      <div className="pt-4 pb-2">
        <H5>
          <strong>{t(heading)}</strong>
        </H5>
      </div>
      {children}
    </div>
  );
};

export { Heading };
