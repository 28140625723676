import React, { useEffect } from 'react';
import NewVMStep3 from './NewVMStep3';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updatePricing } from 'store/actions/vm-deployment';
import { addPricingData } from 'components/vm-deployment/util';
import VmUtils from '../util';

const NewVMStep3Container = ({
  formikProp,
  OsDataPrice,
  OsData,
  templateError,
  vsTemplate,
  serverSizes,
  setValues,
}) => {
  const { values, setFieldValue } = formikProp;
  const dispatch = useDispatch();
  const userAccount = useSelector((state) => state.user.account);
  const defaultValue = _get(OsData, 'vs_os', []).find((item) => item.id === userAccount.vs_last_os_selected)?.id;
  const { isSliderInterface } = VmUtils();

  let data = [];
  if (!isSliderInterface) {
    data = _get(OsData, 'vs_os', []).map((item) => {
      const pricing = _get(OsDataPrice, 'prices_vs_os', []).find((price) => price.id === item.id);
      return { ...item, prices: _get(pricing, 'price_per_size', []) };
    });
  } else {
    data = addPricingData(_get(OsData, 'vs_os', []), _get(OsDataPrice, 'prices_vs_os'));
  }
  const updatePrice = (defaultValue) => {
    if (!isSliderInterface) {
      const filter = data.find((item) => item.id === defaultValue);
      const priceFilter = filter?.prices.find((item) => item.size === values.size);
      dispatch(updatePricing(_get(priceFilter, 'price_monthly_usd', 0), 'os'));
    } else {
      const priceFilter = _get(OsDataPrice, 'prices_vs_os', []).find((item) => item.id === defaultValue);
      dispatch(updatePricing(_get(priceFilter, 'price_monthly_usd', 0), 'os'));
    }
  };

  useEffect(() => {
    setFieldValue('image', defaultValue);
    updatePrice(defaultValue);
  }, [OsDataPrice, OsData]);

  return (
    <NewVMStep3
      OsData={data}
      formikProp={formikProp}
      updatePrice={updatePrice}
      templateError={templateError}
      vsTemplate={vsTemplate}
      serverSizes={serverSizes}
      setValues={setValues}
    />
  );
};

NewVMStep3Container.propTypes = {
  formikProp: PropTypes.object,
  OsDataPrice: PropTypes.object,
  OsData: PropTypes.object,
  templateError: PropTypes.bool,
  vsTemplate: PropTypes.object,
  serverSizes: PropTypes.array,
  setValues: PropTypes.func,
};
export default NewVMStep3Container;
