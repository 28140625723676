export const backupPlans = {
  0: {
    title: 'vs/instance/create.step5_string7',
    features: ['vs/instance/create.step5_string10', 'vs/instance/create.step5_string11'],
  },
  4: {
    title: 'vs/instance/create.step5_string8',
    features: [
      'vs/instance/create.step5_string12',
      'vs/instance/create.step5_string13',
      'vs/instance/create.step5_string14',
      'vs/instance/create.step5_string15',
      'vs/instance/create.step5_string16',
    ],
  },
  30: {
    title: 'vs/instance/create.step5_string9',
    features: [
      'vs/instance/create.step5_string17',
      'vs/instance/create.step5_string13',
      'vs/instance/create.step5_string14',
      'vs/instance/create.step5_string18',
      'vs/instance/create.step5_string19',
    ],
  },
};
