import React from 'react';
import Backup from 'assets/images/backup.png';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import { H5, H4 } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';
const Head = () => {
  const { t } = useTranslation();
  return (
    <Styles.BackupBg>
      <Styles.BackupSc>
        <div className="mr-4">
          <img src={Backup} alt="backup png" />
        </div>
        <div>
          <H4>
            <strong> {t('vs/instance.string136')}</strong>
          </H4>
          <H5>{t('vs/instance.string137')}</H5>
        </div>
      </Styles.BackupSc>
    </Styles.BackupBg>
  );
};
export { Head };
