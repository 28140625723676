/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-27 17:56:19
 * @modify date 2021-02-05 11:04:31
 */

export default {
  /** Brand colors */
  primaryColor: '#F9AB43',
  primaryColorA1: 'rgba(249, 171, 67, 0.1)',
  primaryColorA2: 'rgba(249, 171, 67, 0.2)',
  primaryColorA3: 'rgba(249, 171, 67, 0.3)',
  primaryDarkColor: '#FF7300',
  primaryDarkColorA1: 'rgba(255, 115, 0, 0.1)',
  primaryDarkColorA2: 'rgba(255, 115, 0, 0.2)',
  primaryDarkColorA3: 'rgba(255, 115, 0, 0.3)',
  secondaryColor: '#616C7B',
  secondaryLightColor: '#707A88',
  secondaryDarkColor: '#363D47',
  lightShadeBackgroundColor: '#f2f2f2',
  errorColor: '#fe4040',
  errorBackgroundColor: '#ffe3e6',
  errorBorderColor: '#ecbcc0',
  dividerColor: '#e6e6e6',
  gray: {
    shade0: '#000000',
    shade1: '#111111',
    shade2: '#222222',
    shade3: '#333333',
    shade4: '#444444',
    shade5: '#555555',
    shade6: '#666666',
    shade7: '#777777',
    mid: '#808080',
    shade8: '#888888',
    shade9: '#999999',
    shade10: '#343434',
    shadeA: '#aaaaaa',
    shadeB: '#bbbbbb',
    shadeC: '#cccccc',
    shadeD: '#dddddd',
    shadeE: '#eeeeee',
    shadeF: '#ffffff',
  },
  blackA1: 'rgba(0, 0, 0, 0.1)',
  blackA2: 'rgba(0, 0, 0, 0.2)',
  overlayBackground: 'rgba(0,0,0,0.5)',

  boxShadow1: 'box-shadow: 0 4px 5px 0 #bdbdbd',

  iconColor: '#222222',

  header: {
    account: {
      fontColor: '#3567ce',
    },
  },

  notification: {
    backgroundColor: '#82bf50',
  },

  serverSizeCard: {
    borderColor: '#e3e6e4',
    hoverBorderColor: '#f5d9b3',
    hoverBackgroundColor: '#F7F6F5',
    saleRed: {
      backgroundColor: 'red',
    },

    features: {
      backgroundColor: '#F6F6F6',
      hoverBackgroundColor: '#EBEDEC',
    },
    tag: {
      backgroundColor: '#0c7a00',
    },
    selectedTag: {
      backgroundColor: '#616C7B',
    },
  },
  redColor: '#E84040',
};
