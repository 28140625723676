export const alphaSpace = /^[ .a-zA-Z0-9,_-]*$/;
export const nameRegex = /^[ .a-zA-Z0-9,-]*$/;
export const phoneNumberRegex = /^[+.0-9]*$/;
export const vsLabelRegex = /^[a-zA-Z0-9-_.]+[a-zA-Z0-9-_. ]*$/;
export const passwordRegex = /^[a-zA-Z0-9]+$/;
export const numberRegex = /^[0-9]*$/;
export const mixRegex = /^[A-Za-z0-9-_., \n\r\t]*$/;
export const roleNameRegex = /^[ a-zA-Z0-9 _-]+$/;
export const pinRegex = /(\d)\1{3}|(\d)\2{2}|(\d)(\d).*\1\2|(\d)(\d)\4|1234|2345|3456|4567|5678|6789/;
export const dnsDomain = /^(?![-.])(?!.*(?:--|\.\.))[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]*)+[a-zA-Z0-9]$/;
export const phoneNumberRegexWithCountryCode = /^[0-9+-]*$/;
export const usernameRegex = /^[a-zA-Z0-9]+$/;