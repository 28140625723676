import React, { useState, useEffect, useMemo } from 'react';
import * as Styles from './ManageVM.style';
import CustomButton from 'components/common/custom-button';
import { nextPageValidation, backPageValidation } from 'components/manage-vm/utils';
import { Highlight } from 'components/vm-deployment/components';
import { FilterCard, SortCard, ManageVMCard, ManageVMMobile, ManagePagination } from 'components/manage-vm';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { navLinks } from 'utils/nav-links-util';
import { useMutation, useQuery } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { get } from 'lodash';
import Pagination from '@material-ui/lab/Pagination';
import ConditionalFragment from 'components/conditional-fragment';
import { INSTANCE_ACTION, UPDATE_PREFERENCES } from 'components/manage-vm/queries';
import Comment from 'components/comment';
import { useSelector } from 'react-redux';
import Toggle from 'components/common/toggle';
import Spinner from 'components/spinner';
import { userRights } from 'store/selectors/user-details';
import { CustomHook } from 'utils/custom-hook';
import classes from 'components/manage-vm/manage-vm.module.css';
import VmUtils from '../new-vm/util';
import { P } from 'components/reusable-css';
import { Heading } from 'assets/css/common-styles';

const ManageVM = ({
  vmData,
  setQueries,
  queries,
  managerVmRefetch,
  managerVmIsFetching,
  backup,
  iconKeyBoard,
  status,
  instance,
  network,
  isFilter,
  isSort,
  isTags,
  pagination,
  dropdownItems,
  menuOptions,
  isLoading,
  statuses,
  vmStatusRefetch,
  vmNetworkData,
  preferenceData,
  style,
}) => {
  const { mutationProps, per_page, isMobile } = CustomHook();
  const { newVmPath } = VmUtils();
  const { t } = useTranslation();
  const rights = useSelector(userRights);
  const state = useSelector((state) => state.user);
  const { data: regionsData } = useQuery('/vs/regions');
  const { data: osData } = useQuery('/vs/os');
  const { data: allTags, refetch: refetchTags } = useQuery(`/${network || 'vs'}/tags`);
  const [activeActive, setActiveAction] = useState({});
  const [deletedIds, setDeletedIds] = useState([]);
  const [preferenceEnabled, setPreferenceEnabled] = useState(
    preferenceData?.preferences[0]?.mrtg_public_url == '0' ? false : true,
  );

  //TO KEEP THE MANAGE BM PUBLIC URL TOGGLE BUTTON UPDATED WHEN PAGE ROUTE CHANGES AND MUTATION API IS RUNNNING AT THE SAME TIME
  useEffect(() => {
    setPreferenceEnabled(preferenceData?.preferences[0]?.mrtg_public_url == '0' ? false : true);
  }, [preferenceData?.preferences[0]?.mrtg_public_url]);

  const { mutate: RefreshServer, isLoading: serverActionsLoading } = useMutation(INSTANCE_ACTION, {
    ...mutationProps('', { errorHeading: t('error'), onSuccess: () => state.userAccountRefetch() }),
  });
  const { mutate: updatePreferences, isLoading: updatePreferencesLoading } = useMutation(UPDATE_PREFERENCES, {
    ...mutationProps('', { errorHeading: t('error'), onSuccess: () => state.userUiRefetch() }),
  });
  const handlePagination = (value) => {
    setQueries({
      ...queries,
      page_number: 1,
      per_page: value.target.value,
    });
  };
  const handleBottomPagination = (event, value) => {
    setQueries({
      ...queries,
      page_number: value,
    });
  };

  const handleNextPage = () => {
    if (nextPageValidation(vmData, queries)) {
      setQueries({
        ...queries,
        page_number: Number(queries.page_number) + 1,
      });
    }
  };
  const opacity = (dropDownItem, id) => {
    if (serverActionsLoading && activeActive.id === id && activeActive.action == dropDownItem.action) {
      return { opacity: 0.5, pointerEvents: 'none' };
    }
    return { opacity: 1 };
  };
  const handleBackPage = () => {
    if (backPageValidation(queries)) {
      setQueries({
        ...queries,
        page_number: Number(queries.page_number) - 1,
      });
    }
  };
  const handleConsoleOpen = (data) => {
    if (data.kvm_url) window.open(data.kvm_url, '_blank', 'toolbar=0,status=0,width=900,height=700,resizable=0');
  };
  const detailOfServers = useMemo(() => get(vmData, instance || 'vs_instance', []), [vmData]);
  const isPagination = useMemo(() => Math.ceil(vmData?.meta?.total_all_records / +per_page) > 1, [vmData]);

  return (
    <React.Fragment>
      <ConditionalFragment condition={detailOfServers.length}>
        <Styles.ManageVMSC style={style}>
          <Styles.ManageVMs>
            <Styles.BuildVM>
              <div>
                <Heading>
                  <Trans
                    i18nKey={network == 'bm' ? 'bm/instance.string1' : 'vs/instance.string1'}
                    components={{ highlight: <Highlight /> }}
                  />
                </Heading>
                <Styles.SearchVMs>
                  <Styles.Searching matches={isMobile}>
                    <div>
                      <ConditionalFragment condition={isFilter}>
                        <FilterCard regionsData={regionsData} osData={osData} allTags={get(allTags, 'tags', [])} />
                      </ConditionalFragment>
                      <ConditionalFragment condition={isSort}>
                        <SortCard setQueries={setQueries} queries={queries} type={network} />
                      </ConditionalFragment>
                    </div>
                    <ConditionalFragment condition={!isMobile}>
                      <ManagePagination
                        handleBackPage={handleBackPage}
                        handleNextPage={handleNextPage}
                        handlePagination={handlePagination}
                        pagination={pagination && isPagination}
                        vmData={vmData}
                        queries={queries}
                      />
                    </ConditionalFragment>
                  </Styles.Searching>
                </Styles.SearchVMs>
              </div>

              <ConditionalFragment condition={!isMobile}>
                <Link to={network == 'bm' ? navLinks.new_server.link : newVmPath}>
                  <CustomButton title={t(network == 'bm' ? 'bm/instance.string2' : 'vs/instance.string2')} />
                </Link>
              </ConditionalFragment>
            </Styles.BuildVM>
          </Styles.ManageVMs>
          <ConditionalFragment condition={isMobile}>
            <Link className={classes.mobile_manage_btn} to={network == 'bm' ? navLinks.new_server.link : newVmPath}>
              <CustomButton
                title={t(network == 'bm' ? 'bm/instance.string2' : 'vs/instance.string2')}
                buttonStyles={{
                  margin: '1rem 0',
                }}
              />
            </Link>
          </ConditionalFragment>

          <ConditionalFragment condition={isMobile}>
            <Styles.SearchVMs>
              <Styles.Searching className={classes.manage_mobile_pagination}>
                <ManagePagination
                  handleBackPage={handleBackPage}
                  handleNextPage={handleNextPage}
                  handlePagination={handlePagination}
                  pagination={pagination && isPagination}
                  vmData={vmData}
                  queries={queries}
                />
              </Styles.Searching>
            </Styles.SearchVMs>
          </ConditionalFragment>

          <Grid item xs={12}>
            <ConditionalFragment condition={!isMobile}>
              <Styles.Cards>
                {detailOfServers?.map((data) => (
                  <ManageVMCard
                    key={data.id}
                    data={data}
                    rights={rights}
                    managerVmRefetch={managerVmRefetch}
                    allTags={allTags}
                    refetchTags={refetchTags}
                    managerVmIsFetching={managerVmIsFetching}
                    backup={backup}
                    iconKeyBoard={iconKeyBoard}
                    status={status}
                    network={network}
                    isTags={isTags}
                    dropdownItems={dropdownItems}
                    menuOptions={menuOptions}
                    isLoading={isLoading}
                    RefreshServer={async (action, onSuccess) => {
                      setActiveAction({ id: data.id, action });
                      await RefreshServer(
                        { id: data.id, action, type: network },
                        {
                          onSuccess: () => {
                            onSuccess();
                          },
                        },
                      );
                    }}
                    handleConsoleOpen={() => handleConsoleOpen(data)}
                    setDeletedIds={setDeletedIds}
                    deletedIds={deletedIds}
                    opacity={(item) => opacity(item, data.id)}
                    serverActionsLoading={serverActionsLoading}
                    activeActive={activeActive}
                    serverStatus={statuses}
                    vmStatusRefetch={vmStatusRefetch}
                    serverIpDetails={vmNetworkData?.find((item) => item.id === data.id)}
                  />
                ))}
              </Styles.Cards>
            </ConditionalFragment>
            <ConditionalFragment condition={isMobile}>
              {detailOfServers?.map((data) => (
                <ManageVMMobile
                  key={data.id}
                  data={data}
                  rights={rights}
                  managerVmRefetch={managerVmRefetch}
                  allTags={allTags}
                  refetchTags={refetchTags}
                  managerVmIsFetching={managerVmIsFetching}
                  backup={backup}
                  iconKeyBoard={iconKeyBoard}
                  status={status}
                  network={network}
                  isTags={isTags}
                  dropdownItems={dropdownItems}
                  menuOptions={menuOptions}
                  handleConsoleOpen={() => handleConsoleOpen(data)}
                  RefreshServer={async (action, onSuccess) => {
                    setActiveAction({ id: data.id, action });
                    await RefreshServer(
                      { id: data.id, action, type: network },
                      {
                        onSuccess: () => {
                          onSuccess();
                        },
                      },
                    );
                  }}
                  setDeletedIds={setDeletedIds}
                  deletedIds={deletedIds}
                  activeActive={activeActive}
                  serverIpDetails={vmNetworkData?.find((item) => item.id === data.id)}
                  opacity={(item) => opacity(item, data.id)}
                  serverStatus={statuses}
                  vmStatusRefetch={vmStatusRefetch}
                />
              ))}
            </ConditionalFragment>
          </Grid>
          <ConditionalFragment condition={pagination && isPagination}>
            <div className={classes.manage_bottom_mobile_pagination}>
              <Pagination
                variant="outlined"
                siblingCount={10}
                color="secondary"
                count={vmData?.meta?.how_many_pages}
                page={Number(queries?.page_number)}
                onChange={handleBottomPagination}
              />
            </div>
          </ConditionalFragment>
          <ConditionalFragment condition={network === 'bm'}>
            <Comment>
              <P>
                <Trans i18nKey={'bm/instance.string8'}>
                  <i className="fa fa-cog" />
                </Trans>
              </P>
              <P className={classes.bottomMarginSty}>{t('bm/instance.string9')}</P>

              <div className={classes.flex_link}>
                <Styles.link
                  className="text-white"
                  target="_blank"
                  rel="noreferrer"
                  to={state?.ui?.mrtg_public_fullurl.split('#')[1]}
                >
                  {state?.ui?.mrtg_public_fullurl}
                </Styles.link>

                {/* SHOW LOADING WHEN PREFERENCES ARE UPDATING AND NEW LINK WILL BE GENERATED IN THE USER DETAILS API */}
                {(updatePreferencesLoading || state.isUserUiFetching) && (
                  <span className={classes.loading_spinner}>
                    <Spinner color="orange" />
                  </span>
                )}
              </div>
              <Styles.toggle>
                <div className={classes.rightMarginSty}>
                  <Toggle
                    handleChange={async (e) => {
                      if (e.target.checked) {
                        setPreferenceEnabled(true);
                        await updatePreferences({ mrtg_public_url: '1' });
                      } else {
                        setPreferenceEnabled(false);
                        await updatePreferences({ mrtg_public_url: '0' });
                      }
                    }}
                    value="active"
                    checked={preferenceEnabled}
                  />
                </div>
                <span className={classes.topMarginSty}>{t('bm/instance.string10')}</span>
              </Styles.toggle>
            </Comment>
          </ConditionalFragment>
        </Styles.ManageVMSC>
      </ConditionalFragment>
      <ConditionalFragment condition={!detailOfServers.length}>
        <Styles.NotFound style={style}>
          <div className={classes.noserver_container}>
            <div className={classes.noserver_inner_container}>
              <Heading>
                <Trans
                  i18nKey={network == 'bm' ? 'bm/instance.string11' : 'vs/instance.string50'}
                  components={{ highlight: <Highlight /> }}
                />
              </Heading>
              <Link to={network == 'bm' ? navLinks.new_server.link : newVmPath}>
                <CustomButton title={t(network == 'bm' ? 'bm/instance.string2' : 'vs/instance.string2')} />
              </Link>
            </div>
            <P className={classes.no_server}>{t(network == 'bm' ? 'bm/instance.string12' : 'vs/instance.string51')}</P>
          </div>
        </Styles.NotFound>
      </ConditionalFragment>
    </React.Fragment>
  );
};

ManageVM.defaultProps = {
  backup: true,
  iconKeyBoard: true,
  status: true,
  isFilter: false,
  isSort: true,
  isTags: true,
  pagination: true,
};
export default React.memo(ManageVM);
