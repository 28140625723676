import styled from 'styled-components';
import { variables } from 'variables';

export const RecoverableVsSC = styled.div`
  background: ${(props) => props.theme.pageBackgroundColor};
  padding: ${variables.pageContentPadding}px;
  padding-bottom: 2em;
  min-height: 100% !important;
  width: 100%;
  height: 100%;
  background-size: cover;
`;
export const Heading = styled.div`
  padding-top: 8px;
  p {
    margin: 12px 0;
  }
`;

export const RecoverableVs = styled.div`
  margin: 30px 0;
`;

export const Card = styled.div`
  background: white;
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  margin-top: 15px;
  padding: 15px 20px;
  &:hover {
    background-color: #f9f9f9;
  }
  @media only screen and (max-width: 600px) {
    padding: 15px 10px;
  }
`;
export const CardContent = styled.div`
  display: flex;
  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

//card content
export const Icon = styled.div`
  display: flex;
  width: 50%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
export const Picture = styled.div`
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  i {
    color: balck;
  }
`;
export const Border = styled.div`
  border-right: 1px solid #e9e9e9;
  margin: 0 0.5rem;
`;

export const Label = styled.div`
  padding: 0 10px;
`;

export const Region = styled.div`
  padding: 0 10px;
  width: 40%;
  text-align: end;
  @media only screen and (max-width: 600px) {
    width: 70%;
    margin-top: 20px;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
  }
`;
export const CountryFlag = styled.div`
  display: flex;
  h6 {
    margin: 5px 15px 0 8px;
  }
`;
export const Circle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  border: 0.5px solid #c5c5c5;
  img {
    object-fit: contain;
  }
`;
export const Button = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 600px) {
    justify-content: flex-end;
    width: 30%;
    margin-top: 20px;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
  }
`;

export const TickIcon = styled.div`
  display: flex;
  margin-bottom: 8px;
  i {
    color: white !important;
  }
  p {
    margin-left: -20px;
  }
`;

export const CommentContainer = styled.div`
  margin-top: -1rem;
`;
