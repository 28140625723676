export const authenticationSteps = [
  {
    localize_label: 'vs/instance/create.step6_string3',
    label: 'Root/Administrator password:',
    type: 'password',
    name: 'root_pass',
    text: 'Because different operating system have different password requirements, this form will only accept letters and numbers. Please use a combination of lower and upper case letters as well as numbers. Once you log in to the server, please change your password to a more complex one. We recommend you use https://passwordsgenerator.net/ to generate a stronger password later on.',
    localizeText: 'vs/instance/create.step6_string7',
  },
  {
    localize_label: 'vs/instance/create.step6_string4',
    label: 'Confirm password:',
    type: 'password',
    name: 'confirm_pass',
  },
  {
    localize_label: 'vs/instance/create.step6_string5',
    label: 'Name this virtual server:',
    type: 'text',
    name: 'virtual_server_name',
    text: 'Please use only letters, numbers, spaces, dashes, underscores or periods; up to 32 characters. For example, this server could be named "Email Server 3" or "WordPress Server".',
    localizeText: 'vs/instance/create.step6_string8',
  },
  {
    localize_label: 'vs/instance/create.step6_string6',
    label: 'Any extra IP numbers?',
    type: 'select',
    name: 'ipv4_ips',
    localizeText: 'vs/instance/create.step6_string9',
    options: [
      { value: 1, text: 'vs/instance/create.step6_string10' },
      { value: 2, text: 'vs/instance/create.step6_string11' },
      { value: 3, text: 'vs/instance/create.step6_string12' },
      { value: 4, text: 'vs/instance/create.step6_string13' },
    ],
    text: 'If you need more IPs, please feel free to contact our sales team. With proper IP justification, we may be able to accomodate your needs. Each additional IP number is $3/month.',
  },
];
