import { dynamicStyles } from 'components/reusable-css';
import styled from 'styled-components';
import { deviceBreakpoints } from 'variables';

export const AppDrawerSC = styled.div`
  display: flex;
  height: 100vh;
  min-width: ${(props) => props.theme.default?.desktop_menu_width};
  max-width: ${(props) => props.theme.default?.desktop_menu_width};
  transition: left 500ms ease;
  ${(props) =>
    dynamicStyles(props.theme.resolutions, 'desktop_menu_width', 'min-width', 'desktop_menu_width', 'max-width')};
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    position: fixed;
    top: ${(props) => props.theme.default?.top_bar_height};
    left: -${(props) => (props.isAppDrawerOpen ? 0 : props.theme.default?.desktop_menu_width)};
    height: calc(100vh - ${(props) => props.theme.default?.top_bar_height});
    z-index: 9999;
  }
`;

export const AppDrawerContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.secondaryColor};

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    display: none;
  }
`;

export const AppDrawerHeader = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.theme.default?.top_bar_height};
  min-height: ${(props) => props.theme.default?.top_bar_height};
  background: ${(props) => props.theme.gray.shadeE};
  ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_height', 'height', 'top_bar_height', 'min-height')};
`;

export const LogoImageSC = styled.img`
  width: ${(props) => props.theme.default?.logo_top_left};
  ${(props) => dynamicStyles(props.theme.resolutions, 'logo_top_left', 'width')};
`;

export const GoBackButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    display: none;
  }
`;
