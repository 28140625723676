import React from 'react';
import * as Styles from './style';
import Heading from 'components/Heading/Heading';
import { H6, P } from 'components/reusable-css';
import moment from 'moment';
import Checkbox from 'components/common/radio-button/RadioButton';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UpperCaseWord } from 'assets/css/common-styles';
import { useMutation } from 'react-query';
import { BILLING_PAYMENT_TERMS } from 'components/manage-vm/queries';
import LoadingImage from 'assets/images/loading.svg';
import { CustomHook } from 'utils/custom-hook';

const paymentTermArr = [
  { id: 1, name: 'vs/instance.string181', termId: 1 },
  { id: 12, name: 'vs/instance.string182', termId: 12 },
];

const PaymentTerms = ({ data, setPaymentTerm, paymentTerm, setIschanged }) => {
  const { t } = useTranslation();
  const { mutate: billingTerms, isLoading } = useMutation(BILLING_PAYMENT_TERMS);
  const userAccount = useSelector((state) => state.user.account);
  const { mutationProps } = CustomHook();

  const handleClick = async (value) => {
    setPaymentTerm(value.id);
    await billingTerms(
      {
        id: data.id,
        term: value.id,
      },
      mutationProps(false),
    );
    setIschanged(value.id);
  };

  return (
    <Styles.PAymentTermSC>
      <Styles.FirstSection>
        <div className="width">
          <Heading small name={'vs/instance.string184'} />
        </div>
        <Styles.PriceTermSC>
          {paymentTermArr.map((item) => (
            <Styles.PriceTerm key={item.id} onClick={() => handleClick(item)}>
              <Checkbox
                checkbox={paymentTerm}
                setCheckedValue={(value) => setPaymentTerm(value)}
                data={item}
                onClick={() => handleClick(item)}
              />
              <H6>
                <Trans
                  i18nKey={item.name}
                  values={{
                    price: item.id == 1 ? data?.price_monthly_usd : data?.price_yearly_usd,
                    currency: userAccount.account_currency,
                    currency_symbol: userAccount.account_currency_symbol,
                  }}
                  components={{ upperCaseWord: <UpperCaseWord /> }}
                />
              </H6>
            </Styles.PriceTerm>
          ))}
          {isLoading && <img src={LoadingImage} width={25} />}
        </Styles.PriceTermSC>
      </Styles.FirstSection>
      <Styles.Section>
        <div className="width">
          <Heading small name={'vs/instance.string185'} />
        </div>
        <H6 className="section">{moment(data.next_payment_date).format('ll')}</H6>
      </Styles.Section>
      <Styles.Section>
        <div className="width">
          <Heading small name={'vs/instance.string186'} />
        </div>
        <H6 className="margin">
          <Trans
            i18nKey={'vs/instance.string187'}
            values={{
              currency: userAccount.account_currency,
              currency_symbol: userAccount.account_currency_symbol,
              price: paymentTerm == 1 ? data?.price_monthly_usd : data?.price_yearly_usd,
            }}
            components={{ upperCaseWord: <UpperCaseWord /> }}
          />
        </H6>
      </Styles.Section>
      <P>{t('vs/instance.string183')}</P>
    </Styles.PAymentTermSC>
  );
};

export default PaymentTerms;
