import React from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import CustomButton from 'components/common/custom-button';
import { useTranslation } from 'react-i18next';

const NewPassword = ({ loading, refreshServer }) => {
  const { t } = useTranslation();
  return (
    <Styles.ActionDiv>
      <Styles.OptionText>{t('vs/instance.string107')}</Styles.OptionText>
      <CustomButton
        title={'vs/instance.string108'}
        isLoading={loading}
        onClick={async () => await refreshServer('resetpw')}
      />
    </Styles.ActionDiv>
  );
};

export { NewPassword };
