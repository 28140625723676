import styled, { css } from 'styled-components';
import BackgroundImage from 'assets/images/welcome-bg.jpg';
import LogoCC from 'assets/images/logo-cc.svg';
import { Link } from 'react-router-dom';
import { deviceBreakpoints } from 'variables';
import { h3Styles, h4Styles, pStyles } from 'components/reusable-css';

export const Error = styled.div`
  padding-top: 1em;
  padding-bottom: 1.5em;
`;

export const Warning = styled.div`
  padding: 0 1em;
  padding-bottom: 0.5em;
  width: 100%;
`;

export const Welcomebg = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${(props) => props.theme.default?.top_bar_height});
  ${(props) =>
    props.theme.resolutions?.map((resolution) => {
      const { width, top_bar_height } = resolution;
      if (top_bar_height != undefined)
        return css`
          @media only screen and (max-width: ${width}px) {
            min-height: calc(100vh - ${top_bar_height});
          }
        `;
    })};
`;

export const WelcomeStart = styled.div`
  font-family: raleway, sans-serif !important;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const WelcomeTop = styled.div`
  text-align: center;
  padding: 18px 10px;
  display: flex;
  flex-flow: row wrap;
`;
export const WelcomeHeading = styled.h3`
  color: #218fc2;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 700;
  line-height: 1.5;
  font-size: ${(props) => props.theme.default?.h3};
  ${(props) => h3Styles(props.theme.resolutions)}
`;

export const WelcomeContent = styled.div`
  margin: 20px auto;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const WelcomeBox = styled.div`
  background: #fff;
  display: inline-block;
  vertical-align: top;
  margin: 0 30px;
  width: 440px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 24px 5px #d1d1d1;
  @media only screen and (max-width: 765px) {
    width: 440px;
  }
  @media only screen and (max-width: 550px) {
    width: 100%;
    margin: 0;
  }
`;

export const InnerBox = styled.div`
  padding: 0 15px;
  padding-bottom: 20px;
  text-align: left;
`;
export const SecondWelcomeBox = styled.div`
  background: grey;
  line-height: 1.5;
  display: inline-block;
  margin: 0 30px;
  width: 440px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 24px 5px #d1d1d1;
  height: 470px;

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    width: 330px;
  }
  @media only screen and (max-width: 1020px) {
    visibility: hidden;
    height: 0;
    width: 0;
    margin: 0;
  }
`;
export const SecondInnerBox = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 0;
  fontsize: 30px !important;
`;
export const List = styled.div`
  padding: 0 10px;
  list-style: none;
  @media only screen and (max-width: ${deviceBreakpoints.phone}px) {
    padding: 0;
  }
`;
export const Logo = styled.div`
  background-image: url(${LogoCC});
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  width: 90%;
  height: 0;
  margin-top: 20px;
  padding-bottom: 28%;
  @media only screen and (min-width: 1024px) {
    width: 45%;
    padding-bottom: 15%;
  }
  @media only screen and (max-width: 1024px) {
    width: 150px;
    padding-bottom: 50px;
  }
`;
export const Icons = styled.div`
  content: '';
  width: 12px;
  height: 12px;
  background: #ff7300;
  color: #ff7300;
  font-size: 60px;
  border-radius: 50%;
  @media only screen and (max-width: ${deviceBreakpoints.phone}px) {
    width: 10px;
    height: 10px;
  }
`;
export const Li = styled.div`
  position: relative;
  padding: 8px 0;
  display: flex;
  align-items: center;
  i {
    margin-top: -3px;
    font-size: 10px;
    color: ${(props) => props.theme.primaryDarkColor};
  }
`;
export const Links = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
  }
`;
export const ListName = styled.div`
  display: inline-block;
  background: #f0f0f0;
  color: #0160a9;
  margin-left: 12px;
  margin-bottom: 0;
  padding: 10px 15px;
  line-height: 1.2;
  font-weight: 400;
  border-radius: 4px;
  cursor: pointer;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)}
  &:hover {
    background: #d8f4fe;
    text-decoration: none !important;
    -webkit-text-decoration: none !important;
  }
  @media only screen and (max-width: ${deviceBreakpoints.phone}px) {
    padding: 5px 15px;
    margin-left: 9px;
  }
`;
export const StyledLink = styled.span`
  text-decoration: underline;
  color: #0160a9;
  &:hover {
    background: #d8f4fe;
    text-decoration: none !important;
  }
`;
export const LinkSpan = styled.span`
  font-weight: 30 !important;
  text-decoration: none !important;
  color: black;
`;

export const WelcomeBoxBottom = styled.div`
  margin-top: 20px;
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
  }
  @media only screen and (max-width: 1020px) {
    display: inline-block;
  }
`;
export const WelcomeBottom = styled.div`
  padding: 10px 0;
  height: 100px;
  background: #fff;
  display: inline-block;
  vertical-align: top;
  margin: 2px 30px;
  width: 440px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 24px 5px #d1d1d1;
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    width: 330px;
  }

  @media only screen and (max-width: 1020px) {
    width: 355px;
    margin: 0 5px 0 10px;
    height: 90px;
  }
  @media only screen and (max-width: 780px) {
    width: 350px;
    margin: 0;
    margin-top: 18px;
  }
  @media only screen and (max-width: 765px) {
    width: 440px;
    margin: 0;
    margin-top: 18px;
  }

  @media only screen and (max-width: ${deviceBreakpoints.phone}px) {
    width: 350px;
    margin: 0;
    margin-top: 18px;
  }
  @media only screen and (max-width: ${deviceBreakpoints.sPhone}px) {
    width: 278px;
    height: 85px;
  }
`;
export const WelcomeBottomSecond = styled.div`
  padding-top: 12px;
  height: 100px;
  background: #fff;
  display: inline-block;
  vertical-align: top;
  margin: 0 30px;
  width: 440px;
  overflow: hidden;
  box-shadow: 0 0 24px 5px #d1d1d1;
  @media only screen and (max-width: 1020px) {
    margin: 0 10px 0 5px;
    height: 90px;
  }

  @media only screen and (max-width: 780px) {
    margin: 0;
    margin-top: 18px;
  }
  @media only screen and (max-width: 765px) {
    width: 440px;
    margin: 0;
    margin-top: 18px;
  }

  @media only screen and (max-width: ${deviceBreakpoints.phone}px) {
    width: 350px;
    margin: 0;
    margin-top: 20px;
  }
  @media only screen and (max-width: ${deviceBreakpoints.sPhone}px) {
    width: 100%;
    height: 85px;
    padding-top: 10px;
  }
`;

export const WelcomeBottomSecondList = styled.div`
  display: flex;
`;

export const BottomList = styled.div`
  padding: 0 20px;
  display: flex;
  margin-left: 1em;
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    margin: 0;
    padding: 0 0 0 5px;
  }
  @media only screen and (max-width: ${deviceBreakpoints.sLaptop}px) {
    margin: 0;
    padding: 0 0 0 35px;
  }

  @media only screen and (max-width: ${deviceBreakpoints.sPhone}px) {
    padding: 0 13px;
  }
`;
export const Image = styled.div`
  width: 23%;
  margin-left: 1em;
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    width: 22%;
    margin: 0;
  }
  @media only screen and (max-width: 750px) {
    width: 18%;
  }
  @media only screen and (max-width: ${deviceBreakpoints.sPhone}px) {
    width: 28%;
  }
`;

export const Paragraphy = styled.div`
  width: 80%;
  color: #ff7300;
  font-weight: 350;
  font-size: ${(props) => props.theme.default?.h4};
  ${(props) => h4Styles(props.theme.resolutions)};
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    font-weight: 300;
    padding: 5px 0 15px 5px;
    width: 70%;
  }
  @media only screen and (max-width: ${deviceBreakpoints.phone}px) {
    padding: 5px 0 10px 15px;
  }
  @media only screen and (max-width: ${deviceBreakpoints.sPhone}px) {
    padding: 10px 0 10px 10px;
    width: 60%;
  }
`;

export const HyphenIcon = styled.span`
  padding: 0 10px;
  font-weight: 200;
  content: '';
  background: #ff7300;
  color: #ff7300;
  font-size: 2px;
  margin-top: 11em;
  margin-bottom: 18em;
  margin-left: 20px;
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 9em;
    padding: 0 8px;
    font-size: 1.8px;
  }

  @media only screen and (max-width: ${deviceBreakpoints.sLaptop}px) {
    margin-right: 0;
  }
  @media only screen and (max-width: ${deviceBreakpoints.phone}px) {
    margin-left: 8px;
  }
  @media only screen and (max-width: ${deviceBreakpoints.sPhone}px) {
    margin-left: 0;
    margin-top: 5em;
    padding: 0 8px;
    font-size: 1.8px;
  }
`;

export const Span = styled.span`
  font-weight: 30 !important;
`;

export const FiveStar = styled.span`
  padding: 4px 14px;
  border-left: 1px solid #ccc;
  @media only screen and (max-width: ${deviceBreakpoints.sLaptop}px) {
    padding: 0 14px 0 0;
    width: 50%;
  }
  @media only screen and (max-width: ${deviceBreakpoints.phone}px) {
    padding: 0 10px;
    width: 48%;
    font-size: 12px;
  }
  @media only screen and (max-width: ${deviceBreakpoints.sPhone}px) {
    padding: 4px 4px;
    width: 50%;
  }
`;

export const Images = styled.span`
  padding: 0 10px;
  width: 50%;
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    padding: 10px 5px;
    width: 55%;
  }
  @media only screen and (max-width: 750px) {
    padding: 5px 5px;
    width: 50%;
  }
  @media only screen and (max-width: ${deviceBreakpoints.phone}px) {
    padding: 0 10px;
    width: 52%;
  }
  @media only screen and (max-width: ${deviceBreakpoints.sPhone}px) {
    width: 50%;
    padding-top: 12px;
  }
`;
export const Star = styled.img`
  width: 100%;

  @media only screen and (max-width: ${deviceBreakpoints.phone}px) {
    width: 90%;
  }
  @media only screen and (max-width: ${deviceBreakpoints.sPhone}px) {
    width: 100%;
  }
`;
export const Linked = styled.span`
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: underline;
    color: black;
  }
`;
