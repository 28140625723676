import styled from 'styled-components';

export const SidePadding = styled.div`
  padding-left: 2.5rem;
`;
export const MobileBackupCard = styled.div`
  h6 {
    font-weight: bold;
    margin: 0;
  }
  p {
    margin: 0;
  }
`;
export const WithBackupCard = styled.div`
  border: 2px solid #e3e6e4;
  padding: 20px 15px;
  border-radius: 8px;
  background: white;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  flex-flow: nowrap row;
  i {
    color: ${(props) => props.theme.primaryDarkColor};
    cursor: pointer;
  }
  @media (max-width: 950px) {
    justify-content: space-between;
    flex-flow: nowrap column;
  }
`;
