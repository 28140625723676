import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { get } from 'lodash';
import { DiskUsageData } from '../util';
import { H5, H6, P } from 'components/reusable-css';
import { Trans, useTranslation } from 'react-i18next';

const CustomPieChart = ({ data, usedGB }) => {
  const { t } = useTranslation();
  const [isUse, setIsUse] = useState(false);

  const innerDonut = {
    cutoutPercentage: 93,
    tooltips: false,
    rotation: 3.5,
  };
  const outterDonut = {
    cutoutPercentage: 85,
    tooltips: false,
    rotation: 3.5,
    events: ['mouseout', 'mousemove'],
    onHover: (e) => {
      if (e.type === 'mouseout') {
        setIsUse(false);
      } else {
        setIsUse(true);
      }
    },
    legend: {
      display: true,
      onClick: () => {},
    },
  };
  let availGB = get(data, 'vs_resource_statistics[0].disk_usage_current[0].disk_avail_gb');
  let totalGB = get(data, 'vs_resource_statistics[0].disk_usage_current[0].disk_size_gb');

  return (
    <React.Fragment>
      <div style={{ ...(usedGB == 0 && { opacity: 0.3, pointerEvents: 'none' }) }} className="position-relative">
        <div className="position-absolute" style={{ zIndex: 1200 }}>
          <Doughnut options={outterDonut} data={DiskUsageData(usedGB, availGB)} height={300} width={300} />
        </div>

        <div className="position-absolute" style={{ top: '36px', left: '36px' }}>
          <Doughnut options={innerDonut} data={DiskUsageData(usedGB, availGB)} height={230} width={230} />
        </div>
        <div className="position-absolute" style={{ top: '110px', left: '52px', width: '14em' }}>
          <H6 className="font-weight-bold text-center mb-0">
            {t(isUse ? 'vs/instance.string61' : 'vs/instance.string65')}
          </H6>
          <H5 className="font-weight-bold text-center my-2">
            <Trans i18nKey={'vs/instance.string66'} values={{ data: isUse ? usedGB : availGB }} />
          </H5>
          <P className="font-weight-bold text-center" style={{ width: '13.5em' }}>
            <Trans i18nKey={'vs/instance.string67'} values={{ used: usedGB, total: totalGB }} />
          </P>
        </div>
      </div>
    </React.Fragment>
  );
};

export { CustomPieChart };
