export const data = [
  {
    id: 1,
    lang: 'vs/recoverable.string3',
  },
  {
    id: 2,
    lang: 'vs/recoverable.string4',
  },
  {
    id: 3,
    lang: 'vs/recoverable.string5',
  },
];
