import React, { useEffect } from 'react';
import { BgLogin, LoginBox, LoginForm, Logo, LoginContainer } from './VsThankYou.style';
import CustomButton from 'components/common/custom-button';
import { H1, P } from 'components/reusable-css';
import { Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { navLinks } from 'utils/nav-links-util';
import { useMutation } from 'react-query';
import { POST_THANKYOU } from './queries';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { getLocalStorageValues } from 'constants/local-storage';

const VsThankYou = () => {
  const { t } = useTranslation();
  const { promoCode } = getLocalStorageValues();
  const history = useHistory();
  const { mutate } = useMutation(POST_THANKYOU);
  let params = queryString.parse(history?.location?.search);

  useEffect(async () => {
    localStorage.removeItem('vs_cart');
    await mutate({
      paypaltoken: params.token,
      paypalpayerid: params.PayerID || '',
    });
  }, []);

  return (
    <BgLogin>
      <LoginContainer>
        <LoginBox>
          <LoginForm>
            <Logo>
              <Icon name="check circle" />
            </Logo>
            <H1>{t('public/checkout/vs/thankyou.string1')}</H1>
            <P>{t('public/checkout/vs/thankyou.string2')}</P>
            <CustomButton
              onClick={() => history.replace(navLinks.public_checkout_vs.link + promoCode)}
              buttonStyles={{ width: '100%' }}
              type="submit"
              title={'public/checkout/vs/thankyou.string3'}
            />
          </LoginForm>
        </LoginBox>
      </LoginContainer>
    </BgLogin>
  );
};

export default VsThankYou;
