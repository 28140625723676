import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const REQUEST_PASSWORD_RESET = async (e) => {
  const res = await axios.post(BASE_URL + `/account/request_password_reset`, { logininfo: e.logininfo });
  return res.data;
};

export const PASSWORD_RESET = async (e) => {
  const res = await axios.post(BASE_URL + `/account/password_reset`, e);
  return res.data;
};
