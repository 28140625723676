import React from 'react';
import cPanelImage from 'assets/images/cpanel.svg';
import { Trans } from 'react-i18next';
import { NewVMStep3SC, StepDescription, Image, Description } from './NewVSStep3.style';
import CPanel from '../component/cPanel-card/CPanelCard';
import Heading from 'components/Heading/Heading';
import { P } from 'components/reusable-css';

const NewVSStep3 = ({ appsData, formikProp }) => {
  return (
    <NewVMStep3SC id="ST3">
      <Heading name="public/checkout/vs.step3_string2">
        <StepDescription>
          <Image>
            <img src={cPanelImage} alt="cpanel logo" width="90%" />
          </Image>
          <Description>
            <P>
              <Trans
                i18nKey="public/checkout/vs.step3_string3"
                values={{
                  currency: 'USD',
                  currency_symbol: '$',
                }}
              >
                <a href={appsData?.apps[0]?.infopage} rel="noreferrer" target="_blank" />
                <span style={{ textTransform: 'uppercase' }} />
              </Trans>
            </P>
          </Description>
        </StepDescription>
        <CPanel appData={appsData} formikProp={formikProp} />
      </Heading>
    </NewVMStep3SC>
  );
};
export default NewVSStep3;
