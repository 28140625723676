import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as Styles from './Header.style';
import LanguageDropdown from 'components/language-dropdown/LanguageDropdown';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { navLinks } from 'utils/nav-links-util';

const Header = ({ isAppDrawerOpen, onAppDrawerToggle }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  let history = useHistory();
  const [language, setLanguage] = useState(i18n.language);
  const noOfVS = useSelector((state) => state.vsCart.vs_deploy);
  const handleLanguage = async (value) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  const hanldeNavigation = () => history.replace(navLinks.public_checkout_vs_summary.link);

  return (
    <Styles.HeaderSC>
      <Styles.AppDrawerToggleButton onClick={onAppDrawerToggle}>
        <Icon name={isAppDrawerOpen ? 'close' : 'bars'} />
      </Styles.AppDrawerToggleButton>
      <Styles.RightHeader onClick={() => (isAppDrawerOpen ? onAppDrawerToggle() : {})}>
        <Styles.SocialMediaLink target="_blank " href="https://www.serverpoint.com/en/contact-us/">
          <i className="fa fa-comments" />
          <div>{t('header.string6')}</div>
        </Styles.SocialMediaLink>
        <Styles.LanguageSwitcher>
          <LanguageDropdown language={language} handleLanguage={(e) => handleLanguage(e)} isHeader />
          <Icon name="cart" onClick={hanldeNavigation} className="cart-icon" />
          <div className="noOfvs" onClick={hanldeNavigation}>
            {noOfVS.length}
          </div>
        </Styles.LanguageSwitcher>
      </Styles.RightHeader>
    </Styles.HeaderSC>
  );
};
export default Header;
