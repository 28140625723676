import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { navLinks } from './nav-links-util';
import { BASE_URL } from 'constants/env';
import { UNAUTHORIZE_USER } from 'components/user-management/queries';
import { useDispatch } from 'react-redux';
import { updateUserAccount, updateUserUi } from 'store/actions/user-details';
import { updatePricingDetails } from 'store/actions/vm-deployment';

const useAuthentication = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authenticate = async (user, password) => {
    try {
      const token = btoa(`${user}:${btoa(password)}`);
      const response = await axios.get(BASE_URL + `/authenticate`, {
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
      localStorage.setItem(
        'userName',
        JSON.stringify({
          userName: response.data.username,
          email: response.data.username,
          token: response.data.token,
        }),
      );
      history.replace(navLinks.see_current_tickets.link);
    } catch (error) {
      history.replace('/');
    }
  };

  const logout = async (queryClient) => {
    try {
      await UNAUTHORIZE_USER();
      dispatch(updateUserAccount({}));
      dispatch(updatePricingDetails({}));
      dispatch(updateUserUi({}));
      localStorage.removeItem('userName');
      if (queryClient) queryClient.clear();
    } catch (error) {
      console.log('error::', error);
    }
  };

  return { authenticate, logout };
};

export default useAuthentication;
