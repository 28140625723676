import { h4Styles, h6Styles, pStyles } from 'components/reusable-css';
import styled from 'styled-components';

export const UserManagementSC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

//-------------------------------Add New User------------------------------------//

export const AddNewUserSC = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  color: black;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  padding-bottom: 2em;
`;

export const AddNewUser = styled.div`
  margin: 5em 40px 1em 40px;
  @media only screen and (max-width: 786px) {
    margin: 30px 20px 1em 0;
  }
`;

export const PanelHeading = styled.div`
  display: flex;
  align-items: flex-start;
  width: 87%;
  h4 {
    font-size: ${(props) => props.theme.default?.h4};
    ${(props) => h4Styles(props.theme.resolutions)};
    font-weight: bolder;
    padding-top: 8px;
    span {
      font-weight: bolder;
      margin-right: 0.1rem;
      font-size: ${(props) => props.theme.default?.h4};
      ${(props) => h4Styles(props.theme.resolutions)};
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  align-items: flex-start;
  width: 87%;
  i {
    font-size: 40px;
  }
  h2 {
    font-weight: 900;
    padding-top: 10px;
  }
`;

export const AddUsers = styled.div`
  margin: 20px 0 0 3.7em;
  p {
    padding-bottom: 10px;
  }
  @media only screen and (max-width: 786px) {
    margin: 20px 0 0 3.5em;
    p {
      padding-bottom: 0;
    }
  }
  @media only screen and (max-width: 425px) {
    margin: 20px 0 0 2.6em;
  }
`;

export const FormDiv = styled.div`
  padding-bottom: 2.4em;
`;

//-----------------------------------Current User-------------------------------------//

export const CurrentUser = styled.div`
  margin: 2em 20px 3em 40px;
  @media only screen and (max-width: 786px) {
    margin: 10px 20px 5em 0;
  }
  @media only screen and (max-width: 425px) {
    margin: 10px 0px 5em 0;
  }
`;

export const CurrentUsers = styled.div`
  margin: 30px 0 0 4em;
  @media only screen and (max-width: 786px) {
    margin: 30px 0 0 3.5em;
  }
`;
export const Cards = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const Card = styled.div`
  border: 1.5px solid #d9d9d9;
  border-radius: 5px;
  background: #e4e4e4;
  padding: 12px;
  margin: 0 8px 8px 0;
  display: flex;
  flex-wrap: nowrap;
`;
export const Picture = styled.div`
  margin-right: 15px;
`;

export const Description = styled.div`
  display: flex;
  flex-flow: column nowrap;
  h6 {
    font-weight: 700;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 5px;
    font-weight: 500;
  }
`;
export const DescriptionIcon = styled.div`
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
  div {
    margin-right: 4px;
  }
  i {
    color: ${(props) => props.theme.primaryDarkColor};
    font-size: 11px;
    padding-top: 3px;
  }
  span {
    color: ${(props) => props.theme.primaryDarkColor};
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
    font-weight: 500;
  }
`;

//-------------------------------Add New Role-------------------------------------//

export const AddNewRoleSC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.pageBackgroundColor};
  width: 100%;
  color: black;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  padding-bottom: 2em;
`;

export const AddNewRole = styled.div`
  margin: 1em 20px 1em 40px;
  @media only screen and (max-width: 786px) {
    margin: 30px 10px 1em 0;
  }
`;

export const Para = styled.p`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  padding-bottom: 10px;
`;

export const AddRoles = styled.div`
  margin: 20px 0 0 4em;
  @media only screen and (max-width: 786px) {
    margin: 20px 0 0 3.5em;
  }
  @media only screen and (max-width: 425px) {
    margin: 20px 0 0 2.6em;
  }
  .clear-box {
    cursor: pointer;
    text-decoration: underline;
  }
  h6 {
    padding-top: 2.9rem;
    font-weight: bold;
    text-align: end;
    padding-right: 12px;
    @media only screen and (max-width: 959px) {
      text-align: start;
    }
  }
  .buttonStyle {
    margintop: 27px;
    padding: 9px 30px;
    @media only screen and (max-width: 430px) {
      margintop: 5px;
    }
  }
`;
export const Roles = styled.div`
  margin: 20px 0 0 3.5em;
  @media only screen and (max-width: 786px) {
    margin: 20px 0 0 3.5em;
  }
  @media only screen and (max-width: 425px) {
    margin: 5px 0 0 3em;
  }
`;

//--------------------------------------Current Role------------------------//

export const ErrorMsgContainer = styled.div`
  margin-top: 3em;
  margin-right: 1em;
`;

export const ClearAllBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

//--------------------------------------Delete Card------------------------//

export const Flex = styled.div`
  display: flex;
`;
export const DeleteCards = styled.div`
  color: black;
  width: 40em;
  border: 1px solid transparent;
  background: white;
  border-radius: 15px;
  padding: 20px 10px 10px 5px;
  i {
    font-size: 16px;
    cursor: pointer;
  }
  @media only screen and (max-width: 600px) {
    width: 30em;
  }
  @media only screen and (max-width: 450px) {
    width: 25em;
  }
  @media only screen and (max-width: 370px) {
    width: 20em;
  }
`;

export const DeleteContent = styled.div`
  padding: 0px 20px;
  padding-top: 20px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0 10px 0;
`;

//--------------------------------------Edit User------------------------//

export const UserMain = styled.div`
  color: black;
  width: 100%;
  background: #f2f2f2;
  overflow-y: scroll;
  height: 100%;
  border: 1px solid transparent;
  i {
    font-size: 16px;
    cursor: pointer;
  }
`;
export const CrossIcon = styled.div`
  align-self: center;
  @media only screen and (max-width: 550px) {
    align-self: unset;
  }
`;
export const PanelUserHeader = styled.div`
  background: white;
  padding: 1.5rem 1rem 2rem 2rem;
  display: flex;
  align-item: center;
  justify-content: space-between;
  position: relative;
}
`;
export const EditUserContent = styled.div`
  padding: 1.5rem 1rem 3rem 3rem;
  @media only screen and (max-width: 550px) {
    padding: 1.5rem 1rem 2rem 1.5rem;
  }
`;
export const HighlightedHeading = styled.span`
  font-size: 30px !important;
  font-weight: 900 !important;
  margin: 0 5px !important;
  color: ${(props) => props.theme.primaryDarkColor};
  @media only screen and (max-width: 786px) {
    font-size: 25px !important;
  }
  @media only screen and (max-width: 550px) {
    font-size: 18px !important;
  }
`;
//--------------------------------------Edit Role------------------------//
export const EditRoleContent = styled.div`
  padding: 2.5rem;
  @media only screen and (max-width: 550px) {
    padding: 2.5rem 1.5rem;
  }
`;

export const RolesLabel = styled.h6`
  padding: 0.7rem 0;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  font-weight: bold;
`;

export const RolesFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.panelRole ? '49%' : '50%')};
  @media only screen and (max-width: 850px) {
    width: ${(props) => (props.panelRole ? '100%' : '50%')};
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`;

//---------------------UserManagement---------------//
export const CommentCon = styled.div``;
