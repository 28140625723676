import React from 'react';
import PropTypes from 'prop-types';

const ConditionalFragment = ({ condition, children }) => {
  return <React.Fragment>{condition ? <React.Fragment>{children}</React.Fragment> : null}</React.Fragment>;
};
ConditionalFragment.propTypes = {
  condition: PropTypes.any.isRequired,
  children: PropTypes.any,
};
export default ConditionalFragment;
