import React, { useState } from 'react';
import './Dropdown.css';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Dropdown } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { useTranslation } from 'react-i18next';
import ClickNHold from 'react-click-n-hold';
import { Delete } from 'assets/css/delete-animation';
import Tooltip from 'components/Tooltip';

const Dropdowns = ({
  dropdownItems,
  setOpenConfigurationPanel,
  RefreshServer,
  handleConsoleOpen,
  setId,
  id,
  deletedIds,
  opacity,
  enableBackup,
  inProgress,
  toggleStats,
  upgradeOpen,
  resetPasswordOpen,
  data,
  handleActionsOpen,
}) => {
  const { t } = useTranslation();
  const [anim, setAnim] = useState();
  const [rowID, setRowId] = useState();
  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        onToggle={() => {
          setRowId(id);
          setShow(!show);
        }}
        show={!inProgress && id === rowID && show}
      >
        <Dropdown.Toggle id="dropdown-basic">
          <MoreHorizIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ borderRadius: '10px', boxShadow: 'unset', zIndex: 99999 }} className="menu">
          {dropdownItems(
            setOpenConfigurationPanel,
            RefreshServer,
            handleConsoleOpen,
            '',
            '',
            enableBackup,
            {
              toggleStats,
              upgradeOpen,
              resetPasswordOpen,
            },
            data,
            handleActionsOpen,
          ).map((dropDownItem) => (
            <React.Fragment key={dropDownItem.id}>
              {dropDownItem.name != 'delete_VS' ? (
                <Tooltip title={t(dropDownItem.tooltip_lang)} placement="left">
                  <Dropdown.Item
                    style={{ paddingTop: '7px', paddingBottom: '7px', margin: '5px 0', ...opacity(dropDownItem) }}
                    className="icon d-flex"
                    onClick={dropDownItem.onClick}
                  >
                    <Icon className={dropDownItem.icon} />
                    <p>{t(dropDownItem.lang)}</p>
                  </Dropdown.Item>
                </Tooltip>
              ) : (
                <ClickNHold
                  time={2}
                  onStart={() => setAnim(true)}
                  onEnd={() => setAnim(false)}
                  onClickNHold={() => {
                    dropDownItem.onClick();
                    setId([...deletedIds, id]);
                  }}
                >
                  <Tooltip title={t('vs/instance.string54')} placement="left">
                    <Delete anim={anim} className="icon d-flex" style={{ padding: '7px 21px' }}>
                      <Icon className={dropDownItem.icon} />
                      <p>{t(dropDownItem.lang)}</p>
                    </Delete>
                  </Tooltip>
                </ClickNHold>
              )}
            </React.Fragment>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export { Dropdowns };
