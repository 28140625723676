import React from 'react';
import ErrorMessage from 'components/error-message';
import PropTypes from 'prop-types';
import ConditionalFragment from 'components/conditional-fragment';
import { get } from 'lodash';

const FormikErrorMessage = ({ formikProp, value, isText, innerValue, textError }) => {
  const text = Array.isArray(value) ? value : [value];

  const findFirst = text.find((item) => formikProp.errors[item] && formikProp.touched[item]);

  return (
    <div style={{ height: 37 }}>
      <ConditionalFragment condition={formikProp.errors[findFirst] && formikProp.touched[findFirst]}>
        <ErrorMessage
          text={
            isText
              ? textError
              : innerValue && get(formikProp, `errors.${findFirst}`)
              ? get(formikProp, `errors.${findFirst}.${innerValue}`)
              : get(formikProp, `errors.${findFirst}`)
          }
        />
      </ConditionalFragment>
    </div>
  );
};
FormikErrorMessage.propTypes = {
  formikProp: PropTypes.object,
  value: PropTypes.any,
  isText: PropTypes.bool,
};
export default FormikErrorMessage;
