/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { PassSC, Pass, ChangePasswords, FormDiv } from './EditEmailInfo.style';
import { Trans, useTranslation } from 'react-i18next';
import { Highlight } from 'components/vm-deployment/components';
import { edit_Email_Contact_Form } from 'constants/account-profile';
import { Grid } from '@material-ui/core';
import Input from 'components/input';
import { Formik, Form } from 'formik';
import { initialValues, validationSchema } from 'constants/account-profile';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { CHANGE_EMAIL } from '../queries';
import CustomButton from 'components/common/custom-button';
import { CustomHook } from 'utils/custom-hook';
import 'components/input/input.css';
import ConditionalFragment from 'components/conditional-fragment';
import { Heading } from 'components/user-management/Heading';
import { P } from 'components/reusable-css';
import { Label } from 'assets/css/common-styles';

const EditEmail = ({ width, rights }) => {
  const { t } = useTranslation();
  const { mutationProps } = CustomHook();

  const { data: emails, refetch: emailRefetch } = useQuery('/account/profile/email_contact');
  const { mutate: ChangeEmail, isLoading: changeEmailLoading } = useMutation(CHANGE_EMAIL);

  const handleEmailSubmit = async (values) => {
    await ChangeEmail(values, mutationProps(emailRefetch));
  };
  return (
    <Formik
      initialValues={initialValues(emails)}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => handleEmailSubmit(values, resetForm)}
      enableReinitialize={true}
    >
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <Form>
          <PassSC>
            <Pass>
              <Heading>
                <Trans i18nKey="account/profile.string19" components={{ highlight: <Highlight /> }} />
              </Heading>
              <ChangePasswords>
                <P>{t('account/profile.string20')}</P>
                <P>{t('account/profile.string21')}</P>
                <FormDiv>
                  {edit_Email_Contact_Form.map((item) => (
                    <Grid key={item.name} container>
                      <Grid xs={12} sm={4} md={3} lg={3} xl={2}>
                        <Label>{t(item.lang)}</Label>
                      </Grid>
                      <Grid xs={12} sm={10} md={6} lg={5} xl={3}>
                        <Input
                          styling={width}
                          className="custom_inputs"
                          type={item.type}
                          name={item.name}
                          value={values[item.name]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors[item.name]}
                          touched={touched[item.name]}
                          placeholderClass
                        />
                      </Grid>
                    </Grid>
                  ))}
                </FormDiv>
                <ConditionalFragment condition={rights?.profilemodify}>
                  <CustomButton type="submit" buttonStyles={{ padding: ' 9px 55px' }} isLoading={changeEmailLoading} />
                </ConditionalFragment>
              </ChangePasswords>
            </Pass>
          </PassSC>
        </Form>
      )}
    </Formik>
  );
};
export default EditEmail;
