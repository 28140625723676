import USAFlag from 'assets/images/flag-usa.svg';
import NetherlandFlag from 'assets/images/flag-netherlands.svg';
import SingaporeFlag from 'assets/images/flag-singapore.svg';

export const getRegionImage = (name) => {
  switch (name) {
    case 'united states':
      return USAFlag;
    case 'netherlands':
      return NetherlandFlag;
    case 'singapore':
      return SingaporeFlag;
    default:
      return '';
  }
};
