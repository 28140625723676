import React from 'react';
import Affiliate from './Affiliate';
import { useQuery } from 'react-query';
import Loading from 'components/common/loading/Loading';

const AffiliateContainer = ({ style }) => {
  const { data: bannerData, isLoading } = useQuery('/affiliate/banners');
  const { data: affiliate, isLoading: loading } = useQuery('/affiliate');
  const { data: affiliateTransaction } = useQuery('/affiliate/transactions');

  if (isLoading || loading) {
    return <Loading gap={200} />;
  }
  return (
    <Affiliate
      style={style}
      bannerData={bannerData}
      affiliate={affiliate}
      affiliateTransaction={affiliateTransaction}
    />
  );
};

export default AffiliateContainer;
