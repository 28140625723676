/* eslint-disable no-unused-vars */
import React from 'react';
import CPanel from 'components/vm-deployment/cPanel-card';
import cPanelImage from 'assets/images/cpanel.svg';
import { useTranslation, Trans } from 'react-i18next';
import { NewVMStep4SC, StepDescription, Image, Description, Paragraph } from './NewVMStep4.style';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import { updateCPanelOs, updatePricing } from 'store/actions/vm-deployment';
import { GreyOut } from '../new-vm-step-3/NewVMStep3.style';
import { Highlight, StepLabel, StepLabelMobile } from 'components/common';
import Heading from 'components/Heading/Heading';

const NewVMStep4 = ({ withPricing, formikProp, vsTemplate }) => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  const { values } = formikProp;
  const dispatch = useDispatch();
  const updatePrice = (item) => {
    dispatch(updatePricing(_get(item, 'pricing.price_monthly_usd', 0), 'cpanel'));
    dispatch(updateCPanelOs(item.requiredos));
  };
  const updateState = () => {
    dispatch(updateCPanelOs(''));
    dispatch(updatePricing(0, 'cpanel'));
  };

  return (
    <NewVMStep4SC id="ST4">
      <GreyOut isGrey={_get(values, 'template', '')}>
        {/* <StepLabel id="ST4" stepCount={t('vs/instance/create.step4_string1')}>
          <Trans i18nKey="vs/instance/create.step4_string2" components={{ highlight: <Highlight /> }} />
        </StepLabel>
        <StepLabelMobile stepCount={t('vs/instance/create.step4_string1')}>
          <Trans i18nKey="vs/instance/create.step4_string2" components={{ highlight: <Highlight /> }} />
        </StepLabelMobile> */}
        <Heading name={'vs/instance/create.step4_string2'}>
          {withPricing.map((item) => (
            <React.Fragment key={item.id}>
              <StepDescription>
                <Image>
                  <img src={cPanelImage} alt="cpanel logo" width="90%" />
                </Image>
                <Description>
                  <Paragraph>
                    <Trans
                      i18nKey="vs/instance/create.step4_string3"
                      values={{
                        price: item.price,
                        currency: userAccount.account_currency,
                        currency_symbol: userAccount.account_currency_symbol,
                      }}
                    >
                      <a href={item.infopage} rel="noreferrer" target="_blank" />
                      <span style={{ textTransform: 'uppercase' }} />
                    </Trans>
                  </Paragraph>
                </Description>
              </StepDescription>
              <CPanel
                type={'vs'}
                item={item}
                updateState={updateState}
                formikProp={formikProp}
                updatePrice={updatePrice}
                vsTemplate={_get(vsTemplate, 'vs_saved_templates', [])}
              />
            </React.Fragment>
          ))}
        </Heading>
      </GreyOut>
    </NewVMStep4SC>
  );
};
export default NewVMStep4;
