import { h2Styles, h5Styles, h6Styles } from 'components/reusable-css';
import styled from 'styled-components';
import { deviceBreakpoints } from 'variables';

export const Flex = styled.div`
  display: flex;
`;

export const Heading = styled.h2`
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
`;

export const Label = styled.h6`
  padding-top: 2.8rem;
  font-weight: bold;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;

export const Margin = styled.h5`
  padding-top: 33px;
  font-size: ${(props) => props.theme.default?.h5};
  ${(props) => h5Styles(props.theme.resolutions)};
`;

export const UpperCaseWord = styled.span`
  text-transform: uppercase;
  margin: 0 3px;
`;

export const StepDescription = styled.div`
  margin-top: 55px;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    margin-top: 2em;
  }
`;
