import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import * as Styles from './BackupPlanCard.style';
import { get } from 'lodash';
import ConditionalFragment from 'components/conditional-fragment';
import CustomButton from 'components/common/custom-button';
import { backupPlans } from 'constants/servers';
import { userRights } from 'store/selectors/user-details';
import { useSelector } from 'react-redux';
import { UpperCaseWord } from 'assets/css/common-styles';

export default function BackupPlanCard(props) {
  const {
    data,
    selected = false,
    onClick,
    calculatePrice,
    isUpgrade,
    upgradeClick,
    upgradeButtonLoad,
    backupPlan,
    billing_schedule,
  } = props;
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  const rights = useSelector(userRights);
  const priceOfBackup = () => {
    if (calculatePrice) {
      return calculatePrice(data);
    }
    return get(data, 'pricing.price', 0);
  };

  const pricePercentageOfBackup = () => get(data, 'pricing.price_percentage_of_virtual_server', 0);

  const planData = backupPlans[data.retention_days];

  return (
    <Styles.ServerSizeCardSC data-testid="backup-plan-card" selected={selected} onClick={() => onClick(data)}>
      <Styles.Tag showTag={data.retention_days == 30}>
        {data.retention_days == 30 ? t('vs/instance/create.step5_string5') : null}
      </Styles.Tag>
      <Styles.NameContainer>
        <Styles.Name>{t(planData.title)}</Styles.Name>
      </Styles.NameContainer>
      <Styles.Price>
        <Styles.PerMonth>
          <Trans
            i18nKey="vs/instance/create.step5_string6"
            values={{
              price: priceOfBackup(),
              currency: userAccount.account_currency,
              currency_symbol: userAccount.account_currency_symbol,
            }}
            components={{ price: <Styles.PriceUnit />, uppercase: <UpperCaseWord /> }}
          />
        </Styles.PerMonth>
      </Styles.Price>
      <Styles.Features>
        {planData.features.map((item, key) => (
          <Styles.Feature key={key}>
            <Icon name="check" />
            <Trans i18nKey={item} values={{ days: data.retention_days, price_percentage: pricePercentageOfBackup() }} />
          </Styles.Feature>
        ))}
        <ConditionalFragment condition={isUpgrade}>
          <div className="pt-5">
            <Styles.ManageButton>
              {!(selected && data.id === 'backupplan0') && (
                <CustomButton
                  title={data.retention_days == 0 ? 'vs/instance.string142' : 'vs/instance.string143'}
                  onClick={upgradeClick}
                  isLoading={upgradeButtonLoad}
                  disabled={
                    billing_schedule ||
                    (selected && isUpgrade) ||
                    ((data.id === 'backupplan1' || data.id === 'backupplan2') &&
                      backupPlan === 'backupplan0' &&
                      rights.vsbackupupgrade === 0) ||
                    (data.id === 'backupplan0' && backupPlan === 'backupplan1' && rights.vsbackupdowngrade === 0) ||
                    ((data.id === 'backupplan0' || data.id === 'backupplan1') &&
                      backupPlan === 'backupplan2' &&
                      rights.vsbackupdowngrade === 0) ||
                    (data.id === 'backupplan2' && backupPlan === 'backupplan1' && rights.vsbackupupgrade === 0) ||
                    (rights.vsbackupdowngrade === 0 && rights.vsbackupupgrade === 0)
                  }
                />
              )}
            </Styles.ManageButton>
          </div>
        </ConditionalFragment>
      </Styles.Features>

      {selected &&
        (isUpgrade ? (
          <Styles.ManageSelectedTag>
            <Icon name="check" />
            {backupPlan === 'backupplan0' ? t('vs/instance.string140') : t('vs/instance.string141')}
          </Styles.ManageSelectedTag>
        ) : (
          <Styles.SelectedTag>
            <Icon name="check" />
            {t('vs/instance/create.step5_string20')}
          </Styles.SelectedTag>
        ))}
    </Styles.ServerSizeCardSC>
  );
}
