import React from 'react';
import * as Styles from 'pages/member/wh/WH.style';
import { StepLabel, Highlight } from 'components/vm-deployment/components';
import { useTranslation, Trans } from 'react-i18next';
import Checkbox from 'components/common/radio-button';
import { Grid } from '@material-ui/core';
import { getAppImage } from 'constants/wh';
import { get } from 'lodash';
import FormikErrorMessage from 'components/formik-error-message';
import { H6 } from 'components/reusable-css';

const WHStep3 = ({ whAppData, formikProp }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = formikProp;

  return (
    <Styles.WHStep3SC>
      <StepLabel id="ST3" stepCount={t('Step 3')}>
        <Trans i18nKey="wh_step3_heading" components={{ highlight: <Highlight spaceLeft /> }} />
      </StepLabel>
      <Styles.StepDescription>{t('wh_step3_paragraph')}</Styles.StepDescription>
      <Styles.InlineFlex>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4} md={3} lg={4} xl={2}>
            <Styles.FlexContainerWidth onClick={() => setFieldValue('app', 'no_app')}>
              <Checkbox
                checkbox={values.app}
                setCheckedValue={() => setFieldValue('app', 'no_app')}
                data={{
                  id: 'no_app',
                  title_en: 'no_app',
                }}
              />
              <H6 className="flexData">No Application</H6>
            </Styles.FlexContainerWidth>
          </Grid>
          {get(whAppData, 'wh_apps', [])
            .filter((val) => +val.active === 1 && +val.create_ui === 1)
            .map((val) => (
              <Grid item key={val?.id} xs={12} sm={4} md={3} lg={4} xl={2}>
                <Styles.FlexContainerWidth key={val?.id} onClick={() => setFieldValue('app', val.id)}>
                  <Checkbox checkbox={values.app} setCheckedValue={() => setFieldValue('app', val.id)} data={val} />
                  <div>
                    <img src={getAppImage(val?.id)} width={25} height={25} alt="OS Image" />
                  </div>
                  <H6 className="flexData">{val?.title_en}</H6>
                </Styles.FlexContainerWidth>
              </Grid>
            ))}
        </Grid>
      </Styles.InlineFlex>
      <FormikErrorMessage formikProp={formikProp} value="app" />
    </Styles.WHStep3SC>
  );
};
export default WHStep3;
