import React from 'react';
import * as Styles from 'pages/member/new-domain/new-domain-step-1/DomainStep1.style';
import { useTranslation } from 'react-i18next';
import { H3, P } from 'components/reusable-css';
import CustomButton from 'components/common/custom-button';
import Input from 'components/input';
import { Form } from 'formik';
import { Icon } from 'semantic-ui-react';
import { useMediaQuery } from '@material-ui/core';

const NewDomain = ({ formikProp, isLoading, isSuccess, price }) => {
  const { t } = useTranslation();
  const { values, errors, handleChange, touched, handleBlur } = formikProp;
  const isLaptop = useMediaQuery('(max-width:1366px)');
  return (
    <Form>
      <div className="d-flex flex-column">
        <Styles.DomainContainer>
          <Input
            placeholderClass
            value={values['new_domain']}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors['new_domain']}
            touched={touched['new_domain']}
            styling={{ minWidth: '31.8em' }}
            type="text"
            name={'new_domain'}
          />
          <CustomButton
            isLoading={isLoading}
            buttonStyles={{
              marginLeft: '10px',
              height: '48px',
              padding: '0rem 2em',
              marginTop: isLaptop ? '1.8em' : '1.69em',
            }}
            type="submit"
            title={t('search')}
          />
        </Styles.DomainContainer>

        {isSuccess && (
          <Styles.IsDomainAvailable>
            <div className="d-flex">
              <Icon name="check" />
              <P>Domain is available! Just...</P>
            </div>
            <H3>${price}/Year</H3>
          </Styles.IsDomainAvailable>
        )}
      </div>
    </Form>
  );
};

export default NewDomain;
