export let validationsTooltip = (value) => {
  return !(value.length > 7 && /\p{Lu}/u.test(value) && /\d+/g.test(value) && /\W/g.test(value))
    ? `
        <div class="validationsContainer">
          <div class="signupValidations"> ${value.length > 7 ? '&#10004;' : '&#10060;'} </div>
          <div>at least 8 characters</div>
        </div>
        <div class="validationsContainer">
          <div class="signupValidations"> ${/\p{Lu}/u.test(value) ? '&#10004;' : '&#10060;'} </div>
          <div>at least one uppercase letter</div>
        </div>
        <div class="validationsContainer">
          <div class="signupValidations"> ${/\d+/g.test(value) ? '&#10004;' : '&#10060;'} </div>
          <div>at least one number</div>
        </div>
        <div class="validationsContainer">
          <div class="signupValidations"> ${/\W/g.test(value) ? '&#10004;' : '&#10060;'} </div>
          <div>at least 1 symbol (non character and non numerical)</div>
        </div>
      `
    : `<div class="validationsContainer">
      <div class="signupValidations"> ${value.length > 7 ? '&#10004;' : '&#10060;'} </div>
      <div>at least 8 characters</div>
    </div>
    <div class="validationsContainer">
      <div class="signupValidations"> ${/\p{Lu}/u.test(value) ? '&#10004;' : '&#10060;'} </div>
      <div>at least one uppercase letter</div>
    </div>
    <div class="validationsContainer">
      <div class="signupValidations"> ${/\d+/g.test(value) ? '&#10004;' : '&#10060;'} </div>
      <div>at least one number</div>
    </div>
    <div class="validationsContainer">
      <div class="signupValidations"> ${/\W/g.test(value) ? '&#10004;' : '&#10060;'} </div>
      <div>at least 1 symbol (non character and non numerical)</div>
    </div>
  `;

};

export let validation = (value) => value.length > 7 && /\p{Lu}/u.test(value) && /\d+/g.test(value) && /\W/g.test(value);
