import { DISK, RAM, CORE } from 'components/vm-deployment/constants';
import { getCurrentYear } from 'utils/current-year';

export const cardInitialValues = {
  country: 'United States',
  card_month: '1',
  card_year: getCurrentYear(),
};

export const sliders = {
  [RAM]: 512,
  [DISK]: 10,
  [CORE]: 1,
};
