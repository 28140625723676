import {
  UPDATE_BM_CPANEL_OS,
  UPDATE_BM_PRICING,
  BM_DEPLOYMENT,
} from 'store/types';

export const updateBM_Deployment = (payload) => (dispatch) => {
  dispatch({
    type: BM_DEPLOYMENT,
    payload,
  });
};


export const updateBMPricing = (payload, key) => (dispatch) => {
  dispatch({
    type: UPDATE_BM_PRICING,
    payload,
    key,
  });
};

export const updateBMCPanelOs = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_BM_CPANEL_OS,
    payload,
  });
};