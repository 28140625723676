import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import * as Styles from '../MobileSettingsPanel.styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    fontWeight: 900,
    fontFamily: 'Open Sans',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    fontFamily: 'Open Sans',
    fontWeight: 900,
  },
  container: {
    // height: '40px',
    background: '#f8f8f8',
  },
}));

export const SettingsTabs = ({ tabs, update, loading, initialTab }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(initialTab);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Styles.AccordionContainer>
        {tabs.map((item, index) => {
          const { Component } = item;
          return (
            <Accordion
              className={classes.container}
              expanded={item.id ? expanded == item.id : expanded === `panel${index + 1}`}
              onChange={handleChange(item.id || `panel${index + 1}`)}
              key={index}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography className={classes.heading}>{t(item.title_lang)}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                {item.Component ? useMemo(() => <Component loading={loading} />, update) : null}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Styles.AccordionContainer>
    </div>
  );
};

SettingsTabs.propTypes = {
  update: PropTypes.any,
  tabs: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  initialTab: PropTypes.string,
};
