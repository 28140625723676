import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const BILLING_TERMS = async (e) => {
  const { id, term } = e;
  const res = await axios.post(BASE_URL + `/billing/terms/${id}`, { term });
  return res.data;
};

export const BILLING_PAYMENT_TERMS = async (e) => {
  const { id, term } = e;
  const res = await axios.post(BASE_URL + `/billing/${id}/payment_term `, { term });
  return res.data;
};

export const MANAGE_VM = async (e) => {
  const res = await axios.get(BASE_URL + `/vs/instance`, { params: e.queryKey[1] });
  return res.data;
};

export const ADD_TAG_VM = async (e) => {
  const { type, tag, id } = e;
  const res = await axios.post(BASE_URL + `/${type || 'vs'}/tags/${id}`, { tag });
  return res.data;
};

export const DELETE_TAG_VM = async (e) => {
  const { type, tag, id } = e;
  const res = await axios.delete(BASE_URL + `/${type || 'vs'}/tags/${id}/${tag}`);
  return res.data;
};

export const LABEL_UPDATE = async (e) => {
  const { id, type, ...remaining } = e;
  const res = await axios.patch(BASE_URL + `/${type || 'vs'}/instance/${id}`, remaining);
  return res.data;
};

export const INSTANCE_ACTION = async (e) => {
  const { id, type, ...remaining } = e;
  const res = await axios.post(BASE_URL + `/${type || 'vs'}/instance/${id}/actions`, remaining);
  return res.data;
};

export const UPDATE_SERVER_SIZE = async (e) => {
  const { id, type, ...remaining } = e;
  const res = await axios.post(BASE_URL + `/${type || 'vs'}/instance/${id}/sizechange`, remaining);
  return res.data;
};

export const ADD_SERVER_IP = async (e) => {
  const { id, type, ...remaining } = e;
  const res = await axios.post(BASE_URL + `/${type || 'vs'}/instance/${id}/ipv4`, remaining);
  return res.data;
};

export const DELETE_SERVER_IP = async (e) => {
  const { id, type, ip, ...remaining } = e;
  const res = await axios.delete(BASE_URL + `/${type || 'vs'}/instance/${id}/ipv4/${ip}`, remaining);
  return res.data;
};

export const SET_REVERSE_DNS = async (e) => {
  const { id, type, ip, ...remaining } = e;
  const res = await axios.post(BASE_URL + `/${type || 'vs'}/instance/${id}/ipv4/${ip}/reverse`, remaining);
  return res.data;
};

export const DELETE_PERMANENT_BACKUP = async (e) => {
  const { id, type, backup, ...remaining } = e;
  const res = await axios.delete(BASE_URL + `/${type || 'vs'}/instance/${id}/backups/${backup}`, remaining);
  return res.data;
};

export const BACKUP_CHANGE = async (e) => {
  const { id, type, backups_plan, ...remaining } = e;
  const res = await axios.patch(BASE_URL + `/${type || 'vs'}/instance/${id}/backups`, { backups_plan, ...remaining });
  return res.data;
};

export const BACKUP_PATCH_UPDATE = async (e) => {
  const { id, type, backup, ...remaining } = e;
  const res = await axios.patch(BASE_URL + `/${type || 'vs'}/instance/${id}/backups/${backup}`, {
    ...remaining,
  });
  return res.data;
};

export const BACKUP_ACTIONS = async (e) => {
  const { id, type, backup, action, ...remaining } = e;
  const res = await axios.post(BASE_URL + `/${type || 'vs'}/instance/${id}/backups/${backup}`, {
    action,
    ...remaining,
  });
  return res.data;
};

export const PERMANENT_BACKUP = async (e) => {
  const { id, type, action, ...remaining } = e;
  const res = await axios.post(BASE_URL + `/${type || 'vs'}/instance/${id}/backups`, {
    action,
    ...remaining,
  });
  return res.data;
};

export const GET_STATS = async (e) => {
  // eslint-disable-next-line no-unused-vars
  const { id, type } = e?.queryKey[1];
  const res = await axios.get(BASE_URL + `/${type}/instance/${id}/stats`);
  return res.data;
};

export const UPDATE_PREFERENCES = async (e) => {
  const res = await axios.patch(BASE_URL + `/preferences`, e);
  return res.data;
};
