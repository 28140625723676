import { h5Styles, h6Styles, pStyles } from 'components/reusable-css';
import styled, { keyframes } from 'styled-components';
import { variables } from 'variables';
import BackgroundImage from 'assets/svg/bg-for-old-design_02.svg';

export const DefaultName = styled.h5`
  border-radius: 6px;
  padding: 6px 12px;
  background: #82bf50;
  color: white;
  font-size: ${(props) => props.theme.default?.h5};
  ${(props) => h5Styles(props.theme.resolutions)};
`;

export const DNSSC = styled.div`
  background: ${(props) => props.theme.pageBackgroundColor};
  padding: ${variables.pageContentPadding}px;
  padding-bottom: 2em;
  min-height: 100% !important;
  width: 100%;
  height: 100%;
  background-size: cover;
`;

export const StepDescription = styled.div`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  margin-top: 5px;
`;

export const HeadingWithButton = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isError ? 'flex-end' : 'space-between')};
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
  .button {
    @media (max-width: 960px) {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
  @media (max-width: 960px) {
    justify-content: flex-start;
  }
`;

export const Heading = styled.div`
  width: 80%;
  @media (max-width: 960px) {
    order: 2;
    width: 100%;
    margin-top: 1em;
  }
`;

export const ListsContainer = styled.div`
  margin: 2em 0;
`;

export const NotFound = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  border-radius: 10px;
  color: white;
  margin-top: 1em;
`;

export const NotFoundFlex = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const NotFoundContent = styled.div`
  padding: 0 1.5em;
  padding-bottom: 2em;
  h4 {
    margin-top: 1em;
    margin-bottom: 0.3em;
  }
  p {
    margin: 0;
  }
  .last {
    margin-top: 1.5em;
  }
  .flexCol {
    display: flex;
    align-items: center;
    margin-top: 0.1em;
    margin-right: 1em;
  }
  .flexRow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .circle {
    color: #ff7300;
    font-size: 8px;
    margin-top: -4px;
  }
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;

export const ImageContainer = styled.img`
  height: 300px;
  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
  }
`;

export const ListContainer = styled.div`
  pointer-events: ${(props) => (props.loading ? 'none' : 'auto')};
  opacity: ${(props) => (props.loading ? '0.5' : '1')};
  background: white;
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  margin-top: 15px;
  &:hover {
    background-color: #f9f9f9;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.3em 1em;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const FirstDiv = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    width: auto;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;

export const MobileFirstDiv = styled.div`
  display: none;
  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      margin-right: 10px;
      font-size: 10px;
      margin-top: 9px;
      color: ${(props) => props.theme.primaryDarkColor};
    }
    h5 {
      margin: 0;
    }
  }
`;

export const DomainName = styled.div`
  display: flex;
  i {
    margin-right: 10px;
    font-size: 10px;
    margin-top: 9px;
    color: ${(props) => props.theme.primaryDarkColor};
  }
  h5 {
    margin: 0;
  }
`;

export const SecondDiv = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1000px) {
    width: 30em;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;

export const MobileSecondDiv = styled.div`
  display: none;
  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
  }
`;

export const DropdownContainer = styled.div`
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #e9e9e9;
  color: #ff7300;
  cursor: pointer;
  background-color: transparent;
  box-shadow: none;
`;

export const IPContainer = styled.div`
  padding: 0 1em;
  display: flex;
  width: 50%;
  h6 {
    margin: 0;
    padding-top: 2px;
  }
  i {
    padding-right: 1.5em;
    color: ${(props) => props.theme.primaryDarkColor};
    cursor: pointer;
  }
  @media (max-width: 800px) {
    padding-left: 0;
    width: auto;
  }
`;

export const IP = styled.div`
  padding: 0 1em;
  display: flex;
  @media (max-width: 800px) {
    padding-left: 0;
  }
`;

export const StatusContainer = styled.div`
  padding: 0 1em;
  display: flex;
  align-items: center;
  width: 35%;
  @media (max-width: 800px) {
    padding-right: 0;
    width: auto;
  }
`;

export const Status = styled.div`
  color: white;
  background: ${(props) =>
    props.status == 'active'
      ? props.theme.serverSizeCard.tag.backgroundColor
      : props.theme.serverSizeCard.tag.stopBackgroundColor};
  border-radius: 5px;
  padding: 5px 8px;
  h6 {
    margin: 0;
    text-transform: capitalize;
  }
`;

export const AddDomainModalContainer = styled.div`
  color: black;
  border: 1px solid transparent;
  background: white;
  border-radius: 15px;
  padding: 20px 10px 10px 5px;
  i {
    font-size: 16px;
    cursor: pointer;
  }
`;

export const AddDomainModalContent = styled.div`
  padding: 0px 20px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  padding-top: 2em;
  padding-bottom: 1em;
  @media (max-width: 600px) {
    display: block;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
  h6 {
    width: 8em;
    margin-top: -10px;
    @media (max-width: 600px) {
      margin-bottom: -10px;
    }
  }
`;

export const ConfigurationPanel = styled.div`
  background: ${(props) => props.theme.panelBackground};
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CrossIcon = styled.div`
  i {
    color: ${(props) => props.theme.primaryDarkColor};
    cursor: pointer;
  }
`;

export const Header = styled.div`
  background: white;
  display: flex;
  padding: 2em 1.5em;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
`;

export const BottomWarning = styled.div`
  margin-top: 3em;
  margin-bottom: 1em;
`;

export const BottomWarningStatus = styled.div`
  padding: 1.8em 1.3em;
  background-image: url(${BackgroundImage});
  background-size: cover;
  border-radius: 10px;
  color: white;
  .lightbulb {
    margin-right: 8px;
    color: #ff7300;
    margin-top: -10px;
  }
  .circle {
    margin-right: 10px;
    color: #ff7300;
    margin-top: -3px;
    font-size: 8px;
  }
  h6 {
    margin: 0;
  }
  .flexCol {
    display: flex;
    align-items: center;
  }
  .margin {
    margin-top: 10px;
    margin-left: 1.6em;
    @media (max-width: 768px) {
      margin-left: 1.4em;
    }
  }
`;

const delete_animation = keyframes`
  from{
    background-size: 100%;
  }
  to {
    background-size: 0%;
  }
`;

export const DeleteAnimation = styled.p`
  width: 100%;
  cursor: pointer;
  display: flex;
  &:hover {
    background: ${(prop) =>
      prop.anim ? 'linear-gradient( white , white) rgb(255,215,235) no-repeat 100% 0' : 'rgba(0,0,0,.06)'};
  }
  mix-blend-mode: multiply;
  background-size: '100% 100%';
  animation-name: ${(prop) => (prop.anim ? delete_animation : '')};
  animation-duration: 2s;
  padding: 7px 21px;
`;

export const Step1 = styled.div`
  background: white;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;

export const Start = styled.div`
  padding: 10px 30px;
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1em 0;
`;

export const Option = styled.h6`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  padding: 10px 15px;
  margin: 6px 6px 6px 0;
  border-radius: 5px;
  cursor: pointer;
  width: 5.5em;
  text-align: center;
  background-color: ${(props) => (props.isShow == props.menuId ? '#FF7300' : '#ebebeb')};
  color: ${(props) => (props.isShow == props.menuId ? 'white' : 'black')};
  position: relative;
  .tricut-image {
    position: absolute;
    bottom: -1px;
    right: 42%;
    width: 15px;
    transform: rotate(180deg);
  }
`;

export const OptionContent = styled.div`
  margin: 2em 0;
`;

export const SaveContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.5em;
  @media (max-width: 960px) {
    flex-direction: column;
    button {
      margin-top: 1em !important;
    }
  }
`;

export const SaveContainerColumn = styled.div`
  margin-right: 1em;
  max-width: 100%;
  h6 {
    margin: 0;
    margin-bottom: 5px;
    @media (max-width: 960px) {
      margin-bottom: -10px;
      margin-top: 20px;
    }
  }
`;
export const SaveContainerbtnColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const Step2 = styled.div`
  padding: 2em 30px;
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const RecordTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  h6 {
    margin: 0;
  }
`;

export const RecordTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.3em;
  padding-top: 1em;
  h6 {
    margin: 0;
  }
`;

export const RecordCol1 = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  i {
    margin-right: 10px;
    font-size: 10px;
    color: ${(props) => props.theme.primaryDarkColor};
  }
  h5 {
    margin: 0;
    margin-top: -5px;
  }
`;

export const RecordCol2 = styled.div`
  width: 20%;
`;

export const RecordCol3 = styled.div`
  width: 30%;
`;

export const RecordCol4 = styled.div`
  width: 10%;
  display: flex;
  justify-content: flex-end;
`;

export const Record1 = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  padding-right: 5px;
  i {
    margin-right: 10px;
    font-size: 10px;
    color: ${(props) => props.theme.primaryDarkColor};
  }
  h5 {
    margin: 0;
    margin-top: -3px;
  }
`;

export const Record2 = styled.div`
  width: 15%;
  padding: 0 5px;
`;

export const Record3 = styled.div`
  width: 25%;
  padding: 0 5px;
`;

export const Record4 = styled.div`
  width: 10%;
  padding: 0 5px;
`;

export const Record5 = styled.div`
  width: 10%;
  display: flex;
  justify-content: flex-end;
  padding-left: 5px;
`;
