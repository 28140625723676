import React, { useState } from 'react';
import { CartLoginScreen, CartLoginForm, CartLoginFormWithEmail, CartAuthScreen } from '../Checkout.style';
import CustomButton from 'components/common/custom-button';
import { Trans, useTranslation } from 'react-i18next';
import { H6 } from 'components/reusable-css';
import Input from 'components/input';
import { useMediaQuery } from '@material-ui/core';
import Heading from 'components/Heading/Heading';
import { useQuery } from 'react-query';
import { LOG_IN } from 'components/vm-deployment/queries';
import { reactQueryConfig } from 'constants/react-query-config';
import { Message } from 'components/alert/Message';
import { getLocalStorageValues } from 'constants/local-storage';
import { CartLoginWithPin } from './CartLoginWithPin';
import { validationsTooltip } from 'utils/password-validation-tooltip';
import LoadingImage from 'assets/images/loading.svg';
import axios from 'axios';
import { navLinks } from 'utils/nav-links-util';

const CartLogin = ({ formik, handleLogout, isLoading }) => {
  const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formik;
  const { t } = useTranslation();
  const { remember_cart_login } = getLocalStorageValues();
  const matches = useMediaQuery('(max-width:800px)');
  let width = matches ? '97%' : '23rem';
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [close, setClose] = useState(false);
  const [backupNumber, setBackupNumber] = useState(0);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const { isLoading: loginIsLoading } = useQuery(['LOG_IN', { userName: username, password: btoa(password) }], LOG_IN, {
    enabled: trigger,
    ...reactQueryConfig,
    onSuccess: async (res) => {
      setTrigger(false);
      if (res?.status == 'pass_reset_required') {
        history.replace(navLinks['expired-password'].link);
      } else if (res.status == 'two_factor_sms_sent') {
        setBackupNumber(res?.backup_number_available);
        setClose(true);
      } else {
        localStorage.setItem(
          'remember_cart_login',
          JSON.stringify({ userName: res.username, email: username, token: res.token }),
        );
        localStorage.setItem(
          'userName',
          JSON.stringify({
            userName: res.username,
            email: username,
            token: res.token,
          }),
        );
        axios.defaults.headers.common.Authorization = `bearer ${res.token}`;
        setFieldValue('emailAddress', '');
        setFieldValue('password', '');
      }
    },
    onError: () => {
      Message.error('Login Failed!', 'Error');
      setTrigger(false);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setTrigger(true);
  };

  return (
    <>
      <CartLoginScreen id="cart-user-login">
        {remember_cart_login?.email == undefined ? (
          <>
            <Heading name="public/checkout.string15">
              <H6 className="mt-4">{t('public/checkout.string46')}</H6>
              <CartLoginForm>
                <div className="label">
                  <H6 className="username">{t('public/checkout.string13')}</H6>
                  <H6 className="password">{t('public/checkout.string14')}</H6>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <Input
                    styling={{ width }}
                    placeholderClass
                    value={username}
                    isFormIk={false}
                    onKeyEnter={(e) => {
                      e.target.keyCode === 13 && handleSubmit;
                    }}
                    onEnter={(e) => handleSubmit(e)}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <Input
                    type="password"
                    styling={{ width }}
                    placeholderClass
                    value={password}
                    isFormIk={false}
                    onKeyEnter={(e) => {
                      e.target.keyCode === 13 && handleSubmit;
                    }}
                    onEnter={(e) => handleSubmit(e)}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <CustomButton
                    isLoading={loginIsLoading || trigger}
                    title={t('public/checkout.string16')}
                    type="submit"
                    buttonStyles={{ marginTop: '1em' }}
                    onClick={(e) => handleSubmit(e)}
                  />
                </div>
              </CartLoginForm>
            </Heading>
            <CartLoginFormWithEmail id="cart-login">
              <Heading name="public/checkout.string30">
                <CartLoginForm>
                  <div className="label">
                    <H6 className="username">{t('public/checkout.string31')}</H6>
                    <H6 className="password">{t('public/checkout.string47')}</H6>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <Input
                      name={'emailAddress'}
                      value={values['emailAddress']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors['emailAddress']}
                      touched={touched['emailAddress']}
                      disabledField={remember_cart_login?.email != undefined}
                      styling={{ width }}
                      placeholderClass
                    />
                    <Input
                      id="password"
                      name={'password'}
                      value={values['password']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors['password']}
                      touched={touched['password']}
                      dataTooltip={validationsTooltip(values.password)}
                      disabledField={remember_cart_login?.email != undefined}
                      styling={{ width }}
                      placeholderClass
                      PasswordInput
                      handleClickShowPassword={() => setIsShowPassword(!isShowPassword)}
                      showPassword={isShowPassword}
                    />
                  </div>
                </CartLoginForm>
              </Heading>
            </CartLoginFormWithEmail>
          </>
        ) : (
          <Heading name="public/checkout.string52">
            <CartAuthScreen>
              <H6>
                <Trans i18nKey={'public/checkout.string48'}>
                  <span
                    style={{ marginRight: '10px', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={handleLogout}
                  />
                </Trans>
              </H6>
              {isLoading && <img src={LoadingImage} width={25} />}
            </CartAuthScreen>
          </Heading>
        )}
      </CartLoginScreen>
      <CartLoginWithPin
        backupNumber={backupNumber}
        userName={username}
        password={password}
        open={close}
        handleClose={() => setClose(false)}
      />
    </>
  );
};

export default CartLogin;
