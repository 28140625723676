import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import { Head, BackupPlan, DailyBackup, PermanentBackup } from './components';
import { useMutation, useQuery } from 'react-query';
import { get } from 'lodash';
import { addPricingData } from 'components/vm-deployment/util';
import Loading from 'components/common/loading/Loading';
import { BACKUP_CHANGE } from 'components/manage-vm/queries';
import hooks from 'hooks';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import ConditionalFragment from 'components/conditional-fragment';

const Templates = ({ formikProp, data, managerVmRefetch }) => {
  const [refetch, setRefetch] = useState({});
  const rights = useSelector(userRights);
  const { mutationProps, isBackupRefetch } = hooks();
  const {
    data: backupLevelsData,
    isLoading: backupLevelLoad,
    refetch: backupLevelRefetch,
  } = useQuery('/vs/backuplevels');
  const {
    data: instanceBackupData,
    isLoading: backupDataLoading,
    refetch: backupInstanceRefetch,
  } = useQuery(`/vs/instance/${data.id}/backups`, {
    ...refetch,
    onSuccess: (res) => {
      formikProp.setFieldValue('backup_plan', get(res, 'vs_backups[0][backups_plan]'));
      setRefetch(isBackupRefetch(res));
    },
  });
  const { mutate: changeBackupPlan, isLoading: changeBackupLoad } = useMutation(BACKUP_CHANGE, {
    ...mutationProps('', {
      onSuccess: async () => {
        await backupLevelRefetch();
        await backupInstanceRefetch();
        await managerVmRefetch();
      },
    }),
  });
  const withPricing = addPricingData(
    get(backupLevelsData, 'vs_backupplans', []),
    get(instanceBackupData, 'vs_backups[0].vs_backups_prices', []),
  );

  if (backupDataLoading || backupLevelLoad) {
    return <Loading />;
  }

  return (
    <Styles.TemplateSC>
      {data?.billing_schedule == 'hourly' && (
        <>
          <Head />
          <BackupPlan
            formikProp={formikProp}
            backupData={withPricing}
            changeBackupPlan={(backups_plan) => changeBackupPlan({ id: data.id, backups_plan })}
            changeBackupLoad={changeBackupLoad}
          />
        </>
      )}
      <DailyBackup backupData={instanceBackupData} data={data} />
      <ConditionalFragment condition={rights?.vsbackupsave}>
        <PermanentBackup
          backupData={instanceBackupData}
          data={data}
          backupLevelRefetch={backupLevelRefetch}
          backupInstanceRefetch={backupInstanceRefetch}
          managerVmRefetch={managerVmRefetch}
        />
      </ConditionalFragment>
    </Styles.TemplateSC>
  );
};
export default React.memo(Templates);
Templates.propTypes = {
  formikProp: PropTypes.object,
  data: PropTypes.any,
};
