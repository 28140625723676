import React from 'react';
import * as Styles from './wh.style';
import { Icon } from 'semantic-ui-react';
import CustomButton from 'components/common/custom-button';
import MostPopular from 'assets/images/most_popular.png';

const WHSize = ({ size, sizePrice, onClick, selected, sizeDescription, index, length }) => {
  return (
    <Styles.Size selected={selected}>
      <Styles.Popular showTag={size?.mostpopular == 1} alt="most_popular" src={MostPopular} />
      <Styles.SizesContainer length={length} index={index} selected={selected} onClick={() => onClick(size)}>
        <div>
          <Styles.NameContainer>
            <Styles.Name>{sizeDescription?.title_en}</Styles.Name>
          </Styles.NameContainer>
          <Styles.PlanText>At half-past ipsum dolor.</Styles.PlanText>
          <Styles.Price>
            <div>
              <Styles.PerMonth>
                <Styles.DollarSign>$</Styles.DollarSign>
                <Styles.PriceUnit>{Math.trunc(sizePrice?.price_monthly_usd)}</Styles.PriceUnit>
              </Styles.PerMonth>
              <Styles.PlanText>per month</Styles.PlanText>
            </div>
            <Styles.Separator />
            <div>
              <Styles.PerMonth>
                <Styles.DollarSign>$</Styles.DollarSign>
                <Styles.PriceUnit>{Math.trunc(sizePrice?.price_yearly_usd)}</Styles.PriceUnit>
              </Styles.PerMonth>
              <Styles.PlanText>per year</Styles.PlanText>
            </div>
          </Styles.Price>
          <Styles.PlanDescription>
            At half-past eight the door opened, the policeman appeared, and, requesting them to follow him.
          </Styles.PlanDescription>

          <Styles.Features>
            {sizeDescription?.en?.map((val, i) => (
              <Styles.Conatiner key={i}>
                <Styles.Heading>{val.header} </Styles.Heading>
                {val?.content?.map((text, i) => (
                  <Styles.Feature key={i}>{text}</Styles.Feature>
                ))}
              </Styles.Conatiner>
            ))}
          </Styles.Features>
        </div>
        <Styles.FeaturesButton>
          <CustomButton
            isIcon={selected ? <Icon name="check" /> : <Icon name="angle right" />}
            title={selected ? 'Selected' : 'Select'}
            buttonStyles={{
              width: '100%',
              backgroundColor: selected ? '#cfcfcf' : '#FF7300',
              fontWeight: 'bold',
              color: selected ? 'black' : 'white',
            }}
          />
        </Styles.FeaturesButton>
      </Styles.SizesContainer>
    </Styles.Size>
  );
};
export default WHSize;
