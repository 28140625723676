import { get } from 'lodash';
import { useQuery } from 'react-query';

export const useBackupPrice = () => {
  const { data: backupLevelsPricingData } = useQuery('/prices/vs/backups');
  return {
    backupData: (data) =>
      get(backupLevelsPricingData, 'prices_vs_backups', []).find((obj) => obj.id == data.backups_plan),
  };
};
