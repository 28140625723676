import CustomButton from 'components/common/custom-button';
import React from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import DialogBox from 'components/dialog';
import { H5, P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const RestoreConfirmationModal = ({ handleSubmit, loading, toggle, open }) => {
  const { t } = useTranslation();
  return (
    <DialogBox width="sm" open={open} onClose={toggle}>
      <Styles.RestoreConfirmation>
        <Styles.RestoreContent>
          <H5>{t('vs/instance.string160')}</H5>
          <P>{t('vs/instance.string161')}</P>
          <Styles.Buttons>
            <CustomButton isLoading={loading} title={'vs/instance.string162'} onClick={handleSubmit} />
            <CustomButton
              title={'vs/instance.string163'}
              buttonStyles={{
                marginLeft: '8px',
              }}
              onClick={toggle}
            />
          </Styles.Buttons>
        </Styles.RestoreContent>
      </Styles.RestoreConfirmation>
    </DialogBox>
  );
};
export { RestoreConfirmationModal };
