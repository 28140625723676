import { h6Styles, pStyles } from 'components/reusable-css';
import styled, { css } from 'styled-components';
import { deviceBreakpoints } from 'variables';

export const Cards = styled.div`
  padding-bottom: 25px;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    display: flex;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  margin-right: 20px;
  min-height: 40px;
  opacity: ${(props) => (props.isGrey ? 0.4 : 1)};
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    width: 48%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .cards {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer !important;
    border-bottom: 2px solid transparent;
    padding-top: 3px;
    padding-right: 10px;
    &:hover {
      background-color: #cfcfcf;
      transition: background-color 0.3s ease;
      border-radius: 15px;
      border-bottom: 2px solid orange;
    }
    @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
      display: none;
    }
  }
`;

export const CardsMobile = styled.div`
  display: none;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer !important;
    padding: 10px 20px;
    border-radius: 8px;
    border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  }
  ${(props) =>
    props.selected &&
    css`
      background-color: ${props.theme.primaryDarkColor};
      border: 2px solid ${props.theme.primaryDarkColor} !important;
      color: white !important;
    `};
`;

export const Heading = styled.div`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  font-weight: bolder;
  padding-left: 12px;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    padding-left: 0;
  }
`;

export const SubHeading = styled.p`
  @media (max-width: 600px) {
    white-space: normal;
  }
  padding-top: 1px;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  font-weight: 400 !important;
  white-space: nowrap;
`;
