import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const LOG_IN = async (e) => {
  const token = btoa(`${e.queryKey[1].userName}:${e.queryKey[1].password}`);
  const res = await axios.get(BASE_URL + `/authenticate`, {
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
  return res.data;
};

export const TWOFALOG_IN = async (e) => {
  const token = btoa(`${e.queryKey[1].userName}:${e.queryKey[1].password}:${e.queryKey[1].code}`);
  const res = await axios.get(BASE_URL + `/authenticate`, {
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
  return res.data;
};

export const USER_UI_DETAIL = async () => {
  const res = await axios.get(BASE_URL + `/uidetails`);
  return res.data;
};

export const ACCOUNT_DETAIL = async () => {
  const res = await axios.get(BASE_URL + `/account`);
  return res.data;
};

export const BILLING_DETAILS = async () => {
  const res = await axios.get(BASE_URL + `/account/billing_info`);
  return res.data;
};
