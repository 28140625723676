import React from 'react';
import { useQuery } from 'react-query';
import NewVMStep4 from './NewVMStep4';
import _get from 'lodash/get';
import { addPricingData } from 'components/vm-deployment/util';

const NewVMStep4Container = ({ formikProp, vsTemplate }) => {
  const { data: appsData } = useQuery('/vs/apps');
  const { data: appsPricingData } = useQuery('/prices/vs/apps');
  const withPricing = addPricingData(_get(appsData, 'apps', []), _get(appsPricingData, 'prices_vs_apps'));

  return <NewVMStep4 withPricing={withPricing} formikProp={formikProp} vsTemplate={vsTemplate} />;
};
export default NewVMStep4Container;
