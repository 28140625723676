import { h6Styles, pStyles } from 'components/reusable-css';
import styled from 'styled-components';

export const SliderWidth = styled.div`
  margin-right: 16px;

  @media only screen and (min-width: 650px) {
    width: 56%;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  border: 2px solid #e3e6e4;
  border-radius: 10px;
  width: 120px;
  margin-right: 5px;
  padding: 5px 0;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  font-weight: 600;
  margin-bottom: 20px;
  background-color: ${(props) => (props.selected ? '#f7f6f5' : '')};
  border-color: ${(props) => (props.selected ? '#f5d9b3' : '')};
  &:hover {
    border-color: #f5d9b3;
    background-color: #f7f6f5;
    transition: 0.3s;
  }
  @media only screen and (max-width: 1024px) {
    width: 100px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 768px) {
    width: 55px;
  }
  @media only screen and (max-width: 640px) {
    width: 50px;
  }
`;

export const Input = styled.input`
  height: 50px;
  width: 14%;
  border: 2px solid #e3e6e4;
  border-bottom: 2px solid orange;
  border-radius: 8px;
  background-color: #fff;
  padding-left: 15px;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  font-weight: 600;
  margin-right: 14px;

  &:hover {
    background-color: #ebedec;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const SliderContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 80px;
`;

export const SliderCard = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
  margin-right: 18px;
  margin-left: 5px;
  @media only screen and (max-width: 640px) {
    display: flex;
    flex-flow: column wrap;
  }
`;

export const Description = styled.div`
  margin-top: 10px;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};

  span {
    font-weight: 400;
  }
  @media only screen and (max-width: 929px) {
    width: 25%;
    margin-top: 2px;
  }

  @media only screen and (max-width: 768px) {
    width: 25%;
    margin-top: 5px;
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const PopularButton = styled.div`
  position: absolute;
  bottom: 100%;
  text-align: center;
  width: 100%;
`;
export const PopularSizeText = styled.div`
  background-color: red;
  color: white;
  border-radius: 10px;
  font-weight: 600;
  font-size: ${(props) => props.theme.default?.p};
  margin: 0px 20px 6px 20px;
  ${(props) => pStyles(props.theme.resolutions)};
  @media only screen and (max-width: 768px) {
    margin: 0px 0px 6px 0px;
  }
`;
