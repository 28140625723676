import { Link } from 'react-router-dom';
import { dynamicStyles } from 'components/reusable-css';
import styled from 'styled-components';
import { deviceBreakpoints } from 'variables';

export const AppDrawerSC = styled.div`
  display: flex;
  height: 100vh;
  min-width: ${(props) => props.theme.default?.desktop_menu_width};
  max-width: ${(props) => props.theme.default?.desktop_menu_width};
  transition: left 500ms ease;

  ${(props) =>
    dynamicStyles(props.theme.resolutions, 'desktop_menu_width', 'min-width', 'desktop_menu_width', 'max-width')};
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    position: fixed;
    top: ${(props) => props.theme.default?.top_bar_height};
    left: -${(props) => (props.isAppDrawerOpen ? 0 : props.theme.default?.desktop_menu_width)};
    height: calc(100vh - ${(props) => props.theme.default?.top_bar_height});
    z-index: 9999;
  }
`;

export const AppDrawerContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    display: none;
  }
`;

export const AppDrawerHeader = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.theme.default?.top_bar_height};
  min-height: ${(props) => props.theme.default?.top_bar_height};
  background: ${(props) => props.theme.gray.shadeE};
  ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_height', 'height', 'top_bar_height', 'min-height')};
`;

export const LogoImageSC = styled.img`
  width: ${(props) => props.theme.default?.logo_top_left};
  ${(props) => dynamicStyles(props.theme.resolutions, 'logo_top_left', 'width')};
`;

export const AppDrawerBody = styled.div`
  display: flex;
  width: 100%;
  background: ${(props) => props.theme.secondaryColor};
`;

export const SectionLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.secondaryDarkColor};
  width: ${(props) => props.theme.default?.desktop_menu_icon_area_width};
  ${(props) => dynamicStyles(props.theme.resolutions, 'desktop_menu_icon_area_width', 'width')};
`;

export const SectionLink = styled(Link)`
  display: grid;
  align-items: center;
  justify-items: center;
  align-content: center;
  gap: 4px;
  height: 100px;
  color: ${(props) => props.theme.gray.shadeF};
  text-decoration: none;
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.gray.shadeF};
    background: ${(props) => props.theme.secondaryColor};
  }

  background: ${(props) => (props.active ? props.theme.secondaryColor : 'transparent')};

  .k-icon {
    color: ${(props) => props.theme.gray.shadeF};
  }
`;

export const SectionIcon = styled.img`
  height: ${(props) => props.theme.default?.desktop_menu_icon_width};
  width: ${(props) => props.theme.default?.desktop_menu_icon_width};
  ${(props) =>
    dynamicStyles(props.theme.resolutions, 'desktop_menu_icon_width', 'width', 'desktop_menu_icon_width', 'height')};
`;

export const SectionLabel = styled.div`
  display: flex;
  text-align: center;
  font-size: ${(props) => props.theme.default?.desktop_menu_icon_font};
  ${(props) => dynamicStyles(props.theme.resolutions, 'desktop_menu_icon_font', 'font-size')};
`;

export const MainLinkIcon = styled.div`
  width: 6px;
  height: 6px;
  margin: 4.5px 0;
  margin-right: 12px;
  border-radius: 6px;
  background: ${(props) => props.theme.gray.shadeF};
`;

export const MainLinkLabel = styled.div`
  display: flex;
  flex: 1;
  font-size: ${(props) => props.theme.default?.desktop_menu_submenu_font};
  ${(props) => dynamicStyles(props.theme.resolutions, 'desktop_menu_submenu_font', 'font-size')};
  margin-right: 5px;
  @media only screen and (min-width: 1367px) {
    margin-top: -2px
  }
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    flex: none;
    margin-right: 10px;
  }
`;

export const MainLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  padding-left: ${(props) => props.theme.default?.desktop_menu_submenu_bullet_left_space};
  ${(props) => dynamicStyles(props.theme.resolutions, 'desktop_menu_submenu_bullet_left_space', 'padding-left')};
  width: ${(props) => props.theme.default?.desktop_menu_submenu_area_width};
  ${(props) => dynamicStyles(props.theme.resolutions, 'desktop_menu_submenu_area_width', 'width')};

  .main-link {
    display: flex;
    align-items: flex-start;
    color: ${(props) => props.theme.gray.shadeF};
    text-decoration: none;
    margin-top: 4px;
    background: ${(props) => (props.active ? props.theme.secondaryLightColor : 'transparent')};
    border-radius: 8px;
    padding-bottom: ${(props) => props.theme.default?.desktop_menu_submenu_font_line_height};
    ${(props) => dynamicStyles(props.theme.resolutions, 'desktop_menu_submenu_font_line_height', 'padding-bottom')};
    &:hover {
      ${MainLinkLabel} {
        text-decoration: underline;
      }
    }
  }
`;

export const Divider = styled.div`
  display: flex;
  height: 1px;
  background: ${(props) => props.theme.gray.shadeF};
  margin: 20px 0;
`;

export const AppDrawerMobileContainer = styled.div`
  display: none;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.appDrawer.mobile.backgroundColor};
  border-bottom: 1px solid ${(props) => props.theme.appDrawer.mobile.borderBottomColor};
  border-top: 1px solid ${(props) => props.theme.appDrawer.mobile.borderTopColor};
  opacity: 0.97;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.appDrawer.mobile.borderTopColor};
  }

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    display: block;
  }
`;

export const MobileSectionLinkContainer = styled.div`
  display: grid;
  max-height: ${(props) => (props.active ? (props.linkCounts + 1) * 60 : 45)}px;
  overflow: hidden;
  transition: max-height 500ms ease;
`;

export const MobileSectionLink = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  color: ${(props) => props.theme.gray.shadeF};
  cursor: pointer;
  font-size: ${(props) => props.theme.default?.desktop_menu_icon_font};
  ${(props) => dynamicStyles(props.theme.resolutions, 'desktop_menu_icon_font', 'font-size')};
  ${(props) => props.theme.appDrawer.mobile.borderBottomColor};
  border-top: 1px solid ${(props) => props.theme.appDrawer.mobile.borderTopColor};
  border-bottom: 1px solid ${(props) => props.theme.appDrawer.mobile.borderBottomColor};
  box-shadow: ${(props) => (props.active ? props.theme.appDrawer.mobile.section.activeBoxShadow : 'none')};

  i {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const MobileLinksContainer = styled.div`
  display: grid;

  .main-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 25px;
    padding-right: 10px;
    font-size: ${(props) => props.theme.default?.desktop_menu_submenu_font};
    ${(props) => dynamicStyles(props.theme.resolutions, 'desktop_menu_submenu_font', 'font-size')};
    height: 45px;
    border-top: 1px solid ${(props) => props.theme.appDrawer.mobile.borderTopColor};
    border-bottom: 1px solid ${(props) => props.theme.appDrawer.mobile.borderBottomColor};
    background: ${(props) => props.theme.appDrawer.mobile.link.backgroundColor};
    color: ${(props) => props.theme.gray.shadeF};

    .badge-number {
      background: ${(props) => props.theme.appDrawer.mobile.link.badge.backgroundColor};
      color: ${(props) => props.theme.gray.shadeF};
    }
  }
`;

export const BlinkBackground = styled.div`
  animation: ${(props) => (props.isAnimation ? 'blinkingBackground 2s infinite' : '')};
  border-radius: 10px;
  padding: 0 6px;

  @keyframes blinkingBackground {
    50% {
      background-color: ${(props) => props.theme.primaryDarkColor};
    }
  }
`;
