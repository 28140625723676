import React from 'react';
import * as Styles from 'pages/member/user-management/UserManagement.style';
import { PanelHeader } from '../panelHeader';
import { EditRoleContent } from './components/edit-role-content';
import { Formik } from 'formik';
import { editRoleInitialValues, editRoleValidationSchema } from 'constants/user-management';
import hooks from 'hooks';
import { useMutation } from 'react-query';
import { EDIT_ROLE } from '../queries';
import { removeObjectEmpty } from 'utils';

const EditRole = ({ isClose, getRole, roleRefetch, labels, usersRefetch }) => {
  const { mutationProps } = hooks();
  const { mutate: editRole, isLoading: loading } = useMutation(EDIT_ROLE);

  // SUBMITTING EDIT ROLES FORM.
  const handleSubmit = async (values, { resetForm }) => {
    await editRole(
      { id: getRole.id, rolename: values.edit_role_name, ...values.isCheck },
      mutationProps(() => {}, {
        onSuccess: async () => {
          await roleRefetch();
          await usersRefetch();
          resetForm({
            values: { isCheck: values.isCheck, role_name: '' },
          });
        },
      }),
    );
  };
  const selectedRoles = {
    ...getRole,
    id: undefined,
    rolename: undefined,
  };
  return (
    <React.Fragment>
      <Styles.UserMain>
        <PanelHeader
          isClose={isClose}
          user_number={getRole?.qty_users_assigned_to_this}
          name={getRole?.rolename.toUpperCase()}
          heading={'account/usermgmt.string15'}
        />
        <Formik
          initialValues={editRoleInitialValues(getRole.rolename, removeObjectEmpty(selectedRoles))}
          validationSchema={editRoleValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(formikProps) => (
            <Styles.EditRoleContent>
              <EditRoleContent
                getRole={getRole}
                labels={labels}
                roleRefetch={roleRefetch}
                loading={loading}
                formikProps={formikProps}
              />
            </Styles.EditRoleContent>
          )}
        </Formik>
      </Styles.UserMain>
    </React.Fragment>
  );
};
export { EditRole };
