import React from 'react';
import * as Styles from '../styles/ForgotPassword.style';
import { useTranslation } from 'react-i18next';
import LogoImage from 'assets/images/serverpoint.svg';
import { P } from 'components/reusable-css';

const ExpiredPassword = () => {
  const { t } = useTranslation();
  return (
    <Styles.BgLogin>
      <Styles.LoginContainer>
        <Styles.LoginBox isExpiredPassword>
          <Styles.Logo>
            <img src={LogoImage} alt="serverpoint" width="100%" />
          </Styles.Logo>
          <Styles.LoginForm>
            <P className='my-0 '>{t('expired-password.string1')} </P>
          </Styles.LoginForm>
        </Styles.LoginBox>
      </Styles.LoginContainer>
    </Styles.BgLogin>
  );
};

export { ExpiredPassword };
