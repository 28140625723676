import { checkWholeValues } from '../util';
export const tooltipLabel = (tooltipItem, data, yLabel) => {
  return yLabel
    ? data?.datasets[tooltipItem.datasetIndex]?.label +
        ': ' +
        data['datasets'][tooltipItem['datasetIndex']]['data'][tooltipItem['index']] +
        yLabel
    : data?.datasets[tooltipItem.datasetIndex]?.label +
        ': ' +
        data['datasets'][tooltipItem['datasetIndex']]['data'][tooltipItem['index']] +
        '%';
};

export const options = (xAxis, yLabel, ystepSize, yMax, round, pointRadius) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 65,
      },
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      yAlign: 'bottom',
      callbacks: {
        label: (tooltipItem, data) => tooltipLabel(tooltipItem, data, yLabel),
      },
    },
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: pointRadius || 2,
        hoverRadius: 4,
      },
    },
    hover: {
      intersect: true,
    },
    scales: {
      xAxes: [
        {
          ...(xAxis || {
            type: 'time',
            time: {
              unit: 'hour',
              displayFormats: { hour: 'HH:mm' },
              // round: 'hour',
            },
          }),
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: '#c87c22',
            // autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            maxTicksLimit: 7,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            precision: 0,
            fontColor: '#c87c22',
            stepSize: ystepSize || 20,
            callback: function (value) {
              return yLabel ? checkWholeValues(value) + yLabel : Math.round(value) + '%';
            },
            beginAtZero: true,
            max: yMax || 100,
          },
        },
      ],
    },
  };
};
