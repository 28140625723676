import { h6Styles, pStyles } from 'components/reusable-css';
import styled from 'styled-components';
//import { deviceBreakpoints } from 'variables';
export const Card = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer !important;
  border-bottom: 2px solid transparent;
  padding-top: 3px;
  padding-left: 4px;
  padding-bottom: 4px;
  &:hover {
    background-color: #cfcfcf;
    transition: background-color 0.3s ease;
    border-radius: 15px;
    border-bottom: 2px solid orange;
  }
`;

export const Heading = styled.div`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  color: #000;
  font-weight: bolder;
  padding-left: 12px;
`;

export const SubHeading = styled.p`
  font-weight: 400 !important;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;
export const CheckBox = styled.div`
  padding-right: 5px;
`;
