import Loading from 'components/common/loading/Loading';
import React from 'react';
import { useQuery } from 'react-query';
import Step1 from './step-1';
import Step2 from './step-2';

export default function IssuesContainer({ formikProps, newTicketLoading, style }) {
  const { data, isLoading } = useQuery('/tickets/step1');
  const { values } = formikProps;

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Step1 style={style} data={data} formikProps={formikProps} />
      {values.step1 && <Step2 newTicketLoading={newTicketLoading} formikProps={formikProps} />}
    </>
  );
}
