import { CHARGE_SAVED_CARD, SAVE_CHARGE_CARD } from 'components/vm-deployment/queries/billing';
import { useMutation } from 'react-query';
import { CustomHook } from 'utils/custom-hook';

export const MutationQueries = () => {
  const { mutationProps } = CustomHook();
  const { mutate: saveAndChargeCard, isLoading: saveAndChargeCardLoad } = useMutation(SAVE_CHARGE_CARD, {
    ...mutationProps('', { isSuccess: false }),
  });
  const { mutate: chargeSavedCard, isLoading: chargeSavedCardLoad } = useMutation(CHARGE_SAVED_CARD, {
    ...mutationProps('', { isSuccess: false }),
  });
  return {
    saveAndChargeCard,
    chargeSavedCard,
    saveAndChargeCardLoad,
    chargeSavedCardLoad,
  };
};
