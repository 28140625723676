/* eslint-disable no-unused-vars */
import React from 'react';
import DynamicInputs from 'components/issues/DynamicInputs';

const IssuesStep1 = ({ data, formikProps, style }) => {
  const { values, setFieldValue } = formikProps;
  return (
    <DynamicInputs
      style={style}
      data={data}
      setValue={(id, value, step) => {
        setFieldValue('step1_next_step', step?.next_step);
        setFieldValue('step1', value);
      }}
      value={values?.step1}
      stepType="step1"
      formikProps={formikProps}
    />
  );
};
export default IssuesStep1;
