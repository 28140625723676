import React from 'react';
import {
  BgLogin,
  LoginBox,
  LoginForm,
  Logo,
  LoginContainer,
} from './ErrorScreen.style';
import LogoImage from 'assets/images/serverpoint.svg';

const ErrorScreen = () => {
  return (
    <BgLogin>
      <LoginContainer>
        <LoginBox>
          <LoginForm>
            <Logo>
              <img src={LogoImage} alt="serverpoint" width="100%" />
            </Logo>
            <p>:(</p>
            <p>
              For some reason, PayPal sent you here. Perhaps you accidentally
              canceled your transaction?
            </p>
          </LoginForm>
        </LoginBox>
      </LoginContainer>
    </BgLogin>
  );
};

export default ErrorScreen;
