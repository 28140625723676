import React from 'react';
import * as Styles from 'pages/member/dns/DNS.style';
import SettingDropdown from './SettingDropdown';
import { Icon } from 'semantic-ui-react';
import { H5, H6 } from 'components/reusable-css';
import { useMutation } from 'react-query';
import { DELETE_DOMAIN, DNS_ACTIONS } from './queries';
import { CustomHook } from 'utils/custom-hook';
import { dnsListdropdown } from './util';

const DNSList = ({ data, setOpenConfigurationPanel, setOpenDomain, refectDNS, fetchDNSLoading }) => {
  const { mutationProps } = CustomHook();
  const { mutate: dnsActions, isLoading } = useMutation(DNS_ACTIONS);
  const { mutate: deleteDomain, deleteLoading } = useMutation(DELETE_DOMAIN);

  const handleAction = async (actionName) => {
    if (actionName == 'delete') {
      await deleteDomain(
        {
          domain: data.domain,
        },
        mutationProps(() => {}, {
          onSuccess: async () => {
            await refectDNS();
          },
        }),
      );
    } else {
      await dnsActions(
        {
          domain: data.domain,
          action: actionName,
        },
        mutationProps(() => {}, {
          onSuccess: async () => {
            await refectDNS();
          },
        }),
      );
    }
  };

  return (
    <Styles.ListContainer loading={isLoading || fetchDNSLoading || deleteLoading}>
      <Styles.Content>
        <Styles.FirstDiv>
          <Styles.DomainName>
            <i className="fa fa-circle" />
            <H5>{data.domain}</H5>
          </Styles.DomainName>
        </Styles.FirstDiv>
        <Styles.MobileFirstDiv>
          <Styles.DomainName>
            <i className="fa fa-circle" />
            <H5>{data.domain}</H5>
          </Styles.DomainName>
          <SettingDropdown
            handleAction={handleAction}
            data={data}
            refectDNS={refectDNS}
            setOpenConfigurationPanel={setOpenConfigurationPanel}
            dropdownItems={dnsListdropdown}
            setOpenDomain={setOpenDomain}
          />
        </Styles.MobileFirstDiv>
        <Styles.SecondDiv>
          <Styles.IPContainer>
            <Styles.IP>
              <Icon
                name="pencil"
                onClick={() => {
                  setOpenConfigurationPanel(true);
                  setOpenDomain(data.domain);
                }}
              />
              <div>
                {data.a.map((data, index) => {
                  return data.name == '' && <H6 key={index}>{data.value}</H6>;
                })}
              </div>
            </Styles.IP>
          </Styles.IPContainer>
          <Styles.StatusContainer>
            <Styles.Status status={data.status_en}>
              <H6>{data.status_en}</H6>
            </Styles.Status>
          </Styles.StatusContainer>
          <SettingDropdown
            handleAction={handleAction}
            data={data}
            refectDNS={refectDNS}
            setOpenConfigurationPanel={setOpenConfigurationPanel}
            dropdownItems={dnsListdropdown}
            setOpenDomain={setOpenDomain}
          />
        </Styles.SecondDiv>
        <Styles.MobileSecondDiv>
          <Styles.IPContainer>
            <Styles.IP>
              <Icon
                name="pencil"
                onClick={() => {
                  setOpenConfigurationPanel(true);
                  setOpenDomain(data.domain);
                }}
              />
              <div>
                {data.a.map((data, index) => {
                  return data.name == '' && <H6 key={index}>{data.value}</H6>;
                })}
              </div>
            </Styles.IP>
          </Styles.IPContainer>
          <Styles.StatusContainer>
            <Styles.Status status={data.status_en}>
              <H6>{data.status_en}</H6>
            </Styles.Status>
          </Styles.StatusContainer>
        </Styles.MobileSecondDiv>
      </Styles.Content>
    </Styles.ListContainer>
  );
};

export default DNSList;
