import React, { useEffect, useMemo } from 'react';
import { ACCOUNT_DETAIL, BILLING_DETAILS, USER_UI_DETAIL } from 'components/vm-deployment/queries';
import { getLocalStorageValues } from 'constants/local-storage';
import { useQuery } from 'react-query';
import { reactQueryConfig } from 'constants/react-query-config';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateUserAccount,
  updateUserBillingInfo,
  updateUserUi,
  updateUserBillingAccountRefetch,
  updateUserAccountRefetch,
  updateUserUiRefetch,
  isUserFetching,
} from 'store/actions/user-details';
import MemberNavigator from './MemberNavigator';
import { CustomHook } from 'utils/custom-hook';
// import { Helmet } from 'react-helmet';
import Loading from 'components/common/loading';

export const MemberNavigatorContainer = ({ queryClient }) => {
  const userUI = useSelector((state) => state.user.ui);
  const isAppDrawerOpen = useSelector((state) => state.commonStates.isAppDrawerOpen);
  const { logout } = CustomHook();
  const dispatch = useDispatch();
  let { userName } = getLocalStorageValues();
  const onError = async () => {
    try {
      await logout(queryClient);
    } catch (error) {
      console.log('error::', error);
    }
  };
  // UI API
  const {
    refetch: uiRefetch,
    isFetching: userUiFetching,
    isError: uiError,
  } = useQuery(['USER_UI_DETAIL', {}], USER_UI_DETAIL, {
    ...reactQueryConfig,
    enabled: userName.token ? true : false,
    refetchOnWindowFocus: true,
    onSuccess: async (res) => {
      dispatch(updateUserUiRefetch(uiRefetch));
      dispatch(updateUserUi(res));
    },
    onError,
  });

  //THIS IS USED IN MANAGE BM PUBLIC BANDWITH URL WHEN PREFERENCES ARE CHANGED MANUALLY
  useEffect(() => {
    dispatch(isUserFetching(userUiFetching));
  }, [userUiFetching]);

  // ACCOUNTS API
  const { refetch: accountRefetch, isError: accountError } = useQuery(['ACCOUNT_DETAIL', {}], ACCOUNT_DETAIL, {
    ...reactQueryConfig,
    enabled: userName.token ? true : false,
    onSuccess: (res) => {
      dispatch(updateUserAccount(res));
      dispatch(updateUserAccountRefetch(accountRefetch));
    },
    onError,
    refetchInterval: Number(userUI.refresh_every_x_seconds) * 1000,
    refetchIntervalInBackground: false,
  });
  const {
    data: billingData,
    refetch,
    isError: billingError,
  } = useQuery(['BILLING_DETAILS', {}], BILLING_DETAILS, {
    ...reactQueryConfig,
    enabled: userName.token ? true : false,
    onSuccess: (res) => {
      dispatch(updateUserBillingInfo(res));
      dispatch(updateUserBillingAccountRefetch(refetch));
    },
    onError,
    refetchIntervalInBackground: true,
    refetchInterval: 60 * 1000,
  });

  if (accountError || uiError || billingError) {
    return <Loading gap={200} />;
  }

  return useMemo(
    () => (
      <React.Fragment>
        {/* <Helmet>
          <script type="text/javascript">{`
    {
      window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s= d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set. _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8"); $.src="https://v2.zopim.com/?3rIiDhWVojwN2PHtI1iIdhNh5ZLraHh6";z.t=+new Date;$. type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script"); 
    }
    `}</script>
        </Helmet> */}
        <MemberNavigator
          isAppDrawerOpen={isAppDrawerOpen}
          queryClient={queryClient}
        />
      </React.Fragment>
    ),
    [billingData, isAppDrawerOpen, queryClient],
  );
};
export default React.memo(MemberNavigatorContainer);
