import * as Yup from 'yup';
import { roleNameRegex, passwordRegex } from 'constants/regex';
export const add_new_user = [
  {
    type: 'text',
    name: 'username',
    label_lang: 'account/usermgmt.string31',
    placeholder_lang: 'account/usermgmt.string31',
  },
  {
    type: 'password',
    name: 'new_user_password',
    label_lang: 'account/usermgmt.string32',
    placeholder_lang: 'account/usermgmt.string33',
  },
  {
    type: 'password',
    name: 'confirm_password',
    label_lang: 'account/usermgmt.string34',
    placeholder_lang: 'account/usermgmt.string35',
  },
];

export const roleDeletion = [
  {
    title_lang: 'account/usermgmt.string21',
    heading_lang: `account/usermgmt.string25`,
    buttons: [
      { button: 'Yes', button_lang: 'account/usermgmt.string22' },
      { button: 'No', button_lang: 'account/usermgmt.string23' },
    ],
  },
];
export const cannotDelete = [
  {
    title_lang: 'account/usermgmt.string19',
  },
];
export const userDeletion = [
  {
    title_lang: 'account/usermgmt.string20',
    heading_lang: `account/usermgmt.string25`,
    buttons: [
      { button: 'Yes', button_lang: 'account/usermgmt.string22' },
      { button: 'No', button_lang: 'account/usermgmt.string23' },
    ],
  },
];

export const deletionNotPossibleButton = [
  {
    title_lang: 'account/usermgmt.string27',
    heading_lang: `account/usermgmt.string26`,
    buttons: [{ button: 'Ok', button_lang: 'account/usermgmt.string24' }],
  },
];
export const saveRoleInitialValues = { role_name: '', isCheck: {} };
export const saveRoleValidationSchema = Yup.object({
  role_name: Yup.string()
    .max(32, 'account/usermgmt.string46')
    .required('account/usermgmt.string47')
    .matches(roleNameRegex, 'account/usermgmt.string48'),
  isCheck: Yup.object(),
});

export const editRoleInitialValues = (value, checkValues) => {
  return { edit_role_name: value, isCheck: checkValues, isEdit: 'text' };
};
export const editRoleValidationSchema = Yup.object({
  edit_role_name: Yup.string()
    .max(32, 'account/usermgmt.string46')
    .required('account/usermgmt.string47')
    .matches(roleNameRegex, 'account/usermgmt.string48'),
  isCheck: Yup.object(),
});

export const initialValues = (initialRole) => {
  return {
    user_show_password: false,
    user_password_show_again: false,
    role: initialRole,
    username: '',
    new_user_password: '',
    confirm_password: '',
  };
};

export const validationSchema = Yup.object({
  username: Yup.string()
    .max(64, 'account/usermgmt.string49')
    .min(6, 'account/usermgmt.string50')
    .matches(passwordRegex, 'account/usermgmt.string51'),
  // .required('Username required'),
  new_user_password: Yup.string()
    .required('account/usermgmt.string52')
    .min(6, 'account/usermgmt.string53')
    .max(64, 'account/usermgmt.string54'),
  confirm_password: Yup.string()
    .required('account/usermgmt.string55')
    .min(6, 'account/usermgmt.string53')
    .max(64, 'account/usermgmt.string54')
    .oneOf([Yup.ref('new_user_password'), null], 'account/usermgmt.string55'),
  role: Yup.string().required('account/usermgmt.string56'),
});

export const editUserInitialValues = (initialRole, { status } = {}) => {
  return {
    user_show_password: false,
    user_password_show_again: false,
    role: initialRole,
    username: '',
    user_password: '',
    user_password_again: '',
    status: status === 'active' ? true : false,
  };
};

export const editUserValidationSchema = Yup.object({
  user_password: Yup.string().optional().min(6, 'account/usermgmt.string53').max(64, 'account/usermgmt.string54'),
  user_password_again: Yup.string()
    .optional()
    .min(6, 'account/usermgmt.string53')
    .max(64, 'account/usermgmt.string54')
    .oneOf([Yup.ref('user_password'), null], 'account/usermgmt.string55'),
  role: Yup.string().optional(),
});
