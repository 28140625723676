import React from 'react';
import { Icon } from 'semantic-ui-react';
import { NotificationSC, NotificationMsg } from './RedNotification.style';

const RedNotification = ({ children, bgColor, width, icon, color, style }) => {
  return (
    <NotificationSC>
      <NotificationMsg
        style={{
          color: color ? color : 'white',
          backgroundColor: !bgColor ? '#bd0505' : bgColor,
          width: width || 'auto',
          ...style,
        }}
      >
        <Icon name={icon ? icon : 'warning circle'} />
        {children}
      </NotificationMsg>
    </NotificationSC>
  );
};
export default RedNotification;
