/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-27 18:14:37
 * @modify date 2021-02-05 09:47:53
 */

import { UPDATE_THEME, UPDATE_ACTIVE_MODAL, TOGGLE_APP_DRAWER, UPDATE_ACTIVE_SECTION_INDEX } from 'store/types';

export const updateActiveSectionIndex = (secIndex) => (dispatch) => {
  dispatch({
    type: UPDATE_ACTIVE_SECTION_INDEX,
    payload: secIndex,
  });
};

export const updateTheme = (themeName) => (dispatch) => {
  dispatch({
    type: UPDATE_THEME,
    payload: themeName,
  });
};

export const updateActiveModal = (modalName) => (dispatch) => {
  dispatch({
    type: UPDATE_ACTIVE_MODAL,
    payload: modalName,
  });
};

export const toggleAppDrawer = () => (dispatch) => {
  dispatch({
    type: TOGGLE_APP_DRAWER,
  });
};
