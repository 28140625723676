import React from 'react';
import { Grid } from '@material-ui/core';
import Checkbox from 'components/common/checkbox/Checkbox';
import * as Styles from 'pages/member/user-management/UserManagement.style';
import { useTranslation } from 'react-i18next';

const Roles = ({ panel, isCheck, setIsCheck, labels = [], panelRole }) => {
  const { t } = useTranslation();
  const data = [
    {
      label: 'Admin',
      label_lang: 'account/usermgmt.string9',
      id: 'profile',
      data: labels.filter((role) => role.category === 'profile'),
    },
    {
      label: 'Billing and payments',
      label_lang: 'account/usermgmt.string10',
      id: 'billing',
      data: labels.filter((role) => role.category === 'billing'),
    },
    {
      label: 'Bare metal dedicated server management',
      label_lang: 'account/usermgmt.string11',
      id: 'bm',
      data: labels.filter((role) => role.category === 'bm'),
    },
    {
      label: 'ColossusCloud virtual server management',
      label_lang: 'account/usermgmt.string12',
      id: 'vs',
      data: labels.filter((role) => role.category === 'vs'),
    },
  ];

  // HANDLING CLICK OF ROLE CHECKBOX
  const handleRoleClick = (value, role) => {
    // WHEN CLICKED ROLE IS PARENT
    if (role.category_relationship == 'parent') {
      const categoryRoles = data.find((item) => item.id === role.category);
      let roles = {};
      for (const obj of categoryRoles.data) {
        roles = { ...roles, [`${obj.id}`]: value ? isCheck[obj.id] : 0 };
      }
      setIsCheck({ ...isCheck, ...roles, [role.id]: value ? 1 : 0 });
    } else {
      let parentRole = data.find((item) => item.id === role.category);
      parentRole = parentRole.data?.find((parent) => parent.category_relationship == 'parent');
      setIsCheck({ ...isCheck, [`${role.id}`]: value ? 1 : 0, [parentRole.id]: value ? 1 : isCheck[parentRole.id] });
    }
  };
  return (
    <React.Fragment>
      {data.map((roles) => {
        const count = roles?.data?.length / 2;
        const firstColumn = roles?.data?.slice(0, count + 1);
        const secondColumn = roles?.data?.slice(count + 1, roles?.data?.length);
        const mapData = [firstColumn, secondColumn];
        return (
          <Grid xs={12} xl={12} className="pt-4" container key={roles.label}>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Styles.RolesLabel>{t(roles.label_lang)}</Styles.RolesLabel>
            </Grid>
            <Grid
              className="mb-3"
              container
              xs={12}
              sm={panel ? 12 : 12}
              md={panel ? 12 : 12}
              lg={panel ? 8 : 9}
              xl={8}
            >
              {mapData.map((obj, index) => (
                <Styles.RolesFlex panelRole={panelRole} key={index}>
                  {obj?.map((role) => (
                    <div key={role.id}>
                      <div className="d-flex">
                        <Checkbox
                          type="checkbox"
                          value={isCheck[`${role.id}`]}
                          setValue={(value) => handleRoleClick(value, role)}
                          title={role.description_en}
                          id={panel ? `panel-${role.id}` : role.id}
                          labelStyle={{
                            paddingTop: '7px',
                            fontWeight: '600',
                            fontSize: '14px',
                            marginLeft: '-8px',
                          }}
                          exclaimation={role.warning_en}
                          tooltip={role.warning_en}
                        />
                      </div>
                    </div>
                  ))}
                </Styles.RolesFlex>
              ))}
            </Grid>
          </Grid>
        );
      })}
    </React.Fragment>
  );
};

export { Roles };
