export const parseBackUpValues = (backup_plan) => {
  switch (backup_plan) {
    case 'backupplan0':
      return 'plan0';
    case 'backupplan1':
      return 'plan1';
    case 'backupplan2':
      return 'plan2';
    default:
      break;
  }
};
