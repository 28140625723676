/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-28 01:29:03
 * @modify date 2021-01-28 20:49:19
 */

import styled from 'styled-components';

export const NotFoundSC = styled.div`
  flex: 1;
  height: 100%;
`;

export const Card = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 8px;
  padding: 100px 0;
  text-align: center;
  color: ${(props) => props.theme.gray.shade2};
  background: ${(props) => props.theme.gray.shadeF};
  transition: all 250ms ease;
`;

export const Heading = styled.h2`
  font-size: 96px;
`;

export const SubHeading = styled.p`
  text-align: center;
`;
