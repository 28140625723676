/* eslint-disable no-unused-vars */
import React from 'react';
import { Form } from 'formik';
import * as Styles from 'pages/member/wh/WH.style';
import Input from 'components/input';
import CustomButton from 'components/common/custom-button';
import { Icon } from 'semantic-ui-react';
import priceCalculator from 'utils/price-calculator';
import { useState } from 'react';
import { H4, H6, P } from 'components/reusable-css';

const WHDomainForm = ({ formikProp, domainExtData, domainLookUploading, isError, isSuccess, parentValues }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikProp;
  const domainPrices = domainExtData?.prices_domain_extensions.find((item) => item.id === values.selectedDomainExt);
  return (
    <>
      <Form>
        <Styles.SearchDomainInput>
          <Styles.SearchDomainInputParent>
            <Input
              largerFont
              value={values['new_domain']}
              placeholder={'Search for your new domain'}
              onChange={handleChange}
              onFocus={() => {
                setInputFocus(true);
              }}
              onBlur={(e) => {
                setInputFocus(false);
                handleBlur(e);
              }}
              error={errors['new_domain']}
              touched={touched['new_domain']}
              styling={{
                width: '100%',
                maxWidth: '50em',
                paddingRight: '100px !important',
                marginBottom: 0,
              }}
              type="text"
              errorStyles={{ display: 'flex' }}
              name={'new_domain'}
              // inputProps={{
              //   maxLength: 64,
              // }}
            />
            <Styles.Select
              validateDomain={errors['new_domain'] && touched['new_domain']}
              inputFocus={inputFocus}
              type="button"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Icon name="caret down" />
              <H4>.{values.selectedDomainExt}</H4>
            </Styles.Select>
          </Styles.SearchDomainInputParent>
          <Styles.SearchDomainButton>
            <CustomButton type="submit" title={'Search'} isLoading={domainLookUploading} className="search-button" />
          </Styles.SearchDomainButton>
        </Styles.SearchDomainInput>
      </Form>
      {isOpen && (
        <Styles.DropDownDomain>
          {domainExtData?.prices_domain_extensions?.map((item) => (
            <Styles.domainName
              selectedDomainExt={values.selectedDomainExt}
              item={item}
              onClick={() => {
                setFieldValue('selectedDomainExt', item.id);
                setIsOpen(false);
              }}
              key={item.id}
            >
              .{item.id}
            </Styles.domainName>
          ))}
        </Styles.DropDownDomain>
      )}

      {isSuccess && (
        <Styles.AvailDomainContainer>
          <div className="domain">
            <div className="isDomainAvail">
              <Icon name="check" />
              <P className="para">DOMAIN AVAILABLE</P>
            </div>
          </div>
          <div className="domainpriceCon">
            <H6 className="para_break">{`${parentValues.domain}`}</H6>
            <H6 className="para">
              <span>USD {priceCalculator(domainPrices.price_yearly_usd)}/year</span>
            </H6>
          </div>
        </Styles.AvailDomainContainer>
      )}
      {isError && (
        <Styles.NotAvailDomainContainer>
          <div className="domain">
            <div className="isDomainAvail">
              <Icon name="ban" />
              <P className="para">THIS DOMAIN IS ALREADY TAKEN; PLEASE CHOOSE ANOTHER</P>
            </div>
          </div>
          <div className="domainpriceCon">
            <H6 className="para_break">{`${parentValues.domain}`}</H6>
          </div>
        </Styles.NotAvailDomainContainer>
      )}
    </>
  );
};

export default WHDomainForm;
