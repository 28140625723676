import React from 'react';
import { PassSC, Pass, ChangePasswords, FormDiv } from './ChangePassword.style';
import { Trans, useTranslation } from 'react-i18next';
import { Highlight } from 'components/vm-deployment/components';
import { change_password_form } from 'constants/account-profile';
import { Grid } from '@material-ui/core';
import Input from 'components/input';
import { Formik, Form } from 'formik';
import { changePasswordInitialValues, changePasswordValidationSchema } from 'constants/account-profile';
import CustomButton from 'components/common/custom-button';
import { useMutation } from 'react-query';
import { CHANGE_PASSWORD } from '../queries';
import { Message } from 'components/alert/Message';
import 'components/input/input.css';
import { validationsTooltip, validation } from 'utils/password-validation-tooltip';
import { Heading } from 'components/user-management/Heading';
import { P } from 'components/reusable-css';
import { Label, Margin } from 'assets/css/common-styles';

const ChangePassword = ({ username, width }) => {
  const { t } = useTranslation();
  const { mutate: changePassword, isLoading: changePasswordLoading } = useMutation(CHANGE_PASSWORD);

  return (
    <Formik
      initialValues={changePasswordInitialValues('')}
      validationSchema={changePasswordValidationSchema}
      onSubmit={async (values, { resetForm }) => {
        await changePassword(
          {
            original_password: btoa(values.original_password),
            new_password: btoa(values.new_password),
            new_password_confirm: btoa(values.new_password_confirm),
          },
          {
            onSuccess: (res) => {
              Message.success(t(`${res.status}`), t('success'));
              resetForm();
            },
            onError: (err) => {
              Message.error(t(`${err.response.data.error}`));
            },
          },
        );
      }}
    >
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <Form>
          <PassSC>
            <Pass>
              <Heading>
                <Trans i18nKey="account/profile.string1" components={{ highlight: <Highlight /> }} />
              </Heading>
              <ChangePasswords>
                <P>{t('account/profile.string2')}</P>
                <FormDiv>
                  <Grid container>
                    <Grid xs={6} sm={3} lg={3} xl={2}>
                      <Label>{t('account/profile.string3')}</Label>
                    </Grid>
                    <Grid xs={6} sm={2} lg={2} xl={3}>
                      <Margin>{username}</Margin>
                    </Grid>
                  </Grid>
                  {change_password_form.map((item, i) => (
                    <Grid key={item.name} container>
                      <Grid xs={12} sm={3} lg={3} xl={2}>
                        <Label>{t(item.lang)}</Label>
                      </Grid>
                      <Grid xs={12} sm={10} md={6} lg={5} xl={3}>
                        <Input
                          styling={width}
                          type={item.type}
                          className="custom_inputs"
                          name={item.name}
                          value={values[item.name]}
                          placeholder={t(item.placeholder_lang)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors[item.name]}
                          touched={touched[item.name]}
                          dataTooltip={i != 0 && validationsTooltip(values[item.name])}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </FormDiv>
                <CustomButton
                  disabled={
                    !validation(values.new_password) ||
                    !validation(values.new_password_confirm) ||
                    errors.new_password_confirm ||
                    !values.original_password
                  }
                  type="submit"
                  buttonStyles={{ padding: ' 9px 55px' }}
                  isLoading={changePasswordLoading}
                />
              </ChangePasswords>
            </Pass>
          </PassSC>
        </Form>
      )}
    </Formik>
  );
};
export default ChangePassword;
