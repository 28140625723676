import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';

export const CardDetails = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};

`;
export const CardNumber = styled.span`
  font-weight: bolder;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};

`;
export const DefaultLabel = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  color: ${(props) => props.theme.primaryDarkColor};
`;
export const StoredCards = styled.div`
  margin: 1rem 2rem;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 550px) {
    margin: 1rem -0.3rem;
  }
`;
export const CardAction = styled.p`
  color: blue;
  text-decoration: underline;
  ${(props) => pStyles(props.theme.resolutions)};
  color: ${(props) => props.theme.primaryDarkColor};
  font-weight: 500;
  width: ${(props) => props.width}px;
  &: hover {
    cursor: pointer;
  }
 span{
    width: 1rem;
    margin: 1.5rem;
  }
`;

export const CardContainer = styled.p`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  ${(props) => pStyles(props.theme.resolutions)};
  color: ${(props) => props.theme.primaryDarkColor};
`;