import React from 'react';
import { PaymentMethodContainer, PaymentMethodButton, PaymentMethodSingleButton } from '../Checkout.style';
import { P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';
import Heading from 'components/Heading/Heading';
import { isSafari } from 'react-device-detect';

const buttons = [
  { id: 1, heading: 'string33', text: 'string19' },
  { id: 0, heading: 'string34', text: 'string18' },
  { id: 2, heading: 'string35', text: 'string20' },
  // { id: 3, heading: 'string36', text: 'string21' },
  { id: 3, heading: 'string37', text: 'string22' },
  { id: 4, heading: 'string38', text: 'string25' },
  { id: 5, heading: 'string39', text: 'string26' },
];

const PaymentMethod = ({ selected, handleClick }) => {
  const { t } = useTranslation();

  return (
    <PaymentMethodContainer>
      <Heading name="public/checkout.string17">
        <PaymentMethodButton>
          {buttons.map((data) => (
            <PaymentMethodSingleButton
              greyOut={data.id == 3 && !isSafari}
              selected={data.id == selected}
              key={data.id}
              onClick={() => {
                data.id != 3 ? handleClick(data.id) : data.id == 3 && isSafari ? handleClick(data.id) : null;
              }}
            >
              <P>{t(`public/checkout.${data.text}`)}</P>
            </PaymentMethodSingleButton>
          ))}
        </PaymentMethodButton>
        {selected != 100 && <P>{t(`public/checkout.${buttons[selected]?.heading}`)}</P>}
      </Heading>
    </PaymentMethodContainer>
  );
};

export default PaymentMethod;
