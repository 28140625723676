import React from 'react';
import * as Styles from './AppDrawer.style';
import LogoImage from 'assets/images/logo-large.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CustomButton from 'components/common/custom-button';
import { navLinks } from 'utils/nav-links-util';
import { getLocalStorageValues } from 'constants/local-storage';

const AppDrawer = ({ isAppDrawerOpen }) => {
  let history = useHistory();
  const { promoCode } = getLocalStorageValues();

  return (
    <Styles.AppDrawerSC isAppDrawerOpen={isAppDrawerOpen}>
      <Styles.AppDrawerContainer>
        <Styles.AppDrawerHeader>
          <Styles.LogoImageSC src={LogoImage} alt="logo" />
        </Styles.AppDrawerHeader>
        <div>
          {history?.location?.pathname?.includes(navLinks.public_checkout_vs_summary.link) && (
            <Styles.GoBackButton>
              <CustomButton
                buttonStyles={{ padding: '.5em 3em' }}
                title={'public/checkout.string24'}
                onClick={() => history.replace(navLinks.public_checkout_vs.link + promoCode)}
              />
            </Styles.GoBackButton>
          )}
        </div>
      </Styles.AppDrawerContainer>
    </Styles.AppDrawerSC>
  );
};
export default AppDrawer;
