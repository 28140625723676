import React, { useEffect } from 'react';
import CustomButton from 'components/common/custom-button';
import Input from 'components/input';
import Select from 'components/select';

const PrimaryForm = ({ areaCodeArr, formikProps, isLoading, get2fadata, mobile }) => {
  const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikProps;
  useEffect(() => {
    const { primary_2fa_area_code, primary_2fa_phone } = get2fadata;
    setFieldValue('primary_2fa_area_code', primary_2fa_area_code);
    setFieldValue('primary_2fa_phone', primary_2fa_phone);
  }, [get2fadata]);
  return (
    <div className="d-flex">
      <div style={{ width: mobile ? '270px' : '360px', display: 'flex', zIndex: 10000 }}>
        <Select
          options={areaCodeArr || []}
          name={'primary_2fa_area_code'}
          value={values['primary_2fa_area_code']}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors['primary_2fa_area_code']}
          touched={touched['primary_2fa_area_code']}
        />
        <Input
          styling={{ marginTop: '-6px', marginLeft: '10px', width: '100%' }}
          name={'primary_2fa_phone'}
          value={values['primary_2fa_phone']}
          onChange={(event) => {
            const numericValue = event.target.value.replace(/[^0-9]/g, '');
            setFieldValue('primary_2fa_phone', numericValue);
          }}
          onBlur={handleBlur}
          error={errors['primary_2fa_phone']}
          touched={touched['primary_2fa_phone']}
          placeholderClass
        />
      </div>
      <CustomButton
        type="submit"
        buttonStyles={{ marginLeft: '10px', marginTop: '20px', height: '49px', padding: '0 21px' }}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PrimaryForm;
