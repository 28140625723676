/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-02 10:42:11
 * @modify date 2021-02-02 10:42:11
 */

import React from 'react';
import PropTypes from 'prop-types';

import { StepLabelSC, StepCount, StepHeading } from '../styles/StepLabel.style';

export const StepLabel = ({headingFontWeight, id, stepCount, children })=> {
  return (
    <StepLabelSC id={id}>
      <StepCount>{stepCount}</StepCount>
      <StepHeading headingFontWeight={headingFontWeight}>{children}</StepHeading>
    </StepLabelSC>
  );
};
StepLabel.propTypes = {
  /** doc */
  stepCount: PropTypes.string,
  children: PropTypes.any.isRequired,
  id: PropTypes.string,
};

StepLabel.defaultProps = {
  stepCount: '1',
};
