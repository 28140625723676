import styled from 'styled-components';

export const PassSC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.pageBackgroundColor};
  width: 100%;
  color: black;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;

export const Pass = styled.div`
  margin: 70px 45px 7em 40px;
  @media only screen and (max-width: 786px) {
    margin: 30px 20px 7em 0;
  }
`;

export const Heading = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 40px;
  h1 {
    font-size: 30px;
    font-weight: 900;
    padding-top: 10px;
  }
`;

export const ChangePasswords = styled.div`
  margin: 20px 0 0 4em;
  @media only screen and (max-width: 786px) {
    margin: 20px 0 0 3.5em;
  }
  @media only screen and (max-width: 425px) {
    margin: 20px 0 0 2.6em;
  }
`;

export const FormDiv = styled.div`
  padding-bottom: 2.4em;
`;
