/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-27 17:35:42
 * @modify date 2021-02-05 10:55:29
 */

export const variables = {
  headerHeight: 75,
  appDrawer: {
    expandedWidth: 330,
    mobileExpandedWidth: 260,
    sectionLinksWidth: 82,
  },
  pageContentPadding: 15,
};

export const zIndex = {
  appDrawer: 9999,
};

export const deviceBreakpoints = {
  lLaptop: 1440,
  mLaptop: 1280,
  tablet: 1024,
  mobile: 768,
  sLaptop: 768,
  phone: 450,
  sPhone: 384,
};
