import React from 'react';
import WHStep2 from './WHStep2';

export default function WHStep2Container({
  whSizesData,
  whSizesPricesData,
  formikProp,
  whSizesDataDescription,
}) {
  const { setFieldValue, values } = formikProp;

  const handleSizeSelection = (data) => {
    const price = whSizesPricesData?.prices_wh_sizes?.find((val) => data.id == val.id);
    setFieldValue('size', data);
    setFieldValue('prices.yearly.size', +price?.price_yearly_usd);
    setFieldValue('prices.size', +price?.price_monthly_usd);
  };

  return (
    <WHStep2
      selectedSize={values.size}
      handleSizeSelection={handleSizeSelection}
      whSizesData={whSizesData}
      whSizesDataDescription={whSizesDataDescription}
      whSizesPricesData={whSizesPricesData}
      formikProp={formikProp}
    />
  );
}
