import React from 'react';
import tooltipArrow from 'assets/images/input-tooltip-arrow.png';
import { Tooltip, TooltipHeader, TooltipBody, CornerArrow, CornerArrowRight } from './styles/input-tooltip';
import ConditionalFragment from 'components/conditional-fragment';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

const InputTooltip = ({ dataTooltip, tooltipStyles }) => {
  const { t } = useTranslation();
  const { leftPosition, headingSize, descriptionSize, minWidth, right, width, bottomPosition, arrowSize } =
    tooltipStyles || {};
  return (
    <ConditionalFragment condition={dataTooltip}>
      <Tooltip bottomPosition={bottomPosition} minWidth={minWidth} width={width} leftPosition={leftPosition}>
        <TooltipHeader headingSize={headingSize}>{t('string12')}</TooltipHeader>
        <TooltipBody descriptionSize={descriptionSize}>{ReactHtmlParser(dataTooltip)}</TooltipBody>
        {right == 0 ? (
          <CornerArrowRight arrowSize={arrowSize} src={tooltipArrow} />
        ) : (
          <CornerArrow arrowSize={arrowSize} src={tooltipArrow} />
        )}
      </Tooltip>
    </ConditionalFragment>
  );
};
export { InputTooltip };
