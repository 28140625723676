import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';
import { deviceBreakpoints, variables } from 'variables';

export const NewVMStep4SC = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  padding: ${variables.pageContentPadding}px;
  padding-top: 60px;
  // padding-top: 75px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    padding-top: 0;
  }
`;

export const StepDescription = styled.div`
  margin: 30px 0;
  width: 100%;
  display: flex;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Description = styled.div`
  width: 82%;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    margin-top: 1em;
    width: 100%;
  }
`;

export const Paragraph = styled.p`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;

export const Image = styled.div`
  width: 18%;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    width: 15em;
  }
`;

export const AnchorTag = styled.a`
  padding: 0 5px;
  color: black;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: black;
  }
`;
