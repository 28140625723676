import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    alignItems: 'center',
  },
}));

export default function ActionAlerts({ open, handleClose, text, severity, link }) {
  const classes = useStyles();

  return (
    <>
      {open && (
        <Alert
          className={classes.root}
          color={severity === 'error' ? 'error' : 'success'}
          severity={severity}
          onClose={handleClose}
        >
          {text} {link}
        </Alert>
      )}
    </>
  );
}

ActionAlerts.propTypes = {
  /** Child will populate the styleguidist content with the Providers. */
  open: PropTypes.any,
  handleClose: PropTypes.any,
  text: PropTypes.any,
  severity: PropTypes.any,
};
