import React from 'react';
import * as Styles from '../styles/ForgotPassword.style';
import { useTranslation } from 'react-i18next';
import LogoImage from 'assets/images/serverpoint.svg';
import Input from 'components/input';
import CustomButton from 'components/common/custom-button';
import { REQUEST_PASSWORD_RESET } from '../queries';
import { useMutation } from 'react-query';
import { Formik, Form } from 'formik';
import { CustomHook } from 'utils/custom-hook';
import { passRecoveryInitial } from 'constants/signUp';
import { H6 } from 'components/reusable-css';
import { EMAIL, USER_NAME } from 'constants/yup';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { mutationProps } = CustomHook();
  const { mutate: passwordRecovery, isLoading: passRecoveryLoading } = useMutation(REQUEST_PASSWORD_RESET);
  const handleSubmit = async (values) => {
    await passwordRecovery({ logininfo: values.password_recovery_email }, mutationProps());
  };

  return (
    <Styles.BgLogin>
      <Styles.LoginContainer>
        <Styles.LoginBox>
          <Styles.Logo>
            <img src={LogoImage} alt="serverpoint" width="100%" />
          </Styles.Logo>
          <Styles.LoginForm>
            <H6>{t('forgot-password.string1')} </H6>
            <Formik
              initialValues={passRecoveryInitial}
              validate={async (values) => {
                const errors = {};
                let isValidUserName = await USER_NAME.isValid({
                  password_recovery_email: values.password_recovery_email,
                });
                let isValidEmail = await EMAIL.isValid({
                  password_recovery_email: values.password_recovery_email,
                });
                if (!(isValidUserName ^ isValidEmail)) {
                  errors.password_recovery_email = 'forgot-password.string5';
                }
                return errors;
              }}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ values, handleChange, handleBlur, errors, touched, isValid }) => (
                <Form>
                  <Input
                    name={'password_recovery_email'}
                    value={values['password_recovery_email']}
                    placeholder={t('forgot-password.string2')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors['password_recovery_email']}
                    touched={touched['password_recovery_email']}
                  />
                  <Styles.Paragraph>{t('forgot-password.string3')}</Styles.Paragraph>
                  <div className="mt-4 cursor-pointer position-relative float-right">
                    <div className="position-absolute icon">
                      <i className="fa fa-envelope" />
                    </div>
                    <CustomButton
                      disabled={!isValid || values.password_recovery_email.trim() == ''}
                      isLoading={passRecoveryLoading}
                      buttonStyles={{
                        paddingLeft: '2.5rem',
                      }}
                      title={t('forgot-password.string4')}
                      type="submit"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </Styles.LoginForm>
        </Styles.LoginBox>
      </Styles.LoginContainer>
    </Styles.BgLogin>
  );
};

export { ForgotPassword };
