/* eslint-disable no-unused-vars */
/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-28 02:21:50
 * @modify date 2021-01-28 02:21:50
 */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppDrawer from './AppDrawer';
import { updateActiveSectionIndex } from 'store/actions/common-actions';

export default function AppDrawerContainer({ ui, menuData }) {
  const dispatch = useDispatch();
  // const location = useLocation();
  const sectionIndex = useSelector((state) => state.commonStates.activeSectionIndex);
  // const drawerItems = sideNavLinks().filter((obj) => (typeof obj.show === 'undefined' ? true : obj.show));

  // const indexNumber = useMemo(
  //   () =>
  //     drawerItems.findIndex((item) =>
  //       item.links.some((path) => {
  //         const match =
  //           typeof path.link === 'string' && location.pathname
  //             ? matchPath(location.pathname, {
  //                 path: path.link,
  //                 exact: true,
  //                 strict: true,
  //               })
  //             : null;
  //         return !!match;
  //       }),
  //     ),
  //   [location.pathname],
  // );
  const [activeSectionIndex, setActiveSectionIndex] = useState(sectionIndex);
  const [activeMobileSectionIndex, setActiveMobileSectionIndex] = useState('');
  const isAppDrawerOpen = useSelector((state) => state.commonStates.isAppDrawerOpen);

  const handleOnSectionClick = (index) => {
    dispatch(updateActiveSectionIndex(index));
    setActiveSectionIndex(index);
  };
  const handleOnMobileSectionClick = (index) => {
    if (activeMobileSectionIndex === index) {
      setActiveMobileSectionIndex('');
    } else {
      setActiveMobileSectionIndex(index);
    }
  };

  // useEffect(() => {
  //   if (typeof indexNumber !== 'undefined') {
  //     setActiveSectionIndex(indexNumber > 0 ? indexNumber : 0);
  //   }
  // }, [indexNumber]);

  return (
    <AppDrawer
      activeSectionIndex={activeSectionIndex}
      activeMobileSectionIndex={activeMobileSectionIndex}
      isAppDrawerOpen={isAppDrawerOpen}
      onSectionClick={handleOnSectionClick}
      onMobileSectionClick={handleOnMobileSectionClick}
      ui={ui}
      menuData={menuData}
    />
  );
}
