import CustomButton from 'components/common/custom-button';
import React, { useState } from 'react';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import { managerBmSortBy, managerVmSortBy } from 'constants/manager-vm';
import { getSortTitle } from 'components/manage-vm/utils';
import { useTranslation } from 'react-i18next';
import Modals from 'components/modal';
import { H5 } from 'components/reusable-css';

const SortCard = ({ setQueries, queries, type }) => {
  const { t } = useTranslation();
  const [openSort, setOpenSort] = useState(false);
  const handleOpen = () => setOpenSort(true);
  const handleClose = () => setOpenSort(false);

  return (
    <div>
      <Styles.Button type="button" onClick={handleOpen}>
        {t('vs/instance.string12')} : <span>{t(`vs/instance.${getSortTitle(queries.sort_by)?.lang}`)}</span>
      </Styles.Button>
      <Modals open={openSort} onClose={handleClose}>
        <Styles.SortBy>
          <Styles.FilterContent>
            <H5>{t('vs/instance.string12')}</H5>
            <Styles.GreyBorder />
            <Styles.Buttons>
              {(type == 'bm' ? managerBmSortBy : managerVmSortBy).map((item, index) => {
                return (
                  <CustomButton
                    key={index}
                    title={t(`vs/instance.${item.lang}`)}
                    buttonStyles={{
                      background: '#f8f8f8',
                      border: '1px solid #cecece',
                      marginBottom: '10px',
                      color: 'grey',
                      padding: ' 8px 16px',
                      marginRight: '8px',
                      borderBottom: queries.sort_by === item.value ? '2.9px solid #FF7300' : '1px solid #cecece',
                    }}
                    onClick={() => {
                      setQueries({ ...queries, sort_by: item.value });
                      setOpenSort(false);
                    }}
                  />
                );
              })}
            </Styles.Buttons>
          </Styles.FilterContent>
        </Styles.SortBy>
      </Modals>
    </div>
  );
};
export { SortCard };
