import { h1Styles, h2Styles, h5Styles, h6Styles, pStyles } from 'components/reusable-css';
import styled, { css } from 'styled-components';
import { variables } from 'variables';

export const WHSC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: sans-serif;
`;

//---------------------------------Step1----------------------//

export const WHStep1SC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.theme.pageBackgroundColor};
  padding: ${variables.pageContentPadding}px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  padding-right: 0px;
  padding-bottom: 0px;
`;

export const Margin = styled.div`
  margin: 2em 0;
  padding-right: ${variables.pageContentPadding}px;
  padding-bottom: ${variables.pageContentPadding}px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  cursor: pointer;

  h6 {
    white-space: nowrap;
    margin: 0;
  }
`;

export const NoNeedDomain = styled.div`
  margin-bottom: 2rem;
  margin-left: 2.1rem;

  p {
    margin-top: 1rem;
    span {
      color: ${(props) => props.theme.primaryDarkColor};
    }
  }
`;

export const SearchDomainInput = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  .error_messageSty {
    /* border-bottom-left-radius: 5px; */
    margin-left: 6px;
  }
  @media only screen and (max-width: 500px) {
    flex-wrap: wrap;
  }
`;
export const domainName = styled.h6`
  background: ${(props) => (props.selectedDomainExt === props.item.id ? '#d8d8d8' : null)};
  &:hover {
    background-color: #d8d8d8;
  }
`;
export const SearchDomainInputParent = styled.div`
  position: relative;
  width: 100%;
  max-width: 50em;
`;
export const SearchDomainButton = styled.div`
  .search-button {
    padding: 1.3rem 1.5rem;
    margin-left: 1rem;
    margin-bottom: 0px;
    @media only screen and (max-width: 500px) {
      margin: 10px 0px;
    }
  }
`;

export const DropDownDomain = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #d8d8d8;
  height: 13em;
  flex-wrap: wrap;
  padding-top: 8px;
  border-bottom: 2.9px solid orange;
  margin-top: 5px;
  width: 63em;
  h6 {
    padding: 2px 0 2px 10px;
    margin: 0;
    cursor: pointer;
    font-size: ${(props) => props.theme.default?.h6};
    ${(props) => h6Styles(props.theme.resolutions)};
  }
`;

export const Select = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  background: white;
  border: 0px;
  position: absolute;
  top: 40px;
  right: 10px;
  cursor: pointer;
  /* border-radius: 0px;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top: ${(props) => (props.validateDomain && props.inputFocus ? '2px solid #d80000' : '2px solid #d8d8d8')};
  border-bottom: ${(props) =>
    props.validateDomain ? `${props.inputFocus ? '2px' : '2.9px'} solid #d80000` : '2.9px solid orange'};
  border-right: ${(props) => (props.validateDomain && props.inputFocus ? '2px solid #d80000' : '2px solid #d8d8d8')};
  border-left: 0px;
  outline: 0;
  height: 50px;
  padding: 0 14px;
  cursor: pointer;
  padding-top: 5px;
  margin-top: 15px;
  width: 180px; */
  i {
    font-size: 22px;
    margin-top: -8px;
  }
  h4 {
    margin: 0;
  }
  outline: none;
`;

export const AvailDomainContainer = styled.div`
border-radius: 10px;
padding: .5rem 1rem;
width:30em;
margin-top: 1rem;
background-color: #f8f2f5;
border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  .domain{
    display:flex;
    .isDomainAvail{
      display:flex;
      align-items:center;
      background-color:green;
      padding:6px 8px;
      margin-top:.5rem ;
      margin-bottom:1rem ;
      border-radius:3px;
      i{
        margin-bottom:7px;
        margin-right:6px;
        color:white;
      }
      .para {
        margin: 0;
        color:white;
        font-weight: bold;
      }
    }
  }

  .domainpriceCon{
    display:flex;
    justify-content:space-between;
    .para {
      margin: 0;
      font-weight: bolder;
      span {
        color: ${(props) => props.theme.primaryDarkColor};
      }
    }
    .para_break{
      margin: 0;
      margin-right:1em;
      font-weight: bolder;
      word-break: break-all;
    }
  }
}
`;
export const NotAvailDomainContainer = styled.div`
border-radius: 10px;
padding: .5rem 1rem;
width:30em;
margin-top: 1rem;
background-color: #f8f2f5;
border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
.domain{
  display:flex;
  .isDomainAvail{
    display:flex;
    align-items:center;
    background-color:red;
    padding:6px 8px;
    margin-top:.5rem ;
    margin-bottom:1rem ;
    border-radius:3px;
    i{
      margin-bottom:7px;
      margin-right:6px;
      color:white;
    }
    .para {
      margin: 0;
      color:white;
      font-weight: bold;
    }
  }
}

.domainpriceCon{
  display:flex;
  justify-content:space-between;
  .para {
    margin: 0;
    font-weight: bolder;
    span {
      color: ${(props) => props.theme.primaryDarkColor};
    }
  }
  .para_break{
    margin: 0;
    margin-right:1em;
    font-weight: bolder;
    word-break: break-all;
  }
}
}
`;

//////////////////---------step 2---------///////////////

export const WHStep2SC = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  padding: ${variables.pageContentPadding}px;
  padding-top: 70px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  padding-right: 0px;
  padding-bottom: 0px;
`;

export const StepDescription = styled.div`
  margin: 50px 0;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  @media only screen and (max-width: 960px) {
    margin-bottom: 0px;
  }
`;

export const InlineSizesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24%, 1fr));
  padding-right: ${variables.pageContentPadding}px;
  @media only screen and (max-width: 960px) {
    display: block;
  }
  margin: 2em 10px 3em 10px;
`;

export const SizesContainer = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 5px;
  background: ${(props) => props.theme.gray.shadeF};
  min-width: 250px;
  width: 100%;
  cursor: pointer;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};

  ${(props) =>
    props.selected &&
    css`
      border-color: ${(props) => props.theme.primaryColor};
      border-radius: 10px;
    `};
`;

export const MostPopularTag = styled.div`
  display: flex;
  justify-content: center;
  visibility: ${(props) => (props.showTag ? 'visible' : 'hidden')};
  color: ${(props) => props.theme.gray.shadeF};
  width: 200px;
  border-top: 30px solid ${(props) => props.theme.primaryColor};
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  position: relative;
  top: -15px;
  left: 22px;
  font-style: italic;
  font-weight: bold;
  font-size: 16px;
  p {
    position: relative;
    top: -25px;
  }
`;

export const SizesHeading = styled.div`
  padding-bottom: 1rem;
  text-align: center;
  .size_name {
    font-size: 26px;
    font-weight: bolder;
    margin-top: -10px;
  }
  .flex_price {
    display: flex;
    justify-content: center;
    .border {
      border: 1px solid ${(props) => props.theme.serverSizeCard.borderColor};
    }
    p {
      margin: 0;
      padding: 0 10px;
      font-size: 14px;
      font-weight: bolder;
    }
  }
`;

export const SizesDiffHeading = styled.div`
  padding: 1rem;
  background: ${(props) => props.theme.pageBackgroundColor};
  margin-top: 5px;
  .head_name {
    font-size: 15px;
    font-weight: bolder;
  }
`;

export const SizesHeadingData = styled.div`
  padding: 0.5rem 1rem;
  p {
    font-size: 14px;
    font-weight: normal;
    margin: 3px 0;

    a {
      color: ${(props) => props.theme.primaryDarkColor};
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const SizesButton = styled.div`
  padding: 2rem 1rem 1rem;
`;

//----------------- container --------------------//

export const WHContainerSC = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  padding: ${variables.pageContentPadding}px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;

export const MarginContainer = styled.div`
  margin: 3em 0;
`;

export const FlexContainers = styled.div`
  display: flex;
  align-items: center;

  .imageWidth {
    width: 20%;
    display: flex;
    justify-content: center;
  }
  .paraWidth {
    width: 80%;
    margin: 0 1rem;
  }
`;

//------------------------ step3 ------------------//

export const WHStep3SC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.pageBackgroundColor};
  width: 100%;
  padding: ${variables.pageContentPadding}px;
  padding-top: 70px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  padding-bottom: 0px;
  padding-right: 0px;
`;

export const InlineFlex = styled.div`
  margin-bottom: 1.5em;
  padding-right: ${variables.pageContentPadding}px;
  padding-bottom: ${variables.pageContentPadding}px;
`;

export const FlexContainerWidth = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  cursor: pointer;
  flex-wrap: nowrap;
  // margin-right: 20px;
  border-bottom: 2px solid transparent;
  padding: 4px;

  &:hover {
    background-color: #cfcfcf;
    transition: background-color 0.3s ease;
    border-radius: 15px;
    border-bottom: 2px solid orange;
  }
  .flexData {
    margin: 0;
    font-weight: bolder;
    margin-left: 8px;
    white-space: nowrap;
  }
`;

///--------------------step4--------------------//

export const Heading = styled.div`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  color: #000;
  margin: 0;
  font-weight: 700;
  padding-left: 12px;
  text-transform: capitalize;
`;

export const SubHeading = styled.p`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  font-weight: 400 !important;
`;

export const Card = styled.div`
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  margin-right: 20px;
  cursor: pointer !important;
  border-bottom: 2px solid transparent;
  padding-top: 3px;
  padding-left: 4px;
  padding-bottom: 4px;

  &:hover {
    background-color: #cfcfcf;
    transition: background-color 0.3s ease;
    border-radius: 15px;
    border-bottom: 2px solid orange;
  }
`;
export const Card0 = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  margin-right: 20px;
  cursor: pointer !important;
  border-bottom: 2px solid transparent;
  padding-top: 3px;
  padding-left: 4px;
  padding-bottom: 4px;
  /* background-color: #cfcfcf; */
  transition: background-color 0.3s ease;
  border-radius: 15px;
  opacity: 0.2;
`;

//---------------------step5------------------------//

export const PaymentMethod = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  .para {
    font-size: 17px;
    margin: 0;
    width: 250px;
    font-weight: bold;
  }
  .price {
    font-size: 18px;
    font-weight: bolder;
    margin: 0 1rem;
  }
`;
export const HeadingStep5 = styled.h6`
  padding-top: ${(props) => (props.marginTop ? '2.8rem ' : '2rem')};
  font-weight: bold;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;

export const ParaStep5 = styled.h6`
  padding: 2rem 1rem 0 1rem;
  font-weight: bold;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;

export const PaymentCon = styled.div`
  border-radius: 10px;
  margin: 0.2rem;
  margin-left: 1em;
  padding-left: 1em;
  background: ${(props) => props.theme.serverSizeCard.borderColor};
  width: 250px;
  border: 2px solid transparent;
  cursor: pointer;
  &:hover {
    border-color: ${(props) => props.theme.primaryColor};
  }

  @media (max-width: 960px) {
    margin-left: 0;
    margin-top: 1em;
    margin-right: 0.5em;
  }

  .paymentprice {
    padding: 1em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 0;
    font-weight: bolder;
    flex-wrap: nowrap;
    span {
      font-size: 26px;
      font-weight: bolder;
    }
  }

  ${(props) =>
    props.selected &&
    css`
      border-color: ${(props) => props.theme.primaryColor};
    `};
`;

export const RedTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.showTag ? 'visible' : 'hidden')};
  align-self: flex-end;
  background: ${(props) => props.theme.serverSizeCard.saleRed.backgroundColor};
  color: ${(props) => props.theme.gray.shadeF};
  height: 28px;
  width: 230px;
  border-radius: 0 10px 0 20px;
  position: relative;
  top: -2px;
  right: -2px;
  font-style: italic;
  font-weight: bold;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;

export const PriceStep5 = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.3rem 0;
`;

export const PerMonthStep5 = styled.h6`
  font-weight: 700;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  margin: 0;
  color: black;
`;

export const PriceUnitStep5 = styled.span`
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
`;

//------last step------//

export const NewVMLastStepSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ef6c0f;
  align-items: center;
  justify-content: center;
  color: black;
  font-family: 'Open Sans', verdana, arial, sans-serif !important;
  @media only screen and (max-width: 765px) {
    display: flex;
    flex-flow: column wrap;
  }
`;
export const PriceServer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f1f2;
  padding: 28px 15px;
  justify-content: center;
  align-items: center;
  width: 60%;
  min-height: 205px;
  @media only screen and (max-width: 765px) {
    width: 100%;
    min-height: 150px;
  }
`;
export const VirtualServer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #dfdbda;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40%;
  height: 205px;
  @media only screen and (max-width: 765px) {
    width: 100%;
    height: 150px;
  }
`;
export const SubmitServer = styled.div`
  display: flex;
  flex-direction: wrap;
  .select {
    padding: 7px 25px 7px 15px;
    font-size: 20px;
    border: 2px solid #e3e6e4;
    border-bottom: 2px solid orange;
    border-radius: 8px;
    outline: 0;
    &:focus {
      background-color: #f0f0f0;
    }
  }
`;
export const Content = styled.div`
  text-align: right;
  .price {
    font-weight: 700;
  }
`;
export const Price = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
`;

export const PerMonth = styled.p`
  font-weight: 700;
  font-size: ${(props) => props.theme.default?.h5};
  ${(props) => h5Styles(props.theme.resolutions)};
  color: #ef6c0f;
`;

export const PriceUnit = styled.span`
  font-size: ${(props) => props.theme.default?.h1};
  ${(props) => h1Styles(props.theme.resolutions)};
`;

export const Paragraph = styled.span`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
`;
