import styled from 'styled-components';
import BackgroundImage from 'assets/images/bg_login_portal-client_grey.svg';
import { h4Styles, pStyles } from 'components/reusable-css';
import { Link } from 'react-router-dom';

export const SignInSC = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  font-weight: 400 !important;
  font-size: 16px !important;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignInContainer = styled.div`
  margin: 0 auto;
  padding: 2em 0;
`;

export const LogInButton = styled.div`
  width: auto;
  @media (max-width: 500px) {
    width: 100%;
    padding: 0 5px;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  @media (max-width: 500px) {
    flex-direction: column-reverse;
    gap: 15px;
  }
`;

export const Para = styled.div`
  padding: 0 12px;
  margin-top: 1em;
`;

export const LoginContainer = styled.div`
  box-shadow: 0 0 24px 5px #d1d1d1;
`;

export const SignInBox = styled.div`
  background: #fff;
  margin: 0 auto;
  width: 480px;
  border-radius: 5px;
  padding: 30px;
  .loader {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: -15px;
  }
  @media (max-width: 500px) {
    width: 350px;
  }
  @media (max-width: 400px) {
    width: 310px;
    padding: 40px 10px;
  }
  @media (max-width: 330px) {
    width: 290px;
  }
`;

export const Logo = styled.p`
  margin: 10px auto;
  text-align: center;
  width: 380px;
  @media (max-width: 425px) {
    width: 290px;
  }
  @media (max-width: 375px) {
    width: 250px;
  }
  @media (max-width: 375px) {
    width: 230px;
  }
`;

export const Heading = styled.h4`
  font-size: ${(props) => props.theme.default?.h4};
  ${(props) => h4Styles(props.theme.resolutions)};
  font-weight: bolder;
  color: black;
  text-align: center;
  margin: 0 3rem;
  @media (max-width: 425px) {
    padding-bottom: 5px;
  }
  @media (max-width: 500px) {
    margin: 0 2rem;
  }
  @media (max-width: 330px) {
    margin: 0 1rem;
  }
`;

export const LinkSC = styled(Link)`
  pointer-event: ${(props) => (props.loading ? 'none' : 'auto')};
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  font-size: ${(props) => props.theme.default?.p};
  margin-right: ${(props) => (props.margin ? props.margin : '0px')};
  ${(props) => pStyles(props.theme.resolutions)};
  text-align: center;
  text-decoration: underline;
  color: #ff7300;
  a {
    color: #ff7300;
  }
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #ff7300;
  }
`;


export const Linked = styled.span`
  pointer-event: ${(props) => (props.loading ? 'none' : 'auto')};
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  font-size: ${(props) => props.theme.default?.p};
  margin-right: ${(props) => (props.margin ? props.margin : '0px')};
  ${(props) => pStyles(props.theme.resolutions)};
  text-align: center;
  text-decoration: underline;
  color: #ff7300;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #ff7300;
  }
`;

export const PinSC = styled.div`
  text-align: center;
  padding-bottom: 2em;
  padding-top: 1em;
  input {
    width: 40px;
    height: 50px;
    border: 2px solid black;
    border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
    outline: none;
    line-height: 50px;
    text-align: center;
    font-size: 30px;
    border-radius: 5px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .splitter {
    padding: 0 5px;
    color: white;
    font-size: ${(props) => props.theme.default?.h4};
    ${(props) => h4Styles(props.theme.resolutions)};
  }
`;
