import React from 'react';
import * as Styles from './ServerSizeCard.style';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UpperCaseWord } from 'assets/css/common-styles';

const ServerSideStaticCard = () => {
  const userAccount = useSelector((state) => state.user.account);
  
  const { t } = useTranslation();
  return (
    <Styles.ServerSizeCardSC>
      <Styles.NameContainer style={{ paddingTop: '30px' }}>
        <Styles.Name>{t('bm/instance/create.step1_string3')}</Styles.Name>
      </Styles.NameContainer>
      <Styles.Price>
        <Styles.PerMonth>
          <Trans
            i18nKey={'bm/instance/create.step1_string4'}
            values={{
              currency: userAccount.account_currency,
              currency_symbol: userAccount.account_currency_symbol,
              price: 200,
            }}
            components={{ price: <Styles.PriceUnit />, uppercase: <UpperCaseWord /> }}
          />
        </Styles.PerMonth>
      </Styles.Price>
      <Styles.Features>
        <Styles.FeatureStatic>{t('bm/instance/create.step1_string5')}</Styles.FeatureStatic>
        <Styles.FeatureStatic>{t('bm/instance/create.step1_string6')}</Styles.FeatureStatic>
      </Styles.Features>
    </Styles.ServerSizeCardSC>
  );
};

export default ServerSideStaticCard;
