import styled from 'styled-components';
import RadioBtnCheck from 'assets/images/radio-btn-new.svg';

export const RadioCheck = styled.div`
  padding-top: 5px;
  padding-right: 10px;
`;

export const Input = styled.input`
  appearance: none;
  outline: 0 transparent !important;
  padding: 0;
  margin: 0 !important;
  top: 0;
  height: 20px;
  width: 20px;
  background: url(${RadioBtnCheck}) no-repeat;
  background-size: 20px;
  cursor: pointer !important;

  &:checked {
    background-position: 0 -20px;
  }
`;
