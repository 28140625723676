import { get } from 'lodash';
import { createSelector } from 'reselect';

export const userRights = createSelector(
  (state) => state.user.account,
  (rawValue) => {
    const array = new Map(
      Object.keys(get(rawValue, 'user_rights[0]', [])).map((i) => [i, +get(rawValue, `user_rights[0].${i}`)]),
    );
    return Object.fromEntries(array);
  },
);
