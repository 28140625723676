import React from 'react';
import * as Styles from './Styles';
import { P } from 'components/reusable-css';

const IPNumber = ({ data }) => {
  return (
    <Styles.Network>
      {data.networks.map((network) => {
        return network.eth0?.map((eth) => {
          return eth.ipv4?.map((ip) => (
            <P className="network-border" key={ip.ip}>
              {ip.ip}
            </P>
          ));
        });
      })}
    </Styles.Network>
  );
};
export { IPNumber };
