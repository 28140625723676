import React, { useState } from 'react';
import DesktopStatisticPanel from './DesktopStatisticPanel';
import { statisticPanelTabs } from 'constants/index';

const Statistics = ({ setOpenStatisticPanel, data, menuOptions, type }) => {
  const [activePanelValue, setActivePanelValue] = useState(statisticPanelTabs.statistics);
  return (
    <DesktopStatisticPanel
      data={data}
      menuOptions={menuOptions}
      setOpenStatisticPanel={setOpenStatisticPanel}
      activePanelValue={activePanelValue}
      setActivePanelValue={setActivePanelValue}
      type={type}
    />
  );
};

export default React.memo(Statistics);
