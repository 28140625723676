/* eslint-disable no-unused-vars */
import React from 'react';
import { NewVMStep2SC } from './NewVMStep2.style';
import WorldwideCloudRegion from 'components/vm-deployment/worldwide-cloud-region';
import { useTranslation, Trans } from 'react-i18next';
import ErrorMessage from 'components/error-message';
import { Highlight, StepLabel, StepLabelMobile } from 'components/common';
import { StepDescription } from 'assets/css/common-styles';
import Heading from 'components/Heading/Heading';

const NewVMStep2 = ({ regionsData, formikProp, categoryID, sizeData }) => {
  const { t } = useTranslation();

  return (
    <NewVMStep2SC id="ST2">
      {/* <StepLabel id="ST2" stepCount={t('vs/instance/create.step2_string1')}>
        <Trans i18nKey="vs/instance/create.step2_string2" components={{ highlight: <Highlight /> }} />
      </StepLabel>
      <StepLabelMobile stepCount={t('vs/instance/create.step2_string1')}>
        <Trans i18nKey="vs/instance/create.step2_string2" components={{ highlight: <Highlight /> }} />
      </StepLabelMobile> */}
      <Heading name={'vs/instance/create.step2_string2'}>
        <StepDescription />
        <WorldwideCloudRegion
          regionsData={regionsData}
          formikProp={formikProp}
          categoryID={categoryID}
          sizeData={sizeData}
        />
        {formikProp.errors.region && formikProp.touched.region && <ErrorMessage />}
      </Heading>
    </NewVMStep2SC>
  );
};

export default NewVMStep2;
