import { pricingDetails } from 'components/vm-deployment/constants/index';
import {
  UPDATE_CPANEL_OS,
  UPDATE_PRICING,
  VM_DEPLOYMENT,
  VM_DEPLOYMENT_PRICING,
  NO_OF_VM,
  VM_CREATION,
  VM_REFETCH,
} from 'store/types';

const initialState = {
  vm_deploy: {},
  pricingDetails,
  cPanelOs: '',
  noOfVM: 1,
  is_creation_in_process: false,
  handleVmRefetch: () => {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VM_DEPLOYMENT:
      return {
        ...state,
        vm_deploy: action.payload,
      };
    case NO_OF_VM:
      return {
        ...state,
        noOfVM: action.payload,
      };
    case VM_DEPLOYMENT_PRICING:
      return {
        ...state,
        pricingDetails: action.payload,
      };
    case UPDATE_PRICING:
      return {
        ...state,
        pricingDetails: {
          ...state.pricingDetails,
          [action.key]: action.payload,
        },
      };
    case UPDATE_CPANEL_OS:
      return {
        ...state,
        cPanelOs: action.payload,
      };
    case VM_CREATION:
      return {
        ...state,
        is_creation_in_process: action.payload,
      };
    case VM_REFETCH:
      return {
        ...state,
        handleVmRefetch: action.payload,
      };
    default:
      return state;
  }
}
