import { h2Styles, h4Styles, h6Styles, pStyles } from 'components/reusable-css';
import styled, { css } from 'styled-components';

export const Features = styled.div`
  flex: 1;
  padding: 25px 15px 4em 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${(props) => props.theme.serverSizeCard.features.backgroundColor};
  @media only screen and (max-width: 649px) {
    padding-bottom: 30px;
  }
`;

export const ServerSizeCardSC = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 100%;
  height: auto;
  background: ${(props) => props.theme.gray.shadeF};
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  overflow: hidden;
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    background: ${(props) => props.theme.serverSizeCard.hoverBackgroundColor};
    border-color: ${(props) => props.theme.serverSizeCard.hoverBorderColor};

    ${Features} {
      background: ${(props) => props.theme.serverSizeCard.features.hoverBackgroundColor};
    }
  }

  ${(props) =>
    props.selected &&
    css`
      border-color: ${(props) => props.theme.primaryColor};
    `};
`;

export const Tag = styled.div`
  display: flex;
  visibility: ${(props) => (props.showTag ? 'visible' : 'hidden')};
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  background: ${(props) => props.theme.serverSizeCard.tag.backgroundColor};
  color: ${(props) => props.theme.gray.shadeF};
  height: 28px;
  width: 200px;
  border-radius: 0 10px 0 20px;
  position: relative;
  top: -2px;
  right: -2px;
  font-style: italic;
  font-weight: bold;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;

export const RedTag = styled.div`
  display: flex;
  visibility: ${(props) => (props.showTag ? 'visible' : 'hidden')};
  align-items: center;
  justify-content: space-evenly;
  align-self: flex-end;
  background: ${(props) => props.theme.serverSizeCard.saleRed.backgroundColor};
  color: ${(props) => props.theme.gray.shadeF};
  height: 25px;
  width: 210px;
  border-radius: 0 10px 0 20px;
  position: relative;
  top: -2px;
  right: -2px;
  font-style: italic;
  font-weight: bold;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding: 0px 15px;
  font-weight: 500;
`;

export const Name = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.default?.h4};
  ${(props) => h4Styles(props.theme.resolutions)};
`;

export const ActualPrice = styled.div`
  display: flex;
  text-decoration: line-through;
  margin-left: 5px;
  font-weight: normal;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 15px;
  margin-bottom: 25px;
`;

export const PerMonth = styled.div`
  display: flex;
  align-items: baseline;
  font-weight: 900;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;

export const PriceUnit = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
  margin-left: 4px;
`;

export const Feature = styled.div`
  display: flex;
  line-height: 2em;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  i {
    color: ${(props) => props.theme.primaryColor};
    margin-right: 6px;
  }
  @media only screen and (max-width: 959px) {
    margin-right: 6px;
    margin-bottom: 10px;
    line-height: 1.2em;
  }
`;
export const FeatureStatic = styled.p`
  display: flex;
  line-height: 1.5em;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;

export const Extra = styled.p`
  display: flex;
  line-height: 0.8em;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;

export const SelectedTag = styled.div`
  display: flex;
  background: ${(props) => props.theme.serverSizeCard.selectedTag.backgroundColor};
  border-radius: 0;
  border-top-left-radius: 20px;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.gray.shadeF};
  height: 40px;
  width: 220px;
  position: absolute;
  bottom: -2px;
  right: -2px;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};

  i {
    margin-right: 6px;
    margin-bottom: 8px;
  }
`;
