import { h2Styles } from 'components/reusable-css';
import styled from 'styled-components';
import { deviceBreakpoints, variables } from 'variables';

export const NewVMStep4SC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.pageBackgroundColor};
  width: 100%;
  padding: ${variables.pageContentPadding}px;
  padding-right: 0;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  padding-top: 4em;
  padding-bottom: 0;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    padding-top: 0;
  }
`;

export const Heading = styled.h2`
  padding-right: 15px;
  margin-top: 30px;
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
  font-weight: 100;
`;

export const NotificationContainer = styled.div`
  display: flex;
  margin: 50px 0;
  padding-right: 15px;
`;

export const Padding = styled.div`
  padding-bottom: 4em;
`;

export const BackupOptions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5em;
  cursor: pointer;
  .cards {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-bottom: 2px solid transparent;
    padding-top: 3px;
    padding-right: 10px;
    h6 {
      font-weight: bold;
    }
    &:hover {
      background-color: #cfcfcf;
      transition: background-color 0.3s ease;
      border-radius: 15px;
      border-bottom: 2px solid orange;
    }
  }
`;
