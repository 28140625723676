import { cpuPriceOnly } from 'components/vm-deployment/util';
import { get } from 'lodash';
import priceCalculator from 'utils/price-calculator';
import { useSelector } from 'react-redux';
// import { useQuery } from 'react-query';

export const BackupLevels = () => {
  const userUI = useSelector((state) => state.user.ui);
  // const { data: backupLevelsPricingData } = useQuery('/prices/vs/backups');
  const calculatePrice = (data, priceServer) => {
    const percent = Number(get(get(data, 'pricing'), 'price_percentage_of_virtual_server', '0'));
    let price = 0;
    if (percent) {
      price = (percent / 100) * Number(cpuPriceOnly(priceServer));
    }
    return priceCalculator(price, false);
  };
  const isBackupRefetch = (data) => {
    const daily = get(data, 'vs_backups[0].vs_daily_backups', []).some((item) => item.status === 'progress');
    const archive = get(data, 'vs_backups[0].vs_archive_backups', []).some((item) => item.status === 'progress');
    if (daily || archive) {
      return { refetchInterval: Number(userUI.refresh_every_x_seconds) * 1000 };
    }
    return {};
  };

  return {
    calculatePrice,
    isBackupRefetch,
    // backupData: (data) =>
    //   get(backupLevelsPricingData, 'prices_vs_backups', []).find((obj) => obj.id == data.backups_plan),
  };
};
