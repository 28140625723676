import styled from 'styled-components';
import { variables } from 'variables';

export const NewVMStep1SC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${variables.pageContentPadding}px;
  padding-top: 30px;
  padding-bottom: 0px;
  padding-right: 0px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;
