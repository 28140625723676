import React from 'react';
import NewBMStep6 from './NewBMStep6';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

const NewVMStep6Container = ({ formikProp }) => {
  const { values, setFieldValue } = formikProp;

  const { data: paymentsData } = useQuery('/prices/bm/prepaid_options', {
    onSuccess: (res) => {
      setFieldValue('options_prepaid', _get(res, '/prices/bm/prepaid_options[0]', {}));
    },
  });

  const handleServerSizeSelection = (data) => {
    setFieldValue('options_prepaid', data);
  };

  return (
    <NewBMStep6
      formikProp={formikProp}
      paymentsData={paymentsData}
      selectedServerSize={values.options_prepaid}
      onServerSizeSelection={handleServerSizeSelection}
    />
  );
};
export default NewVMStep6Container;
NewVMStep6Container.propTypes = {
  formikProp: PropTypes.any,
};
