import React, { useEffect, useState } from 'react';
import { TabsHeader } from './TabsHeader';
import { menuOptions } from './util';
import * as Styles from './Styles';
import ConditionalFragment from 'components/conditional-fragment/ConditionalFragment';
import TriCut from 'assets/images/action-tri-cut.png';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import PaymentTerms from './PaymentTerms';
import Loading from 'components/common/loading';
import { IPNumber } from './IPNumber';
import { BASE_URL } from 'constants/index';
import axios from 'axios';

const SlidingPanelPaymentTerm = ({ setIsClose, vsInstanceArr, isLoading }) => {
  const [activePanel, setIsActivePanel] = useState('payment_terms');
  const [isShow, setIsShow] = useState(0);
  const toggle = (value) => {
    setPaymentTerm(0);
    setIsShow(value);
  };
  const [paymentTerm, setPaymentTerm] = useState(1);
  const [isLoadingPaymentTerm, setIsLoadingPaymentTerm] = useState(false);
  const { t } = useTranslation();

  const handlePaymentTerm = async () => {
    setIsLoadingPaymentTerm(true);
    try {
      const payterm = await axios.get(`${BASE_URL}/billing/${vsInstanceArr[isShow]?.id}/payment_term`);
      setPaymentTerm(Number(payterm?.data?.term));
      setIsLoadingPaymentTerm(false);
    } catch (error) {
      setPaymentTerm(paymentTerm);
      setIsLoadingPaymentTerm(false);
    }
  };

  useEffect(() => {
    handlePaymentTerm();
  }, [paymentTerm, isShow]);

  if (isLoading) {
    return <Loading gap={50} />;
  }

  return (
    <Styles.SlidingPanel>
      <TabsHeader
        menuOptions={menuOptions}
        isClose={() => setIsClose(false)}
        activePanelValue={activePanel}
        setActivePanelValue={() => setIsActivePanel('payment_terms')}
      />
      <Styles.ActionDetail>
        <Styles.Action1>
          <Styles.ActionOptions>
            {vsInstanceArr?.map((actionOption, index) => (
              <div key={index} style={{ position: 'relative' }} onClick={() => toggle(index)}>
                <ConditionalFragment condition={isShow == index}>
                  <img
                    style={{
                      backgroundColor: 'red',
                      position: 'absolute',
                      right: -29,
                      width: '25px',
                      top: 4,
                    }}
                    src={TriCut}
                  />
                </ConditionalFragment>
                <Styles.ActionText isShow={isShow == index}>
                  <Icon name="circle" />
                  <Styles.ActionOption isShow={isShow == index}>{t(actionOption.label)}</Styles.ActionOption>
                </Styles.ActionText>
                <IPNumber data={actionOption} />
              </div>
            ))}
          </Styles.ActionOptions>
        </Styles.Action1>
        <Styles.Action2>
          <PaymentTerms
            isLoadingPaymentTerm={isLoadingPaymentTerm}
            data={vsInstanceArr[isShow]}
            setPaymentTerm={setPaymentTerm}
            paymentTerm={paymentTerm}
          />
        </Styles.Action2>
      </Styles.ActionDetail>
    </Styles.SlidingPanel>
  );
};

export default SlidingPanelPaymentTerm;
