import React from 'react';
import { AffiliateSC } from './Affiliate.style';
import { AllSales, BeSuccessful, HighConversion, LinkAndStats, Traffic } from 'components/Affiliate';

const Affiliate = ({ style, bannerData, affiliate, affiliateTransaction }) => {
  return (
    <AffiliateSC style={style}>
      <BeSuccessful />
      <LinkAndStats affiliate={affiliate} />
      <HighConversion bannerData={bannerData} />
      <Traffic />
      {affiliateTransaction?.affiliate_pending_transactions?.length > 0 && (
        <AllSales affiliateTransaction={affiliateTransaction} />
      )}
    </AffiliateSC>
  );
};

export default Affiliate;
