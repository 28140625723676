import React, { useState } from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import { Heading } from 'components/user-management';
import { Highlight } from 'components/vm-deployment/components';
import { NoBackup } from './NoBackup';
import ConditionalFragment from 'components/conditional-fragment';
import { WithBackup } from './WithBackup';
import { get } from 'lodash';
import { P } from 'components/reusable-css';
import { Trans, useTranslation } from 'react-i18next';

const BackupTop = ({
  backupData,
  type,
  titleText,
  subtitle,
  currentInfo,
  emptyMessage,
  isPermanent,
  backupDelete,
  backupDeleteLoad,
  data,
}) => {
  const { t } = useTranslation();
  const isData = (backups) =>
    get(backups, type, []).length && get(backups, type, []).some((item) => Object.keys(item).length);
  const [backupDeleteRecord, setBackupDeleteRecord] = useState();
  return (
    <Styles.Align>
      <Heading>
        <Trans i18nKey={titleText} components={{ highlight: <Highlight /> }} />
      </Heading>
      <Styles.BackupPadding>
        <Styles.Paragraph>
          <P>{t(subtitle)}</P>
        </Styles.Paragraph>

        {get(backupData, 'vs_backups', []).map((backups) => (
          <React.Fragment key={backups.id}>
            <ConditionalFragment condition={isData(backups)}>
              <P>
                <Trans
                  i18nKey={currentInfo}
                  values={{ days: isPermanent ? backups.permanent_snapshots : backups.retention_days }}
                />
              </P>
              <br />
              <br />
              <WithBackup
                isPermanent={isPermanent}
                backups={backups[type]}
                backupDelete={(backup) => {
                  backupDelete(backup);
                  setBackupDeleteRecord(backup);
                }}
                backupDeleteLoad={backupDeleteRecord === backups.id && backupDeleteLoad}
                data={data}
              />
            </ConditionalFragment>
            <ConditionalFragment condition={!isData(backups)}>
              <NoBackup title={emptyMessage} />
            </ConditionalFragment>
          </React.Fragment>
        ))}
        <Styles.Paragraph>
          {!isPermanent && (
            <P>
              <Trans i18nKey={'vs/instance.string152'}>
                <strong />
                <br />
              </Trans>
            </P>
          )}
        </Styles.Paragraph>
      </Styles.BackupPadding>
    </Styles.Align>
  );
};

export { BackupTop };
