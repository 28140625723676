import React, { useState } from 'react';
import * as Styles from './TicketEasyView.styles';
import LogoImage from 'assets/images/serverpoint.svg';
import { Icon } from 'semantic-ui-react';
import { Hidden } from '@material-ui/core';
import { Base64 } from 'js-base64';
import { Editable, useEditor } from '@wysimark/react';
import Input from 'components/input';
import CustomButton from 'components/common/custom-button/custom-button';
import { useMutation } from 'react-query';
import { ADD_EASYVIEW_TICKET_REPLY } from 'pages/member/issues/queries';
import hooks from 'hooks';
import { useHistory } from 'react-router-dom';
import ReactMarkdown from 'markdown-to-jsx';
import { Trans, useTranslation } from 'react-i18next';

const TicketEasyView = ({ tickets, handleOnComplete }) => {
  const { t } = useTranslation();
  const editor = useEditor({ initialMarkdown: '', minHeight: 400 });
  const [secret, setSecret] = useState('');
  const [editorValue, setEditorValue] = useState('');
  const ticket = tickets.tickets && tickets?.tickets[0];
  const { mutate: addEasviewTicketReply, isLoading: addReplyLoading } = useMutation(ADD_EASYVIEW_TICKET_REPLY);
  const { mutationProps } = hooks();
  const history = useHistory();

  const handleAddReply = async (message, secret) => {
    let pinCode = await localStorage.getItem('pinCode');
    await addEasviewTicketReply(
      {
        action: 'reply',
        ticket_id: ticket.id,
        pin: Number(pinCode),
        message: Base64.encode(message),
        secret: Base64.encode(secret),
      },

      mutationProps(() => {}, {
        onSuccess: async () => {
          handleOnComplete(Number(pinCode));
        },
      }),
    );
  };

  return ticket != undefined ? (
    <Styles.TicketEasyViewSC>
      <Styles.Logo>
        <img src={LogoImage} alt="serverpoint" width="100%" />
      </Styles.Logo>
      <Styles.ChatSC>
        <Styles.Container>
          {ticket?.status == 0 ? (
            <Styles.TicketClosed>
              <Icon name="exclamation circle" />
              {t('tickets/easyview.string1')}
            </Styles.TicketClosed>
          ) : null}
          <Styles.Heading>{atob(ticket?.subject)}</Styles.Heading>
          <Styles.SecondRow>
            <Styles.Flex>
              <p>
                <Trans
                  i18nKey={'tickets/easyview.string2'}
                  values={{
                    ticket_id: ticket?.id,
                    date: ticket?.date_local_format_mdy,
                  }}
                />
              </p>
              <Icon className="dot" name="circle" />
              <p>
                <Icon name="comment outline" />
                {ticket?.message_count}
              </p>
            </Styles.Flex>
          </Styles.SecondRow>
          <Styles.Chat>
            {ticket?.ticket_thread?.map((val, index) => (
              <>
                {val.from_client_or_staff != 'client' && (
                  <Styles.ReplyContainer>
                    <Styles.ReplyChat>{t('tickets/easyview.string3')}</Styles.ReplyChat>
                    <Icon name="reply" size="large" />
                  </Styles.ReplyContainer>
                )}
                <Hidden only={['xs', 'sm']}>
                  <Styles.ChatMobile key={index}>
                    <Styles.Div1>
                      <Styles.DateSC bg={val.from_client_or_staff}>
                        <Styles.UserName staff={val.from_client_or_staff != 'client'}>
                          <Trans
                            i18nKey={
                              val.from_client_or_staff != 'client'
                                ? 'tickets/easyview.string12'
                                : 'tickets/easyview.string13'
                            }
                            values={{ name: val.username }}
                          >
                            <span />
                          </Trans>
                        </Styles.UserName>
                        <Styles.TimeAgo>{val.date_ago_en}</Styles.TimeAgo>
                      </Styles.DateSC>
                    </Styles.Div1>
                    <Styles.Line>
                      <i className="fa fa-circle line-background" />
                    </Styles.Line>
                    <Styles.Div3>
                      <Styles.DetailSC secret={val.secret} bg={val.from_client_or_staff}>
                        <Styles.MessageDate staff={val.from_client_or_staff != 'client'}>
                          <Trans i18nKey={'tickets/easyview.string4'} values={{ date: val?.date_local_format_mdy }} />
                        </Styles.MessageDate>
                        <ReactMarkdown className="line-break">{Base64.decode(val?.message)}</ReactMarkdown>
                      </Styles.DetailSC>
                      {val.secret && (
                        <Styles.SecretInfoContainer>
                          <div>
                            <p>
                              <strong>
                                {val.from_client_or_staff != 'client'
                                  ? t('tickets/easyview.string5')
                                  : t('tickets/easyview.string6')}
                              </strong>
                            </p>
                            <p>
                              <i>{Base64.decode(val.secret)}</i>{' '}
                            </p>
                          </div>
                        </Styles.SecretInfoContainer>
                      )}
                    </Styles.Div3>
                  </Styles.ChatMobile>
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                  <Styles.ChatMobile key={index}>
                    <Styles.Chatlog>
                      <Styles.Div1>
                        <Styles.DateSC bg={val.from_client_or_staff}>
                          <Styles.UserName staff={val.from_client_or_staff != 'client'}>
                            <Trans
                              i18nKey={
                                val.from_client_or_staff != 'client'
                                  ? 'tickets/easyview.string12'
                                  : 'tickets/easyview.string13'
                              }
                              values={{ name: val.username }}
                            >
                              <span />
                            </Trans>
                          </Styles.UserName>
                        </Styles.DateSC>
                      </Styles.Div1>
                      <Styles.Div3>
                        <Styles.DetailSC secret={val.secret} bg={val.from_client_or_staff}>
                          <Styles.MessageDate staff={val.from_client_or_staff != 'client'}>
                            <Trans i18nKey={'tickets/easyview.string4'} values={{ date: val?.date_local_format_mdy }} />
                          </Styles.MessageDate>
                          <ReactMarkdown className="line-break">{Base64.decode(val?.message)}</ReactMarkdown>
                        </Styles.DetailSC>
                        {val.secret && (
                          <Styles.SecretInfoContainer>
                            <div>
                              <p>
                                <strong>
                                  {val.from_client_or_staff != 'client'
                                    ? t('tickets/easyview.string5')
                                    : t('tickets/easyview.string6')}
                                </strong>
                              </p>
                              <p>
                                <i>{Base64.decode(val.secret)}</i>{' '}
                              </p>
                            </div>
                          </Styles.SecretInfoContainer>
                        )}
                      </Styles.Div3>
                    </Styles.Chatlog>
                  </Styles.ChatMobile>
                </Hidden>
              </>
            ))}
          </Styles.Chat>
          {ticket?.status != 0 ? (
            <>
              <div style={{ display: 'flex' }}>
                <Styles.ReplyChatContainerBg>
                  <div style={{ width: '100%' }}>
                    <Editable editor={editor} onChange={() => setEditorValue(editor.getMarkdown())} />
                  </div>
                  <Styles.SecretContainer>
                    <Styles.WysimarkInfo>
                      <p>
                        <strong>{t('tickets/easyview.string7')}</strong>
                      </p>
                      <span>{t('tickets/easyview.string8')}</span>
                    </Styles.WysimarkInfo>
                    <Styles.SecrectInputContainer>
                      <Input
                        borderRadius="6px"
                        borderWidth="1px"
                        borderBottomWidth="2px"
                        isFormIk={false}
                        multiline
                        minRows={7}
                        maxRows={11}
                        placeholder={t('tickets/easyview.string9')}
                        onChange={(e) => setSecret(e.target.value)}
                        value={secret}
                      />
                    </Styles.SecrectInputContainer>
                  </Styles.SecretContainer>
                  <CustomButton
                    type="submit"
                    title={'tickets/easyview.string10'}
                    buttonStyles={{
                      marginTop: '1em',
                    }}
                    disabled={!editorValue}
                    onClick={async () => {
                      await handleAddReply(editor.getMarkdown(), secret, () => {
                        setSecret('');
                        editor.resetMarkdown();
                      });
                    }}
                    isLoading={addReplyLoading}
                  />
                </Styles.ReplyChatContainerBg>
              </div>

              <Styles.ReplyChatContainer>
                <CustomButton
                  type="submit"
                  title="tickets/easyview.string11"
                  buttonStyles={{
                    background: '#8552da',
                    marginTop: '3em',
                  }}
                />
              </Styles.ReplyChatContainer>
            </>
          ) : null}
        </Styles.Container>
      </Styles.ChatSC>
    </Styles.TicketEasyViewSC>
  ) : (
    <div>{history.push('/pin_required')}</div>
  );
};

export default TicketEasyView;
