import React, { useState } from 'react';
import 'components/manage-vm/Dropdown.css';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Dropdown } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { useTranslation } from 'react-i18next';
import { P } from 'components/reusable-css';
import ClickNHold from 'react-click-n-hold';
import Tooltip from 'components/Tooltip';
import { DeleteAnimation } from 'pages/member/dns/DNS.style';

const SettingDropdown = ({
  setOpenConfigurationPanel,
  handleAction,
  dropdownItems,
  data,
  refectDNS,
  setOpenDomain,
}) => {
  const { t } = useTranslation();
  const [anim, setAnim] = useState();

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic">
        <MoreHorizIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ borderRadius: '10px', boxShadow: 'unset', zIndex: 99999 }} className="menu">
        {dropdownItems(setOpenConfigurationPanel, handleAction).map((dropDownItem) => (
          <React.Fragment key={dropDownItem.id}>
            {dropDownItem.name != 'delete_records' && dropDownItem.name != 'delete_dns' ? (
              <Dropdown.Item
                style={{ paddingTop: '7px', paddingBottom: '7px', margin: '5px 0', display: 'flex' }}
                className="icon"
                onClick={() => {
                  if (dropDownItem.name == 'configure_dns') {
                    dropDownItem.onClick();
                    setOpenDomain(data.domain);
                    refectDNS();
                  } else {
                    dropDownItem.onClick();
                  }
                }}
              >
                <Icon className={dropDownItem.icon} />
                <P>{t(dropDownItem.lang)}</P>
              </Dropdown.Item>
            ) : (
              <ClickNHold
                time={1.5}
                onStart={() => setAnim(true)}
                onEnd={() => setAnim(false)}
                onClickNHold={() => dropDownItem.onClick()}
              >
                <Tooltip title="Click for 2 seconds." placement="left">
                  <DeleteAnimation anim={anim} className="icon">
                    <Icon className={dropDownItem.icon} />
                    <P>{t(dropDownItem.lang)}</P>
                  </DeleteAnimation>
                </Tooltip>
              </ClickNHold>
            )}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SettingDropdown;
