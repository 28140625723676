import { h2Styles, h6Styles } from 'components/reusable-css';
import styled from 'styled-components';

export const NewBMLastStepSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ef6c0f;
  align-items: center;
  justify-content: center;
  color: black;
  font-family: 'Open Sans', verdana, arial, sans-serif !important;
  @media only screen and (max-width: 765px) {
    display: flex;
    flex-flow: column wrap;
  }
`;
export const PriceServer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f1f2;
  padding: 53px 15px;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;

  @media only screen and (max-width: 765px) {
    width: 100%;
  }
`;
export const VirtualServer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #dfdbda;
  padding: 84px 15px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40%;
  height: 100%;
  @media only screen and (max-width: 765px) {
    width: 100%;
  }
  @media only screen and (max-width: 500px) {
    padding: 50px 15px;
  }
`;
export const SubmitServer = styled.div`
  display: flex;
  flex-direction: wrap;
`;
export const Content = styled.div`
  text-align: right;
  .your_server_price {
    font-weight: 700;
  }
`;
export const Price = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 5px;
`;

export const PerMonth = styled.p`
  font-weight: 700;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  color: #ef6c0f;
`;

export const PriceUnit = styled.span`
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
`;

export const PerHour = styled.p`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  font-weight: bold;
  color: black;
  margin-top: 0.6em;
`;

///Setting Upgrade//////

export const NewBMLastStep = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid #ef6c0f;
  /* position: absolute; */
  /* bottom: 0px; */
  /* width: 100%; */
  justify-content: center;
  align-items: center;
  background-color: #dfdbda;
  color: black;
  font-family: 'Open Sans', verdana, arial, sans-serif !important;
`;

export const Flex = styled.div`
  display: flex;
  width: 60%;
  padding: 30px 15px;
  background-color: #f3f1f2;
  height: 100%;
  @media only screen and (max-width: 1316px) {
    display: flex;
    width: 100%;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    padding: 0 15px;
  }
`;

export const CurrentPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding-right: 10px;
  @media only screen and (max-width: 800px) {
    width: 100%;
    padding: 20px 0px;
  }
`;
export const NewPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding-left: 10px;
  @media only screen and (max-width: 800px) {
    width: 100%;
    padding: 20px 0px;
  }
`;
export const FlexUpgrade = styled.div`
  width: 40%;
  background-color: #dfdbda;
  padding: 20px 15px;
  height: 100%;
`;
export const Upgrade = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const Discount = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;
export const ActualPrice = styled.div`
  display: flex;
  text-decoration: line-through;
  margin: 0 6px;
  color: grey;
`;
export const DiscountPrice = styled.div`
  border-radius: 5px;
  background: ${(props) => props.theme.redColor};
  padding: 3px 5px;
  color: white;
`;
