/* eslint-disable no-unused-vars */
/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-28 02:24:15
 * @modify date 2021-01-28 02:24:15
 */

import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import * as Styles from './Header.style';
import Account from 'layout/header/account/Account';
import { navLinks } from 'utils/nav-links-util';
import FIVE_STAR_IMG from 'assets/images/five-star.svg';
import { useDispatch, useSelector } from 'react-redux';
import currencyFilter from 'utils/currency-filter';
import { Link } from 'react-router-dom';
import VmUtils from 'pages/member/new-vm/util';
import hooks from 'hooks';
import { userRights } from 'store/selectors/user-details';
import ConditionalFragment from 'components/conditional-fragment';
import { useMutation } from 'react-query';
import { UPDATE_PREFERENCES } from 'components/manage-vm/queries';
import LanguageDropdown from 'components/language-dropdown/LanguageDropdown';
import { logoutUser } from 'store/actions/user-details';

export default function Header({
  ui,
  isAppDrawerOpen,
  version,
  releasedDate,
  username,
  onAppDrawerToggle,
  queryClient,
  profileMenu,
}) {
  const dispatch = useDispatch();
  const { logout } = hooks();
  const rights = useSelector(userRights);
  const { t, i18n } = useTranslation();
  let defaultLang = i18n.language == 'en-US' ? 'en' : i18n.language;
  const [language, setLanguage] = useState(defaultLang);
  const { mutate: updatePreferences } = useMutation(UPDATE_PREFERENCES);
  const userAccount = useSelector((state) => state.user.account);
  const userUi = useSelector((state) => state.user.ui);
  const { newVmPath } = VmUtils();
  const { account_bucks = 0, account_currency = 0, account_funds = 0, account_approved } = userAccount || {};
  const handleNavigate = async (link) => {
    if (link.id === 'logout') {
      dispatch(logoutUser(true));
      try {
        await logout(queryClient);
        dispatch(logoutUser(false));
      } catch (error) {
        dispatch(logoutUser(false));
        console.log('error::', error);
      }
    }
  };

  const handleLanguage = async (value) => {
    await updatePreferences({ language: value });
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  return (
    <Styles.HeaderSC>
      <Styles.AppDrawerToggleButton onClick={onAppDrawerToggle}>
        <Icon name={isAppDrawerOpen ? 'close' : 'bars'} />
      </Styles.AppDrawerToggleButton>
      {userUi?.header == 1 && (
        <Styles.LeftHeader onClick={() => (isAppDrawerOpen ? onAppDrawerToggle() : {})}>
          <Styles.Para>
            <Trans
              i18nKey={ui?.user_level === 'subuser' ? 'header.string2' : 'header.string1'}
              components={{
                changelog_link: <Styles.ChangelogLink to={navLinks.changelog.link} target="_blank" />,
                text: <Styles.Text />,
              }}
              values={{
                version: version,
                username: username,
                release_date: moment(releasedDate).format('MMMM DD, YYYY'),
              }}
            />
          </Styles.Para>
          <Styles.Para>
            <Styles.Text>
              {account_approved == 1 ? (
                <span>
                  {rights.billing && (
                    <Trans
                      i18nKey={'header.string3'}
                      values={{
                        account_price: currencyFilter(account_funds, account_currency),
                        buck_price: currencyFilter(account_bucks, account_currency, true),
                      }}
                    />
                  )}
                </span>
              ) : (
                <Trans i18nKey={'header.string4'}>
                  <a href={'#' + newVmPath}>{t('virtual server')}</a>
                  <a href={'#' + navLinks.new_server.link}>{t('bare metal server')}</a>
                </Trans>
              )}
              <ConditionalFragment condition={rights.billingmakepmt && rights.billing}>
                <Link to={navLinks.billing.link}>
                  <Styles.ButtonSC color="green" size="mini">
                    {t('string2')}
                    <i style={{ marginLeft: '0.4rem' }} className="fa fa-credit-card-alt" />
                  </Styles.ButtonSC>
                </Link>
              </ConditionalFragment>
            </Styles.Text>
          </Styles.Para>
        </Styles.LeftHeader>
      )}

      <Styles.RightHeader header={userUi?.header == 1} onClick={() => (isAppDrawerOpen ? onAppDrawerToggle() : {})}>
        <Styles.LanguageSwitcher>
          <LanguageDropdown language={language} handleLanguage={(e) => handleLanguage(e)} isHeader />
        </Styles.LanguageSwitcher>

        {userUi.show_reviews == 1 ? (
          <>
            <Styles.TestimonialContainer href={navLinks.testimonials.link} target="_blank" rel="noreferrer">
              <Styles.Stars src={FIVE_STAR_IMG} alt={'Five Star'} />
              <Styles.TestimonialText>{t('header.string5')}</Styles.TestimonialText>
            </Styles.TestimonialContainer>
            <Styles.SocialMediaLink target="_blank " to={navLinks.contact_us.link}>
              <i className="fa fa-comments" />
              <div>{t('header.string6')}</div>
            </Styles.SocialMediaLink>
          </>
        ) : (
          userUi.show_reviews != 1 && (
            <>
              <Styles.SocialMediaLink target="_blank " to={navLinks.contact_us.link}>
                <i className="fa fa-comments" />
                <div>{t('header.string6')}</div>
              </Styles.SocialMediaLink>
              <Styles.SocialMediaLink onClick={() => handleNavigate(navLinks.logout)} to={navLinks.logout.link}>
                <i className="fa fa-sign-out signout-icon" />
                <div>{t('header.string7')}</div>
              </Styles.SocialMediaLink>
            </>
          )
        )}

        <Account queryClient={queryClient} profileMenu={profileMenu} />
      </Styles.RightHeader>
    </Styles.HeaderSC>
  );
}

Header.propTypes = {
  isAppDrawerOpen: PropTypes.bool.isRequired,
  version: PropTypes.string.isRequired,
  releasedDate: PropTypes.instanceOf(Date).isRequired,
  username: PropTypes.string.isRequired,
  onAppDrawerToggle: PropTypes.func.isRequired,
  queryClient: PropTypes.any,
};
