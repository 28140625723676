import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const RECOVERABLE_VS = async () => {
  const res = await axios.get(BASE_URL + '/vs/recoverable');
  return res.data;
};

export const RECOVERABLE_VS_ACTION = async (e) => {
  const { id, action } = e;
  const res = await axios.post(BASE_URL + `/vs/instance/${id}/actions`, { action });
  return res.data;
};
