import React, { useRef, useState } from 'react';
import * as Styles from './Authentication.style';
import { Trans, useTranslation } from 'react-i18next';
import { Highlight } from 'components/vm-deployment/components';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Formik, Form } from 'formik';
import {
  backupFormInitialValues,
  backupFormValidationSchema,
  primaryFormInitialValues,
  primaryFormValidationSchema,
} from 'constants/account-profile';
import { useMutation } from 'react-query';
import { ACC_PROFILE_2FA } from '../queries';
import { Heading } from 'components/user-management/Heading';
import { P } from 'components/reusable-css';
import { CustomHook } from 'utils/custom-hook';
import ErrorMessage from 'components/common/red-notification';
import PinInput from 'react-pin-input';
import LoadingImage from 'assets/images/loading.svg';
import PrimaryForm from './PrimaryForm';
import BackupForm from './BackupForm';

const Authentication = ({ areaCodeData, get2fadata, refetch }) => {
  const primaryRef = useRef(null);
  const backupRef = useRef(null);
  const { t } = useTranslation();
  const mobile = useMediaQuery('(max-width:500px)');
  const [error, setError] = useState('');
  const { mutationProps } = CustomHook();
  const { mutate: primaryNum, isLoading: primaryLoading } = useMutation(ACC_PROFILE_2FA);
  const { mutate: backupNum, isLoading: backupLoading } = useMutation(ACC_PROFILE_2FA);
  const { mutate: primaryVerify, isLoading: primaryverifyLoading } = useMutation(ACC_PROFILE_2FA);
  const { mutate: backupVerify, isLoading: backupverifyLoading } = useMutation(ACC_PROFILE_2FA);
  //primary
  const [isPrimary, setIsPrimary] = useState(0);
  const [primaryDisplay, setPrimaryDisplay] = useState(0);
  const [primaryNumSuccess, setPrimaryNumSuccess] = useState(false);
  //backup
  const [isBackup, setIsBackup] = useState(0);
  const [backupDisplay, setBackupDisplay] = useState(0);
  const [backupNumSuccess, setBackupNumSuccess] = useState(false);

  const handlePrimarySubmit = async (values) => {
    const { primary_2fa_area_code, primary_2fa_phone } = values;
    await primaryNum(
      {
        primary_2fa_area_code: primary_2fa_area_code == '' ? areaCodeArr[0].value : primary_2fa_area_code,
        primary_2fa_phone: primary_2fa_phone,
        token1: '',
        token2: '',
      },
      mutationProps(() => {}, {
        onSuccess: async (res) => {
          setError('');
          if (res.status == 'two_factor_sms_sent') {
            setIsPrimary(1);
            setPrimaryDisplay(1);
            primaryRef.current.focus();
          } else if (res.status == '2fa_authentication_info_updated_number_removed') {
            await refetch();
          }
        },
        onError: (err) => {
          if (err.response.data.error == 'apierror_bad_syntax') {
            setError(err.response.data.error);
          } else {
            setError('');
          }
        },
      }),
    );
  };

  const handleBackupSubmit = async (values) => {
    const { backup_2fa_area_code, backup_2fa_phone } = values;
    await backupNum(
      {
        backup_2fa_area_code: backup_2fa_area_code == '' ? areaCodeArr[0].value : backup_2fa_area_code,
        backup_2fa_phone: backup_2fa_phone,
        token1: '',
        token2: '',
      },
      mutationProps(() => {}, {
        onSuccess: async (res) => {
          setError('');
          if (res.status == 'two_factor_sms_sent') {
            setIsBackup(1);
            setBackupDisplay(1);
            backupRef.current.focus();
          }
        },
        onError: (err) => {
          if (err.response.data.error == 'apierror_bad_syntax') {
            setError(err.response.data.error);
          } else {
            setError('');
          }
        },
      }),
    );
  };

  const handlePrimaryVerify = async (code) => {
    await primaryVerify(
      {
        primary_verification_token: code,
      },
      mutationProps(() => {}, {
        onSuccess: () => {
          setPrimaryNumSuccess(true);
          setTimeout(() => {
            setIsPrimary(0);
            setPrimaryNumSuccess(false);
            primaryRef.current.clear();
          }, 3 * 1000);
          setTimeout(() => {
            setPrimaryDisplay(0);
          }, 4 * 1000);
        },
        onError: () => {
          setPrimaryNumSuccess(false);
          primaryRef.current.clear();
        },
      }),
    );
  };

  const handleBackupVerify = async (code) => {
    await backupVerify(
      {
        backup_verification_token: code,
      },
      mutationProps(() => {}, {
        onSuccess: () => {
          setBackupNumSuccess(true);
          setTimeout(() => {
            setIsBackup(0);
            setBackupNumSuccess(false);
            backupRef.current.clear();
          }, 3 * 1000);
          setTimeout(() => {
            setBackupDisplay(0);
          }, 4 * 1000);
        },
        onError: () => {
          setBackupNumSuccess(false);
          backupRef.current.clear();
        },
      }),
    );
  };

  const areaCodeArr = areaCodeData?.two_fa_codes?.map((val) => {
    return { value: val.id.split('+')[1], text: val.id };
  });

  return (
    <Styles.PassSC>
      <Styles.Pass>
        <Heading>
          <Trans i18nKey="account/profile.string9" components={{ highlight: <Highlight /> }} />
        </Heading>
        <Styles.ChangePasswords>
          <P>{t('account/profile.string10')}</P>
          <P>{t('account/profile.string11')}</P>
          <Styles.FormDiv>
            <Grid container>
              <Grid xs={12} sm={12} md={4} lg={3} xl={2}>
                <Styles.Label>{t('account/profile.string12')}</Styles.Label>
              </Grid>
              <Grid>
                <div className="flexColumn">
                  <div>{error && <span className="error_messageSty">{t(`${error}`)}</span>}</div>
                  <Formik
                    initialValues={primaryFormInitialValues(get2fadata, areaCodeArr)}
                    validationSchema={primaryFormValidationSchema}
                    onSubmit={(values) => handlePrimarySubmit(values)}
                  >
                    {(formikProps) => (
                      <Form>
                        <PrimaryForm
                          mobile={mobile}
                          isLoading={primaryLoading}
                          get2fadata={get2fadata}
                          areaCodeArr={areaCodeArr}
                          formikProps={formikProps}
                        />
                      </Form>
                    )}
                  </Formik>
                  <Styles.VerificationFom display={primaryDisplay} visible={isPrimary}>
                    <Styles.PinSC>
                      <PinInput
                        ref={primaryRef}
                        length={6}
                        initialValue=""
                        type="numeric"
                        inputMode="number"
                        style={{ padding: '10px 0px' }}
                        inputStyle={{
                          width: mobile ? '38px' : '50px',
                          height: '50px',
                          border: '2px solid #b3b3b3b3',
                          borderBottom: '2.9px solid orange',
                          background: 'white',
                          marginLeft: '5px',
                          marginBottom: '5px',
                          fontSize: '22px',
                        }}
                        onComplete={(value) => {
                          handlePrimaryVerify(value);
                        }}
                        autoSelect
                        focus
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                    </Styles.PinSC>

                    {primaryverifyLoading ? (
                      <img src={LoadingImage} width={50} style={{ margin: '0 1.3em' }} />
                    ) : primaryNumSuccess ? (
                      <i className="fa fa-check fa-2x" style={{ padding: '0 1.05em' }} />
                    ) : (
                      <div style={{ padding: '0 3.1em' }} />
                    )}
                  </Styles.VerificationFom>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} sm={12} md={4} lg={3} xl={2}>
                <Styles.Label>{t('account/profile.string13')}</Styles.Label>
              </Grid>
              <Grid>
                <Formik
                  initialValues={backupFormInitialValues(get2fadata, areaCodeArr)}
                  validationSchema={backupFormValidationSchema}
                  onSubmit={(values) => handleBackupSubmit(values)}
                >
                  {(formikProps) => (
                    <Form>
                      <BackupForm
                        mobile={mobile}
                        get2fadata={get2fadata}
                        formikProps={formikProps}
                        areaCodeArr={areaCodeArr}
                        isLoading={backupLoading}
                      />
                    </Form>
                  )}
                </Formik>
                <Styles.VerificationFom visible={isBackup} display={backupDisplay}>
                  <Styles.PinSC>
                    <PinInput
                      ref={backupRef}
                      length={6}
                      initialValue=""
                      type="numeric"
                      inputMode="number"
                      style={{ padding: '10px 0px' }}
                      inputStyle={{
                        width: mobile ? '38px' : '50px',
                        height: '50px',
                        border: '2px solid #b3b3b3b3',
                        borderBottom: '2.9px solid orange',
                        background: 'white',
                        marginLeft: '5px',
                        marginBottom: '5px',
                        fontSize: '22px',
                      }}
                      onComplete={(value) => {
                        handleBackupVerify(value);
                      }}
                      autoSelect
                      focus
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                  </Styles.PinSC>

                  {backupverifyLoading ? (
                    <img src={LoadingImage} width={50} style={{ margin: '0 1.3em' }} />
                  ) : backupNumSuccess ? (
                    <i className="fa fa-check fa-2x" style={{ padding: '0 1.05em' }} />
                  ) : (
                    <div style={{ padding: '0 3.1em' }} />
                  )}
                </Styles.VerificationFom>
              </Grid>
            </Grid>
          </Styles.FormDiv>
        </Styles.ChangePasswords>
        <Styles.Warning>
          <ErrorMessage bgColor="#82bf50">
            <p>{t('account/profile.string14')}</p>
          </ErrorMessage>
        </Styles.Warning>
      </Styles.Pass>
    </Styles.PassSC>
  );
};
export default Authentication;
