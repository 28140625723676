import React from 'react';
import { TopArrow, ArrowBorders, TopArrowText } from '../Statistics.style';
import { useTranslation } from 'react-i18next';

const StatsArrow = ({ text, lines, portDisabled }) => {
  const { t } = useTranslation();
  return (
    <>
      {text && (
        <div style={{ paddingTop: '2rem' }}>
          <TopArrowText>{t(text)}</TopArrowText>
          <TopArrow />
          <ArrowBorders portDisabled={portDisabled} lines={lines} />
        </div>
      )}
    </>
  );
};
export { StatsArrow };
