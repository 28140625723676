import { h2Styles, pStyles } from 'components/reusable-css';
import styled from 'styled-components';
import { deviceBreakpoints, variables } from 'variables';

export const NewVMPageSC = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: flex-start;
  background: ${(props) => props.theme.pageBackgroundColor};
  width: 100%;
  padding: ${variables.pageContentPadding}px;
  padding-top: 60px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    padding-top: 30px;
  }
`;

export const Heading = styled.h2`
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
  padding-left: 12px;
  @media only screen and (max-width: ${deviceBreakpoints.sLaptop}px) {
    padding-left: 2px;
  }
`;

export const StepDescription = styled.div`
  padding-top:5px;
  margin: 30px 0;
  display: flex;
  flex-wrap-wrap;
  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    margin: 10px 0; 
  } 
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    display: flex;
    flex-direction: column;
    margin: 5px 0 20px 0; 
    padding-right: 14px;   
  } 
`;
export const Image = styled.div`
  width: 33%;

  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    width: 20em;
  }
`;

export const Description = styled.div`
  width: 67%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 5px;
    width: 100%;
  }
`;
export const Paragraph = styled.p`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  padding-bottom: 1em;
`;
export const Img = styled.img`
  width: 50%;

  @media only screen and (max-width: ${deviceBreakpoints.lLaptop}px) {
    width: 68%;
  }
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    width: 75%;
  }
  @media only screen and (max-width: ${deviceBreakpoints.sLaptop}px) {
    width: 100%;
  }
`;
