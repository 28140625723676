export * from './bm-hooks';
import VmUtils from 'pages/member/new-vm/util';
import { CustomHook } from 'utils/custom-hook';
import { BackupLevels } from './backup-levels';
import { BmHooks } from './bm-hooks';
import { MutationQueries } from './mutation-queries';
export default (props) => {
  return {
    ...BmHooks(),
    ...CustomHook(),
    ...VmUtils(),
    ...MutationQueries(),
    ...BackupLevels(props),
  };
};
