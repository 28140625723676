import React from 'react';
import * as Styles from './RecoverableVS.style';
import { Highlight } from 'components/vm-deployment/components';
import { Trans, useTranslation } from 'react-i18next';
import RecoverableVs from 'components/recoverable-vs';
import Comment from 'components/comment';
import { TickIcon } from 'components/icons';
import { data } from 'constants/index';
import { H3, P } from 'components/reusable-css';
import { Heading } from 'assets/css/common-styles';

const RecoverableVS = ({ recoverableVs, style, refetchMenu }) => {
  const { t } = useTranslation();
  return (
    <Styles.RecoverableVsSC style={style}>
      <div>
        {recoverableVs ? (
          <Styles.Heading>
            <Heading>
              <Trans i18nKey={'vs/recoverable.string6'} components={{ highlight: <Highlight /> }} />
            </Heading>
            <P>{t('vs/recoverable.string7')}</P>
          </Styles.Heading>
        ) : (
          <Styles.CommentContainer>
            <Comment>
              <div>
                <H3>{t('vs/recoverable.string1')}</H3>
                <P>{t('vs/recoverable.string2')}</P>
                {data?.map((item) => (
                  <Styles.TickIcon key={data.id}>
                    <TickIcon />
                    <P>{t(item.lang)}</P>
                  </Styles.TickIcon>
                ))}
              </div>
            </Comment>
          </Styles.CommentContainer>
        )}

        <Styles.RecoverableVs>
          {recoverableVs?.vs_recoverable_instance?.map((data) => (
            <RecoverableVs refetchMenu={refetchMenu} data={data} key={data.id} />
          ))}
        </Styles.RecoverableVs>
      </div>
    </Styles.RecoverableVsSC>
  );
};
export default RecoverableVS;
