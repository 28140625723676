import React from 'react';
import * as Styles from './Heading.style';
import { Icon } from 'semantic-ui-react';
import { H2, H4, H6 } from 'components/reusable-css';
import { Trans } from 'react-i18next';
import { Highlight } from 'components/common';

const Heading = ({ children, name, small, sub_heading }) => {
  return (
    <div>
      {sub_heading ? (
        <>
          {' '}
          <Styles.SubHeading small={small}>
            <Icon name="circle icon-circle" className="mb-2" />
            <H4>
              <Trans
                i18nKey={name}
                components={{
                  highlight: <Highlight />,
                  font_bold: <span style={{ fontWeight: 'bold' }} />,
                  font_red: <span style={{ color: 'red' }} />,
                }}
              />
            </H4>
          </Styles.SubHeading>
          <Styles.ContentSubHeading>{children}</Styles.ContentSubHeading>
        </>
      ) : (
        <>
          <Styles.Heading small={small}>
            <Icon name="angle right" className="mb-2" />
            {small ? (
              <H6>
                <Trans
                  i18nKey={name}
                  components={{
                    highlight: <Highlight />,
                    font_bold: <span style={{ fontWeight: 'bold' }} />,
                    font_red: <span style={{ color: 'red' }} />,
                  }}
                />
              </H6>
            ) : (
              <H2>
                <Trans
                  i18nKey={name}
                  components={{
                    highlight: <Highlight />,
                    font_bold: <span style={{ fontWeight: 'bold' }} />,
                    font_red: <span style={{ color: 'red' }} />,
                  }}
                />
              </H2>
            )}
          </Styles.Heading>
          <Styles.Content>{children}</Styles.Content>
        </>
      )}
    </div>
  );
};

export default Heading;
