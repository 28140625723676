/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { NewVMStep5SC, StepDescription, ServerSizeGrid } from './NewVMStep5.style';
import BackupPlanCard from 'components/vm-deployment/backup-plan-card';
import ErrorMessage from 'components/error-message';
import { Highlight, StepLabel, StepLabelMobile } from 'components/common';
import Heading from 'components/Heading/Heading';

const NewVMStep5 = ({ backuplevelsData, selectedServerSize, onServerSizeSelection, calculatePrice, formikProp }) => {
  const { t } = useTranslation();

  return (
    <NewVMStep5SC id="ST5">
      {/* <StepLabel id="ST5" stepCount={t('vs/instance/create.step5_string1')}>
        <Trans i18nKey="vs/instance/create.step5_string2" components={{ highlight: <Highlight /> }} />
      </StepLabel>
      <StepLabelMobile stepCount={t('vs/instance/create.step5_string1')}>
        <Trans i18nKey="vs/instance/create.step5_string2" components={{ highlight: <Highlight /> }} />
      </StepLabelMobile> */}
      <Heading name={'vs/instance/create.step5_string2'}>
        <StepDescription>
          {t('vs/instance/create.step5_string3')}
          <br />
          <br />
          {t('vs/instance/create.step5_string4')}
        </StepDescription>
        <ServerSizeGrid>
          {backuplevelsData?.map((backuplevelData) => (
            <BackupPlanCard
              key={backuplevelData.id}
              data={backuplevelData}
              selected={backuplevelData.id === selectedServerSize}
              onClick={onServerSizeSelection}
              calculatePrice={calculatePrice}
            />
          ))}
        </ServerSizeGrid>
        {formikProp.errors.backup_plan && formikProp.touched.backup_plan && <ErrorMessage />}
      </Heading>
    </NewVMStep5SC>
  );
};
export default NewVMStep5;
