/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-31 23:35:35
 * @modify date 2021-02-05 11:13:50
 */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { dynamicStyles, h6Styles } from 'components/reusable-css';

export const AccountSC = styled.div`
  display: flex;
  border-left: 1px solid ${(props) => props.theme.dividerColor};
  height: calc(100% - 1px);
`;

export const TriggerContainer = styled.div`
  display: grid;
  justify-items: center;
  align-content: center;
  cursor: pointer;
  width: 6.5em;
  &:hover {
    text-decoration: underline;
  }
  .user-icon {
    font-size: ${(props) => props.theme.default?.top_bar_right_icon_width};
    width: ${(props) => props.theme.default?.top_bar_right_icon_width};
    height: ${(props) => props.theme.default?.top_bar_right_icon_height};
    ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_right_icon_width', 'font-size')};
    margin: 1px 0px 2px 0px;
  }
`;

export const UserImage = styled.img`
  height: ${(props) => props.theme.default?.top_bar_right_icon_height};
  width: ${(props) => props.theme.default?.top_bar_right_icon_width};
  margin-top: 4px;

  ${(props) =>
    dynamicStyles(props.theme.resolutions, 'top_bar_right_icon_width', 'width', 'top_bar_right_icon_height', 'height')};
`;

export const AccountLabel = styled.div`
  display: inline;
  font-size: ${(props) => props.theme.default?.top_bar_right_icon_font};
  font-weight: bold;
  color: ${(props) => props.theme.gray.shade10};

  i {
    margin-left: 2px;
  }
  ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_right_icon_font', 'font-size')};
`;

export const PopupContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const Greeting = styled.div`
  display: flex;
  padding: 10px;
  margin-top: 10px;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;

export const Divider = styled.div`
  display: block;
  height: 1px;
  background: ${(props) => props.theme.dividerColor};
  margin: 15px 0;
`;

export const Links = styled.div``;

export const LinkSC = styled(Link)`
  display: flex;
  padding: 0 20px;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.gray.shade0};
  div {
    font-size: ${(props) => props.theme.default?.account_submenu_font};
    line-height: ${(props) => props.theme.default?.account_submenu_font_line_height};
    ${(props) =>
      dynamicStyles(
        props.theme.resolutions,
        'account_submenu_font_line_height',
        'line-height',
        'account_submenu_font',
        'font-size',
      )};
  }

  i {
    margin-right: 15px;
    display: table;
  }

  &:hover {
    color: ${(props) => props.theme.gray.shade0};
    background: ${(props) => props.theme.gray.shadeE};
  }
`;
export const AchorTag = styled.a`
  display: flex;
  padding: 0 20px;
  align-items: center;
  color: ${(props) => props.theme.gray.shade0};
  div {
    font-size: ${(props) => props.theme.default?.account_submenu_font};
    line-height: ${(props) => props.theme.default?.account_submenu_font_line_height};
    ${(props) =>
      dynamicStyles(
        props.theme.resolutions,
        'account_submenu_font_line_height',
        'line-height',
        'account_submenu_font',
        'font-size',
      )};
  }
  i {
    margin-right: 15px;
    display: table;
  }

  &:hover {
    color: ${(props) => props.theme.gray.shade0};
    background: ${(props) => props.theme.gray.shadeE};
  }
`;

export const SocialMediaLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30px);
  gap: 30px;
  font-size: 30px;
  margin-top: 10px;
  padding: 10px 20px;
`;

export const SocialMediaLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.gray.shade0};
  .twitter-logo {
    margin-top: -10px;
    width: 25px;
    height: 25px;
  }
  &:hover {
    color: ${(props) => props.color};
  }
`;
