/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-02 10:31:48
 * @modify date 2021-02-02 10:38:43
 */

import styled from 'styled-components';

export const NewVMSC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
