import { CORE, DISK, RAM, SLIDER } from 'components/vm-deployment/constants';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { parseBackUpValues } from './new-vm-last-step/util';
import { navLinks } from 'utils/nav-links-util';

const VmUtils = () => {
  const user = useSelector((state) => state.user.account);
  const isSliderInterface = user.public_vs_interface_type === SLIDER ? true : false;
  return {
    useFullTemplateData: (vsTemplate = []) => {
      const data = vsTemplate.map((item) => {
        return {
          // text: `${item.name} (Min disk ${item.size_in_gb}GBs), OS:${item.osname}`,
          text: `${item.name}`,
          value: item.id,
        };
      });
      return [{ text: 'No snapshot template', value: '' }, ...data];
    },
    isSliderInterface,
    vmCreationQueryChanges: (values, data) => {
      let query = {
        region: values.region,
        label: values.virtual_server_name,
        size: values.size,
        app: _get(values, 'template', '') ? '' : values.app,
        image: _get(values, 'template', '') ? '' : values.image,
        password: btoa(values.confirm_pass),
        image_from_snapshot: _get(values, 'template', ''),
        backup_plan: parseBackUpValues(values.backup_plan),
        ipv4_ips: values.ipv4_ips,
        options_prepaid:
          values.options_prepaid.price_times_months == 12 ? values.options_prepaid.price_times_months : '0',
        ...data,
      };
      if (isSliderInterface) {
        delete query.size;
        query = {
          ...query,
          ram: values.sliders[RAM],
          disk: values.sliders[DISK],
          vcpus: String(values.sliders[CORE]),
        };
      }
      return query;
    },
    newVmPath: isSliderInterface ? navLinks.new_vm_slider.link : navLinks.new_vm.link,
    newVmObject: isSliderInterface ? navLinks.new_vm_slider : navLinks.new_vm,
  };
};

export default VmUtils;
