/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { NewVMStep6SC, AuthenticationSteps } from './NewVMStep6.style';
import AuthenticationStep from 'components/label-field';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { updatePricing } from 'store/actions/vm-deployment';
import { authenticationSteps } from 'components/vm-deployment/constants/step6';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormikErrorMessage from 'components/formik-error-message';
import { Highlight, StepLabel, StepLabelMobile } from 'components/common';
import Heading from 'components/Heading/Heading';

const NewVMStep6 = ({ formikProp }) => {
  const { values, handleChange, handleBlur, errors, touched } = formikProp;
  const matches = useMediaQuery('(max-width:450px)');
  let width = matches ? '100%' : '25rem';
  const textboxStyling = {
    width: width,
  };
  const selectStyling = {
    width: width,
    marginTop: '1.7rem',
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ipPrices, setIPPrices] = useState(0);

  useQuery('/prices/vs/ip', {
    onSuccess: async (res) => {
      setIPPrices(res?.prices_vs_ip);
    },
  });

  const calculatePrice = () => {
    let ip_price = 0;
    if (values?.ipv4_ips > 1) {
      ip_price = (Number(values?.ipv4_ips) - 1) * Number(ipPrices[0]?.price_monthly_usd);
    }
    return ip_price;
  };

  useEffect(() => {
    dispatch(updatePricing(Number(calculatePrice()), 'ip_price'));
  }, [values.ipv4_ips]);

  return (
    <NewVMStep6SC id="ST6">
      {/* <StepLabel id="ST6" stepCount={t('vs/instance/create.step6_string1')}>
        <Trans i18nKey="vs/instance/create.step6_string2" components={{ highlight: <Highlight /> }} />
      </StepLabel>
      <StepLabelMobile stepCount={t('vs/instance/create.step6_string1')}>
        <Trans i18nKey="vs/instance/create.step6_string2" components={{ highlight: <Highlight /> }} />
      </StepLabelMobile> */}
      <Heading name={'vs/instance/create.step6_string2'}>
        <AuthenticationSteps>
          {React.Children.toArray(
            authenticationSteps.map((item) => (
              <AuthenticationStep
                ipPrices={ipPrices}
                styling={textboxStyling}
                selectStyling={selectStyling}
                options={item.options}
                type={item.type}
                text={item.text}
                localizeText={item.localizeText}
                localizeLabel={item.localize_label}
                name={item.name}
                value={values[item.name]}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors[item.name]}
                touched={touched[item.name]}
                inputProps={{
                  maxlength: 64,
                }}
              />
            )),
          )}
        </AuthenticationSteps>
        <FormikErrorMessage
          isText
          textError="string9"
          formikProp={formikProp}
          value={['root_pass', 'confirm_pass', 'virtual_server_name']}
        />
      </Heading>
    </NewVMStep6SC>
  );
};
export default NewVMStep6;
