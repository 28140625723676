import React from 'react';
import { Paragraph, List, Steps } from 'pages/member/affiliate/Affiliate.style';
import { Trans } from 'react-i18next';
import { Highlight } from 'components/vm-deployment/components';
import { Heading } from 'components/user-management/Heading';

const HeadingWithText = ({ children, backgroundColor, text, paraStyle, listStyle }) => {
  return (
    <Steps style={backgroundColor}>
      <Heading>
        <Trans i18nKey={text} components={{ highlight: <Highlight /> }} />
      </Heading>
      <List style={listStyle}>
        <Paragraph style={paraStyle}>{children}</Paragraph>
      </List>
    </Steps>
  );
};
export { HeadingWithText };
