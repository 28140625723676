/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-27 18:23:09
 * @modify date 2021-02-05 14:09:45
 */

import { dynamicStyles } from 'components/reusable-css';
import styled, { css } from 'styled-components';
import { deviceBreakpoints, variables } from 'variables';

export const Error = styled.div`
  padding-top: 1em;
  padding-bottom: 1.5em;
`;

export const Warning = styled.div`
  padding: 0 1em;
  padding-bottom: 0.5em;
  width: 100%;
`;

export const LogOutUserSC = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  background: white;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
`;

export const MemberNavigatorSC = styled.div`
  display: flex;
  opacity: ${({ isUserLogout }) => (isUserLogout ? 0.5 : 1)};
`;

export const PageHeaderBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  background: ${(props) => props.theme.pageBackgroundColor};
  max-width: calc(100vw - ${(props) => props.theme.default?.desktop_menu_width});
  ${(props) =>
    props.theme.resolutions?.map((resolution) => {
      const { width, desktop_menu_width } = resolution;
      if (desktop_menu_width !== undefined) {
        return css`
          @media only screen and (max-width: ${width}px) {
            max-width: calc(100vw - ${desktop_menu_width});
          }
        `;
      }
    })};

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    max-width: 100%;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: calc(100vh - ${(props) => props.theme.default?.top_bar_height});
  width: 100%;
  overflow: ${(props) => (props.isAppDrawerOpen ? 'hidden' : 'auto')};
  position: relative;

  ${(props) =>
    props.theme.resolutions?.map((resolution) => {
      const { width, top_bar_height } = resolution;
      if (top_bar_height != undefined)
        return css`
          @media only screen and (max-width: ${width}px) {
            max-height: calc(100vh - ${top_bar_height});
          }
        `;
    })};

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    max-width: 100%;
    max-height: 100%;
    margin-top: ${(props) => props.theme.default?.top_bar_height};
    ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_height', 'margin-top')};
    ${(props) => props.isAppDrawerOpen && 'filter: brightness(0.95)'};
  }
`;

export const PageContent = styled.div`
  flex: 1;
  min-height: calc(100vh - ${variables.headerHeight + variables.pageContentPadding * 2}px);
  transition: transform 500ms ease;

  //transform: ${(props) =>
    props.isAppDrawerOpen ? `translateX(${variables.appDrawer.mobileExpandedWidth}px)` : `none`};
`;
