import CustomButton from 'components/common/custom-button';
import React from 'react';
import * as Styles from 'pages/member/user-management/UserManagement.style';
import Modal from 'components/modal';
import get from 'lodash/get';
import { H5, P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const DeleteCard = ({ cardStyle, buttons, handleSubmit, loading, toggle, open, role }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Modal open={open} onClose={toggle}>
        <Styles.DeleteCards style={cardStyle}>
          <Styles.DeleteContent>
            {buttons?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <H5>{t(item?.heading_lang)}</H5>
                  <P>{t(item?.title_lang)}</P>
                  {role?.users_assigned?.length > 0 && (
                    <P>
                      {t('account/usermgmt.string18')}{' '}
                      {role.users_assigned.map((user, index) => (
                        <span key={index}>
                          {user}
                          {role.users_assigned.length !== index + 1 ? ', ' : ''}
                        </span>
                      ))}
                    </P>
                  )}
                  <Styles.Buttons>
                    {get(item, 'buttons', []).map((button, index) => (
                      <CustomButton
                        isLoading={button.button === 'Yes' ? loading : null}
                        key={index}
                        title={t(button.button_lang)}
                        buttonStyles={{
                          padding: ' 8px 32px',
                          marginLeft: '8px',
                        }}
                        onClick={button.button === 'Yes' ? handleSubmit : toggle}
                      />
                    ))}
                  </Styles.Buttons>
                </React.Fragment>
              );
            })}
          </Styles.DeleteContent>
        </Styles.DeleteCards>
      </Modal>
    </React.Fragment>
  );
};
export { DeleteCard };