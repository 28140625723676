import React, { useState } from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import { actionOptions, bmActionsTabs } from 'components/manage-vm/utils';
import TriCut from 'assets/images/action-tri-cut.png';
import { Backup, DestroyVM, NewPassword, Reinstall } from './settings-action';
import ConditionalFragment from 'components/conditional-fragment';
import { TabContent } from './TabContent';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import CancelVM from './settings-action/CancelVM';

const ActionsManage = ({ data, loading, refreshServer, type, setDeletedIds, initialTab }) => {
  const { t } = useTranslation();
  const isCancellation = data.bm_cancelation_scheduled == 1;
  const isVsCancellation = data?.vs_cancelation_scheduled == 1;
  const actionData = actionOptions(type, { undoBm: isCancellation, undoVs: isVsCancellation }, data).filter(
    (obj) => obj.show,
  );
  const [isShow, setIsShow] = useState(
    type === 'bm' ? initialTab || actionData[0]?.id : initialTab || actionData[0]?.id,
  );
  const toggle = (value) => {
    setIsShow(value);
  };
  return (
    <React.Fragment>
      <Styles.ActionDetail>
        <Styles.Action1>
          <Styles.ActionOptions>
            {actionData.map((actionOption) => (
              <div key={actionOption.id} style={{ position: 'relative' }}>
                <ConditionalFragment condition={isShow == actionOption.id}>
                  <img
                    style={{
                      backgroundColor: 'red',
                      position: 'absolute',
                      right: -29,
                      width: '25px',
                      top: 4,
                    }}
                    src={TriCut}
                  />
                </ConditionalFragment>
                <Styles.ActionText isShow={isShow == actionOption.id}>
                  <Icon name="circle" />
                  <Styles.ActionOption isShow={isShow == actionOption.id} onClick={() => toggle(actionOption.id)}>
                    {t(actionOption.lang)}
                  </Styles.ActionOption>
                </Styles.ActionText>
              </div>
            ))}
          </Styles.ActionOptions>
        </Styles.Action1>
        <ConditionalFragment condition={type === 'vs'}>
          <Styles.Action2>
            <ConditionalFragment condition={isShow === 'backup'}>
              <Backup />
            </ConditionalFragment>
            <ConditionalFragment condition={isShow === 'destroy_VM'}>
              <DestroyVM loading={loading} refreshServer={refreshServer} setDeletedIds={setDeletedIds} />
            </ConditionalFragment>
            <ConditionalFragment condition={isShow === 'cancel_VM'}>
              <CancelVM data={data} refreshServer={refreshServer} loading={loading} />
            </ConditionalFragment>
            <ConditionalFragment condition={isShow === 'new_password'}>
              <NewPassword loading={loading} refreshServer={refreshServer} />
            </ConditionalFragment>
            <ConditionalFragment condition={isShow === 'reinstall'}>
              <Reinstall
                billing_schedule={data?.billing_schedule == 'hourly'}
                manageVmId={data?.id}
                loading={loading}
                refreshServer={refreshServer}
              />
            </ConditionalFragment>
          </Styles.Action2>
        </ConditionalFragment>
        <ConditionalFragment condition={type === 'bm'}>
          <Styles.Action2>
            {bmActionsTabs(data, { isCancellation })
              .filter((obj) => obj.show)
              .map((item, index) => (
                <ConditionalFragment condition={isShow === item.type} key={index}>
                  <TabContent
                    buttonText={item.buttonText}
                    content={item.content}
                    content2={item.content2}
                    buttonProps={{
                      onClick: async () => {
                        await refreshServer(item.action);
                      },
                      isLoading: loading,
                    }}
                  />
                </ConditionalFragment>
              ))}
          </Styles.Action2>
        </ConditionalFragment>
      </Styles.ActionDetail>
    </React.Fragment>
  );
};

export const Actions = React.memo(ActionsManage);
