/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-02 18:13:01
 * @modify date 2021-02-02 18:13:01
 */

import React from 'react';
import PropTypes from 'prop-types';

import { NotificationSC } from '../styles/Notification.style';

export const Notification = ({ children, bg }) => {
  return <NotificationSC bg={bg}>{children}</NotificationSC>;
};
Notification.propTypes = {
  /** the content of the notification */
  children: PropTypes.any,
  bg: PropTypes.any,
};

Notification.defaultProps = {
  children: '',
};
