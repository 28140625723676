import {
  UPDATE_CPANEL_OS,
  UPDATE_PRICING,
  VM_DEPLOYMENT,
  VM_DEPLOYMENT_PRICING,
  NO_OF_VM,
  VM_CREATION,
  VM_REFETCH,
} from 'store/types';

export const updateVM_Deployment = (payload) => (dispatch) => {
  dispatch({
    type: VM_DEPLOYMENT,
    payload,
  });
};

export const updateNoOfVM = (payload) => (dispatch) => {
  dispatch({
    type: NO_OF_VM,
    payload,
  });
};

export const updatePricingDetails = (payload) => (dispatch) => {
  dispatch({
    type: VM_DEPLOYMENT_PRICING,
    payload,
  });
};

export const updatePricing = (payload, key) => (dispatch) => {
  dispatch({
    type: UPDATE_PRICING,
    payload,
    key,
  });
};

export const updateCPanelOs = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_CPANEL_OS,
    payload,
  });
};

export const updateVmCreation = (payload) => (dispatch) => {
  dispatch({
    type: VM_CREATION,
    payload,
  });
};

export const handleVmRefetch = (payload) => (dispatch) => {
  dispatch({
    type: VM_REFETCH,
    payload,
  });
};
