import React from 'react';
import { CustomLineChart } from '../charts';
import { HeadingAndChart } from './index';
import { NotAvailable } from '../Statistics.style.js';
import { YStepSizeRounded } from '../util';
import { P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const LineUsage = ({
  title,
  containerClass,
  statsData,
  ystepSize,
  xAxis,
  maxValues,
  yLabel,
  pointRadius,
  customStepSize,
  count,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`pt-3 ${containerClass || 'w-50'}`}>
      <HeadingAndChart count={count} heading={title || 'vs/instance.string71'}>
        <div
          style={{
            ...(maxValues?.highestValue == 0 && { opacity: 0.5, pointerEvents: 'none' }),
            height: '20rem',
            marginTop: '-40px',
          }}
        >
          <CustomLineChart
            yLabel={yLabel}
            statsData={statsData}
            ystepSize={YStepSizeRounded(customStepSize, maxValues, ystepSize)}
            xAxis={xAxis}
            yMax={maxValues?.yMaxValue}
            pointRadius={pointRadius}
          />
        </div>

        {maxValues?.highestValue == 0 && (
          <div className="ml-lg-0 ml-3">
            <NotAvailable>{t('vs/instance.string68')}</NotAvailable>
            <P style={{ marginTop: '2rem' }}>{t('vs/instance.string62')}</P>
          </div>
        )}
      </HeadingAndChart>
    </div>
  );
};

export { LineUsage };
