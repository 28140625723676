import React, { useEffect } from 'react';
import { BgLogin, LoginBox, LoginForm, Logo, LoginContainer } from './OnetimeThankYou.style';
import CustomButton from 'components/common/custom-button';
import { H1, P } from 'components/reusable-css';
import { Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { navLinks } from 'utils/nav-links-util';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { BILLING_ONE_TIME_PAYMENT_THANKYOU } from './queries';
import { ACCOUNT_DETAIL } from 'components/vm-deployment/queries';
import { reactQueryConfig } from 'constants/react-query-config';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorageValues } from 'constants/local-storage';
import { updateUserAccount, updateUserAccountRefetch } from 'store/actions/user-details';

const OnetimeThankYou = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { mutate } = useMutation(BILLING_ONE_TIME_PAYMENT_THANKYOU);
  const userUI = useSelector((state) => state.user.ui);
  let { userName } = getLocalStorageValues();

  useEffect(async () => {
    await mutate();
  }, []);

  // ACCOUNTS API
  const { refetch: accountRefetch } = useQuery(['ACCOUNT_DETAIL', {}], ACCOUNT_DETAIL, {
    ...reactQueryConfig,
    enabled: userName.token ? true : false,
    onSuccess: (res) => {
      dispatch(updateUserAccount(res));
      dispatch(updateUserAccountRefetch(accountRefetch));
    },
    refetchInterval: Number(userUI.refresh_every_x_seconds) * 1000,
    refetchIntervalInBackground: true,
  });

  return (
    <BgLogin>
      <LoginContainer>
        <LoginBox>
          <LoginForm>
            <Logo>
              <Icon name="check circle" />
            </Logo>
            <H1>{t('account/onetime/thankyou.string1')}</H1>
            <P>{t('account/onetime/thankyou.string2')}</P>
            <CustomButton
              onClick={() => history.replace(navLinks.billing.link)}
              buttonStyles={{ width: '100%' }}
              type="submit"
              title={'account/onetime/thankyou.string3'}
            />
          </LoginForm>
        </LoginBox>
      </LoginContainer>
    </BgLogin>
  );
};

export default OnetimeThankYou;
