import { VS_CART, REMOVE_VS_CART, CLEAR_ALL_VS_CART, UPDATE_VS_CART } from 'store/types';

export const addVS_CART = (payload) => (dispatch) => {
  dispatch({
    type: VS_CART,
    payload,
  });
};

export const removeVS_CART = (payload) => (dispatch) => {
  dispatch({
    type: REMOVE_VS_CART,
    payload,
  });
};

export const clearAllVS_CART = (payload) => (dispatch) => {
  dispatch({
    type: CLEAR_ALL_VS_CART,
    payload,
  });
};

export const updateVS_CART = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_VS_CART,
    payload,
  });
};
