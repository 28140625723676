import { h6Styles } from 'components/reusable-css';
import styled from 'styled-components';

export const ErrorSC = styled.div`
  display: flex;
  justify-content: flex-end;
  line-height: 2em;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  i {
    color: #bd0505;
    margin-right: 12px;
    margin-top: 2px;
  }
`;
export const ErrorMsg = styled.div`
  background: #bd0505;
  border-radius: 4px 0 0 2px;
  padding: 7px 16px;
  p {
    color: white;
  }
`;
