/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-27 18:15:34
 * @modify date 2021-02-05 10:00:25
 */

import { UPDATE_LANGUAGE } from 'store/types';

const initialState = {
  lang: localStorage.getItem('i18nextLng') || 'en-US',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_LANGUAGE:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
}
