import * as yup from 'yup';
import Concrete5 from 'assets/svg/wh_icon_Concrete-5.svg';
import Drupal from 'assets/svg/wh_icon_drupal.svg';
import Joomla from 'assets/svg/wh_icon_joomla.svg';
import Magento from 'assets/svg/wh_icon_magento.svg';
import WP from 'assets/svg/wh_icon_wp.svg';

export const whStep1Data = [
  {
    id: 'register_a_new_domain',
    value: 'register_a_new_domain',
  },
  {
    id: 'transfer_an_existing_domain',
    value: 'transfer_an_existing_domain',
  },
  {
    id: 'no_need_for_a_domain',
    value: 'no_need_for_a_domain',
  },
];

export const initialValueOfTransferDomain = {
  transfer_domain: '',
};

export const validationSchemaOfTransferDomain = yup.object({
  transfer_domain: yup.string(),
});

export const initialValue = (domainExt) => {
  return {
    new_domain: '',
    selectedDomainExt: domainExt,
  };
};

export const validationSchema = yup.object({
  new_domain: yup
    .string()
    .required('Enter domain name')
    .max(64, 'Maximum length is 64 characters')
    .matches(/^(?!-)(?!.*-$)[a-zA-Z0-9-]+$/, 'Incorrect format'),
  transfer_domain: yup.string().max(64, 'Maximum length is 64 characters'),
  selectedDomainExt: yup.string().required('Select Extension'),
});

export const getAppImage = (name) => {
  switch (name) {
    case 'concrete5':
      return Concrete5;
    case 'drupal':
      return Drupal;
    case 'joomla':
      return Joomla;
    case 'magento':
      return Magento;
    case 'wp':
      return WP;
    default:
      return '';
  }
};

export const sizesData = (webiste, storage) => [
  {
    heading: 'Top Features',
    description: [
      { text: `${webiste} Website` },
      { text: `${storage} GB SSD Storage` },
      { text: `Custom Themes` },
      { text: `24/7 Customer Support` },
    ],
  },
  {
    heading: 'Website Builder Available',
    description: [
      { text: `WordPress Integration` },
      { text: `${storage} GB SSD Storage` },
      { text: `Drag and Drop Functionality` },
      { text: `Al-Driven Templates` },
    ],
  },
  {
    heading: 'Also Includes',
    description: [
      { text: `Free Domain - 1 year` },
      { text: `Free CDN Included` },
      { text: `Free Domain Privacy` },
      { text: `Free Automated Backup - 1 year` },
    ],
  },
];
export const domainTabs = {
  register_a_new_domain: 'register_a_new_domain',
  transfer_an_existing_domain: 'transfer_an_existing_domain',
  no_need_for_a_domain: 'no_need_for_a_domain',
};
export const whCreateInitialValue = {
  domain: '',
  size: '',
  region: '',
  app: '',
  options_prepaid: 12,
  domain_checked: domainTabs.register_a_new_domain,
  prices: {
    domain: 0,
    size: 0,
    yearly: {
      size: 0,
      domain: 0,
    },
  },
};

export const whCreateValidationSchema = (validations = {}) =>
  yup.object({
    size: yup
      .object({
        id: yup.string().required('Something wrong with the data'),
      })
      .required('Select size'),
    region: yup.string().required('Select region'),
    app: yup.string().required('Select app'),
    options_prepaid: yup.string().required('Select option prepaid'),
    ...validations,
  });
