/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-27 18:17:19
 * @modify date 2021-01-27 18:17:21
 */

export function saveInLocalStore(name, value, prefix) {
  let state = JSON.parse(localStorage.getItem('state')) ?? {};
  if (state[prefix] === undefined) {
    state[prefix] = {};
  }
  state[prefix][name] = value;
  localStorage.setItem('state', JSON.stringify(state));
}

export function getFromLocalStore(name, prefix, defaultValue) {
  const state = JSON.parse(localStorage.getItem('state'));
  if (state && state[prefix] && state[prefix][name] !== undefined) {
    return state[prefix][name];
  }
  return defaultValue;
}
