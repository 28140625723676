import React from 'react';
import { BgLogin, LoginBox, LoginForm, Logo, LoginContainer } from 'components/error-screen/ErrorScreen.style';
import LogoImage from 'assets/images/serverpoint.svg';
import { Link } from 'react-router-dom';

const PaypalSuccessScreen = () => {
  return (
    <BgLogin>
      <LoginContainer>
        <LoginBox>
          <LoginForm>
            <Logo>
              <img src={LogoImage} alt="serverpoint" width="100%" />
            </Logo>
            <p>Thank you!</p>
            <p>
              Your PayPal payment has been processed. You can now close this browser tab to return to ServerPoint's
              Client Portal.
            </p>
            <p>
              Or just click <Link to="/welcome">here</Link>.
            </p>
          </LoginForm>
        </LoginBox>
      </LoginContainer>
    </BgLogin>
  );
};

export default PaypalSuccessScreen;
