import React from 'react';
import { Icon } from 'semantic-ui-react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import TriCut from 'assets/images/button-tri-cut.png';
import { Head } from './Head';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';

const TabsHeader = ({ data, menuOptions, isClose, activePanelValue, setActivePanelValue }) => {
  const { t } = useTranslation();
  const rights = useSelector(userRights);
  return (
    <Styles.Header>
      <Styles.CrossIcon>
        <Icon size="large" name="close" onClick={() => isClose(false)} />
      </Styles.CrossIcon>
      <Styles.Start>
        <Head data={data} />
        <Styles.Options>
          {menuOptions(rights, data).map((menuOption) => (
            <Styles.Option
              menuOptionId={menuOption.id}
              activePanelValue={activePanelValue}
              onClick={() => setActivePanelValue(menuOption.id)}
              key={menuOption.id}
            >
              {t(menuOption.name_lang)}
              {activePanelValue == menuOption.id && <img className="tricut-image" src={TriCut} />}
            </Styles.Option>
          ))}
        </Styles.Options>
      </Styles.Start>
    </Styles.Header>
  );
};

export { TabsHeader };
