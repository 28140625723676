import React from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import { H4 } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const NoBackup = ({ title }) => {
  const { t } = useTranslation();
  return (
    <Styles.NoBackupCard>
      <H4>{t(title)}</H4>
    </Styles.NoBackupCard>
  );
};
export { NoBackup };