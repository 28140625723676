/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { Formik } from 'formik';
import { initialValue, validation } from 'constants/account-profile';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { LOGIN_EMAIL } from '../queries';
import { CustomHook } from 'utils/custom-hook';
import LoginEmailForm from './LoginEmailForm';

const LoginEmail = ({ username, width }) => {
  const { mutationProps } = CustomHook();
  const { data: email } = useQuery('/account/profile/login_alias');
  const { mutate: LoginEmail, isLoading: loginEmailLoading } = useMutation(LOGIN_EMAIL);
  const handleSubmit = async (values, resetForm) => {
    await LoginEmail(values, mutationProps(resetForm));
  };

  return (
    <Formik
      initialValues={initialValue(email)}
      validationSchema={validation}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      enableReinitialize
    >
      {(formikProp) => (
        <LoginEmailForm width={width} username={username} loginEmailLoading={loginEmailLoading} formikProp={formikProp} />
      )}
    </Formik>
  );
};
export default LoginEmail;
