import * as Yup from 'yup';
import { nameRegex, numberRegex, mixRegex } from 'constants/regex';
import { cardDetailValues } from 'pages/member/add-fund/constants';

export const formValidation = {
  card_name: Yup.string()
    .matches(nameRegex, 'common/credit_card_module.string42')
    .required('common/credit_card_module.string43'),
  cvv: Yup.string()
    .matches(numberRegex, 'common/credit_card_module.string44')
    .required('common/credit_card_module.string45'),
  card_number: Yup.string()
    .matches(numberRegex, 'common/credit_card_module.string44')
    .required('common/credit_card_module.string46'),
  city: Yup.string()
    .matches(mixRegex, 'common/credit_card_module.string42')
    .required('common/credit_card_module.string47'),
  state: Yup.string()
    .matches(mixRegex, 'common/credit_card_module.string42')
    .required('common/credit_card_module.string48'),
  billing_address: Yup.string()
    .matches(mixRegex, 'common/credit_card_module.string42')
    .required('common/credit_card_module.string49'),
  zip: Yup.string()
    .matches(mixRegex, 'common/credit_card_module.string42')
    .required('common/credit_card_module.string50'),
  bank_phone_number: Yup.string()
    // .matches(mixRegex, 'common/credit_card_module.string42')
    .required('common/credit_card_module.string51'),
  bank_name: Yup.string()
    .matches(mixRegex, 'common/credit_card_module.string42')
    .required('common/credit_card_module.string52'),
  phone: Yup.string().required('common/credit_card_module.string54'),
  address_continue: Yup.string().matches(mixRegex, 'common/credit_card_module.string42'),
  company: Yup.string().matches(mixRegex, 'common/credit_card_module.string42'),
};
export const validationObject = (isCustomValue) =>
  isCustomValue
    ? Yup.object({
        ...formValidation,
        custom_vs_amount: Yup.string()
          .required('account/billing_info.string40')
          .matches('^[1-9]+[0-9]+$', 'account/billing_info.string40'),
      })
    : Yup.object(formValidation);

export const validationChargedCard = Yup.object({
  custom_vs_amount: Yup.number().required('account/billing_info.string40').moreThan(9, 'account/billing_info.string40'),
});

export const paymentMethodValidation = {
  payment_method: Yup.string().required('common/credit_card_module.string53'),
};

export const cardError = (errors, values, touched) => {
  const isNoChangeCard = values?.options_prepaid?.minimum_payment != '0';
  if (isNoChangeCard) {
    return cardDetailValues.some((item) => errors[item] && touched[item]);
  }
  return false;
};
