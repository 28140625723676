import React, { useState, useRef, useEffect } from 'react';
import { Formik as BaseFormik } from 'formik';
import { initialValues, validationSchema } from 'constants/user-management';
import { CustomHook } from 'utils/custom-hook';
import { useMutation } from 'react-query';
import { ADD_USER } from 'components/user-management/queries';
import { disableStyles } from 'components/manage-vm/utils';
import { get } from 'lodash';
import UserSubmissionForm from './UserSubmissionForm';
import axios from 'axios';
import { BASE_URL } from 'constants/index';

const AddUser = ({ usersRefetch, getRoles, roleRefetch }) => {
  let formikRef = useRef();
  const { mutationProps } = CustomHook();
  const { mutate: addUser, isLoading: addUserLoading } = useMutation(ADD_USER);
  const [userState, setUserState] = useState({ isUserExist: false, isUserLoading: false });
  const handleAddUser = async (values, resetForm) => {
    await addUser(
      { user: values?.username, password: btoa(values?.new_user_password), roleid: values?.role },
      mutationProps(() => {}, {
        onSuccess: async () => {
          await usersRefetch();
          roleRefetch();
          resetForm();
        },
      }),
    );
  };
  let initialRole = get(getRoles, 'role_rights[0].id');
  const mounted = useRef(false);
  //Custom validation
  const validation = async ({ username }) => {
    const errors = {};
    //On first mount, Username error should not be displayed.
    if (!username && mounted?.current) {
      errors.username = 'Username required';
      return errors;
    }
    setUserState({ isUserExist: false, isUserLoading: true });
    return axios
      .post(BASE_URL + '/account/usermgmt/users', { user: username, dryrun: '1' })
      .then(() => {
        //If User does not exist in the database.
        setUserState({ isUserExist: false, isUserLoading: false });
      })
      .catch(() => {
        //If User exist in the database.
        setUserState({ isUserExist: true, isUserLoading: false });
        if (username) {
          errors.username = 'This user already exists';
          return errors;
        }
      });
  };
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldTouched('username');
    }
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <BaseFormik
      innerRef={formikRef}
      onSubmit={(values, { resetForm }) => handleAddUser(values, resetForm)}
      initialValues={initialValues(initialRole)}
      validationSchema={validationSchema}
      validate={validation}
      enableReinitialize
    >
      {(formikProps) => (
        <UserSubmissionForm
          style={!initialRole ? disableStyles : {}}
          formikProps={formikProps}
          user={getRoles}
          loading={addUserLoading}
          isUserExist={userState.isUserExist}
          checkUserLoading={userState.isUserLoading}
        />
      )}
    </BaseFormik>
  );
};
export { AddUser };
