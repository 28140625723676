import { h4Styles, h5Styles, h6Styles, pStyles } from 'components/reusable-css';
import styled, { css } from 'styled-components';
import { deviceBreakpoints } from 'variables';

export const Features = styled.div`
  flex: 1;
  padding: 0 15px;
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 1.5em;
  background: ${(props) => props.theme.serverSizeCard.features.backgroundColor};
  @media only screen and (max-width: 649px) {
    padding: 1em 15px 3em 15px;
  }
`;

export const Currency = styled.div`
  text-transform: uppercase;
  margin-right: 5px;
`;

export const GreyOut = styled.div`
  opacity: ${(props) => (props.isGrey ? 0.4 : 1)};
`;

export const ServerSizeCardSC = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 100%;
  height: 430px;
  background: ${(props) => props.theme.gray.shadeF};
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  overflow: hidden;
  cursor: pointer;
  transition: all 150ms ease;
  @media only screen and (max-width: 649px) {
    height: auto;
  }
  &:hover {
    background: ${(props) => props.theme.serverSizeCard.hoverBackgroundColor};
    border-color: ${(props) => props.theme.serverSizeCard.hoverBorderColor};

    ${Features} {
      background: ${(props) => props.theme.serverSizeCard.features.hoverBackgroundColor};
    }
  }

  ${(props) =>
    props.selected &&
    css`
      border-color: ${(props) => props.theme.primaryColor};
    `};
`;

export const Tag = styled.div`
  display: flex;
  visibility: ${(props) => (props.showTag ? 'visible' : 'hidden')};
  align-items: center;
  justify-content: space-evenly;
  align-self: flex-end;
  background: ${(props) => props.theme.serverSizeCard.tag.backgroundColor};
  color: ${(props) => props.theme.gray.shadeF};
  height: 25px;
  width: 200px;
  border-radius: 0 10px 0 20px;
  position: relative;
  top: -2px;
  right: -2px;
  font-style: italic;
  font-weight: bold;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    display: none;
  }
`;

export const MobileTag = styled.div`
  display: none;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    z-index: 3;
    position: absolute;
    display: flex;
    visibility: ${(props) => (props.showTag ? 'visible' : 'hidden')};
    align-items: center;
    justify-content: center;
    align-self: center;
    background: ${(props) => props.theme.serverSizeCard.tag.backgroundColor};
    color: ${(props) => props.theme.gray.shadeF};
    height: 25px;
    border-radius: 0 0 8px 8px;
    padding: 3px 10px;
    font-style: italic;
    font-weight: bold;
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
    position: relative;
    top: -2px;
    right: -2px;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding-right: 15px;
  padding-left: 10px;
  margin-top: 8px;
  width: 40%;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    width: 100%;
    justify-content: center;
    margin-top: 0;
    margin-bottom: -5px;
  }
`;

export const FlexCon = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Name = styled.div`
  display: flex;
  white-space: nowrap;
  font-size: ${(props) => props.theme.default?.h4};
  ${(props) => h4Styles(props.theme.resolutions)};
`;

export const ActualPrice = styled.div`
  display: flex;
  text-decoration: line-through;
  margin-left: 5px;
  font-weight: normal;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;

export const Price = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 10px;
  padding-left: 15px;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    width: 100%;
    align-items: center;
  }
`;

export const PriceNoMonth = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 10px;
  padding-left: 15px;
  margin-top: 6px;
  margin-bottom: 10px;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    width: 100%;
    margin-top: 0;
    align-items: center;
  }
`;

export const PerMonth = styled.div`
  display: flex;
  align-items: baseline;
  font-weight: 900;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;

export const PriceUnit = styled.div`
  display: flex;
  margin-left: 4px;
  font-size: ${(props) => props.theme.default?.h4};
  ${(props) => h4Styles(props.theme.resolutions)};
`;

export const PerHour = styled.div`
  display: flex;
  margin: 0;
  margin-top: -5px;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    margin-top: 0;
  }
`;

export const Feature = styled.div`
  display: flex;
  line-height: 2em;
  p {
    line-height: 2em;
  }

  @media only screen and (max-width: 649px) {
    margin-right: 6px;
    margin-bottom: 10px;
    p {
      line-height: 1.2em;
    }
  }
  i {
    color: ${(props) => props.theme.primaryColor};
    margin-right: 6px;
  }
`;

export const SelectedTag = styled.div`
  display: flex;
  background: ${(props) => props.theme.serverSizeCard.selectedTag.backgroundColor};
  border-radius: 0;
  border-top-left-radius: 20px;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.gray.shadeF};
  height: 40px;
  width: 220px;
  position: absolute;
  bottom: -2px;
  right: -2px;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};

  i {
    margin-right: 6px;
    margin-bottom: 8px;
  }
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    display: none;
  }
`;

export const SelectedMobileTag = styled.div`
  display: none;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    display: flex;
    color: ${(props) => props.theme.gray.shadeF};
    border-radius: 0;
    border-left: 45px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 30px solid ${(props) => props.theme.primaryDarkColor};
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
    i {
      color: white;
      position: absolute;
      bottom: -24px;
      right: 2px;
    }
  }
`;

export const WaterMark = styled.div`
  position: absolute;
  z-index: 121212;
  font-size: ${(props) => props.theme.default?.h5};
  ${(props) => h5Styles(props.theme.resolutions)};
  font-weight: 600;
  display: flex;
  opacity: 0.88;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100%;
  .button-type {
    border: 1px solid;
    border-color: ${(props) => props.theme.primaryColor};
    background-color: ${(props) => props.theme.primaryColor};
    color: black;
    border-radius: 23px;
  }
`;
