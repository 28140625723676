import React from 'react';
import { Icon } from 'semantic-ui-react';
import * as Styles from './Styles';
const TickIcon = () => {
  return (
    <Styles.TickIcon>
      <Icon name="check" />
    </Styles.TickIcon>
  );
};

export { TickIcon };
