import React from 'react';
import { Text } from './userRole.style';
import { Grid } from '@material-ui/core';
import { Icon } from 'semantic-ui-react';
import classes from './styles.module.css';
import { Roles } from 'components/user-management/';
import CustomButton from 'components/common/custom-button';
import ErrorMessage from 'components/common/red-notification';
import Input from 'components/input';
import { P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

export const EditRoleContent = ({ getRole, labels, formikProps, loading }) => {
  const { t } = useTranslation();
  const { dirty, values, handleSubmit, setFieldValue, isSubmitting, handleChange, handleBlur, errors, touched } =
    formikProps;

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={5} sm={4} md={4} lg={3} xl={3}>
          <Text>{t('account/usermgmt.string5')}</Text>
        </Grid>
        <Grid className={classes.startRight} xs={5} sm md lg xl>
          {values.isEdit === 'text' && <Text>{getRole.rolename.toUpperCase()}</Text>}
          {values.isEdit === 'edit' && (
            <Input
              type={'text'}
              className="custom_inputs"
              name={'edit_role_name'}
              value={values['edit_role_name']}
              placeholder={t('account/usermgmt.string16')}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors['edit_role_name']}
              touched={touched['edit_role_name']}
              onEnter={(e) => setFieldValue('edit_role_name', e.target.value)}
              styling={{ width: '18rem', marginTop: '-3em' }}
            />
          )}
          <Icon
            style={{ margin: '-0.5rem 0 0 0.8rem' }}
            onClick={() => setFieldValue('isEdit', 'edit')}
            name="pencil"
          />
        </Grid>
      </Grid>
      <Grid className={classes.startRow} container>
        <Grid item xs={5} sm={4} md={4} lg={3} xl={3}>
          <Text>{t('account/usermgmt.string17')}</Text>
        </Grid>
        <Grid className={classes.startRight} xs={5} sm md lg xl>
          <Text className="text-break">{getRole.id}</Text>
        </Grid>
      </Grid>
      <Roles
        panelRole
        panel={'true'}
        labels={labels}
        getRole={getRole}
        isCheck={values.isCheck}
        setIsCheck={(value) => setFieldValue('isCheck', value)}
      />
      <br />
      <br />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} />
        <Grid xs={12} sm md lg xl>
          <CustomButton
            onClick={handleSubmit}
            isLoading={isSubmitting || loading}
            disabled={!dirty}
            type="submit"
            buttonStyles={{ padding: '9px 55px', marginTop: '-1rem' }}
          />
          <div style={{ margin: '2em 0' }}>
            <ErrorMessage>
              <P>{t('account/usermgmt.string8')}</P>
            </ErrorMessage>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
