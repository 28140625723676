import SupportIcon from 'assets/svg/icon_menu_support.svg';
import KbIcon from 'assets/svg/icon_menu_changelog.svg';
import ChangelogIcon from 'assets/svg/icon_menu_changelog.svg';
import BmIcon from 'assets/svg/icon_menu_bare_metal_servers.svg';
import VsIcon from 'assets/svg/icon_menu_virtual_servers.svg';
import SupportIconHover from 'assets/svg/icon_menu_support_hover.svg';
import KbIconHover from 'assets/svg/icon_menu_changelog_hover.svg';
import ChangelogIconHover from 'assets/svg/icon_menu_changelog_hover.svg';
import BmIconHover from 'assets/svg/icon_menu_bare_metal_servers_hover.svg';
import VsIconHover from 'assets/svg/icon_menu_virtual_servers_hover.svg';
import DnsIcon from 'assets/svg/icon_domain-dns.svg';
import DnsIconHover from 'assets/svg/icon_domain-dns_hover.svg';

export const getMenuIcon = (name) => {
  switch (name) {
    case 'icon-menu_support.svg':
      return SupportIcon;
    case 'icon-menu_layout.svg':
      return KbIcon;
    case 'icon-menu_changelog.svg':
      return ChangelogIcon;
    case 'icon-menu_virtual-servers.svg':
      return VsIcon;
    case 'icon-menu_bare-metal-servers.svg':
      return BmIcon;
    case 'icon-menu_support_hover.svg':
      return SupportIconHover;
    case 'icon-menu_layout_hover.SVG':
      return KbIconHover;
    case 'icon-menu_changelog_hover.svg':
      return ChangelogIconHover;
    case 'icon-menu_virtual-servers_hover.svg':
      return VsIconHover;
    case 'icon-menu_bare-metal-servers_hover.svg':
      return BmIconHover;
    case 'icon-menu_domain_dns.svg':
      return DnsIcon;
    case 'icon-menu_domain_dns_hover.svg':
      return DnsIconHover;
    default:
      break;
  }
};
