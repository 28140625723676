import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';

export const NotificationSC = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  border-radius: 8px;
  font-weight: 500;
  background: ${(props) => (props.bg ? props.theme.notification.errorColor : props.theme.notification.backgroundColor)};
  color: white;
  padding: 7px 10px;

  i {
    margin-right: 4px;
  }
`;
