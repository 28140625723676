import React from 'react';
import ApproveImage from 'assets/images/shopper-approve.svg';
import FiveStarImage from 'assets/images/five-star.svg';
import * as Styles from './WelcomePage.style';
import { Trans, useTranslation } from 'react-i18next';
import VmUtils from '../new-vm/util';
import { navLinks } from 'utils/nav-links-util';
import { useDispatch, useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import ConditionalFragment from 'components/conditional-fragment';
import { P } from 'components/reusable-css';
import { updateActiveSectionIndex } from 'store/actions/common-actions';
import ErrorMessage from 'components/common/red-notification';
import { Icon } from 'semantic-ui-react';

const WelcomePage = ({ ui }) => {
  const dispatch = useDispatch();
  const { newVmPath } = VmUtils();
  const { t } = useTranslation();
  const userUi = useSelector((state) => state.user.ui);
  const rights = useSelector(userRights);
  const sectionIndex = useSelector((state) => state.commonStates.activeSectionIndex);
  const handleOnSectionClick = (index) => dispatch(updateActiveSectionIndex(index));

  return (
    <Styles.Welcomebg>
      {ui?.header_messages && ui?.header_messages[0]?.id && (
        <Styles.Error>
          {ui?.header_messages?.map((data) => {
            return (
              data?.id && (
                <Styles.Warning key={data.id}>
                  <ErrorMessage bgColor={`#${data.color}`} color={`#${data.font_color}`} width="100%">
                    <p>{t(data.api_language_string)} </p>
                  </ErrorMessage>
                </Styles.Warning>
              )
            );
          })}
        </Styles.Error>
      )}
      <Styles.WelcomeStart>
        <Styles.WelcomeTop>
          <Styles.WelcomeHeading>
            <Trans i18nKey={'welcome.string1'} components={{ span: <Styles.Span /> }} />
          </Styles.WelcomeHeading>
        </Styles.WelcomeTop>
        <Styles.WelcomeContent>
          <Styles.WelcomeBox>
            <Styles.InnerBox>
              <Styles.Logo />
              <Styles.List>
                <ConditionalFragment condition={rights.vsprovision}>
                  <Styles.Li>
                    <Icon name="circle" />
                    <Styles.Links to={newVmPath} onClick={() => handleOnSectionClick(0)}>
                      <Styles.ListName>
                        <Trans
                          i18nKey={'welcome.string2'}
                          components={{ link: <Styles.StyledLink />, paragraph: <Styles.LinkSpan /> }}
                        />
                      </Styles.ListName>
                    </Styles.Links>
                  </Styles.Li>
                </ConditionalFragment>
                <ConditionalFragment condition={rights.bmprovision}>
                  <Styles.Li>
                    <Icon name="circle" />
                    <Styles.Links to={navLinks.new_server.link} onClick={() => handleOnSectionClick(1)}>
                      <Styles.ListName>
                        <Trans
                          i18nKey={'welcome.string3'}
                          components={{ link: <Styles.StyledLink />, paragraph: <Styles.LinkSpan /> }}
                        />
                      </Styles.ListName>
                    </Styles.Links>
                  </Styles.Li>
                </ConditionalFragment>
                <ConditionalFragment condition={rights.vs}>
                  <Styles.Li>
                    <Icon name="circle" />
                    <Styles.Links to={navLinks.manage_your_vms.link} onClick={() => handleOnSectionClick(0)}>
                      <Styles.ListName>
                        <Trans
                          i18nKey={'welcome.string4'}
                          components={{ link: <Styles.StyledLink />, paragraph: <Styles.LinkSpan /> }}
                        />
                      </Styles.ListName>
                    </Styles.Links>
                  </Styles.Li>
                </ConditionalFragment>
                <ConditionalFragment condition={rights.bm}>
                  <Styles.Li>
                    <Icon name="circle" />
                    <Styles.Links to={navLinks.manage_your_servers.link} onClick={() => handleOnSectionClick(1)}>
                      <Styles.ListName>
                        <Trans
                          i18nKey={'welcome.string5'}
                          components={{ link: <Styles.StyledLink />, paragraph: <Styles.LinkSpan /> }}
                        />
                      </Styles.ListName>
                    </Styles.Links>
                  </Styles.Li>
                </ConditionalFragment>
                <ConditionalFragment condition={rights.usermanagement}>
                  <Styles.Li>
                    <Icon name="circle" />
                    <Styles.Links to={navLinks.user_management.link}>
                      <Styles.ListName>
                        <Trans
                          i18nKey={'welcome.string6'}
                          components={{ link: <Styles.StyledLink />, paragraph: <Styles.LinkSpan /> }}
                        />
                      </Styles.ListName>
                    </Styles.Links>
                  </Styles.Li>
                </ConditionalFragment>
                <ConditionalFragment condition={rights.affiliate}>
                  <Styles.Li>
                    <Icon name="circle" />
                    <Styles.Links
                      to={navLinks.make_money_with_serverpoints_affiliate_program.link}
                      onClick={() => handleOnSectionClick(sectionIndex == 0 || sectionIndex == 1 ? sectionIndex : 0)}
                    >
                      <Styles.ListName>
                        <Trans
                          i18nKey={'welcome.string7'}
                          components={{ link: <Styles.StyledLink />, paragraph: <Styles.LinkSpan /> }}
                        />
                      </Styles.ListName>
                    </Styles.Links>
                  </Styles.Li>
                </ConditionalFragment>
                <ConditionalFragment condition={rights.billing}>
                  <Styles.Li>
                    <Icon name="circle" />
                    <Styles.Links to={'/account/billing_info'}>
                      <Styles.ListName>
                        <Trans
                          i18nKey={'welcome.string8'}
                          components={{ link: <Styles.StyledLink />, paragraph: <Styles.LinkSpan /> }}
                        />
                      </Styles.ListName>
                    </Styles.Links>
                  </Styles.Li>
                </ConditionalFragment>
              </Styles.List>
            </Styles.InnerBox>
          </Styles.WelcomeBox>
          {userUi.show_reviews == 1 ? (
            <Styles.WelcomeBoxBottom>
              <a rel="noreferrer" href="http://www.shopperapproved.com/reviews/serverpoint.com/" target="_blank">
                <Styles.WelcomeBottomSecond>
                  <Styles.WelcomeBottomSecondList>
                    <Styles.Images>
                      <img src={ApproveImage} alt="img" />
                    </Styles.Images>
                    <hr />
                    <Styles.FiveStar>
                      <Styles.Linked>
                        <Styles.Star src={FiveStarImage} alt="img" />
                        <P style={{ paddingTop: '3px' }}>{t('welcome.string9')}</P>
                      </Styles.Linked>
                    </Styles.FiveStar>
                  </Styles.WelcomeBottomSecondList>
                </Styles.WelcomeBottomSecond>
              </a>
            </Styles.WelcomeBoxBottom>
          ) : null}
        </Styles.WelcomeContent>
      </Styles.WelcomeStart>
    </Styles.Welcomebg>
  );
};

export default WelcomePage;
