import _get from 'lodash/get';

export const sizePricingFilter = (sizes, prices) => {
  return _get(sizes, 'vs_sizes', []).map((item) => {
    const pricing = _get(prices, 'prices_vs_sizes', []).find(
      (price) => price.id === item.id,
    );
    return {
      ...item,
      ...pricing,
    };
  });
};
