import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const DELETE_CARD = async (e) => {
  const res = await axios.delete(BASE_URL + `/billing/ccs/${e.id}`);
  return res.data;
};

export const DEFAULT_CARD = async (e) => {
  let { id, ...rest } = e;
  const res = await axios.patch(BASE_URL + `/billing/ccs/${id}`, rest);
  return res.data;
};
