import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const DOMAIN_LOOKUP = async (e) => {
  const res = await axios.post(`${BASE_URL}/domain/lookup`, e);
  return res.data;
};

export const WH_CREATE_INSTANCE = async (e) => {
  const res = await axios.post(`${BASE_URL}/wh/create`, e);
  return res.data;
};