import React from 'react';
import FilledInput from '@material-ui/core/FilledInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import clsx from 'clsx';
export default function PasswordInput({
  showPassword,
  value,
  onChange,
  label,
  handleClickShowPassword,
  classes,
  name,
  ...rest
}) {
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl variant="filled">
      <InputLabel htmlFor={'filled-adornment-password' + name}>{label}</InputLabel>
      <FilledInput
        type={showPassword ? 'text' : 'password'}
        className={clsx(classes.root, classes.underline)}
        id={'filled-adornment-password' + name}
        name={name}
        {...rest}
        inputProps={{
          autocomplete: rest.autocomplete,
        }}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              style={{ position: 'absolute', right: 10, top: 0 }}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
PasswordInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  handleClickShowPassword: PropTypes.func,
  showPassword: PropTypes.any,
  label: PropTypes.any,
  classes: PropTypes.any,
  name: PropTypes.string,
};

export * from './TogglePassword';
