import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';
import { variables } from 'variables';

export const NewDomainStep2SC = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  padding: ${variables.pageContentPadding}px;
  padding-top: 80px;
  padding-bottom: 4em;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;

export const StepDescription = styled.div`
  margin-top: 50px;
  margin-bottom: 1.5em;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;
