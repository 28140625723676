import React from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import CustomButton from 'components/common/custom-button';
import { useTranslation } from 'react-i18next';

const DestroyVM = ({ refreshServer, loading, setDeletedIds }) => {
  const { t } = useTranslation();
  return (
    <Styles.ActionDiv>
      <Styles.DestroyText>{t('vs/instance.string103')}</Styles.DestroyText>
      <Styles.DestroyText>{t('vs/instance.string104')}</Styles.DestroyText>
      <Styles.DestroyText>{t('vs/instance.string105')}</Styles.DestroyText>
      <Styles.InputName>
        <CustomButton
          title={'vs/instance.string106'}
          buttonStyles={{ marginTop: '5px', padding: ' 9px 15px' }}
          isLoading={loading}
          onClick={() => {
            refreshServer('delete');
            setDeletedIds();
          }}
        />
      </Styles.InputName>
    </Styles.ActionDiv>
  );
};

export { DestroyVM };
