import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Heading } from './NewVMStep7.style';
import classes from 'assets/css/payment-form.module.css';
import { paymentMethods } from 'components/vm-deployment/constants/step7';
import { useSelector } from 'react-redux';
import ErrorMessage from 'components/error-message';
import PaymentMethod from 'components/common/payment-method';
import PaymentForm from 'components/Payment-form';
import { get } from 'lodash';
import { useMutation } from 'react-query';
import { PAYPAL_BILLING } from 'components/vm-deployment/queries/billing';
import hooks from 'hooks';
import Spinner from 'components/spinner';
import { userRights } from 'store/selectors/user-details';
import ConditionalFragment from 'components/conditional-fragment';
import priceCalculator from 'utils/price-calculator';
import { P } from 'components/reusable-css';
import CustomButton from 'components/common/custom-button';
import { Message } from 'components/alert/Message';
import { scrollToSection } from 'utils';
import { isChargeCard } from 'pages/member/add-fund/utils';

const VmPaymentForm = ({
  formikProp,
  handlePaypalBilling,
  paraAddfunds,
  addFundsBmCheck,
  addFunds,
  cardError,
  containerClass,
  amount,
  allButton = false,
  billingChargeProductLoad,
  handleOneTimeBilling,
  creditCardButtonDisabled,
  isButton = false,
  creditCardButtonLoading,
  id,
}) => {
  const { values, errors, touched, setFieldValue } = formikProp;
  const { credit_card_info: accountInfo } = hooks();
  const { t } = useTranslation();
  const credit_card_info = useSelector((state) => state.user.billing_info);
  const { mutate: PaypalBilling, isLoading: paypalLoad } = useMutation(PAYPAL_BILLING);
  const rights = useSelector(userRights);
  let isLoading = billingChargeProductLoad || paypalLoad;

  const handlePayPalBilling = async () => {
    const amountToCharge =
      get(values, 'options_prepaid.id') == 0
        ? get(values, 'options_prepaid.minimum_payment', 0)
        : amount * get(values, 'options_prepaid.id', 1);
    const amountToSend = +priceCalculator(amountToCharge, false);
    let body = {
      amount: amountToSend,
      currency: accountInfo?.account_currency,
    };
    await PaypalBilling(body, {
      onSuccess: (res) => {
        window.open(res?.paypal_url, '_blank');
      },
    });
  };
  return (
    <div className={containerClass}>
      <div className={classes.payment}>
        {paymentMethods(allButton)?.map((item) => (
          <ConditionalFragment key={item?.id} condition={!(rights.billingaddcc !== 1 && item.id === 'credit_debit')}>
            <PaymentMethod
              heading={t(item.lang)}
              data={item}
              setCheckedValue={(e) => setFieldValue('payment_method', e)}
              checkboxValue={values?.payment_method || ''}
              onClick={() => {
                setFieldValue('payment_method', values?.payment_method == item?.id ? '' : item?.id);
              }}
              style={
                errors['payment_method'] && touched['payment_method']
                  ? {
                      color: 'white',
                      background: '#d80000',
                    }
                  : {}
              }
            />
          </ConditionalFragment>
        ))}
      </div>
      <div className={classes.content} id="PAYMENT-CARD">
        {values?.payment_method === 'paypal' ? (
          <div className={classes.paypalDesc}>
            {!paraAddfunds ? (
              <P>
                <Trans i18nKey={'common/credit_card_module.string1'}>
                  <span
                    style={{ paddingRight: '0.3rem' }}
                    onClick={handlePaypalBilling || handlePayPalBilling}
                    className={isLoading ? `${classes.link_disabled}` : `${classes.link}`}
                  />
                  {isLoading && <Spinner color="orange" style={{ display: 'initial' }} classes="ml-2" />}
                </Trans>
              </P>
            ) : addFundsBmCheck ? (
              <P>
                <Trans i18nKey={'common/credit_card_module.string2'}>
                  <span
                    style={{ paddingRight: '0.3rem' }}
                    onClick={handlePaypalBilling || handlePayPalBilling}
                    className={isLoading ? `${classes.link_disabled}` : `${classes.link}`}
                  />
                  {isLoading && <Spinner color="orange" style={{ display: 'initial' }} classes="ml-2" />}
                </Trans>
              </P>
            ) : (
              <P>
                <Trans
                  i18nKey={'common/credit_card_module.string3'}
                  values={{
                    currency: accountInfo.account_currency,
                    currency_symbol: accountInfo.account_currency_symbol,
                  }}
                >
                  <span
                    style={{ paddingRight: '0.3rem' }}
                    onClick={handlePaypalBilling || handlePayPalBilling}
                    className={isLoading ? `${classes.link_disabled}` : `${classes.link}`}
                  />
                  {isLoading && <Spinner color="orange" style={{ display: 'initial' }} classes="ml-2" />}
                  <span style={{ textTransform: 'uppercase' }} />
                </Trans>
              </P>
            )}
            {!paraAddfunds && <P>{t('common/credit_card_module.string4')}</P>}
            <P>{t('common/credit_card_module.string5')}</P>
          </div>
        ) : values?.payment_method === 'credit_debit' &&
          credit_card_info?.billing_default_credit_card_exp &&
          credit_card_info?.billing_default_credit_card_last_four ? (
          <div className={classes.default_credit}>
            {' '}
            <div className={classes.heading}>
              <Heading>{t('common/credit_card_module.string6')}:</Heading>
            </div>
            <P className={classes.exp}>
              <Trans
                i18nKey="common/credit_card_module.string7"
                values={{
                  credit_card_info: credit_card_info?.billing_default_credit_card_last_four,
                  credit_card_info_expiry: credit_card_info?.billing_default_credit_card_exp,
                }}
              />
            </P>
            {isButton && (
              <CustomButton
                type="submit"
                buttonStyles={{
                  opacity: !formikProp.isValid && 0.3,
                  backgroundColor: !formikProp.isValid && 'gray',
                }}
                disabled={creditCardButtonDisabled}
                title={t('account/billing_info.string21')}
                isLoading={creditCardButtonLoading || isLoading}
                onClick={async () => {
                  const errors = await formikProp?.validateForm();
                  allButton ? handleOneTimeBilling('defaultcc') : formikProp?.handleSubmit();
                  if (errors.payment_method) {
                    Message.error(errors.payment_method);
                    return scrollToSection(id || 'Add_funds_vm');
                  }
                  if (!isChargeCard(credit_card_info)) {
                    if (cardError(errors, formikProp?.values, 'vm', formikProp.touched)) {
                      Message.error(t('string14'), t('string13'));
                      return scrollToSection(id || 'Add_funds_vm');
                    }
                  }
                }}
              />
            )}
          </div>
        ) : values?.payment_method === 'credit_debit' &&
          !credit_card_info?.billing_default_credit_card_exp &&
          !credit_card_info?.billing_default_credit_card_last_four ? (
          <ConditionalFragment condition={rights.billingaddcc === 1}>
            <PaymentForm addFunds={addFunds} formikProp={formikProp} />
            {isButton && (
              <CustomButton
                type="submit"
                buttonStyles={{
                  opacity: !formikProp.isValid && 0.3,
                  backgroundColor: !formikProp.isValid && 'gray',
                  marginTop: '2em',
                }}
                disabled={creditCardButtonDisabled}
                title={t(allButton ? 'account/billing_info.string32' : 'account/billing_info.string21')}
                isLoading={creditCardButtonLoading}
                onClick={async () => {
                  const errors = await formikProp?.validateForm();
                  formikProp?.handleSubmit();
                  setFieldValue('payment_method', 'credit_debit');
                  if (errors.payment_method) {
                    Message.error(errors.payment_method);
                    return scrollToSection(id || 'Add_funds_vm');
                  }
                  if (!isChargeCard(credit_card_info)) {
                    if (cardError(errors, formikProp?.values, 'vm', formikProp.touched)) {
                      Message.error(t('string14'), t('string13'));
                      return scrollToSection(id || 'Add_funds_vm');
                    }
                  }
                }}
              />
            )}
          </ConditionalFragment>
        ) : values?.payment_method != 'credit_debit' &&
          values?.payment_method != 'paypal' &&
          values?.payment_method != '' ? (
          <div className={classes.paypalDesc}>
            <P>
              <Trans i18nKey={'common/credit_card_module.string55'}>
                <span
                  style={{ paddingRight: '0.3rem' }}
                  onClick={() => handleOneTimeBilling() || handlePayPalBilling}
                  className={isLoading ? `${classes.link_disabled}` : `${classes.link}`}
                />
                {isLoading && <Spinner color="orange" style={{ display: 'initial' }} classes="ml-2" />}
              </Trans>
            </P>
            <P>{t('common/credit_card_module.string56')}</P>
          </div>
        ) : null}
      </div>
      <div
        style={{
          height:
            values?.payment_method === 'credit_debit' &&
            credit_card_info?.billing_default_credit_card_exp &&
            credit_card_info?.billing_default_credit_card_last_four
              ? 0
              : 37,
        }}
      >
        {!addFunds ? (
          <React.Fragment>
            {cardError(formikProp.errors, formikProp.values, formikProp.touched) &&
            get(formikProp, 'touched.options_prepaid') ? (
              <ErrorMessage text="string9" />
            ) : (
              get(formikProp, 'errors.payment_method') &&
              get(formikProp, 'touched.payment_method') && (
                <ErrorMessage text={get(formikProp, 'errors.payment_method')} />
              )
            )}{' '}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {get(formikProp, 'errors.payment_method') && get(formikProp, 'touched.payment_method') && (
              <ErrorMessage text={get(formikProp, 'errors.payment_method')} />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
export default VmPaymentForm;
