export const paymentMethods = (allButton) => {
  return allButton
    ? [
        { id: 'credit_debit', lang: 'common/credit_card_module.string8' },
        { id: 'paypal', lang: 'common/credit_card_module.string9' },
        { id: 'onetime', lang: 'common/credit_card_module.string57' },
      ]
    : [
        { id: 'credit_debit', lang: 'common/credit_card_module.string8' },
        { id: 'paypal', lang: 'common/credit_card_module.string9' },
      ];
};

export const months = [
  { value: 1, text: '01 January' },
  { value: 2, text: '02 February' },
  { value: 3, text: '03 March' },
  { value: 4, text: '04 April' },
  { value: 5, text: '05 May' },
  { value: 6, text: '06 June' },
  { value: 7, text: '07 July' },
  { value: 8, text: '08 August' },
  { value: 9, text: '09 September' },
  { value: 10, text: '10 October' },
  { value: 11, text: '11 November' },
  { value: 12, text: '12 December' },
];
const getYears = () => {
  var date = new Date(),
    years = [],
    year = date.getFullYear();

  for (var i = year; i < year + 10; i++) {
    years.push({ value: i, text: i });
  }
  return years;
};

export const creditCardSelectData = [
  { name: 'card_month', options: months },
  { name: 'card_year', options: getYears() },
];
export const billingData = [
  {
    name: 'billing_address',
    placeholder: 'common/credit_card_module.string33',
    dataTooltip: 'common/credit_card_module.string23',
  },
  {
    name: 'address_continue',
    placeholder: 'common/credit_card_module.string34',
    dataTooltip: 'common/credit_card_module.string23',
  },
];

export const FormLastData = [
  {
    type: 'text',
    label: 'Company / organization:',
    name: 'company',
    placeholder: 'common/credit_card_module.string35',
    dataTooltip: 'common/credit_card_module.string23',
    lang: 'common/credit_card_module.string13',
  },
  {
    type: 'text',
    label: 'Your phone number*:',
    name: 'phone',
    placeholder: 'common/credit_card_module.string36',
    dataTooltip: 'common/credit_card_module.string27',
    lang: 'common/credit_card_module.string14',
  },
  {
    type: 'text',
    label: 'Your card issuer*:',
    name: 'bank_name',
    placeholder: 'common/credit_card_module.string37',
    dataTooltip: 'common/credit_card_module.string28',
    lang: 'common/credit_card_module.string15',
  },
  {
    type: 'text',
    label: `Card issuer's phone number*:`,
    name: 'bank_phone_number',
    placeholder: 'common/credit_card_module.string38',
    lang: 'common/credit_card_module.string16',
    text_lang: 'common/credit_card_module.string17',
    text: `Your card issuer and their phone number is found on the back of your credit card. It is usually the card's bank's name. To protect against fraud, our team will manually verify the information with your card's issuer.
    This card will be stored for future payments and will be your default card. You may disable this under Account > Billing.`,
    addFundsText1: `Your card issuer and their phone number is found on the back of your credit card. It is usually the card's bank's name. To protect against fraud, our team will manually verify the information with your card's issuer.`,
    addFundsText1_lang: 'common/credit_card_module.string18',
    addFundsText2: `This card will be stored for future payments and will be your default card. You may disable this later on.`,
    addFundsText2_lang: 'common/credit_card_module.string19',
    addFundsText3: `A temporary $1 transaction will be placed to verify this card.`,
    addFundsText3_lang: 'common/credit_card_module.string20',
    dataTooltip: 'common/credit_card_module.string29',
  },
];
