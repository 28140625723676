/* eslint-disable no-unused-vars */
import React from 'react';
import { Icon } from 'semantic-ui-react';
import OSCard from 'components/bm-deployment/os-card';
import { Highlight, Notification, StepLabel } from 'components/vm-deployment/components';
import { useTranslation, Trans } from 'react-i18next';
import { NewVMStep3SC, StepDescription, NotificationContainer, NewVMStep3SContainer } from './NewBMStep3.style';
import FormikErrorMessage from 'components/formik-error-message';
import Heading from 'components/Heading/Heading';

const NewBMStep2 = ({ OsData, formikProp }) => {
  const { t } = useTranslation();
  return (
    <NewVMStep3SC id="ST2">
      <NewVMStep3SContainer>
        <Heading name={'bm/instance/create.step2_string2'}>
          {/* <StepLabel id="BM_ST2" stepCount={t('bm/instance/create.step2_string1')}>
          <Trans i18nKey="bm/instance/create.step2_string2" components={{ highlight: <Highlight /> }} />
        </StepLabel> */}
          <StepDescription />
          <OSCard OsData={OsData} formikProp={formikProp} />
          <NotificationContainer>
            <Notification>
              <Icon name="info circle" />
              {t('bm/instance/create.step2_string4')}
            </Notification>
          </NotificationContainer>
        </Heading>
      </NewVMStep3SContainer>
      <FormikErrorMessage value="image" formikProp={formikProp} />
    </NewVMStep3SC>
  );
};
export default NewBMStep2;
