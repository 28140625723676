export const parseManagerLevelValues = (backup_plan) => {
  switch (backup_plan) {
    case 'planlevel0':
      return 'plan0';
    case 'planlevel1':
      return 'plan1';
    case 'planlevel2':
      return 'plan2';
    default:
      break;
  }
};

export const filterBmValuesForCreation = (values, dry_run) => {
  const query = {
    region: 'las1-1',
    label: values.virtual_server_name,
    size: values.id,
    app: values.app,
    image: values.image,
    password: btoa(values.root_pass),
    managed_plan: parseManagerLevelValues(values.managed_plan),
    ipv4_ips: values.ipv4_ips,
    options_prepaid: values.options_prepaid.id,
    dryrun: '0',
  };
  if (dry_run) {
    query.dryrun = '1';
  }
  return query;
};
