import { get } from 'lodash';

export const isChecked = (checkbox, data, checked, field) => {
  if (typeof checkbox !== 'undefined') {
    if (checkbox === get(data, 'id') || checkbox === get(data, field)) {
      return true;
    }
  }
  if (checked) {
    return true;
  }
  return false;
};
