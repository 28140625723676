import styled from 'styled-components';
import { h2Styles, pStyles } from 'components/reusable-css';

export const CloudSC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.pageBackgroundColor};
  width: 100%;
  color: black;
  padding: 3em 2em 0 2em;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  @media only screen and (max-width: 768px) {
    padding: 1em 0.5em;
  }
`;

export const Paragraph = styled.div`
  padding: 1.5em 0 2em 0;
  p {
    margin-bottom: 1.3rem;
  }
`;

export const CloudService = styled.div`
  padding-top: 1.5rem;
`;

export const VsService = styled.div`
  padding-top: 1.5em;
  padding-bottom: 1em;
  .second-para {
    margin: ${({ isActive }) => (isActive ? '1.5em 0 2em 0' : '1.5em 0 4em 0')};
    .red {
      color: red;
    }
  }
`;

export const CloudList = styled.div`
  margin-top: -1.5rem;
`;

export const HighlightPara = styled.p`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;

export const CloudHeading = styled.h2`
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
  padding-left: 0.5em;
`;

export const BareService = styled.div`
  padding-top: 1em;
`;

export const Para = styled.div`
  padding-top: 1.5rem;
  p {
    margin-bottom: 1.3rem;
  }
`;

export const PaymentButton = styled.div`
  margin-top: -4rem;
`;

export const Price = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  min-height: 40px;
  width: 45%;
  padding: 0 1.5em;
  cursor: pointer !important;
  border-bottom: 2px solid transparent;
  font-family: open sans, verdana, arial, sans-serif !important;
  h6 {
    white-space: nowrap;
    margin: 0;
  }
  &:hover {
    background-color: #cfcfcf;
    transition: background-color 0.3s ease;
    border-radius: 15px;
    border-bottom: 2px solid orange;
  }
  @media only screen and (min-width: 2800px) {
    width: 15%;
  }
  @media only screen and (max-width: 2800px) {
    width: 20%;
  }
  @media only screen and (max-width: 2250px) {
    width: 25%;
  }
  @media only screen and (max-width: 1900px) {
    width: 30%;
  }
  @media only screen and (max-width: 1400px) {
    width: 40%;
  }
  @media only screen and (max-width: 1000px) {
    width: 45%;
  }
  @media only screen and (max-width: 850px) {
    width: 60%;
  }
  @media only screen and (max-width: 600px) {
    width: 70%;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const Prices = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  min-height: 40px;
  padding: 0 1.5em;
  cursor: pointer !important;
  border-bottom: 2px solid transparent;
  h6 {
    white-space: nowrap;
    @media only screen and (max-width: 960px) {
      white-space: wrap;
    }
    margin: 0;
  }
  &:hover {
    background-color: #cfcfcf;
    transition: background-color 0.3s ease;
    border-radius: 15px;
    border-bottom: 2px solid orange;
  }
`;

export const LastPara = styled.div`
  margin-bottom: 2em;
  i {
    color: ${(props) => props.theme.primaryDarkColor};
  }
`;
