import styled from 'styled-components';

export const MobileSettingPanel = styled.div`
  background: ${(props) => props.theme.panelBackground};
  min-height: 100%;
`;

export const Heading = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  &.floating {
    box-shadow: 2px 1px 18px -14px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 1px 18px -14px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 1px 18px -14px rgba(0, 0, 0, 0.75);
  }
  display: flex;
  justify-content: space-between;
  border-bottom: 0.7px solid ${(props) => props.theme.borders.lightGrey};
`;

export const CrossIcon = styled.div`
  i {
    color: black;
    cursor: pointer;
    margin-right: 10px;
  }
`;

export const AccordionContainer = styled.div`
  .MuiButtonBase-root {
    color: black;
  }
  .MuiAccordionDetails-root {
    display: block;
    padding: 8px 16px 16px;
  }
  .MuiAccordionSummary-root {
    height: 35px;
    min-height: 35px;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    height: 40px;
    min-height: 40px;
    background-color: ${(props) => props.theme.primaryDarkColor};
    .MuiTypography-root {
      color: white;
    }
    .MuiButtonBase-root {
      color: white;
    }
  }
`;
