import axios from 'axios';
import { BASE_URL } from 'constants/env';

export const CHECKOUT_VS = async (e) => {
  const res = await axios.post(BASE_URL + `/public/checkout/vs`, e);
  return res.data;
};

export const CHECKOUT_VS_ID = async (e) => {
  const { id, action, size, region, image, app, backup_plan, term, promocode } = e;
  const res = await axios.post(BASE_URL + `/public/checkout/vs/${id}`, {
    action,
    size,
    region,
    image,
    app,
    backup_plan,
    term,
    promocode,
  });
  return res.data;
};
