import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import * as Styles from './Styles';
import { Icon } from 'semantic-ui-react';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 10px',
    outline: 0,
    zIndex: 16000,
  },
  hidden: {
    visibility: 'hidden',
  },
});
const CustomModal = ({ children, open, onClose }) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableAutoFocus
    >
      <Fade in={open}>
        <Styles.ModalCross>
          <span className="modal_close">
            <Icon name="close" onClick={onClose} />
          </span>
          {children}
        </Styles.ModalCross>
      </Fade>
    </Modal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CustomModal;
