/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-31 16:08:56
 * @modify date 2021-01-31 16:08:56
 */

import React from 'react';
import PropTypes from 'prop-types';

import { BadgeSC } from './Badge.style';

export default function Badge({ number }) {
  return (
    <BadgeSC data-testid="badge" className="badge-number">
      {number}
    </BadgeSC>
  );
}
Badge.propTypes = {
  /** number which needs to be displayed inside of the badge */
  number: PropTypes.number,
};

Badge.defaultProps = {
  number: 0,
};
