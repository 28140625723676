/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { StepLabel, Highlight } from 'components/vm-deployment/components';
import { NewBMStep4SC, StepDescription, ServerSizeGrid, NewBMStep4SContainer } from './NewBMStep4.style';
import ManagedPlanCard from 'components/bm-deployment/managed-plan-card/ManagedPlanCard';
import FormikErrorMessage from 'components/formik-error-message';
import Heading from 'components/Heading/Heading';

const NewVMStep4 = ({ withPricing, selectedManagedPlan, onManagedPlanSelection, formikProp }) => {
  const { t } = useTranslation();
  return (
    <NewBMStep4SC>
      <NewBMStep4SContainer>
        <Heading name={'bm/instance/create.step4_string2'}>
          {/* <StepLabel id="ST4" stepCount={t('bm/instance/create.step4_string1')}>
          <Trans i18nKey="bm/instance/create.step4_string2" components={{ highlight: <Highlight /> }} />
        </StepLabel> */}
          <StepDescription>
            {t('bm/instance/create.step4_string3')}
            <br />
            <br />
            {t('bm/instance/create.step4_string4')}
          </StepDescription>
          <ServerSizeGrid>
            {withPricing?.map((plan) => (
              <ManagedPlanCard
                key={plan.id}
                data={plan}
                selected={plan.id === selectedManagedPlan}
                onClick={onManagedPlanSelection}
              />
            ))}
          </ServerSizeGrid>
        </Heading>
      </NewBMStep4SContainer>
      <FormikErrorMessage value="managed_plan" formikProp={formikProp} />
    </NewBMStep4SC>
  );
};
export default NewVMStep4;
