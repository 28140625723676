/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './input.css';
import { ErrorMessage } from 'formik';
// import ValidationError from './error';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles, TextField } from '@material-ui/core';
import PasswordTextField from 'components/password-input';
import { InputTooltip } from 'components/input-tooltip/input-tooltip';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UpperCaseWord } from 'assets/css/common-styles';

const Input = ({
  styling,
  placeholder,
  touched,
  error,
  name,
  value,
  onChange,
  className,
  isFormIk,
  handleClickShowPassword,
  PasswordInput,
  inputProps,
  loading,
  EndAdornment,
  onEnter,
  validateBorderColor,
  validateBorderCondition,
  validateLoading,
  dataTooltip,
  tooltipStyles,
  onBlur,
  onFocus,
  largerFont,
  borderWidth,
  borderRadius,
  borderBottomWidth,
  isValidate = true,
  errorStyles = {},
  placeholderClass = false,
  disabled = false,
  defaultDropdown = 'on',
  ...rest
}) => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  // Here validate border condition is added because of user management page where we are validating the user exist in the database or not while adding the subuser.
  const useStyles = makeStyles({
    root: {
      ...(error && touched
        ? {
            border: `${borderWidth || '2px'} solid #d8d8d8`,
            borderRadius: borderRadius || '8px',
            borderBottom: `${borderBottomWidth || '2.9px'} solid #d80000`,
            '&.Mui-focused': {
              border: '2px solid #d80000',
            },
          }
        : {
            border: `${borderWidth || '2px'} solid #d8d8d8`,
            borderRadius: borderRadius || '8px',
            borderBottom:
              validateBorderColor && value && !validateLoading
                ? !validateBorderCondition
                  ? `${borderBottomWidth || '2.9px'} solid green`
                  : `${borderBottomWidth || '2.9px'} solid #d80000`
                : `${borderBottomWidth || '2.9px'} solid orange`,
            '&.Mui-focused': {
              border:
                validateBorderColor && value && !validateLoading
                  ? !validateBorderCondition
                    ? `${borderWidth || '2px'} solid green`
                    : `${borderWidth || '2px'} solid #d80000`
                  : ``,
            },
          }),
      '&.MuiFilledInput-root': {
        fontSize: largerFont ? 22 : 15,
      },
      ...styling,
    },
    underline: {
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  });
  const onKeyEnter = (e) => {
    if (e.key === 'Enter') {
      if (onEnter) onEnter(e);
    }
  };
  const [show, setShow] = useState(false);

  const showTooltip = (bool) => {
    setShow(bool);
  };

  useEffect(() => {
    if (name == 'domain') document.getElementById('domain').focus();
  }, []);

  const classes = useStyles();
  return (
    <>
      <div
        style={{ display: 'grid', marginBottom: 10, ...styling }}
        className={rest.disabledField ? '' : `${'custom_input ' + className}`}
      >
        {isValidate && (
          <div
            style={{
              height: '1.8rem',
              maxWidth: styling?.width,
              whiteSpace: 'nowrap',
              ...errorStyles,
            }}
          >
            {isFormIk ? (
              <ErrorMessage
                name={name}
                render={(msg) => (
                  <span className="error_messageSty">
                    <Trans
                      i18nKey={msg}
                      values={{
                        currency: userAccount.account_currency,
                        currency_symbol: userAccount.account_currency_symbol,
                      }}
                      components={{ uppercase: <UpperCaseWord /> }}
                    />
                  </span>
                )}
              />
            ) : (
              <>
                {error && (
                  <span className="error_messageSty">
                    <Trans
                      i18nKey={error || 'Wrong format'}
                      values={{
                        currency: userAccount.account_currency,
                        currency_symbol: userAccount.account_currency_symbol,
                      }}
                      components={{ uppercase: <UpperCaseWord /> }}
                    />
                  </span>
                )}
              </>
            )}
          </div>
        )}
        <div
          style={{ position: 'relative' }}
          className={`custom-text-field ${placeholder && 'no_placeholder'} ${placeholderClass && 'placeholder_class'}`}
        >
          {PasswordInput ? (
            <PasswordTextField
              disabled={rest.disabledField}
              classes={classes}
              handleClickShowPassword={handleClickShowPassword}
              {...rest}
              onKeyPress={onKeyEnter}
              label={placeholder}
              name={name}
              value={value}
              onChange={onChange}
              onFocus={() => {
                showTooltip(true);
                if (onFocus) {
                  onFocus();
                }
              }}
              onBlur={(e) => {
                if (onBlur) onBlur(e);
                showTooltip(false);
              }}
            />
          ) : (
            <TextField
              autoComplete={defaultDropdown}
              disabled={disabled || rest.disabledField}
              InputProps={{
                classes,
              }}
              inputProps={{
                ...inputProps,
                autocomplete: rest.autocomplete,
              }}
              label={placeholder}
              variant="filled"
              id={name}
              name={name}
              onKeyPress={onKeyEnter}
              {...rest}
              value={(value + '')?.trim().length === 0 ? '' : t(value)}
              onChange={onChange}
              onFocus={() => {
                showTooltip(true);
                if (onFocus) {
                  onFocus();
                }
              }}
              onBlur={(e) => {
                if (onBlur) onBlur(e);
                showTooltip(false);
              }}
            />
          )}
          {loading ? (
            <CircularProgress size={20} classes={{ root: 'loading' }} />
          ) : (
            <div className={`end-adornment ${error && 'opacity-3 '}`}>{EndAdornment()}</div>
          )}
          {show && <InputTooltip tooltipStyles={tooltipStyles} dataTooltip={dataTooltip} />}
        </div>
      </div>
    </>
  );
};

export default Input;

Input.defaultProps = {
  isFormIk: true,
  inputProps: {},
  loading: false,
  EndAdornment: () => {},
};

Input.propTypes = {
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  touched: PropTypes.any,
  placeholder: PropTypes.string,
  styling: PropTypes.object,
  className: PropTypes.string,
  isFormIk: PropTypes.bool,
  handleClickShowPassword: PropTypes.func,
  PasswordInput: PropTypes.bool,
  inputProps: PropTypes.object,
  loading: PropTypes.bool,
  EndAdornment: PropTypes.any,
  tooltipStyles: PropTypes.object,
  errorStyles: PropTypes.object,
};
