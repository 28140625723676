import React from 'react';
import CustomButton from 'components/common/custom-button';
import * as Styles from 'pages/member/dns/DNS.style';
import { H5 } from 'components/reusable-css';
import CustomModal from 'components/modal/Modal';
import Input from 'components/input';
import { Form, Formik } from 'formik';
import { Label } from 'assets/css/common-styles';
import { initialValues, validationSchema } from './util';
import Select from 'components/select';
import { useTranslation } from 'react-i18next';

const AddDomainModal = ({ handleDomain, loading, toggle, open, ipData }) => {
  const { t } = useTranslation();
  return (
    <CustomModal width="sm" open={open} onClose={toggle}>
      <Styles.AddDomainModalContainer>
        <Styles.AddDomainModalContent>
          <H5>{t('dns/instance.string15')}</H5>
          <Formik
            initialValues={initialValues(ipData?.ip_numbers[0]?.id)}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => handleDomain(values, resetForm)}
          >
            {({ values, handleChange, handleBlur, errors, touched }) => (
              <Form>
                <Styles.Flex>
                  <Label>{t('dns/instance.string16')}</Label>
                  <Input
                    styling={{ minWidth: '330px', width: '100%' }}
                    type={'text'}
                    name={'domain'}
                    value={values['domain']}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors['domain']}
                    touched={touched['domain']}
                    placeholderClass
                    defaultDropdown={'off'}
                  />
                </Styles.Flex>
                <Styles.Flex>
                  <Label>{t('dns/instance.string17')}</Label>
                  <Select
                    dns
                    styling={{ minWidth: '330px', width: '100%' }}
                    options={ipData?.ip_numbers}
                    name={'ip_number'}
                    value={values['ip_number']}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors['ip_number']}
                    touched={touched['ip_number']}
                  />
                </Styles.Flex>
                <Styles.Buttons>
                  <>
                    <CustomButton
                      buttonStyles={{ width: '100%' }}
                      type="submit"
                      isLoading={loading}
                      title={t('dns/instance.string18')}
                    />
                  </>
                </Styles.Buttons>
              </Form>
            )}
          </Formik>
        </Styles.AddDomainModalContent>
      </Styles.AddDomainModalContainer>
    </CustomModal>
  );
};
export default AddDomainModal;
