import DialogBox from 'components/dialog/Dialog';
import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import { H5, H6, P } from 'components/reusable-css';
import Loading from 'components/common/loading/Loading';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/common/custom-button/custom-button';
import CopyToClipboard from 'react-copy-to-clipboard';

const PasswordRetrieve = ({ isOpen, handleClose, isLoading, isRefetching, passwordData }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  var text = '';
  for (let index = 0; index < passwordData?.vs_passwords.length; index++) {
    text = text.concat(
      `${passwordData?.vs_passwords[index].ip_number} : ${passwordData?.vs_passwords[index].password}\n`,
    );
  }

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1 * 1000);
  };

  return (
    <DialogBox width="lg" open={isOpen} onClose={() => handleClose(false)}>
      <Styles.PasswordRetriveCards>
        <div className="d-flex align-items-center justify-content-end">
          {isLoading == false && isRefetching == false && (
            <CopyToClipboard text={text} onCopy={() => handleCopy()}>
              <CustomButton
                disabled={copied}
                title={'vs/instance.string204'}
                buttonStyles={{ padding: '3px 5px', marginRight: '1em' }}
              />
            </CopyToClipboard>
          )}
          <Icon name="close" onClick={() => handleClose(false)} />
        </div>

        <Styles.TagContent>
          <H5>{t('vs/instance.string202')}</H5>
          <Styles.GreyBorder />
          {isLoading || isRefetching ? (
            <Loading />
          ) : (
            <Styles.PasswordRetriveContent>
              {passwordData?.vs_passwords?.map((val) => (
                <div className="list" key={val.id}>
                  <H6 className="ip">{val.ip_number} :</H6>
                  <H6 className="password">{val.password}</H6>
                </div>
              ))}
              <P className="mt-4">{t('vs/instance.string203')}</P>
            </Styles.PasswordRetriveContent>
          )}
        </Styles.TagContent>
      </Styles.PasswordRetriveCards>
    </DialogBox>
  );
};

export default PasswordRetrieve;
