import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const PUT_TICKET = async (e) => {
  const { ticket_id, ...rest } = e;
  const res = await axios.put(BASE_URL + `/tickets/${ticket_id}`, rest);
  return res.data;
};

export const POST_TICKET = async (e) => {
  const res = await axios.post(BASE_URL + `/tickets`, e);
  return res.data;
};

export const ADD_TICKET_REPLY = async (e) => {
  const { ticket_id, ...rest } = e;
  const res = await axios.post(BASE_URL + `/tickets/${ticket_id}`, rest);
  return res.data;
};

export const CLOSE_TICKET = async (e) => {
  const res = await axios.post(BASE_URL + `/tickets/actions`, e);
  return res.data;
};

export const ADD_EASYVIEW_TICKET_REPLY = async (e) => {
  const { ...rest } = e;
  const res = await axios.post(BASE_URL + `/tickets/easyview/actions`, rest);
  return res.data;
};
