import axios from 'axios';
import { BASE_URL } from 'constants/env';

export const ADD_DOMAIN = async (e) => {
  const res = await axios.post(BASE_URL + `/dns/instance/create`, { ...e });
  return res.data;
};

export const ADD_RECORDS = async (e) => {
  let { domain, type, name, value, priority, isTXT } = e;
  let replaceName = isTXT && name.replace(/[\t\n\r]+/g, '');
  let replaceValue = isTXT && value.replace(/[\t\n\r]+/g, '');

  let dnsName = isTXT ? btoa(replaceName) : name;
  let dnsValue = isTXT ? btoa(replaceValue) : value;

  const res = await axios.post(BASE_URL + `/dns/instance/${domain}`, {
    type: type.toLowerCase(),
    name: dnsName,
    value: dnsValue,
    priority,
  });
  return res.data;
};

export const DNS_ACTIONS = async (e) => {
  let { domain, action } = e;
  const res = await axios.post(BASE_URL + `/dns/instance/${domain}/actions`, { action });
  return res.data;
};

export const DELETE_DOMAIN_DNS = async (e) => {
  let { domain, dnsID } = e;
  const res = await axios.delete(BASE_URL + `/dns/instance/${domain}/${dnsID}`);
  return res.data;
};

export const DELETE_DOMAIN = async (e) => {
  const res = await axios.delete(BASE_URL + `/dns/instance/${e.domain}`);
  return res.data;
};
