import { totalPrice } from 'components/vm-deployment/util';

const UseWh = ({ formikProp }) => {
  const { values } = formikProp;
  const monthlyPrice = totalPrice({ ...values.prices, yearly: 0 });
  const yearlyPrice = totalPrice(values.prices.yearly);
  const withoutDomainPriceMonthly = totalPrice({ ...values.prices, yearly: 0, domain: 0 });
  const withoutDomainPriceYearly = totalPrice({ ...values.prices.yearly, domain: 0 });
  return { monthlyPrice, yearlyPrice, withoutDomainPriceMonthly, withoutDomainPriceYearly };
};

export default UseWh;
