import { h2Styles } from 'components/reusable-css';
import styled from 'styled-components';
import { deviceBreakpoints, variables } from 'variables';

export const NewVMStep3SC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.pageBackgroundColor};
  width: 100%;
  padding: ${variables.pageContentPadding}px;
  padding-right: 0;
  padding-bottom: 0;
  // padding-top: 80px;
  padding-top: 60px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    padding-top: 0;
  }
`;

export const Heading = styled.h2`
  padding-right: 15px;
  margin-top: 5px;
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
  font-weight: 100;
`;

export const NotificationContainer = styled.div`
  display: flex;
  margin: 50px 0;
  padding-right: 15px;
`;

export const GreyOut = styled.div`
  opacity: ${(props) => (props.isGrey ? 0.2 : 1)};
`;
