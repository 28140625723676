import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const PAYPAL_BILLING = async (e) => {
  const res = await axios.post(BASE_URL + `/billing/paypal_link`, e);
  return res.data;
};

export const SUBMIT_VM = async (e) => {
  const res = await axios.post(BASE_URL + `/vs/instance/create`, e);
  return res.data;
};

export const SUBMIT_SLIDER_VM = async (e) => {
  const res = await axios.post(BASE_URL + `/vs/instance/custom_create`, e);
  return res.data;
};

export const CHARGE_SAVED_CARD = async (e) => {
  const res = await axios.post(BASE_URL + `/billing/charge_default_cc`, e);
  return res.data;
};

export const SAVE_CHARGE_CARD = async (e) => {
  const res = await axios.post(BASE_URL + `/billing/cc/save`, e);
  return res.data;
};

export const BILLING_CHARGE_PRODUCT = async (e) => {
  const res = await axios.post(BASE_URL + `/billing/charge_product`, e);
  return res.data;
};
