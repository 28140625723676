import React from 'react';
import CustomButton from 'components/common/custom-button/custom-button';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import * as Styles from './IssuesList.styles';
import { Trans, useTranslation } from 'react-i18next';
import { Highlight } from 'components/vm-deployment/components';
import _get from 'lodash/get';
import { navLinks } from 'utils/nav-links-util';
import { H5, P } from 'components/reusable-css';
import { Heading } from 'assets/css/common-styles';

const AllIssue = ({ data, ticketsStatus, ticketsDepartment, style }) => {
  const { tickets, count_open, count_closed } = data;
  const { t } = useTranslation();
  return (
    <Styles.AllIssueSC style={style}>
      <div>
        <Styles.NewMessage>
          <div>
            <Heading>
              <Trans i18nKey={'tickets.string1'} components={{ highlight: <Highlight /> }} />
            </Heading>
            {tickets?.length == 0 ? (
              <Styles.StepDescription>{t('tickets.string2')}</Styles.StepDescription>
            ) : (
              <Styles.FirstRow>
                <Styles.OpenClosedIcon>
                  <div className="open">
                    <i className="fa fa-folder-open-o" aria-hidden="true" />
                    <Styles.P>
                      <Trans i18nKey={'tickets.string3'} values={{ count: count_open }} />
                    </Styles.P>
                  </div>
                  <div className="closed">
                    <i className="fa fa-folder-o" aria-hidden="true" />
                    <Styles.P>
                      <Trans i18nKey={'tickets.string4'} values={{ count: count_closed }} />
                    </Styles.P>
                  </div>
                </Styles.OpenClosedIcon>
              </Styles.FirstRow>
            )}
          </div>
          <Link to={navLinks.contact_our_staff.link}>
            <CustomButton type="submit" title={t('tickets.string5')} />
          </Link>
        </Styles.NewMessage>

        {tickets?.length != 0 && (
          <Styles.AllRow>
            {tickets?.map((val) => {
              let subStatus = ticketsStatus?.ticket_substatus_definitions?.find((status) => status.id == val.substatus);
              let department = ticketsDepartment?.ticket_department_definitions?.find(
                (dep) => dep.id == val.department,
              );
              return (
                <Link key={val.id} to={`/tickets/${val.id}`} style={{ textDecoration: 'none' }}>
                  <Styles.Issue>
                    <Styles.Content>
                      <Styles.Flex>
                        <Styles.Department>
                          <Styles.H5>{t(`tickets.${department?.api_language_string}`)}</Styles.H5>
                        </Styles.Department>
                        <Styles.Border />
                        <Styles.Left>
                          <H5 className="issueName">
                            {_get(val, 'properties', []).some((i) => i?.key === 'subject')
                              ? atob(_get(val, 'properties', []).find((i) => i?.key === 'subject')?.value)
                              : ''}
                          </H5>
                          <P>
                            <Trans
                              i18nKey={'tickets.string6'}
                              values={{
                                ticket_id: val.id,
                                date: val.date_local_format_mdy,
                                time: val.time_opened_ago_en,
                              }}
                            />
                          </P>
                        </Styles.Left>
                      </Styles.Flex>

                      <Styles.Right>
                        <Styles.IssueName
                          style={{ background: `#${subStatus?.color}`, color: `#${subStatus?.font_color}` }}
                        >
                          <P>{t(`tickets/create.${subStatus?.api_language_string_title}`)}</P>
                        </Styles.IssueName>
                        <div className="righticon">
                          <Icon name="comment outline" />
                          <P>{val?.message_count}</P>
                        </div>
                      </Styles.Right>
                    </Styles.Content>
                  </Styles.Issue>
                </Link>
              );
            })}
          </Styles.AllRow>
        )}
      </div>
    </Styles.AllIssueSC>
  );
};

export default AllIssue;
