/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import * as Styles from '../tickets-sign-in-pin/SignInWithPin.styles';
import LogoImage from 'assets/images/serverpoint.svg';
import PinInput from 'react-pin-input';
import { useMediaQuery } from '@material-ui/core';
import CustomButton from 'components/common/custom-button/custom-button';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { LOG_IN, TWOFALOG_IN } from 'components/vm-deployment/queries';
import { CustomHook } from 'utils/custom-hook';
import { reactQueryConfig } from 'constants/react-query-config';
import axios from 'axios';
import { navLinks } from 'utils/nav-links-util';
import Alert from 'components/alert';
import { P } from 'components/reusable-css';

const SignInwithPin = ({ queryClient }) => {
  const pinCodeRef = useRef(null);
  const mobile = useMediaQuery('(max-width:500px)');
  const history = useHistory();
  const { logout } = CustomHook();
  const { username, password, backup_number_available } = history.location.state || {};
  const [trigger, setTrigger] = useState(false);
  const [severity, setSeverity] = useState('');
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState('');
  const [loginResult, setLoginResult] = useState('Thank you! Please go ahead and log in below.');

  const { isLoading: loginIsLoading } = useQuery(['LOG_IN', { userName: username, password, code }], TWOFALOG_IN, {
    enabled: trigger,
    ...reactQueryConfig,
    onSuccess: async (res) => {
      setOpen(false);
      if (res?.status == 'pass_reset_required') {
        history.replace(navLinks['expired-password'].link);
      } else if (res.status == 'two_factor_sms_sent') {
        setOpen(true);
        setSeverity('success');
        setLoginResult('A new code has been sent');
        setTrigger(false);
        setCode('');
        pinCodeRef.current.clear();
        pinCodeRef.current.focus();
      } else {
        const query = `?sc_username=${res.sc_username}&sc_password=${res.sc_password}`;
        if (res.redirect_type === 'tab') {
          return window.open(`${res.redirect_url}${query}`);
        }
        if (res.redirect_type === 'redirect') {
          await logout('', false);
          return window.location.replace(`${res.redirect_url}${query}`);
        }
        if (queryClient) queryClient.clear();
        axios.defaults.headers.common.Authorization = `Bearer ${res.token}`;
        localStorage.setItem(
          'userName',
          JSON.stringify({
            userName: res.username,
            email: username,
            token: res.token,
          }),
        );
        setTrigger(false);
        history.replace(navLinks.virtual_machines_home.link);
      }
    },
    onError: () => {
      pinCodeRef.current.clear();
      setCode('');
      setSeverity('error');
      setLoginResult('Code mismatch! Please try again.');
      setOpen(true);
      setTrigger(false);
    },
  });

  const handleSubmit = () => setTrigger(true);
  const handleLinkClick = () => {
    setCode('999999');
    setTrigger(true);
  };
  if (!username) {
    history.replace('/');
  }

  let disabled = code.split('').length != 6 || loginIsLoading || trigger;
  return (
    <Styles.SignInSC>
      <Styles.SignInContainer>
        <Styles.Logo>
          <img src={LogoImage} alt="serverpoint" width="100%" />
        </Styles.Logo>
        <Styles.LoginContainer>
          <Alert
            severity={severity == 'error' ? 'error' : 'info'}
            text={loginResult}
            open={open}
            handleClose={() => setOpen(false)}
          />
          <Styles.SignInBox>
            <Styles.PinSC>
              <PinInput
                ref={pinCodeRef}
                length={6}
                initialValue=""
                type="numeric"
                inputMode="number"
                style={{ padding: '10px 0px' }}
                inputStyle={{
                  width: mobile ? '38px' : '50px',
                  height: mobile ? '50px' : '70px',
                  border: '2px solid #b3b3b3b3',
                  borderBottom: '3px solid #FF7300',
                  background: 'white',
                  margin: mobile ? '0 5px' : '0 10px',
                  fontSize: mobile ? '22px' : '30px',
                }}
                onComplete={(value) => {
                  setCode(value);
                  handleSubmit();
                }}
                onChange={(value) => setCode(value)}
                autoSelect
                focus
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </Styles.PinSC>
            <Styles.Flex>
              <Styles.Row>
                <Styles.LinkSC to={'/'} margin="15px">
                  Try again
                </Styles.LinkSC>
              </Styles.Row>
              <Styles.LogInButton>
                <CustomButton
                  type="submit"
                  title="Log in"
                  disabled={disabled}
                  onClick={handleSubmit}
                  isLoading={loginIsLoading || trigger}
                  buttonStyles={{ width: '100%' }}
                />
              </Styles.LogInButton>
            </Styles.Flex>
            {backup_number_available == 1 && (
              <Styles.Para>
                <P>
                  You have a backup phone number configured. To send an SMS code to that number,{' '}
                  <Styles.Linked loading={trigger} onClick={handleLinkClick}>
                    please click here
                  </Styles.Linked>
                  .
                </P>
              </Styles.Para>
            )}
          </Styles.SignInBox>
        </Styles.LoginContainer>
      </Styles.SignInContainer>
    </Styles.SignInSC>
  );
};

export default SignInwithPin;
