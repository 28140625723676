/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { Grid } from '@material-ui/core';
import * as Styles from './ServerSizeSlider.style';
import {
  sliderValue,
  sliderStyles,
  sliderItemText,
  RAM,
  DISK,
  CORE,
  CPU_SHARE,
  CPU_SHARE_DISCOUNT,
} from 'components/vm-deployment/constants/step1';
import _get from 'lodash/get';
import { sliderItemQuantity } from './util';
import { useDispatch } from 'react-redux';
import { updatePricing } from 'store/actions/vm-deployment';
import { Message } from 'components/alert/Message';
import { useTranslation } from 'react-i18next';
import hooks from 'hooks';
import get from 'lodash/get';
import { getSliderDataValues } from 'components/manage-vm/utils';

const Sliders = withStyles({
  ...sliderStyles,
})(Slider);

const CustomizedSlider = ({
  formikProp,
  sliderData,
  sliderSuccess,
  sliderSizeData,
  OsData,
  vsTemplate,
  isPriceFormik,
  formIkPrice,
  defaultData,
}) => {
  const { t } = useTranslation();
  const { isSliderInterface } = hooks();
  const { values, setFieldValue } = formikProp;

  const os = _get(OsData, 'vs_os', []).find((item) => item.id === values.image);
  const template = _get(vsTemplate, 'vs_saved_templates', []).find((item) => item.id == values.template);
  const dispatch = useDispatch();
  const sliderPrices = _get(sliderData, 'prices_vs_slider_units', []);
  const diskMin = +template?.size_in_gb || os?.min_disk_gb || sliderValue[DISK].min;
  const ramMin = os?.min_ram_mb || sliderValue[RAM].min;
  const ramPrice = sliderPrices.find((slider) => slider.id == RAM);
  const diskPrice = sliderPrices.find((slider) => slider.id == DISK);
  const cpuPrice = sliderPrices.find((slider) => slider.id == CORE);
  const updateValues = (first, second) => {
    if (isPriceFormik) {
      setFieldValue(formIkPrice ? `${formIkPrice}.${second}` : second, +first);
    } else {
      dispatch(updatePricing(+first, second));
    }
  };
  const minValuesOfSlider = (slider) => {
    if (slider.id === DISK && +template?.size_in_gb) {
      return +template.size_in_gb;
    }
    if (slider.id === DISK && os?.min_disk_gb) {
      return +os?.min_disk_gb;
    }
    if (slider.id === RAM && os?.min_ram_mb) {
      return +os?.min_ram_mb;
    }
    return sliderValue[slider.id]?.min;
  };
  const updateSlider = (newValue, id, price) => {
    const cpuShare = sliderPrices.find((item) => item.id == CPU_SHARE);
    if (defaultData && id === DISK) {
      const defaultValues = getSliderDataValues(defaultData);
      if (newValue < defaultValues[DISK]) return;
    }
    if (newValue <= sliderValue[id]?.max) {
      setFieldValue(`sliders[${id}]`, newValue);
    } else {
      setFieldValue(`sliders[${id}]`, sliderValue[id]?.max);
      newValue = sliderValue[id]?.max;
    }
    const min = minValuesOfSlider({ id });
    if (newValue <= min) {
      setFieldValue(`sliders[${id}]`, min);
      newValue = min;
    }
    if (id == CORE) {
      updateValues(Number(price.price_discounted_monthly_usd * newValue), id);
      updateValues(Number(newValue) * cpuShare.price_discounted_monthly_usd * 5, CPU_SHARE);
      updateValues(Number(price.price_monthly_usd * newValue), `${id}_no_discount`);
      updateValues(Number(newValue) * cpuShare.price_monthly_usd * 5, CPU_SHARE_DISCOUNT);
    } else {
      updateValues(Number(newValue * price.price_discounted_monthly_usd), id);
      updateValues(Number(price.price_monthly_usd * newValue), `${id}_no_discount`);
    }
  };

  const handleSliderChange = (event, newValue, id, price) => {
    if (ramMin && id == RAM) {
      if (+newValue >= +ramMin) {
        updateSlider(newValue, id, price);
      } else {
        updateSlider(ramMin, id, price);
      }
    } else {
      updateSlider(newValue, id, price);
    }
    if (diskMin && id === DISK) {
      if (+newValue >= +diskMin) {
        updateSlider(newValue, id, price);
      } else {
        updateSlider(diskMin, id, price);
      }
    } else {
      updateSlider(newValue, id, price);
    }
  };
  useEffect(() => {
    if (isSliderInterface && defaultData) {
      const defaultValues = getSliderDataValues(defaultData);
      updateSlider(get(defaultValues, DISK, 0), DISK, diskPrice);
      updateSlider(get(defaultValues, RAM, 0), RAM, ramPrice);
      updateSlider(get(defaultValues, CORE, 0), CORE, cpuPrice);
    }
  }, [defaultData]);
  useEffect(() => {
    if (os) {
      if (+os.min_disk_gb > +values.sliders[DISK]) {
        setFieldValue(`sliders${[DISK]}`, os.min_disk_gb);
        handleSliderChange(null, os.min_disk_gb, DISK, diskPrice);
        Message.error(t('alert1'), t('alert2'));
      }
      if (+os.min_ram_mb > +values.sliders[RAM]) {
        setFieldValue(`sliders${[DISK]}`, os.min_ram_mb);
        handleSliderChange(null, os.min_ram_mb, RAM, ramPrice);
        Message.error(t('alert22'), t('alert23'));
      }
    }
  }, [values.image]);

  useEffect(() => {
    if (template) {
      if (+values.sliders[DISK] < +template.size_in_gb) {
        setFieldValue(`sliders${[DISK]}`, +template.size_in_gb);
        handleSliderChange(null, +template.size_in_gb, DISK, diskPrice);
        Message.error(t('alert1'), t('alert2'));
      }
    }
  }, [values.template]);

  useEffect(() => {
    if (sliderSizeData && !defaultData) {
      const popularSize = sliderSizeData.find((item) => item.mostpopular == 1);
      handleButton(popularSize);
    }
  }, [sliderSuccess, sliderSizeData]);
  const handleButton = (feature) => {
    setFieldValue('slider_feature', feature?.id);

    handleSliderChange(null, feature[RAM], RAM, ramPrice);
    handleSliderChange(null, feature[DISK], DISK, diskPrice);
    handleSliderChange(null, feature[CORE], CORE, cpuPrice);
  };

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        {sliderSizeData.map((feature) => (
          <React.Fragment key={feature.id}>
            <Grid item style={{ position: 'relative' }}>
              {feature.mostpopular == 1 && (
                <Styles.PopularButton>
                  <Styles.PopularSizeText>{t('vs/instance/custom_create.step1_string2')}</Styles.PopularSizeText>
                </Styles.PopularButton>
              )}
              <Styles.Buttons selected={feature.id === values.slider_feature} onClick={() => handleButton(feature)}>
                {' '}
                {feature.id.toUpperCase()}{' '}
              </Styles.Buttons>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Styles.SliderContainer>
        {sliderPrices.slice(0, 3).map((slider) => (
          <Styles.SliderCard key={slider.id}>
            <Styles.SliderWidth>
              <Sliders
                value={values.sliders[slider.id]}
                max={sliderValue[slider.id]?.max}
                onChange={(e, n) => handleSliderChange(e, n, slider.id, slider)}
                aria-labelledby="input-slider"
                min={minValuesOfSlider(slider)}
                step={sliderValue[slider.id]?.step || 1}
              />
            </Styles.SliderWidth>
            <Styles.Input
              value={values.sliders[slider.id]}
              type="number"
              aria-labelledby="input-slider"
              max={sliderValue[slider.id]?.max}
              min={minValuesOfSlider(slider)}
              step={sliderValue[slider.id]?.step || 1}
              onChange={(e) => setFieldValue(`sliders[${slider.id}]`, e.target.value)}
              onBlur={(e) => handleSliderChange(e, e.target.value, slider.id, slider)}
            />
            <Styles.Description>
              <span>{sliderItemQuantity(slider.id, values.sliders[slider.id])} </span>
              <strong>{t(`vs/instance/custom_create.${sliderItemText[slider.id]}`)}</strong>
            </Styles.Description>
          </Styles.SliderCard>
        ))}
      </Styles.SliderContainer>
    </React.Fragment>
  );
};

export default CustomizedSlider;
