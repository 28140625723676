import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';

const DialogBox = ({ children, open, onClose, width }) => {
  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: '15px' },
      }}
      style={{zIndex: 16000}}
      maxWidth={width}
      open={open}
      onClose={onClose}
    >
      {children}
    </Dialog>
  );
};
export default DialogBox;
DialogBox.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  width: PropTypes.any,
};
