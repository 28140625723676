import React from 'react';
import { NewVMStep1SC } from './NewVSStep1.style';
import WorldwideCloudRegion from '../component/worldwide-cloud-region/WorldwideCloudRegion';
import { StepDescription } from 'assets/css/common-styles';
import Heading from 'components/Heading/Heading';

const NewVMStep2 = ({ regionsData, formikProp, sizeData }) => {
  return (
    <NewVMStep1SC id="ST1">
      <Heading name="public/checkout/vs.step1_string2">
        <StepDescription />
        <WorldwideCloudRegion regionsData={regionsData} formikProp={formikProp} sizeData={sizeData} />
      </Heading>
    </NewVMStep1SC>
  );
};

export default NewVMStep2;
