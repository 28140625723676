import React from 'react';
import * as Styles from 'pages/member/wh/WH.style';
import { StepLabel, Highlight } from 'components/vm-deployment/components';
import { useTranslation, Trans } from 'react-i18next';
import Checkbox from 'components/common/radio-button';
import { Grid } from '@material-ui/core';
import { Message } from 'components/alert/Message';
import { getRegionImage } from 'components/vm-deployment/worldwide-cloud-region/util';
import { get } from 'lodash';
import FormikErrorMessage from 'components/formik-error-message';

const WHStep4 = ({ whRegionData, formikProp }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = formikProp;

  const showMessage = () => {
    Message.error(
      `This zone is temporarily unavailable.Please contact our team to know
        when it will be available again.`,
      'Unavailable',
    );
  };
  const setIsChecked = (value) => {
    setFieldValue('region', value);
  };

  return (
    <Styles.WHStep3SC>
      <StepLabel id="ST4" stepCount={t('Step 4')}>
        <Trans i18nKey="wh_step4_heading" components={{ highlight: <Highlight spaceLeft /> }} />
      </StepLabel>
      <Styles.StepDescription>{t('wh_step4_paragraph')}</Styles.StepDescription>
      <Styles.InlineFlex>
        <Grid container spacing={0}>
          {get(whRegionData, 'wh_regions', []).map((region) => (
            <Grid item key={region.id} xs={12} sm={6} md={4} lg={4} xl={3}>
              {region.new_deployments_allowed == 0 ? (
                <Styles.Card0 onClick={showMessage}>
                  <Checkbox data={region} setCheckedValue={showMessage} />
                  <img
                    style={{ border: '0.5px solid #C5C5C5' }}
                    src={getRegionImage(region?.country)}
                    alt="flag Image"
                  />
                  <Styles.Heading>
                    {region.city}, {region.state}
                    <Styles.SubHeading>
                      {region.region}, {region.country}
                    </Styles.SubHeading>
                  </Styles.Heading>
                </Styles.Card0>
              ) : (
                <Styles.Card
                  onClick={() => {
                    setIsChecked(region.id);
                  }}
                >
                  <Checkbox
                    data={region}
                    setCheckedValue={(value) => setIsChecked(value)}
                    checkbox={values.region}
                    onClick={() => {
                      setIsChecked(region.id);
                    }}
                  />
                  <img
                    style={{ border: '0.5px solid #C5C5C5' }}
                    src={getRegionImage(region.country)}
                    alt="flag Image"
                  />
                  <Styles.Heading>
                    {region.city}, {region.state}
                    <Styles.SubHeading>
                      {region.region}, {region.country}
                    </Styles.SubHeading>
                  </Styles.Heading>
                </Styles.Card>
              )}
            </Grid>
          ))}
        </Grid>
      </Styles.InlineFlex>
      <FormikErrorMessage formikProp={formikProp} value="region" />
    </Styles.WHStep3SC>
  );
};
export default WHStep4;
