import React from 'react';
import { BMStatsArrowText, BMStatsLinkAggregation } from '../util';
import { ChartImage, MobileleftBorder, ChartArea, EmptyArea, EmptyAreaText } from '../Statistics.style';
import ConditionalFragment from 'components/conditional-fragment';
import { StatsArrow } from '../components/TopArrow';
import { useMediaQuery } from '@material-ui/core';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';
import { LazyImage } from 'react-lazy-images';
import { useTranslation } from 'react-i18next';

const BMCharts = ({ data, graphImages }) => {
  const { t } = useTranslation();
  const mobile_view = useMediaQuery('(max-width:1250px)');

  return (
    <div style={{ backgroundColor: '#f5f5f5', padding: '0.5rem 0 1.5rem 0', width: '100%' }}>
      {graphImages.map((time, index) => (
        <Grid container key={index}>
          {get(data, 'networks[0].interfaces', []).map((item, i) => (
            <>
              <ConditionalFragment
                condition={i == 0 && item?.link_aggregation === 'enabled' && item?.port === 'enabled'}
              >
                <Grid xs={12}>
                  {/* top arrow and lines work for link aggregation scenario started. */}
                  {mobile_view && index == 0 ? (
                    <StatsArrow text={BMStatsLinkAggregation(item)} />
                  ) : (
                    !mobile_view && <StatsArrow text={BMStatsLinkAggregation(item)} />
                  )}
                  {/* top arrow and lines work for link aggregation scenario ended. */}
                </Grid>
              </ConditionalFragment>
              <Grid style={{ position: 'relative' }} key={i} xs={12} sm={12} md={12} lg={6} xl={6}>
                {/* top arrow and lines work for all other scenarios started. */}
                {/* lines boolean is passed to show the lines on mobile mode under the arrow. */}
                <StatsArrow lines text={BMStatsArrowText(item)} />
                {/* top arrow and lines work for all other scenario ended. */}

                <ChartArea mobileleftLines={item?.link_aggregation === 'enabled' && item?.port === 'enabled'}>
                  {/* Show left join lines for link aggregation chart only on mobile mode. */}
                  <ConditionalFragment condition={item?.link_aggregation === 'enabled' && item?.port === 'enabled'}>
                    <MobileleftBorder index={i} />
                  </ConditionalFragment>

                  {item?.link_aggregation == 'disabled' && item?.port == 'disabled' ? (
                    <>
                      <EmptyArea>
                        <EmptyAreaText>{t('bm/instance.string21')}</EmptyAreaText>
                      </EmptyArea>
                      <p style={{ margin: '1rem 1rem 1rem 1.2rem' }}>{t('bm/instance.string22')}</p>
                    </>
                  ) : (
                    <>
                      {/* //full width chart if not link aggregated. */}
                      <LazyImage
                        src={`${item[time]}?${new Date().getTime()}`}
                        placeholder={({ ref }) => <EmptyArea ref={ref} />}
                        loading={() => <EmptyArea />}
                        actual={({ imageProps }) => (
                          <ChartImage
                            fullWidth={item?.link_aggregation === 'enabled' && item?.port === 'enabled'}
                            {...imageProps}
                          />
                        )}
                        error={() => (
                          <EmptyArea>
                            <EmptyAreaText>{t('bm/instance.string24')}</EmptyAreaText>
                          </EmptyArea>
                        )}
                      />
                    </>
                  )}
                </ChartArea>
              </Grid>
            </>
          ))}
        </Grid>
      ))}
    </div>
  );
};
export { BMCharts };
