// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import NewVMStep5 from './NewVMStep5';
import { useQuery } from 'react-query';
import { addPricingData } from 'components/vm-deployment/util';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { updatePricing } from 'store/actions/vm-deployment';
import hooks from 'hooks';

const NewVMStep5Container = ({ formikProp }) => {
  const { values, setFieldValue } = formikProp;
  const dispatch = useDispatch();
  const priceServer = useSelector((state) => state.vmDeployment.pricingDetails);
  const { calculatePrice } = hooks();
  const { data: backupLevelsPricingData } = useQuery('/prices/vs/backups');

  const { data: backupLevelsData } = useQuery('/vs/backuplevels', {
    onSuccess: (res) => {
      handleServerSizeSelection(_get(res, 'vs_backupplans[0]', {}), false);
    },
  });

  const handleServerSizeSelection = (data, no = true) => {
    if (no) {
      const elem = document.getElementById('ST6');
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setFieldValue('backup_plan', data.id);
    dispatch(updatePricing(calculatePrice(data, priceServer), 'backup_plan'));
  };
  const withPricing = addPricingData(
    _get(backupLevelsData, 'vs_backupplans', []),
    _get(backupLevelsPricingData, 'prices_vs_backups', []),
  );
  useEffect(() => {
    if (values.backup_plan) {
      const data = withPricing.find((i) => i.id === values.backup_plan);
      dispatch(updatePricing(calculatePrice(data, priceServer), 'backup_plan'));
    }
  }, [values.size]);
  
  return (
    <NewVMStep5
      backuplevelsData={withPricing}
      selectedServerSize={values.backup_plan}
      onServerSizeSelection={handleServerSizeSelection}
      calculatePrice={(data) => calculatePrice(data, priceServer)}
      formikProp={formikProp}
    />
  );
};
export default NewVMStep5Container;
