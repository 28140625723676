import { LENGTH_ERROR } from 'utils';
import * as Yup from 'yup';
import _get from 'lodash/get';
import { alphaSpace, nameRegex } from './regex';
import { EMAIL_YUP } from './yup';
export const change_password_form = [
  {
    type: 'password',
    label: 'Current password',
    name: 'original_password',
    lang: 'account/profile.string4',
    placeholder: '',
  },
  {
    type: 'password',
    label: 'New password',
    name: 'new_password',
    lang: 'account/profile.string5',
    placeholder: 'Please use a complex password',
    placeholder_lang: 'account/profile.string7',
  },
  {
    type: 'password',
    label: 'New password again',
    name: 'new_password_confirm',
    lang: 'account/profile.string6',
    placeholder: 'Same password as above',
    placeholder_lang: 'account/profile.string8',
  },
];

export const change_email_form = [
  {
    type: 'email',
    label: 'Log in instead with',
    lang: 'account/profile.string17',
    name: 'email_login',
    placeholder: 'An email address',
    placeholder_lang: 'account/profile.string18',
  },
];

export const edit_Email_Contact_Form = [
  {
    type: 'email',
    lang: 'account/profile.string22',
    name: 'primary_email',
  },
  {
    type: 'email',
    lang: 'account/profile.string23',
    name: 'secondary_email',
  },
  {
    type: 'email',
    lang: 'account/profile.string24',
    name: 'primary_billing_email',
  },
  {
    type: 'email',
    lang: 'account/profile.string25',
    name: 'secondary_billing_email',
  },
];

export const nameData = [
  {
    type: 'text',
    name: 'first_name',
    placeholder: 'First name',
    placeholder_lang: 'account/profile.string29',
    dataTooltip: 'account/profile.string50',
  },
  {
    type: 'text',
    name: 'last_name',
    placeholder: 'Last name',
    placeholder_lang: 'account/profile.string30',
    dataTooltip: 'account/profile.string50',
  },
];

export const phoneData = [
  {
    type: 'text',
    name: 'phone_primary',
    placeholder: 'Cell Phone',
    placeholder_lang: 'account/profile.string44',
    dataTooltip: 'account/profile.string47',
  },
  {
    type: 'text',
    name: 'phone_secondary',
    placeholder: 'Home Phone',
    placeholder_lang: 'account/profile.string45',
    dataTooltip: 'account/profile.string48',
  },
  {
    type: 'text',
    name: 'phone_emergency',
    placeholder: 'Emergency Phone',
    placeholder_lang: 'account/profile.string46',
    dataTooltip: 'account/profile.string49',
  },
];

export const cityData = [
  {
    name: 'city',
    placeholder: 'Your city.',
    placeholder_lang: 'account/profile.string37',
    label: 'City',
    dataTooltip: 'account/profile.string40',
  },
  {
    name: 'state',
    placeholder: 'State/Province.',
    placeholder_lang: 'account/profile.string38',
    label: 'State',
    dataTooltip: 'account/profile.string41',
  },
  {
    name: 'zip',
    placeholder: 'Zip Code.',
    placeholder_lang: 'account/profile.string39',
    label: 'Zip Code',
    dataTooltip: 'account/profile.string42',
  },
];

export const initialValues = (emails) => {
  return {
    primary_email: _get(emails, 'primary_email', ''),
    secondary_email: _get(emails, 'secondary_email', ''),
    primary_billing_email: _get(emails, 'primary_billing_email', ''),
    secondary_billing_email: _get(emails, 'secondary_billing_email', ''),
    email_login: _get(emails, 'email_login', ''),
  };
};

export const validationSchema = Yup.object({
  primary_email: EMAIL_YUP.required('account/profile.string56'),
  secondary_email: EMAIL_YUP.optional(),
  primary_billing_email: EMAIL_YUP.optional(),
  secondary_billing_email: EMAIL_YUP.optional(),
  email_login: EMAIL_YUP.optional(),
});

export const changePasswordInitialValues = (passwords) => {
  return {
    original_password: passwords,
    new_password: passwords,
    new_password_confirm: passwords,
  };
};

export const changePasswordValidationSchema = Yup.object({
  original_password: Yup.string().required('account/profile.string51').max(64, LENGTH_ERROR()),
  new_password: Yup.string().required('account/profile.string52').max(64, LENGTH_ERROR()),
  new_password_confirm: Yup.string()
    .required('account/profile.string53')
    .max(64, LENGTH_ERROR())
    .oneOf([Yup.ref('new_password'), null], 'account/profile.string54'),
});

export const primaryFormInitialValues = (twoFactordata, defaultData) => {
  const { primary_2fa_area_code, primary_2fa_phone } = twoFactordata;
  const primary_areacode = primary_2fa_area_code == '' ? defaultData[0].value : primary_2fa_area_code;

  return {
    primary_2fa_area_code: primary_areacode,
    primary_2fa_phone: primary_2fa_phone,
    token1: '',
    token2: '',
  };
};

export const backupFormInitialValues = (twoFactordata, defaultData) => {
  const { backup_2fa_area_code, backup_2fa_phone } = twoFactordata;
  const backup_areacode = backup_2fa_area_code == '' ? defaultData[0].value : backup_2fa_area_code;

  return {
    backup_2fa_area_code: backup_areacode,
    backup_2fa_phone: backup_2fa_phone,
    token1: '',
    token2: '',
  };
};

export const primaryFormValidationSchema = Yup.object({
  primary_2fa_area_code: Yup.string().optional(),
  primary_2fa_phone: Yup.string().optional(),
});

export const backupFormValidationSchema = Yup.object({
  backup_2fa_area_code: Yup.string().optional(),
  backup_2fa_phone: Yup.string().optional(),
});

export const initialValue = (emails) => {
  return {
    email_login: _get(emails, 'email_login', ''),
  };
};

export const validation = Yup.object({
  email_login: EMAIL_YUP.required('account/profile.string55'),
});

export const personalInfoInitialValues = (values, defaultValues = {}) => {
  return {
    first_name: _get(values, 'first_name', ''),
    last_name: _get(values, 'last_name', ''),
    organization: _get(values, 'organization', ''),
    address_first_line: _get(values, 'address_first_line', ''),
    address_second_line: _get(values, 'address_second_line', ''),
    city: _get(values, 'city', 'United States'),
    state: _get(values, 'state', ''),
    zip: _get(values, 'zip', ''),
    country: _get(values, 'country', '') || defaultValues['country'],
    phone_primary: _get(values, 'phone_primary', ''),
    phone_secondary: _get(values, 'phone_secondary', ''),
    phone_emergency: _get(values, 'phone_emergency', ''),
  };
};

export const modifyValuesProfileSubmit = (values) => {
  return {
    ...values,
    phone_primary: (_get(values, 'phone_primary', '') + '').replace(/\D/g, ''),
    phone_secondary: (_get(values, 'phone_secondary', '') + '').replace(/\D/g, ''),
    phone_emergency: (_get(values, 'phone_emergency', '') + '').replace(/\D/g, ''),
  };
};

export const personalInfoValidationSchema = Yup.object({
  first_name: Yup.string()
    .required('account/profile.string57')
    .max(64, LENGTH_ERROR())
    .matches(nameRegex, 'account/profile.string58'),
  last_name: Yup.string()
    .required('account/profile.string59')
    .max(64, LENGTH_ERROR())
    .matches(nameRegex, 'account/profile.string58'),
  organization: Yup.string()
    // .required('Organization is not required')
    .matches(alphaSpace, 'account/profile.string58')
    .max(100, LENGTH_ERROR(100)),
  address_first_line: Yup.string()
    .required('account/profile.string60')
    .matches(alphaSpace, 'account/profile.string58')
    .max(100, LENGTH_ERROR(100)),
  address_second_line: Yup.string().optional().matches(alphaSpace, 'account/profile.string58').max(100, LENGTH_ERROR(100)),
  city: Yup.string()
    .required('account/profile.string61')
    .matches(alphaSpace, 'account/profile.string58')
    .max(64, LENGTH_ERROR()),
  state: Yup.string().optional().matches(alphaSpace, 'account/profile.string58'),
  zip: Yup.string().required('account/profile.string62').matches(alphaSpace, 'account/profile.string58'),
  country: Yup.string().required('account/profile.string63'),
  phone_primary: Yup.string().required('account/profile.string64').max(30, `account/profile.string65`),
  phone_secondary: Yup.string().max(30, `account/profile.string65`).optional(),
  phone_emergency: Yup.string().max(30, `account/profile.string65`).optional(),
});
