import React from 'react';
import * as Styles from 'pages/member/wh/WH.style';
import { StepLabel, Highlight } from 'components/vm-deployment/components';
import { useTranslation, Trans } from 'react-i18next';
import Checkbox from 'components/common/radio-button';
import ConditionalFragment from 'components/conditional-fragment';
import Input from 'components/input';
import { Icon } from 'semantic-ui-react';
import {
  domainTabs,
  initialValue,
  initialValueOfTransferDomain,
  validationSchema,
  validationSchemaOfTransferDomain,
  whCreateInitialValue,
} from 'constants/wh';
import { CustomHook } from 'utils/custom-hook';
import { Form, Formik } from 'formik';
import { get } from 'lodash';
import FormikErrorMessage from 'components/formik-error-message';
import { DOMAIN_LOOKUP } from '../queries';
import { useMutation } from 'react-query';
import WHDomainForm from 'components/wh/WHDomainForm';
import { H6, P } from 'components/reusable-css';

const WHStep1 = ({ domainExtData, formikProp }) => {
  const {
    mutate: domainLookUp,
    isLoading: domainLookUploading,
    isSuccess,
    isError,
    reset,
  } = useMutation(DOMAIN_LOOKUP);
  const { t } = useTranslation();
  const { mutationProps } = CustomHook();
  const { setFieldValue, values: parentValues } = formikProp;
  const isChecked = parentValues.domain_checked;
  const setIsChecked = (value) => {
    setFieldValue('domain_checked', value);
  };

  // SUBMITTING DOMAIN LOOKUP
  const handleSubmit = (values) => {
    domainLookUp(
      { domain: values.new_domain, domainext: values.selectedDomainExt },
      mutationProps(() => {}, {
        onSuccess: async () => {
          const domainPrices = domainExtData?.prices_domain_extensions.find(
            (item) => item.id === values.selectedDomainExt,
          );
          setFieldValue('domain', `${values.new_domain}.${values.selectedDomainExt}`);
          setFieldValue('prices.domain', domainPrices.price_yearly_usd);
          setFieldValue('prices.yearly.domain', domainPrices.price_yearly_usd);
          // resetForm({
          //   values: { selectedDomainExt: domainExtData?.prices_domain_extensions[0]?.id, new_domain: '' },
          // });
        },
        onError() {
          setFieldValue('domain', `${values.new_domain}.${values.selectedDomainExt}`);
        },
      }),
    );
  };

  return (
    <Styles.WHStep1SC>
      <StepLabel id="ST1" stepCount={t('new_server.step1.step1')}>
        <Trans i18nKey="wh_step1_heading" components={{ highlight: <Highlight spaceLeft /> }} />
      </StepLabel>
      <Styles.Margin>
        <Styles.FlexContainer onClick={() => setIsChecked(domainTabs.register_a_new_domain)}>
          <Checkbox
            checkbox={isChecked}
            setCheckedValue={() => {
              setIsChecked(domainTabs.register_a_new_domain);
            }}
            data={{ id: domainTabs.register_a_new_domain }}
          />
          <H6>{t(domainTabs.register_a_new_domain)}</H6>
        </Styles.FlexContainer>
        <ConditionalFragment condition={isChecked == domainTabs.register_a_new_domain}>
          <Styles.NoNeedDomain>
            <Formik
              initialValues={initialValue(get(domainExtData, 'prices_domain_extensions.0.id', ''))}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formikProp) => (
                <WHDomainForm
                  formikProp={formikProp}
                  domainLookUploading={domainLookUploading}
                  domainExtData={domainExtData}
                  isError={isError}
                  isSuccess={isSuccess}
                  parentValues={parentValues}
                />
              )}
            </Formik>
          </Styles.NoNeedDomain>
        </ConditionalFragment>
        <Styles.FlexContainer
          onClick={() => {
            setFieldValue('domain', '');
            setFieldValue('price', whCreateInitialValue.prices);
            setIsChecked(domainTabs.transfer_an_existing_domain);
            reset();
          }}
        >
          <Checkbox
            checkbox={isChecked}
            setCheckedValue={() => {
              setIsChecked(domainTabs.transfer_an_existing_domain);
              setFieldValue('domain', '');
            }}
            data={{ id: domainTabs.transfer_an_existing_domain }}
          />
          <H6>{t(domainTabs.transfer_an_existing_domain)}</H6>
        </Styles.FlexContainer>
        <ConditionalFragment condition={isChecked == domainTabs.transfer_an_existing_domain}>
          <Formik initialValues={initialValueOfTransferDomain} validationSchema={validationSchemaOfTransferDomain}>
            {({ values, handleBlur, handleChange, errors, touched }) => (
              <Form>
                <Styles.NoNeedDomain>
                  <Input
                    value={values['transfer_domain']}
                    placeholder={'Enter the domain name you want to transfer'}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors['transfer_domain']}
                    touched={touched['transfer_domain']}
                    styling={{ marginTop: '-20px', width: '54em' }}
                    name="transfer_domain"
                    EndAdornment={() => (
                      <span>
                        <Icon name="search" />
                      </span>
                    )}
                    inputProps={{
                      maxLength: 64,
                    }}
                  />
                  <P >
                    Once you deploy this web hosting container, you will receive the necessary DNS information that you
                    need to apply to your existing domain.
                  </P>
                  <P >
                    If you do not know how, our team will gladly do it for you. Just simply click the support icon to
                    the left and open a ticket with our team. Please include login credentials to your domain registrar.
                  </P>
                </Styles.NoNeedDomain>
              </Form>
            )}
          </Formik>
        </ConditionalFragment>
        <Styles.FlexContainer onClick={() => setIsChecked(domainTabs.no_need_for_a_domain)}>
          <Checkbox
            checkbox={isChecked}
            setCheckedValue={() => {
              setIsChecked(domainTabs.no_need_for_a_domain);
            }}
            data={{ id: domainTabs.no_need_for_a_domain }}
          />
          <H6>{t(domainTabs.no_need_for_a_domain)}</H6>
        </Styles.FlexContainer>
        <ConditionalFragment condition={isChecked == domainTabs.no_need_for_a_domain}>
          <Styles.NoNeedDomain>
            <P>
              We will assign you a subdomain of <span>*.serverpoint.app.</span> You may change this later or add your
              own domains.
            </P>
          </Styles.NoNeedDomain>
        </ConditionalFragment>
      </Styles.Margin>
      <FormikErrorMessage formikProp={formikProp} value="domain" />
    </Styles.WHStep1SC>
  );
};
export default WHStep1;
