import React, { useState } from 'react';
import { TagCard } from 'components/manage-vm';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import IsNewInstance from 'components/common/isNewInstance';
import { Icon } from 'semantic-ui-react';
import ErrorMessage from 'components/common/red-notification';
import { AlmaLinuxcPanel } from 'components/manage-vm/components';
import ConditionalFragment from 'components/conditional-fragment';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import { H5, H6, P } from 'components/reusable-css';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { navLinks } from 'utils/nav-links-util';
import { useQuery } from 'react-query';
import PasswordRetrieve from 'components/manage-vm/PasswordRetrieve';

const EditLabel = ({
  statusOfServer,
  type,
  isTags,
  labelText,
  data,
  allTags,
  managerVmIsFetching,
  managerVmRefetch,
  openTag,
  handleClose,
  handleOpen,
  setting,
  enableActionsDelete,
  inProgress,
  refetchTags,
  enableActionsCancel,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isTriggerApi, setIsTriggerApi] = useState(false);
  const {
    data: passwordData,
    isLoading,
    isRefetching,
  } = useQuery('/vs/password/retrieve', {
    enabled: isTriggerApi,
  });
  const rights = useSelector(userRights);
  return (
    <React.Fragment>
      <div className="d-flex align-items-center">
        <Styles.Label>
          {setting ? <H6>{labelText}</H6> : <H5>{labelText}</H5>}
          <ConditionalFragment
            condition={type === 'bm' ? rights?.bmrename || rights?.bmedittags : rights?.vsrename || rights?.vsedittags}
          >
            <span style={{ marginRight: '1rem' }}>
              <Icon name="pencil" onClick={handleOpen} />
            </span>
          </ConditionalFragment>
          <IsNewInstance completeTime={statusOfServer?.date_deployment_completed} />
        </Styles.Label>
      </div>
      <ConditionalFragment condition={!inProgress}>
        <AlmaLinuxcPanel type={type} data={data} style={{ marginBottom: '15px' }} />
      </ConditionalFragment>
      {data.vs_cancelation_scheduled == 1 && data?.billing_schedule != 'hourly' && !setting && (
        <ErrorMessage style={{ marginBottom: '5px' }}>
          <P>
            <Trans i18nKey={'vs/instance.string195'}>
              <span
                onClick={enableActionsCancel}
                style={{ margin: '0 4px', textDecoration: 'underline', cursor: 'pointer' }}
              />
            </Trans>
          </P>
        </ErrorMessage>
      )}
      {data.retrievable_password == 1 && !setting && (
        <Styles.PasswordLink
          onClick={() => {
            setIsTriggerApi(true);
            setIsOpen(true);
          }}
        >
          <P>{t('vs/instance.string201')}</P>
        </Styles.PasswordLink>
      )}
      {data?.vs_is_suspended == 1 && !setting && (
        <ErrorMessage style={{ marginBottom: '5px' }} bgColor={'#FF7300'} color={'black'}>
          <P>
            <Trans i18nKey={'vs/instance.string198'}>
              <Link
                to={navLinks.see_current_tickets.link}
                style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }}
              />
            </Trans>
          </P>
        </ErrorMessage>
      )}
      {data.bm_cancelation_scheduled == 1 && (
        <ErrorMessage>
          <P>
            <Trans i18nKey={'bm/instance.string4'}>
              <span
                onClick={enableActionsDelete}
                style={{ margin: '0 4px', textDecoration: 'underline', cursor: 'pointer' }}
              />
            </Trans>
          </P>
        </ErrorMessage>
      )}
      <Styles.AddTag>
        <TagCard
          openTag={openTag}
          handleOpen={handleOpen}
          handleClose={handleClose}
          data={data}
          managerVmRefetch={managerVmRefetch}
          allTags={allTags}
          refetchTags={refetchTags}
          managerVmIsFetching={managerVmIsFetching}
          isTags={isTags}
          type={type}
          rights={rights}
        />
      </Styles.AddTag>
      <PasswordRetrieve
        isOpen={isOpen}
        isLoading={isLoading}
        isRefetching={isRefetching}
        handleClose={() => {
          setIsTriggerApi(false);
          setIsOpen(false);
          managerVmRefetch();
        }}
        passwordData={passwordData}
      />
    </React.Fragment>
  );
};
export { EditLabel };
