export const lightColor = {
  fill: true,
  borderColor: '#2c3e50',
  pointBackgroundColor: '#2c3e50',
  borderWidth: 1,
};

export const darkColor = {
  fill: true,
  borderColor: '#75828d',
  borderWidth: 1,
  pointBackgroundColor: '#603913',
};
