import React from 'react';
import DomainStep1 from './DomainStep1';

const DomainStep1Container = ({
  domainList,
  transferPossible,
  setTransferPossible,
  isNewDomain,
  setIsNewDomain,
}) => {
  return (
    <DomainStep1
      domainList={domainList}
      transferPossible={transferPossible}
      setTransferPossible={setTransferPossible}
      isNewDomain={isNewDomain}
      setIsNewDomain={setIsNewDomain}
    />
  );
};

export default DomainStep1Container;
