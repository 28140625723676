import { get } from 'lodash';
import { lightColor, darkColor } from 'constants/index';

//CPU USAGE
export const CpuUsageEveryFiveMinutes = (statsData, type) => {
  let cpuData = get(statsData, `${type}_resource_statistics[0].cpu_usage_every_five_minutes`, []);
  let labels = [];
  let datasets = [{ label: 'CPU usage', data: [], ...lightColor }];
  cpuData.forEach((item) => {
    labels.push(item?.timestamp);
    datasets[0]?.data?.push(item?.cpu_usage_percentage);
  });
  return { labels, datasets };
};

export const CPUYMax = (statsData, type) => {
  let cpuYMax = Number(
    get(statsData, `${type}_resource_statistics[0].cpu_usage_every_five_minutes[0].cpu_maximum_usage_percentage`),
  );
  let cpuData = get(statsData, `${type}_resource_statistics[0].cpu_usage_every_five_minutes`, []);
  let yMaxData = [];
  cpuData.forEach((item) => {
    yMaxData.push(item?.cpu_usage_percentage);
  });
  let highestValue = Math.max(...yMaxData);
  let yMaxValue = cpuYMax;
  // if (highestValue == cpuYMax) {
  //   yMaxValue = cpuYMax + cpuYMax * 0.4;
  // } else {
  //   yMaxValue = cpuYMax;
  // }
  return { yMaxValue, highestValue };
};

//LINUX AVERAGE
export const UnixLoadAverage = (statsData, type) => {
  let unixData = get(statsData, `${type}_resource_statistics[0].unix_load_every_hour`, []);
  let labels = [];
  let datasets = [{ label: 'Linux load', data: [], ...lightColor }];
  unixData.forEach((item) => {
    labels.push(item?.timestamp);
    datasets[0]?.data?.push(item?.unix_load);
  });
  return { labels, datasets };
};

export const UnixLoadYMax = (statsData, type) => {
  let unixData = get(statsData, `${type}_resource_statistics[0].unix_load_every_hour`, []);
  let yMaxData = [];
  unixData.forEach((item) => {
    yMaxData?.push(item?.unix_load);
  });
  let highestValue = Math.max(...yMaxData);
  let yMaxValue;
  if (highestValue <= 1) {
    yMaxValue = 1;
  } else {
    let value = highestValue + highestValue * 0.25;
    yMaxValue = Math.round(value);
  }
  return { yMaxValue, highestValue };
};

//RAM USAGE
export const RAMUsage = (statsData, type) => {
  let ramData = get(statsData, `${type}_resource_statistics[0].ram_usage_every_hour`, []);
  let labels = [];
  let datasets = [
    { label: 'RAM cache usage', data: [], ...lightColor },
    { label: 'RAM usage', data: [], ...darkColor },
  ];
  ramData.forEach((item) => {
    labels.push(item?.timestamp);
    datasets[0]?.data?.push(item?.ram_cache_usage_percentage);
    datasets[1]?.data?.push(item?.ram_usage_percentage);
  });
  return { labels, datasets };
};

export const RamYMax = (statsData, type) => {
  let ramData = get(statsData, `${type}_resource_statistics[0].ram_usage_every_hour`, []);
  let ramCacheMaxData = [];
  let ramUsageData = [];
  ramData.forEach((item) => {
    ramCacheMaxData?.push(item?.ram_cache_usage_percentage);
    ramUsageData?.push(item?.ram_usage_percentage);
  });
  let highestValue = Math.max(...ramCacheMaxData, ...ramUsageData);
  return { highestValue };
};

//BANDWITH
export const BandwithYMax = (statsData) => {
  let unixData = get(statsData, 'vs_resource_statistics[0].bandwidth_usage_every_hour', []);
  let yMaxDataIn = [];
  let yMaxDataOut = [];
  unixData.forEach((item) => {
    yMaxDataIn?.push(Number(item?.network_rate_in) / 1024);
    yMaxDataOut?.push(Number(item?.network_rate_out) / 1024);
  });
  let highestValue = Math.max(...yMaxDataIn, ...yMaxDataOut);
  let yMaxValue;
  if (highestValue <= 1) {
    yMaxValue = 1;
  } else {
    let value = highestValue + highestValue * 0.25;
    yMaxValue = Math.round(value);
  }
  return { yMaxValue, highestValue };
};

export const Bandwidth = (statsData) => {
  let bandwidthData = get(statsData, 'vs_resource_statistics[0].bandwidth_usage_every_hour', []);
  let labels = [];
  let datasets = [
    { label: 'IN data transfer', data: [], ...lightColor },
    { label: 'OUT data transfer', data: [], ...darkColor },
  ];
  bandwidthData.forEach((item) => {
    labels.push(item?.timestamp);
    datasets[0]?.data?.push(roundOff(Number(item?.network_rate_in) / 1024));
    datasets[1]?.data?.push(roundOff(Number(item?.network_rate_out) / 1024));
  });
  return { labels, datasets };
};

//DISKUSAGE
const pieChart = {
  backgroundColor: ['#FF7300', '#dedede'],
  borderColor: ['#FF7300', '#dedede'],
  borderWidth: 0,
};

export const DiskUsageData = (used, avail) => {
  let data = {
    datasets: [
      {
        data: [Number(used), Number(avail)],
        ...pieChart,
      },
    ],
  };
  return data;
};

export const YStepSizeRounded = (customStepSize, maxValues, ystepSize) =>
  customStepSize || maxValues?.yMaxValue > 1
    ? Math.round(maxValues?.yMaxValue / ystepSize)
    : maxValues?.yMaxValue / ystepSize;

export const roundOff = (value) => Math.ceil(value * 100) / 100;
export const checkWholeValues = (value) => (value > 1 ? Math.round(value) : value);

//BM CHART IMAGES
export const bmGraphImages = ['mrtg_hourly', 'mrtg_daily', 'mrtg_weekly', 'mrtg_monthly'];
export const BMStatsArrowText = (item) =>
  item?.link_aggregation === 'disabled' && item?.port === 'enabled' && item?.nic === '0'
    ? 'bm/instance.string18'
    : item?.link_aggregation === 'disabled' && item?.port === 'enabled' && item?.nic === '1'
    ? 'bm/instance.string19'
    : item?.link_aggregation === 'disabled' && item?.port === 'disabled' && item?.nic === '1'
    ? 'bm/instance.string20'
    : '';
export const BMStatsLinkAggregation = (item) =>
  item?.link_aggregation === 'enabled' && item?.port === 'enabled' ? 'bm/instance.string17' : '';
