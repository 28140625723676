import React from 'react';
import * as Styles from 'pages/member/dns/DNS.style';
import { H5, H6 } from 'components/reusable-css';
import SettingDropdown from '../SettingDropdown';
import { recordsListdropdown } from '../util';
import { useTranslation } from 'react-i18next';

const MxRecordsList = ({ data, handleAction, deleteLoading, isFetching }) => {
  const { t } = useTranslation();
  return (
    <Styles.Step2>
      {data?.mx?.length > 0 ? (
        <>
          <Styles.RecordTableHeader>
            <Styles.RecordCol1>
              <H6>{t('dns/instance.string38')}</H6>
            </Styles.RecordCol1>
            <Styles.RecordCol2>
              <H6>{t('dns/instance.string31')}</H6>
            </Styles.RecordCol2>
            <Styles.RecordCol3>
              <H6>{t('dns/instance.string43')}</H6>
            </Styles.RecordCol3>
            <Styles.RecordCol4 />
          </Styles.RecordTableHeader>
          {data?.mx?.map((item, i) => (
            <Styles.ListContainer loading={deleteLoading || isFetching} key={i}>
              <Styles.RecordTable>
                <Styles.RecordCol1>
                  <i className="fa fa-circle" />
                  <H5>{item.name}</H5>
                </Styles.RecordCol1>
                <Styles.RecordCol2>
                  <H6>{t('dns/instance.string28')}</H6>
                </Styles.RecordCol2>
                <Styles.RecordCol3>
                  <H6>{item.priority}</H6>
                </Styles.RecordCol3>
                <Styles.RecordCol4>
                  <SettingDropdown
                    dropdownItems={recordsListdropdown}
                    handleAction={(action) => handleAction(action, item.id)}
                  />
                </Styles.RecordCol4>
              </Styles.RecordTable>
            </Styles.ListContainer>
          ))}
        </>
      ) : null}
    </Styles.Step2>
  );
};
export default MxRecordsList;
