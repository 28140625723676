import React from 'react';
import Input from 'components/input';
import { linkify } from 'utils/linkify';
import { Grid } from '@material-ui/core';
import classes from './label-field.module.css';
import Select from 'components/select';
import { H5, P } from 'components/reusable-css';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const LabelField = ({ styling, type, text, selectStyling, options, localizeText, localizeLabel, ...rest }) => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  return (
    <Grid className={classes.main} container>
      <Grid xs={12} sm={5} md={4} lg={4} xl={2} className='pr-5'>
        <H5 className={classes.label}>{t(`${localizeLabel}`)}</H5>
      </Grid>
      <Grid xs={12} sm={7} lg={8} xl={10}>
        {type === 'select' ? (
          <Select styling={selectStyling} options={options} {...rest} />
        ) : (
          <Input styling={styling} type={type} {...rest} placeholderClass />
        )}
        {rest.name == 'ipv4_ips' ? (
          <P tabIndex="-1" className={classes.text}>
            <Trans
              i18nKey={localizeText}
              values={{
                price: rest.ipPrices[0]?.price_monthly_usd || 0,
                currency_symbol: userAccount.account_currency_symbol,
              }}
            />
          </P>
        ) : (
          <P tabIndex="-1" className={classes.text}>
            {text && linkify(t(`${localizeText}`))}
          </P>
        )}
      </Grid>
    </Grid>
  );
};
export default LabelField;
