import React from 'react';
import * as Styles from 'pages/member/wh/WH.style';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/common/custom-button';
import { Message } from 'components/alert/Message';
import { scrollToSection } from 'utils';
import UseWh from 'hooks/use-wh';
import priceCalculator from 'utils/price-calculator';
import { P } from 'components/reusable-css';

const WHLastStep = ({ formikProp, isLoading }) => {
  const { t } = useTranslation();
  const { validateForm, handleSubmit, values, isValid } = formikProp;
  const { withoutDomainPriceYearly, withoutDomainPriceMonthly } = UseWh({ formikProp });
  return (
    <Styles.NewVMLastStepSC>
      <Styles.PriceServer>
        <Styles.Content>
          <P className="price">Your web hosting space price is...</P>
          <Styles.Price>
            <Styles.PerMonth>
              USD $
              <Styles.PriceUnit>
                {values.options_prepaid === 1
                  ? priceCalculator(withoutDomainPriceMonthly, false)
                  : priceCalculator(withoutDomainPriceYearly, false)}
              </Styles.PriceUnit>
              /year
            </Styles.PerMonth>
          </Styles.Price>
        </Styles.Content>
      </Styles.PriceServer>
      <Styles.VirtualServer>
        <Styles.SubmitServer>
          <CustomButton
            isLoading={isLoading}
            title={t('Deploy')}
            disabled={!isValid}
            onClick={async () => {
              const errors = await validateForm();
              handleSubmit();
              if (errors.domain) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST1');
              }
              if (errors.sizes) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST2');
              }
              if (errors.region) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST3');
              }
              if (errors.app) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST4');
              }
              // if (errors.options_prepaid) {
              //   Message.error(t('string14'), t('string13'));
              //   return scrollToSection('ST5');
              // }
            }}
            type="submit"
            buttonStyles={{ marginLeft: '10px', padding: '0.8rem 1rem' }}
          />
        </Styles.SubmitServer>
      </Styles.VirtualServer>
    </Styles.NewVMLastStepSC>
  );
};

export default WHLastStep;
