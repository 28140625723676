import React from 'react';
import Loading from 'components/common/loading/Loading';
import { useMutation, useQuery } from 'react-query';
import SingleIssue from './IssueDetail';
import { ADD_TICKET_REPLY } from '../queries';
import { CustomHook } from 'utils/custom-hook';
import { Base64 } from 'js-base64';
import NotFound from 'layout/not-found/NotFound';

const SingleIssueContainer = ({ style }) => {
  let id = location?.hash?.split('s/')[1];
  const { data, isLoading, refetch: refetchReply } = useQuery(`/tickets/${id}`);
  const { mutate: addReply, isLoading: addReplyLoading } = useMutation(ADD_TICKET_REPLY);
  const { mutationProps } = CustomHook();

  const handleAddReply = async (message, secret, onSuccess) => {
    await addReply(
      {
        action: 'reply',
        ticket_id: id,
        message: Base64.encode(message),
        secret: Base64.encode(secret),
      },
      mutationProps(() => {}, {
        onSuccess: () => {
          refetchReply();
          onSuccess && onSuccess();
        },
      }),
    );
  };

  if (isLoading) {
    return <Loading gap={200} />;
  }

  return data == undefined ? (
    <NotFound />
  ) : (
    <SingleIssue handleAddReply={handleAddReply} addReplyLoading={addReplyLoading} style={style} data={data} />
  );
};

export default SingleIssueContainer;
