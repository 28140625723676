import React from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import CustomButton from 'components/common/custom-button';
import PropTypes from 'prop-types';

const TabContent = ({ content2, content, buttonText, buttonProps }) => {
  return (
    <Styles.ActionDiv>
      <Styles.OptionText> {content} </Styles.OptionText>
      {content2 ? <Styles.OptionText> {content2} </Styles.OptionText> : null}
      <Styles.InputName>
        <CustomButton title={buttonText} buttonStyles={{ marginTop: '5px', padding: ' 9px 15px' }} {...buttonProps} />
      </Styles.InputName>
    </Styles.ActionDiv>
  );
};

TabContent.propTypes = {
  content: PropTypes.string,
  buttonText: PropTypes.string,
  buttonProps: PropTypes.any,
};

export { TabContent };
