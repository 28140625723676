import React from 'react';
import { BM_MANAGED, BM_MANAGED_PRICING } from 'components/bm-deployment/queries';
import { reactQueryConfig } from 'constants/react-query-config';
import { useQuery } from 'react-query';
import NewBMStep4 from './NewBMStep4';
import _get from 'lodash/get';
import { addPricingData } from 'components/vm-deployment/util';
import hooks from 'hooks';

const NewBMStep4Container = ({ formikProp }) => {
  const { scrollDownToId } = hooks();
  const { values, setFieldValue } = formikProp;
  const { data: managedData } = useQuery(['BM_MANAGED'], BM_MANAGED, {
    ...reactQueryConfig,
  });
  const { data: managedPricingData } = useQuery(['BM_MANAGED_PRICING'], BM_MANAGED_PRICING, {
    ...reactQueryConfig,
  });
  const handlePlanSelection = (data) => {
    scrollDownToId('ST5');
    setFieldValue('managed_plan', data.id);
    setFieldValue('prices.managed_plan', _get(data, 'pricing.price_monthly_usd', 0));
  };

  const withPricing = addPricingData(
    _get(managedData, 'bm_managed_plans', []),
    _get(managedPricingData, 'prices_bm_managed_plans'),
  );

  return (
    <NewBMStep4
      withPricing={withPricing}
      selectedManagedPlan={values.managed_plan}
      onManagedPlanSelection={handlePlanSelection}
      formikProp={formikProp}
    />
  );
};

export default NewBMStep4Container;
