import React, { useState } from 'react';
import * as Styles from 'pages/member/user-management/UserManagement.style';
import { DeleteCard, RoleEditPanel } from 'components/user-management';
import { roleDeletion, cannotDelete } from 'constants/user-management';
import ConditionalFragment from 'components/conditional-fragment';
import hooks from 'hooks';
import { useMutation } from 'react-query';
import { DELETE_ROLE } from './queries';
import { Icon } from 'semantic-ui-react';
import { checkDeletedItems } from 'components/manage-vm/utils';
import { H6 } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const CurrentRole = ({ getRoles, roleRefetch, labels, getRolesError, usersRefetch }) => {
  const { t } = useTranslation();
  const { mutationProps } = hooks();
  const { mutate: deleteRole, isLoading: loading } = useMutation(DELETE_ROLE);
  const [user, setUser] = useState();
  const [openModal, setOpenModal] = useState(false);
  const toggle = () => setOpenModal((prev) => !prev);

  const [deletedIds, setDeletedIds] = useState([]);
  const handleSubmit = async () => {
    await deleteRole(
      { id: user.id },
      mutationProps(() => {}, {
        onSuccess: async () => {
          toggle();
          setDeletedIds((prev) => [...prev, user.id]);
          usersRefetch();
        },
      }),
    );
  };
  let checkDisabledItem = (id) => checkDeletedItems(deletedIds, id);
  return (
    <Styles.Cards>
      <ConditionalFragment condition={!getRolesError}>
        {getRoles?.map((role) => (
          <Styles.Card style={{ ...checkDisabledItem(role.id) }} key={role.id}>
            <Styles.Description>
              <H6 style={{ fontWeight: '600' }}>{role?.rolename}</H6>
              <Styles.DescriptionIcon>
                <RoleEditPanel usersRefetch={usersRefetch} getRole={role} roleRefetch={roleRefetch} labels={labels} />
                <Styles.Flex
                  onClick={() => {
                    toggle();
                    setUser(role);
                  }}
                >
                  <Icon name="trash alternate" />
                  <span>{t('account/usermgmt.string13')}</span>
                </Styles.Flex>
              </Styles.DescriptionIcon>
            </Styles.Description>
          </Styles.Card>
        ))}
        <DeleteCard
          buttons={+user?.qty_users_assigned_to_this ? cannotDelete : roleDeletion}
          handleSubmit={handleSubmit}
          role={user}
          loading={loading}
          open={openModal}
          toggle={toggle}
        />
      </ConditionalFragment>
    </Styles.Cards>
  );
};

export { CurrentRole };
