/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-02 10:46:06
 * @modify date 2021-02-02 10:46:06
 */

import React from 'react';
import PropTypes from 'prop-types';

import { HighlightSC } from '../styles/Highlight.style';

/** Highlight component highlights the string in primary color */
export const Highlight = ({ children, spaceLeft, spaceRight, color }) => {
  return (
    <HighlightSC style={color && {color}} data-testid="highlight">
      {spaceLeft ? <span>&nbsp;</span> : ''}
      {children}
      {spaceRight ? <span>&nbsp;</span> : ''}
    </HighlightSC>
  );
};

Highlight.propTypes = {
  /** adds a space on the left side of the highlighted text */
  spaceLeft: PropTypes.bool,
  /** adds a space on the right side of the highlighted text */
  spaceRight: PropTypes.bool,
  /** children will contain the string which needs to be highlighted on the page in a primary color */
  children: PropTypes.any,
  color: PropTypes.string,
};

Highlight.defaultProps = {
  spaceLeft: false,
  spaceRight: false,
  children: '',
};
