import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';
import { deviceBreakpoints, variables } from 'variables';

export const NewVMStep7SC = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  padding: ${variables.pageContentPadding}px;
  // padding-top: 80px;
  padding-top: 60px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  padding-right: 0px;
  padding-bottom: 0px;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    padding-top: 0;
  }
`;

export const Heading = styled.div`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  color: #000;
  font-weight: bolder;
`;

export const StepDescription = styled.div`
  margin: 50px 0;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;
export const ServerSizeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  padding-right: 14px;
`;
