import React, { useState } from 'react';
import * as Styles from './DomainStep3.style';
import { useTranslation } from 'react-i18next';
import { H6, P } from 'components/reusable-css';
import RadioButton from 'components/common/radio-button';
import Heading from 'components/Heading/Heading';

const checkBtn = [
  { id: 'enable', text: 'Enable WHOIS privacy ($5/year)' },
  { id: 'disable', text: 'Disable WHOIS privacy' },
];

const DomainStep3 = () => {
  const { t } = useTranslation();
  const [checkValue, setCheckValue] = useState('enable');

  return (
    <Styles.NewDomainStep3SC>
      <Heading name={`new_domain.step3.heading`}>
        <Styles.StepDescription>
          <P>{t('new_domain.step3.description1')}</P>
          <P>{t('new_domain.step3.description2')}</P>
        </Styles.StepDescription>
        <Styles.CheckBoxContainer>
          {checkBtn.map((val) => (
            <div className="single" key={val.id} onClick={() => setCheckValue(val.id)}>
              <RadioButton
                checkbox={checkValue}
                checked={checkValue == val.id}
                setCheckedValue={(id) => setCheckValue(id)}
                data={val}
              />
              <H6>{val.text}</H6>
            </div>
          ))}
        </Styles.CheckBoxContainer>
      </Heading>
    </Styles.NewDomainStep3SC>
  );
};

export default DomainStep3;
