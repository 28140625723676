import Shape from 'assets/images/comment-shape.svg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Comment = styled.div`
  color: white;
  background: #008dc3;
  border-radius: 10px;
  margin: 2rem 0;
  padding: 10px 20px;
  font-size: 16px;
  position: relative;

  &::after {
    content: '';
    background-image: url(${Shape});
    width: 40px;
    height: 30px;
    position: absolute;
    bottom: -28px;
    right: 15px;
  }
`;
export const link = styled(Link)`
  border-bottom: 1px dotted white;
  word-break: break-all;
  &:hover {
    border-bottom: 0;
    text-decoration: none;
  }
`;

export const Dot = styled.div`
  padding: 0 3rem;
  display: flex;
  @media (max-width: 960px) {
    padding: 0 1.5rem;
  }
  .dot {
    content: '';
    width: 12px;
    height: 12px;
    background: #ff7300;
    color: #ff7300;
    border-radius: 50%;
    margin-top: 1.6rem;
  }
`;

export const Chart = styled.div`
  padding: 0 4rem;
  padding-top: 2rem;
  @media (max-width: 960px) {
    padding: 0 1.5rem;
    padding-top: 2rem;
  }
`;
export const ChartWidth = styled.div`
  @media (min-width: 1200px) {
    width: 1200px;
  }
  @media (max-width: 1199px) {
    width: 90%;
  }
  @media (max-width: 600px) {
    width: 95%;
  }
`;
export const Footer = styled.div`
  background-color: #151a20;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  flex-wrap: wrap;
  @media (max-width: 960px) {
    padding: 3rem 2rem;
  }
  .first {
    width: 50%;
    font-size: 17px;
    color: gray;
    @media (max-width: 960px) {
      width: 100%;
      padding-bottom: 1.5rem;
    }
  }
  .second {
    width: 50%;
    i {
      color: white;
      font-size: 25px;
      margin: 0.7rem;
      cursor: pointer;
      &:hover {
        color: #23527c;
      }
    }

    @media (max-width: 960px) {
      width: 100%;
    }
  }
`;
