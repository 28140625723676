import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import Input from 'components/input';
import { useQuery } from 'react-query';
import classes from 'assets/css/payment-form.module.css';
import { billingData, creditCardSelectData, FormLastData } from 'components/vm-deployment/constants';
import Select from 'components/select';
import { get_countries } from 'utils/countries';
import Card from 'card-validator';
import { Field } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { P } from 'components/reusable-css';
import { Label } from 'assets/css/common-styles';

const PaymentForm = ({ formikProp, addFunds }) => {
  const { values, handleChange, handleBlur, errors, touched, handleSubmit } = formikProp;
  const { t } = useTranslation();

  //INPUTS WIDTH RESPONSIVE START
  const matches = useMediaQuery('(max-width:800px)');
  let width = matches ? '97%' : '23rem';
  let credit_input_width = matches ? '97%' : '17rem';
  let cvv_input_width = matches ? { width: '100%', marginRight: '0.7rem' } : { width: '7rem' };
  let zip_code_input = matches ? { width: '100%', marginRight: '0.7rem' } : { width: '10rem' };
  let credit_info_select = { marginRight: '0.7rem', marginTop: '1.8rem' };
  let country_select = matches ? { width: '97%' } : { width: '23rem' };
  let state_input = matches ? { width: '100%', marginRight: '0.7rem' } : { width: '13rem', marginRight: '0.7rem' };
  let city_input = matches ? { width: '100%', marginRight: '0.7rem' } : { width: '24rem', marginRight: '0.7rem' };
  const textboxStyling = {
    marginRight: '0.7rem',
    width: width,
  };
  const creditCardNumberStyling = {
    marginRight: '0.7rem',
    width: credit_input_width,
  };
  //INPUTS WIDTHS RESPONSIVE END

  //FORM-DATA START
  const billingMidFieldsData = [
    {
      name: 'city',
      placeholder: 'common/credit_card_module.string39',
      styling: city_input,
      dataTooltip: 'common/credit_card_module.string24',
    },
    {
      name: 'state',
      placeholder: 'common/credit_card_module.string40',
      styling: state_input,
      dataTooltip: 'common/credit_card_module.string25',
    },
    {
      name: 'zip',
      placeholder: 'common/credit_card_module.string41',
      styling: zip_code_input,
      dataTooltip: 'common/credit_card_module.string26',
    },
  ];
  //FORM-DATA END

  const cardValidation = (value) => {
    const numberValidation = Card.number(value);
    if (!numberValidation.isValid && value) {
      return 'Invalid card number';
    }
    return '';
  };

  //COUNTIES GET
  const { data: countries } = useQuery('/uicountries');

  return (
    <div className={classes.card_form}>
      <Grid className={classes.main} container>
        <Grid xs={12} sm={3} lg={3} xl={2}>
          <Label>{t('common/credit_card_module.string10')}</Label>
        </Grid>
        <Input
          styling={textboxStyling}
          type={'text'}
          name={'card_name'}
          value={values['card_name']}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors['card_name']}
          touched={touched['card_name']}
          placeholder={t('common/credit_card_module.string30')}
          dataTooltip={t('common/credit_card_module.string21')}
        />
      </Grid>
      <Grid className={classes.main} container>
        <Grid xs={12} sm={3} lg={3} xl={2}>
          <Label>{t('common/credit_card_module.string11')}</Label>
        </Grid>
        <Field
          validate={cardValidation}
          name={'card_number'}
          value={values['card_number']}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {({ field }) => (
            <Input
              {...field}
              type={'text'}
              styling={creditCardNumberStyling}
              error={errors['card_number']}
              touched={touched['card_number']}
              placeholder={t('common/credit_card_module.string31')}
              dataTooltip={t('common/credit_card_module.string22')}
            />
          )}
        </Field>
        {creditCardSelectData.map((item) => (
          <Select
            key={item.name}
            styling={credit_info_select}
            options={item.options}
            name={item.name}
            value={values[item.name]}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors[item.name]}
            touched={touched[item.name]}
          />
        ))}
        <Input
          type={'text'}
          styling={cvv_input_width}
          name={'cvv'}
          value={values['cvv']}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors['cvv']}
          touched={touched['cvv']}
          placeholder={t('common/credit_card_module.string32')}
        />
      </Grid>
      <Grid className={classes.main} container>
        <Grid xs={12} sm={3} lg={3} xl={2}>
          <Label>{t('common/credit_card_module.string12')}</Label>
        </Grid>
        <Grid style={{ position: 'relative' }} xs={12} sm={9} lg={9} xl={10}>
          {billingData.map((item) => (
            <>
              <Input
                key={item.name}
                type={'text'}
                styling={textboxStyling}
                name={item.name}
                value={values[item.name]}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors[item.name]}
                touched={touched[item.name]}
                placeholder={t(item.placeholder)}
                dataTooltip={t(item.dataTooltip)}
              />
            </>
          ))}
          <Grid container>
            {billingMidFieldsData.map((item) => (
              <Input
                key={item.name}
                type={'text'}
                styling={item.styling}
                name={item.name}
                value={values[item.name]}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors[item.name]}
                touched={touched[item.name]}
                placeholder={t(item.placeholder)}
                dataTooltip={t(item.dataTooltip)}
              />
            ))}
          </Grid>
          <Select
            styling={country_select}
            options={get_countries(countries)}
            name={'country'}
            value={values['country']}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors['country']}
            touched={touched['country']}
          />{' '}
        </Grid>
      </Grid>
      {FormLastData.map((item) => (
        <Grid key={item.name} className={classes.main} container>
          <Grid xs={12} sm={3} lg={3} xl={2}>
            <Label>{t(item.lang)}</Label>
          </Grid>
          <Grid xs={12} sm={8} lg={9} xl={10}>
            <Input
              styling={textboxStyling}
              type={item.type}
              name={item.name}
              value={values[item.name]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors[item.name]}
              touched={touched[item.name]}
              placeholder={t(item.placeholder)}
              dataTooltip={t(item.dataTooltip)}
              onEnter={handleSubmit}
            />
            {!addFunds ? (
              <P className={item.text && classes.issuer_phone}>{t(item.text_lang)}</P>
            ) : (
              <>
                <P className={item.addFundsText1 && classes.issuer_phone}>{t(item.addFundsText1_lang)}</P>
                <P className={item.addFundsText2 && classes.issuer_phone}>{t(item.addFundsText2_lang)}</P>
                <P className={item.addFundsText3 && classes.issuer_phone}>
                  <Trans i18nKey={item.addFundsText3_lang} values={{ currency_symbol: '$' }} />
                </P>
              </>
            )}
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default PaymentForm;
