import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const CHARGE_SAVED_CARD = async (e) => {
  const res = await axios.post(BASE_URL + `/billing/charge_default_cc`, e);
  return res.data;
};

export const SAVE_CHARGE_CARD = async (e) => {
  const res = await axios.post(BASE_URL + `/billing/cc/save`, e);
  return res.data;
};
