import { CORE, CPU_SHARE, DISK, RAM } from './step1';

export const pricingDetails = {
  // size: 0,
  // os: 0,
  // backup_plan: 0,
  // cpanel: 0,
  // [DISK]: 0,
  // [CORE]: 0,
  // [RAM]: 0,
};

export const defaultPricingDetailValues = {
  size: 0,
  os: 0,
  backup_plan: 0,
  cpanel: 0,
  [DISK]: 0,
  [CORE]: 0,
  [RAM]: 0,
  [CPU_SHARE]: 0,
};
