import React from 'react';
import { H1, P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const AffiliateRight = ({ item }) => {
  const { t } = useTranslation();
  return (
    <table className="table">
      <tbody>
        <tr>
          <td className="left">
            <H1>{item?.hits_last_90_days}</H1>
          </td>
          <td className="right">
            <P>{t('affiliate.string12')}</P>
          </td>
        </tr>
        <tr>
          <td className="left">
            <H1>{item?.successful_conversions}</H1>
          </td>
          <td className="right">
            <P>{t('affiliate.string13')}</P>
          </td>
        </tr>
        <tr>
          <td className="left">
            <H1>
              {item?.successful_conversions > 0 && item?.conversion_rate === '0' ? '<1%' : `${item?.conversion_rate}%`}
            </H1>
          </td>
          <td className="right">
            <P>{t('affiliate.string14')}</P>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export { AffiliateRight };
