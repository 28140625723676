import React from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import { BackupTop } from './index';

const DailyBackup = ({ backupData, data }) => {
  return (
    <Styles.DailyBackup billing_schedule={data?.billing_schedule == 'hourly'}>
      <BackupTop
        backupData={backupData}
        type="vs_daily_backups"
        titleText="vs/instance.string144"
        currentInfo="vs/instance.string147"
        emptyMessage="vs/instance.string151"
        data={data}
      />
    </Styles.DailyBackup>
  );
};
export { DailyBackup };
