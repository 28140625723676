export const features = [
  {
    id: 1,
    dataKey: 'ram',
    dataKey2: 'ram_type',
    lanRef: 'ram',
    lang: 'bm/instance.string43',
    plural_lang: 'bm/instance.string44',
  },
  {
    id: 2,
    dataKey: 'disk_count',
    dataKey2: 'disk_size',
    dataKey3: 'disk_type',
    lanRef: 'disk',
    lang: 'bm/instance.string45',
    plural_lang: 'bm/instance.string45',
  },
  {
    id: 3,
    dataKey: 'cpu_speed',
    dataKey2: 'cpu_sockets',
    lanRef: 'cpu_speed',
    langSingle: 'bm/instance.string66',
    langDual: 'bm/instance.string67',
    langQuad: 'bm/instance.string68',
  },

  {
    id: 4,
    dataKey: 'cpu_count',
    dataKey2: 'cpu_threads',
    lanRef: 'cpu_count',
    lang: 'bm/instance.string47',
    plural_lang: 'bm/instance.string48',
  },
  {
    id: 5,
    dataKey: 'nic_port',
    lanRef: 'redundant_network',
    lang: 'bm/instance.string49',
    plural_lang: 'bm/instance.string49',
  },
  {
    id: 6,
    dataKey: 'redundant_power',
    lanRef: 'redundant_power',
    lang: 'bm/instance.string50',
    plural_lang: 'bm/instance.string50',
  },
  {
    id: 7,
    dataKey: 'data_transfer_per_month',
    lanRef: 'data_transfer',
    lang: 'bm/instance.string51',
    plural_lang: 'bm/instance.string53',
  },
];

export const extraFeatures = [
  {
    id: 1,
    dataKey: 'extra_hardware_second_disk_count',
    dataKey2: 'extra_hardware_second_disk_size',
    dataKey3: 'extra_hardware_second_disk_type',
    lanRef: 'extra_hardware',
    lang: 'bm/instance.string56',
  },
];

export const extraWithMegaraidFeatures = [
  {
    id: 1,
    lanRef: 'megaraid_hardware',
    lang: 'bm/instance.string55',
  },
];
