import React from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import PropTypes from 'prop-types';
import IPImage from 'assets/images/IP.png';
import IsNewInstance from 'components/common/isNewInstance';
import sandAnimation from 'assets/gifs/sand-animation.gif';
import { useMediaQuery } from '@material-ui/core';
import { P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const IPContainer = ({ children, style, ip, ipData, text }) => {
  const { t } = useTranslation();
  const noWrap = useMediaQuery('(min-width:380px)');
  const wrap = useMediaQuery('(max-width:369px)');
  const match = wrap ? 'flex-wrap' : noWrap ? 'flex-nowrap' : null;
  return (
    <Styles.IPBackground style={style} className={`w-100 d-flex ${match} justify-content-between align-items-center`}>
      <div className={`d-flex align-items-center ${match}`}>
        <React.Fragment>
          <div className="d-flex align-items-center">
            <img src={IPImage} alt="IP icon" />
            <p style={{ margin: '10px 0px 10px 10px', minWidth: '10rem' }}>
              {ip || (
                <div className="d-flex" style={{ whiteSpace: 'nowrap' }}>
                  <span>{t('vs/instance.string128')}</span>
                  <img style={{ width: '1.5rem', height: '1.5rem', marginRight: '0.5rem' }} src={sandAnimation} />
                </div>
              )}
            </p>
          </div>
        </React.Fragment>
        <IsNewInstance completeTime={ipData.date_assigned} />
        <P>{t(text)}</P>
      </div>
      {children}
    </Styles.IPBackground>
  );
};

export { IPContainer };
IPContainer.propTypes = {
  children: PropTypes.any,
  style: PropTypes.any,
  ip: PropTypes.string,
  text: PropTypes.string,
  ipData: PropTypes.any,
};
