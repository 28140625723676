import React from 'react';
import { BM_APPS, BM_APPS_PRICING } from 'components/bm-deployment/queries/cpanel';
import { reactQueryConfig } from 'constants/react-query-config';
import { useQuery } from 'react-query';
import NewBMStep3 from './NewBMStep3';
import _get from 'lodash/get';
import { addPricingData } from 'components/vm-deployment/util';

const NewBMStep3Container = ({ formikProp }) => {
  const { data: appsData } = useQuery(['BM_APPS'], BM_APPS);
  const { data: appsPricingData } = useQuery(['BM_APPS_PRICING'], BM_APPS_PRICING, {
    ...reactQueryConfig,
  });
  const withPricing = addPricingData(
    _get(appsData, 'apps', []),
    _get(appsPricingData, 'prices_bm_apps'),
  );

  return <NewBMStep3 withPricing={withPricing} formikProp={formikProp} />;
};

export default NewBMStep3Container;
