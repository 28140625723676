import React from 'react';
import PropTypes from 'prop-types';
// import Modals from 'components/modal';
import { Icon } from 'semantic-ui-react';
import Tooltips from 'components/Tooltip';
import { useTranslation } from 'react-i18next';

const ConsoleModal = ({ handleConsoleOpen }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Tooltips title={t('vs/instance.string27')}>
        <Icon size="large" name="keyboard outline" onClick={handleConsoleOpen} />
      </Tooltips>
    </React.Fragment>
  );
};
export { ConsoleModal };
ConsoleModal.propTypes = {
  handleConsoleOpen: PropTypes.func,
};
