import {
  USER_UI,
  USER_ACCOUNT,
  USER_BILLING_ACCOUNT_REFETCH,
  USER_BILLING,
  USER_ACCOUNT_REFETCH,
  USER_UI_REFETCH,
  IS_USER_FETCHING,
  LOGOUT_USER,
} from 'store/types';

export const logoutUser = (payload) => (dispatch) => {
  dispatch({
    type: LOGOUT_USER,
    payload,
  });
};

export const updateUserUi = (payload) => (dispatch) => {
  dispatch({
    type: USER_UI,
    payload,
  });
};

export const updateUserAccount = (payload) => (dispatch) => {
  dispatch({
    type: USER_ACCOUNT,
    payload,
  });
};

export const updateUserAccountRefetch = (payload) => (dispatch) => {
  dispatch({
    type: USER_ACCOUNT_REFETCH,
    payload,
  });
};

export const updateUserUiRefetch = (payload) => (dispatch) => {
  dispatch({
    type: USER_UI_REFETCH,
    payload,
  });
};

export const isUserFetching = (payload) => (dispatch) => {
  dispatch({
    type: IS_USER_FETCHING,
    payload,
  });
};

export const updateUserBillingAccountRefetch = (payload) => (dispatch) => {
  dispatch({
    type: USER_BILLING_ACCOUNT_REFETCH,
    payload,
  });
};

export const updateUserBillingInfo = (payload) => (dispatch) => {
  dispatch({
    type: USER_BILLING,
    payload,
  });
};
