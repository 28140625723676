import React from 'react';
import { TransferData, Heading } from './index';
import { CustomPieChart } from '../charts';
import { Flex, Box } from '../Statistics.style';
import { get } from 'lodash';
import { mbToGb } from 'utils';
import { useMediaQuery } from '@material-ui/core';
import { H6, P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const Transfer = ({ statsData, data }) => {
  const { t } = useTranslation();
  let usedGB = get(data, 'vs_resource_statistics[0].disk_usage_current[0].disk_used_gb');
  const match1 = useMediaQuery('(min-width:2600px)');
  const match2 = useMediaQuery('(min-width:1500px)');
  const match3 = useMediaQuery('(max-width:1500px)');
  let widths = match1 ? '2' : match2 ? '4' : match3 ? '4' : null;

  return (
    <div className="row p-4 d-flex">
      <div style={{ position: 'relative' }} className={`pb-2 col-sm-12 col-md-12 col-lg-5 col-xl-${widths} pb-2`}>
        <Heading heading="vs/instance.string61">
          <Box>
            <CustomPieChart usedGB={usedGB} data={data} />
          </Box>
          {usedGB == 0 && (
            <>
              <H6 style={{ margin: '2rem 0 1rem 0', width: '300px', textAlign: 'center' }}>
                {t('vs/instance.string68')}
              </H6>
              <P>{t('vs/instance.string62')}</P>
            </>
          )}
        </Heading>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8">
        <Flex>
          <Heading style={{ width: '18rem', textAlign: 'center' }} heading="vs/instance.string63">
            <TransferData
              incomingData={
                statsData?.last_day_incoming_mb >= 1000
                  ? `${mbToGb(statsData?.last_day_incoming_mb).toFixed(2) || '0'}GB`
                  : `${statsData?.last_day_incoming_mb || '0'}MB`
              }
              outgoingData={
                statsData?.last_day_outgoing_mb >= 1000
                  ? `${mbToGb(statsData?.last_day_outgoing_mb).toFixed(2) || '0'}GB`
                  : `${statsData?.last_day_outgoing_mb || '0'}MB`
              }
            />
          </Heading>
          <div className="border" />
          <Heading style={{ width: '18rem', textAlign: 'center' }} heading="vs/instance.string64">
            <TransferData
              incomingData={`${statsData?.last_month_incoming_gb || '0'}GB`}
              outgoingData={`${statsData?.last_month_outgoing_gb || '0'}GB`}
            />
          </Heading>
        </Flex>
      </div>{' '}
    </div>
  );
};
export { Transfer };
