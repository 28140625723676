import React, { useState } from 'react';
import * as Styles from 'pages/member/dns/DNS.style';
import { Icon } from 'semantic-ui-react';
import { H5, P } from 'components/reusable-css';
import TriCut from 'assets/images/button-tri-cut.png';
import { recordsMenuOptions } from './util';
import { useMutation, useQuery } from 'react-query';
import Loading from 'components/common/loading/Loading';
import { SettingsSlidingPanel } from 'components/manage-vm/components';
import ARecordsList from './a-records/ARecordsList';
import AForm from './a-records/AForm';
import CNameRecordsList from './cname-records/CNameRecordsList';
import CNameForm from './cname-records/CNameForm';
import { ADD_RECORDS, DELETE_DOMAIN_DNS } from './queries';
import { CustomHook } from 'utils/custom-hook';
import MxForm from './mx-records/MxForm';
import MxRecordsList from './mx-records/MxRecordsList';
import NsForm from './ns-records/NsForm';
import NsRecordsList from './ns-records/NsRecordsList';
import AllRecordsList from './all-records/AllRecordsList';
import { useTranslation } from 'react-i18next';
import TxtForm from './txt-records/TxtForm';
import TxtRecordsList from './txt-records/TxtRecordsList';

const ConfigurationDNS = ({ isOpen, isClose, openDomain, ipData, refecthMainList }) => {
  const { t } = useTranslation();
  const { mutationProps } = CustomHook();
  const { data: domainData, isLoading, refetch, isFetching } = useQuery(`/dns/instance/${openDomain}`);
  const { mutate: saveRecords, isLoading: loadingRescords } = useMutation(ADD_RECORDS);
  const { mutate: deleteDomain, isLoading: deleteLoading } = useMutation(DELETE_DOMAIN_DNS);

  const [isShow, setIsShow] = useState('all');
  let data = domainData?.dns_domains[0];
  
  const handleAddRecord = async (values, resetForm, isTXT) => {
    await saveRecords(
      {
        domain: data.domain,
        isTXT,
        ...values,
      },
      mutationProps(() => {}, {
        onSuccess: async () => {
          refecthMainList();
          await refetch();
          resetForm();
        },
      }),
    );
  };

  const handleAction = async (actionName, Id) => {
    if (actionName == 'delete') {
      await deleteDomain(
        {
          domain: data.domain,
          dnsID: Id,
        },
        mutationProps(() => {}, {
          onSuccess: async () => {
            await refetch();
          },
        }),
      );
    }
  };

  return (
    <SettingsSlidingPanel
      openPanel={isOpen}
      setOpenPanel={() => {
        isClose();
        setIsShow('all');
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Styles.ConfigurationPanel>
          <Styles.Header>
            <Styles.DomainName>
              <i className="fa fa-circle" />
              <H5>{data?.domain}</H5>
            </Styles.DomainName>
            <Styles.CrossIcon>
              <Icon size="large" name="close" onClick={() => isClose(false)} />
            </Styles.CrossIcon>
          </Styles.Header>
          <Styles.Step1>
            <Styles.Start>
              <Styles.Options>
                {recordsMenuOptions.map((menuOption) => (
                  <Styles.Option
                    menuId={menuOption.value}
                    isShow={isShow}
                    onClick={() => setIsShow(menuOption.value)}
                    key={menuOption.value}
                  >
                    {t(menuOption.lang)}
                    {isShow == menuOption.value && <img className="tricut-image" src={TriCut} />}
                  </Styles.Option>
                ))}
              </Styles.Options>
              <Styles.OptionContent>
                {isShow == 'all' ? (
                  <P>{t('dns/instance.string24')}</P>
                ) : isShow == 'a' ? (
                  <AForm
                    ipData={ipData}
                    loading={loadingRescords}
                    handleAddRecord={(values, resetForm) => handleAddRecord(values, resetForm)}
                  />
                ) : isShow == 'cname' ? (
                  <CNameForm
                    loading={loadingRescords}
                    handleAddRecord={(values, resetForm) => handleAddRecord(values, resetForm)}
                  />
                ) : isShow == 'mx' ? (
                  <MxForm
                    loading={loadingRescords}
                    handleAddRecord={(values, resetForm) => handleAddRecord(values, resetForm)}
                  />
                ) : isShow == 'ns' ? (
                  <NsForm
                    loading={loadingRescords}
                    handleAddRecord={(values, resetForm) => handleAddRecord(values, resetForm)}
                  />
                ) : isShow == 'txt' ? (
                  <TxtForm
                    loading={loadingRescords}
                    handleAddRecord={(values, resetForm) => handleAddRecord(values, resetForm, true)}
                  />
                ) : null}
              </Styles.OptionContent>
            </Styles.Start>
          </Styles.Step1>
          {isShow == 'a' ? (
            <ARecordsList
              data={data}
              handleAction={handleAction}
              deleteLoading={deleteLoading}
              isFetching={isFetching}
            />
          ) : isShow == 'cname' ? (
            <CNameRecordsList
              data={data}
              handleAction={handleAction}
              deleteLoading={deleteLoading}
              isFetching={isFetching}
            />
          ) : isShow == 'mx' ? (
            <MxRecordsList
              data={data}
              handleAction={handleAction}
              deleteLoading={deleteLoading}
              isFetching={isFetching}
            />
          ) : isShow == 'ns' ? (
            <NsRecordsList
              data={data}
              handleAction={handleAction}
              deleteLoading={deleteLoading}
              isFetching={isFetching}
            />
          ) : isShow == 'all' ? (
            <AllRecordsList
              data={data}
              handleAction={handleAction}
              deleteLoading={deleteLoading}
              isFetching={isFetching}
            />
          ) : isShow == 'txt' ? (
            <TxtRecordsList
              data={data}
              handleAction={handleAction}
              deleteLoading={deleteLoading}
              isFetching={isFetching}
            />
          ) : null}
        </Styles.ConfigurationPanel>
      )}
    </SettingsSlidingPanel>
  );
};
export default ConfigurationDNS;
