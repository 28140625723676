import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const BM_OS = async () => {
  const res = await axios.get(BASE_URL + `/bm/os`);
  return res.data;
};
export const BM_OS_PRICES = async () => {
  const res = await axios.get(BASE_URL + `/prices/bm/os`);
  return res.data;
};


