import priceCalculator from './price-calculator';

export default (price, currency, noPreCurrency, notRound) => {
  if (currency === 'usd') {
    if (noPreCurrency) {
      return priceCalculator(price);
    }
    else if(notRound){
      return `$${price} `;
    }
    return `USD ${priceCalculator(price)}`;
  }
  return '';
};
