export const fetchedOptions = [
  { value: 1, text: 'x1' },
  { value: 2, text: 'x2' },
  { value: 3, text: 'x3' },
  { value: 4, text: 'x4' },
  { value: 5, text: 'x5' },
  { value: 6, text: 'x6' },
  { value: 7, text: 'x7' },
  { value: 8, text: 'x8' },
  { value: 9, text: 'x9' },
  { value: 10, text: 'x10' },
  { value: 11, text: 'x11' },
  { value: 12, text: 'x12' },
  { value: 13, text: 'x13' },
  { value: 14, text: 'x14' },
];
export const SLIDER = 'slider';
