/* eslint-disable no-irregular-whitespace */
import { keyframes } from 'styled-components';
import styled from 'styled-components';

const delete_animation = keyframes`
    from{
        background-size: 100%;
    }
    to {
      background-size: 0%;
    }
`;
export const Delete = styled.p`
  width: 100%;
  cursor: pointer;
  &:hover{
    background: ${(prop) =>
      prop.anim ? 'linear-gradient( white , white) rgb(255,215,235) no-repeat 100% 0' : 'rgba(0,0,0,.06)'}
  }
  mix-blend-mode: multiply;
  background-size: '100% 100%';
  animation-name: ${(prop) => (prop.anim ? delete_animation : '')};
  animation-duration: 2s;
`;
