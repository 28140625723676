import { CORE, DISK, RAM } from 'components/vm-deployment/constants/step1';
import { mbToGb } from 'utils';

export const sliderItemQuantity = (id, quantity) => {
  if (id === RAM) {
    return mbToGb(quantity).toFixed(2);
  }
  if (id === DISK) {
    return quantity;
  }
  if (id === CORE) {
    return quantity;
  }
};
