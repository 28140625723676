/* eslint-disable no-unused-vars */
import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Message } from 'components/alert/Message';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateUserAccount, updateUserUi } from 'store/actions/user-details';
import { updatePricingDetails } from 'store/actions/vm-deployment';
import { get_countries } from './countries';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { noRefetch } from 'constants/index';
import moment from 'moment';
import { vm_year } from 'utils';
import { UNAUTHORIZE_USER } from 'components/user-management/queries';

const Hooks = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:959px)');
  const history = useHistory();
  const credit_card_info = useSelector((state) => state.user.billing_info);
  const userAccount = useSelector((state) => state.user.account);
  const userUi = useSelector((state) => state.user.ui);
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:450px)');
  let textboxWidth = matches ? { width: '95%' } : { width: '16.5rem' };
  const matches_2 = useMediaQuery('(max-width:768px)');
  let width = matches_2 ? '100%' : '30rem';
  const textBoxStyling = {
    marginRight: '1rem',
    width: width,
  };
  let params = queryString.parse(history?.location?.search);
  const per_page = userUi.show_records_per_page;
  const per_page_query = (page) => `per_page=${page || per_page}`;
  const defaultCard =
    credit_card_info.billing_default_credit_card_exp && credit_card_info.billing_default_credit_card_last_four;
  const countries = () => {
    const { data: countries } = useQuery('/uicountries', {
      ...noRefetch,
    });
    return get_countries(countries);
  };

  return {
    mutationProps: (resetForm, props) => mutationProps(resetForm, props, t),
    saveUTMParamsToCookies,
    getCountries: countries,
    fixedTextboxWidth: textboxWidth,
    textBoxStyling,
    logout: async (queryClient, isRoute = true) => {
      try {
        await UNAUTHORIZE_USER();
        dispatch(updateUserAccount({}));
        dispatch(updatePricingDetails({}));
        dispatch(updateUserUi({}));
        localStorage.removeItem('userName');
        if (queryClient) queryClient.clear();
        // if (isRoute) location.replace(window.origin);
      } catch (error) {
        console.log('error::', error);
      }
    },
    per_page,
    per_page_query,
    paginationToUrl: (url, page_no, sort_by, per_page) => {
      let query = `?page_number=${page_no}&${per_page_query(per_page)}`;
      if (sort_by) {
        query += `&sort_by=${sort_by}`;
      }
      if (query !== history.location.search) {
        history.push(`${url}${query}`);
      }
    },
    scrollDownToId: (id) => {
      const elem = document.getElementById(id);
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    },
    getParams: params,
    defaultCard,
    chargeCardParams: (values, amountToCharge) => {
      return {
        number: values.card_number,
        expmonth: ('0' + values.card_month).slice(-2),
        expyear: vm_year(values.card_year),
        cvv: values.cvv,
        name: values.card_name,
        phone: values.phone.replace(/\D/g, ''),
        organization: values.company,
        address1: values.billing_address,
        address2: values.address_continue,
        city: values.city,
        state: values.state,
        zipcode: values.zip,
        country: values.country,
        bank: values.bank_name,
        bankphone: values.bank_phone_number.replace(/\D/g, ''),
        currency: userAccount.account_currency,
        amount_to_charge: amountToCharge,
        make_default: '1',
      };
    },
    isCard: (values) => {
      return values.options_prepaid.minimum_payment != '0';
    },
    handleCreationSuccess: () => {
      toast.dismiss();
      Message.success(t('alert13'), t('alert12'));
      Message.success(t('alert14'), t('alert15'));
    },
    handleBmCreationSuccess: () => {
      toast.dismiss();
      Message.success(t('alert11'), t('string7'));
    },
    refreshTime: Number(userUi.refresh_every_x_seconds) * 1000,
    isNewInstance: (completeTime) => {
      if (completeTime) {
        return moment(completeTime).add(+userUi.show_new_vs_hours, 'hours').isAfter(userAccount.server_timedate_pst);
      }
      return false;
    },
    isMobile,
  };
};

export const CustomHook = Hooks;
const getUTMParamsFromURL = (params) => {
  const utmParams = {};
  utmParams.utm_campaign = params['utm_campaign'];
  utmParams.utm_medium = params['utm_medium'];
  utmParams.utm_source = params['utm_source'];
  utmParams.utm_term = params['utm_term'];
  utmParams.utm_content = params['utm_content'];
  utmParams.utm_landing = params['utm_landing'];
  utmParams.affiliate_id = params['affiliate_id'];
  utmParams.refid = params['refid'];
  utmParams.landing_url = params['landing_url'];
  utmParams.firstdate = params['firstdate'];
  utmParams.lastdate = params['lastdate'];
  utmParams.referrer = params['referrer'];

  return utmParams;
};
export const getCookie = (name) => {
  let cookieName = name + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let singleCookie = cookieArray[i];
    while (singleCookie.charAt(0) == ' ') {
      singleCookie = singleCookie.substring(1);
    }
    if (singleCookie.indexOf(cookieName) == 0) {
      return singleCookie.substring(cookieName.length, singleCookie.length);
    }
  }
  return '';
};

export const saveUTMParamsToCookies = (params) => {
  const utmParams = getUTMParamsFromURL(params);
  Object.keys(utmParams).forEach((key) => {
    const value = utmParams[key];
    const isCookieAlreadySaved = getCookie(key);
    if (value && !isCookieAlreadySaved) document.cookie = `${key}=${value}; path=/`;
  });
};
export const mutationProps = (resetForm, props, t) => {
  const { onSuccess, isSuccess = true, errorHeading = '', error, onError } = props || {};
  return {
    onSuccess: (res) => {
      if (onSuccess) {
        onSuccess(res);
      }
      if (isSuccess) Message.success(t(`${res.status}`), t('success'));
      if (resetForm) resetForm();
    },
    onError: (err) => {
      if (onError) {
        onError(err);
      }
      Message.error(t(`${err.response.data.error || err.response.data.status}`), error ? t(error) : errorHeading);
    },
  };
};
