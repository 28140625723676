import styled from 'styled-components';

export const Heading = styled.div`
  display: flex;
  width: ${({ small }) => (small ? '100%' : '95%')};
  .icon-circle {
    font-size: 12px;
    margin-top: 5px;
    margin-right: 5px;
  }
  i {
    font-size: ${({ small }) => (small ? '20px' : '40px')};
  }
  h2 {
    width: 100%;
    font-weight: 900;
    padding-top: 10px;
    margin: 0;
  }
  h4 {
    width: 100%;
    font-weight: 900;
    margin: 0;
  }
  h6 {
    width: 100%;
    font-weight: bold;
    margin: 0;
    padding-top: 5px;
  }
`;

export const Content = styled.div`
  padding-left: 3.8em;
  @media only screen and (max-width: 768px) {
    padding-left: 3.6em;
  }
`;

export const ContentSubHeading = styled.div`
  padding-left: 3em;
`;

export const SubHeading = styled.div`
  display: flex;
  width: 100%;
  margin-left: 1em;
  .icon-circle {
    font-size: 12px;
    margin-right: 1em;
    margin-top: 6px;
  }
  h4 {
    width: 100%;
    font-weight: 900;
    margin: 0;
  }
`;
