import styled from 'styled-components';
import BackgroundImage from 'assets/images/BG_dark_Blue.svg';
import { Link } from 'react-router-dom';

export const Background = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  font-weight: 400 !important;
  font-size: 16px !important;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LanguageContainer = styled.div`
  display: flex;
  align-items: cenetr;
  justify-content: center;
  margin-top: 1.5em;
`;

export const SignUpContainer = styled.div`
  margin: 0 auto;
  padding: 1em;
  @media (max-width: 768px) {
    padding: 3em 0;
  }
`;

export const Heading = styled.p`
  font-size: 26px;
  padding: 0 8px;
  color: white;
`;

export const SignupContainer = styled.div`
  display: flex;
  margin-bottom: 2em;
  @media (max-width: 768px) {
    flex-flow: column wrap;
    align-items: center;
  }
`;
export const Form = styled.div`
  margin: 0 auto;
`;

export const SignupBox = styled.div`
  background: #fff;
  width: 380px;
  border-radius: 5px;
  //box-shadow: 0 0 24px 8px #d1d1d1;
  @media (max-width: 500px) {
    width: 350px;
  }
  @media (max-width: 400px) {
    width: 330px;
  }
  @media (max-width: 330px) {
    width: 290px;
  }
`;

export const Instruction = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding-left: 2em;
  @media (max-width: 768px) {
    padding-top: 1em;
    padding-left: 0;
  }
  @media (max-width: 400px) {
    padding-left: 1.5rem;
  }
`;

export const SubHeading = styled.h1`
  font-weight: bold;
  color: white;
  @media (max-width: 850px) {
    font-size: 30px;
  }
  @media (max-width: 375px) {
    font-size: 25px;
  }
`;

export const Uses = styled.div`
  margin: 1em;
  @media (max-width: 768px) {
    margin-left: 0em;
  }
`;

export const Doing = styled.div`
  margin: 1em;
  @media (max-width: 768px) {
    margin-left: 0em;
  }
`;

export const LinK = styled(Link)`
  text-decoration: underline;
  color: #ff7300;

  &:hover {
    text-decoration: underline;
    color: #ff7300;
  }
`;

export const SignupForm = styled.div`
  padding: 27px 30px;
  h2 {
    font-weight: bolder;
  }
  a {
    text-decoration: underline;
    color: #ff7300;

    &:hover {
      text-decoration: underline;
      color: #ff7300;
    }
  }
  @media (max-width: 500px) {
    padding: 27px 20px;
  }
`;

export const Condition = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  a {
    text-decoration: underline;
    color: #ff7300;
  }
  p {
    font-size: 13px;
    color: black;
  }
`;

export const Bottom = styled.div`
  margin-top: 15px;
  p {
    text-align: center;
    font-size: 13px;
    color: black;
  }
`;

export const Features = styled.div`
  flex: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
`;

export const Feature = styled.div`
  display: flex;
  line-height: 2em;
  font-size: 16px;
  font-weight: 500;
  color: white;
  i {
    color: ${(props) => props.theme.primaryColor};
    margin-right: 12px;
    margin-top: 1px;
  }
`;
