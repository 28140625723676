import React, { useEffect } from 'react';
import { Card, Heading, Card0 } from './WorldwideCloudRegion.style';
import { getRegionImage } from './util';
import { Grid } from '@material-ui/core';
import Checkbox from 'components/common/radio-button';
import { Message } from 'components/alert/Message';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const WorldwideCloudRegion = ({ regionsData, formikProp, categoryID, sizeData }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = formikProp;
  const userAccount = useSelector((state) => state.user.account);
  const availableRegionOfHighFrequency = sizeData?.vs_sizes?.find((val) => val.category == 'highfrequency');
  const defaultValue = regionsData?.regions.find((item) => item?.id === userAccount?.vs_last_region_selected)?.id;

  const handleScrolldown = (id) => {
    const elem = document.getElementById('ST3');
    setFieldValue('region', id);
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    setFieldValue('region', defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    let availRegion = availableRegionOfHighFrequency?.availableregions?.includes(defaultValue);
    if (categoryID == 'highfrequency' && availRegion) {
      setFieldValue('region', defaultValue);
    } else if (categoryID == 'highfrequency' && !availRegion) {
      setFieldValue('region', '');
    }
  }, [categoryID]);

  const showMessage = () => Message.error(t('alert3'), t('alert5'));

  const showMessageForHighFrequency = () => Message.error(t('alert4'), t('alert5'));

  return (
    <Grid container spacing={0} className="mb-5">
      {regionsData?.regions.map((region) => {
        let availRegion = availableRegionOfHighFrequency?.availableregions?.includes(region.id);
        return (
          <Grid item key={region.id} xs={12} sm={6} md={4} lg={4} xl={3}>
            {region.new_deployments_allowed == 0 || (categoryID == 'highfrequency' && !availRegion) ? (
              <Card0
                onClick={categoryID == 'highfrequency' && !availRegion ? showMessageForHighFrequency : showMessage}
              >
                <Checkbox
                  data={region}
                  setCheckedValue={
                    categoryID == 'highfrequency' && !availRegion ? showMessageForHighFrequency : showMessage
                  }
                />
                <div>
                  <img
                    style={{ border: '0.5px solid #C5C5C5' }}
                    src={getRegionImage(region?.country)}
                    alt="flag Image"
                  />
                </div>
                <Heading>
                  {region.city}, {region.state}
                </Heading>
              </Card0>
            ) : (
              <Card onClick={() => handleScrolldown(region.id)}>
                <Checkbox
                  data={region}
                  setCheckedValue={(value) => setFieldValue(value)}
                  checkbox={values.region}
                  onClick={() => handleScrolldown(region.id)}
                />
                <div>
                  <img
                    style={{ border: '0.5px solid #C5C5C5' }}
                    src={getRegionImage(region.country)}
                    alt="flag Image"
                  />
                </div>
                <Heading>
                  {region.city}, {region.state}
                </Heading>
              </Card>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};
export default WorldwideCloudRegion;
