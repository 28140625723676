import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PriceCal, MainCon } from './PriceCalculator.style';
import { H4, H5 } from 'components/reusable-css';
import { UpperCaseWord } from 'assets/css/common-styles';

const PriceCalculator = ({ values, totalPrice, yearlyTotalPrice }) => {
  const { t } = useTranslation();
  
  return (
    <MainCon>
      <PriceCal>
        <H5> {t('public/checkout/vs.string1')}</H5>
        {values.selected_size?.id != undefined && values.monthly_sizePrice == 0 ? null : (
          <H4>
            <Trans
              i18nKey={'public/checkout/vs.string5'}
              values={{ price: totalPrice || '$0', currency: 'USD' }}
              components={{ uppercase: <UpperCaseWord /> }}
            >
              <span />
            </Trans>
          </H4>
        )}
        <H4>
          <Trans
            i18nKey={'public/checkout/vs.string6'}
            values={{ price: yearlyTotalPrice || '$0', currency: 'USD' }}
            components={{ uppercase: <UpperCaseWord /> }}
          >
            <span />
          </Trans>
        </H4>
      </PriceCal>
    </MainCon>
  );
};

export default PriceCalculator;
