import React from 'react';
import { managerVmSortBy } from 'constants/manager-vm';
import USAFlag from 'assets/images/flag-usa.svg';
import NetherlandFlag from 'assets/images/flag-netherlands.svg';
import SingaporeFlag from 'assets/images/flag-singapore.svg';
import { Trans, useTranslation } from 'react-i18next';
import { get } from 'lodash';
import * as Yup from 'yup';
import priceCalculator from 'utils/price-calculator';
import { vsPriceOnly, hoursTotalPrice, totalPrice } from 'components/vm-deployment/util';
import { OFF, ON, REBOOT } from 'constants/index';
import { sliders } from 'constants/initial-values';
import moment from 'moment';
import { CORE, DISK, RAM } from 'components/vm-deployment/constants';
import { mbToGb } from 'utils';
import USA from 'assets/images/icons/usa-flag.svg';
import Singapore from 'assets/images/icons/signapore-flag.svg';
import Netherland from 'assets/images/icons/netherland-flag.svg';
import { getCurrentYear } from 'utils/current-year';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';

//BLACK AND WHITE IMAGES OF FLAGS FOR VS PENDING TO DESTROY
export const getBlackCountryImage = (name, second) => {
  switch (name) {
    case 'us':
      return USA;
    case 'sg':
      return Singapore;
    case 'nl':
      return Netherland;
  }
  switch (second) {
    case 'DAL1-1':
      return USA;
    case 'LAS1-1':
      return USA;
    default:
      return '';
  }
};

export const networkPricing = (price) => {
  let cond = price?.price_discounted_monthly_usd ? price?.price_discounted_monthly_usd : price?.price_monthly_usd;
  return {
    isMonthly: Number(cond) > 0 ? true : false,
    price_monthly: Number(cond) > 0 ? `${Number(cond).toFixed(2)}` : null,
  };
};

export const networkCapacity = (price) => {
  return price.capacity >= 1000 ? mbToGb(price.capacity).toFixed(0) : price.capacity;
};
export const disableStyles = { pointerEvents: 'none', opacity: 0.3 };

export const checkDeletedItems = (deletedIds, data) => {
  let Item = deletedIds.find((item) => item === data);
  if (Item) {
    return disableStyles;
  } else {
    return {};
  }
};

export const getCountryImage = (name, second) => {
  switch (name) {
    case 'us':
      return USAFlag;
    case 'sg':
      return SingaporeFlag;
    case 'nl':
      return NetherlandFlag;
  }
  switch (second) {
    case 'DAL1-1':
      return USAFlag;
    case 'LAS1-1':
      return USAFlag;
    default:
      return '';
  }
};

export const getCountry = (name) => {
  switch (name) {
    case 'united states':
      return USAFlag;
    case 'singapore':
      return SingaporeFlag;
    case 'netherlands':
      return NetherlandFlag;
    default:
      return '';
  }
};

export const fetchOptions = (meta = {}, user_per_page) => {
  let options = [];
  const ceil = Math.ceil(meta.total_all_records / user_per_page);
  for (let index = 1; index <= +(ceil || 1); index++) {
    // const value = 1 * index;
    const textCalculate = user_per_page * index;
    if (textCalculate > +meta.total_all_records || textCalculate >= 50) {
      const text = textCalculate > +meta.total_all_records || textCalculate > 50 ? 'All' : textCalculate;
      options = [...options, { value: +meta.total_all_records, text }];
      break;
    }
    // const text = textCalculate > +meta.total_all_records || textCalculate > 50 ? 'All' : textCalculate;
    options = [...options, { value: textCalculate, text: textCalculate }];
  }
  return options;
};

// OLD SELECT BEFORE LESS THAN 50 CONDITION

// export const fetchOptions = (meta = {}, user_per_page) => {
//   let options = [];
//   const ceil = Math.ceil(meta.total_all_records / user_per_page);
//   for (let index = 1; index <= +(ceil || 1); index++) {
//     // const value = 1 * index;
//     const textCalculate = user_per_page * index;
//     if (textCalculate > +meta.total_all_records || textCalculate >= 50) {
//       const text = textCalculate > +meta.total_all_records || textCalculate > 50 ? 'All' : textCalculate;
//       options = [...options, { value: +meta.total_all_records, text }];
//       break;
//     }
//     // const text = textCalculate > +meta.total_all_records || textCalculate > 50 ? 'All' : textCalculate;
//     options = [...options, { value: textCalculate, text: textCalculate }];
//   }
//   return options;
// };

export const bmDropDownItems =
  (rights) =>
  (
    openConfigurationModal,
    refreshServer,
    handleConsoleOpen,
    handleCloseMenu,
    setInitialTab,
    enableBackup,
    { toggleStats },
  ) => {
    const dropdownArray = [
      {
        id: 2,
        name: 'turn_on_server',
        icon: 'fa fa-play',
        lang: 'bm/instance.string13',
        onClick: () => refreshServer(ON),
        action: ON,
        show: rights.bmon,
      },
      {
        id: 3,
        name: 'turn_of_server',
        lang: 'bm/instance.string14',
        icon: 'fa fa-stop-circle-o',
        onClick: () => refreshServer(OFF),
        action: OFF,
        show: rights.bmoff,
      },
      {
        id: 4,
        name: 'reboot',
        lang: 'vs/instance.string45',
        icon: 'fa fa-refresh',
        onClick: () => refreshServer(REBOOT),
        action: REBOOT,
        show: rights.bmreboot,
      },
      {
        id: 6,
        name: 'statistics',
        lang: 'vs/instance.string48',
        icon: 'fa fa-bar-chart',
        onClick: () => {
          toggleStats();
          if (handleCloseMenu) handleCloseMenu();
        },
        show: true,
      },
      {
        id: 7,
        name: 'settings',
        lang: 'vs/instance.string49',
        icon: 'fa fa-cog',
        onClick: () => openConfigurationModal(true),
        show: true,
      },
    ];
    return dropdownArray.filter((res) => res.show);
  };

export const vmDropDownItems =
  (rights, refetchMenu) =>
  (
    openConfigurationModal,
    refreshServer,
    handleConsoleOpen,
    handleCloseMenu,
    setInitialTab,
    enableBackup,
    { toggleStats, upgradeOpen, resetPasswordOpen },
    data,
    handleActionsOpen,
  ) => {
    const dropdownArray = [
      {
        id: 0,
        name: 'upgrade',
        lang: 'vs/instance.string41',
        icon: 'fa fa-arrow-up',
        onClick: () => {
          if (upgradeOpen) upgradeOpen();
        },
        show: data?.billing_schedule == 'hourly',
      },
      { id: 1, name: 'enable_backup', lang: 'string42', icon: 'fa fa-cloud-upload', onClick: () => enableBackup() },
      {
        id: 2,
        name: 'turn_on_VM',
        lang: 'vs/instance.string43',
        tooltip: 'There will be no confirmation.',
        tooltip_lang: 'vs/instance.string53',
        icon: 'fa fa-play',
        onClick: () => refreshServer(ON),
        action: ON,
        show: rights.vson,
      },
      {
        id: 3,
        name: 'turn_of_VM',
        lang: 'vs/instance.string44',
        tooltip: 'There will be no confirmation.',
        tooltip_lang: 'vs/instance.string53',
        icon: 'fa fa-stop-circle-o',
        onClick: () => refreshServer(OFF),
        action: OFF,
        show: rights.vsoff,
      },
      {
        id: 4,
        name: 'reboot',
        lang: 'vs/instance.string45',
        icon: 'fa fa-refresh',
        tooltip: 'There will be no confirmation.',
        tooltip_lang: 'vs/instance.string53',
        onClick: () => refreshServer(REBOOT),
        action: REBOOT,
        show: rights.vsreboot,
      },
      {
        id: 9,
        name: 'reset_password',
        lang: 'vs/instance.string178',
        icon: 'fa fa-key',
        onClick: () => resetPasswordOpen(),
        show: true,
      },
      {
        id: 6,
        name: 'delete_VS',
        lang: 'vs/instance.string46',
        icon: 'fa fa-trash',
        onClick: async () => {
          await refreshServer('delete', refetchMenu);
        },
        show: rights.vsdelete && data?.billing_schedule == 'hourly',
      },
      {
        id: 6,
        name: 'cancel_VS',
        lang: 'vs/instance.string189',
        icon: 'fa fa-trash',
        onClick: () => handleActionsOpen(),
        show: rights.vsdelete && data?.billing_schedule != 'hourly',
      },
      {
        id: 5,
        name: 'open_console',
        lang: 'vs/instance.string47',
        icon: 'fa fa-keyboard-o',
        onClick: () => handleConsoleOpen(),
        show: rights.vskvm,
      },
      {
        id: 7,
        name: 'statistics',
        lang: 'vs/instance.string48',
        icon: 'fa fa-bar-chart',
        onClick: () => {
          toggleStats();
          if (handleCloseMenu) handleCloseMenu();
        },
        show: true,
      },
      {
        id: 8,
        name: 'settings',
        lang: 'vs/instance.string49',
        icon: 'fa fa-cog',
        onClick: () => {
          openConfigurationModal(true);
          if (setInitialTab) setInitialTab();
          if (handleCloseMenu) handleCloseMenu();
        },
        show: true,
      },
    ];
    return dropdownArray.filter((res) => res.show);
  };

export const backUpNewPrice = (backupData, formikProp) => {
  const percent = Number(get(backupData, 'price_percentage_of_virtual_server', '0'));
  let price = 0;
  if (percent) {
    price = (percent / 100) * +vsPriceOnly(formikProp.values.prices);
  }
  return priceCalculator(price, false);
};

export const reviewIncluded = (features, userAccount) => {
  let vsOptions = [];
  if (get(features, 'length')) {
    vsOptions = features.map((item) => {
      return {
        id: item.paid_option,
        name: (
          <Trans
            i18nKey={`vs/instance.${item.paid_option}`}
            values={{
              price: priceCalculator(item.price_monthly_usd, false),
              new_price: priceCalculator(item.new_price, false),
              count: +item.qty,
              currency_symbol: userAccount.account_currency_symbol,
            }}
          />
        ),
      };
    });
  }

  return vsOptions;
};

export const actionOptions = (type, { undoBm, undoVs }, data) => {
  const rights = useSelector(userRights);
  if (type === 'bm') {
    return [
      { id: 'reboot', lang: 'bm/instance.string25', show: rights.bmreboot },
      { id: 'power_on', lang: 'bm/instance.string26', show: rights.bmon },
      { id: 'power_off', lang: 'bm/instance.string27', show: rights.bmoff },
      {
        id: 'delete_bm',
        lang: undoBm ? 'bm/instance.string28' : 'bm/instance.string29',
        show: undoBm ? rights.bmundodelete : rights.bmdelete,
      },
    ];
  }
  return [
    {
      id: 'destroy_VM',
      lang: 'vs/instance.string100',
      show: rights.vsdelete && data?.billing_schedule == 'hourly',
    },
    {
      id: 'cancel_VM',
      lang: undoVs ? 'vs/instance.string191' : 'vs/instance.string188',
      show: rights.vsdelete && data?.billing_schedule != 'hourly',
    },
    { id: 'new_password', lang: 'vs/instance.string101', show: rights.vsresetpassword },
    { id: 'reinstall', lang: 'vs/instance.string102', show: rights.vsreinstall },
  ];
};

export const ipOptions = (howManyIps) => {
  let options = [];
  for (let index = 1; index <= +howManyIps; index++) {
    options = [
      ...options,
      {
        value: index,
        text: index > 1 ? 'vs/instance.string177' : 'vs/instance.string176',
      },
    ];
  }

  return [
    {
      options: [...options],
    },
  ];
};

export const upgradeInitialValues = {
  payment_method: '',
  size: '',
  country: 'US',
  card_month: 1,
  card_year: getCurrentYear(),
  prices: {
    size: 0,
  },
  sliders,
  nic_mbps: undefined,
};
export const templateInitialValues = {
  backup_plan: '',
};

export const updateValidationSchema = (schema = {}) =>
  Yup.object({
    size: Yup.object({
      id: Yup.string().required('Please select a size'),
    }).required('Please select a size'),
    ...schema,
  });

export const updateValidationSchemaWithSlider = (schema = {}) =>
  Yup.object({
    ...schema,
  });

export const ActionInitialValues = {
  user_show_password: false,
};

export const recordNumbers = (meta = {}) => {
  let end = +meta.per_page < +meta.total_all_records ? meta.per_page : meta.total_all_records;
  if (meta.page_number == 1) {
    return `1-${end}`;
  }
  const first = (meta.page_number - 1) * meta.per_page;
  const firstCalculate = first + +meta.per_page;
  end = firstCalculate > +meta.total_all_records ? +meta.total_all_records : firstCalculate;
  return `${first}-${end}`;
};

export const getSortTitle = (value) => {
  return managerVmSortBy.find((item) => item.value === value);
};
export let nextPageValidation = (vmData, queries) => Number(vmData?.meta?.how_many_pages) > Number(queries.page_number);
export let backPageValidation = (queries) => Number(queries?.page_number) > 1;

export const paidOptionsPrice = (data) =>
  data.reduce((acc, item) => (+item.new_price || +item.price_monthly_usd) + acc, 0);

export const hourlyPaidOptionsPrice = (data) =>
  data.reduce((acc, item) => (+item.new_price || +item.price_monthly_usd) / 672 + acc, 0);

export const upgradeServerNewPrice = (values, data) => {
  return {
    mo: priceCalculator(totalPrice(values.prices) + paidOptionsPrice(data), false),
    hourly: priceCalculator(hoursTotalPrice(values.prices) + hourlyPaidOptionsPrice(data), false),
  };
};

export const filteredOsForSelect = (os) =>
  get(os, 'vs_os', []).map((item) => {
    return { text: item.name, value: item.id };
  });

export const isInProgress = (network) => {
  return get(network, 'networks', []).some((item) => get(item, 'eth0', []).some((e) => e.ipv4.some((ip) => !ip.ip)));
};

export const ReverseDNSInitialValues = {};

export const bmActionsTabs = (data, { isCancellation }) => {
  const { t } = useTranslation();
  const rights = useSelector(userRights);
  return [
    {
      type: 'reboot',
      action: 'reboot',
      content: t('bm/instance.string30'),
      buttonText: t('bm/instance.string25'),
      show: rights.bmreboot,
    },
    {
      type: 'power_on',
      action: 'on',
      content: t('bm/instance.string32'),
      buttonText: t('bm/instance.string26'),
      show: rights.bmon,
    },
    {
      type: 'power_off',
      action: 'off',
      content: t('bm/instance.string33'),
      buttonText: t('bm/instance.string27'),
      show: rights.bmoff,
    },
    {
      type: 'delete_bm',
      action: isCancellation ? 'undodelete' : 'delete',
      content: isCancellation ? (
        <Trans
          i18nKey={'bm/instance.string34'}
          values={{
            date: moment(data.next_payment_date).format('ll'),
          }}
        />
      ) : (
        <Trans
          i18nKey={'bm/instance.string35'}
          values={{
            date: moment(data.next_payment_date).format('ll'),
          }}
        />
      ),
      content2: isCancellation ? '' : t('bm/instance.string36'),
      buttonText: isCancellation ? t('bm/instance.string37') : t('bm/instance.string29'),
      show: isCancellation ? rights.bmundodelete : rights.bmdelete,
    },
  ];
};

export const backupHeadingDate = (backup) => {
  const date = moment(backup.backup_date).calendar({
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
  });
  return date === 'Today' || date === 'Tomorrow' || date === 'Yesterday'
    ? date
    : `${moment().startOf('day').diff(moment(backup.backup_date), 'days')} days ago`;
};

export const isSliderBaseUpgradeDefaultForFeatures = (data, values) => {
  const defaultValues = getSliderDataValues(data);
  const slider = values.sliders;
  return (
    defaultValues[DISK] == slider[DISK] && defaultValues[RAM] == slider[RAM] && defaultValues[CORE] == slider[CORE]
  );
};

export const isSliderBaseUpgradeDefault = (data, values) => {
  const defaultValues = getSliderDataValues(data);
  const slider = values.sliders;
  return (
    defaultValues[DISK] == slider[DISK] &&
    defaultValues[RAM] == slider[RAM] &&
    defaultValues[CORE] == slider[CORE] &&
    data.nic_mbps == values.nic_mbps
  );
};

export const getSliderDataValues = (data) => {
  return {
    [DISK]: +get(data, 'disks[0].disk_gb', 0),
    [RAM]: +get(data, 'ram_mb', 0),
    [CORE]: +get(data, 'vcpus', 0),
  };
};

export const getUpdatedFeatures = (data, { isUpgraded, backupData, formikProp, osPrice }) => {
  return (data.paid_options_included || []).map((item) => {
    if (item.paid_option.includes('backup_plan') && isUpgraded) {
      return {
        new_price: backUpNewPrice(backupData, formikProp),
        isUpgraded,
        ...item,
        paid_option: 'string170',
      };
    }
    if (item.paid_option === 'package_win' && isUpgraded) {
      return {
        new_price: get(osPrice, 'price_per_size', []).find((item) => item.size === formikProp.values?.size?.id)
          ?.price_monthly_usd,
        isUpgraded,
        ...item,
        paid_option: data?.size == formikProp?.values?.size.id ? 'string197' : 'string171',
      };
    }
    return item;
  });
};

export const networkTypeName = (type) => (type === 'bm' ? 'bare metal server' : 'virtual server');
