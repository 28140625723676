import React from 'react';
import * as Styles from 'pages/member/changelog/Changelog.style';
import { H5, H6 } from 'components/reusable-css';

const VersionCard = ({ versions }) => {
  return (
    <React.Fragment>
      {versions.map((version, index) => (
        <Styles.VersionSC key={index}>
          <Styles.FlexColumn>
            <Styles.VersionName>
              <H5>{version.name}</H5>
            </Styles.VersionName>
            <Styles.VersionDate>
              <H6>{version.date}</H6>
            </Styles.VersionDate>
          </Styles.FlexColumn>
        </Styles.VersionSC>
      ))}
    </React.Fragment>
  );
};

export { VersionCard };
