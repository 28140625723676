import React from 'react';
import ConditionalFragment from 'components/conditional-fragment';
import { backPageValidation, fetchOptions, recordNumbers, nextPageValidation } from 'components/manage-vm/utils';
import { Trans } from 'react-i18next';
import { get } from 'lodash';
import hooks from 'hooks';
import Select from 'components/select';
import { Icon } from 'semantic-ui-react';
import { P } from 'components/reusable-css';

const ManagePagination = ({ pagination, vmData, queries, handleBackPage, handlePagination, handleNextPage }) => {
  const { per_page } = hooks();

  return (
    <ConditionalFragment condition={pagination}>
      <div>
        <P>
          <Trans
            i18nKey={'vs/instance.string21'}
            values={{
              count: recordNumbers(vmData?.meta),
              counts: get(vmData, 'meta.total_all_records', {}),
            }}
          />
        </P>
        <Icon
          onClick={handleBackPage}
          style={{
            cursor: 'pointer',
            marginRight: 15,
            color: backPageValidation(queries) ? 'orange' : 'grey',
          }}
          size="large"
          name="angle left"
        />
        <Select
          options={fetchOptions(vmData?.meta, +per_page)}
          onChange={handlePagination}
          value={queries.per_page}
          styling={{
            marginBottom: '15px',
            padding: '0 13px',
            border: '1px solid #b4b4b4',
            background: 'white',
            color: 'grey',
            fontSize: '13px',
            height: '35px',
          }}
        />
        <Icon
          onClick={handleNextPage}
          style={{
            marginLeft: 15,
            cursor: 'pointer',
            color: nextPageValidation(vmData, queries) ? 'orange' : 'grey',
          }}
          size="large"
          name="angle right"
        />
      </div>
    </ConditionalFragment>
  );
};

export { ManagePagination };
