import React from 'react';
import NewDomain from './NewDomain';
import { useQuery } from 'react-query';
import Loading from 'components/common/loading/Loading';

const NewDomainContainer = ({ style }) => {
  const { data: domainList, isLoading } = useQuery('/prices/domains/extensions');
  if (isLoading) {
    <Loading gap={200} />;
  }
  return <NewDomain style={style} domainList={domainList} />;
};
export default NewDomainContainer;
