import React from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import { Highlight, StepLabel } from 'components/vm-deployment/components';
import { Trans, useTranslation } from 'react-i18next';
import { reviewIncluded } from 'components/manage-vm/utils';
import { TickIcon } from 'components/icons';
import { P } from 'components/reusable-css';
import { useSelector } from 'react-redux';

const Features = ({ features, isSliderInterface }) => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  return (
    <Styles.Padding>
      <StepLabel stepCount={isSliderInterface ? t('vs/instance.string88') : t('vs/instance.string87')}>
        <Trans i18nKey="vs/instance.string89" components={{ highlight: <Highlight /> }} />
      </StepLabel>
      <Styles.Step3>
        <Styles.VSOption>
          <Styles.Flex>
            {reviewIncluded(features, userAccount).map((vsOption) => (
              <Styles.VSOptions key={vsOption.id}>
                <TickIcon />
                <P style={{ fontWeight: 'bold' }}>{vsOption.name}</P>
              </Styles.VSOptions>
            ))}
          </Styles.Flex>
        </Styles.VSOption>
      </Styles.Step3>
    </Styles.Padding>
  );
};

export { Features };
