import styled from 'styled-components';

export const TickIcon = styled.div`
  display: flex;
  i {
    margin-right: 2.1rem;
    color: #585858;
    cursor: pointer;
    color: ${(props) => props.theme.primaryDarkColor};
  }
  svg {
    margin-right: 2.1rem;
    color: #585858;
    cursor: pointer;
    color: ${(props) => props.theme.primaryDarkColor};
  }
`;
