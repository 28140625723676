import React from 'react';
import NewVMStep7 from './NewVMStep7';
import { useQuery } from 'react-query';
import _get from 'lodash/get';

const NewVMStep7Container = ({ formikProp }) => {
  const { values, setFieldValue } = formikProp;
  const { data: fundsData } = useQuery('/prices/vs/prepaid_options', {
    onSuccess: (res) => {
      setFieldValue('options_prepaid', _get(res, 'prices_vs_prepaid_options[0]', {}));
    },
  });

  const handleServerSizeSelection = (data) => {
    setFieldValue('options_prepaid', data);
  };

  return (
    <NewVMStep7
      formikProp={formikProp}
      fundsData={fundsData}
      selectedServerSize={values.options_prepaid}
      onServerSizeSelection={handleServerSizeSelection}
    />
  );
};
export default NewVMStep7Container;
