import React from 'react';
import { BgLogin, LoginBox, LoginForm, Logo, LoginContainer } from './styles';
import { H1, P } from 'components/reusable-css';
import { Icon } from 'semantic-ui-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

const AccountEmailVerify = () => {
  const { t } = useTranslation();
  let str = location?.hash?.split('account/email/verify/')[1];
  let url = str == undefined ? '/account/email/verify' : `/account/email/verify/${str}`;
  const { isError } = useQuery(url);

  return (
    <BgLogin>
      <LoginContainer>
        <LoginBox>
          <LoginForm>
            <Logo>
              <Icon name="check circle" />
            </Logo>
            {isError ? (
              <>
                <H1>{t('account/email/verify.string3')}</H1>
                <P>{t('account/email/verify.string4')}</P>
                <P>{t('account/email/verify.string5')}</P>
              </>
            ) : (
              <>
                <H1>{t('account/email/verify.string1')}</H1>
                <P>{t('account/email/verify.string2')}</P>
              </>
            )}
          </LoginForm>
        </LoginBox>
      </LoginContainer>
    </BgLogin>
  );
};

export default AccountEmailVerify;
