import React from 'react';
import * as Styles from 'pages/member/dns/DNS.style';
import { H5, H6 } from 'components/reusable-css';
import SettingDropdown from '../SettingDropdown';
import { recordsListdropdown } from '../util';
import { useTranslation } from 'react-i18next';

const AllRecordsList = ({ data, handleAction, deleteLoading, isFetching }) => {
  let { a, cname, mx, ns } = data;
  const { t } = useTranslation();
  return (
    <Styles.Step2>
      {a.length > 0 || cname.length > 0 || mx.length > 0 || ns.length > 0 ? (
        <>
          <Styles.RecordTableHeader>
            <Styles.Record1>
              <H6>{t('dns/instance.string32')}</H6>
            </Styles.Record1>
            <Styles.Record2>
              <H6>{t('dns/instance.string31')}</H6>
            </Styles.Record2>
            <Styles.Record3>
              <H6>{t('dns/instance.string45')}</H6>
            </Styles.Record3>
            <Styles.Record4>
              <H6>{t('dns/instance.string43')}</H6>
            </Styles.Record4>
            <Styles.Record5 />
          </Styles.RecordTableHeader>
          {Object.keys(data).map((key) => (
            <>
              {key == 'a' || key == 'mx' || key == 'ns' || key == 'cname' || key == 'txt' ? (
                <>
                  {data[key]?.map((item, i) => (
                    <Styles.ListContainer loading={deleteLoading || isFetching} key={i}>
                      <Styles.RecordTable>
                        <Styles.Record1>
                          <i className="fa fa-circle" />
                          {item.name == '' && key == 'a' ? (
                            <Styles.DefaultName>{t('dns/instance.string36')}</Styles.DefaultName>
                          ) : item.name == '' && key == 'cname' ? (
                            <Styles.DefaultName>{t('dns/instance.string42')}</Styles.DefaultName>
                          ) : (
                            <H5>{key == 'txt' ? atob(item.name) : item.name}</H5>
                          )}
                        </Styles.Record1>
                        <Styles.Record2>
                          <H6>{key.toUpperCase()}</H6>
                        </Styles.Record2>
                        <Styles.Record3>
                          <H6>{key == 'txt' ? atob(item.value) : item.value}</H6>
                        </Styles.Record3>
                        <Styles.Record4>
                          <H6>{item.priority}</H6>
                        </Styles.Record4>
                        <Styles.Record5>
                          <SettingDropdown
                            dropdownItems={recordsListdropdown}
                            handleAction={(action) => handleAction(action, item.id)}
                          />
                        </Styles.Record5>
                      </Styles.RecordTable>
                    </Styles.ListContainer>
                  ))}
                </>
              ) : null}
            </>
          ))}
        </>
      ) : null}
    </Styles.Step2>
  );
};
export default AllRecordsList;
