import React, { useEffect } from 'react';
import NewVMStep1 from './NewVMStep1';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateVM_Deployment, updatePricing } from 'store/actions/vm-deployment';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Message } from 'components/alert/Message';
export default function NewVMStep1Container({
  serverSizes,
  selectedServerSize,
  setSelectedServerSize,
  formikProp,
  sliderData,
  sliderSuccess,
  sliderSizeData,
  isShowPriceButton,
  vsTemplate,
  OsData,
  setCategoryID,
  categoryID,
}) {
  const { setFieldValue, values } = formikProp;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const findTemplate = _get(vsTemplate, 'vs_saved_templates', []).find((item) => item.id == values.template);

  const { size_in_gb } = findTemplate || {};

  const handleServerSizeSelection = (data) => {
    if (+size_in_gb > +data?.disk_gb) {
      return Message.error(t('alert16'), t('alert17'));
    }
    if (Number(data.memory_mb) < Number(values.image_object?.min_ram_mb)) {
      return Message.error(t('alert20'), t('alert5'));
    } else if (Number(data.disk_gb) < Number(values.image_object?.min_disk_gb)) {
      return Message.error(t('alert21'), t('alert5'));
    }
    dispatch(updatePricing(data.price_monthly_usd, 'size'));
    setSelectedServerSize(data);
    dispatch(updateVM_Deployment({ server_size: data }));

    const elem = document.getElementById('ST2');
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (selectedServerSize) {
      setFieldValue('size_object', selectedServerSize);
      setFieldValue('size', selectedServerSize.id);
    }
  }, [selectedServerSize]);

  return (
    <NewVMStep1
      serverSizes={serverSizes}
      selectedServerSize={selectedServerSize}
      onServerSizeSelection={handleServerSizeSelection}
      formikProp={formikProp}
      sliderData={sliderData}
      sliderSuccess={sliderSuccess}
      sliderSizeData={sliderSizeData}
      isShowPriceButton={isShowPriceButton}
      vsTemplate={vsTemplate}
      OsData={OsData}
      categoryID={categoryID}
      setCategoryID={setCategoryID}
    />
  );
}

NewVMStep1Container.propTypes = {
  serverSizes: PropTypes.array,
  selectedServerSize: PropTypes.object,
  setSelectedServerSize: PropTypes.func,
  formikProp: PropTypes.object,
  sliderData: PropTypes.object,
  sliderSuccess: PropTypes.bool,
  sliderSizeData: PropTypes.array,
  isShowPriceButton: PropTypes.bool,
  vsTemplate: PropTypes.object,
  OsData: PropTypes.object,
};
