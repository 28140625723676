export const planlevel0 = [
  {
    id: 1,
    lanRef: 'step4_string10',
  },
  {
    id: 2,
    lanRef: 'step4_string11',
  },
  {
    id: 3,
    lanRef: 'step4_string12',
  },
  {
    id: 4,
    lanRef: 'step4_string13',
  },
  {
    id: 5,
    lanRef: 'step4_string14',
  },
];

export const planlevel1 = [
  {
    id: 1,
    lanRef: 'step4_string15',
  },
  {
    id: 2,
    lanRef: 'step4_string16',
  },
  {
    id: 3,
    lanRef: 'step4_string17',
  },
  {
    id: 4,
    lanRef: 'step4_string18',
  },
  {
    id: 5,
    lanRef: 'step4_string19',
  },
  {
    id: 6,
    lanRef: 'step4_string20',
  },
  {
    id: 7,
    lanRef: 'step4_string21',
  },
  {
    id: 8,
    lanRef: 'step4_string22',
  },
  {
    id: 9,
    lanRef: 'step4_string23',
  },
];

export const planlevel2 = [
  {
    id: 1,
    lanRef: 'step4_string24',
  },
  {
    id: 2,
    lanRef: 'step4_string25',
  },
  {
    id: 3,
    lanRef: 'step4_string26',
  },
  {
    id: 4,
    lanRef: 'step4_string27',
  },
  {
    id: 5,
    lanRef: 'step4_string28',
  },
  {
    id: 6,
    lanRef: 'step4_string23',
  },
];