import React from 'react';
import { Form } from 'formik';
import * as Styles from 'pages/member/user-management/UserManagement.style';
import { add_new_user } from 'constants/user-management';
import { Grid } from '@material-ui/core';
import classes from 'assets/css/payment-form.module.css';
import Input from 'components/input';
import hooks from 'hooks';
import { roles } from './util';
import Select from 'components/select';
import CustomButton from 'components/common/custom-button';

import Spinner from 'components/spinner';
import ErrorMessage from 'components/common/red-notification';
import { Label } from 'assets/css/common-styles';
import { P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const UserSubmissionForm = ({ style, formikProps, user, loading, isUserExist, checkUserLoading }) => {
  const { t } = useTranslation();
  const { fixedTextboxWidth } = hooks();
  const { values, handleChange, handleBlur, errors, touched, isSubmitting } = formikProps;

  return (
    <>
      <Form style={style}>
        <Styles.FormDiv>
          {add_new_user.map((user) => (
            <Grid key={user.name} container>
              <Grid xs={12} sm={4} lg={3} xl={2}>
                <Label>{t(user.label_lang)}</Label>
              </Grid>
              <Grid xs={12} sm={7} md={6} lg={5} xl={3}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Input
                    styling={fixedTextboxWidth}
                    autoComplete="new-password"
                    type={user.type}
                    className="custom_inputs"
                    name={user.name}
                    value={user.name == 'username' ? values[user.name].toLowerCase() : values[user.name]}
                    placeholder={t(user.placeholder_lang)}
                    onChange={handleChange}
                    {...(user.name != 'username' && { onBlur: handleBlur })}
                    error={errors[user.name]}
                    touched={touched[user.name]}
                    validateBorderColor={user.name == 'username'}
                    validateBorderCondition={isUserExist}
                    validateLoading={checkUserLoading}
                  />
                  {user.name == 'username' && checkUserLoading && (
                    <div style={{ marginTop: '1.5rem', marginLeft: '1rem' }}>
                      <Spinner color="orange" />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          ))}
          <Grid className={classes.main} container spacing={0}>
            <Grid xs={12} sm={4} lg={3} xl={2}>
              <Label>{t('account/usermgmt.string36')}</Label>
            </Grid>
            <Grid xs={12} sm={7} md={6} lg={5} xl={3}>
              <Select
                styling={fixedTextboxWidth}
                options={roles(user?.role_rights || [])}
                name={'role'}
                value={values['role']}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors['role']}
                touched={touched['role']}
              />
            </Grid>
          </Grid>
        </Styles.FormDiv>
        <CustomButton
          disabled={
            !values.confirm_password ||
            !values.new_user_password ||
            !values.username ||
            errors.username ||
            errors.confirm_password ||
            errors.new_user_password
          }
          isLoading={isSubmitting || loading}
          type="submit"
          buttonStyles={{ padding: ' 9px 55px' }}
        />
      </Form>
      {!user?.role_rights && (
        <div style={{ marginTop: '3em' }}>
          <ErrorMessage bgColor="#82bf50">
            <P style={{ padding: '0px' }}>{t('account/usermgmt.string37')}</P>
          </ErrorMessage>
        </div>
      )}
    </>
  );
};

export default UserSubmissionForm;
