import Loading from 'components/common/loading/Loading';
import React from 'react';
import { useQuery } from 'react-query';
import AllIssue from './IssuesList';

const AllIssueContainer = ({ style }) => {
  const { data, isLoading } = useQuery('/tickets');
  const { data: ticketsStatus } = useQuery('/tickets/substatus/definitions');
  const { data: ticketsDepartment } = useQuery('/tickets/department/definitions');

  if (isLoading) {
    return <Loading gap={200} />;
  }

  return <AllIssue style={style} data={data} ticketsStatus={ticketsStatus} ticketsDepartment={ticketsDepartment} />;
};

export default AllIssueContainer;
