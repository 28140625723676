import React, { useState } from 'react';
import * as Styles from './MobileTemplate.style';
import CustomButton from 'components/common/custom-button';
import Image from 'assets/images/backup_name.png';
import { Icon } from 'semantic-ui-react';
import { backupHeadingDate } from 'components/manage-vm/utils';
import moment from 'moment';
import ConditionalFragment from 'components/conditional-fragment';
import { RestoreConfirmationModal } from '../components/RestoreConfirmationModal';
import { H6, P } from 'components/reusable-css';
import { Trans, useTranslation } from 'react-i18next';

const MobileTemplate = ({
  backupData,
  isPermanent,
  backupDelete,
  backupDeleteLoad,
  restoreBackup,
  restoreBackupLoad,
  rights,
  setIsOpenConfirmationWarning,
  isOpenConfirmationWarning,
}) => {
  const { t } = useTranslation();
  const [restoreId, setRestoreId] = useState();

  return (
    <Styles.MobileBackupCard>
      {backupData.map((backup) => (
        <Styles.WithBackupCard key={backup.id}>
          <div>
            <div className="d-flex align-center">
              <div>
                <img src={Image} />
              </div>
              <div>
                <P className="pl-1 pt-1 text-capitalize">{backupHeadingDate(backup)}</P>
              </div>
            </div>

            <Styles.SidePadding>
              <div className="mt-3 mb-3">
                <H6> {t('vs/instance.string153')} :</H6>
                <P className="mt-2">
                  <Trans
                    i18nKey={'vs/instance.string159'}
                    values={{ date: moment(backup.backup_date).format('LL LTS').toLocaleString() }}
                  />
                </P>
              </div>
              {isPermanent && (
                <div className="mb-3">
                  <H6> {t('vs/instance.string155')} :</H6>
                  <P className="mt-2 text-capitalize">
                    {backup.label}{' '}
                    <ConditionalFragment condition={rights.vsbackuprename}>
                      <Icon name="pencil" />
                    </ConditionalFragment>
                  </P>
                </div>
              )}
            </Styles.SidePadding>
          </div>

          <div>
            <Styles.SidePadding>
              <div className="d-flex align-center">
                <H6>{t('vs/instance.string154')} :</H6>
                <P className="ml-2 text-capitalize">{backup.status}</P>
              </div>
              <div className="d-flex mt-3 flex-wrap">
                <ConditionalFragment condition={isPermanent && rights.vsbackupdelete}>
                  <CustomButton
                    title="vs/instance.string116"
                    buttonStyles={{ marginRight: '5px', marginBottom: '5px', padding: '5px 22px' }}
                    onClick={() => backupDelete(backup.id)}
                    isLoading={backupDeleteLoad}
                  />
                </ConditionalFragment>
                <ConditionalFragment condition={rights?.vsbackuprestore}>
                  <CustomButton
                    title="vs/instance.string156"
                    onClick={async () => {
                      setRestoreId(backup.id);
                      setIsOpenConfirmationWarning(true);
                    }}
                  />
                </ConditionalFragment>
                <RestoreConfirmationModal
                  open={isOpenConfirmationWarning}
                  loading={restoreBackupLoad}
                  toggle={() => setIsOpenConfirmationWarning(false)}
                  handleSubmit={async () => await restoreBackup(restoreId)}
                />
              </div>
            </Styles.SidePadding>
          </div>
        </Styles.WithBackupCard>
      ))}
    </Styles.MobileBackupCard>
  );
};
export default MobileTemplate;
