import React from 'react';
import NewVS from './NewVS';
import { initialValues, totalPrice, validationSchema, yearlyTotalPrice } from './util';
import { Formik } from 'formik';
import { addVS_CART } from 'store/actions/vs-shopping-cart';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorageValues } from 'constants/local-storage';

const NewVSContainer = () => {
  const dispatch = useDispatch();
  const { cart_selected_region } = getLocalStorageValues();
  const cartVs = useSelector((state) => state.vsCart.vs_deploy);
  const handleSubmit = (values, setSubmitting) => {
    let price = totalPrice(values);
    let yearly_price = yearlyTotalPrice(values);
    let { selected_region, selected_size, app, selected_image, term, backup } = values;
    let vs_cart = { selected_region, selected_size, app, selected_image, price, yearly_price, term, backup };
    dispatch(addVS_CART({ vs_cart }));
    localStorage.setItem('vs_cart', JSON.stringify([...cartVs, { ...vs_cart }]));
    setTimeout(() => {
      setSubmitting(false);
    }, 0.5 * 1000);
  };

  return (
    <Formik
      initialValues={initialValues(cart_selected_region)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
    >
      {(formikProp) => <NewVS formikProp={formikProp} />}
    </Formik>
  );
};
export default NewVSContainer;
