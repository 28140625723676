import React from 'react';
import { Icon } from 'semantic-ui-react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import TriCut from 'assets/images/button-tri-cut.png';
import { useTranslation } from 'react-i18next';

const TabsHeader = ({ menuOptions, isClose, activePanelValue, setActivePanelValue }) => {
  const { t } = useTranslation();
  return (
    <Styles.Header>
      <Styles.CrossIcon>
        <Icon size="large" name="close" onClick={() => isClose(false)} />
      </Styles.CrossIcon>
      <Styles.Start>
        <Styles.Options>
          {menuOptions.map((menuOption) => (
            <Styles.Option
              menuOptionId={menuOption.id}
              activePanelValue={activePanelValue}
              onClick={() => setActivePanelValue(menuOption.id)}
              key={menuOption.id}
            >
              {t(menuOption.name_lang)}
              {activePanelValue == menuOption.id && <img className="tricut-image" src={TriCut} />}
            </Styles.Option>
          ))}
        </Styles.Options>
      </Styles.Start>
    </Styles.Header>
  );
};

export { TabsHeader };
