import React from 'react';
import * as Styles from './DomainLastStep.style';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/common/custom-button';
import ErrorMessage from 'components/common/red-notification';
import { H6, P } from 'components/reusable-css';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { navLinks } from 'utils/nav-links-util';

const NewDomainLastStep = () => {
  const { t } = useTranslation();
  return (
    <Styles.NewVMLastStepSC>
      <Styles.WarningContainer>
        <div className="margin">
          <ErrorMessage width="100%">
            <p>
              Once you initiate this transfer, your current registrar will most likely send you an email to authorize
              the transfer. Please monitor your email accounts.{' '}
            </p>
          </ErrorMessage>
        </div>
      </Styles.WarningContainer>
      <Styles.ButtonContainer>
        <H6>Your total price is:</H6>
        <Styles.Price>
          <Styles.PerMonth>
            <Styles.PriceUnit>$30</Styles.PriceUnit>
            /year
          </Styles.PerMonth>
        </Styles.Price>
        <CustomButton title={t('Purchase')} type="submit" buttonStyles={{ padding: '.8em 3em' }} />
        <Link className='link-fund' target="blank" to={navLinks.billing.link}>
          <P>Add funds</P>
        </Link>
      </Styles.ButtonContainer>
    </Styles.NewVMLastStepSC>
  );
};

export default NewDomainLastStep;
