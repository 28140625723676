/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-28 02:23:44
 * @modify date 2021-01-28 02:23:44
 */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from './Header';

import { toggleAppDrawer } from 'store/actions/common-actions';
import { getLocalStorageValues } from 'constants/local-storage';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

export default function HeaderContainer({ queryClient, ui }) {
  const version = ui?.portal_version;
  const releasedDate = ui?.portal_release_date;
  let { userName: username } = getLocalStorageValues();
  const { data: profileMenu } = useQuery('/uiprofilemenu ');

  const isAppDrawerOpen = useSelector((state) => state.commonStates.isAppDrawerOpen);
  const dispatch = useDispatch();

  const handleAppDrawerToggle = () => {
    dispatch(toggleAppDrawer());
  };

  return (
    <Header
      version={version}
      releasedDate={releasedDate}
      username={username?.userName}
      isAppDrawerOpen={isAppDrawerOpen}
      onAppDrawerToggle={handleAppDrawerToggle}
      queryClient={queryClient}
      ui={ui}
      profileMenu={profileMenu}
    />
  );
}

HeaderContainer.propTypes = {
  queryClient: PropTypes.any,
};
