import React from 'react';
import { getCountryImage } from 'components/manage-vm/utils';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';

const Head = ({ data }) => {
  return (
    <Styles.Heading>
      <Styles.Picture>
        {data.os_class == 'windows' ? (
          <i className="fa fa-windows " />
        ) : data.os_class == 'linux' ? (
          <i className="fa fa-linux " />
        ) : null}
      </Styles.Picture>
      <Styles.Text>{data.label}</Styles.Text>
      <Styles.Country>
        <Styles.Circle style={{ border: '0.5px solid #C5C5C5' }}>
          <img src={getCountryImage(data?.region_country, data.region_ucase)} alt="country" />
        </Styles.Circle>
        <Styles.Text>{data.region_ucase}</Styles.Text>
      </Styles.Country>
    </Styles.Heading>
  );
};
export { Head };
