export const vm_amount = (values) => {
  return values.vm_price === 'custom_amount' ? values?.custom_vs_amount : values?.vm_price;
};
export const validatePrice = (values) => {
  return values?.vm_price === 'custom_amount'
    ? values?.custom_vs_amount !== '0' || values?.custom_vs_amount !== ''
    : values.vm_price !== '0' || values.vm_price !== '';
};
export const isChargeCard = (credit_card_info) => {
  return credit_card_info?.billing_default_credit_card_exp && credit_card_info?.billing_default_credit_card_last_four;
};
