export const bannerSets = (bannerData) =>
  bannerData?.banner_sets?.map((data) => {
    return { text: `Banner Set ${data.set}`, value: data.set };
  });

export const bannerSizes = (bannerData, selectedBannerValue) =>
  bannerData?.banner_sets
    ?.map((data) => {
      return data?.set === selectedBannerValue
        ? data?.sizes?.map((size) => {
            return { text: size.size, value: size.size };
          })
        : null;
    })
    .filter((value) => value != null)[0];

export const bannerUrls = (bannerData, selectedBannerValue, selectedSizes) =>
  bannerData?.banner_sets
    ?.map((data) => {
      return (
        data?.set === selectedBannerValue &&
        data?.sizes
          ?.map((size) => {
            return size.size === selectedSizes ? { text: size.url } : null;
          })
          .filter((value) => value != null)[0]
      );
    })
    .filter((val) => val != false);

export const commentText = [
  {
    id: 1,
    lang: 'affiliate.string27',
  },
  {
    id: 2,
    lang: 'affiliate.string28',
  },
  {
    id: 3,
    lang: 'affiliate.string29',
  },
  {
    id: 4,
    lang: 'affiliate.string30',
  },
  {
    id: 5,
    lang: 'affiliate.string31',
  },
];
