export const cPanels = (id) => [
  {
    id: id,
    city: 'step4_string4',
    region: `step4_string5`,
  },
  {
    id: '',
    city: 'step4_string6',
    region: '',
  },
];
