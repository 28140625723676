import { Grid, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import Input from 'components/input';
import CustomButton from 'components/common/custom-button';
import { useMutation } from 'react-query';
import { SET_REVERSE_DNS } from 'components/manage-vm/queries';
import hooks from 'hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import ConditionalFragment from 'components/conditional-fragment';

const ReverseForm = ({ ip, data, networkDataRefetch, networkSuccess, type }) => {
  const { mutationProps } = hooks();
  const rights = useSelector(userRights);
  const { t } = useTranslation();
  const match = useMediaQuery('(min-width:1280px)');
  const match1 = useMediaQuery('(max-width:1280px)');
  const [inputText, setInputText] = useState(ip.reverse_dns);
  const [validate, setValidate] = useState(false);
  const [inputBlur, setinputBlur] = useState(false);
  let inputStyle = match ? { marginTop: '-15px', marginRight: '10px' } : match1 ? { marginTop: '-15px' } : {};
  const { mutate: setReverse, isLoading: setReverseLoad } = useMutation(SET_REVERSE_DNS, {
    ...mutationProps('', { onSuccess: () => networkDataRefetch() }),
  });
  let domainValidation = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

  useEffect(() => {
    setValidate(domainValidation.test(ip.reverse_dns));
    setInputText(ip.reverse_dns);
  }, [networkSuccess]);

  const add = () => setReverse({ domain: inputText, id: data.id, ip: ip.ip, type });
  return (
    <Grid container>
      <Grid xs={12} sm={12} md={5} lg={4} xl={2}>
        <Styles.IP>{ip.ip}</Styles.IP>
      </Grid>
      <Grid>
        <Styles.Spacing />
      </Grid>
      <Grid xs={12} sm={12} md={6} lg={7} xl={9}>
        <div className="d-flex flex-wrap align-items-center">
          {rights.vseditrdns || rights.bmeditrdns ? (
            <Input
              styling={{ ...inputStyle, maxWidth: 320, marginRight: 10, width: '100%' }}
              inputProps={{
                maxlength: 100,
              }}
              type="text"
              value={inputText}
              placeholder={t('vs/instance.string133')}
              onChange={(e) => {
                setValidate(domainValidation.test(e.target.value));
                setInputText(e.target.value);
              }}
              onBlur={() => setinputBlur(true)}
              isFormIk={false}
              error={!validate && inputBlur && inputText ? 'Wrong format' : ''}
              onKeyPress={(e) => e.key === 'Enter' && add()}
              dataTooltip={t('vs/instance.string135')}
            />
          ) : (
            <Styles.IP>{inputText}</Styles.IP>
          )}
          <ConditionalFragment condition={type === 'vs' && rights.vseditrdns}>
            <CustomButton
              pointerEvents={!validate && { pointerEvents: 'none' }}
              title={'vs/instance.string134'}
              disabled={!validate}
              isLoading={setReverseLoad}
              onClick={() => add()}
            />
          </ConditionalFragment>
          <ConditionalFragment condition={type === 'bm' && rights.bmeditrdns}>
            <CustomButton
              pointerEvents={!validate && { pointerEvents: 'none' }}
              title={'vs/instance.string134'}
              disabled={!validate}
              isLoading={setReverseLoad}
              onClick={() => add()}
            />
          </ConditionalFragment>
        </div>
      </Grid>
    </Grid>
  );
};

export { ReverseForm };
