import React from 'react';
import { useQuery } from 'react-query';
import { BM_OS, BM_OS_PRICES } from 'components/bm-deployment/queries';
import { reactQueryConfig } from 'constants/react-query-config';
import NewBMStep2 from './NewBMStep2';
import _get from 'lodash/get';

const NewBMStep2Container = ({ formikProp }) => {
  const { data: bmOSData } = useQuery(['BM_OS'], BM_OS, {
    ...reactQueryConfig,
  });
  
  const { data: bmOsDataPrice } = useQuery(['BM_OS_PRICES'], BM_OS_PRICES, {
    ...reactQueryConfig,
  });

  const data = _get(bmOSData, 'bm_os', []).map((item) => {
    const pricing = _get(bmOsDataPrice, 'prices_bm_os', []).find((price) => price.id === item.id);
    return { ...item, prices: pricing?.price_monthly_usd || '' };
  });

  return <NewBMStep2 OsData={data} formikProp={formikProp} />;
};

export default NewBMStep2Container;
