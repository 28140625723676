import { UPDATE_BM_PRICING, UPDATE_BM_CPANEL_OS, BM_DEPLOYMENT } from 'store/types';

const initialState = {
  bm_deploy: {},
  pricingDetails: {
    size: 0,
    cpanel: 0,
  },
  cPanelOs: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BM_DEPLOYMENT:
      return {
        ...state,
        bm_deploy: action.payload,
      };

    case UPDATE_BM_PRICING:
      return {
        ...state,
        pricingDetails: {
          ...state.pricingDetails,
          [action.key]: action.payload,
        },
      };
    case UPDATE_BM_CPANEL_OS:
      return {
        ...state,
        cPanelOs: action.payload,
      };
    default:
      return state;
  }
}
