/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import DynamicInputs from 'components/issues/DynamicInputs';

const IssuesStep2 = ({ data, formikProps, setFields, fields }) => {
  const { values, setFieldValue } = formikProps;

  return useMemo(
    () => (
      <>
        <DynamicInputs
          data={data}
          setValue={(id, value) => {
            if (id === 'pin') {
              setFieldValue(`step2.${id}`, value);
            }
            setFields((prev) => ({ ...prev, [id]: value }));
          }}
          value={fields}
          formikProps={formikProps}
          stepType="step2"
        />
      </>
    ),
    [data, values?.step2, formikProps, fields],
  );
};
export default React.memo(IssuesStep2);
