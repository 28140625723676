export const mbToGb = (mb) => {
  return Number(mb) / 1024;
};
//FOR BM/CREATE/INSTANCE EXTRAS (Peter said)
export const gbToTBs = (gb) => {
  return Number(gb) / 1000;
};

export const mbToTB = (mb) => {
  return Number(mb) / (1024 * 1024);
};

export const gbToTB = (gb) => {
  return Number(gb) / 1024;
};

export const scrollToSection = (id) => {
  const elem = document.getElementById(id);
  if (elem) {
    elem.scrollIntoView({ behavior: 'smooth' });
  }
};

export const LENGTH_ERROR = (max) => `Maximum length is ${max || 64} characters`;

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const vm_year = (year) => {
  return String(year).slice(-2);
};

export const removeObjectEmpty = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeObjectEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};
export const get = (object, keys, defaultVal) => {
  keys = Array.isArray(keys) ? keys : keys.split('.');
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return get(object, keys.slice(1));
  }
  return object === undefined ? defaultVal : object;
};
export const validatePin = (value) => {
  const forbiddenValues = [
    '1234',
    '1212',
    '7777',
    '1004',
    '2000',
    '6969',
    '0000',
    '1111',
    '2222',
    '3333',
    '4444',
    '5555',
    '6666',
    '7777',
    '8888',
    '9999',
  ];

  if (forbiddenValues.includes(value)) {
    return 'Please use a more secure pin number';
  }

  return '';
};
