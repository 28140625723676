import React from 'react';
import { TabContent } from '../TabContent';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

export const vsActionsTabs = (data) => {
  const { t } = useTranslation();
  let isCancellation = data?.vs_cancelation_scheduled == 1;

  return [
    {
      content: isCancellation ? (
        <Trans
          i18nKey={'vs/instance.string194'}
          values={{
            date: moment(data.next_payment_date).format('ll'),
          }}
        />
      ) : (
        <Trans
          i18nKey={'vs/instance.string192'}
          values={{
            date: moment(data.next_payment_date).format('ll'),
          }}
        />
      ),
      content2: isCancellation ? '' : t('vs/instance.string193'),
      buttonText: isCancellation ? t('vs/instance.string191') : t('vs/instance.string188'),
      action: isCancellation ? 'undocancel' : 'cancel',
    },
  ];
};

const CancelVM = ({ data, refreshServer, loading }) => {
  return (
    <>
      {vsActionsTabs(data).map((item) => (
        <TabContent
          buttonProps={{
            onClick: async () => {
              await refreshServer(item.action);
            },
            isLoading: loading,
          }}
          key={item.id}
          buttonText={item.buttonText}
          content={item.content}
          content2={item.content2}
        />
      ))}
    </>
  );
};

export default CancelVM;
