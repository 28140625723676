import React from 'react';
import { Paragraphs, Steps } from 'pages/member/affiliate/Affiliate.style';
import { Trans, useTranslation } from 'react-i18next';
import { Highlight } from 'components/vm-deployment/components';
import { useSelector } from 'react-redux';
import { P } from 'components/reusable-css';
import { Heading } from 'assets/css/common-styles';

const BeSuccessful = () => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  return (
    <Steps>
      <Heading>
        <Trans i18nKey="affiliate.string1" components={{ highlight: <Highlight /> }} />
      </Heading>
      <Paragraphs>
        <P>{t('affiliate.string2')}</P>
        <P>{t('affiliate.string3')}</P>
        <P>
          <Trans i18nKey={'affiliate.string4'} values={{ id: userAccount.affiliate_id }}>
            <strong />
          </Trans>
        </P>
        <P>
          <Trans i18nKey={'affiliate.string5'}>
            <a target="_blank" rel="noreferrer" href="https://www.serverpoint.com/en/affiliate/" />
          </Trans>
        </P>
      </Paragraphs>
    </Steps>
  );
};
export { BeSuccessful };
