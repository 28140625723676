import React, { useState } from 'react';
import * as Styles from 'pages/member/user-management/UserManagement.style';
import DefaultUserImage from 'assets/images/default-user.svg';
import { Icon } from 'semantic-ui-react';
import { DeleteCard, UserEditPanel } from 'components/user-management';
import { userDeletion } from 'constants/user-management';
import { useMutation } from 'react-query';
import { DELETE_USER } from './queries';
import hooks from 'hooks';
import { get } from 'lodash';
import { checkDeletedItems } from 'components/manage-vm/utils';
import { H6, P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const CurrentUser = ({ data, usersRefetch, getRoles, roleRefetch }) => {
  const { t } = useTranslation();
  const { mutationProps } = hooks();
  const [openModal, setOpenModal] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [user, setUser] = useState();
  const toggle = () => setOpenModal((prev) => !prev);
  const { mutate: deleteUser, isLoading: deleteLoading } = useMutation(DELETE_USER, {
    ...mutationProps(() => {}, {
      onSuccess: async () => {
        toggle();
        setDeletedIds((prev) => [...prev, user.id]);
        roleRefetch();
      },
    }),
  });
  let checkDisabledItem = (id) => checkDeletedItems(deletedIds, id);

  const handleDeleteUser = async () => {
    await deleteUser({
      id: user.id,
    });
  };

  return (
    <React.Fragment>
      {get(data, 'users', []).map((user, i) => (
        <Styles.Card style={{ ...checkDisabledItem(user.id) }} key={i}>
          <Styles.Picture>
            <img src={DefaultUserImage} />
          </Styles.Picture>
          <Styles.Description>
            <H6>
              {user?.id} {user.status != 'active' && `(${user.status})`}
            </H6>
            <P style={{ height: '1.4rem' }}>
              {t(user?.user_type == 'admin' ? 'account/usermgmt.string38' : 'account/usermgmt.string39')}
            </P>
            {user.user_type != 'admin' && (
              <Styles.DescriptionIcon>
                <UserEditPanel roleRefetch={roleRefetch} getRoles={getRoles} usersRefetch={usersRefetch} user={user} />
                <Styles.Flex
                  onClick={() => {
                    toggle();
                    setUser(user);
                  }}
                >
                  <Icon name="trash alternate" />
                  <span>{t('account/usermgmt.string13')}</span>
                </Styles.Flex>
              </Styles.DescriptionIcon>
            )}
          </Styles.Description>
        </Styles.Card>
      ))}
      <DeleteCard
        buttons={userDeletion}
        handleSubmit={handleDeleteUser}
        open={openModal}
        toggle={toggle}
        loading={deleteLoading}
      />
    </React.Fragment>
  );
};

export { CurrentUser };
