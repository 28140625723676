import { totalPrice } from 'components/vm-deployment/util';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const BmHooks = () => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  const credit_card_info = useSelector((state) => state?.user?.account);

  const amountToCharge = (values) => {
    const price = Number(totalPrice(values.prices));
    const amountToCharge = get(values, 'options_prepaid.id', 1) * price;
    return amountToCharge;
  };
  const isBmAmountAvailableToTotal = (values) => {
    const amount = amountToCharge(values);
    return +userAccount.account_funds_plus_bucks >= amount;
  };

  const handleBackupHours = (data) => {
    if (!data.last_backup_hours_ago) {
      return {
        text: 'vs/instance.string200',
        param: '',
      };
    }
    if (data.last_backup_hours_ago <= 2) {
      return {
        text: 'vs/instance.string23',
        param: `${data.last_backup_hours_ago} ${t('hour')} ${t('ago')}`,
      };
    } else {
      return {
        text: 'vs/instance.string23',
        param: `${data.last_backup_hours_ago} ${t('hours')} ${t('ago')}`,
      };
    }
  };
  return {
    isBmAmountAvailableToTotal,
    isBmCardCharge: (values) => {
      let isCard = true;
      if (get(values, 'options_prepaid.id') == 1) {
        isCard = !isBmAmountAvailableToTotal(values);
      }
      return isCard;
    },
    credit_card_info,
    amountToCharge,
    handleBackupHours,
  };
};
