import styled from 'styled-components';
import BackgroundImage from 'assets/images/signup_bg.jpg';
import { pStyles } from 'components/reusable-css';

export const BgLogin = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  font-weight: 400 !important;
  font-size: 16px !important;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginContainer = styled.div`
  padding: 2em 0;
`;

export const LoginBox = styled.div`
  background: #fff;
  width: 452px;
  padding: ${({ isExpiredPassword }) => (isExpiredPassword ? '1rem 0 2rem 0' : '1rem 0 5.5rem 0')};
  border-radius: 5px;
  box-shadow: 0 0 24px 5px #d1d1d1;
  @media (max-width: 500px) {
    width: 350px;
  }
  @media (max-width: 400px) {
    width: 310px;
  }
  @media (max-width: 330px) {
    width: 290px;
  }
`;

export const LoginForm = styled.div`
  padding: 0.5rem 30px;
  h6 {
    margin: 0;
  }
  @media (max-width: 500px) {
    padding: 0.5rem 20px;
  }
  .icon {
    color: white;
    z-index: 1000;
    top: 7px;
    left: 10px;
  }
`;

export const Logo = styled.p`
  margin: 0 0 10px 2em;
  text-align: center;
  width: 380px;
  @media (max-width: 500px) {
    width: 300px;
    margin-left: 1em;
  }
  @media (max-width: 375px) {
    width: 270px;
  }
  @media (max-width: 330px) {
    width: 260px;
  }
`;

export const Paragraph = styled.p`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  margin-top: 1.5rem;
  margin-bottom: 0;
  a {
    text-decoration: underline;
  }
`;
