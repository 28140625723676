import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const CHANGE_EMAIL = async (e) => {
  const res = await axios.post(BASE_URL + `/account/profile/email_contact`, e);
  return res.data;
};

export const LOGIN_EMAIL = async (e) => {
  const res = await axios.post(BASE_URL + `/account/profile/login_alias`, e);
  return res.data;
};

export const CHANGE_PASSWORD = async (e) => {
  const res = await axios.post(BASE_URL + `/account/profile/credentials`, e);
  return res.data;
};

export const EDIT_PROFILE = async (e) => {
  const res = await axios.post(BASE_URL + `/account/profile/personal_contact`, e);
  return res.data;
};

export const ACC_PROFILE_2FA = async (e) => {
  const res = await axios.post(BASE_URL + `/account/profile/2fa`, e);
  return res.data;
};
