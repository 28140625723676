import React from 'react';
import 'assets/css/toggle.css';
const Toggle = ({ name, handleChange, checked, value }) => {
  return (
    <label className="switch">
      <input type="checkbox" name={name} onChange={handleChange} value={value} checked={checked} />
      <span  className="slider round" />
    </label>
  );
};

export default Toggle;
