import React from 'react';
import * as Styles from './SignInWithPin.styles';
import LogoImage from 'assets/images/serverpoint.svg';
import { Link } from 'react-router-dom';
import PinInput from 'react-pin-input';
import LoadingImage from 'assets/images/loading.svg';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const TicketsSignInPin = ({ handleOnComplete, isLoading }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery('(max-width:800px)');
  return (
    <Styles.SignInSC>
      <Styles.SignInContainer>
        <Styles.Logo>
          <img src={LogoImage} alt="serverpoint" width="100%" />
        </Styles.Logo>
        <Styles.SignInBox>
          <Styles.Heading>{t('pin_required.string1')}</Styles.Heading>
          <Styles.PinSC>
            <PinInput
              length={4}
              initialValue=""
              type="numeric"
              inputMode="number"
              style={{ padding: '10px 0px' }}
              inputStyle={{
                width: mobile ? '38px' : '60px',
                height: mobile ? '50px' : '70px',
                border: '2px solid #b3b3b3b3',
                borderBottom: '3px solid #FF7300',
                background: 'white',
                fontSize: mobile ? '22px' : '30px',
                margin: mobile ? '0 5px' : '0 15px',
              }}
              onComplete={(value) => handleOnComplete(value)}
              autoSelect
              focus
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </Styles.PinSC>
          <Styles.LinkSC>
            <Link to="/">{t('pin_required.string2')}</Link>
          </Styles.LinkSC>
          {isLoading && (
            <div className="loader">
              <img src={LoadingImage} width={50} />
            </div>
          )}
        </Styles.SignInBox>
      </Styles.SignInContainer>
    </Styles.SignInSC>
  );
};

export default TicketsSignInPin;
