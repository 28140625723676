import React from 'react';
import { CardDetails, CardNumber, DefaultLabel, StoredCards, CardAction, CardContainer } from './stored-cards.style';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { disableStyles } from 'components/manage-vm/utils';
import ConditionalFragment from 'components/conditional-fragment';
export default function StoredCard({
  clickedItem,
  data,
  onDoNotDefaultClick,
  onDefaultClick,
  onDeleteClick,
  updateCreditCardLoading,
  deleteCreditCardLoading,
  itemsDeleted,
  rights,
}) {
  return (
    <StoredCards style={itemsDeleted.some((id) => id === data.id) ? disableStyles : {}}>
      <div>
        <Icon size="large" name="angle right" />
      </div>
      <div>
        {data?.default == 1 ? (
          <Trans
            i18nKey={'account/billing_info.string33'}
            components={{
              card_details: <CardDetails />,
              card_number: <CardNumber />,
              default: <DefaultLabel />,
            }}
            values={{
              card_number: data?.lastdigits,
              card_expiry: data?.exp,
            }}
          >
            <i className="fa fa-credit-card" />
          </Trans>
        ) : (
          <Trans
            i18nKey={'account/billing_info.string34'}
            components={{
              card_details: <CardDetails />,
              card_number: <CardNumber />,
            }}
            values={{
              card_number: data?.lastdigits,
              card_expiry: data?.exp,
            }}
          >
            <i className="fa fa-credit-card" />
          </Trans>
        )}
        <CardContainer>
          <ConditionalFragment condition={rights.billingdefaultcc === 1}>
            {data?.default === '1' ? (
              <CardAction width={290} onClick={onDoNotDefaultClick && onDoNotDefaultClick}>
                <Trans i18nKey={'account/billing_info.string35'} />
                <span>
                  {data?.id === clickedItem?.id && clickedItem?.do_not_default && updateCreditCardLoading && (
                    <CircularProgress disableShrink size={20} style={{ color: 'orange' }} />
                  )}
                </span>
              </CardAction>
            ) : (
              <CardAction width={290} onClick={onDefaultClick && onDefaultClick}>
                <Trans i18nKey={'account/billing_info.string36'} />
                <span>
                  {data?.id === clickedItem?.id && clickedItem?.default && updateCreditCardLoading && (
                    <CircularProgress disableShrink size={20} style={{ color: 'orange' }} />
                  )}
                </span>
              </CardAction>
            )}
          </ConditionalFragment>
          <ConditionalFragment condition={rights.billingdelcc === 1}>
            <CardAction style={{ marginTop: '-0.18rem' }} width={250} onClick={onDeleteClick && onDeleteClick}>
              <Trans i18nKey={'account/billing_info.string37'} />
              <span>
                {data?.id === clickedItem?.id && clickedItem?.delete && deleteCreditCardLoading && (
                  <CircularProgress disableShrink size={20} style={{ color: 'orange' }} />
                )}
              </span>
            </CardAction>
          </ConditionalFragment>
        </CardContainer>
      </div>
    </StoredCards>
  );
}
StoredCard.propTypes = {
  data: PropTypes.object,
  onDefaultClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onDoNotDefaultClick: PropTypes.func,
  clickedItem: PropTypes.object,
};
