import styled from 'styled-components';

export const Tooltip = styled.div`
  position: absolute;
  bottom: ${(props) => (props.bottomPosition ? props.bottomPosition : '80px')};
  left: ${(props) => (props.leftPosition ? props.leftPosition : '70%')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : '100%')};
  width: ${(props) => (props.width ? props.width : '100%')};
  z-index: 9999;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border: 1px solid #dcdcdc;
  @media (max-width: 700px) {
    left: 10%;
    width: 230px;
  }
`;

export const TooltipHeader = styled.div`
  background-color: #f8f7f7;
  border-bottom: 1px solid #efeff0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 8px 18px;
  font-size: ${(props) => (props.headingSize ? props.headingSize : '14px')};
`;
export const CornerArrow = styled.img`
  position: absolute;
  bottom: -24;
  height: ${(props) => (props.arrowSize ? props.arrowSize : '25px')};
  width: ${(props) => (props.arrowSize ? props.arrowSize : '25px')};
  transform: rotateX(180deg);
`;

export const CornerArrowRight = styled.img`
  position: absolute;
  bottom: -24;
  right: 0;
  height: ${(props) => (props.arrowSize ? props.arrowSize : '25px')};
  width: ${(props) => (props.arrowSize ? props.arrowSize : '25px')};
  transform: rotateX(180deg);
`;

export const TooltipBody = styled.div`
  padding: 8px 18px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: ${(props) => (props.descriptionSize ? props.descriptionSize : '14px')};
`;
