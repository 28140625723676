import React from 'react';
import * as Styles from 'pages/member/affiliate/Affiliate.style';
import { HeadingWithText } from './HeadingWithText';
import Comment from 'components/comment';
import { commentText } from './utils';
import { H6, P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const AllSales = ({ affiliateTransaction }) => {
  const { t } = useTranslation();
  return (
    <HeadingWithText text="affiliate.string32">
      <Styles.SalesCard>
        <Styles.SalesCardHeader>
          <H6 className="text-nowrap first">{t('affiliate.string21')}</H6>
          <H6 className="text-nowrap second">{t('affiliate.string22')} </H6>
          <H6 className="text-nowrap third">{t('affiliate.string23')}</H6>
          <H6 className="text-nowrap fourth">{t('affiliate.string24')} </H6>
          <H6 className="text-nowrap fifth">{t('affiliate.string25')} </H6>
          <H6 className="text-nowrap sixth">{t('affiliate.string26')} </H6>
        </Styles.SalesCardHeader>
        <Styles.SalesRow>
          {affiliateTransaction?.affiliate_pending_transactions?.map((item) => (
            <div className="d-flex w-full" key={item?.id}>
              <div className="first">
                <P className="text-nowrap">{item?.product_en}</P>
              </div>
              <div className="second">
                <P className="text-nowrap">{item?.date_order_approved}</P>
              </div>
              <div className="third">
                <P className="text-nowrap">USD ${item?.maximum_commission}</P>
              </div>
              <div className="fourth">
                <P className="text-nowrap ">USD ${item?.commission_so_far}</P>
              </div>
              <div style={{}} className="fifth">
                <P className="text-nowrap ">USD ${item?.pending_to_be_paid}</P>
              </div>
              <div style={{}} className="-ml-auto sixth">
                <P className="text-nowrap">USD ${item?.paid}</P>
              </div>
            </div>
          ))}
        </Styles.SalesRow>
      </Styles.SalesCard>
      <Styles.CommentContainer>
        <Comment>
          {commentText.map((text, i) => (
            <Styles.CommentText key={i}>
              <i className="fa fa-circle" />
              <P>{t(text.lang)}</P>
            </Styles.CommentText>
          ))}
        </Comment>
      </Styles.CommentContainer>
    </HeadingWithText>
  );
};
export { AllSales };
