import WindowOSImage from 'assets/images/os_windows.svg';
import CentOSImage from 'assets/images/os_centos.svg';
import DebianOSImage from 'assets/images/os_debian.svg';
import FedoraOSImage from 'assets/images/os_fedora.svg';
import UbuntuOSImage from 'assets/images/os_ubuntu.svg';
import LinuxOSImage from 'assets/images/os_almalinux.svg';
import RockyOSLinux from 'assets/images/os_RockyLinux.svg';

export const getOSImage = (name) => {
  switch (name) {
    case 'windows':
      return WindowOSImage;
    case 'centos':
      return CentOSImage;
    case 'debian':
      return DebianOSImage;
    case 'fedora':
      return FedoraOSImage;
    case 'ubuntu':
      return UbuntuOSImage;
    case 'almalinux':
      return LinuxOSImage;
    case 'rockylinux':
      return RockyOSLinux;
    default:
      return '';
  }
};

export const osPricing = (price) => {
  return Number(price?.price_monthly_usd) > 0 ? `($${Number(price?.price_monthly_usd)}/mo)` : null;
};
