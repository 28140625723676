import styled from 'styled-components';
import { deviceBreakpoints } from 'variables';
import { h2Styles, h6Styles, pStyles } from 'components/reusable-css';

export const StepLabelSC = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    display: none;
  }
`;

export const StepCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  min-width: 200px;
  border: 1px solid ${(props) => props.theme.gray.shade0};
  font-weight: bold;
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    padding: 20px 14px;
    min-width: 150px;
  }
`;

export const StepHeading = styled.h2`
  display: inline;
  font-size: ${(props) => props.theme.default?.h2};
  margin: 0;
  margin-left: 15px;
  ${(props) => h2Styles(props.theme.resolutions)};
`;

export const StepLabelMobileSC = styled.div`
  display: none;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    display: block;
  }
`;

export const StepCountMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  color: white;
  margin-left: -15px;
  border-bottom-right-radius: 15px;
  width: 5em;
  background: ${(props) => props.theme.serverSizeCard.selectedTag.backgroundColor};
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;

export const StepHeadingMobile = styled.h2`
  margin: 0.5em 0;
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
`;

export const NotificationSC = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  border-radius: 8px;
  font-weight: 500;
  background: ${(props) =>
    props.isError ? props.theme.notification.errorColor : props.theme.notification.backgroundColor};
  color: white;
  padding: 7px 10px;
  i {
    margin-right: 4px;
  }
`;

export const HighlightSC = styled.span`
  color: ${(props) => props.theme.primaryDarkColor};
  font-weight: 600;
`;

export const HighlightBackgroundSC = styled.span`
  background: ${(props) =>
    props.isError
      ? props.theme.serverSizeCard.saleRed.backgroundColor
      : props.theme.serverSizeCard.tag.backgroundColor};
  color: ${(props) => (props.isError ? 'white' : 'black')};
  margin: 0;
  padding: 0 10px;
  border-radius: 30px;
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    padding: 4px 10px;
  }
`;
