import React, { useState, useEffect } from 'react';
import * as Styles from './styles/SignIn.style';
import { useTranslation } from 'react-i18next';
import LogoImage from 'assets/images/serverpoint.svg';
import { LOG_IN } from 'components/vm-deployment/queries';
import { reactQueryConfig } from 'constants/react-query-config';
import { useMutation, useQuery } from 'react-query';
import Alert from 'components/alert';
import { Link, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { getLocalStorageValues } from 'constants/local-storage';
import Loader from 'react-loader-spinner';
import { navLinks } from 'utils/nav-links-util';
import Checkbox from 'components/common/checkbox/Checkbox';
import axios from 'axios';
import Input from 'components/input';
import queryString from 'query-string';
import Loading from 'components/common/loading/Loading';
import { CustomHook } from 'utils/custom-hook';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_PREFERENCES } from 'components/manage-vm/queries';
import LanguageDropdown from 'components/language-dropdown/LanguageDropdown';
import { logoutUser } from 'store/actions/user-details';
//sign-in
const SignIn = ({ queryClient }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let { userName: UserName } = getLocalStorageValues();
  let history = useHistory();
  const { logout } = CustomHook();
  let params = queryString.parse(history?.location?.search);
  const obj = localStorage.getItem('remember');
  let email = '';
  let staffTest = params.user && params.onetimepassword;
  const userUi = useSelector((state) => state.user.ui);

  if (obj) {
    const { email: e } = JSON.parse(obj);
    email = e;
  }
  let defaultLang = i18n.language == 'en-US' ? 'en' : i18n.language;
  const [language, setLanguage] = useState(defaultLang);
  const [userName, setUserName] = useState(email);
  const [password, setPassword] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [open, setOpen] = useState(false);
  const [loginResult, setLoginResult] = useState('Thank you! Please go ahead and log in below.');
  const [severity, setSeverity] = useState('');
  const [isRememberPassword, setIsRememberPassword] = useState(email && true);
  const { mutate: updatePreferences, isLoading: updatePreferencesLoading } = useMutation(UPDATE_PREFERENCES);
  const { isLoading: loginIsLoading } = useQuery(
    [
      'LOG_IN',
      { userName: staffTest ? params.user : userName, password: btoa(staffTest ? params.onetimepassword : password) },
    ],
    LOG_IN,
    {
      enabled: trigger,
      ...reactQueryConfig,
      onSuccess: async (res) => {
        dispatch(logoutUser(false));
        await updatePreferences({ language });
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
        if (res.status == 'pass_reset_required') {
          history.replace(navLinks['expired-password'].link);
        } else if (res.status == 'two_factor_sms_sent') {
          if (isRememberPassword) {
            localStorage.setItem(
              'remember',
              JSON.stringify({
                email: userName,
              }),
            );
          } else {
            localStorage.removeItem('remember');
          }

          history.push({
            pathname: '/pin',
            state: {
              username: staffTest ? params.user : userName,
              password: btoa(staffTest ? params.onetimepassword : password),
              backup_number_available: res?.backup_number_available,
            },
          });
        } else {
          const query = `?sc_username=${res.sc_username}&sc_password=${res.sc_password}`;
          if (res.redirect_type === 'tab') {
            return window.open(`${res.redirect_url}${query}`);
          }
          if (res.redirect_type === 'redirect') {
            await logout('', false);
            return window.location.replace(`${res.redirect_url}${query}`);
          }
          if (queryClient) queryClient.clear();
          axios.defaults.headers.common.Authorization = `Bearer ${res.token}`;
          localStorage.setItem(
            'userName',
            JSON.stringify({
              userName: res.username,
              email: userName,
              token: res.token,
            }),
          );
          if (isRememberPassword) {
            localStorage.setItem(
              'remember',
              JSON.stringify({
                email: userName,
              }),
            );
          } else {
            localStorage.removeItem('remember');
          }

          setTrigger(false);
          history.replace(navLinks.virtual_machines_home.link);
        }
      },
      onError: () => {
        setSeverity('error');
        setLoginResult('Login Failed!');
        setOpen(true);
        setTrigger(false);
        staffTest ? history.replace('/') : null;
      },
    },
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTrigger(true);
  };

  useEffect(() => {
    if (staffTest) {
      setTrigger(true);
    }
  }, []);

  if (staffTest) return <Loading gap={50} background="transparent" />;
  if (!staffTest && UserName?.token) {
    return <Redirect from="*" to={userUi?.default_url || '/dashboard'} />;
  }

  const handleLanguage = (value) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  return (
    <Styles.BgLogin>
      <Styles.LoginContainer>
        <Styles.Logo>
          <img src={LogoImage} alt="serverpoint" width="100%" />
        </Styles.Logo>
        <Styles.LoginBox>
          <Alert
            severity={severity == 'error' ? 'error' : 'info'}
            text={loginResult}
            open={params?.signup_approve || open}
            handleClose={() => setOpen(false)}
          />
          <Styles.LoginForm>
            <Styles.Heading>{t('signin.string1')}</Styles.Heading>
            <form onSubmit={handleSubmit}>
              <div>
                <Input
                  type="text"
                  name={'name'}
                  value={userName}
                  placeholder={t('signin.string2')}
                  onKeyPress={(e) => {
                    e.target.keyCode === 13 && e.preventDefault();
                  }}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  isFormIk={false}
                  autocomplete="username"
                />
              </div>
              <div>
                <Input
                  type="password"
                  name={'password'}
                  value={password}
                  placeholder={t('signin.string3')}
                  onKeyPress={(e) => {
                    e.target.keyCode === 13 && e.preventDefault();
                  }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  isFormIk={false}
                  autocomplete="current-password"
                />
              </div>
              <div>
                <Styles.SubmitForm>
                  <Styles.Remember>
                    <Checkbox
                      id="check"
                      title="signin.string4"
                      value={isRememberPassword}
                      setValue={setIsRememberPassword}
                      remember={isRememberPassword}
                    />
                  </Styles.Remember>
                  <Styles.Forgot>
                    <Link to="/forgot-password">{t('signin.string5')}</Link>
                  </Styles.Forgot>
                </Styles.SubmitForm>
                <Styles.LoginButton>
                  <Styles.Button>
                    {loginIsLoading || trigger || updatePreferencesLoading ? (
                      <Loader
                        style={{ display: 'inline' }}
                        type="ThreeDots"
                        color="#fff"
                        height={24}
                        width={24}
                        radius={4}
                      />
                    ) : null}
                    <span>
                      <span style={{ fontWeight: 'bolder' }}> {t('signin.string6')}</span>
                    </span>
                  </Styles.Button>
                </Styles.LoginButton>
              </div>
              <Styles.Bottom>
                <LanguageDropdown language={language} handleLanguage={(e) => handleLanguage(e)} />
                <Styles.SignUp>
                  <Link to="/signup">{t('signin.string7')}</Link>
                </Styles.SignUp>
              </Styles.Bottom>
            </form>
          </Styles.LoginForm>
        </Styles.LoginBox>
      </Styles.LoginContainer>
    </Styles.BgLogin>
  );
};

export default SignIn;
