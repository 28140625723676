import React, { useState } from 'react';
import * as Styles from 'components/issues/Issues.style';
import RadioButton from 'components/common/radio-button';
import { RecursiveInputs } from './DynamicInputs';
import Input from 'components/input';
import { useEditor, Editable } from '@wysimark/react';
import PinInput from 'react-pin-input';
import Select from 'components/select';
import Checkbox from 'components/common/checkbox/Checkbox';
import { P } from 'components/reusable-css';
import { Trans, useTranslation } from 'react-i18next';

const MultipleItems = ({ step, value, setValue, setShow, show, formikProps, stepType }) => {
  const { t } = useTranslation();
  const { errors, touched } = formikProps || {};
  const editor = useEditor({ height: +step?.text_area_height || 160 });
  const [checkBoxVal, setCheckBoxVal] = useState([]);
  const onClickCheckBox = (index, value) => {
    const updatedValues = [...checkBoxVal];
    updatedValues[index] = value;
    setCheckBoxVal(updatedValues);
  };
  const handlePinComplete = (id, value) => {
    setValue(id, value);
  };

  //indiviudal radio item
  const RadioItem = ({ step: innerStep }) => (
    <Styles.FlexContainer
      margin="0 0 5px 0"
      cursor="pointer"
      onClick={() => {
        setValue(step.id, innerStep.id, step);
      }}
    >
      <RadioButton
        checkbox={value}
        checked={value && value[step.id] ? value[step.id] === innerStep.id : null}
        setCheckedValue={(id) => setValue(step.id, id, step)}
        data={step}
        onClick={() => setValue(step.id, innerStep.id, step)}
      />
      <div className="d-flex" {...(step.sub?.length ? { onClick: () => setShow(!show) } : {})}>
        {step.sub?.length ? <Styles.CarretIcon name="caret down" className="text-xl" /> : null}
        <Styles.step1Title input_type={step?.input_type}>
          {t(`tickets/create.${innerStep.option || innerStep.api_language_string_title}`)}
        </Styles.step1Title>
      </div>
    </Styles.FlexContainer>
  );

  const handleError = (error) => {
    let regex = /^[a-zA-Z0-9 ]{5,}$/;
    let isChecked =
      error.id == 'subject'
        ? !regex.test(value?.subject)
        : error.id == 'fullname'
        ? !regex.test(value?.fullname)
        : false;
    return isChecked;
  };

  return (
    <React.Fragment key={step.id}>
      {/* radio with title */}
      {step?.input_type === 'radio' && (
        <>
          {step.api_language_string_title && stepType === 'step2' && (
            <Styles.Title>
              <strong>{t(`tickets/create.${step.api_language_string_title}`)}</strong>
            </Styles.Title>
          )}
          {step?.api_language_string_sub_title && (
            <Styles.Description margin={`${step.api_language_string_title ? '0' : '10px'} 0 10px 0`}>
              {t(`tickets/create.${step?.api_language_string_sub_title}`)}
            </Styles.Description>
          )}
          {step.input_type_options ? (
            step.input_type_options
              .map((item) => ({ id: item, option: item }))
              .map((item, i) => <RadioItem key={i} step={item} />)
          ) : (
            <RadioItem step={step} />
          )}
        </>
      )}

      {/* select with title */}
      {step?.input_type == 'select' && (
        <div style={{ maxWidth: '800px', margin: '10px 0' }}>
          {step.title_en && stepType === 'step2' && (
            <Styles.Title>
              <strong>{step?.title_en}</strong>
            </Styles.Title>
          )}
          {step?.sub_title_en && <Styles.Description margin={'0'}>{step?.sub_title_en}</Styles.Description>}
          <Select
            options={step?.input_type_options_en?.map((data) => {
              return { text: data?.name, value: data?.value };
            })}
            styling={{
              width: '16em',
              backgroundColor: 'white',
            }}
          />
        </div>
      )}

      {/* checkbox with title */}
      {step?.input_type == 'checkbox' && (
        <div style={{ maxWidth: '800px', margin: '10px 0' }}>
          {step.title_en && stepType === 'step2' && (
            <Styles.Title>
              <strong>{step?.title_en}</strong>
            </Styles.Title>
          )}
          {step?.sub_title_en && <Styles.Description margin={'0'}>{step?.sub_title_en}</Styles.Description>}
          {step?.input_type_options_en.map((item, i) => (
            <Checkbox
              key={i}
              id={item?.value}
              value={checkBoxVal[i] || false}
              setValue={(value) => onClickCheckBox(i, value)}
              title={item?.name}
              name={item?.value}
              labelStyle={{
                textTransform: 'capitalize',
                paddingTop: '7px',
                marginLeft: '-8px',
              }}
            />
          ))}
        </div>
      )}

      {/* nested radio buttons with title */}
      {!step?.input_type && (
        <Styles.RecursionCheckBoxes>
          <Styles.FlexContainer
            margin="0 0 5px 0"
            alignItems="normal"
            cursor="pointer"
            {...(step.sub?.length ? { onClick: () => setShow(!show) } : {})}
          >
            {step.sub?.length ? <Styles.CarretIcon name={`caret ${show ? 'up' : 'down'}`} className="text-xl" /> : null}
            <Styles.step1Title input_type={step?.input_type}>
              {t(`tickets/create.${step.api_language_string_title}`)}
            </Styles.step1Title>
          </Styles.FlexContainer>
          <div className="repeat-div">
            {step.sub?.length && show ? (
              <>
                <RecursiveInputs steps={step.sub || []} setValue={setValue} value={value} />
              </>
            ) : null}
          </div>
        </Styles.RecursionCheckBoxes>
      )}

      {/* input */}
      {step.input_type === 'text' && (
        <>
          {step.api_language_string_title && (
            <Styles.Title>
              <strong>{t(`tickets/create.${step.api_language_string_title}`)}</strong>
            </Styles.Title>
          )}
          {step?.api_language_string_sub_title && (
            <Styles.Description margin={`10px 0 10px 0`}>{step?.api_language_string_sub_title}</Styles.Description>
          )}
          <div style={{ maxWidth: '800px', width: '100%' }}>
            {handleError(step) && (
              <span className="error_messageSty">
                <Trans i18nKey={'tickets/create.string1'} />
              </span>
            )}
          </div>

          <Input
            styling={{ maxWidth: '800px', width: '100%' }}
            borderRadius="6px"
            isValidate={false}
            borderWidth="1px"
            borderBottomWidth="2px"
            name={'Title'}
            placeholder={
              step.api_language_string_content_input
                ? t(`tickets/create.${step.api_language_string_content_input}`)
                : step.api_language_string_content_input
            }
            onChange={(e) => {
              setValue(step.id, e.target.value);
            }}
            value={value[step.id]}
          />
        </>
      )}

      {/* textarea editor */}
      {step.input_type === 'textarea' && (
        <Styles.TextAreaContainer>
          {step.api_language_string_title && (
            <Styles.Title>
              <strong>{t(`tickets/create.${step.api_language_string_title}`)}</strong>
            </Styles.Title>
          )}
          {step?.api_language_string_sub_title && (
            <Styles.Description margin={`10px 0 10px 0`}>
              {t(`tickets/create.${step?.api_language_string_sub_title}`)}
            </Styles.Description>
          )}
          <Styles.FlexContainer
            width={step.paragraph_title_en || step.paragraph_content_en ? 'fit-content' : '100%'}
            display={step.paragraph_title_en || step.paragraph_content_en ? 'flex' : 'block'}
            alignItems="flex-start"
            justifyContent="flex-end"
            margin="0"
          >
            {step.paragraph_title_en && step.paragraph_content_en && (
              <Styles.WysimarkInfo width="55%">
                {step.paragraph_title_en && (
                  <P>
                    <strong>{step.paragraph_title_en}</strong>
                  </P>
                )}
                <span>{step.paragraph_content_en}</span>
              </Styles.WysimarkInfo>
            )}
            <Editable
              placeholder={
                step?.api_language_string_content_input
                  ? t(`tickets/create.${step?.api_language_string_content_input}`)
                  : step?.api_language_string_content_input
              }
              editor={editor}
              id={step?.id}
              value={value[step.id]}
              onChange={() => {
                setValue(step.id, editor.getMarkdown());
              }}
            />
          </Styles.FlexContainer>
        </Styles.TextAreaContainer>
      )}

      {/* secret textbox with title and description */}
      {step.input_type === 'secrettextarea' && (
        <Styles.ContentPadding>
          <Styles.FlexContainer
            margin="0px"
            alignItems="flex-start"
            justifyContent="flex-end"
            fontWeight="normal"
            maxWidth="800px"
            width="100%"
          >
            <Styles.WysimarkInfo>
              <p>
                <strong>{t(`tickets/create.${step.api_language_string_paragraph_title}`)}</strong>
              </p>
              <span>{t(`tickets/create.${step.api_language_string_paragraph_content}`)}</span>
            </Styles.WysimarkInfo>
            <Styles.InputContainer>
              <Input
                styling={{ marginBottom: '45px', marginTop: '-10px' }}
                borderRadius="6px"
                borderWidth="1px"
                borderBottomWidth="2px"
                name={'comment'}
                multiline
                minRows={7}
                maxRows={11}
                placeholder={t(`tickets/create.${step.api_language_string_content_input}`)}
                onChange={(e) => setValue(step.id, e.target.value)}
                value={value[step.id]}
              />
            </Styles.InputContainer>
          </Styles.FlexContainer>
        </Styles.ContentPadding>
      )}

      {/* pin textboxes with title and description */}
      {step.input_type === 'pin' && (
        <Styles.ContentPadding maxWidth="815px" id="step2-pin">
          <Styles.Title>
            <strong>{t(`tickets/create.${step.api_language_string_paragraph_title}`)}</strong>
          </Styles.Title>
          <Styles.FlexContainer>
            <Styles.WysimarkInfo width="100%">
              <span>{t(`tickets/create.${step.api_language_string_paragraph_content}`)}</span>
            </Styles.WysimarkInfo>

            <div className="pin-details">
              <Styles.Pin>
                <PinInput
                  name={`${stepType}.${step.id}`}
                  length={4}
                  initialValue=""
                  type="numeric"
                  inputMode="number"
                  style={{ padding: '10px' }}
                  inputStyle={{
                    borderColor: touched?.[stepType]?.[step.id] && errors?.[stepType]?.[step.id] ? 'red' : '#b3b3b3b3',
                    background: 'white',
                  }}
                  inputFocusStyle={{
                    borderColor: touched?.[stepType]?.[step.id] && errors?.[stepType]?.[step.id] ? 'red' : '#b3b3b3b3',
                  }}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  onChange={(value) => handlePinComplete(step.id, value)}
                />
              </Styles.Pin>
              {touched?.[stepType]?.[step.id] && errors?.[stepType]?.[step.id] && (
                <P className="pin-error">
                  {touched?.[stepType]?.[step.id] && errors?.[stepType]?.[step.id] ? errors?.[stepType]?.[step.id] : ''}
                </P>
              )}
            </div>
          </Styles.FlexContainer>
        </Styles.ContentPadding>
      )}
    </React.Fragment>
  );
};

export default React.memo(MultipleItems);
