import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Popup } from 'semantic-ui-react';
import * as Styles from './Account.style';
import { socialMediaLinks } from 'utils/nav-links-util';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CustomHook } from 'utils/custom-hook';
import TwitterLogo from 'assets/images/twitter-Logо.png';

export default function Account({ queryClient, profileMenu }) {
  const { t } = useTranslation();
  const { logout } = CustomHook();
  const [isOpen, setIsOpen] = useState(false);
  const userUiDetails = useSelector((state) => state.user.ui);
  const handleNavigate = async (link) => {
    setIsOpen(false);
    if (link.url === '/logout') {
      await logout(queryClient);
    }
  };
  return (
    <Styles.AccountSC>
      <Popup
        closable
        preserve
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
        on="click"
        wide
        position="bottom left"
        style={{ padding: 0, right: '2px', left: 'unset' }}
        trigger={
          <Styles.TriggerContainer onClick={() => setIsOpen(!isOpen)}>
            <i className="fa fa-user user-icon" />
            <Styles.AccountLabel>
              {t('header.string8')}
              <Icon name="caret square down" />
            </Styles.AccountLabel>
          </Styles.TriggerContainer>
        }
      >
        <Popup.Content>
          <Styles.PopupContentContainer>
            <Styles.Greeting>{profileMenu?.hello_en}</Styles.Greeting>
            <Styles.Divider />
            <Styles.Links>
              {profileMenu?.accountmenu?.map((link, i) =>
                link.external_link === '1' ? (
                  <Styles.AchorTag key={i} href={link.url} target="blank">
                    <Icon name={link.menu_icon} />
                    <div>{t(link.api_language_string_title)}</div>
                  </Styles.AchorTag>
                ) : (
                  <Styles.LinkSC onClick={() => handleNavigate(link)} key={link.id} to={link.url}>
                    <Icon name={link.menu_icon} />
                    <div>{t(link.api_language_string_title)}</div>
                  </Styles.LinkSC>
                ),
              )}
            </Styles.Links>
            <Styles.SocialMediaLinks>
              {socialMediaLinks(userUiDetails).map((link) => (
                <Styles.SocialMediaLink
                  key={link.id}
                  color={link.color}
                  href={link.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.icon == 'twitter' ? (
                    <img className="twitter-logo" src={TwitterLogo} alt="twitter-logo" />
                  ) : (
                    <Icon name={link.icon} />
                  )}
                </Styles.SocialMediaLink>
              ))}
            </Styles.SocialMediaLinks>
          </Styles.PopupContentContainer>
        </Popup.Content>
      </Popup>
    </Styles.AccountSC>
  );
}

Account.propTypes = {
  queryClient: PropTypes.any,
};
