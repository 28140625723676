import {
  USER_UI,
  USER_ACCOUNT,
  USER_BILLING_ACCOUNT_REFETCH,
  USER_BILLING,
  USER_ACCOUNT_REFETCH,
  USER_UI_REFETCH,
  IS_USER_FETCHING,
  LOGOUT_USER,
} from 'store/types';

const initialState = {
  ui: {},
  account: {},
  billing_info: {},
  userBillingAccountRefetch: () => {},
  userAccountRefetch: () => {},
  userUiRefetch: () => {},
  isUserUiFetching: false,
  isUserLogout: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGOUT_USER:
      return {
        ...state,
        isUserLogout: action.payload,
      };
    case USER_UI:
      return {
        ...state,
        ui: action.payload,
      };
    case USER_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case IS_USER_FETCHING:
      return {
        ...state,
        isUserUiFetching: action.payload,
      };
    case USER_ACCOUNT_REFETCH:
      return {
        ...state,
        userAccountRefetch: action.payload,
      };
    case USER_UI_REFETCH:
      return {
        ...state,
        userUiRefetch: action.payload,
      };
    case USER_BILLING_ACCOUNT_REFETCH:
      return {
        ...state,
        userBillingAccountRefetch: action.payload,
      };
    case USER_BILLING:
      return {
        ...state,
        billing_info: action.payload,
      };
    default:
      return state;
  }
}
