import React, { useState } from 'react';
import Loading from 'components/common/loading/Loading';
import NotFound from 'layout/not-found/NotFound';
import { useQuery } from 'react-query';

const RdpaDomain = () => {
  const [statusCode, setStatusCode] = useState(200);
  let id = location?.hash?.split('domain/')[1];
  const { data, isError, isLoading } = useQuery(`/rdap/domain/${id}`, {
    onError: (error) => setStatusCode(error.response.status),
  });

  if (isLoading) {
    return <Loading gap={50} />;
  }
  if (isError) {
    return <NotFound isError={statusCode} />;
  }
  return (
    <div>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};
export default RdpaDomain;
