import React from 'react';
import NewBMStep1 from './NewBMStep1';

const NewBMStep1Container = ({ serverSizesData, formikProp, handleServerSizeSelection, isShowPriceButton }) => {
  return (
    <NewBMStep1
      onServerSizeSelection={handleServerSizeSelection}
      serverSizesData={serverSizesData}
      formikProp={formikProp || {}}
      isShowPriceButton={isShowPriceButton}
    />
  );
};

export default NewBMStep1Container;
