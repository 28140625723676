/* eslint-disable no-unused-vars */
/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-28 22:59:37
 * @modify date 2021-02-05 14:40:07
 */

// import DASHBAORD_ICON from 'assets/images/icons/dashboard.svg';
import MENU_SPRITE_ICON from 'assets/images/icons/menu-sprite.svg';
// import PAPER_ICON from 'assets/images/icons/paper.svg';
// import TICKET_ICON from 'assets/images/icons/ticket.svg';
import VmUtils from 'pages/member/new-vm/util';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import { get } from 'utils';

export const navLinks = {
  domain_instance: {
    id: 'domain_instance',
    label: 'domain_instance',
    labelMobile: 'domain_instance',
    link: '/domain/instance/create',
  },
  dns_instance_id: {
    id: 'dns_instance_id',
    label: 'dns_instance_id',
    labelMobile: 'dns_instance_id',
    link: '/dns/instance/:id',
  },
  dns_instance: {
    id: 'dns_instance',
    label: 'dns_instance',
    labelMobile: 'dns_instance',
    link: '/dns/instance',
  },
  your_web_hosting_containers: {
    id: 'your_web_hosting_containers',
    label: 'your_web_hosting_containers',
    labelMobile: 'your_web_hosting_containers',
    link: '',
  },
  deploy_a_web_hosting_container: {
    id: 'deploy_a_web_hosting_container',
    label: 'deploy_a_web_hosting_container',
    labelMobile: 'deploy_a_web_hosting_container',
    link: '/wh/instance/create',
  },
  virtual_machines_home: {
    id: 'virtual_machines_home',
    label: 'home',
    labelMobile: 'home',
    link: '/welcome',
  },
  manage_your_servers: {
    id: 'manage_your_servers',
    label: 'manage_your_servers',
    labelMobile: 'manage_your_servers',
    link: '/bm/instances',
    showBadge: true,
  },
  manage_your_vms: {
    id: 'manage_your_vms',
    label: 'manage_your_vms',
    labelMobile: 'manage_your_vms',
    link: '/vs/instance',
    showBadge: true,
    creation_loading: true,
  },
  new_vm: {
    id: 'new_vm',
    label: 'deploy_a_new_vm',
    labelMobile: 'deploy_a_new_vm',
    link: '/vs/instance/create',
  },
  new_vm_slider: {
    id: 'new_vm_slider',
    label: 'deploy_a_new_vm',
    labelMobile: 'deploy_a_new_vm',
    link: '/vs/instance/custom_create',
  },
  new_server: {
    id: 'new_server',
    label: 'deploy_a_new_server',
    labelMobile: 'deploy_a_new_server',
    link: '/bm/instance/create',
  },
  vms_pending_to_destory: {
    id: 'vms_pending_to_destory',
    label: 'vms_pending_to_destory',
    labelMobile: 'vms_pending_to_destory',
    link: '/vs/recoverable',
    showBadge: true,
  },
  great_features: {
    id: 'great_features',
    label: 'great_features',
    labelMobile: 'great_features',
    link: '/great-features',
  },
  available_regions: {
    id: 'available_regions',
    label: 'available_regions',
    labelMobile: 'available_regions',
    link: '/available-regions',
  },
  make_money_with_serverpoints_affiliate_program: {
    id: 'make_money_with_serverpoints_affiliate_program',
    label: 'make_money_with_serverpoints_affiliate_program',
    labelMobile: 'make_money_with_serverpoints_affiliate_program',
    link: '/affiliate',
  },
  changelog: {
    id: 'changelog',
    label: 'changelog',
    labelMobile: 'changelog',
    link: '/changelog',
  },
  open_a_support_ticket: {
    id: 'open_a_support_ticket',
    label: 'open_a_support_ticket',
    labelMobile: 'open_a_ticket',
    link: 'https://secure.serverpoint.com/tickets/index.php?/Tickets/Submit',
    openInNewTab: true,
  },
  contact_our_staff: {
    id: 'contact_our_staff',
    label: 'contact_our_staff',
    labelMobile: 'contact_our_staff',
    link: '/tickets/create',
  },
  see_current_tickets: {
    id: 'see_current_tickets',
    label: 'see_current_tickets',
    labelMobile: 'see_current_tickets',
    link: '/tickets',
    showBadge: true,
  },
  ticket_detail: {
    id: 'ticket_detail',
    label: 'ticket_detail',
    labelMobile: 'ticket_detail',
    link: '/tickets/:id',
    show: false,
  },
  testimonials: {
    id: 'testimonials',
    label: 'testimonials',
    labelMobile: 'testimonials',
    link: 'https://www.shopperapproved.com/reviews/serverpoint.com/',
    openInNewTab: true,
  },
  edit_profile: {
    id: 'edit_profile',
    label: 'edit_profile',
    labelMobile: 'edit_profile',
    link: '/account/profile',
    icon: 'user',
  },
  billing: {
    id: 'billing',
    label: 'billing',
    labelMobile: 'billing',
    link: '/account/billing_info',
    icon: 'credit card outline',
  },
  user_management: {
    id: 'user_management',
    label: 'user_management',
    labelMobile: 'user_management',
    link: '/account/usermgmt',
    icon: 'users',
  },
  provide_feedback: {
    id: 'provide_feedback',
    label: 'provide_feedback',
    labelMobile: 'provide_feedback',
    link: '/provide_feedback',
    icon: 'comments',
  },
  system_status: {
    id: 'system_status',
    label: 'system_status',
    labelMobile: 'system_status',
    link: 'https://status.serverpoint.com/',
    icon: 'chart bar',
  },
  affiliate_program: {
    id: 'affiliate_program',
    label: 'affiliate_program',
    labelMobile: 'make_money',
    link: '/affiliate',
    icon: 'money',
  },
  logout: {
    id: 'logout',
    label: 'logout',
    labelMobile: 'logout',
    link: '/',
    icon: 'sign-out',
  },
  contact_us: {
    id: 'contact_us',
    label: 'Contact us',
    labelMobile: 'Contact us',
    link: '/tickets/create',
    icon: 'fa-solid fa-comments',
  },
  facebook: {
    id: 'facebook',
    label: 'facebook',
    labelMobile: 'facebook',
    link: 'https://www.facebook.com/ServerPointCom/',
    icon: 'facebook square',
    openInNewTab: true,
    color: '#3b5998',
  },
  twitter: {
    id: 'twitter',
    label: 'twitter',
    labelMobile: 'twitter',
    link: 'https://twitter.com/serverpoint?lang=en',
    icon: 'twitter',
    openInNewTab: true,
    color: '#55acee',
  },
  instagram: {
    id: 'instagram',
    label: 'instagram',
    labelMobile: 'instagram',
    link: 'https://www.instagram.com/serverpoint/',
    icon: 'instagram',
    openInNewTab: true,
    color: '#49769c',
  },
  public_checkout_vs_summary: {
    id: 'public_checkout_vs_summary',
    label: 'Summary/Payment',
    labelMobile: 'Summary/Payment',
    link: '/public/checkout/vs/summary',
    icon: 'fa-solid fa-comments',
  },
  public_checkout_vs: {
    id: 'public_checkout_vs',
    label: 'Checkout Vs',
    labelMobile: 'Checkout Vs',
    link: '/public/checkout/vs',
    icon: 'fa-solid fa-comments',
  },
  rdap_domain_id: {
    id: 'rdap_domain_id',
    label: 'rdap_domain_id',
    labelMobile: 'rdap_domain_id',
    link: '/rdap/domain/:id',
  },
  rdap_domain: {
    id: 'rdap_domain',
    label: 'rdap_domain',
    labelMobile: 'rdap_domain',
    link: '/rdap/domain',
  },
  'expired-password': {
    id: 'expired-password',
    label: 'expired-password',
    labelMobile: 'expired-password',
    link: '/expired-password',
  },
};

const navigateLink = (right, type) => {
  const rights = useSelector(userRights);
  return rights[right] ? [navLinks[type] || 'separator'] : [];
};

export const sideNavLinks = () => {
  const { newVmObject } = VmUtils();
  const rights = useSelector(userRights);
  return [
    {
      indexNumber: 0,
      id: 'virtual_machines',
      label: 'virtual_machines',
      labelMobile: 'virtual_servers',
      icon: MENU_SPRITE_ICON,
      classes: 'iserver',
      links: [
        navLinks.virtual_machines_home,
        ...(rights.vsprovision ? [newVmObject] : []),
        navLinks.manage_your_vms,
        ...navigateLink('vsviewdeleted', 'vms_pending_to_destory'),
        // 'separator1',
        // navLinks.great_features,
        // navLinks.available_regions,
        ...navigateLink('affiliate', 'separator2'),
        ...navigateLink('affiliate', 'make_money_with_serverpoints_affiliate_program'),
      ],
      mobileLinks: [
        navLinks.virtual_machines_home,
        ...(rights.vsprovision ? [newVmObject] : []),
        navLinks.manage_your_vms,
        ...navigateLink('vsviewdeleted', 'vms_pending_to_destory'),
        // navLinks.great_features,
        // navLinks.available_regions,
        ...navigateLink('affiliate', 'make_money_with_serverpoints_affiliate_program'),
      ],
      show: rights.vs,
    },
    // {
    //   indexNumber: 4,
    //   id: 'cloud_web_hosting',
    //   label: 'cloud_web_hosting',
    //   labelMobile: 'cloud_web_hosting',
    //   icon: MENU_SPRITE_ICON,
    //   classes: 'iserver',
    //   links: [navLinks.deploy_a_web_hosting_container, navLinks.your_web_hosting_containers],
    //   mobileLinks: [navLinks.deploy_a_web_hosting_container, navLinks.your_web_hosting_containers],
    //   show: rights.vs,
    // },
    {
      indexNumber: 1,
      id: 'bare_metal_servers',
      label: 'bare_metal_servers',
      labelMobile: 'bare_metal_servers',
      icon: MENU_SPRITE_ICON,
      classes: 'ibare',
      links: [
        navLinks.virtual_machines_home,
        ...navigateLink('bmprovision', 'new_server'),
        navLinks.manage_your_servers,
        ...navigateLink('affiliate', 'separator1'),
        ...navigateLink('affiliate', 'make_money_with_serverpoints_affiliate_program'),
      ],
      mobileLinks: [
        navLinks.virtual_machines_home,
        ...navigateLink('bmprovision', 'new_server'),
        navLinks.manage_your_servers,
        ...navigateLink('affiliate', 'make_money_with_serverpoints_affiliate_program'),
      ],
      show: rights.bm,
    },
    {
      indexNumber: 2,
      id: 'changelog',
      label: 'changelog',
      labelMobile: 'changelog',
      icon: MENU_SPRITE_ICON,
      classes: 'ilog',
      links: [navLinks.virtual_machines_home, navLinks.changelog],
      mobileLinks: [navLinks.virtual_machines_home, navLinks.changelog],
    },
    {
      indexNumber: 3,
      id: 'support',
      label: 'support',
      labelMobile: 'support',
      icon: MENU_SPRITE_ICON,
      classes: 'iticket',
      links: [navLinks.contact_our_staff, navLinks.see_current_tickets, navLinks.ticket_detail],
      mobileLinks: [navLinks.contact_our_staff, navLinks.see_current_tickets, navLinks.ticket_detail],
    },
  ];
};

export const showBadgesValue = () => {
  const userAccount = useSelector((state) => state.user.account);
  return {
    manage_your_vms: get(userAccount, 'customer_has_vs', 0),
    vms_pending_to_destory: get(userAccount, 'customer_has_vs_pending_deletion', 0),
    manage_your_servers: get(userAccount, 'customer_has_bm', 0),
    see_current_tickets: get(userAccount, 'customer_has_open_tickets', 0),
  };
};

export const userDropdownLinks = () => {
  const rights = useSelector(userRights);

  return [
    ...navigateLink('profile', 'edit_profile'),
    ...navigateLink('billing', 'billing'),
    ...navigateLink('usermanagement', 'user_management'),
    // navLinks.provide_feedback,
    navLinks.system_status,
    ...(rights.affiliate ? [navLinks.affiliate_program] : []),
    navLinks.logout,
  ];
};

export const socialMediaLinks = (userUiDetails) => {
  let links = [];
  if (userUiDetails.social_media_fb) {
    links = [...links, navLinks.facebook];
  }
  if (userUiDetails.social_media_tw) {
    links = [...links, navLinks.twitter];
  }
  if (userUiDetails.social_media_ig) {
    links = [...links, navLinks.instagram];
  }
  return links;
};
