import React from 'react';
import * as Styles from './UserManagement.style';
import { Formik, Form } from 'formik';
import Input from 'components/input';
import CustomButton from 'components/common/custom-button';
import { Trans } from 'react-i18next';
import { Highlight } from 'components/vm-deployment/components';
import { saveRoleInitialValues, saveRoleValidationSchema } from 'constants/user-management';
import { Grid } from '@material-ui/core';
import { CurrentRole, Heading, Roles } from 'components/user-management/';
import { useMediaQuery } from '@material-ui/core';
import { useMutation } from 'react-query';
import { CustomHook } from 'utils/custom-hook';
import { ADD_ROLES } from 'components/user-management/queries';
import ErrorMessage from 'components/common/red-notification';
import { useTranslation } from 'react-i18next';
import { H6, P } from 'components/reusable-css';

const AddNewRole = ({ roleRefetch, getRoles, getRolesError, labels, usersRefetch }) => {
  const { t } = useTranslation();
  const match = useMediaQuery('(min-width:959px)');
  const match2 = useMediaQuery('(max-width:786px)');
  const nowrap = useMediaQuery('(min-width:430px)');
  const wrap = useMediaQuery('(max-width:430px)');
  let buttonStyle = wrap
    ? { marginTop: '5px', padding: ' 9px 30px' }
    : nowrap
    ? { marginTop: '27px', padding: '13px 30px' }
    : null;
  const { mutationProps } = CustomHook();
  const { mutate: addRole, isLoading: loading } = useMutation(ADD_ROLES);

  const handleSubmit = async (values, { resetForm }) => {
    await addRole(
      { rolename: values.role_name, ...values.isCheck },
      mutationProps(() => {}, {
        onSuccess: async () => {
          resetForm({
            values: { isCheck: values.isCheck, role_name: '' },
          });
          await roleRefetch();
        },
      }),
    );
  };

  return (
    <Formik initialValues={saveRoleInitialValues} validationSchema={saveRoleValidationSchema} onSubmit={handleSubmit}>
      {({ values, handleChange, handleBlur, errors, touched, setFieldValue, resetForm }) => (
        <Form>
          <Styles.AddNewRoleSC>
            <Styles.AddNewRole>
              <Heading>
                <Trans i18nKey="account/usermgmt.string2" components={{ highlight: <Highlight /> }} />
              </Heading>
              <Styles.Roles>
                <Styles.Para>{t('account/usermgmt.string3')}</Styles.Para>
                <Roles
                  isCheck={values.isCheck}
                  setIsCheck={(value) => setFieldValue('isCheck', value)}
                  labels={labels?.available_roles}
                />
              </Styles.Roles>
              <Styles.AddRoles>
                <Grid style={{ marginLeft: match2 ? 4 : match ? -5 : -3 }} container>
                  <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
                    <H6>{t('account/usermgmt.string4')}</H6>
                  </Grid>
                  <Grid xs={12} sm={7} md={6} lg={6} xl={3} className="d-flex flex-wrap">
                    <Input
                      type={'text'}
                      className="custom_inputs"
                      name={'role_name'}
                      value={values['role_name']}
                      placeholder={t('account/usermgmt.string5')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors['role_name']}
                      touched={touched['role_name']}
                      styling={{ width: '18rem', marginRight: '8px' }}
                    />
                    <CustomButton isLoading={loading} type="submit" buttonStyles={buttonStyle} />
                  </Grid>
                  {Object.keys(values.isCheck).length ? (
                    <Styles.ClearAllBoxContainer>
                      <P
                        className="clear-box"
                        onClick={() =>
                          resetForm({
                            values: { isCheck: {} },
                          })
                        }
                      >
                        {t('account/usermgmt.string6')}
                      </P>
                    </Styles.ClearAllBoxContainer>
                  ) : null}
                </Grid>
              </Styles.AddRoles>
            </Styles.AddNewRole>
            <Styles.CurrentUser>
              {getRoles && (
                <Heading>
                  <Trans i18nKey="account/usermgmt.string7" components={{ highlight: <Highlight /> }} />
                </Heading>
              )}
              <Styles.CurrentUsers>
                <CurrentRole
                  getRoles={getRoles?.role_rights}
                  roleRefetch={roleRefetch}
                  labels={labels?.available_roles}
                  getRolesError={getRolesError}
                  usersRefetch={usersRefetch}
                />
                <Styles.ErrorMsgContainer>
                  <ErrorMessage>
                    <P>{t('account/usermgmt.string8')}</P>
                  </ErrorMessage>
                </Styles.ErrorMsgContainer>
              </Styles.CurrentUsers>
            </Styles.CurrentUser>
          </Styles.AddNewRoleSC>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewRole;
