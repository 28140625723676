import styled from 'styled-components';
import BackgroundImage from 'assets/images/signup_bg.jpg';

export const BgLogin = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  font-weight: 400 !important;
  font-size: 16px !important;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginContainer = styled.div`
  padding-top: 5.8em;
  padding-bottom: 7.7em;
`;

export const LoginBox = styled.div`
  background: #fff;
  margin: 0 auto;
  width: 452px;
  box-shadow: 0 0 24px 5px #d1d1d1;

  
  @media (max-width: 500px) {
    width: 350px;
  }
  @media (max-width: 400px) {
    width: 310px;
  }
  @media (max-width: 330px) {
    width: 290px;
    margin-top: -2.5rem;
  }
`;

export const LoginForm = styled.div`
  padding: 40px;
  padding-top: 30px;
`;

export const Logo = styled.p`
  margin-bottom: 19px;
  line-height: 160%;
`;



