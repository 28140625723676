import styled from 'styled-components';
import Checkbox from 'assets/images/check-box.svg';
import UncheckBox from 'assets/images/uncheck-box.svg';
import { pStyles } from 'components/reusable-css';

export const CheckBox = styled.div`
  padding-top: 5px;
  padding-right: 10px;
`;

export const Input = styled.input`
  appearance: none;
  outline: 0 !important;
  padding: 0;
  margin: 0 !important;
  top: 0;
  background: url(${UncheckBox}) no-repeat;
  height: 25px;
  width: 25px;
  background-size: 25px;
  cursor: pointer !important;
  &:checked {
    background: url(${Checkbox}) no-repeat;
    height: 25px;
    width: 25px;
    background-size: 25px;
  }
`;

export const RememberCheckBox = styled.div`
  padding-right: 10px;
  margin-top: 5px;
  background-color: #cfcfcf;
  display: flex;
  border-radius: 10px;
  border-bottom: 2px solid orange;
  label {
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
  }
`;

export const Icon = styled.i`
  color: #ff7300;
`;
