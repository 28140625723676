import React, { useEffect } from 'react';
import { Card, Heading } from './OperatingSystemCard.style';
import { getOSImage, osPricing } from './util';
import Checkbox from 'components/common/radio-button';
import { useDispatch, useSelector } from 'react-redux';
import { updateCPanelOs } from 'store/actions/vm-deployment';
import { updatePricing as updatePrice } from 'store/actions/vm-deployment';
import _get from 'lodash/get';
import VmUtils from 'pages/member/new-vm/util';
import { useTranslation } from 'react-i18next';
import { Message } from 'components/alert/Message';
import { useMediaQuery } from '@material-ui/core';
import { distributeColumns, sortAlphabetically } from 'utils/sorted-os';

export default function OperatingSystemCard({ OsData, formikProp, updatePricing, template }) {
  const { t } = useTranslation();
  const { values, setFieldValue } = formikProp;
  const cPanelOs = useSelector((state) => state.vmDeployment.cPanelOs);
  const dispatch = useDispatch();
  const { isSliderInterface } = VmUtils();
  const temp = template.find((i) => i.id === values.template);
  const vmState = useSelector((state) => state.vmDeployment.vm_deploy.server_size);
  //Show prices with separate api
  const pricePerSize = (data) => {
    const filteredPrice = data?.prices?.filter((price) => price.size === vmState?.id);
    return filteredPrice;
  };

  useEffect(() => {
    if (cPanelOs) {
      setFieldValue('image', cPanelOs);
      updatePricing(cPanelOs);
    }
  }, [cPanelOs]);

  useEffect(() => {
    updatePricing(values.image);
  }, [values.size]);

  useEffect(() => {
    if (temp) {
      updatePricing(temp.os);
      setFieldValue('image', temp.os);
    }
  }, [temp]);

  const handleClick = (operatingSystem) => {
    if (_get(values, 'template', '')) {
      return Message.error(t('alert6'), t('alert7'));
    }
    if (cPanelOs !== operatingSystem.id) {
      dispatch(updateCPanelOs(''));
      setFieldValue('app', '');
      dispatch(updatePrice(0, 'cpanel'));
    }
    updatePricing(operatingSystem.id);
    setFieldValue('image', operatingSystem.id);
    setFieldValue('image_object', operatingSystem);
    if (Number(values.size_object.memory_mb) < Number(operatingSystem.min_ram_mb)) {
      const elem = document.getElementById('ST1');
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
      setFieldValue('size', '');
      setFieldValue('size_object', {});
      Message.error(t('alert20'), t('alert5'));
    } else if (Number(values.size_object.disk_gb) < Number(operatingSystem.min_disk_gb)) {
      const elem = document.getElementById('ST1');
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
      setFieldValue('size', '');
      setFieldValue('size_object', {});
      Message.error(t('alert21'), t('alert5'));
    } else {
      const elem = document.getElementById('ST4');
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const sm = useMediaQuery('(max-width:599px)');
  const md = useMediaQuery('(max-width:959px)');
  const lg = useMediaQuery('(max-width:1280px)');
  const xl = useMediaQuery('(max-width:1919px)');

  let styling = sm
    ? { width: '100%', number: 1 }
    : md
    ? { width: '50%', number: 2 }
    : lg || xl
    ? { width: '33%', number: 3 }
    : { width: '25%', number: 4 };

  const sortedItems = sortAlphabetically(OsData);
  const columns = distributeColumns(sortedItems, styling.number);

  return (
    <div className="d-flex ">
      {columns.map((col, colIndex) => (
        <div className="d-flex flex-column" key={colIndex} style={{ width: styling.width }}>
          {col.map((operatingSystem) => (
            <Card key={operatingSystem.id} onClick={() => handleClick(operatingSystem)}>
              <Checkbox
                checkbox={values.image}
                setCheckedValue={(value) => setFieldValue('image', value)}
                data={operatingSystem}
                onClick={() => handleClick(operatingSystem)}
              />
              <div>
                <img src={getOSImage(operatingSystem?.family)} width={25} height={25} alt="OS Image" />
              </div>
              <Heading>
                {operatingSystem?.name}
                {!isSliderInterface ? (
                  pricePerSize(operatingSystem).map((price) => <> {osPricing(price)}</>)
                ) : (
                  <> {osPricing(_get(operatingSystem, 'pricing', ''))}</>
                )}
              </Heading>
            </Card>
          ))}
        </div>
      ))}
    </div>
  );
}
