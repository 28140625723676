import ConditionalFragment from 'components/conditional-fragment';
import React, { useEffect, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import lightTheme from 'themes/light-theme';
import { useMutation } from 'react-query';
import { INSTANCE_ACTION } from 'components/manage-vm/queries';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import { CustomHook } from 'utils/custom-hook';

const ServerImageComponent = ({ status, managerVmRefetch, type, inProgress }) => {
  const { t } = useTranslation();
  const { mutationProps } = CustomHook();
  const rights = useSelector(userRights);
  const [popperOpen, setPopperOpen] = useState(false);
  const [osStatus, setOsStatus] = useState(status.os_updates_status);
  const { mutate: refreshServer, isLoading: serverActionsLoading } = useMutation(INSTANCE_ACTION, {
    ...mutationProps('', { errorHeading: t('error') }),
  });
  const togglePopper = () => setPopperOpen((prev) => !prev);
  const handleSubmitBubble = () => {
    togglePopper();
    if (!['not_reporting', 'updating'].includes(status.os_updates_status) && status.os_class !== 'windows') {
      setOsStatus('updating');
      refreshServer(
        { id: status.id, type, action: 'installupdates' },
        {
          onSuccess: () => {
            if (managerVmRefetch) managerVmRefetch();
          },
        },
      );
    }
  };
  useEffect(() => {
    if (status.os_updates_status !== osStatus) setOsStatus(status.os_updates_status);
  }, [status.os_updates_status]);

  return (
    <Styles.ServerImage>
      {status.os_class == 'windows' ? (
        <i className="fa fa-windows fa-2x" />
      ) : status.os_class == 'linux' ? (
        <i className="fa fa-linux fa-2x" />
      ) : null}
      <ConditionalFragment condition={!inProgress}>
        <ConditionalFragment
          condition={(+status.os_updates_available || osStatus === 'not_reporting') && status.status !== 'off'}
        >
          <div
            className={`popup ${(serverActionsLoading || osStatus === 'updating') && 'pointerNone'} ${
              osStatus === 'not_reporting' && 'x-error'
            } ${!(type === 'vs' ? rights.vsupdatecentral : rights.bmupdatecentral) && 'pointerNone'}`}
            aria-disabled={osStatus === 'updating'}
          >
            <Popup
              closable
              flowing
              onOpen={togglePopper}
              open={popperOpen}
              onClose={togglePopper}
              hoverable
              inverted
              preserve
              popper={{
                id: 'popper-container',
                style: { zIndex: 2000 },
                className: `${osStatus === 'not_reporting' && 'x-error'}`,
              }}
              on="hover"
              position="top left"
              style={{
                padding: 10,
                right: '2px',
                left: 'unset',
                background:
                  osStatus === 'not_reporting' ? lightTheme.redColor : lightTheme.serverSizeCard.tag.backgroundColor,
                width: 230,
                border: '1px solid white',
              }}
              trigger={
                <div
                  onClick={handleSubmitBubble}
                  // onMouseEnter={() => setPopperOpen(false)}
                  // onMouseLeave={() => setPopperOpen(true)}
                >
                  {osStatus === 'updating' ? (
                    <div className="os_updates_available update">{t('vs/instance.string37')}</div>
                  ) : (
                    <React.Fragment>
                      <ConditionalFragment condition={osStatus === 'not_reporting'}>
                        <div className="os_updates_available px-2 os_error">x</div>
                      </ConditionalFragment>
                      <ConditionalFragment condition={osStatus !== 'not_reporting'}>
                        <div className={`os_updates_available  px-4 ${status.os_class}`}>
                          {status.os_updates_available}
                        </div>
                      </ConditionalFragment>
                    </React.Fragment>
                  )}
                </div>
              }
            >
              <Popup.Content>
                <div className="popup-content px-4">
                  <ConditionalFragment condition={osStatus === 'not_reporting'}>
                    {t('vs/instance.string38')}
                  </ConditionalFragment>
                  {status?.os_class == 'windows' ? (
                    <ConditionalFragment condition={osStatus !== 'not_reporting'}>
                      <Trans i18nKey={'vs/instance.string39'} values={{ value: +status.os_updates_available }} />
                    </ConditionalFragment>
                  ) : (
                    <ConditionalFragment condition={osStatus !== 'not_reporting'}>
                      <Trans i18nKey={'vs/instance.string40'} values={{ value: +status.os_updates_available }} />
                    </ConditionalFragment>
                  )}
                </div>
              </Popup.Content>
            </Popup>
          </div>
        </ConditionalFragment>
      </ConditionalFragment>
    </Styles.ServerImage>
  );
};

export const ServerImage = React.memo(ServerImageComponent);
