import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { planlevel0, planlevel1, planlevel2 } from 'constants/bm-step4';
import * as Styles from './ManagedPlanCard.style';
import { useSelector } from 'react-redux';
import { UpperCaseWord } from 'assets/css/common-styles';

export default function ManagedPlanCard({ data, selected, onClick }) {
  const userAccount = useSelector((state) => state.user.account);
  const { t } = useTranslation();
  return (
    <Styles.ManagedPlanCardSC data-testid="managed-plan-card" selected={selected} onClick={() => onClick(data)}>
      {data.id == 'planlevel0' ? (
        <div>
          <Styles.NameContainer>
            <Styles.Name>{t('bm/instance/create.step4_string5')}</Styles.Name>
          </Styles.NameContainer>
          <Styles.Price>
            <Styles.PerMonth>
              <Trans
                i18nKey="bm/instance/create.step4_string9"
                values={{
                  price: data?.pricing?.price_monthly_usd,
                  currency: userAccount.account_currency,
                  currency_symbol: userAccount.account_currency_symbol,
                }}
                components={{ price: <Styles.PriceUnit />, uppercase: <UpperCaseWord /> }}
              />
            </Styles.PerMonth>
          </Styles.Price>
          <Styles.Features>
            {planlevel0.map((feature) => (
              <Styles.Feature key={feature.id}>
                <Icon name="check" />
                <Trans i18nKey={'bm/instance/create.' + feature.lanRef} />
              </Styles.Feature>
            ))}
          </Styles.Features>
        </div>
      ) : data.id == 'planlevel1' ? (
        <div>
          <Styles.NameContainer>
            <Styles.Name>{t('bm/instance/create.step4_string6')}</Styles.Name>
          </Styles.NameContainer>
          <Styles.Price>
            <Styles.PerMonth>
              <Trans
                i18nKey="bm/instance/create.step4_string9"
                values={{
                  price: data?.pricing?.price_monthly_usd,
                  currency: userAccount.account_currency,
                  currency_symbol: userAccount.account_currency_symbol,
                }}
                components={{ price: <Styles.PriceUnit />, uppercase: <UpperCaseWord /> }}
              />
            </Styles.PerMonth>
          </Styles.Price>
          <Styles.Features>
            {planlevel1.map((feature) => (
              <Styles.Feature key={feature.id}>
                <Icon name="check" />
                <Trans i18nKey={'bm/instance/create.' + feature.lanRef} />
              </Styles.Feature>
            ))}
          </Styles.Features>
        </div>
      ) : data.id == 'planlevel2' ? (
        <div>
          <Styles.NameContainer>
            <Styles.Name>{t('bm/instance/create.step4_string7')}</Styles.Name>
          </Styles.NameContainer>
          <Styles.Price>
            <Styles.PerMonth>
              <Trans
                i18nKey="bm/instance/create.step4_string9"
                values={{
                  price: data?.pricing?.price_monthly_usd,
                  currency: userAccount.account_currency,
                  currency_symbol: userAccount.account_currency_symbol,
                }}
                components={{ price: <Styles.PriceUnit />, uppercase: <UpperCaseWord /> }}
              />
            </Styles.PerMonth>
          </Styles.Price>
          <Styles.Features>
            {planlevel2.map((feature) => (
              <Styles.Feature key={feature.id}>
                <Icon name="check" />
                <Trans i18nKey={'bm/instance/create.' + feature.lanRef} />
              </Styles.Feature>
            ))}
          </Styles.Features>
        </div>
      ) : null}

      {selected && (
        <Styles.SelectedTag>
          <Icon name="check" />
          {t('bm/instance/create.step4_string8')}
        </Styles.SelectedTag>
      )}
    </Styles.ManagedPlanCardSC>
  );
}

ManagedPlanCard.propTypes = {
  data: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ManagedPlanCard.defaultProps = {
  selected: false,
};
