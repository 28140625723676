export const changelogs_en = [
  {
    versions: [
      {
        name: 'Version 6.2024.01.28',
        date: 'January 28, 2024',
      },
    ],

    details: [
      {
        topHeading: 'Welcome to a new version of ServerPoint\'s Client Portal!',
        topPara: 'This new version brings a new structural change to our code: the ability to be multi-language!',
        bottomPara: 'We hope you love these new changes!',
        detail: [
          {
            name: 'General changes',
            descriptions: [
              {
                description: 'Our portal now supports language packs!',
              },
              {
                description: 'Our first language pack, aside from the default English, is Spanish! Click on the language selector at the top right to change language. More languages coming soon!',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    versions: [
      {
        name: 'Version 6.1a',
        date: 'October 5th, 2023',
      },
    ],

    details: [
      {
        topHeading: 'Welcome to version 6.1a!',
        topPara: 'Now, version 6.1a brings some new features to make your experience more pleasant. These were to be released with version 6.1, however, there were some delays in their implementation.',
        bottomPara: 'We hope you love these new changes!',
        detail: [
          {
            name: 'General changes',
            descriptions: [
              {
                description: 'Introducing two factor authentication! For an added layer of security, add your SMS phone number into our system. Whenever you log in, we will text you an additional security code that you will need to log in.',
              },
            ],
          },
          {
            name: 'Changes to ColossusCloud virtual servers and bare metal dedicated servers',
            descriptions: [
              {
                description: 'New DNS hosting service! Host your domains in our new DDoS protected DNS infrastructure. You will no longer have to deal with signing up to a third party DNS hosting provider or using the DNS servers at your domain registrar. And as time goes by, we will continue expanding this service with additional types of DNS record!',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    versions: [
      {
        name: 'Version 6.1',
        date: 'July 4th, 2023',
      },
    ],

    details: [
      {
        topHeading: 'Welcome to version 6.1!',
        topPara: 'We have introduced some great improvements to the speed and efficiency of this Client Portal, as well as fixed many bugs that we, and our loyal clients, have reported.',
        topPara2: 'Now, version 6.1 brings some new features to make your experience more pleasant.',
        bottomPara: 'We hope you love these new changes!',
        detail: [
          {
            name: 'General changes',
            descriptions: [
              {
                description: 'A new structure has been introduced to make responsiveness easier to adjust. Over the next few months, we will continue adjusting the Client Portal\'s interface to the many devices and resolutions our customers use.',
              },
            ],
          },
          {
            name: 'Changes to ColossusCloud virtual servers',
            descriptions: [
              {
                description: 'We have now introduced our newest generation of virtual servers: G4! They are powered by Intel Xeon Gold processors, with a faster CPU frequency and RAM speeds. Deploy a few G4 virtual servers today!',
              },
            ],
          },
          {
            name: 'Changes to communicating with our support staff',
            descriptions: [
              {
                description:
                  'We have introduced the first version of our new support ticket system! Communicating with our staff for support, sales and billing issues is now easier than ever before. Just click on Support on the menu to the left to begin.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    versions: [
      {
        name: 'Version 6.0',
        date: 'June 4th, 2022',
      },
    ],

    details: [
      {
        topHeading: 'Welcome to version 6.0!',
        topPara: `For over a year, we have been working on bringing ServerPoint's Client Portal to today's modern coding standards. This entire portal has been rewritten in ReactJS and it is now what is known as a serverless application, interfacing with our systems via a brand new API.`,
        bottomPara: 'We hope you love it!',
        detail: [
          {
            name: '',
            descriptions: [
              {
                description:
                  'This portal is now hosted, and globally balanced, from dozens of data centers around the world. This means that it will be fast, no matter where you are.',
              },
              {
                description: 'We will now be able to add features faster than ever before.',
              },
              {
                description:
                  'It is more responsive! It will look great on just about any device. If you find design flaws when using this portal in your device, please let us know!',
              },
            ],
          },
          {
            name: 'API',
            descriptions: [
              {
                description:
                  'Yes! We now have an API! Anything that you can do via this Client Portal, it will be doable via our new API. We will soon publish its full documentation!',
              },
            ],
          },
          {
            name: 'General changes',
            descriptions: [
              {
                description:
                  'Our user management interface has been completely redesigned. It is so much more convenient now!',
              },
              {
                description: 'Adding funds/making payments is also a lot easier now.',
              },
              {
                description: 'Now you can manage multiple credit cards.',
              },
            ],
          },
          {
            name: 'Changes to ColossusCloud virtual servers',
            descriptions: [
              {
                description:
                  'Now you have the ability to assign tags to your virtual servers. We will soon offer the ability to filter by tags!',
              },
              {
                description:
                  'We have added many new operating systems! Including Windows 2022, AlmaLinux, RockyLinux, Ubuntu 22.04 LTS and Debian 11.',
              },
              {
                description:
                  'Bonus points! When you pay for a full year, you will get bonus bucks equivalent to two free months of your virtual server! Fees for cPanel, Windows and other third party applications are excluded.',
              },
              {
                description: 'You can sort virtual servers by name, operating system, IP numbers and more.',
              },
              {
                description: 'You can now pay with a credit card or PayPal directly in the deployment screen.',
              },
              {
                description:
                  'We have made great improvements to the keyboard/video/mouse function of every virtual server.',
              },
              {
                description: 'CPU, RAM and disk usage statistics are much more accurate and better presented.',
              },
              {
                description:
                  'When you click the gear icon next to a virtual server, you will find a completely redesigned settings interface that is a lot easier to use.',
              },
              {
                description:
                  'Click on the ... next to a virtual server for a new menu! Also, it is easier to delete a virtual server now (which you can undelete in case you make a mistake).',
              },
              {
                description: 'Speaking of that, the interface to undelete virtual servers is now a lot easier to use.',
              },
            ],
          },
          {
            name: 'Changes to bare metal servers / dedicated servers',
            descriptions: [
              {
                description: 'Deploying a new bare metal server is now so much easier than before.',
              },
              {
                description:
                  'Bonus points! When you pay for half or full year, you will get bonus bucks equivalent to 1 or 2 free months of service! Fees for cPanel, Windows and other third party applications are excluded.',
              },
              {
                description: 'Deploying a bare metal server with cPanel is now fully automated.',
              },
              {
                description: 'You can now pay with a credit card or PayPal directly in the deployment screen.',
              },
              {
                description:
                  'You can buy managed plans directly from the deployment interface. Our staff will then contact you to set up their features.',
              },
              {
                description: 'You can sort bare metal servers by name, operating system, IP numbers and more.',
              },
              {
                description:
                  'Now you have the ability to assign tags to your bare metal servers. We will soon offer the ability to filter by tags!',
              },
              {
                description: 'CPU, RAM and disk usage statistics are much more accurate and better presented.',
              },
            ],
          },
          {
            name: 'Web hosting, DNS and domain registrations',
            descriptions: [
              {
                description:
                  'These functions, which exist in our generation 3 portal used by our shared web hosting clients will soon be here!',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    versions: [
      {
        name: 'Version 5.1.2',
        date: 'March 8th, 2021',
      },
    ],
    details: [
      {
        detail: [
          {
            name: 'Changes to bare metal/dedicated servers.',
            descriptions: [
              {
                description:
                  'Our system to automatically deploy dedicated servers is back! Improved and better than ever, with many servers available to deploy in under an hour!',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    versions: [
      {
        name: 'Version 5.1',
        date: 'October 2, 2020',
      },
    ],
    details: [
      {
        detail: [
          {
            name: 'Major internal updates!',
            descriptions: [
              {
                description:
                  'This release features new changes in the interfaces foundation, to allow for future designs of interfaces that are more mobile friendly.',
              },
              {
                description: 'Menus, headers, footers, are all mobile friendly now.',
              },
            ],
          },
          {
            name: 'Changes to ColossusCloud',
            descriptions: [
              {
                description:
                  'The interface to deploy virtual servers has gone through a complete redesign! It is now much easier and friendly to deploy a virtual server.',
              },
            ],
          },
          {
            name: 'General changes',
            descriptions: [
              {
                description:
                  'A new billing interface! It is now much easier to manage your credit cards, as well as the ability to pay for your services.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    versions: [
      {
        name: 'Version 5.0',
        date: 'September 20th, 2018',
      },
    ],
    details: [
      {
        detail: [
          {
            name: 'Welcome to ServerPoint\'s new Client Portal!',
            descriptions: [
              {
                description:
                  'This release introduces a completely redesigned user interface experience. Not only that, but the code base has been changed to make it easier and faster for our developers to add new features! Just make sure you submit feedback!',
              },
            ],
          },
          {
            name: 'Introducing ColossusCloud Generation 5! An effort of almost a year to improve technology and performance across our entire platform.',
            descriptions: [
              {
                description: 'Shorter, easier new portal URL! https://portal.serverpoint.com.',
              },
              {
                description:
                  'Shorter virtual server deployment times! Both Linux and Windows Virtual Servers deploy much faster than before. Sometimes in under 30 seconds!',
              },
              {
                description: 'ColossusCloud now runs on the latest, and faster, Xen 4.11.',
              },
              {
                description:
                  'Major improvements to snapshots! Snapshots now occur within a minute or two and can be re-used to create new VMs in no time!',
              },
              {
                description:
                  'New backup infrastructure and backup plans! Backups are now kept on a separate system, powered by a very fast incremental, and dedup enabled, backup software. This is now enabled in our Las Vegas, NV and Dallas, TX region and will be enabled in all regions by the end of September, 2018.',
              },
              {
                description:
                  'Improved stability! A bug in old Windows drivers could sometimes cause random crashes. These old drivers have been replaced in Windows VMs of all customers.',
              },
              {
                description: 'Brand new, smaller Windows images that will take up less space in your VMs disk.',
              },
              {
                description:
                  'Brand new 40G/100G core switches at all of our locations with improved failover technologies.',
              },
              {
                description:
                  'This portal is now load balanced across more servers to provide you with a faster experience.',
              },
              {
                description:
                  'Capacity has been increased at all our regions. More servers, more RAM and CPU to spread clients over more servers and reduce load.',
              },
            ],
          },
        ],
      },
    ],
  },
];
