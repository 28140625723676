import React from 'react';
import { useQuery } from 'react-query';
import { BANDWIDTH } from './queries';
import LogoImage from 'assets/images/serverpoint.svg';
import WhiteLogoImage from 'assets/images/serverpoint-white.svg';
import { BMCharts } from 'components/manage-vm/statistics-panel/components/BMCharts';
import { Dot, Chart, Footer, ChartWidth } from './ManageBM.style';
import { social } from 'constants/manager-vm';
import Loading from 'components/common/loading/Loading';
import { useHistory } from 'react-router-dom';

import { bandwithGraphImages } from './util';
const Bandwidth = () => {
  const history = useHistory();
  const { data: bandwidth, isLoading: bandwidthLoading } = useQuery(
    ['BANDWIDTH', { pathname: history.location.pathname }],
    BANDWIDTH,
  );
  if (bandwidthLoading) {
    return <Loading gap={50} />;
  }
  return (
    <div style={{ borderTop: '3px solid #ff7300' }} className="d-flex flex-column align-items-center">
      <div className="py-4 p-2 mb-1">
        <img src={LogoImage} alt="serverpoint" width={300} />
      </div>
      <div style={{ width: '100%', border: '1px solid lightgray' }} />
      <div className="py-5 p-2 text-center">
        <h1>Today's bandwidth usage for all your dedicated servers</h1>
        <p className="pt-3 text-center" style={{ fontSize: '18px' }}>
          Please monitor them closely to avoid any unexpected bandwidth costs.
        </p>
      </div>
      <div style={{ width: '100%' }}>
        {bandwidth?.instance?.map((data) => (
          <div key={data.id}>
            <div style={{ width: '100%', border: '1px solid lightgray' }} />
            <ChartWidth className="mx-auto pt-4 pb-5" key={data.id}>
              <Dot>
                <div className="dot" />
                <h3 className="pl-2 pt-3">{data.label}</h3>
              </Dot>
              <Chart>
                <BMCharts graphImages={bandwithGraphImages} data={data} />
              </Chart>
            </ChartWidth>
          </div>
        ))}
      </div>

      <div className="w-100 pt-4 mt-5">
        <Footer>
          <div className="first">
            <p>
              Copyrights © All Rights Reserved by A+ Hosting, Inc., DBA ServerPoint.com, proudly located in the great
              state of Nevada, with data centers located around the globe.
            </p>
            <p>
              Deploy Linux and Windows virtual private servers, cloud servers and cloud hosting in Amsterdam
              Netherlands, Las Vegas Nevada, Dallas Texas, Ashburn Virginia, Santa Clara (Silicon Valley) California and
              Singapore!
            </p>
          </div>
          <div className="second">
            <div className="d-flex flex-column align-items-end">
              <img src={WhiteLogoImage} alt="serverpoint" width={300} />
              <div className="d-flex mt-3 flex-wrap">
                {social?.map((val, index) => (
                  <a key={index} rel="noreferrer" href={val.link} target="_blank">
                    <i className={val.name} />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Footer>
      </div>
    </div>
  );
};
export { Bandwidth };
