import styled from 'styled-components';
import { deviceBreakpoints, variables } from 'variables';

export const NewVMStep2SC = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  padding: ${variables.pageContentPadding}px;
  padding-top: 60px;
  // padding-top: 80px;
  padding-bottom: 0px;
  padding-right: 0px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  @media only screen and (max-width: ${deviceBreakpoints.mobile}px) {
    padding-top: 0;
  }
`;
