import React from 'react';
import * as Styles from 'pages/member/dns/DNS.style';
import { H6 } from 'components/reusable-css';
import Input from 'components/input';
import CustomButton from 'components/common/custom-button/custom-button';
import { Form, Formik } from 'formik';
import { addRecordsValidation, addRecordsValues } from '../util';
import { useTranslation } from 'react-i18next';

const CNameForm = ({ handleAddRecord, loading }) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={addRecordsValues('CNAME', '')}
      validationSchema={addRecordsValidation('cname')}
      onSubmit={(values, { resetForm }) => handleAddRecord(values, resetForm)}
    >
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <Form>
          <Styles.SaveContainer>
            <Styles.SaveContainerColumn>
              <H6>{t('dns/instance.string31')}</H6>
              <Input
                disabled
                placeholderClass
                styling={{ marginTop: '-6px', maxWidth: '8rem' }}
                name={'type'}
                value={values['type']}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors['type']}
                touched={touched['type']}
              />
            </Styles.SaveContainerColumn>
            <Styles.SaveContainerColumn>
              <H6>{t('dns/instance.string32')}</H6>
              <Input
                placeholderClass
                styling={{ marginTop: '-6px', width: '100%' }}
                name={'name'}
                value={values['name']}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors['name']}
                touched={touched['name']}
              />
            </Styles.SaveContainerColumn>
            <Styles.SaveContainerColumn>
              <H6>{t('dns/instance.string37')}</H6>
              <Input
                placeholderClass
                styling={{ marginTop: '-6px', width: '100%' }}
                name={'value'}
                value={values['value']}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors['value']}
                touched={touched['value']}
              />
            </Styles.SaveContainerColumn>
            <Styles.SaveContainerbtnColumn>
              <CustomButton isLoading={loading} type="submit" buttonStyles={{ height: '49px', padding: '0 2rem' }} />
            </Styles.SaveContainerbtnColumn>
          </Styles.SaveContainer>
        </Form>
      )}
    </Formik>
  );
};
export default CNameForm;
