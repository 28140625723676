import * as yup from 'yup';
import { usernameRegex } from './regex';

export const LABEL_SCHEMA = yup.object().shape({
  label: yup
    .string()
    .required()
    .matches(/^[a-zA-Z0-9-_.]+[a-zA-Z0-9-_. ]*$/, 'Is not in correct format'),
});
export const TAG_SCHEMA = yup.object().shape({
  label: yup
    .string()
    .required()
    .max(32, 'vs/instance/string175')
    .matches(/^[a-zA-Z0-9]*$/, 'Is not in correct format'),
});

export const EMAIL_YUP = yup.string().email('Must be a valid email');

export const USER_NAME = yup.object().shape({
  password_recovery_email: yup.string().required().matches(usernameRegex, 'Please enter email or username'),
});

export const EMAIL = yup.object({
  password_recovery_email: yup.string().required().email('Must be a valid email'),
});
