/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-27 17:39:29
 * @modify date 2021-01-27 17:40:49
 */

/* eslint-disable no-undef */
let MOCK_BASE_URL = process.env.REACT_APP_MOCK_BASE_URL || '';

const Config = {
  appName: 'Sharepoint',
  appTitle: 'ServerChameleon, by ServerPoint.com',
  apiTimeout: 30000,
  mockBaseURL: MOCK_BASE_URL,
  accessTokenLocalStoreKey: 'access_token',
};

export default Config;
