/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-27 18:15:34
 * @modify date 2021-02-05 10:00:25
 */

import { UPDATE_ACTIVE_MODAL, UPDATE_THEME, TOGGLE_APP_DRAWER, UPDATE_ACTIVE_SECTION_INDEX } from 'store/types';

import { getDefaultTheme } from 'utils/theme-util';

import { saveInLocalStore, getFromLocalStore } from 'utils/state-persistence-util';

const PREFIX = 'commonStates';

const initialState = {
  activeModal: '',
  isAppDrawerOpen: false,
  theme: getFromLocalStore('theme', PREFIX, getDefaultTheme()), // Theme can be integrated with General settings API later.
  activeSectionIndex: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.payload,
      };
    case TOGGLE_APP_DRAWER:
      return {
        ...state,
        isAppDrawerOpen: !state.isAppDrawerOpen,
      };
    case UPDATE_THEME:
      saveInLocalStore('theme', action.payload, PREFIX);
      return {
        ...state,
        theme: action.payload,
      };
    case UPDATE_ACTIVE_SECTION_INDEX:
      return {
        ...state,
        activeSectionIndex: action.payload,
      };
    default:
      return state;
  }
}
