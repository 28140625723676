export const extraFeaturesBM = [
  {
    id: 1,
    dataKey: 'extra_hardware_second_disk_count',
    dataKey2: 'extra_hardware_second_disk_size',
    dataKey3: 'extra_hardware_second_disk_type',
    lanRef: 'extra_hardware',
    lang: 'step1_string19',
  },
];

export const extraMegaraidFeaturesBM = [
  {
    id: 1,
    lanRef: 'step1_string18',
  },
];

export const features = [
  {
    id: 1,
    dataKey: 'size',
    lanRef: 'model',
    lang: 'step1_string20',
    plural_lang: 'step1_string20',
  },
  {
    id: 2,
    dataKey: 'ram',
    dataKey2: 'ram_type',
    lanRef: 'ram',
    lang: 'step1_string21',
    plural_lang: 'step1_string22',
  },
  {
    id: 3,
    dataKey: 'disk_count',
    dataKey2: 'disk_size',
    dataKey3: 'disk_type',
    lanRef: 'disk',
    lang: 'step1_string23',
    plural_lang: 'step1_string23',
  },
  {
    id: 4,
    dataKey: 'cpu_speed',
    dataKey2: 'cpu_sockets',
    lanRef: 'cpu_speed',
    langSingle: 'step1_string32',
    langDual: 'step1_string33',
    langQuad: 'step1_string34',
  },

  {
    id: 5,
    dataKey: 'cpu_count',
    dataKey2: 'cpu_threads',
    lanRef: 'cpu_count',
    lang: 'step1_string25',
    plural_lang: 'step1_string26',
  },
  {
    id: 6,
    dataKey: 'nic_port',
    lanRef: 'redundant_network',
    lang: 'step1_string27',
    plural_lang: 'step1_string27',
  },
  {
    id: 7,
    dataKey: 'redundant_power',
    lanRef: 'redundant_power',
    lang: 'step1_string28',
    plural_lang: 'step1_string28',
  },
  {
    id: 8,
    dataKey: 'data_transfer_per_month',
    lanRef: 'data_transfer',
    lang: 'step1_string29',
    plural_lang: 'step1_string30',
  },
];
