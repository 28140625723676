import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useMutation } from 'react-query';
import { USER_VERIFY } from 'utils/queries';
import Loading from 'components/common/loading';
import { CustomHook } from 'utils/custom-hook';

const SignupApprove = () => {
  const { mutationProps } = CustomHook();
  const history = useHistory();
  let params = queryString.parse(history?.location?.search);
  const { mutate, isLoading } = useMutation(USER_VERIFY);

  // FOR VERIFYING USER ON PARAMS CODE
  useEffect(() => {
    mutate(
      {
        code: params.code,
      },
      mutationProps(() => {}, {
        onSuccess() {
          history.push('/?signup_approve=true');
        },
        onError() {
          history.push('/');
        },
        isSuccess: false,
      }),
    );
  }, []);
  if (isLoading) {
    return <Loading gap={50} background="transparent" />;
  }

  return <div />;
};

export default SignupApprove;
