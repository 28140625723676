/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-02 10:47:14
 * @modify date 2021-02-02 10:48:36
 */

import styled from 'styled-components';

export const HighlightSC = styled.span`
  color: #ff7e00;
  font-weight: 600;
`;