export const sortAlphabetically = (array) => {
  return array?.sort((a, b) => a.name.localeCompare(b.name));
};

export const distributeColumns = (array, columns) => {
  const columnHeight = Math.ceil(array?.length / columns);
  let result = Array.from({ length: columns }, () => []);
  array?.forEach((item, index) => {
    result[Math.floor(index / columnHeight)].push(item);
  });
  return result;
};
