import styled from 'styled-components';
import Checkbox from 'assets/images/check-box.svg';
import UncheckBox from 'assets/images/uncheck-box.svg';

export const InvoicesSC = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  padding: 3em 2em 4em 2em;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  @media only screen and (max-width: 768px) {
    padding: 1em 0.5em;
  }
`;

export const Input = styled.input`
  appearance: none;
  outline: 0 !important;
  padding: 0;
  margin: 0 !important;
  top: 0;
  background: url(${UncheckBox}) no-repeat;
  height: 25px;
  width: 25px;
  background-size: 25px;
  cursor: pointer !important;
  &:checked {
    background: url(${Checkbox}) no-repeat;
    height: 25px;
    width: 25px;
    background-size: 25px;
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 3rem 0.5rem 1rem 0.5rem;
  h6 {
    margin: 0;
    font-weight: bold;
  }
`;

export const Content = styled.div`
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  padding: 0.5rem;
  border-radius: 8px;
`;

export const Rows = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
  border-bottom: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  :last-child {
    border-bottom: 0px;
  }
  h6 {
    margin: 0;
  }
  .modal-open {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const DownloadAll = styled.div`
  display: flex;
  color: ${(props) => props.theme.primaryDarkColor};
  margin-top: 2em;
  margin-left: 2.6em;
  text-decoration: underline;
  p {
    margin: 0;
    margin-left: 5px;
    cursor: ${({ isChecked }) => (isChecked ? 'pointer' : 'auto')};
  }
  i {
    color: ${(props) => props.theme.primaryDarkColor};
  }
  width: 24em;
`;

export const MobileRows = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 0;
  border-bottom: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  :last-child {
    border-bottom: 0px;
  }
  h6 {
    margin: 0;
  }
  @media only screen and (max-width: 500px) {
    flex-wrap: wrap !important;
  }
`;

export const MobileContent = styled.div`
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  padding: 0.5rem;
  border-radius: 8px;
  margin-top: 3em;
`;

export const MobileLine = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  .width {
    width: 10em;
  }
  .modal-open {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const MobileButtonIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  padding-bottom: 1rem;
  i {
    cursor: pointer;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
    align-items: center;
    flex-direction: row;
    margin-left: 1.9em;
    margin-top: 0.5em;
  }
`;

export const DialogLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 1em 0.5em 0 0;
  .download {
    display: flex;
    align-items: center;
    margin: 1em 2em 0 0;
  }
  .download-link {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }
  i {
    cursor: pointer;
  }
`;

export const PayAllDue = styled.div`
  margin-top: 2em;
  margin-right: 3em;
   margin-left: 2.6em;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
