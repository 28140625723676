import styled from 'styled-components';

export const AccountSC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.pageBackgroundColor};
  width: 100%;
  color: black;
  padding: 1em 3em 0 3em;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  @media only screen and (max-width: 768px) {
    padding: 1em 0.5em;
  }
`;

export const Accounts = styled.div`
  padding-top: 5px;
`;

export const Paragraphs = styled.div`
  padding: 1.5em 0 0 0.3em;
  p {
    margin-bottom: 1.3rem;
  }
`;

export const MidContent = styled.div`
  padding-left: 2.3em;
  @media only screen and (max-width: 550px) {
    padding-left: 0.5rem;
  }
`;

export const Prices = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Price = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  min-height: 40px;
  width: 45%;
  padding: 0 1.5em;
  cursor: pointer !important;
  border-bottom: 2px solid transparent;
  font-family: open sans, verdana, arial, sans-serif !important;
  h6 {
    white-space: nowrap;
    margin: 0;
  }
  &:hover {
    background-color: #cfcfcf;
    transition: background-color 0.3s ease;
    border-radius: 15px;
    border-bottom: 2px solid orange;
  }
  @media only screen and (min-width: 2800px) {
    width: 15%;
  }
  @media only screen and (max-width: 2800px) {
    width: 20%;
  }
  @media only screen and (max-width: 2250px) {
    width: 25%;
  }
  @media only screen and (max-width: 1900px) {
    width: 30%;
  }
  @media only screen and (max-width: 1400px) {
    width: 40%;
  }
  @media only screen and (max-width: 1000px) {
    width: 45%;
  }
  @media only screen and (max-width: 850px) {
    width: 60%;
  }
  @media only screen and (max-width: 600px) {
    width: 70%;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const InputPaddingLeft = styled.div`
  padding-left: 1.5em;
  margin-top: -1.5rem;
  @media only screen and (max-width: 768px) {
    padding-left: 1.4em;
  }
`;

export const PaymentButton = styled.div`
  margin-top: -4rem;
  border-left: ${({ card }) => (card ? '1px solid orange' : '0px')};
  padding-left: ${({ card }) => (card ? '2rem' : '0rem')};
  margin-top: ${({ card }) => (card ? '-5rem' : '0rem')};
  padding-bottom: ${({ card }) => (card ? '1.5rem' : '0rem')};
`;
