import { gbToTBs } from 'utils'; //divided by 1000(Peter said soo)
export const filterDataKey2 = (datakey2, lanRef) => {
  if (lanRef == 'ram') {
    return datakey2 ? datakey2?.toUpperCase() : '';
  }
  if (lanRef == 'disk') {
    if (datakey2 >= 1000) return gbToTBs(datakey2).toFixed(2);
  }
  if (lanRef == 'cpu_speed') {
    if (datakey2 == 1) return 'Single';
    if (datakey2 == 2) return 'Dual';
    if (datakey2 == 4) return 'Quad';
  }
  if (lanRef == 'extra_hardware') {
    if (datakey2 >= 1000) return gbToTBs(datakey2);
  }
  return datakey2;
};

export const filterMemoryType = (datakey2, lanRef) => {
  if (lanRef == 'disk') {
    if (datakey2 >= 1000) return 'TB';
    else {
      return 'GB';
    }
  }
  if (lanRef == 'extra_hardware') {
    if (datakey2 >= 1000) return 'TB';
    else {
      return 'GB';
    }
  }
  return datakey2;
};

export const filterCount2 = (dataKey3, lanRef) => {
  if (lanRef == 'disk') {
    if (dataKey3 === 'nvme') {
      return 'NVMe';
    }
    return dataKey3 ? dataKey3.toUpperCase() : '';
  }
  if (lanRef == 'extra_hardware') {
    return dataKey3 ? dataKey3.toUpperCase() : '';
  }
  return dataKey3;
};
