/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-02 23:27:35
 * @modify date 2021-02-02 23:30:32
 */

import styled from 'styled-components';

export const LoadingSC = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.gap}px;
  background: ${(props) => props.background || props.theme.pageBackgroundColor};
  height: 100%;
  .loading-img {
    height: 100px;
  }
  &.h-full {
    height: 100vh;
  }
`;
