import React, { useState } from 'react';
import ManageVM from './ManageVM';
import { useQuery } from 'react-query';
import Loading from 'components/common/loading';
import { useDispatch, useSelector } from 'react-redux';
import { navLinks } from 'utils/nav-links-util';
import { MANAGE_VM } from 'components/manage-vm/queries';
import { managerVmSortBy, menuOptions } from 'constants/manager-vm';
import { vmDropDownItems } from 'components/manage-vm/utils';
import { get } from 'lodash';
import { handleVmRefetch } from 'store/actions/vm-deployment';
import { userRights } from 'store/selectors/user-details';
import { CustomHook } from 'utils/custom-hook';

export default function ManageVMContainer({ style, refetchMenu }) {
  const { paginationToUrl, getParams, per_page } = CustomHook();
  const dispatch = useDispatch();
  const rights = useSelector(userRights);
  const userUI = useSelector((state) => state.user.ui);
  const sort_by = getParams.sort_by || managerVmSortBy[0].value;
  const [queries, setQueries] = useState({
    page_number: getParams.page_number || 1,
    per_page: getParams.per_page || per_page,
    sort_by,
  });
  const { data: vmStatusData, refetch: vmStatusRefetch, isLoading: statusLoad } = useQuery('/vs/status', {
    refetchInterval: Number(userUI.refresh_every_x_seconds) * 1000,
  });
  const { data: vmNetworkData, isLoading: networkLoad } = useQuery('/vs/instance/ipv4', {
    refetchInterval: Number(userUI.refresh_every_x_seconds) * 1000,
  });
  const { data: vmData, isLoading, refetch: managerVmRefetch, isFetching: managerVmIsFetching } = useQuery(
    ['MANAGE_VM', queries],
    MANAGE_VM,
    {
      onSuccess: (res) => {
        paginationToUrl(navLinks.manage_your_vms.link, +res?.meta?.page_number, queries.sort_by, +res?.meta?.per_page);
        dispatch(handleVmRefetch(managerVmRefetch));
      },
    },
  );
  if (isLoading || statusLoad || networkLoad) {
    return <Loading gap={200} />;
  }
  return (
    <ManageVM
      style={style}
      vmData={vmData}
      setQueries={setQueries}
      queries={queries}
      managerVmRefetch={managerVmRefetch}
      managerVmIsFetching={managerVmIsFetching}
      dropdownItems={vmDropDownItems(rights, refetchMenu)}
      network="vs"
      menuOptions={menuOptions}
      statuses={vmStatusData}
      vmStatusRefetch={vmStatusRefetch}
      vmNetworkData={get(vmNetworkData, 'vs_ipv4', [])}
    />
  );
}
