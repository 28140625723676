import { h4Styles, h6Styles, pStyles } from 'components/reusable-css';
import styled, { keyframes } from 'styled-components';
import BackgroundImage from 'assets/images/bg_login_portal-client_grey.svg';

export const PassSC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.pageBackgroundColor};
  width: 100%;
  color: black;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;

export const Pass = styled.div`
  margin: 70px 45px 6em 40px;
  @media only screen and (max-width: 786px) {
    margin: 30px 20px 6em 0;
  }
`;

export const ChangePasswords = styled.div`
  margin: 20px 0 0 4em;
  p {
    padding-bottom: 10px;
  }
  @media only screen and (max-width: 786px) {
    margin: 20px 0 0 3.5em;
    p {
      padding-bottom: 0;
    }
  }
  @media only screen and (max-width: 425px) {
    margin: 20px 0 0 2.6em;
  }
`;

export const FormDiv = styled.div`
  padding-bottom: 2.4em;
  .flexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .error_messageSty {
    color: white;
    background-color: #d80000;
    font-family: sans-serif;
    padding: 0.2rem 0.5rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: -20px;
    margin-top: -6px;
    text-align: right;
  }
`;

export const Label = styled.h6`
  padding-top: 2.5rem;
  font-weight: bold;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;

export const Warning = styled.div`
  margin-top: 2em;
  margin-left: 4em;
  @media only screen and (max-width: 786px) {
    margin-left: 3.5em;
  }
  @media only screen and (max-width: 425px) {
    margin-left: 2.6em;
  }
`;

// -----------------Verification code ------------------------------------------  //

export const SignInSC = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  font-weight: 400 !important;
  font-size: 16px !important;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignInContainer = styled.div`
  margin: 0 auto;
  padding: 2em 0;
`;

export const LogInButton = styled.div`
  width: auto;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 12px;
  @media (max-width: 500px) {
    padding: 0 5px;
  }
`;

export const LoginContainer = styled.div`
  box-shadow: 0 0 24px 5px #d1d1d1;
`;

export const SignInBox = styled.div`
  background: #fff;
  margin: 0 auto;
  width: auto;
  border-radius: 5px;
  padding: 30px;
  .loader {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: -15px;
  }
  @media (max-width: 500px) {
    width: 350px;
  }
  @media (max-width: 400px) {
    width: 310px;
    padding: 40px 10px;
  }
  @media (max-width: 330px) {
    width: 290px;
  }
`;

export const Logo = styled.p`
  margin: 10px auto;
  text-align: center;
  width: 380px;
  @media (max-width: 425px) {
    width: 290px;
  }
  @media (max-width: 375px) {
    width: 250px;
  }
  @media (max-width: 375px) {
    width: 230px;
  }
`;

export const Heading = styled.h4`
  font-size: ${(props) => props.theme.default?.h4};
  ${(props) => h4Styles(props.theme.resolutions)};
  font-weight: bolder;
  color: black;
  text-align: center;
  margin: 0 3rem;
  @media (max-width: 425px) {
    padding-bottom: 5px;
  }
  @media (max-width: 500px) {
    margin: 0 2rem;
  }
  @media (max-width: 330px) {
    margin: 0 1rem;
  }
`;

export const LinkSC = styled.div`
  pointer-event: ${(props) => (props.loading ? 'none' : 'auto')};
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  font-size: ${(props) => props.theme.default?.p};
  margin-right: ${(props) => (props.margin ? props.margin : '0px')};
  ${(props) => pStyles(props.theme.resolutions)};
  text-align: center;
  text-decoration: underline;
  color: #ff7300;
  cursor: pointer;
  a {
    color: #ff7300;
  }
`;

export const PinSC = styled.div`
  text-align: center;
  padding: 0.5em 0;
  input {
    width: 40px;
    height: 50px;
    border: 2px solid black;
    border-bottom: 2.9px solid orange;
    outline: none;
    line-height: 50px;
    text-align: center;
    font-size: 30px;
    border-radius: 5px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .splitter {
    padding: 0 5px;
    color: white;
    font-size: ${(props) => props.theme.default?.h4};
    ${(props) => h4Styles(props.theme.resolutions)};
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(-80%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-80%);
  }
`;

export const VerificationFom = styled.div`
  display: ${(props) => (props.display == 1 ? 'flex' : 'none')};
  justify-content: flex-end;
  align-items: center;
  transform: translateY(0);
  z-index: 10 !important;
  animation: ${(props) => (props.visible == 1 ? slideIn : slideOut)} 1s forwards;
  i {
    color: green !important;
  }
`;