import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const BM_APPS = async () => {
  const res = await axios.get(BASE_URL + `/bm/apps`);
  return res.data;
};

export const BM_APPS_PRICING = async () => {
  const res = await axios.get(BASE_URL + `/prices/bm/apps`);
  return res.data;
};
