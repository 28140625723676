import styled from 'styled-components';
import { deviceBreakpoints } from 'variables';
import { dynamicStyles } from 'components/reusable-css';

export const HeaderSC = styled.div`
  display: flex;
  background: ${(props) => props.theme.gray.shadeF};
  height: ${(props) => props.theme.default?.top_bar_height};
  ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_height', 'height')};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.dividerColor};

  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    box-shadow: ${(props) => props.theme.boxShadow1};
    border-bottom: 1px solid ${(props) => props.theme.gray.shade0};
    z-index: 999;
    right: 0;
    left: 0;
    position: fixed;
  }
`;

export const AppDrawerToggleButton = styled.div`
  display: none;
  border-right: 1px solid ${(props) => props.theme.dividerColor};
  font-size: 24px;
  line-height: 24px;
  height: 100%;
  padding: 0 4px;
  color: ${(props) => props.theme.gray.shade0};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    margin-right: 0;
  }
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    display: none;
  }
`;

export const RightHeader = styled.span`
  display: flex;
  justify-content: flex-end;
  width: ${(props) => (props.header ? 'auto' : '100%')};
  height: ${(props) => props.theme.default?.top_bar_height};
  ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_height', 'height')};
`;

export const LanguageSwitcher = styled.div`
  display: flex;
  margin: 0 1em;
  align-items: center;
  .cart-icon {
    margin-left: 1.5em;
    margin-top: -8px;
    font-size: 20px;
    cursor: pointer;
  }
  .noOfvs {
    background-color: red;
    color: white;
    font-size: 14px;
    border-radius: 50px;
    padding: 1px 6px;
    margin-top: -20px;
    margin-left: -10px;
    font-weight: bold;
    z-index: 1000;
    cursor: pointer;
  }
`;

export const SocialMediaLink = styled.a`
  display: grid;
  justify-items: center;
  align-content: center;
  cursor: pointer;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  width: 6.5em;
  &:hover {
    color: ${(props) => props.theme.gray.shade0};
  }
  div {
    font-size: ${(props) => props.theme.default?.top_bar_right_icon_font};
    ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_right_icon_font', 'font-size')};
    color: ${(props) => props.theme.gray.shade10};
    font-weight: bold;
  }
  i {
    font-size: ${(props) => props.theme.default?.top_bar_right_icon_width};
    width: ${(props) => props.theme.default?.top_bar_right_icon_width};
    height: ${(props) => props.theme.default?.top_bar_right_icon_height};
    ${(props) => dynamicStyles(props.theme.resolutions, 'top_bar_right_icon_width', 'font-size')};
    margin-bottom: 3px;
    color: ${(props) => props.theme.gray.shade10};
  }
  .signout-icon {
    margin-left: 2px;
  }
`;
