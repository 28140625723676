/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet } from 'react-helmet';

// Currently, there is a warning for findDomNode because of sementic lib. only in strict mode. But it will be fixed in new updates
// More info: https://github.com/Semantic-Org/Semantic-UI-React/issues/2732#issuecomment-479928712
import 'semantic-ui-css/semantic.min.css';
import 'animate.css/animate.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-sliding-side-panel/lib/index.css';
import './index.css';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  gtmId: 'G-6CXPBRVBZ6',
};

let isProduction = process.env.NODE_ENV == 'production';
let isPortal = window.location.hostname == 'portal.serverpoint.com';

if (isProduction && isPortal) {
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <script type="text/javascript">{`
      !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
      },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
      a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
      twq('config','oevhh');
    `}</script>
    </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
