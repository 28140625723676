/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NewVMStep7SC, StepDescription, ServerSizeGrid } from './NewVMStep7.style';
import FundCard from 'components/vm-deployment/fund-card/FundCard';
import VmPaymentForm from './vm-payment-form';
import { totalPrice } from 'components/vm-deployment/util';
import { cardError } from 'components/vm-deployment/constants';
import _get from 'lodash/get';
import { Highlight, StepLabel, StepLabelMobile } from 'components/common';
import Heading from 'components/Heading/Heading';

const NewVMStep7 = ({ fundsData, selectedServerSize, onServerSizeSelection, formikProp }) => {
  const accountApproved = useSelector((state) => state.user.account.account_approved);
  const { t } = useTranslation();
  const amount = useSelector((state) => state.vmDeployment);

  //FADE OUT WHEN NO PAYMENT NEEDED
  const val = _get(fundsData, 'prices_vs_prepaid_options[0]');
  return (
    <NewVMStep7SC id="ST7">
      {/* <StepLabel stepCount={t('vs/instance/create.step7_string1')}>
        <Trans
          i18nKey={
            accountApproved == 0
              ? 'vs/instance/create.step7_string2'
              : accountApproved == 1
              ? 'vs/instance/create.step7_string3'
              : null
          }
          components={{ highlight: <Highlight /> }}
        />
      </StepLabel>
      <StepLabelMobile stepCount={t('vs/instance/create.step7_string1')}>
        <Trans
          i18nKey={
            accountApproved == 0
              ? 'vs/instance/create.step7_string2'
              : accountApproved == 1
              ? 'vs/instance/create.step7_string3'
              : null
          }
          components={{ highlight: <Highlight /> }}
        />
      </StepLabelMobile> */}
      <Heading
        name={
          accountApproved == 0
            ? 'vs/instance/create.step7_string2'
            : accountApproved == 1
            ? 'vs/instance/create.step7_string3'
            : null
        }
      >
        <StepDescription>
          <Trans i18nKey="vs/instance/create.step7_string4" components={{ highlight: <Highlight /> }} />
        </StepDescription>
        <ServerSizeGrid>
          {fundsData?.prices_vs_prepaid_options?.map((fundData) => (
            <FundCard
              key={fundData.id}
              data={fundData}
              selected={fundData.id === selectedServerSize.id}
              onClick={onServerSizeSelection}
              fundsData={fundsData?.prices_vs_prepaid_options || []}
            />
          ))}
        </ServerSizeGrid>
        <StepDescription>{t('vs/instance/create.step7_string6')}</StepDescription>

        {/* FADE OUT WHEN NO PAYMENT NEEDED */}
        <div
          style={
            val?.id === selectedServerSize?.id && val?.minimum_payment == 0 && val?.free_month == 0
              ? { opacity: '0.3', pointerEvents: 'none' }
              : {}
          }
        >
          <VmPaymentForm
            cardError={cardError}
            amount={Number(totalPrice(amount.pricingDetails)) * Number(amount.noOfVM) || 0}
            formikProp={formikProp}
          />
        </div>
      </Heading>
    </NewVMStep7SC>
  );
};
export default NewVMStep7;
