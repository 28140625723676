import { VersionCard, Detail } from 'components/changelog';
import React from 'react';
import * as Styles from './Changelog.style';
import { Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { changelogs_en, changelogs_es } from 'constants/index';

const Changelog = ({ style }) => {
  const translations = {
    en: changelogs_en,
    es: changelogs_es,
  };
  const { i18n } = useTranslation();
  return (
    <Styles.ChangelogSC style={style}>
      {translations[i18n.language].map((changelog) => (
        <>
          <Hidden only={['xs', 'sm']}>
            <Styles.Changelog>
              <Styles.Div1>
                <VersionCard versions={changelog.versions} />
              </Styles.Div1>
              <Styles.Line>
                <i className="fa fa-circle line-background" aria-hidden="true" />
              </Styles.Line>
              <Styles.Div3>
                <Detail details={changelog.details} />
              </Styles.Div3>
            </Styles.Changelog>
          </Hidden>

          {/* Mobile */}
          <Hidden only={['md', 'lg', 'xl']}>
            <Styles.ChangelogMobile>
              <Styles.Line>
                <i className="fa fa-circle line-background" aria-hidden="true" />
              </Styles.Line>
              <Styles.MobileChangelog>
                <Styles.Div1>
                  <VersionCard versions={changelog.versions} />
                </Styles.Div1>
                <Styles.Div3>
                  <Detail details={changelog.details} />
                </Styles.Div3>
              </Styles.MobileChangelog>
            </Styles.ChangelogMobile>
          </Hidden>
        </>
      ))}
    </Styles.ChangelogSC>
  );
};

export default Changelog;
