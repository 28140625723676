import { h6Styles, pStyles } from 'components/reusable-css';
import styled from 'styled-components';

export const Heading = styled.div`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  color: #000;
  font-weight: bolder;
  padding-left: 12px;
  text-transform: capitalize;
`;
export const NetworkHeading = styled.div`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  color: #000;
  font-weight: 700;
  padding-left: 12px;
`;

export const SubHeading = styled.p`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  font-weight: 400 !important;
`;

export const Card = styled.div`
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  margin-right: 20px;
  cursor: pointer !important;
  border-bottom: 2px solid transparent;
  padding-top: 3px;
  padding-left: 4px;
  padding-bottom: 4px;

  &:hover {
    background-color: #cfcfcf;
    transition: background-color 0.3s ease;
    border-radius: 15px;
    border-bottom: 2px solid orange;
  }
`;
export const Card0 = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  margin-right: 20px;
  cursor: pointer !important;
  border-bottom: 2px solid transparent;
  padding-top: 3px;
  padding-left: 4px;
  padding-bottom: 4px;
  /* background-color: #cfcfcf; */
  transition: background-color 0.3s ease;
  border-radius: 15px;
  opacity: 0.2;
`;
