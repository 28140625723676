/* eslint-disable no-unused-vars */
import React from 'react';
import * as Styles from './NewVMStep1.style';
import { Icon } from 'semantic-ui-react';
import { useTranslation, Trans } from 'react-i18next';
import ServerSizeCard from 'components/vm-deployment/server-size-card';
import ServerSizeSlider from 'components/vm-deployment/server-size-slider/ServerSizeSlider';
import { navLinks } from 'utils/nav-links-util';
import { useSelector } from 'react-redux';
import { totalPrice } from 'components/vm-deployment/util';
import ErrorMessage from 'components/error-message';
import classes from 'assets/css/fading-animation.module.css';
import VmUtils from '../util';
import Checkbox from 'components/common/radio-button/RadioButton';
import { vsStep1Category } from 'components/vm-deployment/constants';
import { H5 } from 'components/reusable-css';
import { Highlight, Notification, StepLabel, StepLabelMobile } from 'components/common';
import { StepDescription } from 'assets/css/common-styles';
import Heading from 'components/Heading/Heading';

const NewVMStep1 = (props) => {
  const {
    serverSizes,
    selectedServerSize,
    onServerSizeSelection,
    sliderData,
    sliderSuccess,
    sliderSizeData,
    formikProp,
    isShowPriceButton,
    vsTemplate,
    OsData,
    categoryID,
    setCategoryID,
  } = props;
  const { t } = useTranslation();
  const price = useSelector((state) => state.vmDeployment.pricingDetails);
  const userAccount = useSelector((state) => state.user.account);

  const { isSliderInterface } = VmUtils();
  const isSaleAvailable = serverSizes.some(
    (data) => Number(data.price_monthly_usd) - Number(data.nonsale_price_monthly_usd),
  );

  const handleClick = (val) => setCategoryID(val.id);

  return (
    <Styles.NewVMStep1SC id="ST1">
      {/* <StepLabel stepCount={t('vs/instance/create.step1_string1')}>
        <Trans
          i18nKey={isSliderInterface ? 'vs/instance/custom_create.step1_string1' : 'vs/instance/create.step1_string2'}
          components={{ highlight: <Highlight /> }}
        />
      </StepLabel>
      <StepLabelMobile stepCount={t('vs/instance/create.step1_string1')}>
        <Trans
          i18nKey={isSliderInterface ? 'vs/instance/custom_create.step1_string1' : 'vs/instance/create.step1_string2'}
          components={{ highlight: <Highlight /> }}
        />
      </StepLabelMobile>

      <StepDescription /> */}
      <Heading
        name={isSliderInterface ? 'vs/instance/custom_create.step1_string1' : 'vs/instance/create.step1_string2'}
      >
        <StepDescription />
        {isSliderInterface ? (
          <ServerSizeSlider
            formikProp={formikProp}
            sliderData={sliderData}
            sliderSuccess={sliderSuccess}
            sliderSizeData={sliderSizeData}
            OsData={OsData}
            vsTemplate={vsTemplate}
          />
        ) : (
          <>
            <Styles.ServerOptions>
              <Styles.CardContainer>
                {vsStep1Category.map((category, i) => (
                  <Styles.CardContainers key={i}>
                    <Styles.Card selected={category.id == categoryID} onClick={() => handleClick(category)}>
                      <Checkbox
                        checkbox={categoryID}
                        setCheckedValue={(value) => setCategoryID(value)}
                        data={category}
                        onClick={() => handleClick(category)}
                      />
                      <H5>{t(`vs/instance/create.${category.lang}`)}</H5>
                    </Styles.Card>
                    <Styles.TriangleLine selected={category.id == categoryID}>
                      <div className="line" />
                      <div className="doubleline" />
                    </Styles.TriangleLine>
                  </Styles.CardContainers>
                ))}
              </Styles.CardContainer>
              <Styles.Line categoryID={categoryID}>
                <div className="line" />
                <div className="doubleline" />
              </Styles.Line>
            </Styles.ServerOptions>

            <Styles.ServerOptionsMobile>
              <Styles.CardContainerMobile>
                {vsStep1Category.map((category, i) => (
                  <>
                    <Styles.CardMobile
                      selected={category.id == categoryID}
                      key={i}
                      onClick={() => handleClick(category)}
                    >
                      <H5>{t(`vs/instance/create.${category.lang}`)}</H5>
                      <Styles.LineMobile selected={category.id == categoryID} />
                    </Styles.CardMobile>
                  </>
                ))}
              </Styles.CardContainerMobile>
            </Styles.ServerOptionsMobile>

            <Styles.ServerSizeGrid>
              {serverSizes.map((serverSize) => (
                <>
                  {serverSize.active && serverSize.category == categoryID && (
                    <ServerSizeCard
                      key={serverSize.id}
                      data={serverSize}
                      selected={serverSize?.id === formikProp.values.size}
                      onClick={onServerSizeSelection}
                      formikProp={formikProp}
                      vsTemplate={vsTemplate}
                      userAccount={userAccount}
                    />
                  )}
                </>
              ))}
            </Styles.ServerSizeGrid>
            {isSaleAvailable && (
              <Styles.NotificationContainer>
                <Notification>
                  <Icon name="info circle" />
                  {t('vs/instance/create.step1_string5')}
                </Notification>
              </Styles.NotificationContainer>
            )}
          </>
        )}
        {formikProp.errors.size && formikProp.touched.size && <ErrorMessage />}
        <div className={isShowPriceButton ? classes.price_box_show : classes.price_box_hide}>
          <Styles.Footer>
            <Styles.FooterText>
              <Trans
                i18nKey={'string1'}
                values={{
                  currency_symbol: userAccount.account_currency_symbol,
                  price: totalPrice(price),
                }}
              />
            </Styles.FooterText>
            <Styles.ButtonSC color="green" size="mini" href={navLinks.billing.link}>
              <Icon name="credit card" />
              {t('string2')}
            </Styles.ButtonSC>
          </Styles.Footer>
        </div>
      </Heading>
    </Styles.NewVMStep1SC>
  );
};
export default NewVMStep1;
