import React from 'react';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import Spinner from 'components/spinner';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { P } from 'components/reusable-css';

const CustomTags = ({ data, onClick, className, iconName, tag, loading, disable }) => {
  return (
    <TransitionGroup>
      <Styles.CurrentTagContainer>
        {data.map((item) => (
          <CSSTransition in key={item.id} timeout={500} classNames="fade">
            <Styles.CurrentTag isHover loading={loading && loading(item)} className={disable && 'disable'}>
              <div
                style={{ position: 'relative' }}
                className={`${className(item)} tag`}
                onClick={(e) => onClick(e, item)}
              >
                <P>{tag ? item[tag] : item}</P>
                <Icon name={iconName || 'add'} />
                {loading && loading(item) && (
                  <Styles.CustomTagStyles>
                    <Spinner />
                  </Styles.CustomTagStyles>
                )}
              </div>
            </Styles.CurrentTag>
          </CSSTransition>
        ))}
      </Styles.CurrentTagContainer>
    </TransitionGroup>
  );
};

export { CustomTags };

CustomTags.defaultProps = {
  loading: false,
  className: () => {},
};

CustomTags.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func,
  className: PropTypes.func,
  iconName: PropTypes.string,
  tag: PropTypes.string,
  loading: PropTypes.func,
};
