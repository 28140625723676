import React from 'react';
// import { ToastContainer } from 'react-toastify';
import './styles.css';
import PropTypes from 'prop-types';
const AlertBox = ({ message, heading }) => {
  return (
    <React.Fragment>
      <div style={{ fontSize: '14px' }}>
        <h4>{heading}</h4>
        <p>{message}</p>
      </div>
    </React.Fragment>
  );
};

AlertBox.propTypes = {
  message: PropTypes.string,
  heading: PropTypes.string,
};

export { AlertBox };
