import React from 'react';
import { NewVMStep5SC, Padding } from './NewVSStep5.style';
import ErrorMessage from 'components/error-message/ErrorMessage';
import { StepDescription } from 'assets/css/common-styles';
import OperatingSystemCard from '../component/operating-system-card/OperatingSystemCard';
import Heading from 'components/Heading/Heading';

const NewVSStep5 = ({ osData, formikProp }) => {
  const { touched, values } = formikProp;

  return (
    <NewVMStep5SC id="ST4">
      <Heading name="public/checkout/vs.step5_string1">
        <StepDescription />
        <Padding>
          <OperatingSystemCard osData={osData} formikProp={formikProp} />
        </Padding>
        {values.app == '' && values.image == '' && touched.image && <ErrorMessage />}
      </Heading>
    </NewVMStep5SC>
  );
};
export default NewVSStep5;
