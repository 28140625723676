import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as Styles from './MemberNavigator.style';
import NotFound from 'layout/not-found/NotFound';
import AppDrawerContainer from 'layout/app-drawer/AppDrawer.container';
import HeaderContainer from 'layout/header/Header.container';
import { getLocalStorageValues } from 'constants/local-storage';
import { navLinks } from 'utils/nav-links-util';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import ErrorMessage from 'components/common/red-notification';
// HOOKS
import VmUtils from './new-vm/util';
import { userRights } from 'store/selectors/user-details';
import { useSelector, useDispatch } from 'react-redux';
import { toggleAppDrawer } from 'store/actions/common-actions';
// Pages
import NewVMContainer from 'pages/member/new-vm/NewVM.container';
import WelcomePage from './welcome-page';
import NewBMContainer from './new-bm/NewBMServer.container';
import AddFundContainer from './add-fund/AddFund.container';
import ProfileContainer from './profile/Profile.container';
import ManageVMContainer from './manage-vm/ManageVM.container';
import UserManagementContainer from './user-management/UserManagement.container';
import ManageBmContainer from './manage-bm';
import ChangelogContainer from './changelog';
import RecoverableVSContainer from './recoverable-vs/RecoverableVS.container';
import IssuesContainer from './issues/create';
import AffiliateContainer from './affiliate';
import WHContainer from './wh/WH.conatiner';
import AllIssueContainer from './issues/issues-list/IssuesListContainer';
import SingleIssueContainer from './issues/Issue-detail/IssueDetailContainer';
import DNSContainer from './dns/DNS.container';
import SingleDNSContainer from './single-dns/SingleDNS.Container';
import NewDomainContainer from './new-domain/NewDomain.container';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import LoadingImage from 'assets/images/loading.svg';
import Loading from 'components/common/loading/Loading';

export default function MemberNavigator({ isAppDrawerOpen, queryClient }) {
  const { t } = useTranslation();
  const { newVmPath } = VmUtils();
  const { data: menuData, refetch: refetchMenu } = useQuery('/uimenu');
  const ui = useSelector((state) => state.user.ui);
  const isUserLogout = useSelector((state) => state.user.isUserLogout);
  const dispatch = useDispatch();
  const rights = useSelector(userRights);
  let history = useHistory();
  let params = queryString.parse(history?.location?.search);
  let { userName } = getLocalStorageValues();
  if (params.bearer) {
    localStorage.setItem(
      'userName',
      JSON.stringify({
        userName: '',
        email: '',
        token: params.bearer,
      }),
    );
  }

  //THIS IS FOR HIDING THE PARENT CONTAINER OVERFLOW.
  let value =
    history.location.pathname.split('/')[1] === 'affiliate' || history.location.pathname === '/vs/instance/create';
  const overflowHiddenStyle = value && 'hidden';

  if (!userName?.userName && !params.bearer) {
    window.href = window.origin;
    return <Redirect from="*" to="/" />;
  }

  let isWelcomePage = history.location.pathname != '/welcome';

  return (
    <>
      <Styles.MemberNavigatorSC data-testid="member-navigator">
        <AppDrawerContainer ui={ui} menuData={menuData} />
        <Styles.PageHeaderBodyContainer isUserLogout={isUserLogout}>
          <HeaderContainer queryClient={queryClient} ui={ui} />
          <Styles.PageContainer
            data-testid="page-container"
            isAppDrawerOpen={isAppDrawerOpen}
            onClick={() => (isAppDrawerOpen ? dispatch(toggleAppDrawer()) : {})}
          >
            <Styles.PageContent
              style={{ overflowX: overflowHiddenStyle }}
              data-testid="page-content"
              isAppDrawerOpen={isAppDrawerOpen}
            >
              {/* WELCOME PAGE HAS ITS OWN ERROR MESSAGE IMPLEMENTATION BECAUSE OF ITS BACKGROUND IMAGE */}
              {isWelcomePage && (
                <Styles.Error>
                  {ui?.header_messages &&
                    ui?.header_messages?.map((data) => {
                      return (
                        data?.id && (
                          <Styles.Warning key={data.id}>
                            <ErrorMessage bgColor={`#${data.color}`} color={`#${data.font_color}`} width="100%">
                              <p>{t(data.api_language_string)} </p>
                            </ErrorMessage>
                          </Styles.Warning>
                        )
                      );
                    })}
                </Styles.Error>
              )}
              <Switch>
                <Route path={navLinks.virtual_machines_home.link}>
                  <WelcomePage ui={ui} />
                </Route>
                {rights.billing && (
                  <Route path={navLinks.billing.link}>
                    <AddFundContainer />
                  </Route>
                )}
                {rights.profile && (
                  <Route path={navLinks.edit_profile.link}>
                    <ProfileContainer />
                  </Route>
                )}
                {rights.vsprovision && (
                  <Route exact path={newVmPath}>
                    <NewVMContainer />
                  </Route>
                )}
                {rights.bmprovision && (
                  <Route exact path={navLinks.new_server.link}>
                    <NewBMContainer />
                  </Route>
                )}
                {rights.vs && (
                  <Route path={navLinks.manage_your_vms.link}>
                    <ManageVMContainer refetchMenu={refetchMenu} />
                  </Route>
                )}
                {rights.bm && (
                  <Route path={navLinks.manage_your_servers.link}>
                    <ManageBmContainer />
                  </Route>
                )}
                <Route path={navLinks.user_management.link}>
                  <UserManagementContainer />
                </Route>
                <Route exact path={navLinks.changelog.link}>
                  <ChangelogContainer />
                </Route>
                {rights.affiliate && (
                  <Route path={navLinks.make_money_with_serverpoints_affiliate_program.link}>
                    <AffiliateContainer />
                  </Route>
                )}
                <Route path={navLinks.vms_pending_to_destory.link}>
                  <RecoverableVSContainer refetchMenu={refetchMenu} />
                </Route>
                <Route path={navLinks.deploy_a_web_hosting_container.link}>
                  <WHContainer />
                </Route>
                <Route path={navLinks.contact_our_staff.link}>
                  <IssuesContainer />
                </Route>
                <Route exact path={navLinks.ticket_detail.link}>
                  <SingleIssueContainer />
                </Route>
                <Route path={navLinks.see_current_tickets.link}>
                  <AllIssueContainer />
                </Route>
                <Route path={navLinks.dns_instance_id.link}>
                  <SingleDNSContainer />
                </Route>
                <Route path={navLinks.dns_instance.link}>
                  <DNSContainer />
                </Route>
                <Route path={navLinks.domain_instance.link}>
                  <NewDomainContainer />
                </Route>
                <Route path={navLinks.logout.link}>
                  <div>
                    <Loading gap={50} />
                  </div>
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Styles.PageContent>
          </Styles.PageContainer>
        </Styles.PageHeaderBodyContainer>
      </Styles.MemberNavigatorSC>
      {isUserLogout && (
        <Styles.LogOutUserSC>
          <img src={LoadingImage} width={150} style={{ opacity: 1 }} />
        </Styles.LogOutUserSC>
      )}
    </>
  );
}
