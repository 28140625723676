import React from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import Input from 'components/input';
import CustomButton from 'components/common/custom-button';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { ActionInitialValues } from 'components/manage-vm/utils';

const Backup = () => {
  return (
    <Styles.ActionDiv>
      <Styles.OptionTitle>Give it a name</Styles.OptionTitle>
      <Styles.InputName>
        <Grid container spacing={0}>
          <Grid xs={6} sm={6} md={8} lg={5} xl={2}>
            <Formik initialValues={ActionInitialValues}>
              {({ values, handleChange, handleBlur, errors, touched }) => (
                <Input
                  styling={{ marginTop: '-15px' }}
                  type={'text'}
                  name={'name'}
                  value={values['name']}
                  placeholder={'Template name'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors['name']}
                  touched={touched['name']}
                />
              )}
            </Formik>
          </Grid>
          <Grid>
            <CustomButton buttonStyles={{ marginTop: '13px', marginLeft: '10px', padding: '12px 15px' }} />
          </Grid>
        </Grid>
      </Styles.InputName>
      <Styles.OptionText>This feature requires that this VM be signed up to a backup plan.</Styles.OptionText>
      <Styles.OptionText>
        This action will create a backup of your VM now, which does not get rotated and deleted like regular daily
        backups. You can use this saved backup, also known as a custom template, to deploy new VMs from it. When you
        visit the VM deployment page build a new VM, you will see this custom template listed as one of your available
        operating systems. Note: if you intend to use this custom template to create new VMs, we strongly recommend you
        create this template when this VM is in an OFF state, not while it is running.
      </Styles.OptionText>
    </Styles.ActionDiv>
  );
};

export { Backup };
