import React, { useState } from 'react';
import * as Styles from './CCloudSignp.style';
import LogoImage from 'assets/images/Logo_ColossusCloud_by-Serverpoints.svg';
import { Icon } from 'semantic-ui-react';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { useFeatures, doFeatures } from 'constants/signUp';
import Input from 'components/input';
import Button from 'components/common/custom-button';
import { useMutation, useQuery } from 'react-query';
import { SIGN_UP } from 'components/sign-up/queries';
import { LOG_IN } from 'components/vm-deployment/queries';
import { Formik, Form } from 'formik';
import { signUpInitial, signUpValidationSchema } from 'constants/signUp';
import Recaptcha from 'react-recaptcha';
import { siteKey } from 'constants/env';
import { useHistory, NavLink } from 'react-router-dom';
import axios from 'axios';
import { reactQueryConfig } from 'constants/react-query-config';
import { CustomHook, getCookie } from 'utils/custom-hook';
import { navLinks } from 'utils/nav-links-util';
import Alert from 'components/alert';
import { Link } from 'react-router-dom';
import { H2, P } from 'components/reusable-css';
import LanguageDropdown from 'components/language-dropdown/LanguageDropdown';
import { UPDATE_PREFERENCES } from 'components/manage-vm/queries';

const SignUp = ({ queryClient }) => {
  const { t, i18n } = useTranslation();
  let defaultLang = i18n.language == 'en-US' ? 'en' : i18n.language;
  const [language, setLanguage] = useState(defaultLang);
  const { mutate: updatePreferences, isLoading: updatePreferencesLoading } = useMutation(UPDATE_PREFERENCES);
  const { mutate: signUp, isLoading: signUpLoading } = useMutation(SIGN_UP);
  const [recaptchaToken, setRecapchaToken] = useState();
  const history = useHistory();
  const { logout } = CustomHook();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [loginValidate, setloginValidate] = useState({
    open: false,
    loginResult: 'This email already exists.',
    link: 'Forgot password?',
    severity: 'error',
    trigger: false,
  });

  const { isLoading: loginLoading } = useQuery(['LOG_IN', { userName, password: btoa(password) }], LOG_IN, {
    enabled: loginValidate.trigger,
    ...reactQueryConfig,
    onSuccess: async (res) => {
      await updatePreferences({ language });
      i18n.changeLanguage(language);
      localStorage.setItem('language', language);
      const query = `?sc_username=${res.sc_username}&sc_password=${res.sc_password}`;
      if (res.redirect_type === 'tab') {
        return window.open(`${res.redirect_url}${query}`);
      }
      if (res.redirect_type === 'redirect') {
        await logout('', false);
        return location.replace(`${res.redirect_url}${query}`);
      }
      if (queryClient) queryClient.clear();
      axios.defaults.headers.common.Authorization = `Bearer ${res.token}`;
      localStorage.setItem(
        'userName',
        JSON.stringify({
          userName: res.username,
          email: userName,
          token: res.token,
        }),
      );
      setloginValidate({
        ...loginValidate,
        trigger: false,
      });
      history.replace(navLinks.virtual_machines_home.link);
    },
  });

  const handleSignup = async (values) => {
    await signUp(
      {
        email: values.email,
        password: btoa(values.password),
        'g-recaptcha-response': recaptchaToken || '',
        affiliate_id: getCookie('auser'),
        refid: getCookie('refid'),
        //cookies
        utm_campaign: getCookie('utm_campaign'),
        utm_medium: getCookie('utm_medium'),
        utm_source: getCookie('utm_source'),
        utm_term: getCookie('utm_term'),
        utm_content: getCookie('utm_content'),
        utm_landing: getCookie('utm_landing'),
        landing_url: getCookie('landing_url'),
        firstdate: getCookie('firstdate'),
        lastdate: getCookie('lastdate'),
        referrer: getCookie('referrer'),
      },
      {
        onSuccess: async () => {
          setloginValidate({ ...loginValidate, trigger: true });
        },
        onError: () => {
          setloginValidate({
            ...loginValidate,
            open: true,
            trigger: false,
          });
        },
      },
    );
  };
  let verifyCallback = (response) => {
    setRecapchaToken(response);
  };

  let validationsTooltip = (value) => {
    return `
        <div class="validationsContainer">
          <div class="signupValidations"> ${value.length > 7 ? '&#10004;' : '&#10060;'} </div>
          <div>at least 8 characters</div>
        </div>
        <div class="validationsContainer">
          <div class="signupValidations"> ${/\p{Lu}/u.test(value) ? '&#10004;' : '&#10060;'} </div>
          <div>at least one uppercase letter</div>
        </div>
        <div class="validationsContainer">
          <div class="signupValidations"> ${/\d+/g.test(value) ? '&#10004;' : '&#10060;'} </div>
          <div>at least one number</div>
        </div>
        <div class="validationsContainer">
          <div class="signupValidations"> ${/\W/g.test(value) ? '&#10004;' : '&#10060;'} </div>
          <div>at least 1 symbol (non character and non numerical)</div>
        </div>
      `;
  };

  const handleLanguage = (value) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  let validation = (value) => value.length > 7 && /\p{Lu}/u.test(value) && /\d+/g.test(value) && /\W/g.test(value);

  return (
    <Styles.Background>
      <Styles.SignUpContainer>
        <div className="text-center pb-3">
          <img src={LogoImage} className="px-3 pt-4 mb-3" alt="serverpoint" width="350px" />
        </div>

        <Styles.SignupContainer>
          <Styles.Form>
            <Styles.SignupBox>
              {loginValidate.open && (
                <Alert
                  severity={loginValidate.severity}
                  text={loginValidate.loginResult}
                  open={loginValidate.open}
                  link={
                    <NavLink style={{ color: '#ff7300', textDecoration: 'underline' }} to={'/forgot-password'}>
                      {loginValidate.link}
                    </NavLink>
                  }
                  handleClose={() =>
                    setloginValidate({
                      ...loginValidate,
                      open: false,
                    })
                  }
                />
              )}
              <Styles.SignupForm>
                <H2>{t('signup.string2')}</H2>
                <P>
                  <Trans i18nKey={'signup.string3'}>
                    <Link to="/ccloud/login" />
                  </Trans>
                </P>
                <Formik
                  initialValues={signUpInitial}
                  validationSchema={signUpValidationSchema}
                  onSubmit={(values) => handleSignup(values)}
                >
                  {({ values, handleChange, handleBlur, errors, touched, handleSubmit }) => (
                    //   {({ values, handleChange, handleBlur, errors, touched, handleSubmit }) => (
                    <Form>
                      <Input
                        name={'email'}
                        value={values['email']}
                        placeholder={t('signup.string4')}
                        onChange={(e) => {
                          handleChange(e);
                          setUserName(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors['email']}
                        touched={touched['email']}
                      />
                      <Input
                        name={'password'}
                        value={values['password']}
                        placeholder={t('signup.string5')}
                        dataTooltip={validationsTooltip(values.password)}
                        onChange={(e) => {
                          handleChange(e);
                          setPassword(e.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors['password']}
                        touched={touched['password']}
                        type="password"
                      />
                      <Input
                        name={'confirm_password'}
                        value={values['confirm_password']}
                        placeholder={t('signup.string6')}
                        dataTooltip={validationsTooltip(values.confirm_password)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors['confirm_password']}
                        touched={touched['confirm_password']}
                        type="password"
                      />
                      <div>
                        <Styles.Condition>
                          <P>
                            <Trans i18nKey={'signup.string7'}>
                              <a href="https://serverpoint.com/privacy.pdf" target="_blank" rel="noreferrer" />
                              <a href="https://serverpoint.com/tos.pdf" target="_blank" rel="noreferrer" />
                            </Trans>
                          </P>
                        </Styles.Condition>
                        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                          <Recaptcha
                            sitekey={siteKey}
                            render="explicit"
                            size="normal"
                            verifyCallback={verifyCallback}
                          />
                        </div>
                        <Button
                          disabled={
                            !recaptchaToken ||
                            !values.email ||
                            !values.password ||
                            !values.confirm_password ||
                            !validation(values.password) ||
                            !validation(values.confirm_password) ||
                            errors.confirm_password ||
                            errors.email
                          }
                          onClick={() => handleSubmit()}
                          isLoading={signUpLoading || loginLoading || updatePreferencesLoading}
                          title={t('signup.string8')}
                          buttonStyles={{
                            width: '100%',
                          }}
                        />
                      </div>
                      <Styles.Bottom>
                        <P>{t('signup.string9')}</P>
                      </Styles.Bottom>
                      <Styles.LanguageContainer>
                        <LanguageDropdown language={language} handleLanguage={(e) => handleLanguage(e)} />
                      </Styles.LanguageContainer>
                    </Form>
                  )}
                </Formik>
              </Styles.SignupForm>
            </Styles.SignupBox>
          </Styles.Form>
          <Styles.Instruction>
            <Styles.Uses>
              <Styles.SubHeading>{t('signup.string10')}</Styles.SubHeading>
              <Styles.Features>
                {useFeatures.map((feature) => (
                  <Styles.Feature key={feature.id}>
                    <Icon name="check" />
                    <Trans i18nKey={'signup.' + feature.lanRef} />
                  </Styles.Feature>
                ))}
              </Styles.Features>
            </Styles.Uses>
            <Styles.Doing>
              <Styles.SubHeading>{t('signup.string14')}</Styles.SubHeading>
              <Styles.Features>
                {doFeatures.map((feature) => (
                  <Styles.Feature key={feature.id}>
                    <Icon name="check" />
                    <Trans i18nKey={'signup.' + feature.lanRef} />
                  </Styles.Feature>
                ))}
              </Styles.Features>
            </Styles.Doing>
          </Styles.Instruction>
        </Styles.SignupContainer>
      </Styles.SignUpContainer>
    </Styles.Background>
  );
};

export default withTranslation('signup')(SignUp);
