export const authenticationbmSteps = [
  {
    localize_label: 'bm/instance/create.step5_string3',
    label: 'Root/Administrator password:',
    type: 'password',
    name: 'root_pass',
    localize_text: 'bm/instance/create.step5_string7',
    text: 'Because different operating systems have different password requirements, this form will only accept letters and numbers. Please use a combination of lower and upper case letters as well as numbers. Once you log in to the server, please change your password to a more complex one. We recommend you use https://passwordsgenerator.net/ to generate a stronger password later on.',
    PasswordInput: true,
  },
  {
    localize_label: 'bm/instance/create.step5_string4',
    label: 'Confirm password:',
    type: 'password',
    name: 'confirm_pass',
    PasswordInput: true,
  },
  {
    localize_label: 'bm/instance/create.step5_string5',
    label: 'Name this bare metal server:',
    type: 'text',
    name: 'virtual_server_name',
    localize_text: 'bm/instance/create.step5_string8',
    text: 'Please use only letters, numbers, spaces, dashes or underscores; up to 32 characters. Under "Bare Metal Servers > Manage your servers" in this portal, you will see all your bare metal servers listed along with this name. This is how you can easily identify these servers. For example, this server could be named "Email Server 3" or "WordPress Server".',
  },
  {
    localize_label: 'bm/instance/create.step5_string6',
    label: 'Any extra IP numbers?',
    type: 'select',
    name: 'ipv4_ips',
    localize_text: 'bm/instance/create.step5_string9',
    options: [
      { value: 1, text: 'bm/instance/create.step5_string10' },
      { value: 2, text: 'bm/instance/create.step5_string11' },
      { value: 3, text: 'bm/instance/create.step5_string12' },
      { value: 4, text: 'bm/instance/create.step5_string13' },
    ],
    text: 'If you need more IPs, please feel free to contact our sales team. With proper IP justification, we may be able to accommodate your needs. Each additional IP number is $3/month.',
  },
];
