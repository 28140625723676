import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const BM_MANAGED = async () => {
  const res = await axios.get(BASE_URL + `/bm/managedlevels`);
  return res.data;
};

export const BM_MANAGED_PRICING = async () => {
  const res = await axios.get(BASE_URL + `/prices/bm/managedlevels`);
  return res.data;
};