/* eslint-disable no-unused-vars */
import React from 'react';
import { FlexRow, NoneTagFlex, OuterTagLength, TagLengthPencil } from 'pages/member/manage-vm/ManageVM.style';
import { Icon } from 'semantic-ui-react';
import get from 'lodash/get';
import { TagModal } from './TagModal';
import ConditionalFragment from 'components/conditional-fragment';
import { P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const TagCard = ({
  handleClose,
  handleOpen,
  openTag,
  data,
  managerVmRefetch,
  allTags,
  managerVmIsFetching,
  isTags,
  type,
  end,
  mobile,
  rights,
  refetchTags,
}) => {
  const { t } = useTranslation();
  const tags = get(data, 'tags', []);
  return (
    <div>
      <FlexRow end={end}>
        {tags.map((item, index) => (
          <OuterTagLength key={index}>
            <P>{item}</P>
          </OuterTagLength>
        ))}
        <ConditionalFragment
          condition={type === 'vs' ? rights?.vsrename || rights?.vsedittags : rights?.bmrename || rights?.bmedittags}
        >
          {tags.length ? (
            <TagLengthPencil>
              <P onClick={handleOpen}>
                <Icon name="pencil" />
              </P>
            </TagLengthPencil>
          ) : null}
          {!tags.length && mobile ? (
            <NoneTagFlex>
              <P>{t('vs/instance.string30')}</P>
              <P onClick={handleOpen}>
                <Icon name="pencil" />
              </P>
            </NoneTagFlex>
          ) : null}
        </ConditionalFragment>
      </FlexRow>
      {openTag && (
        <TagModal
          handleClose={handleClose}
          openTag={openTag}
          allTags={allTags}
          refetchTags={refetchTags}
          tags={tags}
          data={data}
          managerVmRefetch={managerVmRefetch}
          managerVmIsFetching={managerVmIsFetching}
          isTags={isTags}
          type={type}
          rights={rights}
        />
      )}
    </div>
  );
};
export { TagCard };
