import React from 'react';
import * as Styles from './NewBMLastStep.style';
import { Trans, useTranslation } from 'react-i18next';
import { totalPrice, hoursTotalPrice } from 'components/vm-deployment/util';
import { scrollToSection } from 'utils';
import { Message } from 'components/alert/Message';
import { cardError } from 'components/vm-deployment/constants';
import priceCalculator from 'utils/price-calculator';
import CustomButton from 'components/common/custom-button';
import { P } from 'components/reusable-css';
import { useSelector } from 'react-redux';
import { UpperCaseWord } from 'assets/css/common-styles';

const NewBMLastStep = ({ formikProp, loading }) => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  const { validateForm, values, setFieldTouched, handleSubmit, isValid } = formikProp;
  return (
    <Styles.NewBMLastStepSC>
      <Styles.PriceServer>
        <Styles.Content>
          <P className="your_server_price">{t('bm/instance/create.string1')}</P>
          <Styles.Price>
            <Trans
              i18nKey="bm/instance/create.string2"
              values={{
                price_month: priceCalculator(totalPrice(values?.prices), false),
                price_hourly: hoursTotalPrice(values?.prices).toFixed(3),
                currency: userAccount.account_currency,
                currency_symbol: userAccount.account_currency_symbol,
              }}
              components={{
                price_per_month: <Styles.PerMonth />,
                price_month: <Styles.PriceUnit />,
                price_per_hourly: <Styles.PerHour />,
                price_hourly: <span />,
                uppercase: <UpperCaseWord />,
              }}
            />
          </Styles.Price>
        </Styles.Content>
      </Styles.PriceServer>
      <Styles.VirtualServer>
        <Styles.SubmitServer>
          <CustomButton
            title={t('bm/instance/create.string3')}
            type="submit"
            isLoading={loading}
            buttonStyles={{ padding: '0.8rem 1rem', opacity: !isValid && 0.3, backgroundColor: !isValid && 'gray' }}
            onClick={async () => {
              const errors = await validateForm();
              handleSubmit();
              setFieldTouched('image', true);
              setFieldTouched('region', true);
              if (errors.size) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST1');
              }
              if (errors.image) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST2');
              }
              if (errors.managed_plan) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST4');
              }
              if (errors.confirm_pass) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST5');
              }
              if (errors.virtual_server_name) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST5');
              }
              if (errors.options_prepaid) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST6');
              }
              if (errors.payment_method) {
                Message.error(t(errors.payment_method), t('string13'));
                return scrollToSection('PAYMENT-CARD');
              }
              if (cardError(errors, values)) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('PAYMENT-CARD');
              }
            }}
          />
        </Styles.SubmitServer>
      </Styles.VirtualServer>
    </Styles.NewBMLastStepSC>
  );
};

export default NewBMLastStep;
