import { h4Styles, h6Styles, pStyles } from 'components/reusable-css';
import styled, { css } from 'styled-components';

export const Features = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 1em;
`;
export const PlanText = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  opacity: 0.6;
  @media only screen and (max-width: 1500px) {
    font-size: 14px;
  }
`;
export const PlanDescription = styled(PlanText)`
  line-height: 24px;
  margin-top: 10px;
  width: 80%;
  margin: 15px auto 0 auto;
  max-width: 350px;
`;
export const Size = styled.div`
  position: relative;
  border-radius: 10px;
  transform: ${(props) => (props.selected ? 'scale(1.05)' : 'scale(1)')};
  z-index: ${(props) => (props.selected ? '2' : '1')};
  box-shadow: ${(props) => (props.selected ? '0 3px 10px rgb(0 0 0 / 0.2)' : '0')};
  transition: transform 0.5s;
  @media only screen and (max-width: 960px) {
    transform: scale(1);
    margin-top: 40px;
  }
`;
export const Popular = styled.img`
  z-index: 3;
  position: absolute;
  visibility: ${(props) => (props.showTag ? 'visible' : 'hidden')};
  width: 200px;
  height: 60px;
  margin-top: -20px;
  left: 50%;
  transform: translateX(-50%);
`;

export const Separator = styled.div`
  margin: 0 12px;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  height: 40px;
  width: 1px;
  background: #e8e8e8;
`;

export const DollarSign = styled.div`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  margin-top: 8px;
  @media only screen and (max-width: 1650px) {
    margin-top: 4px;
  }
  @media only screen and (max-width: 960px) {
    margin-top: 6px;
  }
`;

export const FeaturesButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 15px;
  padding-bottom: 1.5em;
  @media only screen and (min-width: 2000px) {
    padding: 0 30px;
    padding-bottom: 1.5em;
  }
`;
export const Feature = styled.div`
  display: flex;
  padding: 0 20px;
  line-height: 2em;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  @media only screen and (min-width: 2000px) {
    padding: 0 30px;
  }
  @media only screen and (max-width: 649px) {
    margin-right: 6px;
    margin-bottom: 10px;
    line-height: 1.2em;
  }
  i {
    color: ${(props) => props.theme.primaryColor};
    margin-right: 6px;
  }
`;

export const Name = styled.div`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

export const SizesContainer = styled.div`
  display: grid;
  align-items: space-between;
  cursor: pointer;
  position: relative;
  background: white;
  height: 100%;
  overflow: hidden;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  border-left: ${(props) => (props.index == 0 ? `2px solid ${props.theme.serverSizeCard.borderColor}` : '0px')};

  border-top-left-radius: ${(props) => (props.index == 0 ? '10px' : '0px')};
  border-bottom-left-radius: ${(props) => (props.index == 0 ? '10px' : '0px')};
  border-top-right-radius: ${(props) => (props.index == props.length - 1 ? '10px' : '0px')};
  border-bottom-right-radius: ${(props) => (props.index == props.length - 1 ? '10px' : '0px')};

  transition: all 150ms ease;

  @media only screen and (max-width: 960px) {
    border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
    border-radius: 10px;
  }

  &:hover {
    /* background: ${(props) => props.theme.serverSizeCard.hoverBackgroundColor}; */
    border-color: ${(props) => props.theme.serverSizeCard.hoverBorderColor};
  }

  ${(props) =>
    props.selected &&
    css`
      border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
      border-color: ${(props) => props.theme.primaryColor};
      border-radius: 10px;
    `};
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 0px 15px;
  font-weight: 500;
  margin-top: 50px;
`;
export const Price = styled.div`
  display: flex;
  margin: 10px 0 0 0;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
`;

export const PerMonth = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  font-weight: 900;
  color: #ff7800;
`;

export const PriceUnit = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.default?.h4};
  ${(props) => h4Styles(props.theme.resolutions)};
`;

export const PerHour = styled.div`
  display: flex;
  margin: 0;
  margin-bottom: 6px;
  @media only screen and (max-width: 649px) {
    align-items: flex-end;
    margin-left: 12px;
    margin-bottom: 7px;
  }
`;

export const Tag = styled.div`
  display: flex;
  visibility: ${(props) => (props.showTag ? 'visible' : 'hidden')};
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  background: ${(props) => props.theme.serverSizeCard.tag.backgroundColor};
  color: ${(props) => props.theme.gray.shadeF};
  height: 28px;
  width: 200px;
  border-radius: 0 10px 0 20px;
  position: relative;
  top: -2px;
  right: -2px;
  font-style: italic;
  font-weight: bold;
  font-size: 18px;
`;

export const SelectedTag = styled.div`
  display: flex;
  background: ${(props) => props.theme.serverSizeCard.selectedTag.backgroundColor};
  border-radius: 0;
  border-top-left-radius: 20px;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.gray.shadeF};
  height: 40px;
  width: 220px;
  position: absolute;
  bottom: -2px;
  right: -2px;
  font-size: 18px;

  i {
    margin-right: 6px;
    margin-bottom: 8px;
  }
`;

export const Heading = styled.h6`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
  background: #f5f5f6;
  padding: 8px 20px;
  @media only screen and (min-width: 2000px) {
    padding: 8px 30px;
  }
  @media only screen and (max-width: 960px) {
    margin-bottom: 15px;
  }
`;

export const Conatiner = styled.div`
  margin-top: 1.5em;
  width: 100%;
`;
