import { dnsDomain } from 'constants/regex';
import * as Yup from 'yup';

export const initialValues = (default_ip) => {
  return { domain: '', ip_number: default_ip };
};

export const validationSchema = () =>
  Yup.object({
    domain: Yup.string()
      .required('A domain is required')
      .max(128, 'Maximum length is 128 characters')
      .matches(dnsDomain, 'Invalid format'),
  });

export const dnsListdropdown = (setOpenConfigurationPanel, handleAction) => [
  {
    id: 0,
    lang: 'dns/instance.string19',
    name: 'configure_dns',
    icon: 'fa fa-cog',
    onClick: () => setOpenConfigurationPanel(true),
  },
  {
    id: 1,
    lang: 'dns/instance.string20',
    name: 'enable_dns',
    icon: 'fa fa-play',
    onClick: () => handleAction('enable'),
  },
  {
    id: 2,
    lang: 'dns/instance.string21',
    name: `disable_dns`,
    icon: 'fa fa-stop-circle-o',
    onClick: () => handleAction('disable'),
  },
  {
    id: 3,
    lang: 'dns/instance.string22',
    name: 'delete_dns',
    icon: 'fa fa-trash',
    onClick: () => handleAction('delete'),
  },
];

export const addRecordsValues = (type, default_ip = '') => {
  return { type: type, name: '', value: default_ip, priority: type == 'MX' ? 10 : 0 };
};

const dnsName = /^(?:\*\.)?[\w-]*[\w\d]*(\.[\w-]*[\w\d]*)*$/;
const nsName = /^[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9]+$/;
const targetName = /^[a-zA-Z][a-zA-Z0-9-._]*[a-zA-Z0-9]+$/;
const emailServerHostName = /^[a-zA-Z][a-zA-Z0-9-.]*[a-zA-Z0-9]+$/;

export const addRecordsValidation = (validate) => {
  if (validate == 'a') {
    return Yup.object({
      name: Yup.string().optional().max(63, 'Maximum length is 63 characters').matches(dnsName, 'Invalid format'),
    });
  } else if (validate == 'cname') {
    return Yup.object({
      name: Yup.string()
        .required('Name required')
        .max(63, 'Maximum length is 63 characters')
        .matches(dnsName, 'Invalid format'),
      value: Yup.string()
        .required('Value required')
        .max(63, 'Maximum length is 63 characters')
        .matches(targetName, 'Invalid format'),
    });
  } else if (validate == 'mx') {
    return Yup.object({
      name: Yup.string()
        .required('Name required')
        .max(63, 'Maximum length is 63 characters')
        .matches(emailServerHostName, 'Invalid format'),
      priority: Yup.string().required('Priority required'),
    });
  } else if (validate == 'ns') {
    return Yup.object({
      name: Yup.string()
        .required('Name required')
        .max(63, 'Maximum length is 63 characters')
        .matches(nsName, 'Invalid format'),
    });
  } else if (validate == 'txt') {
    return Yup.object({
      name: Yup.string()
        .required('Name required')
        .max(63, 'Maximum length is 63 characters')
        .matches(dnsName, 'Invalid format'),
      value: Yup.string().required('Value required').max(128, 'Maximum length is 128 characters'),
    });
  }
};

export const recordsMenuOptions = [
  { value: 'all', text: 'All', lang: 'dns/instance.string25' },
  { value: 'a', text: 'A', lang: 'dns/instance.string26' },
  { value: 'cname', text: 'CNAME', lang: 'dns/instance.string27' },
  { value: 'mx', text: 'MX', lang: 'dns/instance.string28' },
  { value: 'ns', text: 'NS', lang: 'dns/instance.string29' },
  { value: 'txt', text: 'TXT', lang: 'dns/instance.string30' },
];

export const recordsListdropdown = (_, handleAction) => [
  {
    id: 1,
    lang: 'dns/instance.string23',
    name: 'delete_records',
    icon: 'fa fa-trash',
    onClick: () => handleAction('delete'),
  },
];
