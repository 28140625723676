/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import SlidingPanels from 'components/common/sliding-panel';
import { Icon } from 'semantic-ui-react';
import { useMediaQuery } from '@material-ui/core';
import { EditUser } from './EditUser';
import { useTranslation } from 'react-i18next';

const UserEditPanel = ({ getRoles, usersRefetch, user, roleRefetch }) => {
  const { t } = useTranslation();
  const [openPanel, setOpenPanel] = useState(false);
  const togglePanel = () => setOpenPanel((prev) => !prev);

  const match1 = useMediaQuery('(max-width:790px)');
  const match2 = useMediaQuery('(max-width:1650px)');
  const match3 = useMediaQuery('(max-width:1230px)');
  let size = match1 ? 95 : match3 ? 70 : match2 ? 50 : 40;

  return (
    <React.Fragment>
      <div onClick={togglePanel}>
        <Icon name="pencil" />
        <span>{t('account/usermgmt.string14')}</span>
      </div>
      <SlidingPanels size={size} isOpen={openPanel} isClose={setOpenPanel}>
        <EditUser
          roleRefetch={roleRefetch}
          togglePanel={togglePanel}
          getRoles={getRoles}
          usersRefetch={usersRefetch}
          user={user}
        />
      </SlidingPanels>
    </React.Fragment>
  );
};

export { UserEditPanel };
