import React from 'react';
import * as Styles from './NewVSStep2.style';
import ErrorMessage from 'components/error-message';
import { StepDescription } from 'assets/css/common-styles';
import ServerSizeCard from '../component/server-size-card/ServerSizeCard';
import Heading from 'components/Heading/Heading';
import { P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const NewVSStep2 = (props) => {
  const { serverSizes, formikProp } = props;
  const { errors, touched, values } = formikProp;
  const { t } = useTranslation();
  return (
    <Styles.NewVMStep2SC id="ST2">
      <Heading name="public/checkout/vs.step2_string2">
        <StepDescription />
        <Styles.ServerSizeGrid>
          {serverSizes?.vs_sizes.length == 0 ? (
            <P>{t('public/checkout/vs.step2_string28')}</P>
          ) : (
            serverSizes?.vs_sizes?.map((serverSize) => (
              <>
                {serverSize.active && (
                  <ServerSizeCard
                    key={serverSize.id}
                    data={serverSize}
                    selected={serverSize?.id == values.size}
                    formikProp={formikProp}
                  />
                )}
              </>
            ))
          )}
        </Styles.ServerSizeGrid>
        {errors.size && touched.size && <ErrorMessage />}
      </Heading>
    </Styles.NewVMStep2SC>
  );
};
export default NewVSStep2;
