import * as Yup from 'yup';

export const initialValues = {
  new_domain: '',
};

export const validationSchema = () =>
  Yup.object({
    new_domain: Yup.string().required('Domain required'),
  });

export const transferInitialValues = {
  transfer_domain: '',
};

export const transferValidationSchema = () =>
  Yup.object({
    transfer_domain: Yup.string().required('Domain required'),
  });
