import { pStyles } from 'components/reusable-css';
import styled, { css } from 'styled-components';
import { variables } from 'variables';

export const SupportedDomain = styled.div`
  margin-bottom: 2em;
  .paragraph {
    margin-bottom: 1em;
    width: 100%;
  }
`;

export const SupportedDomainRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const SupportedDomainContainer = styled.div`
  color: green;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 6px;
  margin-bottom: 6px;
  background: #daf7a6;
  // width: 6.5em;
  text-align: center;
  p {
    margin: 0;
    font-weight: 500;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  padding: 1em;
  padding-right: 2em;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  border-radius: 8px;
  margin-right: 10px;

  @media only screen and (max-width: 580px) {
    width: 100%;
  }
  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.primaryDarkColor};
      border: 2px solid ${(props) => props.theme.primaryDarkColor};
      color: white;
    `};
`;

export const DomainStep1SC = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.pageBackgroundColor};
  padding: ${variables.pageContentPadding}px;
  width: 100%;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  padding-bottom: 4em;
`;

export const StepDescription = styled.div`
  margin-bottom: 0.5em;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;

export const Step = styled.div`
  display: flex;
  margin-top: 50px;
  margin-bottom: 40px;
`;

export const Line = styled.div`
  width: 44em;
  border-top: 2px solid orange;
  margin-right: 18px;
  border-radius: 15px;
  padding-bottom: 2.5em;
  .line {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid orange;
    margin-top: -1.5em;
    margin-left: ${(props) => (props.isNewDomain == 1 ? '8.5em' : '30.5em')};
  }
  .doubleline {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid ${(props) => props.theme.pageBackgroundColor};
    margin-top: -1.1em;
    margin-left: ${(props) => (props.isNewDomain == 1 ? '8.5em' : '30.5em')};
  }
`;

export const DomainContainer = styled.div`
  display: flex;
`;

export const IsDomainAvailable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 10px;
  background: ${(props) => (props.error ? '#bd0505' : '#82bf50')};
  border-radius: 5px;
  width: 42.6em;
  p {
    margin: 0;
    margin-left: 5px;
  }
  i {
    color: white;
    margin-top: -1px;
  }
  h3 {
    color: white;
    margin: 0;
    margin-left: 20px;
  }
`;
