import React from 'react';
import * as Styles from 'pages/member/user-management/UserManagement.style';
import { PanelHeader } from './panelHeader';
import { Form, Formik } from 'formik';
import { editUserInitialValues, editUserValidationSchema } from 'constants/user-management';
import { Grid } from '@material-ui/core';
import Input from 'components/input';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { EDIT_USER } from './queries';
import CustomButton from 'components/common/custom-button';
import Select from 'components/select';
import { get } from 'lodash';
import hooks from 'hooks';
import { disableStyles } from 'components/manage-vm/utils';
import { roles } from './util';
import Toggle from 'components/common/toggle';
import { Label } from 'assets/css/common-styles';
import { P } from 'components/reusable-css';

const EditUser = ({ togglePanel, getRoles, usersRefetch, user, roleRefetch }) => {
  const { mutationProps } = hooks();
  const { t } = useTranslation();
  const { mutate: editUser, isLoading: editUserLoading } = useMutation(EDIT_USER);
  const handleAddUser = async (values, { resetForm }) => {
    await editUser(
      {
        password: btoa(values?.user_password),
        roleid: values?.role,
        id: user.id,
        status: values.status ? 'active' : 'inactive',
      },
      mutationProps(() => {}, {
        onSuccess: async () => {
          togglePanel();
          await usersRefetch();
          roleRefetch();
          resetForm();
        },
      }),
    );
  };
  let initialRole = get(getRoles, 'role_rights[0].id');
  return (
    <React.Fragment>
      <Styles.UserMain>
        <PanelHeader name={user?.id} isClose={togglePanel} heading={'account/usermgmt.string40'} isId={false} />
        <Styles.EditUserContent>
          <Formik
            initialValues={editUserInitialValues(user.role_id || initialRole, { status: user.status })}
            validationSchema={editUserValidationSchema}
            onSubmit={handleAddUser}
          >
            {({ values, handleChange, handleBlur, errors, touched, setFieldValue, dirty }) => (
              <Form style={!get(getRoles, 'role_rights', []).length ? disableStyles : {}}>
                <Styles.FormDiv>
                  <Grid container>
                    <Grid xs={12} sm={4} lg={4} xl={3}>
                      <Label>{t('account/usermgmt.string41')}</Label>
                    </Grid>
                    <Grid xs={11} sm={7} md={6} lg={6} xl={5}>
                      <Input
                        name={'user_password'}
                        type="password"
                        value={values['user_password']}
                        placeholder={t('account/usermgmt.string42')}
                        onChange={handleChange}
                        handleClickShowPassword={() => setFieldValue('user_show_password', !values.user_show_password)}
                        onBlur={handleBlur}
                        error={errors['user_password']}
                        touched={touched['user_password']}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xs={12} sm={4} lg={4} xl={3}>
                      <Label>{t('account/usermgmt.string34')}</Label>
                    </Grid>
                    <Grid xs={11} sm={7} md={6} lg={6} xl={5}>
                      <Input
                        handleClickShowPassword={() =>
                          setFieldValue('user_password_show_again', !values.user_password_show_again)
                        }
                        type="password"
                        name={'user_password_again'}
                        value={values['user_password_again']}
                        placeholder={t('account/usermgmt.string43')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors['user_password_again']}
                        touched={touched['user_password_again']}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ paddingTop: 7 }} container>
                    <Grid xs={12} sm={4} lg={4} xl={3}>
                      <Label>{t('account/usermgmt.string36')}</Label>
                    </Grid>
                    <Grid xs={11} sm={7} md={6} lg={6} xl={5}>
                      <Select
                        styling={{ width: '100%' }}
                        options={roles(getRoles?.role_rights || [])}
                        name={'role'}
                        value={values['role']}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors['role']}
                        touched={touched['role']}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid style={{ paddingTop: 7 }} container>
                    <Grid xs={12} sm={4} lg={4} xl={3}>
                      <h1 className={classes.label}>{t('Status')}</h1>
                    </Grid>
                    <Grid xs={11} sm={7} md={6} lg={6} xl={5}>
                      <Checkbox
                        type="checkbox"
                        value={values['status']}
                        setValue={(value) => setFieldValue('status', value)}
                        title=""
                        id="status"
                        labelStyle={{
                          paddingTop: '7px',
                          fontWeight: '600',
                          fontSize: '14px',
                          marginLeft: '-8px',
                        }}
                      />
                    </Grid>
                  </Grid> */}
                  <Grid style={{ paddingTop: 7 }} container>
                    <Grid xs={12} sm={4} lg={4} xl={3}>
                      <Label>{t('account/usermgmt.string45')}</Label>
                    </Grid>
                    <Grid
                      xs={11}
                      sm={7}
                      md={6}
                      lg={6}
                      xl={5}
                      style={{ display: 'flex', flexDirection: 'column', paddingTop: '1.8em' }}
                    >
                      <Toggle
                        name="status"
                        handleChange={() => {
                          setFieldValue('status', !values.status);
                        }}
                        value="active"
                        checked={values.status}
                      />
                      <P className="mt-2">{t('account/usermgmt.string44')}</P>
                    </Grid>
                  </Grid>
                </Styles.FormDiv>
                <CustomButton
                  type="submit"
                  buttonStyles={{ padding: ' 9px 55px' }}
                  isLoading={editUserLoading}
                  disabled={user.role_id ? !dirty : false}
                />
              </Form>
            )}
          </Formik>
        </Styles.EditUserContent>
      </Styles.UserMain>
    </React.Fragment>
  );
};
export { EditUser };
