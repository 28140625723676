import React, { useState } from 'react';
import { LinkTab, Button, Banner, BoxWidth, SelectWidth, Flex } from 'pages/member/affiliate/Affiliate.style';
import { HeadingWithText } from './HeadingWithText';
import Select from 'components/select';
import { useMediaQuery } from '@material-ui/core';
import { bannerSets, bannerSizes, bannerUrls } from './utils';
import { useSelector } from 'react-redux';
import { P } from 'components/reusable-css';
import { Trans, useTranslation } from 'react-i18next';

const HighConversion = ({ bannerData }) => {
  const { t } = useTranslation();
  const match = useMediaQuery('(min-width:600px)');
  const userAccount = useSelector((state) => state.user.account);
  const width = match ? '99%' : '97%';
  const [selectedBannerValue, setSelectedBannerValue] = useState(
    bannerSets(bannerData) && bannerSets(bannerData)[0].value,
  );
  const [selectedSizes, setSelectedSizes] = useState(
    bannerSizes(bannerData, selectedBannerValue) && bannerSizes(bannerData, selectedBannerValue)[14].value,
  );

  return (
    <HeadingWithText text="affiliate.string15">
      <P>
        <Trans i18nKey={'affiliate.string16'}>
          <a href="https://www.serverpoint.com/en/affiliate/affiliate-banners.phtml" />
        </Trans>
      </P>
      <P>{t('affiliate.string17')}</P>
      <Flex>
        <SelectWidth>
          <Select
            options={bannerSets(bannerData) || []}
            onChange={(e) => {
              setSelectedBannerValue(e.target.value);
            }}
            value={selectedBannerValue}
            styling={{
              width: '97%',
              backgroundColor: 'white',
            }}
          />
        </SelectWidth>
        <SelectWidth>
          <Select
            options={bannerSizes(bannerData, selectedBannerValue)}
            onChange={(e) => {
              setSelectedSizes(e.target.value);
            }}
            value={selectedSizes}
            styling={{
              width: '97%',
              backgroundColor: 'white',
            }}
          />
        </SelectWidth>
        <BoxWidth>
          <LinkTab style={{ width: width }}>
            <div>
              {bannerUrls(bannerData, selectedBannerValue, selectedSizes)?.map((url, i) => {
                let val = `<a href="https://www.serverpoint.com/link.cgi?auser=${userAccount.affiliate_id}"><img src="${url?.text}" alt="serverpoint hosting banner" srcset="${url?.text} 1x,${url?.text} 2x"></a>`;
                return (
                  <div key={i}>
                    <P>{val}</P>
                  </div>
                );
              })}
            </div>
            <div>
              <Button>
                <i className="fa fa-copy" />
                <P>{t('affiliate.string18')}</P>
              </Button>
            </div>
          </LinkTab>
        </BoxWidth>
      </Flex>
      <Banner>
        {bannerUrls(bannerData, selectedBannerValue, selectedSizes)?.map((url, i) => (
          <img key={i} src={url.text} alt="banner" />
        ))}
      </Banner>
    </HeadingWithText>
  );
};
export { HighConversion };
