import React from 'react';
import * as Styles from 'pages/member/changelog/Changelog.style';
import ReactHtmlParser from 'react-html-parser';
import { H6 } from 'components/reusable-css';

const Detail = ({ details }) => {
  return (
    <React.Fragment>
      {details.map((detail, index) => (
        <Styles.DetailSC key={index}>
          {detail.topPara && (
            <React.Fragment>
              <Styles.DetailName>
                <H6>{detail.topHeading}</H6>
              </Styles.DetailName>
              <Styles.P>{detail.topPara}</Styles.P>
            </React.Fragment>
          )}
          {detail?.detail?.map((item, index) => (
            <Styles.DetailFlex key={index}>
              {item.name && (
                <Styles.DetailName>
                  <H6>{item.name}</H6>
                </Styles.DetailName>
              )}
              {item?.descriptions?.map((item, index) => (
                <Styles.DetailText key={index}>
                  <Styles.Circle>
                    <i className="fa fa-circle" />
                  </Styles.Circle>
                  <Styles.P>{ReactHtmlParser(item.description)}</Styles.P>
                </Styles.DetailText>
              ))}
            </Styles.DetailFlex>
          ))}
          <Styles.P>{detail.bottomPara}</Styles.P>
        </Styles.DetailSC>
      ))}
    </React.Fragment>
  );
};

export { Detail };
