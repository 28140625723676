import styled from 'styled-components';

export const PassSC = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  color: black;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;

export const Pass = styled.div`
  margin: 70px 20px 7em 40px;
  @media only screen and (max-width: 786px) {
    margin: 30px 15px 7em 0px;
  }
`;

export const ChangePasswords = styled.div`
  margin: 20px 0 0 4em;
  p {
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 786px) {
    margin: 20px 0 0 3.5em;
    p {
      padding-bottom: 0;
    }
  }
  @media only screen and (max-width: 425px) {
    margin: 20px 0 0 2.6em;
  }
`;

export const FormDiv = styled.div`
  padding-bottom: 2.4em;
`;

export const FlexBox = styled.div`
  display: flex;

  @media only screen and (max-width: 786px) {
    flex-flow: wrap column;
  }
  @media only screen and (min-width: 2000px) {
    /* justify-content: flex-start; */
    width: 30rem;
  }
`;

export const FlexItem = styled.div``;
