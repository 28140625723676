import styled from 'styled-components';
import { variables } from 'variables';

export const NewVMStep3SC = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  // padding-top: 70px;
  padding-top: 45px;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
`;

export const StepDescription = styled.div`
  margin: 50px 0;
`;

export const Heading = styled.h2`
  margin-top: 30px;
  font-size: 30px;
  font-weight: 100;
`;

export const NotificationContainer = styled.div`
  display: flex;
  margin: 50px 0;
`;

export const NewVMStep3SContainer = styled.div`
  padding: ${variables.pageContentPadding}px;
`;
