import styled from 'styled-components';
export const ModalCross = styled.div`
  color: black;
  outline: 0;
  .modal_close i {
    font-size: 16px;
    cursor: pointer;
    margin: 12px;
    color: ${(props) => props.theme.primaryDarkColor};
  }
`;
