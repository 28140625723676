import React, { useState } from 'react';
import { useQuery } from 'react-query';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import CustomButton from 'components/common/custom-button';
import { useMediaQuery, Grid } from '@material-ui/core';
import Select from 'components/select';
import { filteredOsForSelect } from 'components/manage-vm/utils';
import { useTranslation } from 'react-i18next';

const Reinstall = ({ loading, refreshServer, billing_schedule, manageVmId }) => {
  const { t } = useTranslation();
  const { data: os } = useQuery(`/vs/os/${manageVmId}`, {
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  const filterTOSelect = filteredOsForSelect(os);
  const [selectedValue, setSelectedValue] = useState({
    text: filterTOSelect[0]?.name,
    value: filterTOSelect[0]?.id,
  });
  const match = useMediaQuery('(min-width:1117px)');
  const match1 = useMediaQuery('(max-width:1116px)');
  const match2 = useMediaQuery('(max-width:959px)');
  let buttonStyle = match
    ? { marginTop: '7px', marginLeft: '5px', padding: '13px 15px' }
    : match1
    ? { marginTop: '10px', marginLeft: '5px' }
    : match2
    ? { marginTop: '9px', marginLeft: '5px' }
    : null;
  let title = useMediaQuery('(min-width:960px)') ? 'vs/instance.string109' : 'vs/instance.string110';
  return (
    <Styles.ActionDiv>
      <Styles.SelectName>
        <Grid container spacing={0}>
          <Grid xs={8} sm={5} md={5} lg={4} xl={2}>
            <Select
              style={{ marginTop: '5px', width: '100%' }}
              value={selectedValue}
              options={filterTOSelect}
              name="os"
              onChange={(e) => {
                setSelectedValue(e.target.value);
              }}
            />
          </Grid>
          <Grid>
            <CustomButton
              buttonStyles={buttonStyle}
              title={title}
              isLoading={loading}
              onClick={() => refreshServer('reinstall', { image: selectedValue })}
            />
          </Grid>
        </Grid>
      </Styles.SelectName>
      <Styles.OptionText>{t('vs/instance.string111')}</Styles.OptionText>
      {billing_schedule && <Styles.OptionText>{t('vs/instance.string112')}</Styles.OptionText>}
    </Styles.ActionDiv>
  );
};

export { Reinstall };
