import styled from 'styled-components';
import { h2Styles, h6Styles } from 'components/reusable-css';

export const NewVMLastStepSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${(props) => props.theme.pageBackgroundColor};
  border-bottom: 1px solid #ef6c0f;
  align-items: center;
  justify-content: center;
  padding-left: 3.8em;
  @media only screen and (max-width: 768px) {
    padding-left: 3.6em;
  }
  @media only screen and (max-width: 765px) {
    display: flex;
    flex-flow: column wrap;
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em 15px;
  justify-content: center;
  width: 70%;
  .margin {
    margin-top: 1em;
  }
  @media only screen and (max-width: 765px) {
    width: 100%;
  }
`;

export const FundContainer = styled.div`
  display: flex;
  margin: 1em 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em 15px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30%;
  h6 {
    margin: 0;
  }
  .link-fund {
    margin-top: 15px;
    text-decoration:underline;
    p {
      margin: 0;
    }
  }
  @media only screen and (max-width: 765px) {
    width: 100%;
  }
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  color: ${(props) => props.theme.primaryDarkColor};
`;

export const PerMonth = styled.div`
  display: flex;
  align-items: baseline;
  font-weight: 900;
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;

export const PriceUnit = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
`;
