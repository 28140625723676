/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { StepLabel, Highlight } from 'components/vm-deployment/components';
import { NewVMStep6SC, AuthenticationSteps } from '../../new-vm/new-vm-step-6/NewVMStep6.style';
import AuthenticationStep from 'components/label-field';
import { useQuery } from 'react-query';
import { authenticationbmSteps } from 'constants/bm-step6';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormikErrorMessage from 'components/formik-error-message';
import Heading from 'components/Heading/Heading';

const NewVMStep5 = ({ formikProp }) => {
  const matches = useMediaQuery('(max-width:450px)');
  let width = matches ? '100%' : '25rem';
  const textboxStyling = {
    width: width,
  };
  const { t } = useTranslation();
  const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikProp;
  const [ipPrices, setIPPrices] = useState(0);

  useQuery('/prices/bm/ip', {
    onSuccess: async (res) => {
      setIPPrices(res?.prices_bm_ip);
    },
  });

  const calculatePrice = () => {
    let ip_price = 0;
    if (Number(values?.ipv4_ips) > 1) {
      ip_price = (Number(values?.ipv4_ips) - 1) * Number(ipPrices[0]?.price_monthly_usd);
    }
    return ip_price;
  };

  useEffect(() => {
    setFieldValue('prices.ip_price', calculatePrice());
  }, [values.ipv4_ips]);

  return (
    <NewVMStep6SC>
      {/* <StepLabel id="ST5" stepCount={t('bm/instance/create.step5_string1')}>
        <Trans i18nKey="bm/instance/create.step5_string2" components={{ highlight: <Highlight /> }} />
      </StepLabel> */}
      <Heading name={'bm/instance/create.step5_string2'}>
        <AuthenticationSteps>
          {React.Children.toArray(
            authenticationbmSteps.map((item) => (
              // eslint-disable-next-line react/jsx-key
              <AuthenticationStep
                ipPrices={ipPrices}
                styling={textboxStyling}
                options={item.options}
                type={item.type}
                localizeLabel={item.localize_label}
                text={item.text}
                localizeText={item.localize_text}
                name={item.name}
                value={values[item.name]}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors[item.name]}
                touched={touched[item.name]}
                // PasswordInput={item.PasswordInput}
              />
            )),
          )}
        </AuthenticationSteps>
        <FormikErrorMessage
          isText
          textError="string9"
          formikProp={formikProp}
          value={['root_pass', 'confirm_pass', 'virtual_server_name']}
        />
      </Heading>
    </NewVMStep6SC>
  );
};
export default NewVMStep5;
