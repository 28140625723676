import React from 'react';
import * as Styles from 'pages/member/new-bm/new-bm-last-step/NewBMLastStep.style';
import { Trans, useTranslation } from 'react-i18next';
import priceCalculator from 'utils/price-calculator';
import { useSelector } from 'react-redux';
import { totalPriceWithoutDiscountSlider } from 'components/vm-deployment/util';
import { P } from 'components/reusable-css';
import { UpperCaseWord } from 'assets/css/common-styles';

const AmountContent = ({ monthlyPrice, hourlyPrice, noDiscount, title, values }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.account);
  return (
    <Styles.CurrentPrice>
      <Styles.Content>
        <P style={{ fontWeight: '700' }}>{t(title || 'vs/instance.string92')}</P>
        <Styles.Price>
          {Number(user.discount_vs) > 0 && (
            <Styles.Discount>
              <Trans
                i18nKey="vs/instance.string93"
                values={{
                  price: priceCalculator(noDiscount || totalPriceWithoutDiscountSlider(values), false),
                  discount: user.discount_vs,
                  currency: user.account_currency,
                }}
                components={{
                  price: <Styles.ActualPrice />,
                  discount: <Styles.DiscountPrice />,
                  uppercase: <UpperCaseWord />,
                }}
              />
            </Styles.Discount>
          )}
          <Trans
            i18nKey="vs/instance.string94"
            values={{
              price_month: priceCalculator(monthlyPrice, false),
              price_hourly: priceCalculator(hourlyPrice),
              currency: user.account_currency,
              currency_symbol: user.account_currency_symbol,
            }}
            components={{
              price_per_month: <Styles.PerMonth />,
              price_month: <Styles.PriceUnit />,
              price_per_hourly: <Styles.PerHour />,
              price_hourly: <span />,
              uppercase: <UpperCaseWord />,
            }}
          />
        </Styles.Price>
      </Styles.Content>
    </Styles.CurrentPrice>
  );
};

export { AmountContent };
