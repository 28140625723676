import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';

export const AffiliateSC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.pageBackgroundColor};
  width: 100%;
  color: black;
  padding: 4em 3em;
  padding-top: 1em;
  border-bottom: 4px solid ${(props) => props.theme.primaryDarkColor};
  @media only screen and (max-width: 768px) {
    padding: 1em;
  }
`;

export const Paragraphs = styled.div`
  padding-top: 1em;
  p {
    margin-bottom: 1.3rem;
    a {
      text-decoration: underline;
      word-break: break-all;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const HeadingAndText = styled.div`
  display: flex;
  i {
    font-size: 28px;
    font-weight: bolder;
    margin: 0;
    text-align: left;
  }
`;

export const List = styled.div`
  padding-left: 3em;
  margin-top: -1.5rem;
`;

export const Paragraph = styled.div`
  padding: 3em 0 2em 1em;
  p {
    margin-bottom: 1.3rem;
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
    a {
      text-decoration: underline;
      word-break: break-all;
      &:hover {
        text-decoration: none;
      }
  }
`;

export const LinkAndStatsSC = styled.div`
  display: flex;
  @media only screen and (max-width: 965px) {
    flex-wrap: wrap;
  }
`;

export const Links = styled.div`
  width: 60%;
  padding-right: 10px;
  @media only screen and (max-width: 965px) {
    width: 100%;
    padding-right: 0;
  }
  p {
    a {
      word-break: break-all;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const ClickRate = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 965px) {
    margin-top: 1em;
    width: 100%;
    justify-content: flex-start;
  }
  td {
    border-top: none;
  }
  table {
    width: auto;
  }
  .table {
    border-collapse: collapse;
    border-spacing: 0;
    .left {
      padding-right: 16px;
      h1 {
        color: #ff7300;
        font-weight: 1000;
        text-align: right;
      }
    }
    .right {
      border-left: 1px lightgray solid;
      p {
        padding-top: 16px;
        padding-left: 6px;
        font-weight: bold;
        @media only screen and (max-width: 380px) {
          padding-top: 6px;
        }
      }
    }
  }
`;

export const LinkTab = styled.div`
  border: 2px solid lightgray;
  border-bottom: 2px solid orange;
  background-color: white;
  margin-top: 18px;
  padding: 0.6em;
  border-radius: 8px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  p {
    @media only screen and (max-width: 599px) {
      word-break: break-all;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  @media only screen and (max-width: 1300px) {
    flex-wrap: wrap;
  }
`;

export const SelectWidth = styled.div`
  width: 12rem;
  @media only screen and (max-width: 1440px) {
    width: 12rem;
  }
  @media only screen and (max-width: 1300px) {
    width: 50%;
  }
  @media only screen and (max-width: 599px) {
    width: 100%;
  }
`;

export const BoxWidth = styled.div`
  width: 80%;
  @media only screen and (max-width: 1440px) {
    width: 70%;
  }
  @media only screen and (max-width: 1335px) {
    width: 75%;
  }
  @media only screen and (max-width: 1300px) {
    width: 100%;
  }
`;

export const Button = styled.div`
  margin-left: 5px;
  border-radius: 5px;
  display: flex;
  padding: 5px 10px;
  background-color: ${(props) => props.theme.primaryDarkColor};
  cursor: pointer;
  p {
    color: white;
    margin: 0;
    white-space: nowrap;
    margin-left: 3px;
  }
  i {
    font-size: 15px;
    color: white;
  }
`;

export const Banner = styled.div`
  margin-top: 2.5em;
`;

export const SalesCard = styled.div`
  overflow-x: auto;
  width: 100%;
  margin-top: 1em;
`;

export const SalesCardHeader = styled.div`
  display: flex;
  padding: 0 1rem;
  .first {
    min-width: 18rem;
    width: 18%;
    text-align: left;
    font-weight: bold;
  }
  .second {
    min-width: 22rem;
    width: 22%;
    text-align: left;
    margin: 0 5px;
    font-weight: bold;
  }
  .third {
    min-width: 10rem;
    width: 10%;
    text-align: right;
    margin: 0 5px;
    font-weight: bold;
  }
  .fourth {
    min-width: 10rem;
    width: 10%;
    text-align: right;
    margin: 0 5px;
    font-weight: bold;
  }
  .fifth {
    min-width: 14rem;
    width: 14%;
    text-align: right;
    margin: 0 5px;
    font-weight: bold;
  }
  .sixth {
    min-width: 8rem;
    width: 8%;
    text-align: right;
    margin-left: auto;
    font-weight: bold;
  }
`;

export const SalesRow = styled.div`
  border: 2px solid #e3e6e4;
  border-radius: 10px;
  padding: 1rem;
  width: fit-content;
  margin: 10px 0;
  background: white;
  @media only screen and (min-width: 1700px) {
    width: 100%;
  }

  .first {
    display: flex;
    min-width: 18rem;
    width: 18%;
    margin: 1rem 0;
  }
  .second {
    display: flex;
    min-width: 22rem;
    width: 22%;
    margin: 1rem 5px;
  }
  .third {
    display: flex;
    justify-content: flex-end;
    min-width: 10rem;
    width: 10%;
    margin: 1rem 5px;
  }
  .fourth {
    display: flex;
    justify-content: flex-end;
    min-width: 10rem;
    width: 10%;
    margin: 1rem 5px;
  }
  .fifth {
    display: flex;
    justify-content: flex-end;
    min-width: 14rem;
    width: 14%;
    margin: 1rem 5px;
  }
  .sixth {
    display: flex;
    justify-content: flex-end;
    min-width: 8rem;
    width: 8%;
    margin-left: auto;
    margin: 1rem 0;
  }
`;

export const CommentContainer = styled.div`
  margin-top: 1rem;
`;

export const CommentText = styled.div`
  display: flex;
  p {
    padding-left: 10px;
    margin-bottom: 5px;
  }
  i {
    color: white;
    font-size: 10px;
    margin-top: 5px;
  }
`;
