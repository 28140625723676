import React, { useState } from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import CustomButton from 'components/common/custom-button';
import Image from 'assets/images/backup_name.png';
import sandAnimation from 'assets/gifs/sand-animation.gif';
import moment from 'moment';
import { backupHeadingDate } from 'components/manage-vm/utils';
import ConditionalFragment from 'components/conditional-fragment';
import { PermanentLabel } from './PermanentLabel';
import { Box } from '@material-ui/core';
import { RestoreConfirmationModal } from './RestoreConfirmationModal';
import { P } from 'components/reusable-css';
import { Trans, useTranslation } from 'react-i18next';

const BackupCard = ({
  backupData,
  isPermanent,
  backupDelete,
  backupDeleteLoad,
  data,
  restoreBackup,
  restoreBackupLoad,
  rights,
  setIsOpenConfirmationWarning,
  isOpenConfirmationWarning,
}) => {
  const { t } = useTranslation();
  const [restoreId, setRestoreId] = useState();
  return (
    <Styles.BackupCard>
      <div className="backupCardHeader">
        <Box component="h5" width={isPermanent ? '17%' : '18%'} />
        <Box component="h5" width={isPermanent ? '25%' : '38%'} textAlign="right">
          {' '}
          {t('vs/instance.string153')}{' '}
        </Box>
        <Box component="h5" width={isPermanent ? '20%' : '0%'} textAlign="right">
          {' '}
          {isPermanent ? t('vs/instance.string155') : ''}
        </Box>
        <Box component="h5" width={isPermanent ? '11%' : '24%'} textAlign="right">
          {t('vs/instance.string154')}{' '}
        </Box>
      </div>
      {backupData?.map((backup) =>
        backup.status == 'progress' ? (
          <>
            <div className="backupCard">
              <div style={{ display: 'flex', width: '100%' }}>
                <img style={{ width: '1.5rem', height: '1.5rem', marginRight: '0.5rem' }} src={sandAnimation} />
                <P>
                  {backup.frequency == 'permanent' ? (
                    <Trans i18nKey={'vs/instance.string157'} />
                  ) : (
                    <Trans i18nKey={'vs/instance.string158'} />
                  )}
                </P>
              </div>
            </div>
            <div style={{ marginBottom: '12px' }} />
          </>
        ) : (
          <>
            <div key={backup.id} className="backupCard">
              <div style={{ width: isPermanent ? '17%' : '18%' }} className="d-flex">
                <div>
                  <img className="pt-1" src={Image} />
                </div>
                <div>
                  <P className="pl-1 pt-2 text-nowrap">{backupHeadingDate(backup)}</P>
                </div>
              </div>
              <div style={{ width: isPermanent ? '25%' : '38%', textAlign: 'right' }}>
                <P className="pt-2 text-nowrap">
                  <Trans
                    i18nKey={'vs/instance.string159'}
                    values={{ date: moment(backup.backup_date).format('LL LTS').toLocaleString() }}
                  />
                </P>
              </div>
              <ConditionalFragment condition={isPermanent}>
                <div
                  className="pt-2 text-nowrap"
                  style={{ width: '20%', display: 'flex', justifyContent: 'flex-end', textAlign: 'right' }}
                >
                  <PermanentLabel rights={rights} data={data} backup={backup} />
                </div>
              </ConditionalFragment>
              <div style={{ width: isPermanent ? '11%' : '24%', textAlign: 'right' }}>
                <P className="text-capitalize pt-2 text-nowrap">{backup.status}</P>
              </div>
              <div
                style={{
                  width: isPermanent ? '27%' : '20%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <ConditionalFragment condition={isPermanent && rights?.vsbackupdelete}>
                  <CustomButton
                    title="vs/instance.string116"
                    buttonStyles={{ padding: '5px 22px', margin: isPermanent ? '0 0.3rem' : '0rem' }}
                    onClick={() => backupDelete(backup.id)}
                    isLoading={backupDeleteLoad}
                  />
                </ConditionalFragment>
                <ConditionalFragment condition={rights?.vsbackuprestore}>
                  <CustomButton
                    title="vs/instance.string156"
                    buttonStyles={{ padding: '5px 18px', margin: isPermanent ? '0 0.3rem' : '0rem' }}
                    onClick={() => {
                      setRestoreId(backup.id);
                      setIsOpenConfirmationWarning(true);
                    }}
                  />
                </ConditionalFragment>
              </div>
              <RestoreConfirmationModal
                open={isOpenConfirmationWarning}
                loading={restoreBackupLoad}
                toggle={() => setIsOpenConfirmationWarning(false)}
                handleSubmit={async () => await restoreBackup(restoreId)}
              />
            </div>
            <div style={{ marginBottom: '12px' }} />
          </>
        ),
      )}
    </Styles.BackupCard>
  );
};
export { BackupCard };
