import React, { useState } from 'react';
import SlidingPanels from 'components/common/sliding-panel';
import { Icon } from 'semantic-ui-react';
import { EditRole } from 'components/user-management/edit-role';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const RoleEditPanel = ({ getRole, roleRefetch, labels, usersRefetch }) => {
  const { t } = useTranslation();
  const [openPanel, setOpenPanel] = useState(false);
  const match1 = useMediaQuery('(max-width:550px)');
  let size = match1 ? 95 : 70;

  return (
    <div>
      <div onClick={() => setOpenPanel(true)}>
        <Icon name="pencil" />
        <span>{t('account/usermgmt.string14')}</span>
      </div>
      <SlidingPanels size={size} isOpen={openPanel} isClose={setOpenPanel}>
        <EditRole
          usersRefetch={usersRefetch}
          getRole={getRole}
          isClose={setOpenPanel}
          labels={labels}
          roleRefetch={roleRefetch}
        />
      </SlidingPanels>
    </div>
  );
};

export { RoleEditPanel };
