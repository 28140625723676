import React from 'react';
import { HeadingWithText } from './HeadingWithText';
import { useQuery } from 'react-query';
import { CustomLineChart } from 'components/manage-vm/statistics-panel/charts';
import { YStepSizeRounded } from 'components/manage-vm/statistics-panel/util';
import { TrafficDataYMax, TrafficData } from './util';
import { P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const Traffic = () => {
  const { t } = useTranslation();
  const { data: affiliateData } = useQuery('/affiliate/stats');
  let maxValues = TrafficDataYMax(affiliateData);
  return (
    <HeadingWithText text="affiliate.string19" backgroundColor={{ backgroundColor: 'white' }}>
      <P>{t('affiliate.string20')}</P>
      <div style={{ height: '28rem' }}>
        <CustomLineChart
          yLabel=" "
          statsData={TrafficData(affiliateData)}
          ystepSize={YStepSizeRounded('', maxValues, 1)}
          yMax={maxValues?.yMaxValue}
        />
      </div>
    </HeadingWithText>
  );
};
export { Traffic };
