import React from 'react';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import { Card, NetworkHeading } from 'components/vm-deployment/worldwide-cloud-region/WorldwideCloudRegion.style';
import { useTranslation, Trans } from 'react-i18next';
import { Highlight, StepLabel } from 'components/vm-deployment/components';
import Checkbox from 'components/common/radio-button';
import { Grid } from '@material-ui/core';
import { networkPricing, networkCapacity } from 'components/manage-vm/utils';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

const Step3 = ({ sliderData, formikProp }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.account);
  const { setFieldValue, values } = formikProp;
  const handleClick = (price) => {
    setFieldValue('nic_mbps', price.capacity);
    setFieldValue('prices.nic_mbps', +price.price_discounted_monthly_usd);
    setFieldValue('prices.networkCapacity_no_discount', +price.price_monthly_usd);
  };
  return (
    <div>
      <Styles.OrangeBorder />
      <Styles.Padding>
        <StepLabel stepCount={t('vs/instance.string87')}>
          <Trans i18nKey="vs/instance.string95" components={{ highlight: <Highlight /> }} />
        </StepLabel>
        <Styles.Step3Margin>
          <Grid container spacing={0}>
            {get(sliderData, 'prices_vs_slider_nic_units', []).map((price, index) => (
              <Grid key={index} item xs={12} sm={7} md={5} lg={4} xl={2}>
                <Card
                  onClick={() => {
                    handleClick(price);
                  }}
                >
                  <Checkbox
                    data={price}
                    setCheckedValue={(value) => setFieldValue(value)}
                    checkbox={values.nic_mbps}
                    field="capacity"
                    onClick={() => {
                      handleClick(price);
                    }}
                  />
                  <NetworkHeading>
                    {networkPricing(price).isMonthly ? (
                      <Trans
                        i18nKey={price.capacity >= 1000 ? 'vs/instance.string99' : 'vs/instance.string98'}
                        values={{
                          currency_symbol: user.account_currency_symbol,
                          price: networkCapacity(price),
                          price_monthly: networkPricing(price).price_monthly,
                        }}
                      />
                    ) : (
                      <Trans
                        i18nKey={price.capacity >= 1000 ? 'vs/instance.string97' : 'vs/instance.string96'}
                        values={{ price: networkCapacity(price) }}
                      />
                    )}
                  </NetworkHeading>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Styles.Step3Margin>
      </Styles.Padding>
    </div>
  );
};

export { Step3 };
