import React from 'react';
import { LinkAndStatsSC, Links, ClickRate } from 'pages/member/affiliate/Affiliate.style';
import { HeadingWithText, AffiliateRight } from './index';
import { useSelector } from 'react-redux';
import { get } from 'utils';
import { P } from 'components/reusable-css';
import { Trans, useTranslation } from 'react-i18next';

const LinkAndStats = ({ affiliate }) => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  return (
    <HeadingWithText text="affiliate.string6" backgroundColor={{ backgroundColor: 'white' }}>
      <LinkAndStatsSC>
        <Links>
          <div>
            <P>{t('affiliate.string7')}</P>
            <P>
              <Trans i18nKey={'affiliate.string8'} values={{ id: userAccount.affiliate_id }}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.serverpoint.com/link.cgi?auser=${userAccount.affiliate_id}`}
                />
              </Trans>
            </P>
            <P>{t('affiliate.string9')}</P>
            <P>
              <Trans i18nKey={'affiliate.string10'} values={{ id: userAccount.affiliate_id }}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.serverpoint.com/link.cgi?auser=${userAccount.affiliate_id}&url=https://www.colossuscloud.com/locations`}
                />
              </Trans>
            </P>
            <P>
              <Trans i18nKey={'affiliate.string11'} values={{ id: userAccount.affiliate_id }}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.serverpoint.com/link.cgi?auser=${userAccount.affiliate_id}&url=https://www.colossuscloud.com/vps-hosting`}
                />
              </Trans>
            </P>
          </div>
        </Links>
        <ClickRate>
          {get(affiliate, 'affiliate_info', []).map((val, i) => (
            <AffiliateRight item={val} key={i} />
          ))}
        </ClickRate>
      </LinkAndStatsSC>
    </HeadingWithText>
  );
};
export { LinkAndStats };
