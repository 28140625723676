/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import BackupPlanCard from 'components/vm-deployment/backup-plan-card';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import { Heading } from 'components/user-management';
import { Highlight } from 'components/vm-deployment/components';
import { P } from 'components/reusable-css';
import { Trans, useTranslation } from 'react-i18next';
const BackupPlan = ({ formikProp, backupData, changeBackupPlan, changeBackupLoad }) => {
  const { t } = useTranslation();
  const [updatePlan, setUpdatePlan] = useState();
  const { values, setFieldValue } = formikProp;
  const handleServerSizeSelection = (data) => {
    // setFieldValue('backup_plan', data.id);
  };

  //WHEN THERE IS NO BACKUP PLAN
  const backupPlan = values.backup_plan ? values.backup_plan : 'backupplan0';

  return (
    <Styles.BackupPlan>
      <Styles.Align>
        <Heading>
          <Trans i18nKey={'vs/instance.string138'} components={{ highlight: <Highlight /> }} />
        </Heading>
        <Styles.BackupPadding>
          <Styles.Paragraph>
            <P>{t('vs/instance.string139')}</P>
          </Styles.Paragraph>
        </Styles.BackupPadding>
        <Styles.BackupCardPadding>
          <Styles.ServerSizeGrid>
            {backupData?.map((backuplevelData) => (
              <BackupPlanCard
                key={backuplevelData.id}
                data={backuplevelData}
                selected={backuplevelData.id === backupPlan}
                onClick={handleServerSizeSelection}
                isUpgrade
                backupPlan={backupPlan}
                upgradeClick={() => {
                  setUpdatePlan(backuplevelData.id);
                  changeBackupPlan(backuplevelData.id);
                }}
                upgradeButtonLoad={updatePlan === backuplevelData.id && changeBackupLoad}
              />
            ))}
          </Styles.ServerSizeGrid>
        </Styles.BackupCardPadding>
      </Styles.Align>
    </Styles.BackupPlan>
  );
};
export { BackupPlan };
