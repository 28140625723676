import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';

export const TicketEasyViewSC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.div`
  width: 300px;
`;

export const TicketClosed = styled.div`
  background-color: rgb(153, 51, 153);
  color: white;
  text-align: center;
  border-radius: 5px;
  padding: 0.6rem 1.4rem;
  font-weight: bold;
  margin-bottom: 2em;
  i {
    margin: 0 8px;
    height: 20px;
  }
`;

export const ChatSC = styled.div`
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
`;

export const Container = styled.div`
  margin: 1.5em 2.5em;
  @media only screen and (max-width: 1280px) {
    margin: 1em;
  }
`;

export const Heading = styled.div`
  font-size: 30px;
  font-weight: normal;
  @media only screen and (max-width: 550px) {
    font-size: 23px;
  }
`;

export const SecondRow = styled.div`
  margin-top: 0.5em;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
  .dot {
    font-size: 5px;
    margin: 0 8px;
    color: #606c7c;
  }
`;

export const Chat = styled.div`
  margin: 2em 0;
`;

export const ReplyChatContainerBg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  margin-left: 240px;
  background: ${(props) => props.theme.lightShadeBackgroundColor};
  @media only screen and (max-width: 959px) {
    margin-left: 0;
  }
`;

export const ReplyChatContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Chatlog = styled.div`
  display: flex;
  @media only screen and (max-width: 959px) {
    flex-wrap: wrap;
  }
`;

export const ChatMobile = styled.div`
  display: flex;
`;

export const Div1 = styled.div`
  width: 16em;
  max-width: 170px;
  min-width: 170px;
`;

export const Div3 = styled.div`
  width: 100%;
`;

export const DateSC = styled.div`
  background: ${(props) => (props.bg == 'client' ? props.theme.lightShadeBackgroundColor : '#606c7c')};
  padding: 20px;
  color: ${(props) => (props.bg == 'client' ? 'black' : 'white')};
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
  @media only screen and (min-width: 960px) {
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid ${(props) => (props.bg == 'client' ? props.theme.lightShadeBackgroundColor : '#606c7c')};
      position: absolute;
      top: 20px;
      right: -9px;
    }
  }
  @media only screen and (max-width: 959px) {
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid ${(props) => (props.bg == 'client' ? props.theme.lightShadeBackgroundColor : '#606c7c')};
      position: absolute;
      top: 20px;
      left: -10px;
    }
  }
`;

export const UserName = styled.h5`
  font-size: 14px;
  line-height: 1.5em;
  span {
    color: ${(props) => (props.staff ? 'white' : '#567ba8')};
  }
`;

export const TimeAgo = styled.h5`
  font-size: 13px;
`;

export const Line = styled.div`
  position: relative;
  margin: 0 35px;
  width: 2px;
  min-height: 100%;
  background: #bfbfbf;
  color: #ff7300;

  .line-background {
    position: absolute;
    font-size: 18px;
    font-weight: 700;
    top: 21px;
    right: -7px;
  }
  @media only screen and (max-width: 959px) {
    margin: 0;
    margin-left: 8px;
  }
`;

export const DetailSC = styled.div`
  background: ${(props) => (props.bg == 'client' ? props.theme.lightShadeBackgroundColor : '#606c7c')};
  color: ${(props) => (props.bg == 'client' ? 'black' : 'white')};
  padding: 20px;
  margin-bottom: ${(props) => (props.secret ? '10px' : '30px')};
  border-radius: 5px;
  position: relative;
  p {
    font-size: 16px;
    font-weight: normal;
  }
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid ${(props) => (props.bg == 'client' ? props.theme.lightShadeBackgroundColor : '#606c7c')};
    position: absolute;
    top: 20px;
    left: -10px;
  }
`;

export const ReplyContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-left: 2px solid #bfbfbf;
  margin-left: 205px;
  i {
    margin-top: 8px;
    color: #606c7c;
  }
  @media only screen and (max-width: 959px) {
    border-left: 0;
  }
`;

export const ReplyChat = styled.p`
  color: #606c7c;
  border: 1px solid lightgray;
  border-radius: 20px;
  padding: 5px 10px;
  font-weight: bold;
  margin-right: 3px;
`;

export const WysimarkInfo = styled.div`
  width: 45%;
  font-weight: 500;
  font-size: 16px;
  margin-right: 20px;
  span {
    font-size: 15px;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    span {
      font-size: 14px;
    }
  }
`;
export const SecrectInputContainer = styled.div`
  width: 300px;
  @media only screen and (max-width: 700px) {
    width: 100%;
`;

export const SecretContainer = styled.div`
  display: flex;
  max-width: 800px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  @media only screen and (max-width: 700px) {
    display: block;
  }
`;

export const MessageDate = styled.div`
  color: ${(props) => (props.staff ? 'white' : 'grey')};
  margin-bottom: 15px;
`;

export const SecretInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 3em;
  padding: 0 10px;
  div {
    width: 24em;
  }
  p {
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
    margin: 0;
    margin-bottom: 5px;
  }
`;
