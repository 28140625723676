import React from 'react';
import * as Styles from './NewVMLastStep.style';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import CustomButton from 'components/common/custom-button';
import classes from './select.module.css';
import { useSelector } from 'react-redux';
import { totalPrice, hoursTotalPrice, totalPriceWithoutDiscountSlider } from 'components/vm-deployment/util';
import { fetchedOptions } from 'components/vm-deployment/constants/last-step';
import { updateNoOfVM } from 'store/actions/vm-deployment';
import { scrollToSection } from 'utils';
import { Message } from 'components/alert/Message';
import { cardError } from 'components/vm-deployment/constants';
import priceCalculator from 'utils/price-calculator';
import { P } from 'components/reusable-css';
import { UpperCaseWord } from 'assets/css/common-styles';

const NewVMLastStep = ({ name, value, formikProp }) => {
  const { t } = useTranslation();
  const { validateForm, handleSubmit, isSubmitting, values, setFieldTouched, isValid } = formikProp;
  const dispatch = useDispatch();
  const price = useSelector((state) => state.vmDeployment.pricingDetails);
  const noOfVM = useSelector((state) => state.vmDeployment.noOfVM);
  const user = useSelector((state) => state.user.account);
  const handleChange = (e) => dispatch(updateNoOfVM(e.target.value));

  return (
    <Styles.NewVMLastStepSC>
      <Styles.PriceServer>
        <Styles.Content>
          <P className="your_server_price">{t('vs/instance/create.string3')}</P>
          {Number(user.discount_vs) > 0 && (
            <Styles.Discount>
              <Trans
                i18nKey="vs/instance/create.string4"
                values={{
                  price: priceCalculator(totalPriceWithoutDiscountSlider(price) * noOfVM, false),
                  discount: user.discount_vs,
                  currency: user.account_currency,
                  currency_symbol: user.account_currency_symbol,
                }}
                components={{
                  price: <Styles.ActualPrice />,
                  discount: <Styles.DiscountPrice />,
                  uppercase: <UpperCaseWord />,
                }}
              />
            </Styles.Discount>
          )}
          <Styles.Price>
            <Trans
              i18nKey="vs/instance/create.string5"
              values={{
                price_month: priceCalculator(totalPrice(price) * noOfVM, false),
                price_hourly: (hoursTotalPrice(price) * noOfVM).toFixed(3),
                currency: user.account_currency,
                currency_symbol: user.account_currency_symbol,
              }}
              components={{
                price_per_month: <Styles.PerMonth />,
                price_month: <Styles.PriceUnit />,
                price_per_hourly: <Styles.PerHour />,
                price_hourly: <span />,
                uppercase: <UpperCaseWord />,
              }}
            />
          </Styles.Price>
        </Styles.Content>
      </Styles.PriceServer>
      <Styles.VirtualServer>
        <Styles.Paragraph>{t('vs/instance/create.string6')}</Styles.Paragraph>
        <Styles.SubmitServer>
          <select name={name} className={classes.select} value={value} onChange={handleChange}>
            {React.Children.toArray(
              fetchedOptions.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.text}
                </option>
              )),
            )}
          </select>
          <CustomButton
            title={t('vs/instance/create.string7')}
            isLoading={isSubmitting}
            disabled={isSubmitting}
            type="submit"
            buttonStyles={{
              marginLeft: '10px',
              padding: '0.8rem 1rem',
              opacity: !isValid && 0.3,
              backgroundColor: !isValid && 'gray',
            }}
            onClick={async () => {
              const errors = await validateForm();
              handleSubmit();
              setFieldTouched('image', true);
              setFieldTouched('region', true);
              if (errors.size) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST1');
              }
              if (errors.region) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST2');
              }
              if (errors.image) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST3');
              }
              if (errors.confirm_pass) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST6');
              }

              if (errors.virtual_server_name) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST6');
              }
              if (errors.options_prepaid) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('ST7');
              }

              if (errors.payment_method) {
                Message.error(t(errors.payment_method), t('string13'));
                return scrollToSection('PAYMENT-CARD');
              }
              if (cardError(errors, values)) {
                Message.error(t('string14'), t('string13'));
                return scrollToSection('PAYMENT-CARD');
              }
            }}
          />
        </Styles.SubmitServer>
      </Styles.VirtualServer>
    </Styles.NewVMLastStepSC>
  );
};

export default NewVMLastStep;
