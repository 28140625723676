import { Button } from 'assets/css/custom-button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner';
import * as Styles from './Styles';

const CustomButton = ({
  leftIcon,
  isIcon,
  pointerEvents,
  title,
  buttonStyles,
  isLoading,
  disabled,
  heightStyling,
  ...remaining
}) => {
  const { t } = useTranslation();
  return (
    <Button pointerEvents={pointerEvents} loading={isLoading} style={{ height: heightStyling }}>
      <button disabled={disabled} type="button" {...remaining} style={{ ...buttonStyles, position: 'relative' }}>
        {leftIcon && leftIcon}
        {t(title || 'string6')}
        {isLoading && (
          <Styles.SpinnerContainer>
            <Spinner color="white" />
          </Styles.SpinnerContainer>
        )}
        {isIcon && isIcon}
      </button>
    </Button>
  );
};

export default CustomButton;
