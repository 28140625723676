import React, { useState } from 'react';
import * as Styles from 'pages/member/recoverable-vs/RecoverableVS.style';
import { VmNetworks } from 'components/manage-vm/components/VmNetworks';
import { getBlackCountryImage, checkDeletedItems } from 'components/manage-vm/utils';
import CustomButton from 'components/common/custom-button';
import { useTranslation } from 'react-i18next';
import { RECOVERABLE_VS_ACTION } from 'components/recoverable-vs/queries';
import { useMutation } from 'react-query';
import hook from 'hooks';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import { H5, H6 } from 'components/reusable-css';

const RecoverableVs = ({ data, refetchMenu }) => {
  const { mutationProps } = hook();
  const state = useSelector((state) => state.user);
  const rights = useSelector(userRights);
  const [isDelete, setIsDelete] = useState([]);
  const { t } = useTranslation();
  const { mutate: unDelete, isLoading: deleteLoading } = useMutation(RECOVERABLE_VS_ACTION, {
    onSuccess: () => {
      state.userAccountRefetch();
      refetchMenu();
    },
  });
  const handleSubmit = async () => {
    setIsDelete([...isDelete, data.id]);
    await unDelete({ id: data.id, action: 'undelete' }, mutationProps());
  };
  let opacity = checkDeletedItems(isDelete, data?.id);

  return (
    <Styles.Card style={opacity}>
      <Styles.CardContent>
        <Styles.Icon>
          <Styles.Picture>
            {data?.os_class == 'windows' ? (
              <i className="fa fa-windows fa-2x" />
            ) : data?.os_class == 'linux' ? (
              <i className="fa fa-linux fa-2x" />
            ) : null}
          </Styles.Picture>
          <Styles.Border />
          <Styles.Label>
            <H5>{data?.label}</H5>
          </Styles.Label>
        </Styles.Icon>
        <Styles.Region>
          <Styles.CountryFlag>
            <Styles.Circle>
              <img src={getBlackCountryImage(data?.region_country, data?.region_ucase)} alt="country" />
            </Styles.Circle>
            <H6>{data?.region_ucase}</H6>
          </Styles.CountryFlag>
          <VmNetworks data={data} />
        </Styles.Region>
        <Styles.Button>
          <CustomButton
            disabled={rights.vsundodelete === 0}
            onClick={handleSubmit}
            isLoading={deleteLoading}
            title={t('vs/recoverable.string8')}
          />
        </Styles.Button>
      </Styles.CardContent>
    </Styles.Card>
  );
};
export default RecoverableVs;
