/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
const RE_URL = /\w+:\/\/\S+/g;
export const linkify = (str) => {
  let match;
  const results = [];
  let lastIndex = 0;
  // eslint-disable-next-line no-cond-assign
  while ((match = RE_URL.exec(str))) {
    const link = match[0];
    if (lastIndex !== match.index) {
      const text = str.substring(lastIndex, match.index);
      results.push(<span tabIndex="-1" key={results.length}>{text}</span>);
    }
    results.push(
      // eslint-disable-next-line react/jsx-no-target-blank
      <a tabIndex="-1" key={results.length} href={link} target="_blank">
        {link}
      </a>,
    );
    lastIndex = match.index + link.length;
  }
  if (results.length === 0) {
    return str;
  }
  if (lastIndex !== str.length) {
    results.push(<span tabIndex="-1" key={results.length}>{str.substring(lastIndex)}</span>);
  }
  return results;
};
