import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import Input from 'components/input';
import ConditionalFragment from 'components/conditional-fragment';
import { useMutation } from 'react-query';
import { BACKUP_PATCH_UPDATE } from 'components/manage-vm/queries';
import hooks from 'hooks';
import TEMPLATE_SCHEMA from 'validations/template';
import { P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const PermanentLabel = ({ backup, data, rights }) => {
  const { t } = useTranslation();
  const [backupTemplate, setBackupTemplate] = useState(backup.label);
  const [isValidFormat, setIsValidFormat] = useState(true);
  const { mutationProps } = hooks();
  const [isEdit, setIsEdit] = useState(false);
  const { mutate: backupPatchUpdate, isLoading } = useMutation(BACKUP_PATCH_UPDATE, {
    ...mutationProps(),
  });
  const handleTemplateSubmit = async () => {
    if (isValidFormat)
      backupPatchUpdate(
        { id: data.id, backup: backup.id, label: backupTemplate },
        {
          onSuccess: () => {
            setIsEdit(false);
          },
        },
      );
  };
  return (
    <React.Fragment>
      <ConditionalFragment condition={!isEdit}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', textAlign: 'right' }}>
          <P style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className="text-capitalize">
            {backupTemplate}{' '}
          </P>
          <ConditionalFragment condition={rights?.vsbackuprename}>
            <span onClick={() => setIsEdit(true)}>
              <Icon name="pencil" />
            </span>
          </ConditionalFragment>
        </div>
      </ConditionalFragment>
      <ConditionalFragment condition={isEdit}>
        <Input
          styling={{ marginTop: '-30px', marginBottom: '0px', marginRight: '-2px', width: '85%' }}
          type="text"
          isFormIk={false}
          name="permanent_label"
          value={backupTemplate}
          placeholder={t('vs/instance.string155')}
          onChange={async (e) => {
            setBackupTemplate(e.target.value);
            const isValid = await TEMPLATE_SCHEMA.isValid({
              label: e.target.value,
            });
            setIsValidFormat(isValid);
          }}
          EndAdornment={() => (
            <span onClick={handleTemplateSubmit}>
              <Icon name="save" />
            </span>
          )}
          inputProps={{
            maxlength: 32,
          }}
          loading={isLoading}
          error={!isValidFormat && 'Not in correct format'}
        />
      </ConditionalFragment>
    </React.Fragment>
  );
};

export { PermanentLabel };
