import React, { useState } from 'react';
import * as Styles from './Account.style';
import { useTranslation, Trans } from 'react-i18next';
import { Highlight } from 'components/vm-deployment/components';
import { useSelector } from 'react-redux';
import { userRights } from 'store/selectors/user-details';
import { Heading } from 'assets/css/common-styles';
import { P } from 'components/reusable-css';
import VmPaymentForm from '../../new-vm/new-vm-step-7/vm-payment-form';
import { Formik } from 'formik';
import { initialValues, validate, cardError } from '../constants';
import Input from 'components/input';
import Checkbox from 'components/common/radio-button';
import { validationObject, validationChargedCard } from 'validations';
import { H6 } from 'components/reusable-css';
import { useMutation } from 'react-query';
import { Message } from 'components/alert/Message';
import { CHARGE_SAVED_CARD, SAVE_CHARGE_CARD } from 'components/vm-deployment/queries/billing';
import { vm_year } from 'utils';
import { vm_amount, validatePrice, isChargeCard } from '../utils';
import { CustomHook } from 'utils/custom-hook';
import { get } from 'lodash';
import { vs_amounts_options } from '../utils';
import { useMediaQuery } from '@material-ui/core';
import ConditionalFragment from 'components/conditional-fragment/ConditionalFragment';
import currencyFilter from 'utils/currency-filter';
import CreditCard from '../credit-card/CreditCard';

const Account = ({ storedCreditCards, refetchStoredCreditCards }) => {
  const rights = useSelector(userRights);
  const state = useSelector((state) => state.user);
  const userUI = useSelector((state) => state.user.ui);
  const { account_approved } = state.account || {};
  const { t } = useTranslation();
  const { mutationProps } = CustomHook();
  const matches = useMediaQuery('(max-width:450px)');
  const { mutate: chargeSavedCard, isLoading: chargeSavedCardLoading } = useMutation(CHARGE_SAVED_CARD);
  const { mutate: saveChargeCard, isLoading: saveChargeCardLoading } = useMutation(SAVE_CHARGE_CARD);
  const credit_card_info = useSelector((state) => state.user.billing_info);
  const credit_card_info_currency = useSelector((state) => state?.user?.account);
  const billingRefetch = useSelector((state) => state.user.userBillingAccountRefetch);
  const initialSelectedItem = get(vs_amounts_options(state?.billing_info?.billing_payment_amount_options), '[0]', {});
  const [isChecked, setIsChecked] = useState(initialSelectedItem.id);

  const handleVmPayment = async (values, resetForm) => {
    const {
      address_continue,
      zip,
      state,
      country,
      city,
      payment_method,
      card_number,
      card_month,
      card_year,
      cvv,
      card_name,
      phone,
      company,
      billing_address,
      bank_name,
      bank_phone_number,
    } = values;
    const isChargeCardExist =
      validatePrice(values) && payment_method === 'credit_debit' && isChargeCard(credit_card_info);
    const isNoChangeCard =
      validatePrice(values) &&
      payment_method === 'credit_debit' &&
      !credit_card_info.billing_default_credit_card_exp &&
      !credit_card_info.billing_default_credit_card_last_four;

    if (isChargeCardExist) {
      try {
        await chargeSavedCard(
          {
            amount: vm_amount(values),
            currency: credit_card_info_currency?.account_currency,
          },
          mutationProps('', {
            onSuccess: async () => {
              await billingRefetch();
            },
          }),
        );
      } catch (err) {
        Message.error(t(`${err.response.data?.error}`));
      }
    }
    if (isNoChangeCard) {
      try {
        await saveChargeCard(
          {
            number: card_number,
            expmonth: ('0' + card_month).slice(-2),
            expyear: vm_year(card_year),
            cvv: cvv,
            name: card_name,
            phone: phone.replace(/\D/g, ''),
            organization: company,
            address1: billing_address,
            address2: address_continue,
            city: city,
            state: state,
            zipcode: zip,
            country: country,
            bank: bank_name,
            bankphone: bank_phone_number.replace(/\D/g, ''),
            currency: credit_card_info_currency?.account_currency,
            amount_to_charge: vm_amount(values),
            make_default: '1',
          },
          mutationProps(resetForm, {
            onSuccess: async () => await billingRefetch(),
          }),
        );
      } catch (err) {
        Message.error(t(`${err.response.data?.error}`));
      }
    }
  };

  let input_width = matches
    ? { width: '80%', marginBottom: '3.5rem', paddingLeft: '2.2rem' }
    : { width: '17rem', marginBottom: '3.5rem', paddingLeft: '2.2rem' };
  let customChecked = isChecked === 'custom_amount';
  let validationSchema = !isChargeCard(credit_card_info)
    ? customChecked
      ? validationObject(true)
      : validationObject(false)
    : customChecked
    ? validationChargedCard
    : '';

  return (
    <>
      {userUI?.show_funds_deposit_section != 0 && (
        <Formik
          initialValues={{ ...initialValues, vm_price: initialSelectedItem.price }}
          enableReinitialize
          validationSchema={validationSchema}
          validate={(values) => validate(values, 'vm')}
          onSubmit={(values, { resetForm }) => handleVmPayment(values, resetForm)}
        >
          {(formikProp) => (
            <Styles.AccountSC>
              <Styles.Accounts>
                <Heading>
                  {account_approved == 1 ? (
                    <>
                      {state?.billing_info?.billing_due_services == 1 ? (
                        <Trans
                          i18nKey="account/billing_info.string1"
                          components={{ highlight: <Highlight color="red" /> }}
                        />
                      ) : (
                        <Trans i18nKey="account/billing_info.string3" components={{ highlight: <Highlight /> }} />
                      )}
                    </>
                  ) : (
                    <Trans i18nKey="account/billing_info.string2" components={{ highlight: <Highlight /> }} />
                  )}
                </Heading>
                <Styles.Paragraphs>
                  {account_approved == 0 ? (
                    <>
                      <P>
                        {state?.billing_info?.billing_due_services == 1
                          ? t('account/billing_info.string4')
                          : t('account/billing_info.string5')}
                      </P>
                      <P>{t('account/billing_info.string6')}</P>
                    </>
                  ) : (
                    <P>{t('account/billing_info.string7')}</P>
                  )}
                  <ConditionalFragment
                    condition={
                      Number(state?.billing_info?.billing_vs_due_full_balance) > 0 ? true : rights.billingmakepmt !== 0
                    }
                  >
                    <P>{t('account/billing_info.string15')}</P>
                  </ConditionalFragment>
                  <ConditionalFragment
                    condition={
                      Number(state?.billing_info?.billing_vs_due_full_balance) > 0 ? true : rights.billingmakepmt !== 0
                    }
                  >
                    <Styles.MidContent>
                      {Number(state?.billing_info?.billing_vs_due_full_balance) > 0 && (
                        <Styles.Price
                          onClick={() => {
                            setIsChecked('due_amount');
                            formikProp.setFieldValue('vm_price', state?.billing_info?.billing_vs_due_full_balance);
                          }}
                          key={'due_amount'}
                        >
                          <Checkbox
                            checkbox={isChecked}
                            setCheckedValue={() => {
                              setIsChecked('due_amount');
                              formikProp.setFieldValue('vm_price', state?.billing_info?.billing_vs_due_full_balance);
                            }}
                            data={{ id: 'due_amount' }}
                          />
                          <H6>
                            <Trans
                              i18nKey={'account/billing_info.string18'}
                              values={{
                                price: currencyFilter(
                                  state?.billing_info?.billing_vs_due_full_balance,
                                  state?.account?.account_currency,
                                ),
                              }}
                            />
                          </H6>
                        </Styles.Price>
                      )}
                      <Styles.Prices>
                        {(vs_amounts_options(state?.billing_info?.billing_payment_amount_options) || []).map(
                          (price) => (
                            <Styles.Price
                              onClick={() => {
                                setIsChecked(price?.id);
                                formikProp.setFieldValue('vm_price', price?.price);
                              }}
                              key={price.id}
                            >
                              <Checkbox
                                checkbox={isChecked}
                                setCheckedValue={() => {
                                  setIsChecked(price?.id);
                                  formikProp.setFieldValue('vm_price', price?.price);
                                }}
                                data={price}
                              />
                              <H6> {currencyFilter(price?.price, state?.account?.account_currency)} </H6>
                            </Styles.Price>
                          ),
                        )}
                        <Styles.Price
                          onClick={() => {
                            setIsChecked('custom_amount');
                            formikProp.setFieldValue('vm_price', 'custom_amount');
                          }}
                          key={'custom_amount'}
                        >
                          <Checkbox
                            checkbox={isChecked}
                            setCheckedValue={() => {
                              setIsChecked('custom_amount');
                              formikProp.setFieldValue('vm_price', 'custom_amount');
                            }}
                            data={{ id: 'custom_amount' }}
                          />
                          <H6>
                            <Trans
                              i18nKey={'account/billing_info.string19'}
                              values={{ currency_symbol: state?.account.account_currency_symbol }}
                            />
                          </H6>
                        </Styles.Price>
                      </Styles.Prices>
                      <Styles.InputPaddingLeft>
                        <Input
                          styling={input_width}
                          placeholder={t('account/billing_info.string20')}
                          type={'text'}
                          name={'custom_vs_amount'}
                          onFocus={() => {
                            setIsChecked('custom_amount');
                            formikProp.setFieldValue('vm_price', 'custom_amount');
                          }}
                          value={formikProp.values['custom_vs_amount']}
                          onChange={formikProp.handleChange}
                          onBlur={formikProp.handleBlur}
                          error={formikProp.errors['custom_vs_amount']}
                          touched={formikProp.touched['custom_vs_amount']}
                          dataTooltip={t('account/billing_info.string38')}
                        />
                        <div
                          id="Add_funds_vm"
                          style={
                            customChecked && formikProp?.values.custom_vs_amount < 10
                              ? { opacity: '0.3', pointerEvents: 'none' }
                              : {}
                          }
                        >
                          <VmPaymentForm
                            addFunds
                            amount={vm_amount(formikProp.values)}
                            paraAddfunds
                            formikProp={formikProp}
                            cardError={() => cardError(formikProp.errors, formikProp.values, 'vm')}
                            creditCardButtonLoading={chargeSavedCardLoading || saveChargeCardLoading}
                            creditCardButtonDisabled={customChecked && formikProp?.values.custom_vs_amount < 10}
                            isButton
                          />
                        </div>
                      </Styles.InputPaddingLeft>
                    </Styles.MidContent>
                  </ConditionalFragment>
                </Styles.Paragraphs>
              </Styles.Accounts>
            </Styles.AccountSC>
          )}
        </Formik>
      )}

      <ConditionalFragment condition={rights.billingviewcc !== 0}>
        <CreditCard
          rights={rights}
          storedCreditCards={storedCreditCards}
          refetchStoredCreditCards={refetchStoredCreditCards}
        />
      </ConditionalFragment>
    </>
  );
};
export default Account;
