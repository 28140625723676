import { h4Styles, h5Styles, pStyles } from 'components/reusable-css';
import styled from 'styled-components';
import { variables } from 'variables';

export const ChangelogSC = styled.div`
  padding: ${variables.pageContentPadding}px;
  background: ${(props) => props.theme.pageBackgroundColor};
  padding-right: 25px;
  width: 100%;
  min-height: calc(100vh - ${variables.headerHeight + variables.pageContentPadding * 2}px);
`;
export const Changelog = styled.div`
  display: flex;
  @media only screen and (max-width: 959px) {
    flex-direction: column;
  }
`;
export const MobileChangelog = styled.div`
  display: flex;
  @media only screen and (max-width: 959px) {
    flex-direction: column;
  }
`;
export const ChangelogMobile = styled.div`
  display: flex;
`;
export const Div1 = styled.div`
  width: 22em;
  @media only screen and (max-width: 959px) {
    margin-bottom: 20px;
  }
`;
export const Div3 = styled.div`
  width: 100%;
  @media only screen and (max-width: 959px) {
    margin-bottom: 30px;
  }
`;

export const VersionSC = styled.div`
  background-color: ${(props) => props.theme.primaryDarkColor};
  padding: 15px 20px;
  border-radius: 5px;
  position: relative;
  @media only screen and (min-width: 960px) {
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid ${(props) => props.theme.primaryDarkColor};
      position: absolute;
      top: 20px;
      right: -9px;
    }
  }
  @media only screen and (max-width: 959px) {
    margin-left: 35px;
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid ${(props) => props.theme.primaryDarkColor};
      position: absolute;
      top: 20px;
      left: -10px;
    }
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;

export const VersionName = styled.div`
  padding-bottom: 5px;
  h5 {
    font-weight: 550;
    margin: 0;
  }
`;

export const VersionDate = styled.div`
  h6 {
    margin: 0;
  }
`;

export const Line = styled.div`
  position: relative;
  margin-left: 35px;
  width: 2px;
  min-height: 100%;
  background: #bfbfbf;
  color: #ff7300;

  .line-background {
    position: absolute;
    font-size: 18px;
    font-weight: 700;
    top: 21px;
    right: -7px;
  }
  @media only screen and (max-width: 959px) {
    margin-left: 20px;
  }
`;

export const DetailSC = styled.div`
  background: ${(props) => props.theme.lightShadeBackgroundColor};
  padding: 15px 20px;
  padding-right: 15px;
  padding-top: 5px;
  margin-left: 35px;
  margin-bottom: 30px;
  border-radius: 5px;
  position: relative;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #f1f1f1;
    position: absolute;
    top: 20px;
    left: -10px;
  }
`;

export const DetailFlex = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
`;
export const DetailName = styled.div`
  padding: 10px 0;
  h6 {
    font-weight: bold;
  }
`;

export const H4 = styled.h4`
  font-size: ${(props) => props.theme.default?.h4};
  ${(props) => h4Styles(props.theme.resolutions)};
`;

export const H5 = styled.h5`
  font-size: ${(props) => props.theme.default?.h5};
  ${(props) => h5Styles(props.theme.resolutions)};
`;

export const P = styled.p`
  font-size: ${(props) => props.theme.default?.p};
  line-height: 20px;
  color: black;
  ${(props) => pStyles(props.theme.resolutions)};
`;

export const Circle = styled.div`
  margin-right: 8px;
  margin-top: -2.5px;
  i {
    font-size: 8px;
  }
`;
export const DetailText = styled.div`
  display: flex;
  margin-bottom: 15px;
`;
