import React from 'react';
import * as Styles from 'pages/member/user-management/UserManagement.style';
import { Icon } from 'semantic-ui-react';
import { H2 } from 'components/reusable-css';
const Heading = ({ children }) => {
  return (
    <Styles.Heading>
      <Icon name="angle right" className="mb-2" />
      <H2>{children}</H2>
    </Styles.Heading>
  );
};

export { Heading };