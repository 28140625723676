/* eslint-disable no-unused-vars */
import React from 'react';
import { PassSC, Pass, ChangePasswords, FormDiv } from './PersonalContactInfo.style';
import { Highlight } from 'components/vm-deployment/components';
import { Trans, useTranslation } from 'react-i18next';
import classes from 'assets/css/payment-form.module.css';
import { cityData, nameData, phoneData } from 'constants/account-profile';
import Input from 'components/input';
import Select from 'components/select';
import { Grid } from '@material-ui/core';
import CustomButton from 'components/common/custom-button';
import { Form } from 'formik';
import 'components/input/input.css';
import { useMediaQuery } from '@material-ui/core';
import ConditionalFragment from 'components/conditional-fragment';
import { Label } from 'assets/css/common-styles';
import { Heading } from 'components/user-management/Heading';

const PersonalContactForm = ({ formikProp, editProfileLoad, width, rights, countries }) => {
  const { t } = useTranslation();
  const { values, handleChange, handleBlur, errors, touched, isSubmitting, isValid, dirty } = formikProp;
  const multiTextbox = useMediaQuery('(max-width:450px)');
  const commonWidth = {
    width: multiTextbox ? '95%' : '22rem',
    paddingRight: multiTextbox ? '0rem' : '0.5rem',
  };

  return (
    <Form>
      <PassSC>
        <Pass>
          <Heading>
            <Trans i18nKey="account/profile.string27" components={{ highlight: <Highlight /> }} />
          </Heading>
          <ChangePasswords>
            <FormDiv>
              <Grid className={classes.main} container spacing={0}>
                <Grid xs={12} sm={12} md={3} lg={3} xl={2}>
                  <Label> {t('account/profile.string28')}</Label>
                </Grid>
                <Grid item container xs={12} sm md lg xl>
                  {nameData.map((item) => (
                    <Input
                      type={item.type}
                      key={item.name}
                      styling={commonWidth}
                      name={item.name}
                      value={values[item.name]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors[item.name]}
                      touched={touched[item.name]}
                      placeholder={t(item.placeholder_lang)}
                      dataTooltip={t(item.dataTooltip)}
                    />
                  ))}
                </Grid>
              </Grid>
              <Grid className={classes.main} container spacing={0}>
                <Grid xs={12} sm={12} md={3} lg={3} xl={2}>
                  <Label>{t('account/profile.string31')}</Label>
                </Grid>
                <Grid xs={12} sm={10} md={6} lg={5} xl={3}>
                  <Input
                    className="custom_inputs"
                    type={'text'}
                    styling={commonWidth}
                    name={'organization'}
                    value={values['organization']}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors['organization']}
                    touched={touched['organization']}
                    placeholder={t('account/profile.string32')}
                    dataTooltip={t('account/profile.string36')}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.main} container spacing={0}>
                <Grid xs={12} sm={12} md={3} lg={3} xl={2}>
                  <Label>{t('account/profile.string33')}</Label>
                </Grid>
                <Grid style={{ position: 'relative' }} item container xs={12} sm md lg xl>
                  <Input
                    styling={commonWidth}
                    className="custom_inputs"
                    type={'text'}
                    name={'address_first_line'}
                    value={values['address_first_line']}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors['address_first_line']}
                    touched={touched['address_first_line']}
                    placeholder={t('account/profile.string34')}
                    dataTooltip={t('account/profile.string36')}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.main} container spacing={0}>
                <Grid xs={12} sm={12} md={3} lg={3} xl={2}>
                  {/* <h1 className={classes.label}>{t('account_profile.address')}</h1> */}
                </Grid>
                <Grid item container xs={12} sm md lg xl>
                  <Input
                    type={'text'}
                    styling={commonWidth}
                    name={'address_second_line'}
                    className="custom_inputs"
                    value={values['address_second_line']}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors['address_second_line']}
                    touched={touched['address_second_line']}
                    placeholder={t('account/profile.string35')}
                    dataTooltip={t('account/profile.string36')}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.main} container spacing={0}>
                <>
                  <Grid xs={12} sm={12} md={3} lg={3} xl={2}>
                    {/* <h1 className={classes.label}>{t('account_profile.address')}</h1> */}
                  </Grid>
                  <Grid item container xs={12} sm md lg xl>
                    {cityData.map((item) => (
                      <Input
                        styling={{
                          width: multiTextbox ? '95%' : item.name === 'city' ? '22rem' : '11rem',
                          paddingRight: multiTextbox ? '0rem' : '0.5rem',
                        }}
                        type={'text'}
                        key={item.name}
                        name={item.name}
                        value={values[item.name]}
                        className="custom_inputs"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors[item.name]}
                        touched={touched[item.name]}
                        placeholder={t(item.placeholder_lang)}
                        dataTooltip={t(item.dataTooltip)}
                      />
                    ))}
                  </Grid>
                </>
              </Grid>

              <Grid className={classes.main} container spacing={0}>
                <Grid xs={12} sm={3} lg={3} xl={2}>
                  <Label>{''}</Label>
                </Grid>
                <Grid xs={12} sm={10} md={6} lg={5} xl={3}>
                  <Select
                    styling={width}
                    options={countries}
                    name={'country'}
                    value={values['country']}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors['country']}
                    touched={touched['country']}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.main} container spacing={0}>
                <Grid xs={12} sm={12} md={3} lg={3} xl={2}>
                  <Label>{t('account/profile.string43')}</Label>
                </Grid>
                <Grid style={{ position: 'relative' }} item container xs={12} sm md lg xl>
                  {phoneData.map((item) => (
                    <Input
                      type={item.type}
                      className="custom_inputs"
                      key={item.name}
                      styling={{
                        width: multiTextbox ? '95%' : '14.7rem',
                        paddingRight: multiTextbox ? '0rem' : '0.5rem',
                      }}
                      inputProps={{
                        maxlength: 15,
                      }}
                      name={item.name}
                      value={values[item.name]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors[item.name]}
                      touched={touched[item.name]}
                      placeholder={t(item.placeholder_lang)}
                      dataTooltip={t(item.dataTooltip)}
                      tooltipStyles={{
                        leftPosition: '-17.5em',
                        width: '20em',
                        right: 0,
                      }}
                    />
                  ))}
                </Grid>
              </Grid>
            </FormDiv>
            <ConditionalFragment condition={rights?.profilemodify}>
              <CustomButton
                disabled={!isValid || !dirty}
                type="submit"
                buttonStyles={{ padding: ' 9px 55px' }}
                isLoading={isSubmitting || editProfileLoad}
              />
            </ConditionalFragment>
          </ChangePasswords>
        </Pass>
      </PassSC>
    </Form>
  );
};
export { PersonalContactForm };
