import CustomButton from 'components/common/custom-button';
import React, { useState } from 'react';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getCountry } from 'components/manage-vm/utils';
import DialogBox from 'components/dialog';
import { H5, H6, P } from 'components/reusable-css';

const FilterCard = ({ regionsData, osData, allTags }) => {
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState(false);
  const handleOpen = () => setOpenFilter(true);
  const handleClose = () => setOpenFilter(false);

  return (
    <Styles.Cardss>
      <CustomButton
        onClick={handleOpen}
        title={t('vs/instance.string3')}
        buttonStyles={{
          background: 'white',
          border: '1px solid #b4b4b4',
          color: 'grey',
          padding: '0.4rem 1rem',
        }}
      />
      <DialogBox width="sm" open={openFilter} onClose={handleClose}>
        <Styles.FiltersCards>
          <Icon name="close" onClick={handleClose} />
          <Styles.FilterContent>
            <H5>{t('vs/instance.string3')}</H5>
            <Styles.GreyBorder />
            <Styles.Flex>
              <H6>{t('vs/instance.string4')}</H6>
              <H6 style={{ color: '#FF7300', cursor: 'pointer' }}>{t('vs/instance.string5')}</H6>
            </Styles.Flex>
            <Styles.Select>
              {allTags.map((item, index) => (
                <Styles.CountryTag key={index}>
                  <P>{item.tag}</P>
                </Styles.CountryTag>
              ))}
            </Styles.Select>
            <Styles.Flex>
              <H6>{t('vs/instance.string6')}</H6>
            </Styles.Flex>
            <Styles.SelectTag>
              {regionsData?.regions?.map((region) => (
                <Styles.CountryTag key={region.id}>
                  <div>
                    <img src={getCountry(region?.country)} alt="flag Image" />
                  </div>
                  <Styles.CountryHeading>
                    <H6>
                      {region.city}, {region.state} ({region.id.toUpperCase()})
                    </H6>
                    <P>
                      {region.region}, {region.country}
                    </P>
                  </Styles.CountryHeading>
                </Styles.CountryTag>
              ))}
            </Styles.SelectTag>
            <Styles.Flex>
              <H6>{t('vs/instance.string7')}</H6>
            </Styles.Flex>
            <Styles.SelectTag>
              {osData?.vs_os?.map((os, i) =>
                os.class == 'linux' ? (
                  <Styles.CountryTag key={i}>
                    <i className="fa fa-linux " />
                    <P>{os.name}</P>
                  </Styles.CountryTag>
                ) : os.class == 'windows' ? (
                  <Styles.CountryTag>
                    <i className="fa fa-windows" />
                    <P>{os.name}</P>
                  </Styles.CountryTag>
                ) : null,
              )}
            </Styles.SelectTag>

            <Styles.Flex>
              <H6>{t('vs/instance.string8')}</H6>
            </Styles.Flex>
            <Styles.StatusButtons>
              <Styles.Select>
                <Styles.CountryTag>
                  <P>{t('vs/instance.string9')}</P>
                </Styles.CountryTag>
              </Styles.Select>
              <Styles.Select>
                <Styles.CountryTag>
                  <P>{t('vs/instance.string10')}</P>
                </Styles.CountryTag>
              </Styles.Select>
            </Styles.StatusButtons>
            <CustomButton onClick={handleClose} title={t('vs/instance.string11')} buttonStyles={{ float: 'right' }} />
          </Styles.FilterContent>
        </Styles.FiltersCards>
      </DialogBox>
    </Styles.Cardss>
  );
};
export { FilterCard };
