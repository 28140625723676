import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const ADD_ROLES = async (e) => {
  const res = await axios.post(BASE_URL + '/account/usermgmt/roles', e);
  return res.data;
};

export const GET_ROLES = async () => {
  const res = await axios.get(BASE_URL + '/account/usermgmt/roles');
  return res.data;
};

export const EDIT_ROLE = async (e) => {
  const res = await axios.patch(BASE_URL + `/account/usermgmt/roles/${e.id}`, e);
  return res.data;
};

export const DELETE_ROLE = async (e) => {
  const res = await axios.delete(BASE_URL + `/account/usermgmt/roles/${e.id}`, e);
  return res.data;
};

export const LABELS = async () => {
  const res = await axios.get(BASE_URL + `/account/usermgmt/rolecodes`);
  return res.data;
};

export const GET_USERS = async () => {
  const res = await axios.get(BASE_URL + '/account/usermgmt/users');
  return res.data;
};

export const ADD_USER = async (e) => {
  const res = await axios.post(BASE_URL + '/account/usermgmt/users', e);
  return res.data;
};

export const DELETE_USER = async (e) => {
  const res = await axios.delete(BASE_URL + `/account/usermgmt/users/${e.id}`, e);
  return res.data;
};

export const EDIT_USER = async (e) => {
  const res = await axios.patch(BASE_URL + `/account/usermgmt/users/${e.id}`, { ...e, id: undefined });
  return res.data;
};

export const UNAUTHORIZE_USER = async () => {
  try {
    const res = await axios.post(BASE_URL + `/unauthenticate`);
    return res.data;
  } catch (error) {
    console.log('error::', error);
  }
};

export const DRY_RUN_USER = async (e) => {
  const res = await axios.post(BASE_URL + '/account/usermgmt/users', e);
  return res.data;
};
