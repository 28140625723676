import React from 'react';
import {
  modifyValuesProfileSubmit,
  personalInfoInitialValues,
  personalInfoValidationSchema,
} from 'constants/account-profile';
import { PersonalContactForm } from './PersonalContactForm';
import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { EDIT_PROFILE } from '../queries';
import { CustomHook } from 'utils/custom-hook';

const PersonalContactInfo = ({ width, rights }) => {
  const { data: personalInfo, refetch } = useQuery('/account/profile/personal_contact');
  const { mutate: editPersonalInfo, isLoading: editProfileLoad } = useMutation(EDIT_PROFILE);
  const { mutationProps, getCountries } = CustomHook();
  const countries = getCountries();

  return (
    <Formik
      initialValues={personalInfoInitialValues(personalInfo, { country: countries[0]?.value })}
      validationSchema={personalInfoValidationSchema}
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        editPersonalInfo(
          modifyValuesProfileSubmit(values),
          mutationProps(resetForm, {
            onSuccess: async () => {
              await refetch();
            },
          }),
        );
      }}
    >
      {(formikProp) => (
        <PersonalContactForm
          rights={rights}
          width={width}
          formikProp={formikProp}
          editProfileLoad={editProfileLoad}
          countries={countries}
        />
      )}
    </Formik>
  );
};
export default PersonalContactInfo;