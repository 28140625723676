export default (amount, isCurrency = true, isCommas = false) => {
  const currencyType = '$';
  let fixedPrice;
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  if (amount) {
    fixedPrice = Number(amount).toLocaleString('en', options);
  } else {
    fixedPrice = 0;
  }
  if (isCommas && fixedPrice) {
    fixedPrice = fixedPrice.split && fixedPrice.split(',').join('');
  }
  if (isCurrency) {
    return `${currencyType}${fixedPrice}`;
  } else {
    return `${fixedPrice}`;
  }
};
