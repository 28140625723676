import React from 'react';
import { Grid } from '@material-ui/core';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import { get } from 'lodash';
import { ReverseForm } from './components';
import { useQuery } from 'react-query';
import Loading from 'components/common/loading';
import hooks from 'hooks';
import { P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const ReverseDNS = ({ data, type }) => {
  const { t } = useTranslation();
  const { refreshTime } = hooks();
  const {
    data: networkData,
    isLoading: networkLoading,
    refetch: networkDataRefetch,
    isSuccess: networkSuccess,
  } = useQuery(`/${type}/instance/${data.id}/ipv4`, {
    refetchInterval: refreshTime,
  });
  const checkIpQuantity = (quantity) => {
    return get(networkData, `${type}_ipv4[0].networks`, []).some((network) =>
      network.eth0?.some((eth) => eth.ipv4?.some((item) => item.ip).length === quantity),
    );
  };

  if (networkLoading) {
    return <Loading />;
  }
  return (
    <Styles.ReverseSC>
      {!checkIpQuantity(0) && <P>{t(type === 'vs' ? `vs/instance.string129` : `bm/instance.string64`)}</P>}
      <Styles.ReverseDNS>
        <Grid container>
          <Grid xs={12} sm={12} md={5} lg={4} xl={2}>
            <Styles.DNS>{t(type === 'vs' ? `vs/instance.string130` : `bm/instance.string65`)}</Styles.DNS>
          </Grid>
          <Grid>
            <Styles.Spacing />
          </Grid>
          <Grid>
            <Styles.DNS>{t('vs/instance.string131')}</Styles.DNS>
          </Grid>
        </Grid>
        {checkIpQuantity(0) ? (
          <Styles.PublicIPPara className="mt-2">{t('vs/instance.string132')}</Styles.PublicIPPara>
        ) : (
          <>
            {get(networkData, `${type}_ipv4[0].networks`, []).map((network) => {
              return network.eth0?.map((eth) => {
                return eth.ipv4?.map((ip, index) => (
                  <ReverseForm
                    key={index}
                    ip={ip}
                    data={data}
                    networkDataRefetch={networkDataRefetch}
                    networkSuccess={networkSuccess}
                    type={type}
                  />
                ));
              });
            })}
          </>
        )}
      </Styles.ReverseDNS>
    </Styles.ReverseSC>
  );
};

export default React.memo(ReverseDNS);
