import React, { useState } from 'react';
import * as Styles from './DomainStep1.style';
import { useTranslation } from 'react-i18next';
import { H5, P } from 'components/reusable-css';
import { Formik } from 'formik';
import {
  initialValues,
  transferInitialValues,
  transferValidationSchema,
  validationSchema,
} from 'components/doamain/utils';
import { useMutation } from 'react-query';
import { DOMAIN_LOOKUP } from 'pages/member/wh/queries';
import { CustomHook } from 'utils/custom-hook';
import TransferDomain from 'components/doamain/TransferDomain';
import NewDomain from 'components/doamain/NewDomain';
import Heading from 'components/Heading/Heading';
import Checkbox from 'components/common/radio-button/RadioButton';

const checkBtn = [
  { id: 1, text: 'register_new_domain' },
  { id: 0, text: 'transfer_existing_domain' },
];

const DomainStep1 = ({ domainList, transferPossible, setTransferPossible, isNewDomain, setIsNewDomain }) => {
  const { t } = useTranslation();
  const { mutationProps } = CustomHook();
  const [price, setPrice] = useState('');
  const { mutate: transfer, isLoading: transferLoading, isSuccess: transferSuccess } = useMutation(DOMAIN_LOOKUP);
  const { mutate, isLoading, isSuccess } = useMutation(DOMAIN_LOOKUP);

  const handleNewDomain = (values) => {
    mutate(
      { domain: values.new_domain },
      mutationProps(() => {}, {
        isSuccess: false,
        onSuccess: async (e) => {
          setPrice(e.price);
        },
      }),
    );
  };

  const handleTransferDomain = (values) => {
    setTransferPossible(404);
    transfer(
      { domain: values.transfer_domain },
      {
        onError: (e) => {
          if (e.response.data.status == 'domain_not_available') {
            const { transfer_possible, price } = e.response.data;
            setTransferPossible(transfer_possible);
            setPrice(price);
          }
        },
      },
    );
  };

  const handleChange = (value) => setIsNewDomain(value.id);

  return (
    <Styles.DomainStep1SC>
      <Styles.SupportedDomain>
        <div className="paragraph">
          <P>{t('supported_domain')}</P>
        </div>
        <Styles.SupportedDomainRow>
          {domainList?.prices_domain_extensions?.map((val) => (
            <Styles.SupportedDomainContainer key={val.id}>
              <P>.{val.id}</P>
            </Styles.SupportedDomainContainer>
          ))}
        </Styles.SupportedDomainRow>
      </Styles.SupportedDomain>
      <Heading name={`new_domain.step1.heading`}>
        <Styles.Step>
          {checkBtn.map((data, i) => (
            <Styles.Card selected={data.id == isNewDomain} key={i} onClick={() => handleChange(data)}>
              <Checkbox
                checkbox={isNewDomain}
                setCheckedValue={(value) => setIsNewDomain(value)}
                data={data}
                onClick={() => handleChange(data)}
              />
              <H5>{t(`${data.text}`)}</H5>
            </Styles.Card>
          ))}
        </Styles.Step>
        <Styles.Line isNewDomain={isNewDomain}>
          <div className="line" />
          <div className="doubleline" />
        </Styles.Line>

        {isNewDomain == 1 ? (
          <>
            <Styles.StepDescription>{t('new_domain.step1.description')}</Styles.StepDescription>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleNewDomain(values)}
            >
              {(formikProp) => (
                <NewDomain price={price} formikProp={formikProp} isLoading={isLoading} isSuccess={isSuccess} />
              )}
            </Formik>{' '}
          </>
        ) : (
          <>
            <Styles.StepDescription>{t('transfer_domain.step1.description')}</Styles.StepDescription>
            <Formik
              initialValues={transferInitialValues}
              validationSchema={transferValidationSchema}
              onSubmit={(values) => handleTransferDomain(values)}
            >
              {(formikProp) => (
                <TransferDomain
                  price={price}
                  formikProp={formikProp}
                  isLoading={transferLoading}
                  isSuccess={transferSuccess}
                  transferPossible={transferPossible}
                />
              )}
            </Formik>{' '}
          </>
        )}
      </Heading>
    </Styles.DomainStep1SC>
  );
};

export default DomainStep1;
