import React, { useState } from 'react';
import Select from 'components/select';
import { ipOptions } from 'components/manage-vm/utils';
import * as Styles from 'components/manage-vm/settings-panel/SettingsPanel.style';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { ADD_SERVER_IP } from 'components/manage-vm/queries';
import CustomButton from 'components/common/custom-button';
import { useMutation } from 'react-query';
import hooks from 'hooks';
import ErrorMessage from 'components/error-message';
import ConditionalFragment from 'components/conditional-fragment';
import { P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const AddIP = ({ howManyIps, data, ipRefetch, network, type, managerVmRefetch }) => {
  const { t } = useTranslation();
  const { mutationProps } = hooks();
  const { mutate: addVmIp, isLoading: addIpLoading } = useMutation(ADD_SERVER_IP, {
    ...mutationProps('', {
      onSuccess: () => {
        ipRefetch();
        setSelectedValueIp(ipOptions(howManyIps).value || '1');
        managerVmRefetch();
      },
    }),
  });
  const match = useMediaQuery('(min-width:960px)');
  const match1 = useMediaQuery('(max-width:959px)');
  const [selectedValueIp, setSelectedValueIp] = useState(ipOptions(howManyIps).value || '1');
  let buttonStyle = match
    ? { marginTop: '2px', marginLeft: '5px', padding: '13px 10px' }
    : match1
    ? { marginTop: '4px', marginLeft: '0px', padding: '3px 10px' }
    : null;
  return (
    <Styles.AddingIP>
      <ConditionalFragment condition={network.can_add_ipv4_ips == 0}>
        <div className="d-flex justify-content-start">
          <ErrorMessage
            text={type === 'bm' ? 'bm/instance.string62' : 'vs/instance.string125'}
            textStyles={{ fontSize: 14 }}
          />
        </div>
      </ConditionalFragment>
      <ConditionalFragment condition={network.can_add_ipv4_ips != 0}>
        {ipOptions(howManyIps).map((ipOption, index) => (
          <div key={index} className="d-flex flex-wrap">
            <Select
              style={{ marginTop: '0px', width: '100%', maxWidth: 200 }}
              value={selectedValueIp}
              options={ipOption.options}
              name={ipOption.text}
              onChange={(e) => setSelectedValueIp(e.target.value)}
            />
            <CustomButton
              title={'vs/instance.string172'}
              buttonStyles={buttonStyle}
              onClick={() => addVmIp({ id: data.id, qty: selectedValueIp })}
              isLoading={addIpLoading}
            />
          </div>
        ))}
      </ConditionalFragment>
      <P className="mt-3">{type === 'bm' ? t('bm/instance.string61') : t('vs/instance.string124')}</P>
    </Styles.AddingIP>
  );
};
export { AddIP };
AddIP.propTypes = {
  data: PropTypes.any,
  howManyIps: PropTypes.string,
  ipRefetch: PropTypes.func,
  network: PropTypes.any,
};
