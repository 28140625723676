/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Loading from 'components/common/loading/Loading';
import { forIn, get } from 'lodash';
import { useQuery } from 'react-query';
import IssuesStep2 from './IssuesStep2';
import CustomButton from 'components/common/custom-button';
import { scrollToSection } from 'utils';
import * as Styles from 'components/issues/Issues.style';

const Step2Container = ({ formikProps, newTicketLoading }) => {
  const { values, submitForm, setFieldValue, setSubmitting, isSubmitting, isValid, errors: formIkErrors } = formikProps;
  const [fields, setFields] = useState({});

  const { data, isLoading, isError, error } = useQuery(values.step1_next_step || `/tickets/${values.step1}/step2`, {
    onSuccess() {
      setFieldValue('step2', {});
      setTimeout(() => scrollToSection('ST2'), 200);
    },
  });

  let subject = get(fields, 'subject', '');
  let fullname = get(fields, 'fullname', '');
  let reply = get(fields, 'message', '');
  let regex = /^[a-zA-Z0-9 ]{5,}$/;

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <p>{get(error, 'response.data.error', 'Ticket not found')}</p>;
  }

  return (
    <div id="ST2">
      <IssuesStep2 data={data} formikProps={formikProps} fields={fields} setFields={setFields} />
      <Styles.FlexContainer
        alignItems="flex-end"
        justifyContent="flex-end"
        fontWeight="normal"
        maxWidth="835px"
        width="100%"
      >
        <CustomButton
          onClick={() => {
            setSubmitting(true);
            forIn(fields, (value, key) => {
              setFieldValue(`step2.${key}`, value);
            });

            if (!isValid && formIkErrors?.step2?.pin) {
              setSubmitting(false);
              scrollToSection('step2-pin');
            }
            submitForm();
            setSubmitting(false);
          }}
          disabled={!regex.test(subject) || !reply || !regex.test(fullname)}
          isLoading={newTicketLoading || isSubmitting}
          type="submit"
          title="OPEN THIS TICKET WITH OUR STAFF"
          buttonStyles={{ margin: '30px 20px' }}
        />
      </Styles.FlexContainer>
    </div>
  );
};
export default React.memo(Step2Container);
