/* eslint-disable no-unused-vars */
import React from 'react';
import CPanel from 'components/vm-deployment/cPanel-card';
import cPanelImage from 'assets/images/cpanel.svg';
import { useTranslation, Trans } from 'react-i18next';
import { Highlight, StepLabel } from 'components/vm-deployment/components';
import { NewBMStep3SC, StepDescription, Image, Description, Paragraph } from './NewBMStep3.style';
import { useDispatch, useSelector } from 'react-redux';
import { updateBMCPanelOs } from 'store/actions/bm-deployment';
import Heading from 'components/Heading/Heading';

const NewBMStep3 = ({ withPricing, formikProp }) => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.account);
  const dispatch = useDispatch();
  const updatePrice = (item) => {
    dispatch(updateBMCPanelOs(item.requiredos));
  };
  const updateState = () => {
    dispatch(updateBMCPanelOs(''));
  };
  return (
    <NewBMStep3SC id="ST3">
      {/* <StepLabel id="ST3" stepCount={t('bm/instance/create.step3_string1')}>
        <Trans i18nKey="bm/instance/create.step3_string2" components={{ highlight: <Highlight /> }} />
      </StepLabel> */}
      <Heading name={'bm/instance/create.step3_string2'}>
        {withPricing.map((item) => (
          <React.Fragment key={item.id}>
            <StepDescription>
              <Image>
                <img src={cPanelImage} alt="cpanel logo" width="90%" />
              </Image>
              <Description>
                <Paragraph>
                  <Trans
                    i18nKey="bm/instance/create.step3_string3"
                    values={{
                      price: item.price,
                      currency: userAccount.account_currency,
                      currency_symbol: userAccount.account_currency_symbol,
                    }}
                  >
                    <a href={item.infopage} rel="noreferrer" target="_blank" />
                    <span style={{ textTransform: 'uppercase' }} />
                  </Trans>
                </Paragraph>
              </Description>
            </StepDescription>
            <CPanel
              type={'bm'}
              item={item}
              updateState={updateState}
              formikProp={formikProp}
              updatePrice={updatePrice}
            />
          </React.Fragment>
        ))}
      </Heading>
    </NewBMStep3SC>
  );
};
export default NewBMStep3;
