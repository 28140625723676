/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-27 17:57:52
 * @modify date 2021-01-27 17:57:54
 */

import lightTheme from 'themes/light-theme';
import darkTheme from 'themes/dark-theme';

// THEMES
export const THEME_LIGHT = 'LIGHT';
export const THEME_DARK = 'DARK';

export function getTheme(themeName) {
  switch (themeName) {
    case THEME_DARK:
      return darkTheme;
    default:
      return lightTheme;
  }
}

export function getDefaultTheme() {
  return THEME_LIGHT;
}

export function toggleTheme(currentTheme) {
  switch (currentTheme) {
    case THEME_LIGHT:
      return THEME_DARK;
    default:
      return THEME_LIGHT;
  }
}
