import React from 'react';
import * as Styles from './UserManagement.style';
import { Highlight } from 'components/vm-deployment/components';
import { AddUser, CurrentUser, Heading } from 'components/user-management';
import { Trans, useTranslation } from 'react-i18next';
import { P } from 'components/reusable-css';

const AddNewUser = ({ getUsers, usersRefetch, getRoles, roleRefetch }) => {
  const { t } = useTranslation();
  return (
    <Styles.AddNewUserSC>
      <Styles.AddNewUser>
        <Heading>
          <Trans i18nKey="account/usermgmt.string28" components={{ highlight: <Highlight /> }} />
        </Heading>
        <Styles.AddUsers>
          <P>{t('account/usermgmt.string29')}</P>
          <AddUser roleRefetch={roleRefetch} getRoles={getRoles} usersRefetch={usersRefetch} />
        </Styles.AddUsers>
      </Styles.AddNewUser>
      {getUsers && (
        <Styles.CurrentUser>
          <Heading>
            <Trans i18nKey="account/usermgmt.string30" components={{ highlight: <Highlight /> }} />
          </Heading>
          <Styles.CurrentUsers>
            <Styles.Cards>
              <CurrentUser roleRefetch={roleRefetch} data={getUsers} usersRefetch={usersRefetch} getRoles={getRoles} />
            </Styles.Cards>
          </Styles.CurrentUsers>
        </Styles.CurrentUser>
      )}
    </Styles.AddNewUserSC>
  );
};

export default AddNewUser;
