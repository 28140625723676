import React from 'react';
import * as Styles from '../styles/ForgotPassword.style';
import { Trans, useTranslation } from 'react-i18next';
import LogoImage from 'assets/images/serverpoint.svg';
import Input from 'components/input';
import CustomButton from 'components/common/custom-button';
import { Formik, Form } from 'formik';
import { initialValues, passResetValidation } from 'constants/signUp';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { PASSWORD_RESET } from '../queries';
import { CustomHook } from 'utils/custom-hook';
import { H6 } from 'components/reusable-css';

const ForgotPasswordSet = (props) => {
  const { t } = useTranslation();
  const { mutationProps } = CustomHook();
  let { location } = props;
  let value = location.search.split('=');
  let token = value[1];
  const { mutate: passwordReset, isLoading: passResetLoading } = useMutation(PASSWORD_RESET);
  const handleSubmit = async (values) => {
    await passwordReset({ token: token, password: btoa(values.new_password) }, mutationProps());
  };
  return (
    <Styles.BgLogin>
      <Styles.LoginContainer>
        <Styles.LoginBox>
          <Styles.Logo>
            <img src={LogoImage} alt="serverpoint" width="100%" />
          </Styles.Logo>
          <Styles.LoginForm>
            <H6>{t('forgot-password-set.string1')} </H6>
            <Formik
              initialValues={initialValues}
              validationSchema={passResetValidation}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ values, handleChange, handleBlur, errors, touched }) => (
                <Form>
                  <Input
                    type={'password'}
                    name={'new_password'}
                    value={values['new_password']}
                    placeholder={t('forgot-password-set.string2')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors['new_password']}
                    touched={touched['new_password']}
                  />
                  <Input
                    type={'password'}
                    name={'confirm_password'}
                    value={values['confirm_password']}
                    placeholder={t('forgot-password-set.string3')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors['confirm_password']}
                    touched={touched['confirm_password']}
                  />
                  <Styles.Paragraph>
                    <Trans i18nKey={'forgot-password-set.string4'}>
                      <Link to="/" />
                    </Trans>
                  </Styles.Paragraph>
                  <div className="mt-4 cursor-pointer position-relative float-right">
                    <div className="position-absolute icon">
                      <i className="fa fa-key" />
                    </div>
                    <CustomButton
                      type="submit"
                      isLoading={passResetLoading}
                      buttonStyles={{ paddingLeft: '2.5rem' }}
                      title={t('forgot-password-set.string5')}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </Styles.LoginForm>
        </Styles.LoginBox>
      </Styles.LoginContainer>
    </Styles.BgLogin>
  );
};

export { ForgotPasswordSet };
