import React, { useState } from 'react';
import DomainStep1Container from './new-domain-step-1/DomainStep1.container';
import { NewDomainSC } from './NewDomain.style';
import DomainStep2Container from './new-domain-step-2/DomainStep2.container';
import DomainStep3Container from './new-domain-step-3/DomainStep3.container';
import DomainLastStepContainer from './new-domain-last-step/DomainLastStep.container';

const NewDomain = ({ style, domainList }) => {
  const [transferPossible, setTransferPossible] = useState(404);
  const [isNewDomain, setIsNewDomain] = useState(1);

  return (
    <NewDomainSC style={style}>
      <DomainStep1Container
        domainList={domainList}
        transferPossible={transferPossible}
        setTransferPossible={setTransferPossible}
        isNewDomain={isNewDomain}
        setIsNewDomain={setIsNewDomain}
      />
      {transferPossible == 1 && isNewDomain == 0 && (
        <>
          <DomainStep2Container />
          <DomainStep3Container />
          <DomainLastStepContainer />
        </>
      )}
    </NewDomainSC>
  );
};
export default NewDomain;
