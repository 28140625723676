import React from 'react';
import MicrosoftOfficeImage from 'assets/images/ms-sql-office.svg';
import MicrosoftProductImage from 'assets/images/ms-products.svg';
import { useTranslation, Trans } from 'react-i18next';
import { Highlight } from 'components/vm-deployment/components';
import * as Styles from './NewVMPage.style';

const NewVMPage = () => {
  const { t } = useTranslation();
  return (
    <Styles.NewVMPageSC>
      <Styles.Heading>
        <Trans i18nKey="vs/instance/create.string1" components={{ highlight: <Highlight /> }} />
      </Styles.Heading>
      <Styles.StepDescription>
        <Styles.Image>
          <img src={MicrosoftOfficeImage} alt="Microsoft Office" />
        </Styles.Image>
        <Styles.Description>
          <Styles.Paragraph>{t('vs/instance/create.string2')}</Styles.Paragraph>
          <Styles.Img src={MicrosoftProductImage} alt="microsoft products" />
        </Styles.Description>
      </Styles.StepDescription>
    </Styles.NewVMPageSC>
  );
};
export default NewVMPage;
