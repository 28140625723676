import React from 'react';
import { CheckBox, Input, RememberCheckBox, Icon } from './Checkbox.style';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip';

const Checkbox = ({ remember, id, type, name, title, value, setValue, labelStyle, exclaimation, tooltip }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {remember === true ? (
        <RememberCheckBox>
          <CheckBox>
            <Input
              type={type || 'checkbox'}
              name={name || 'check'}
              id={id || 'check'}
              checked={value}
              value={value}
              onChange={(e) => setValue(e.target.checked)}
            />
          </CheckBox>
          <label htmlFor={id || 'check'} style={{ ...labelStyle, cursor: 'pointer' }}>
            {t(title)}
          </label>
        </RememberCheckBox>
      ) : (
        <div className="d-flex mt-1">
          <CheckBox>
            <Input
              type={type || 'checkbox'}
              name={name || 'check'}
              id={id || 'check'}
              checked={value}
              value={value}
              onChange={(e) => setValue(e.target.checked)}
            />
          </CheckBox>
          <label htmlFor={id || 'check'} style={{ ...labelStyle, cursor: 'pointer' }}>
            {t(title)}
            {exclaimation && (
              <Tooltip title={tooltip} placement="bottom">
                <Icon style={{ fontSize: '17px', padding: '0 5px' }} className=" fa fa-exclamation-circle orange" />
              </Tooltip>
            )}
          </label>
        </div>
      )}
    </React.Fragment>
  );
};

Checkbox.propTypes = {
  id: PropTypes.any,
  type: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  labelStyle: PropTypes.object,
};

export default Checkbox;
