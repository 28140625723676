import styled from 'styled-components';
import BackgroundImage from 'assets/images/signup_bg.jpg';

export const BgLogin = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  font-weight: 400 !important;
  font-size: 16px !important;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginContainer = styled.div`
  padding-top: 5.8em;
  padding-bottom: 7.7em;
`;

export const LoginBox = styled.div`
  background: #fff;
  margin: 0 auto;
  width: 350px;
  box-shadow: 0 0 24px 5px #d1d1d1;
`;

export const LoginForm = styled.div`
  padding: 30px;
  text-align: center;
  h1 {
    font-weight: bolder;
    margin: 0;
  }
  p {
    margin: 1.5em 0;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
  i {
    font-size: 4em;
    color: ${(props) => props.theme.primaryDarkColor};
  }
`;
