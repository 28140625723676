import React, { useEffect, useState } from 'react';
import { Cards, Card, Heading, SubHeading, CardsMobile } from './CPanelCard.style';
import { cPanels } from 'components/vm-deployment/constants/step4-cpanel';
import Checkbox from 'components/common/radio-button';
import { Message } from 'components/alert/Message';
import { useTranslation, Trans } from 'react-i18next';
import VmUtils from 'pages/member/new-vm/util';

export default function CPanel({ item, formikProp, updatePrice, updateState, vsTemplate = [], type }) {
  const [tempId, setTempId] = useState('');
  const { values, setFieldValue } = formikProp;
  const temp = vsTemplate.find((i) => i.id === values.template);
  const { t } = useTranslation();
  const { isSliderInterface } = VmUtils();
  const memory_mb = isSliderInterface ? values?.sliders?.ram : values?.size_object?.memory_mb;
  const disk_mb = isSliderInterface ? values?.sliders?.disk * 1024 : values?.size_object?.disk_mb;

  useEffect(() => {
    if (temp?.license_cpanel == 1) {
      updatePrice(item);
      setTempId(item.id);
      setFieldValue('app', item.id);
      setFieldValue('prices', { ...values.prices, app_price: item?.pricing?.price_monthly_usd || '0' });
    } else {
      setTempId('');
      setFieldValue('app', '');
      setFieldValue('prices', { ...values.prices, app_price: '0' });
      updateState();
    }
  }, [values.template]);

  useEffect(() => {
    setFieldValue('app', '');
  }, [values.size, values.sliders]);

  const handleCardClick = (cPanel) => {
    if (
      (Number(memory_mb) < Number(item?.minimum_ram) || Number(disk_mb) < Number(item?.minimum_disk)) &&
      cPanel?.id != undefined
    ) {
      Message.error(t('alert26'), t('error'));
      return;
    }
    if (temp?.license_cpanel == 1) {
      return Message.error(t('alert8'), t('alert7'));
    }
    if (temp?.license_cpanel == 0) {
      return Message.error(t('alert9'), t('alert7'));
    }
    const elem = document.getElementById(type === 'bm' ? 'ST4' : type === 'vs' ? 'ST5' : null);
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
    if (cPanel.id) {
      setTempId(item.id);
      setFieldValue('app', item.id);
      setFieldValue('prices.app', item?.pricing?.price_monthly_usd || 0);
      updatePrice(item);
    } else {
      setTempId('');
      setFieldValue('app', '');
      setFieldValue('prices.app', 0);
      updateState();
    }
  };

  return (
    <Cards>
      {cPanels(item.id).map((cPanel) => (
        <Card
          isGrey={
            (Number(memory_mb) < Number(item?.minimum_ram) || Number(disk_mb) < Number(item?.minimum_disk)) &&
            cPanel.id != ''
          }
          onClick={() => handleCardClick(cPanel)}
          key={cPanel.id}
        >
          <div className="cards">
            <Checkbox data={cPanel} checkbox={values.app} onClick={() => handleCardClick(cPanel)} />
            <Heading>
              {t(`vs/instance/create.${cPanel.city}`)}
              {cPanel.region && (
                <SubHeading>
                  <Trans i18nKey={`vs/instance/create.${cPanel.region}`} values={{ os: item.osname }} />
                </SubHeading>
              )}
            </Heading>
          </div>
          <CardsMobile selected={tempId == cPanel.id}>
            <Heading>{t(`vs/instance/create.${cPanel.city}`)}</Heading>
          </CardsMobile>
        </Card>
      ))}
    </Cards>
  );
}
