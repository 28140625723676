/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Message } from 'components/alert/Message';
import { CORE, DISK, initialValues, RAM, validationSchema } from 'components/vm-deployment/constants';
import {
  CHARGE_SAVED_CARD,
  SAVE_CHARGE_CARD,
  SUBMIT_SLIDER_VM,
  SUBMIT_VM,
} from 'components/vm-deployment/queries/billing';
import { totalPrice } from 'components/vm-deployment/util';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateVmCreation } from 'store/actions/vm-deployment';
import NewVM from './NewVM';
import hooks from 'hooks';
import { vm_year } from 'utils';
import * as Yup from 'yup';
import { formValidation, paymentMethodValidation, slidersValidate } from 'validations';
import { get } from 'lodash';
import priceCalculator from 'utils/price-calculator';

export default function NewVMContainer({ style }) {
  let formikRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleCreationSuccess, vmCreationQueryChanges, isSliderInterface, defaultCard } = hooks();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const credit_card_info = useSelector((state) => state.user.billing_info);
  const noOfVM = useSelector((state) => state.vmDeployment.noOfVM);
  const amount = useSelector((state) => state.vmDeployment);
  const userAccount = useSelector((state) => state.user.account);
  const userUI = useSelector((state) => state.user.ui);
  const billingRefetch = useSelector((state) => state.user.userBillingAccountRefetch);
  useEffect(() => {
    if (isSuccess) handleCreationSuccess();
  }, [isSuccess]);

  useEffect(() => {
    if (isError?.response?.data?.error) {
      Message.error(t(`${isError.response.data.error}`));
      dispatch(updateVmCreation(false));
    }
  }, [isError?.response?.data?.error]);
  const vmCreation = async (values, data) => {
    try {
      const query = vmCreationQueryChanges(values, data);
      let res;
      if (isSliderInterface) {
        res = await SUBMIT_SLIDER_VM(query);
      } else {
        res = await SUBMIT_VM(query);
      }
      return res;
    } catch (err) {
      dispatch(updateVmCreation(false));
      setIsError(err);
      return { error: true };
    }
  };

  const handleVmCreationSubmit = async (values) => {
    setIsSuccess(false);
    setIsError(false);
    for (let index = 0; index < noOfVM; index++) {
      dispatch(updateVmCreation(true));
      setTimeout(() => {
        dispatch(updateVmCreation(false));
      }, +userUI.blink_manage_vs_menu_seconds * 1000);
      const isChargeCard =
        values.options_prepaid.minimum_payment !== '0' &&
        values.payment_method === 'credit_debit' &&
        credit_card_info.billing_default_credit_card_exp &&
        credit_card_info.billing_default_credit_card_last_four;
      const isNoChangeCard =
        values.options_prepaid.minimum_payment !== '0' &&
        values.payment_method === 'credit_debit' &&
        !credit_card_info.billing_default_credit_card_exp &&
        !credit_card_info.billing_default_credit_card_last_four;

      const price = Number(totalPrice(amount.pricingDetails));
      let amountToCharge =
        values.options_prepaid.id == 0
          ? Number(values.options_prepaid.minimum_payment)
          : Number(values.options_prepaid.price_times_months) * price;
      amountToCharge = +priceCalculator(amountToCharge, false);
      if (isChargeCard || isNoChangeCard) {
        const res = await vmCreation(values, { dryrun: '1' });
        if (res.error) {
          dispatch(updateVmCreation(false));
          return;
        }
      }

      if (values.options_prepaid.minimum_payment === '0') {
        try {
          const res = await vmCreation(values, { dryrun: '0' });
          if (!res.error) {
            setIsSuccess(true);
          }
        } catch (err) {
          setIsError(err);
        }
      }
      if (isChargeCard) {
        try {
          await CHARGE_SAVED_CARD({
            amount: amountToCharge,
            currency: userAccount.account_currency,
          });
          const res = await vmCreation(values, { dryrun: '0' });
          if (!res.error) {
            setIsSuccess(true);
          }
        } catch (err) {
          setIsError(err);
        }
      }
      if (isNoChangeCard) {
        try {
          await SAVE_CHARGE_CARD({
            number: values.card_number,
            expmonth: ('0' + values.card_month).slice(-2),
            expyear: vm_year(values.card_year),
            cvv: values.cvv,
            name: values.card_name,
            phone: values.phone.replace(/\D/g, ''),
            organization: values.company,
            address1: values.billing_address,
            address2: values.address_continue,
            city: values.city,
            state: values.state,
            zipcode: values.zip,
            country: values.country,
            bank: values.bank_name,
            bankphone: values.bank_phone_number.replace(/\D/g, ''),
            currency: userAccount.account_currency,
            amount_to_charge: amountToCharge,
            make_default: '1',
          });
          await billingRefetch();
          const res = await vmCreation(values, { dryrun: '0' });
          if (!res.error) {
            setIsSuccess(true);
          }
        } catch (err) {
          setIsError(err);
        }
      }
    }
  };
  const validation = Yup.lazy((values) => {
    let valid = {};
    // const isNoChangeCard =
    //   values?.options_prepaid?.minimum_payment !== '0' &&
    //   values.payment_method === 'credit_debit' &&
    //   !credit_card_info.billing_default_credit_card_exp &&
    //   !credit_card_info.billing_default_credit_card_last_four;
    const isSlider = isSliderInterface;
    if (!isSlider) {
      if (!values.size) {
        valid = { ...valid, size: Yup.string().required('vs/instance/create.step1_string27') };
      }
    } else {
      if (!values.sliders && !values.sliders[DISK]) {
        valid = { ...valid, [DISK]: slidersValidate[DISK] };
      }
      if (!values.sliders && !values.sliders[RAM]) {
        valid = { ...valid, [RAM]: slidersValidate[RAM] };
      }
      if (!values.sliders && !values.sliders[CORE]) {
        valid = { ...valid, [CORE]: slidersValidate[CORE] };
      }
    }
    if (!values.payment_method && values?.options_prepaid?.minimum_payment !== '0') {
      valid = { ...valid, ...paymentMethodValidation };
    }
    if (get(values, 'payment_method') === 'credit_debit') {
      if (!defaultCard) {
        valid = { ...valid, ...formValidation };
      }
    }
    return validationSchema(valid);
  });

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldTouched('virtual_server_name');
    }
  }, []);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues(userUI)}
      validationSchema={validation}
      // validate={(values) => {
      //   return validate(values, credit_card_info, userAccount.public_vs_interface_type);
      // }}
      onSubmit={(values) => handleVmCreationSubmit(values)}
    >
      {(formikProp) => <NewVM style={style} formikProp={formikProp} />}
    </Formik>
  );
}
