/* eslint-disable no-unused-vars */
import React from 'react';
import { Icon } from 'semantic-ui-react';
import OperatingSystemCard from 'components/vm-deployment/operating-system-card';
import { useTranslation, Trans } from 'react-i18next';
import * as Styles from './NewVMStep3.style';
import Select from 'components/select';
import VmUtils from '../util';
import { useSelector } from 'react-redux';
import ErrorMessage from 'components/error-message/ErrorMessage';
import _get from 'lodash/get';
import { Highlight, Notification, StepLabel, StepLabelMobile } from 'components/common';
import { StepDescription } from 'assets/css/common-styles';
import Heading from 'components/Heading/Heading';

const NewVMStep3 = ({ OsData, formikProp, updatePrice, templateError, vsTemplate, serverSizes, setValues }) => {
  const { values, setFieldValue, errors, touched } = formikProp;
  const { t } = useTranslation();
  const { useFullTemplateData, isSliderInterface } = VmUtils();
  const vmState = useSelector((state) => state.vmDeployment.vm_deploy.server_size);

  const handleTemplateChange = (event) => {
    const osId = event.target.value;
    const findTemplate = _get(vsTemplate, 'vs_saved_templates', []).find((item) => item.id == osId);
    const { size_in_gb } = findTemplate || {};
    if (!isSliderInterface) {
      if (+size_in_gb > +vmState?.disk_gb) {
        const firstServer = serverSizes.find((item) => +size_in_gb < +item.disk_gb);
        setFieldValue('template', osId);
        return setValues(firstServer);
      }
    }
    updatePrice(osId);
    setFieldValue('template', osId);
  };

  return (
    <Styles.NewVMStep3SC id="ST3">
      <Styles.GreyOut isGrey={_get(values, 'template', '')}>
        {/* <StepLabel id="ST3" stepCount={t('vs/instance/create.step3_string1')}>
          <Trans i18nKey="vs/instance/create.step3_string2" components={{ highlight: <Highlight /> }} />
        </StepLabel>
        <StepLabelMobile stepCount={t('vs/instance/create.step3_string1')}>
          <Trans i18nKey="vs/instance/create.step3_string2" components={{ highlight: <Highlight /> }} />
        </StepLabelMobile> */}
        <Heading name={'vs/instance/create.step3_string2'}>
          <StepDescription />
          <OperatingSystemCard
            OsData={OsData}
            formikProp={formikProp}
            updatePricing={updatePrice}
            template={_get(vsTemplate, 'vs_saved_templates', [])}
          />
        </Heading>
      </Styles.GreyOut>
      <Styles.Heading>
        <Trans i18nKey="vs/instance/create.step3_string4" components={{ highlight: <Highlight /> }} />
      </Styles.Heading>
      {templateError ? (
        <Styles.NotificationContainer>
          <Notification>
            <Icon name="info circle" />
            {t('vs/instance/create.step3_string5')}
          </Notification>
        </Styles.NotificationContainer>
      ) : (
        <Select
          name="template"
          options={useFullTemplateData(_get(vsTemplate, 'vs_saved_templates', []))}
          style={{ margin: '40px 0px', maxWidth: '320px', minWidth: '10px' }}
          onChange={handleTemplateChange}
          value={values['template'] || ''}
        />
      )}

      {errors.image && touched.image && <ErrorMessage />}
    </Styles.NewVMStep3SC>
  );
};
export default NewVMStep3;
