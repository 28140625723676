import React, { useEffect } from 'react';
import { Card, Heading } from 'components/vm-deployment/operating-system-card/OperatingSystemCard.style';
import { getOSImage } from 'components/vm-deployment/operating-system-card/util';
import { useMediaQuery } from '@material-ui/core';
import Checkbox from 'components/common/radio-button';
import { CustomHook } from 'utils/custom-hook';
import { useDispatch, useSelector } from 'react-redux';
import { updateBMCPanelOs } from 'store/actions/bm-deployment';
import { distributeColumns, sortAlphabetically } from 'utils/sorted-os';

export default function BmOSCard({ OsData, formikProp }) {
  const { values, setFieldValue } = formikProp;
  const dispatch = useDispatch();
  const { scrollDownToId } = CustomHook();
  const cPanelOs = useSelector((state) => state.bmDeployment.cPanelOs);
  const handleSelectImage = (operatingSystem) => {
    setFieldValue('image', operatingSystem.id);
    setFieldValue('prices.image', operatingSystem?.prices || '0');
    if (cPanelOs !== operatingSystem.id) {
      dispatch(updateBMCPanelOs(''));
      setFieldValue('app', '');
      setFieldValue('prices.app', 0);
    }
  };
  useEffect(() => {
    const os = OsData.find((item) => item.id === cPanelOs);
    if (os) handleSelectImage(os);
  }, [cPanelOs]);

  OsData.sort((a, b) => a.name.localeCompare(b.name));
  const sm = useMediaQuery('(max-width:599px)');
  const md = useMediaQuery('(max-width:959px)');
  const lg = useMediaQuery('(max-width:1280px)');
  const xl = useMediaQuery('(max-width:1919px)');

  let styling = sm
    ? { width: '100%', number: 1 }
    : md
    ? { width: '50%', number: 2 }
    : lg || xl
    ? { width: '33%', number: 3 }
    : { width: '25%', number: 4 };

  const sortedItems = sortAlphabetically(OsData);
  const columns = distributeColumns(sortedItems, styling.number);

  return (
    <div className="d-flex">
      {columns.map((col, colIndex) => (
        <div className="d-flex flex-column" key={colIndex} style={{ width: styling.width }}>
          {col.map((operatingSystem) => (
            <Card
              key={operatingSystem.id}
              onClick={() => {
                handleSelectImage(operatingSystem);
                scrollDownToId('ST3');
              }}
            >
              <Checkbox
                checkbox={values.image}
                setCheckedValue={(value) => handleSelectImage(OsData.find((item) => item.id === value))}
                data={operatingSystem}
              />
              <div>
                <img src={getOSImage(operatingSystem?.family)} alt="OS Image" />
              </div>
              <Heading>
                {operatingSystem?.name}
                {Number(operatingSystem?.prices) > 0 ? ` ($${Number(operatingSystem?.prices)}/mo)` : null}
              </Heading>
            </Card>
          ))}
        </div>
      ))}
    </div>
  );
}
