import { dynamicStyles } from 'components/reusable-css';
import styled from 'styled-components';
import { deviceBreakpoints } from 'variables';

export const MainCon = styled.div`
  padding: 0 5px;
  left: 0px;
  top: 560px;
  z-index: 100;
  position: fixed;
  width: ${(props) => props.theme.default?.desktop_menu_width};
  ${(props) => dynamicStyles(props.theme.resolutions, 'desktop_menu_width', 'width')};
  @media only screen and (max-width: ${deviceBreakpoints.mLaptop}px) {
    visibility: hidden;
  }
`;

export const PriceCal = styled.div`
  display: inline-block;
  padding: 10px;
  width: 100%;
  background-color: ${(props) => props.theme.primaryDarkColor};
  color: #fff;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
`;
