/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-28 01:28:56
 * @modify date 2021-01-28 01:28:56
 */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NotFoundSC, Card, Heading, SubHeading } from './NotFound.style';

import Config from 'app.config';

export default function NotFound({ isError }) {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t(isError ? Config.appTitle : 'string5');
    return () => {
      document.title = Config.appTitle;
    };
  });

  return (
    <NotFoundSC data-testid="not-found">
      <Card>
        <Heading data-testid="not-found-heading">{t(isError || 'string3')}</Heading>
        <SubHeading>
          {t(
            isError == 404
              ? 'Not Found'
              : isError == 429
              ? 'Too Many Requests'
              : isError == 500
              ? 'Server Error'
              : 'string4',
          )}
        </SubHeading>
      </Card>
    </NotFoundSC>
  );
}
