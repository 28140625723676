import React from 'react';
import classes from './select.module.css';
import { useTranslation } from 'react-i18next';

const Select = ({ name, value, onChange, options, style, styling, dns }) => {
  const { t } = useTranslation();
  return (
    <select name={name} className={classes.select} value={value} onChange={onChange} style={{ ...style, ...styling }}>
      {dns
        ? React.Children.toArray(
            options?.map((item) => (
              <option value={item.id}>
                {item.id} - {item.server_alias}
              </option>
            )),
          )
        : React.Children.toArray(
            options.map((item) => <option value={item.value}>{t(item.text, { number: item.value })}</option>),
          )}
    </select>
  );
};
export default Select;
