import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import { Icon } from 'semantic-ui-react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { VmNetworks, AlmaLinuxcPanel, SettingsSlidingPanel } from './components';
import { BmNetworks } from 'components/manage-bm';
import { TagCard } from 'components/manage-vm';
import ConditionalFragment from 'components/conditional-fragment';
import CustomButton from 'components/common/custom-button';
import hooks from 'hooks';
import theme from 'themes/dark-theme';
import MaterialIcon from '@material-ui/core/Icon';
import SettingPanel from './settings-panel';
import ClickNHold from 'react-click-n-hold';
import { Delete } from 'assets/css/delete-animation';
import { checkDeletedItems } from 'components/manage-vm/utils';
import Tooltip from 'components/Tooltip';
import { VS_INSTANCE_STATUS, statisticPanelMenus, settingTabs } from 'constants/index';
import { get } from 'lodash';
import StatisticsPanel from './statistics-panel';
import { getCountryImage } from 'components/manage-vm/utils';
import { capitalizeFirstLetter } from 'utils';
import { ServerImage } from './settings-panel/components';
import ErrorMessage from 'components/common/red-notification';
import { userRights } from 'store/selectors/user-details';
import moduleClasses from 'components/manage-vm/manage-vm.module.css';
import { P } from 'components/reusable-css';
import PasswordRetrieve from './PasswordRetrieve';
import { useQuery } from 'react-query';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    border: `2px solid ${(props) => props.theme.serverSizeCard.borderColor}`,
  },
}));

const ManageVMMobile = ({
  data,
  network: type,
  managerVmRefetch,
  allTags,
  managerVmIsFetching,
  isTags,
  backup,
  menuOptions,
  dropdownItems,
  RefreshServer,
  handleConsoleOpen,
  setDeletedIds,
  deletedIds,
  opacity,
  serverIpDetails,
  serverStatus,
  vmStatusRefetch,
  status,
  refetchTags,
}) => {
  const { handleBackupHours } = hooks();
  const [openConfigurationPanel, setOpenConfigurationPanel] = useState(false);
  const [openStatisticPanel, setOpenStatisticPanel] = useState(false);
  const [openAccordian, setOpenAccordian] = useState(false);
  const [initialTab, setInitialTab] = useState();
  const [rowID, setRowId] = useState();
  const [anim, setAnim] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isTriggerApi, setIsTriggerApi] = useState(false);
  const {
    data: passwordData,
    isLoading,
    isRefetching,
  } = useQuery('/vs/password/retrieve', {
    enabled: isTriggerApi,
  });
  const statusOfServer = get(serverStatus, VS_INSTANCE_STATUS, [])?.find((item) => item.id === data.id) || data;
  const inProgress = statusOfServer?.is_deployed === '0';
  const [initialActionTab, setInitialActionTab] = useState();
  const classes = useStyles();
  const { t } = useTranslation();
  const userUI = useSelector((state) => state.user.ui);
  const isNew =
    moment(data.date_deployment_completed).add(userUI.show_new_vs_hours, 'hours').isAfter(userUI.server_timedate_pst) ||
    inProgress;
  const [openTag, setOpenTag] = useState(false);
  const handleClose = () => {
    setOpenTag(false);
  };
  const handleOpen = () => {
    setOpenTag(true);
  };
  const toggle = () => setOpenConfigurationPanel((prev) => !prev);
  const toggleStats = () => setOpenStatisticPanel((prev) => !prev);
  const toggleAccordian = () => setOpenAccordian((prev) => !prev);

  let checkDisabledItem = checkDeletedItems(deletedIds, data?.id);
  const enableBackup = () => {
    setInitialTab(settingTabs['templates/backups']);
    setOpenConfigurationPanel(true);
  };
  const enableActionsDelete = () => {
    setInitialTab(settingTabs.actions);
    setOpenConfigurationPanel(true);
    setInitialActionTab('delete_bm');
  };
  const enableActionsCancel = () => {
    setInitialTab(settingTabs.actions);
    setOpenConfigurationPanel(true);
    setInitialActionTab('cancel_VM');
  };
  const tags = get(data, 'tags', []);
  const rights = useSelector(userRights);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setOpenStatisticPanel(false);
      setOpenConfigurationPanel(false);
    }
  }, []);
  const upgradeOpen = () => {
    managerVmRefetch();
    setOpenConfigurationPanel(true);
    setInitialTab(settingTabs.upgrade);
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const handleActionsOpen = () => {
    managerVmRefetch();
    setInitialTab('actions');
    setOpenConfigurationPanel(true);
    setInitialActionTab('cancel_VM');
  };

  return (
    <div style={checkDisabledItem} className={classes.root}>
      <Accordion style={{ borderRadius: '10px' }} elevation={0}>
        <AccordionSummary
          onClick={() => {
            setRowId(data?.id);
            toggleAccordian();
          }}
          style={{ padding: '0 10px 0 0' }}
          expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: 'orange' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Styles.MobilePicture>
            <ServerImage
              inProgress={inProgress}
              status={statusOfServer}
              data={data}
              managerVmRefetch={managerVmRefetch}
              type={type}
            />
          </Styles.MobilePicture>
          <div className={moduleClasses.width}>
            <Styles.Label style={{ justifyContent: 'left' }}>
              <div className={moduleClasses.tag_heading_icon}>
                <ConditionalFragment condition={type === 'bm' && rights.bmrename === 1}>
                  <h3 style={{ fontSize: 17 }}>{data.label}</h3>
                  <Icon className={moduleClasses.pencil_icon} name="pencil" onClick={handleOpen} />
                </ConditionalFragment>
                <ConditionalFragment condition={rights.vsrename === 1 && type === 'vs'}>
                  <h3 style={{ fontsize: 17 }}>{data.label}</h3>
                  <Icon className={moduleClasses.pencil_icon} name="pencil" onClick={handleOpen} />
                </ConditionalFragment>
                <div className={moduleClasses.margin_top}>
                  {isNew && <Styles.NewTagButton>{t('vs/instance.string52')}</Styles.NewTagButton>}
                </div>
              </div>
              {data.vs_cancelation_scheduled == 1 && (
                <div className={moduleClasses.margin_left}>
                  <ErrorMessage>
                    <P>
                      <Trans i18nKey={'bm/instance.string4'}>
                        <span onClick={enableActionsCancel} className={moduleClasses.deleted_server_sty} />
                      </Trans>
                    </P>
                  </ErrorMessage>
                </div>
              )}

              {data.bm_cancelation_scheduled == 1 && (
                <div className={moduleClasses.margin_left}>
                  <ErrorMessage>
                    <P>
                      <Trans i18nKey={'bm/instance.string4'}>
                        <span onClick={enableActionsDelete} className={moduleClasses.deleted_server_sty} />
                      </Trans>
                    </P>
                  </ErrorMessage>
                </div>
              )}
            </Styles.Label>
            {data.retrievable_password == 1 && (
              <Styles.PasswordLink
                onClick={() => {
                  setIsTriggerApi(true);
                  setIsOpen(true);
                }}
              >
                <P>{t('vs/instance.string201')}</P>
              </Styles.PasswordLink>
            )}
            {!inProgress && (
              <>
                {type === 'bm' ? (
                  <BmNetworks data={data} />
                ) : (
                  <VmNetworks data={data} serverIpDetails={serverIpDetails} />
                )}
              </>
            )}
            <ConditionalFragment condition={!inProgress}>
              <AlmaLinuxcPanel type={type} data={data} style={{ marginBottom: '7px' }} />
            </ConditionalFragment>

            <Styles.SummaryLastRow>
              {type != 'bm' && (
                <div className={moduleClasses.last_row}>
                  <ConditionalFragment condition={!inProgress && backup}>
                    <ConditionalFragment condition={data.backups_is_enabled == 0 && data?.billing_schedule == 'hourly'}>
                      <Trans i18nKey={'vs/instance.string169'}>
                        <span className={moduleClasses.manager_vm_backup} />
                        <span className={moduleClasses.never_heading} />
                      </Trans>
                      <CustomButton
                        pointerEvents={checkDisabledItem}
                        title={t('vs/instance.string22')}
                        buttonStyles={{ fontSize: '10px', padding: '0.25rem 0.45rem' }}
                        onClick={enableBackup}
                      />
                    </ConditionalFragment>
                    <ConditionalFragment condition={data.backups_is_enabled == 1}>
                      <Trans i18nKey={handleBackupHours(data).text} values={{ data: handleBackupHours(data).param }}>
                        <span className={moduleClasses.manager_vm_backup} />
                        <span style={{ fontSize: '12px' }} />
                      </Trans>
                    </ConditionalFragment>
                  </ConditionalFragment>
                </div>
              )}
              <Styles.Country style={{ padding: 0, width: 'auto' }} inProgress={inProgress}>
                <Styles.CountryFlagMobile>
                  <Styles.Circle style={{ border: '0.5px solid #C5C5C5', width: '20px', height: '20px' }}>
                    <img src={getCountryImage(data?.region_country, data?.region_ucase)} alt="country" />
                  </Styles.Circle>
                  <h6 style={{ fontSize: '12px' }}>{data.region_ucase}</h6>
                  {status && (
                    <React.Fragment>
                      <Styles.Status
                        style={{ fontSize: '10px', minWidth: '50px' }}
                        managerVmIsFetching={vmStatusRefetch}
                        status={statusOfServer?.status}
                      >
                        {statusOfServer?.status ? t(capitalizeFirstLetter(statusOfServer?.status)) : 'Deleted'}
                      </Styles.Status>
                    </React.Fragment>
                  )}
                </Styles.CountryFlagMobile>
              </Styles.Country>
            </Styles.SummaryLastRow>
          </div>
        </AccordionSummary>
        <AccordionDetails
          style={{
            padding: '1rem 1.4rem 1rem 2rem',
            borderTop: '1px solid lightGrey',
            display: 'block',
            marginBottom: '5rem',
          }}
        >
          <Styles.MobileCardDescription>
            <h3 style={{ fontSize: '15px', marginRight: 8 }}>Tags:</h3>
            <Styles.AddTag style={{ marginTop: tags.length ? -3 : 0 }}>
              <TagCard
                end
                openTag={openTag}
                handleOpen={handleOpen}
                handleClose={handleClose}
                data={data}
                managerVmRefetch={managerVmRefetch}
                allTags={allTags}
                refetchTags={refetchTags}
                managerVmIsFetching={managerVmIsFetching}
                isTags={isTags}
                type={type}
                mobile
                rights={rights}
              />
            </Styles.AddTag>
          </Styles.MobileCardDescription>
        </AccordionDetails>
      </Accordion>
      {!inProgress && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          {rowID == data.id && !inProgress && openAccordian && (
            <Styles.MobileOptions>
              <div
                style={{
                  padding: '1rem 0',
                }}
              >
                {dropdownItems(
                  setOpenConfigurationPanel,
                  RefreshServer,
                  handleConsoleOpen,
                  '',
                  setInitialTab,
                  enableBackup,
                  { toggleStats, upgradeOpen },
                  data,
                  handleActionsOpen,
                ).map((dropDownItem) => (
                  <React.Fragment key={dropDownItem.id}>
                    {dropDownItem.name != 'delete_VS' ? (
                      !(dropDownItem.name == 'reboot' && !rights.vsreboot) && (
                        <Styles.MobileMenuItem
                          onClick={() => {
                            if (dropDownItem.name == 'settings') {
                              managerVmRefetch();
                            }
                            dropDownItem.onClick();
                          }}
                          style={opacity(dropDownItem)}
                        >
                          <div style={{ paddingTop: 5, padding: '0 1.6rem' }} className="d-flex">
                            <MaterialIcon
                              style={{ width: '2rem', marginRight: '1rem', color: theme.primaryDarkColor }}
                              className={dropDownItem.icon}
                            />
                            <p>{t(dropDownItem.lang)}</p>
                          </div>
                        </Styles.MobileMenuItem>
                      )
                    ) : (
                      <ClickNHold
                        time={2}
                        onStart={() => setAnim(true)}
                        onEnd={() => setAnim(false)}
                        onClickNHold={() => {
                          dropDownItem.onClick();
                          setDeletedIds([...deletedIds, data.id]);
                        }}
                      >
                        <Tooltip title="Click for 2 seconds" placement="left">
                          <Delete anim={anim} className="icon d-flex" style={{ padding: '7px 2.1rem' }}>
                            <MaterialIcon
                              style={{ width: '2rem', marginRight: '1rem', color: theme.primaryDarkColor }}
                              className={dropDownItem.icon}
                            />{' '}
                            <p>{t(dropDownItem.lang)}</p>
                          </Delete>
                        </Tooltip>
                      </ClickNHold>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Styles.MobileOptions>
          )}
        </div>
      )}
      <SettingsSlidingPanel openPanel={openConfigurationPanel} setOpenPanel={setOpenConfigurationPanel} isMobile>
        {openConfigurationPanel && (
          <SettingPanel
            data={data}
            type={type}
            menuOptions={menuOptions}
            openConfigurationPanel={openConfigurationPanel}
            setOpenConfigurationPanel={setOpenConfigurationPanel}
            initialTab={initialTab}
            isTags={isTags}
            setDeletedIds={() => setDeletedIds((prev) => [...prev, data.id])}
            toggle={toggle}
            managerVmRefetch={managerVmRefetch}
            allTags={allTags}
            statusOfServer={statusOfServer}
            managerVmIsFetching={managerVmIsFetching}
            initialActionTab={initialActionTab}
          />
        )}
      </SettingsSlidingPanel>
      <SettingsSlidingPanel openPanel={openStatisticPanel} setOpenPanel={setOpenStatisticPanel}>
        {openStatisticPanel && (
          <StatisticsPanel
            openStatisticPanel={openStatisticPanel}
            setOpenStatisticPanel={setOpenStatisticPanel}
            data={data}
            menuOptions={statisticPanelMenus}
            type={type}
          />
        )}
      </SettingsSlidingPanel>
      <PasswordRetrieve
        isOpen={isOpen}
        isLoading={isLoading}
        isRefetching={isRefetching}
        handleOpen={() => {
          setIsTriggerApi(false);
          setIsOpen(false);
        }}
        passwordData={passwordData}
      />
    </div>
  );
};

export { ManageVMMobile };
