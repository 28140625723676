import { h2Styles, h3Styles, h5Styles, h6Styles, pStyles } from 'components/reusable-css';
import styled from 'styled-components';
import { variables } from 'variables';

export const AllIssueSC = styled.div`
  width: 100%;
  background: ${(props) => props.theme.pageBackgroundColor};
  padding: ${variables.pageContentPadding}px;
  padding-bottom: 2em;
  min-height: 100vh;
`;

export const NewMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

export const NoIssue = styled.div`
  font-size: 16px;
  margin: 12px 0;
`;

export const Heading = styled.h2`
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
`;

export const StepDescription = styled.div`
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  margin-top: 5px;
`;

export const FirstRow = styled.div`
  margin-top: 5px;
`;

export const P = styled.p`
  margin: 0;
  margin-left: 4px;
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
`;

export const OpenClosedIcon = styled.div`
  display: flex;
  align-items: center;

  .open {
    display: flex;
    padding-right: 1em;
  }
  .closed {
    display: flex;
  }
  i {
    font-size: 17px;
    margin-top: 2px;
  }
`;

export const AllRow = styled.div`
  margin-top: 3.6em;
  margin-bottom: 2em;
`;

export const Issue = styled.div`
  margin-top: 15px;
  background: white;
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.serverSizeCard.borderColor};
  &:hover {
    background-color: #f9f9f9;
  }
  padding: 1.3em 1em;
  color: black;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

export const Border = styled.div`
  border-right: 1px solid #e9e9e9;
  margin: 0 1.5rem;
`;

export const Department = styled.div`
  display: flex;
  align-items: center;
`;

export const Flex = styled.div`
  display: flex;
`;

export const H5 = styled.h5`
  width: 6em;
  margin: 0;
  color: ${(props) => props.theme.primaryDarkColor};
  font-size: ${(props) => props.theme.default?.h5};
  ${(props) => h5Styles(props.theme.resolutions)};
`;

export const H3 = styled.h3`
  font-size: ${(props) => props.theme.default?.h3};
  ${(props) => h3Styles(props.theme.resolutions)};
`;

export const H6 = styled.h6`
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;

export const Left = styled.div`
  padding: 0 0.2em;
  margin-right: 1em;
  .issueName {
    margin: 0;
    margin-bottom: 8px;
  }
  @media (max-width: 650px) {
    margin-right: 0;
  }
`;

export const Right = styled.div`
  min-width: 21em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0.5em;
  .righticon {
    display: flex;
    margin-left: 1em;
  }
  p {
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
  }
  @media (max-width: 650px) {
    width: 100%;
    padding-top: 1em;
    padding-left: 0;
    justify-content: space-between;
  }
`;

export const IssueName = styled.div`
  padding: 0.6rem 1.4rem;
  border-radius: 5px;
  font-weight: bold;
`;
