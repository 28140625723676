import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';

export const NotificationSC = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const NotificationMsg = styled.div`
  display: flex;
  border-radius: 6px;
  padding: 6px 12px;
  p {
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
  }
  i {
    margin: 0;
    padding: 0;
    margin-right: 12px;
  }
`;
