import React from 'react';
import { Grid } from '@material-ui/core';
import { H4, P } from 'components/reusable-css';
import { useTranslation } from 'react-i18next';

const TransferData = ({ incomingData, outgoingData }) => {
  const { t } = useTranslation();
  return (
    <div className="w-100 py-4">
      <Grid container style={{ maxWidth: 400 }}>
        <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
          <div className="text-success pr-5">
            <P className="mb-2">
              <strong>{t('vs/instance.string69')}</strong>
            </P>
            <H4>
              <strong>{incomingData}</strong>
            </H4>
          </div>
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6} xl={6}>
          <div className="pl-2 text-primary">
            <P className="mb-2">
              <strong>{t('vs/instance.string70')}</strong>
            </P>
            <H4>
              <strong>{outgoingData}</strong>
            </H4>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export { TransferData };
