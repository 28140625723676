import React from 'react';
import * as Styles from 'pages/member/wh/WH.style';
import { StepLabel, Highlight } from 'components/vm-deployment/components';
import { useTranslation, Trans } from 'react-i18next';
import { Grid } from '@material-ui/core';
import priceCalculator from 'utils/price-calculator';
import UseWh from 'hooks/use-wh';
import VmPaymentForm from 'pages/member/new-vm/new-vm-step-7/vm-payment-form';
import { cardError } from 'validations';

const PaymentCard = ({ price, onClick, showTag, paymentType, selected }) => {
  return (
    <Styles.PaymentCon onClick={onClick} selected={selected}>
      <Styles.RedTag showTag={showTag}>{'Free two months!'}</Styles.RedTag>
      <Styles.PriceStep5>
        <Styles.PerMonthStep5>
          USD $<Styles.PriceUnitStep5>{priceCalculator(price, false)}</Styles.PriceUnitStep5>/{paymentType}
        </Styles.PerMonthStep5>
      </Styles.PriceStep5>
    </Styles.PaymentCon>
  );
};

const WHStep5 = ({ formikProp }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = formikProp;
  const { withoutDomainPriceYearly, withoutDomainPriceMonthly, yearlyPrice, monthlyPrice } = UseWh({ formikProp });
  const handleSelectPayment = (value) => {
    setFieldValue('options_prepaid', value);
    
  };

  return (
    <Styles.WHStep2SC>
      <StepLabel id="ST5" stepCount={t('Step 5')}>
        <Trans i18nKey="wh_step5_heading" components={{ highlight: <Highlight spaceLeft /> }} />
      </StepLabel>
      <Styles.MarginContainer>
        <Grid container>
          <Grid xs={12} sm={12} md={3} lg={3} xl={2}>
            <Styles.HeadingStep5 marginTop>Choose your payment terms:</Styles.HeadingStep5>
          </Grid>
          <Grid container xs={12} sm={12} md={9} lg={9} xl={10}>
            <PaymentCard
              selected={values.options_prepaid === 1}
              onClick={() => handleSelectPayment(1)}
              price={withoutDomainPriceMonthly}
              paymentType="month"
            />
            <PaymentCard
              selected={values.options_prepaid === 12}
              onClick={() => handleSelectPayment(12)}
              showTag
              price={withoutDomainPriceYearly}
              paymentType="year"
            />
          </Grid>
        </Grid>
        {values.domain_checked != 'transfer_an_existing_domain' && values.domain_checked != 'no_need_for_a_domain' && (
          <Grid container>
            <Grid xs={7} sm={4} md={3} lg={3} xl={2}>
              <Styles.HeadingStep5>Domain registration:</Styles.HeadingStep5>
            </Grid>
            <Grid container xs={5} sm={8} md={9} lg={9} xl={10}>
              <Styles.ParaStep5>{priceCalculator(values.prices.domain)}/year</Styles.ParaStep5>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid xs={7} sm={4} md={3} lg={3} xl={2}>
            <Styles.HeadingStep5>Total:</Styles.HeadingStep5>
          </Grid>
          <Grid container xs={5} sm={8} md={9} lg={9} xl={10}>
            <Styles.ParaStep5>{priceCalculator(values.options_prepaid == 1 ? monthlyPrice : yearlyPrice)}</Styles.ParaStep5>
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={7} sm={4} md={3} lg={3} xl={2}>
            <Styles.HeadingStep5>Term renewal:</Styles.HeadingStep5>
          </Grid>
          {values.options_prepaid === 1 ? (
            <Grid container xs={5} sm={8} md={9} lg={9} xl={10}>
              <Styles.ParaStep5>Same price every month</Styles.ParaStep5>
            </Grid>
          ) : (
            values.options_prepaid === 12 && (
              <Grid container xs={5} sm={8} md={9} lg={9} xl={10}>
                <Styles.ParaStep5>Same price! We won't raise your price when the year is over!</Styles.ParaStep5>
              </Grid>
            )
          )}
        </Grid>
      </Styles.MarginContainer>
      <VmPaymentForm cardError={cardError} amount={withoutDomainPriceYearly} formikProp={formikProp} />
    </Styles.WHStep2SC>
  );
};
export default WHStep5;
