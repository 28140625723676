import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { h6Styles, pStyles } from 'components/reusable-css';

export const IssuesSC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.backgroundColor || props.theme.pageBackgroundColor};
  padding: 15px;
  border-bottom: ${(props) => `2px solid ${props.theme.primaryDarkColor}`};
  @media only screen and (max-width: 770px) {
    padding: 40px 20px;
  }
`;

export const Title = styled.p`
  margin: 10px 0;
`;

export const CarretIcon = styled(Icon)`
  padding-top: 2.5px;
`;

export const TextAreaContainer = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 10px 0px;
`;

export const SecretSection = styled.div`
  background: #f8f8f8;
  margin-top: 15px;
`;

export const Pin = styled.div`
  .pincode-input-container {
    padding-left: 0px !important;
    width: 230px;
  }
  input {
    width: 40px;
    height: 50px;
    border: 1px solid #b3b3b3b3;
    outline: none;
    border-right: none;
    line-height: 50px;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    :first-child {
      border-radius: 10px 0px 0px 10px;
    }
    :last-child {
      border: 1px solid #b3b3b3b3;
      border-radius: 0px 10px 10px 0px;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .splitter {
    padding: 0 5px;
    color: white;
    font-size: 24px;
  }
`;

export const Description = styled.p`
  margin: ${(props) => props.margin || `40px 0 20px 0`};
  font-size: ${(props) => props.theme.default?.p};
  ${(props) => pStyles(props.theme.resolutions)};
  max-width: 800px;
`;

export const FlexContainer = styled.div`
  display: ${(props) => props.display || `flex`};
  max-width: ${(props) => props.maxWidth || `100%`};
  cursor: ${(props) => props.cursor || `normal`};
  margin: ${(props) => props.margin || `0`};
  align-items: ${(props) => props.alignItems || `center`};
  justify-content: ${(props) => props.justifyContent || `flex-start`};
  font-weight: ${(props) => props.fontWeight || `normal`};
  // @media only screen and (max-width: 700px) {
  //   display: block;
  // }
  width: ${(props) => props.width || `fit-content`};
  .pin-error {
    color: red;
    font-size: 12px;
    height: 15px;
  }
  .pin-details {
    display: flex;
    flex-direction: column;
  }
`;

export const ContentPadding = styled.div`
  display: ${(props) => props.display || `block`};
  align-items: ${(props) => props.alignItems || `normal`};
  justify-content: ${(props) => props.justifyContent || `flex-start`};
  padding: 10px 0px;
  max-width: ${(props) => props.maxWidth || `100%`};
  @media only screen and (max-width: 550px) {
    display: block;
  }
`;

export const SubDepartments = styled.div`
  margin-left: 0.7rem;
`;

export const SelectContainer = styled.div`
  position: relative;
  width: 70%;
  margin-bottom: 50px;
  max-width: 600px;

  @media only screen and (max-width: 770px) {
    width: 100%;
  }
`;

export const Select = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  border-bottom: 2px solid orange;
  height: 60px;
  padding: 0 14px;
  cursor: pointer;
  color: grey;
  width: 100%;
  i {
    font-size: 22px;
    margin-top: -13px;
    color: black;
  }
`;

export const DropDown = styled.div`
  position: absolute;
  right: 0;
  border: 2px solid #d8d8d8;
  flex-wrap: wrap;
  padding: 8px 0px;
  border: 1px solid #d8d8d8;
  border-bottom: 2px solid orange;
  margin-top: 5px;
  border-radius: 6px;
  background-color: white;
  p {
    padding: 4px 20px;
    margin: 0;
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
    cursor: pointer;
    &:hover {
      background-color: #ededed;
    }
  }
`;

export const EditorContainer = styled.div`
  background-color: #f2f2f2;
  padding: 16px;
  margin-bottom: 40px;
  max-width: 1100px;
`;

export const WysimarkInfo = styled.div`
  width: ${(props) => props.width || `45%`};
  margin-right: 20px;
  .flex {
    display: flex;
    max-width: 350px;
    min-width: 350px;
  }
  span {
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
  }
  .text {
    max-width: 350px;
  }
  .secret-icon {
    margin-top: -8px;
    margin-right: 10px;
  }
  @media only screen and (max-width: 860px) {
    width: 50%;
  }
  @media only screen and (max-width: 620px) {
    width: 100%;
    margin-bottom: 5px;
  }
  @media only screen and (max-width: 405px) {
    .flex {
      max-width: 300px;
      min-width: 280px;
    }
  }
`;

export const InputContainer = styled.div`
  width: 35%;
  @media only screen and (max-width: 860px) {
    width: 45%;
  }
  @media only screen and (max-width: 620px) {
    width: 100%;
    padding-bottom: 40px;
  }
`;

export const step1Title = styled.h6`
  margin: 5px 0;
  margin-left: ${(props) => props.input_type !== 'radio' && '10px'};
  font-size: ${(props) => props.theme.default?.h6};
  ${(props) => h6Styles(props.theme.resolutions)};
`;

export const RecursionCheckBoxes = styled.div`
  .repeat-div {
    margin-left: 28px;
  }
  .title {
    cursor: pointer;
    width: fit-content;
  }
`;
