/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-01-31 16:08:51
 * @modify date 2021-01-31 16:50:01
 */

import styled from 'styled-components';

export const BadgeSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 14px;
  border-radius: 2px;
  background: ${(props) => props.theme.gray.shadeC};
  color: ${(props) => props.theme.gray.shade6};
  font-size: 10px;
  font-weight: bold;
`;
