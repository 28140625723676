import React from 'react';
import * as Styles from 'pages/member/wh/WH.style';
import { StepLabel, Highlight } from 'components/vm-deployment/components';
import { useTranslation, Trans } from 'react-i18next';
import WHSize from 'components/wh/WHSize';
import { get } from 'lodash';
import FormikErrorMessage from 'components/formik-error-message';

const WHStep2 = ({
  whSizesData,
  whSizesPricesData,
  handleSizeSelection,
  selectedSize,
  formikProp,
  whSizesDataDescription,
}) => {
  const { t } = useTranslation();
  return (
    <Styles.WHStep2SC>
      <StepLabel id="ST2" stepCount={t('Step 2')}>
        <Trans i18nKey="wh_step2_heading" components={{ highlight: <Highlight spaceLeft /> }} />
      </StepLabel>
      <Styles.StepDescription>{t('wh_step2_paragraph')}</Styles.StepDescription>
      <Styles.InlineSizesContainer>
        {get(whSizesData, 'wh_sizes', [])
          .filter((i) => +i.active)
          .map((size, i) => {
            const sizePrice = whSizesPricesData?.prices_wh_sizes?.find((data) => size.id === data.id);
            const sizeDescription = whSizesDataDescription?.wh_sizes_description?.find((data) => size.id === data.id);
            return (
              <WHSize
                selected={size?.id === selectedSize?.id}
                index={i}
                length={whSizesData.wh_sizes.length}
                onClick={handleSizeSelection}
                size={size}
                key={size?.id}
                sizePrice={sizePrice}
                sizeDescription={sizeDescription}
              />
            );
          })}
      </Styles.InlineSizesContainer>
      <FormikErrorMessage formikProp={formikProp} value="size" />
    </Styles.WHStep2SC>
  );
};
export default WHStep2;
