import React from 'react';
import { Icon } from 'semantic-ui-react';
import * as Styles from 'pages/member/user-management/UserManagement.style';
import { Trans } from 'react-i18next';
import TriCut from 'assets/images/cut-tri.png';

const PanelHeader = ({ name, isClose, heading, user_number }) => {
  return (
    <React.Fragment>
      <Styles.PanelUserHeader>
        <div className="d-flex flex-column w-100">
          <Styles.PanelHeading>
            <h4>
              <Trans
                i18nKey={heading}
                values={{
                  name: name,
                  number: user_number,
                }}
              >
                <span />
              </Trans>
            </h4>
          </Styles.PanelHeading>
        </div>
        <Styles.CrossIcon>
          <Icon size="large" name="close" onClick={() => isClose(false)} />
        </Styles.CrossIcon>
        <img style={{ position: 'absolute', bottom: 0 }} src={TriCut} />
      </Styles.PanelUserHeader>
    </React.Fragment>
  );
};
export { PanelHeader };
