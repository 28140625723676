import axios from 'axios';
import { BASE_URL } from 'constants/index';

export const MANAGE_BM = async (e) => {
  const res = await axios.get(BASE_URL + `/bm/instance`, { params: e.queryKey[1] });
  return res.data;
};

export const BANDWIDTH = async (e) => {
  const res = await axios.get(BASE_URL + `${e.queryKey[1].pathname}`);
  return res.data;
};

