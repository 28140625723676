import React from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import * as Styles from './SlidingPanel.style';

const SlidingPanels = ({ children, isOpen, isClose, size }) => {
  return (
    <SlidingPanel
      type={'right'}
      isOpen={isOpen}
      backdropClicked={() => isClose(false)}
      size={size || 50}
      noBackdrop={false}
    >
      <Styles.UserPanel>{children}</Styles.UserPanel>
    </SlidingPanel>
  );
};

export default SlidingPanels;
