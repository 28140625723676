import { passwordRegex, vsLabelRegex } from 'constants/regex';
import * as Yup from 'yup';
import { SLIDER } from '.';
import { CORE, DISK, RAM } from './step1';
// import { sliders } from 'constants/initial-values';
import { getCurrentYear } from 'utils/current-year';
import { useTranslation } from 'react-i18next';

export const initialValues = (userUI) => {
  const { t } = useTranslation();
  return {
    payment_method: '',
    size_object: {},
    size: '',
    app: '',
    image: '',
    image_object: {},
    region: '',
    backup_plan: '',
    options_prepaid: {},
    root_pass: '',
    confirm_pass: '',
    virtual_server_name: t(userUI.vs_default_label) || 'My SSD Virtual Cloud Server',
    ipv4_ips: 1,
    vs_saved_templates: {},
    // card_name: '',
    // billing_address: '',
    // city: '',
    // cvv: '',
    // card_number: '',
    // bank_name: '',
    // bank_phone_number: '',
    // state: '',
    // zip: '',
    // phone: '',
    // company: '',
    country: 'US',
    card_month: 1,
    card_year: getCurrentYear(),
    sliders: {
      [RAM]: 512,
      [DISK]: 10,
      [CORE]: 1,
    },
  };
};

export const validationSchema = (validation) =>
  Yup.object({
    app: Yup.string().optional(),
    region: Yup.string().required(),
    image: Yup.string().required(),
    backup_plan: Yup.string().required(),
    options_prepaid: Yup.object().required(),
    virtual_server_name: Yup.string()
      .max(32, 'vs/instance/create.string8')
      .matches(vsLabelRegex, 'vs/instance/create.string9')
      .required('vs/instance/create.string10'),
    root_pass: Yup.string()
      .max(128, 'vs/instance/create.string11')
      .min(6, 'vs/instance/create.string12')
      .required('vs/instance/create.string13')
      .matches(passwordRegex, 'vs/instance/create.string14'),
    confirm_pass: Yup.string()
      .max(128, 'vs/instance/create.string11')
      .min(6, 'vs/instance/create.string12')
      .oneOf([Yup.ref('root_pass'), null], 'vs/instance/create.string15')
      .required('vs/instance/create.string13')
      .matches(passwordRegex, 'vs/instance/create.string14'),
    ...validation,
  });

export const validate = (values, credit_card_info, interfaceType) => {
  let error = {};
  const isNoChangeCard =
    values?.options_prepaid?.minimum_payment !== '0' &&
    values.payment_method === 'credit_debit' &&
    !credit_card_info.billing_default_credit_card_exp &&
    !credit_card_info.billing_default_credit_card_last_four;
  const isSlider = interfaceType === SLIDER;
  if (!isSlider) {
    if (!values.size) {
      error.size = 'Size is required';
    }
  } else {
    if (!values.sliders && !values.sliders[DISK]) {
      error.sliders[DISK] = 'Disk is required';
    }
    if (!values.sliders && !values.sliders[RAM]) {
      error.sliders[RAM] = 'Ram is required';
    }
    if (!values.sliders && !values.sliders[CORE]) {
      error.sliders[CORE] = 'CPU is required';
    }
  }
  if (!values.payment_method && values?.options_prepaid?.minimum_payment !== '0') {
    error.payment_method = 'Please select payment method';
  }

  if (isNoChangeCard) {
    if (!values.card_name) {
      error.card_name = 'Card name is required';
    }
    if (!values.billing_address) {
      error.billing_address = 'Billing address is required';
    }
    if (!values.city) {
      error.city = 'City is required';
    }
    if (!values.state) {
      error.state = 'State is required';
    }
    if (!values.cvv) {
      error.cvv = 'Cvv is required';
    }
    if (!values.zip) {
      error.zip = 'Zip is required';
    }
    if (!values.card_number) {
      error.card_number = 'Card number is required';
    }
    if (!values.card_month) {
      error.card_month = 'Expiry month is required';
    }
    if (!values.card_year) {
      error.card_year = 'Expiry year is required';
    }
    if (!values.phone) {
      error.phone = 'Phone is required';
    }
    if (!values.country) {
      error.country = 'Country is required';
    }
    if (!values.bank_name) {
      error.bank_name = 'Bank name is required';
    }
    if (!values.bank_phone_number) {
      error.bank_phone_number = 'Bank Phone number is required';
    }
  }
  return error;
};

export { cardError } from 'validations/payment-form-validation';
