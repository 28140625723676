import { pStyles } from 'components/reusable-css';
import styled from 'styled-components';

export const Button = styled.div`
  pointer-events: ${({ loading, pointerEvents }) => (pointerEvents?.pointerEvents ? 'none' : loading ? 'none' : '')};
  p {
    opacity: ${({ loading }) => (loading ? 0.3 : 1)};
  }

  .icon {
    margin-left: 10px;
  }

  button {
    text-transform: uppercase;
    opacity: ${({ loading, pointerEvents }) => (pointerEvents?.pointerEvents ? 0.7 : loading ? 0.7 : 1)};
    color: white;
    opacity: ${({ loading }) => (loading ? 0.3 : 1)};
    background-color: ${(props) => props.theme.primaryDarkColor};
    border: 0;
    padding: 0.6rem 1.4rem;
    font-size: ${(props) => props.theme.default?.p};
    ${(props) => pStyles(props.theme.resolutions)};
    margin: 0;
    font-weight: bold;
    border-radius: 5px;
    :disabled {
      opacity: 0.3;
      background-color: gray;
    }
  }
`;
