import React from 'react';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import cPanelImage from 'assets/images/cpanel.svg';
import get from 'lodash/get';
import { P } from 'components/reusable-css';

const AlmaLinuxcPanel = ({ type, data, style }) => {
  //DIFFERENCE IN ARRAY VALUES(NETWORK) OF BM AND VS i.e.
  const valBm = data?.networks?.map((network) => {
    return network?.ipv4?.map((ip) => (ip?.type === 'primary' ? ip?.ip : null));
  });
  const valVS = data?.networks?.map((network) => {
    return network?.eth0?.map((eth) => {
      return eth?.ipv4?.map((ip) => (ip?.type === 'primary' ? ip?.ip : null));
    });
  });
  const ipVS = get(valVS, '0.0', []).filter((item) => item != null);
  const ipBM = get(valBm, '0', []).filter((item) => item != null);

  const ip = type === 'vs' ? ipVS : type === 'bm' ? ipBM : null;

  return (
    data?.app === 'almalinuxcpanel' && (
      <Styles.AlmaLinuxcPanel style={style}>
        <img src={cPanelImage} alt="cpanel logo" width={70} />
        <P>
          Link to{' '}
          <a rel="noreferrer" target="_blank" href={`https://${ip}:2087/`}>
            WHM
          </a>{' '}
          /{' '}
          <a rel="noreferrer" target="_blank" href={`https://${ip}:2083/`}>
            cPanel
          </a>
        </P>
      </Styles.AlmaLinuxcPanel>
    )
  );
};
export { AlmaLinuxcPanel };
