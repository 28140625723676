import React from 'react';
import * as Styles from 'pages/member/wh/WH.style';
import { useTranslation } from 'react-i18next';
import WeeblyLogo from 'assets/svg/weebly.svg';
import { P } from 'components/reusable-css';

const WHContainer = () => {
  const { t } = useTranslation();

  return (
    <Styles.WHContainerSC>
      <Styles.MarginContainer>
        <Styles.FlexContainers>
          <div className="imageWidth">
            <img src={WeeblyLogo} width="100%" height={100} alt="Weebly logo" />
          </div>
          <P className="paraWidth">{t(`wh_container_paragraph`)}</P>
        </Styles.FlexContainers>
      </Styles.MarginContainer>
    </Styles.WHContainerSC>
  );
};
export default WHContainer;
