import axios from 'axios';
import { BASE_URL } from 'constants/env';

const fetchDataAndUpdateTheme = async () => {
  try {
    const response = await axios.get(BASE_URL + `/uidetails/css`);
    const { resolutions, default_css } = response.data;
    theme.default = default_css;
    theme.resolutions = resolutions;
    return response;
  } catch (error) {
    console.log(error);
  }
};

const theme = {
  /** Brand colors */
  fetchDataAndUpdateTheme,
  default: null,
  resolutions: [],
  primaryColor: '#F9AB43',
  primaryColorA1: 'rgba(249, 171, 67, 0.1)',
  primaryColorA2: 'rgba(249, 171, 67, 0.2)',
  primaryColorA3: 'rgba(249, 171, 67, 0.3)',
  primaryDarkColor: '#FF7300',
  pageBackgroundColor: '#f8f8f8',
  primaryDarkColorA1: 'rgba(255, 115, 0, 0.1)',
  primaryDarkColorA2: 'rgba(255, 115, 0, 0.2)',
  primaryDarkColorA3: 'rgba(255, 115, 0, 0.3)',
  secondaryColor: '#616C7B',
  secondaryLightColor: '#707A88',
  secondaryDarkColor: '#363D47',
  lightShadeBackgroundColor: '#f2f2f2',
  errorColor: '#fe4040',
  errorBackgroundColor: '#ffe3e6',
  errorBorderColor: '#ecbcc0',
  dividerColor: '#e6e6e6',
  gray: {
    shade0: '#000000',
    shade1: '#111111',
    shade2: '#222222',
    shade3: '#333333',
    shade4: '#444444',
    shade5: '#555555',
    shade6: '#666666',
    shade7: '#777777',
    mid: '#808080',
    shade8: '#888888',
    shade9: '#999999',
    shade10: '#343434',
    shadeA: '#aaaaaa',
    shadeB: '#bbbbbb',
    shadeC: '#cccccc',
    shadeD: '#dddddd',
    shadeE: '#eeeeee',
    shadeF: '#ffffff',
  },
  blackA1: 'rgba(0, 0, 0, 0.1)',
  blackA2: 'rgba(0, 0, 0, 0.2)',
  overlayBackground: 'rgba(0,0,0,0.5)',

  boxShadow1: '0 4px 5px 0 #bdbdbd',

  iconColor: '#222222',

  appDrawer: {
    mobile: {
      backgroundColor: '#363d47',
      borderBottomColor: '#1a1a1a',
      borderTopColor: '#4d4d4d',
      section: {
        activeBoxShadow: '0 0 15px 3px #222 inset',
      },
      link: {
        backgroundColor: '#21252e',
        badge: {
          backgroundColor: '#6eb433',
        },
      },
    },
  },

  header: {
    account: {
      fontColor: '#3567ce',
    },
  },

  notification: {
    backgroundColor: '#82bf50',
    errorColor: '#c80815',
  },

  serverSizeCard: {
    borderColor: '#e3e6e4',
    hoverBorderColor: '#f5d9b3',
    hoverBackgroundColor: '#F7F6F5',
    saleRed: {
      backgroundColor: '#e93f3c',
    },

    features: {
      backgroundColor: '#F6F6F6',
      hoverBackgroundColor: '#EBEDEC',
    },
    tag: {
      backgroundColor: '#99bc82',
      stopBackgroundColor: '#f3a3a3',
    },
    selectedTag: {
      backgroundColor: '#616C7B',
    },
  },
  redColor: '#E84040',
  borders: {
    lightGrey: 'rgba(202, 202, 202, 0.664)',
  },
  panelBackground: '#f8f8f8',
};
export default theme;
