/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import * as Styles from 'pages/member/manage-vm/ManageVM.style';
import { Icon } from 'semantic-ui-react';
import Input from 'components/input';
import get from 'lodash/get';
import { useMutation } from 'react-query';
import { ADD_TAG_VM, DELETE_TAG_VM, LABEL_UPDATE } from 'components/manage-vm/queries';
import { useTranslation } from 'react-i18next';
import { LABEL_SCHEMA, TAG_SCHEMA } from 'constants/yup';
import { CustomHook } from 'utils/custom-hook';
import { CustomTags } from './components';
import DialogBox from 'components/dialog';
import ConditionalFragment from 'components/conditional-fragment';
import { H5, H6 } from 'components/reusable-css';

const TagModal = ({
  handleClose,
  openTag,
  allTags,
  tags,
  managerVmRefetch,
  data,
  managerVmIsFetching,
  isTags,
  type,
  rights,
  refetchTags,
}) => {
  const { mutationProps } = CustomHook();
  const { t } = useTranslation();

  const [addedTag, setAddedTag] = useState('');
  const [deletedTag, setDeletedTag] = useState('');
  const [deletedTag2, setDeletedTag2] = useState('');
  const remainingTags = useMemo(
    () => get(allTags, 'tags', []).filter((item) => !tags.some((i) => item.tag === i)),
    [allTags],
  );

  // Remaining tags not included of servers tags.
  const [totalTags, setTotalTags] = useState(remainingTags);

  // Server tags.
  const [serverTags, setServerTags] = useState(tags);
  const [label, setLabel] = useState(data.label);
  const [labelError, setLabelError] = useState(false);
  const [tagError, setTagError] = useState(false);
  const { mutate: addTag, isLoading: addTagLoading } = useMutation(ADD_TAG_VM);
  const { mutate: deleteTag, isLoading: deleteTagLoading } = useMutation(DELETE_TAG_VM);
  const { mutate: editLabel, isLoading: editLabelLoading } = useMutation(LABEL_UPDATE);

  const handleEditLabel = async (e) => {
    const name = e?.target?.value || label;
    const isValid = await LABEL_SCHEMA.isValid({
      label: name,
    });
    if (isValid && !labelError && name !== data.label && !managerVmIsFetching && !editLabelLoading) {
      await editLabel(
        {
          id: data.id,
          label: name,
          type,
        },
        mutationProps(() => {}, {
          onSuccess: async () => {
            await managerVmRefetch();
          },
        }),
      );
    }
  };

  const handleAddTag = async (item) => {
    const tag = item || addedTag;
    const isValid = await LABEL_SCHEMA.isValid({
      label: tag,
    });
    if (!tagError && isValid) {
      await addTag(
        { id: data.id, tag, type },
        mutationProps(() => {}, {
          onSuccess: async () => {
            if (!item) {
              setAddedTag('');
              setServerTags([...serverTags, addedTag]);
            } else {
              setServerTags([...serverTags, item]);
              const filter = totalTags.filter((i) => i.tag !== item);
              setTotalTags(filter);
            }
            await managerVmRefetch();
            if (refetchTags) refetchTags();
            setDeletedTag('');
          },
        }),
      );
    }
  };

  return (
    <DialogBox width="xs" open={openTag} onClose={handleClose} disableAutoFocus>
      <Styles.FilterCards>
        <Icon name="close" onClick={handleClose} />
        <Styles.TagContent>
          <H5>{t('vs/instance.string31')}</H5>
          <Styles.GreyBorder />
          <ConditionalFragment condition={rights.vsrename && type === 'vs'}>
            <Styles.Flex>
              <H6>{t('vs/instance.string32')}</H6>
            </Styles.Flex>
            <Styles.NewTag>
              <Input
                type="text"
                styling={{ fontSize: '16px', marginBottom: '0' }}
                name="label"
                value={label}
                dataTooltip={t('vs/instance.string33')}
                tooltipStyles={{
                  leftPosition: '8%',
                  headingSize: '11px',
                  descriptionSize: '11px',
                  minWidth: '80%',
                  bottomPosition: '65px',
                  arrowSize: '18px',
                }}
                onChange={async (e) => {
                  setLabel(e.target.value);
                  const isValid = await LABEL_SCHEMA.isValid({
                    label: e.target.value,
                  });
                  setLabelError(!isValid);
                }}
                loading={editLabelLoading}
                inputProps={{
                  maxlength: 64,
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleEditLabel(e);
                  }
                }}
                error={labelError ? 'vs/instance.string175' : ''}
                touched
                placeholder={t('vs/instance.string173')}
                isFormIk={false}
                EndAdornment={() => (
                  <span onClick={() => handleEditLabel()}>
                    <Icon name="save" />
                  </span>
                )}
              />
            </Styles.NewTag>
          </ConditionalFragment>
          <ConditionalFragment condition={type === 'bm' && rights?.bmrename}>
            <Styles.Flex>
              <H6>{t('bm/instance.string5')}</H6>
            </Styles.Flex>
            <Styles.NewTag>
              <Input
                type="text"
                styling={{ fontSize: '16px', marginBottom: '0' }}
                name="label"
                value={label}
                dataTooltip={t('vs/instance.string33')}
                tooltipStyles={{
                  leftPosition: '8%',
                  headingSize: '11px',
                  descriptionSize: '11px',
                  minWidth: '80%',
                  bottomPosition: '65px',
                  arrowSize: '18px',
                }}
                onChange={async (e) => {
                  setLabel(e.target.value);
                  const isValid = await LABEL_SCHEMA.isValid({
                    label: e.target.value,
                  });
                  setLabelError(!isValid);
                }}
                loading={editLabelLoading}
                inputProps={{
                  maxlength: 64,
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleEditLabel(e);
                  }
                }}
                error={labelError ? 'bm/instance.string71' : ''}
                touched
                placeholder={t('bm/instance.string69')}
                isFormIk={false}
                EndAdornment={() => (
                  <span onClick={() => handleEditLabel()}>
                    <Icon name="save" />
                  </span>
                )}
              />
            </Styles.NewTag>
          </ConditionalFragment>

          <ConditionalFragment condition={isTags}>
            {serverTags.length ? (
              <React.Fragment>
                <Styles.Flex>
                  <H6>{t('vs/instance.string34')}</H6>
                </Styles.Flex>
                <CustomTags
                  disable={deleteTagLoading}
                  loading={(item) => item === deletedTag2}
                  // className={(item) => (item === deletedItem.delete ? styles.fadeOut : styles.fadeIn)}
                  data={serverTags}
                  iconName="close"
                  onClick={async (e, item) => {
                    setDeletedTag2(item);
                    await deleteTag(
                      { id: data.id, tag: item, type },
                      mutationProps(() => {}, {
                        onSuccess: async () => {
                          const filter = serverTags.filter((i) => i !== item);
                          setServerTags(filter);
                          setTotalTags([...totalTags, { tag: item }]);
                          setDeletedTag2('');
                          await managerVmRefetch();
                        },
                      }),
                    );
                  }}
                />
              </React.Fragment>
            ) : null}
            <ConditionalFragment condition={type === 'vs' && rights?.vsedittags}>
              <Styles.Flex>
                <H6>{t('vs/instance.string35')}</H6>
              </Styles.Flex>
              <Styles.NewTag>
                <Input
                  defaultDropdown={'off'}
                  type={'text'}
                  styling={{ fontSize: '16px', marginBottom: '0' }}
                  name={'add_new_tag'}
                  value={addedTag}
                  onChange={async (e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
                    setAddedTag(filteredValue);
                    const isValid = await TAG_SCHEMA.isValid({
                      label: filteredValue,
                    });
                    setTagError(!isValid);
                  }}
                  inputProps={{
                    maxlength: 64,
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleAddTag();
                    }
                  }}
                  error={tagError ? 'vs/instance.string175' : ''}
                  touched
                  placeholder={t('vs/instance.string174')}
                  isFormIk={false}
                  loading={addTagLoading && !deletedTag}
                  EndAdornment={() => (
                    <span onClick={() => handleAddTag()}>
                      <Icon name="add" />
                    </span>
                  )}
                  dataTooltip={t('vs/instance.string199')}
                  tooltipStyles={{
                    leftPosition: '8%',
                    headingSize: '11px',
                    descriptionSize: '11px',
                    minWidth: '80%',
                    bottomPosition: '65px',
                    arrowSize: '18px',
                  }}
                />
              </Styles.NewTag>
              <Styles.Flex>
                <H6>{t('vs/instance.string36')}</H6>
              </Styles.Flex>
              <CustomTags
                // className={(item) => (item.tag === deletedItem.add ? styles.fadeOut : styles.fadeIn)}
                data={totalTags}
                disable={addTagLoading}
                loading={(item) => item.tag === deletedTag}
                tag="tag"
                onClick={async (e, item) => {
                  setDeletedTag(item.tag);
                  handleAddTag(item.tag);
                }}
              />
            </ConditionalFragment>

            <ConditionalFragment condition={type === 'bm' && rights?.bmedittags}>
              <Styles.Flex>
                <H6>{t('bm/instance.string6')}</H6>
              </Styles.Flex>
              <Styles.NewTag>
                <Input
                  defaultDropdown={'off'}
                  type={'text'}
                  styling={{ fontSize: '16px', marginBottom: '0' }}
                  name={'add_new_tag'}
                  value={addedTag}
                  onChange={async (e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
                    setAddedTag(filteredValue);
                    const isValid = await TAG_SCHEMA.isValid({
                      label: filteredValue,
                    });
                    setTagError(!isValid);
                  }}
                  inputProps={{
                    maxlength: 64,
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleAddTag();
                    }
                  }}
                  error={tagError ? 'bm/instance.string71' : ''}
                  touched
                  placeholder={t('bm/instance.string70')}
                  isFormIk={false}
                  loading={addTagLoading && !deletedTag}
                  EndAdornment={() => (
                    <span onClick={() => handleAddTag()}>
                      <Icon name="add" />
                    </span>
                  )}
                  dataTooltip={t('vs/instance.string199')}
                  tooltipStyles={{
                    leftPosition: '8%',
                    headingSize: '11px',
                    descriptionSize: '11px',
                    minWidth: '80%',
                    bottomPosition: '65px',
                    arrowSize: '18px',
                  }}
                />
              </Styles.NewTag>
              <Styles.Flex>
                <H6>{t('bm/instance.string7')}</H6>
              </Styles.Flex>
              <CustomTags
                // className={(item) => (item.tag === deletedItem.add ? styles.fadeOut : styles.fadeIn)}
                data={totalTags}
                disable={addTagLoading}
                loading={(item) => item.tag === deletedTag}
                tag="tag"
                onClick={async (e, item) => {
                  setDeletedTag(item.tag);
                  handleAddTag(item.tag);
                }}
              />
            </ConditionalFragment>
          </ConditionalFragment>
        </Styles.TagContent>
      </Styles.FilterCards>
    </DialogBox>
  );
};

export { TagModal };
