export const changelogs_es = [
  {
    versions: [
      {
        name: 'Version 6.2024.01.28',
        date: '28 de enero del 2024',
      },
    ],

    details: [
      {
        topHeading: '¡Bienvenido a una nueva version del Panel de Control de ServerPoint!',
        topPara: 'Esta versión resuelve muchos errores en diseóo y ahora soporta paquetes de lenguaje! Pronto añadiremos más lenguajes.',
        bottomPara: '¡Esperamos que dizfrute estos cambios!',
        detail: [
          {
            name: 'Cambios generales',
            descriptions: [
              {
                description: 'Este portal de clientes ahora utiliza paquetes de lenguajes.',
              },
              {
                description: 'Nuestro primer paquete de languaje, además de Ingles, es Español. Si encuentra algún error de traducción, déjenos saber!',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    versions: [
      {
        name: 'Versión 6.1a',
        date: '5 de octubre del 2023',
      },
    ],

    details: [
      {
        topHeading: '¡Bienvenido a la versión 6.1a!',
        topPara: 'Ahora, la versión 6.1a trae algunas nuevas características para hacer tu experiencia más agradable. Estas eran parte de la versión 6.1, sin embargo, hubo algunos retrasos en su implementación.',
        bottomPara: '¡Esperamos que te encanten estos nuevos cambios!',
        detail: [
          {
            name: 'Cambios generales',
            descriptions: [
              {
                description:
                  '¡Presentamos la autenticación de dos factores! Para mayor seguridad, añade su número de teléfono SMS a nuestro sistema. Siempre que entres a este panel, te enviaremos un código de seguridad adicional por mensaje de texto que necesitarás para autenticarse.',
              },
            ],
          },
          {
            name: 'Cambios en los servicios de servidores virtuales y servidores dedicados',
            descriptions: [
              {
                description:
                  '¡Nuevo servicio de alojamiento de DNS! Hospeda el DNS de sus dominios en nuestra nueva infraestructura de DNS, protegida contra ataques de tipo DDoS. Ya no tendrás que lidiar con utilizar los servidores DNS de tu registrador de dominios o otro proveedor de alojamiento de DNS externo. Y a medida que pase el tiempo, seguiremos expandiendo este servicio con tipos adicionales de registros DNS.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    versions: [
      {
        name: 'Versión 6.1',
        date: '4 de julio de 2023',
      },
    ],

    details: [
      {
        topHeading: '¡Bienvenido a la versión 6.1!',
        topPara:
          'Hemos introducido grandes mejoras en la velocidad y eficiencia de este Portal de Clientes. También hemos corregido muchos errores que han sido reportados a nuestros programadores.',
        topPara2: 'Ahora, la versión 6.1 trae algunas características nuevas para hacer su experiencia más agradable.',
        bottomPara: '¡Esperamos que te encanten estos nuevos cambios!',
        detail: [
          {
            name: 'Cambios generales',
            descriptions: [
              {
                description:
                  'Se ha introducido nuevos cambios en nuestro código para facilitar que este Portal del Cliente funcione mejor en móvil y otras resoluciones. Durante los próximos meses, continuaremos ajustando la interfaz del Portal de Clientes a los diversos dispositivos que nuestros clientes utilizan.',
              },
            ],
          },
          {
            name: 'Cambios en los servidores virtuales de nuestro servicio ColossusCloud',
            descriptions: [
              {
                description:
                  '¡Hemos introducido nuestra nueva generación de servidores virtuales: G4! Estos utilizan los procesadores Intel Xeon Gold, con una frecuencia de CPU más rápida y velocidades de RAM más altas. ¡Puede ordenar estos servidores virtuales G4 hoy mismo!',
              },
            ],
          },
          {
            name: 'Cambios en la comunicación con nuestro personal de apoyo, ventas y facturación',
            descriptions: [
              {
                description:
                  `¡Hemos presentado la primera versión de nuestro nuevo sistema de 'tickets'! Comunicarse con nuestro personal ahora es más fácil que nunca. Solo haz clic en Soporte en el menú de la izquierda para comenzar.`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    versions: [
      {
        name: 'Versión 6.0',
        date: '4 de junio de 2022',
      },
    ],

    details: [
      {
        topHeading: '¡Bienvenido a la versión 6.0!',
        topPara: `Durante más de un año, hemos trabajado en reescribir el código de el Portal del Cliente de ServerPoint a los estándares modernos de codificación de hoy. Este portal ha sido reescrito en ReactJS, interactuando con nuestros sistemas a través de una API completamente nueva.`,
        bottomPara: '¡Esperamos que te encante!',
        detail: [
          {
            name: '',
            descriptions: [
              {
                description:
                  'Este portal ahora está alojado globalmente y balanceado desde docenas de centros de datos alrededor del mundo. Esto significa que será rápido, sin importar donde estés.',
              },
              {
                description: 'Ahora podremos añadir características más rápido que nunca.',
              },
              {
                description:
                  '¡Es más receptivo! Se verá genial en casi cualquier dispositivo. Si encuentras fallos de diseño al usar este portal en tu dispositivo, ¡por favor avísanos!',
              },
            ],
          },
          {
            name: 'API',
            descriptions: [
              {
                description:
                  '¡Sí! ¡Ahora tenemos una API! Todo lo que puedas hacer a través de este Portal del Cliente, será posible hacerlo a través de nuestra nueva API. ¡Pronto publicaremos su documentación completa!',
              },
            ],
          },
          {
            name: 'Cambios generales',
            descriptions: [
              {
                description:
                  'Nuestra interfaz de manejo de usuarios ha sido completamente rediseñada. ¡Ahora es mucho más conveniente!',
              },
              {
                description: 'Añadir fondos/realizar pagos también es mucho más fácil ahora.',
              },
              {
                description: 'Ahora puedes manejar múltiples tarjetas de crédito.',
              },
            ],
          },
          {
            name: 'Cambios en nuestro servicio de servidores virtuales, ColossusCloud',
            descriptions: [
              {
                description:
                  'Ahora tienes la capacidad de asignar etiquetas a tus servidores virtuales. ¡Pronto ofreceremos la capacidad de filtrar por etiquetas!',
              },
              {
                description:
                  'Hemos añadido muchos sistemas operativos nuevos! Incluyendo Windows 2022, AlmaLinux, RockyLinux, Ubuntu 22.04 LTS y Debian 11.',
              },
              {
                description:
                  '¡Puntos de bonificación! Cuando pagas por un año completo, obtendrás puntos de bonificación equivalentes a dos meses gratis de su servidor virtual! Las costos por cPanel, Windows y otras aplicaciones externas están excluidas de el cálculo de los bonos.',
              },
              {
                description: 'Puedes ordenar servidores virtuales por nombre, sistema operativo, números IP y más.',
              },
              {
                description:
                  'Ahora puedes pagar con tarjeta de crédito o PayPal directamente en la interfaz de ordenar.',
              },
              {
                description:
                  'Hemos realizado grandes mejoras en la función de teclado/vídeo/ratón (consola) de cada servidor virtual.',
              },
              {
                description:
                  'Las estadísticas de uso de CPU, RAM y disco son mucho más precisas y están mejor presentadas.',
              },
              {
                description:
                  'Cuando hagas clic en el icono de configuración de un servidor virtual, encontrarás una interfaz de configuración completamente rediseñada que es mucho más fácil de usar.',
              },
              {
                description:
                  'Haz clic en los ... junto a un servidor virtual para un nuevo menú. Además, ahora es más fácil cancelar un servidor virtual (que puedes restaurar en caso de cometer un error).',
              },
              {
                description:
                  'Hablando de eso, la interfaz para restaurar servidores virtuales cancelados es ahora mucho más fácil de usar.',
              },
            ],
          },
          {
            name: 'Cambios en servidores dedicados',
            descriptions: [
              {
                description: 'Ordenar un nuevo servidor dedicado ahora es mucho más fácil que antes.',
              },
              {
                description: '¡Puntos de bonificación! Cuando pagas por un año completo, obtendrás puntos de bonificación equivalentes a dos meses gratis de su servidor virtual! Las costos por cPanel, Windows y otras aplicaciones externas están excluidas de el cálculo de los bonos.',
              },
              {
                description: 'Ordenar un servidor dedicado con cPanel ahora es completamente automatizado.',
              },
              {
                description: 'Ahora puedes pagar con tarjeta de crédito o PayPal directamente en la interfaz de ordenar.',
              },
              {
                description: 'Puedes comprar planes administrados directamente desde la interfaz de ordenar un servidor. Nuestro personal luego se pondrá en contacto con usted para configurarlos.',
              },
              {
                description: 'Puedes ordenar servidores dedicados por nombre, sistema operativo, números IP y más.',
              },
              {
                description: 'Ahora tienes la capacidad de asignar etiquetas a sus servidores dedicados. ¡Pronto ofreceremos la capacidad de filtrar por etiquetas!',
              },
              {
                description: 'Las estadísticas de uso de CPU, RAM y disco son mucho más precisas y están mejor presentadas.',
              },
            ],
          },
          {
            name: 'Alojamiento web, DNS y registro de dominios',
            descriptions: [
              {
                description: 'Estas funciones, que existen en nuestro portal de generación 3 utilizado por nuestros clientes de alojamiento web compartido, ¡pronto estarán aquí!',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    versions: [
      {
        name: 'Versión 5.1.2',
        date: '8 de marzo de 2021',
      },
    ],
    details: [
      {
        detail: [
          {
            name: 'Cambios en servidores dedicados.',
            descriptions: [
              {
                description:
                  '¡Nuestro sistema para ordenar automáticamente servidores dedicados está de vuelta! Mejorado y mejor que nunca, con muchos servidores disponibles en menos de una hora.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    versions: [
      {
        name: 'Versión 5.1',
        date: '2 de octubre de 2020',
      },
    ],
    details: [
      {
        detail: [
          {
            name: '¡Muchos cambios internos!',
            descriptions: [
              {
                description:
                  'Esta versión presenta nuevos cambios en el código base de las interfaces, para permitir futuros diseños de interfaces más responsivos.',
              },
              {
                description: 'La interface ahora es más amigable para dispositivos móviles.',
              },
            ],
          },
          {
            name: 'Cambios en ColossusCloud',
            descriptions: [
              {
                description:
                  '¡La interfaz para ordenar servidores virtuales ha sido completamente rediseñada! Ahora es mucho más fácil construir un servidor virtual.',
              },
            ],
          },
          {
            name: 'Cambios generales',
            descriptions: [
              {
                description:
                  '¡Una nueva interfaz de facturación! Ahora es mucho más fácil manejar tus tarjetas de crédito, así como la capacidad de pagar por sus servicios.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    versions: [
      {
        name: 'Versión 5.0',
        date: '20 de septiembre de 2018',
      },
    ],
    details: [
      {
        detail: [
          {
            name: '¡Bienvenido al nuevo Portal de Cliente de ServerPoint!',
            descriptions: [
              {
                description:
                  'Esta versión introduce una experiencia de interfaz de usuario completamente rediseñada. ¡No solo eso, sino que la base de código ha sido cambiada para hacer más fácil y rápido para nuestros desarrolladores agregar nuevas características! Solo asegúrate de enviarnos sus comentarios.',
              },
            ],
          },
          {
            name: '¡Presentamos la Generación 5 de ColossusCloud! Un esfuerzo de casi un año para mejorar la tecnología y el rendimiento en toda nuestra plataforma.',
            descriptions: [
              {
                description: '¡URL del nuevo portal más corta y fácil! https://portal.serverpoint.com.',
              },
              {
                description:
                  '¡El tiempo entre ordenar un servidor virtual hasta cuando está listo es ahora más corto! Tanto los servidores virtuales Linux como Windows se despliegan mucho más rápido que antes. ¡A veces en menos de 30 segundos!',
              },
              {
                description: 'ColossusCloud ahora funciona con la última y más rápida versión de Xen 4.11.',
              },
              {
                description:
                  'Mejoras significativas en las copias de seguridad. Las copias de seguridad ahora se realizan en un minuto o dos y pueden reutilizarse para crear nuevas máquinas virtuales en muy poco tiempo.',
              },
              {
                description:
                  'New backup infrastructure and backup plans! Backups are now kept on a separate system, powered by a very fast incremental, and dedup enabled, backup software. This is now enabled in our Las Vegas, NV and Dallas, TX region and will be enabled in all regions by the end of September, 2018.',
              },
              {
                description:
                  '¡Mayor estabilidad! Un error en controladores \'drivers\' en Windows a veces podía causar caídas aleatorias. Estos controladores antiguos han sido reemplazados en los servidores virtuales de Windows de todos los clientes.',
              },
              {
                description:
                  'Nuevas imágenes de Windows más pequeñas que ocuparán menos espacio en el disco de sus servidores virtuales.',
              },
              {
                description:
                  'Nuevos switches centrales de 40G/100G en todos nuestros centros de datos con tecnologías \'failover\' mejoradas.',
              },
              {
                description:
                  'Este portal ahora está equilibrado en más servidores para proporcionarte una experiencia más rápida.',
              },
              {
                description:
                  'La capacidad ha aumentado en todas nuestras regiones: más servidores, más RAM y más CPU.',
              },
            ],
          },
        ],
      },
    ],
  },
];
