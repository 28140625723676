import { get } from 'lodash';
import { lightColor } from 'constants/index';

//Traffic Chart
export const TrafficData = (statsData) => {
  let trafficData = get(statsData, `every_hour`, []);
  let labels = [];
  let datasets = [{ label: 'Clicks', data: [], ...lightColor }];
  trafficData.forEach((item) => {
    labels.push(item?.timestamp);
    datasets[0]?.data?.push(item?.hits);
  });
  return { labels, datasets };
};

export const TrafficDataYMax = (statsData) => {
  let trafficData = get(statsData, `every_hour`, []);
  let yMaxData = [];
  trafficData.forEach((item) => {
    yMaxData?.push(item?.hits);
  });
  let highestValue = Math.max(...yMaxData);
  let yMaxValue;
  if (highestValue <= 1) {
    yMaxValue = 1;
  } else {
    let value = highestValue + highestValue * 0.25;
    yMaxValue = Math.round(value);
  }
  return { yMaxValue, highestValue };
};
