import { validatePrice } from '../utils';
import { getCurrentYear } from 'utils/current-year';

export const initialValues = {
  new_card: '',
  payment_method: '',
  size: '',
  app: '',
  image: '',
  region: '',
  backup_plan: '',
  options_prepaid: {},
  root_pass: '',
  confirm_pass: '',
  virtual_server_name: 'My SSD Virtual Cloud Server',
  ipv4_ips: 1,
  vs_saved_templates: {},
  card_name: '',
  billing_address: '',
  city: '',
  cvv: '',
  card_number: '',
  bank_name: '',
  bank_phone_number: '',
  state: '',
  zip: '',
  phone: '',
  company: '',
  country: 'US',
  card_month: 1,
  card_year: getCurrentYear(),
  vm_price: '',
  bm_price: '',
  custom_vs_amount: '',
};

export const validate = (values, type) => {
  let error = {};
  if (!values.payment_method && type === 'vm' && validatePrice(values)) {
    error.payment_method = 'Please select payment method';
  }
  if (!values.payment_method && type === 'bm' && (values.bm_price !== '0' || values.bm_price !== '')) {
    error.payment_method = 'Please select payment method';
  }
  return error;
};
export const cardDetailValues = [
  'card_name',
  'billing_address',
  'city',
  'cvv',
  'zip',
  'card_number',
  'card_month',
  'card_year',
  'phone',
  'company',
  'country',
  'bank_name',
  'bank_phone_number',
];
export const NewCreditCardError = (errors) => {
  return (
    errors.card_name ||
    errors.billing_address ||
    errors.city ||
    errors.cvv ||
    errors.zip ||
    errors.card_number ||
    errors.card_month ||
    errors.card_year ||
    errors.phone ||
    errors.company ||
    errors.country ||
    errors.bank_name ||
    errors.bank_phone_number
  );
};

export const cardError = (errors, values, type, touched) => {
  const isNoChangeCard =
    type === 'vm' ? validatePrice(values) : type === 'bm' ? values.bm_price !== '0' || values.bm_price !== '' : '';

  if (isNoChangeCard) {
    return cardDetailValues.some((item) => errors[item] && touched[item]);
  }
  return false;
};
