import React from 'react';
import LampImage from 'assets/images/lamp.svg';
import * as Style from 'components/manage-bm/ManageBM.style';

const Comment = ({ children }) => {
  return (
    <Style.Comment>
      <div className="d-flex">
        <div className="d-flex align-items-center pr-4">
          <img src={LampImage} />
        </div>
        <div style={{ borderRight: '1px solid white' }} />
        <div className="pl-4 pt-1 pb-2">{children}</div>
      </div>
    </Style.Comment>
  );
};
export default Comment;
