import { H5 } from 'components/reusable-css';
import React from 'react';
import { Trans } from 'react-i18next';

const HeadingAndChart = ({ children, heading, count }) => {
  return (
    <div className="w-100 h-100 pl-lg-4 pl-2 pr-2 pb-5 pr-lg-4">
      <div className="pb-2 pl-lg-0 pr-2 pl-3 pr-lg-0">
        <H5>
          <strong>
            <Trans i18nKey={heading} values={{ count }} />
          </strong>
        </H5>
        <hr />
      </div>
      {children}
    </div>
  );
};

export { HeadingAndChart };
