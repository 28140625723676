/**
 * @author Paras Bansal
 * @email parasbansal10@gmail.com
 * @create date 2021-02-02 10:42:15
 * @modify date 2021-02-05 10:31:18
 */

import styled from 'styled-components';
import { deviceBreakpoints } from 'variables';
import { h2Styles } from 'components/reusable-css';

export const StepLabelSC = styled.div`
  display: flex;
  align-items: center;
`;

export const StepCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  min-width: 200px;
  border: 1px solid ${(props) => props.theme.gray.shade0};
  font-weight: bold;
  font-size: ${(props) => props.theme.default?.h2};
  ${(props) => h2Styles(props.theme.resolutions)};
  @media only screen and (max-width: ${deviceBreakpoints.tablet}px) {
    padding: 20px 14px;
    min-width: 150px;
  }

  @media only screen and (max-width: ${deviceBreakpoints.sLaptop}px) {
    padding: 20px 12px;
    min-width: 100px;
  }
`;

export const StepHeading = styled.h2`
  display: inline;
  font-size: ${(props) => props.theme.default?.h2};
  margin: 0;
  margin-left: 15px;
  ${(props) => h2Styles(props.theme.resolutions)};
`;
